import React, { useState, useContext } from 'react';
import { Navigate } from 'react-router-dom';
import { validateEmail } from '../../utils/utils';
import AuthContext from '../../context/AuthContext';
import LoginUI from './LoginUI';
import PermissionContext from '../../context/PermissionContext';
import { useTranslation } from 'react-i18next';

const Login = ({ title }) => {
  const { loginUser, user, msalInstance, onMsalInstanceChange } = useContext(AuthContext);
  const [formErrors, setFormErrors] = useState({ email: '', password: '' });
  const { updatePermissionList } = useContext(PermissionContext);
  const { t } = useTranslation();

  if (user) {
    return <Navigate to='/dashboard' />;
  }
  const onSubmit = (email, password) => {
    if (!validateEmail(email)) {
      setFormErrors({ ...formErrors, email: t('loginPage.Please enter a valid email!') });
    } else if (password.trim().length < 1) {
      setFormErrors({ ...formErrors, password: t('loginPage.Please enter a password') });
    } else {
      loginUser(email, password, updatePermissionList);
    }
  };
  return <LoginUI title={title} formErrors={formErrors} onSubmit={onSubmit} msalInstance={msalInstance} onMsalInstanceChange={onMsalInstanceChange}  />;
};

export default Login;
