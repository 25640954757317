import { useState, createContext, useEffect, useContext } from "react";
import useAxios from "../hooks/useAxios";
import AuthContext from "./AuthContext";
import axios from "axios";

const PermissionContext = createContext();
export default PermissionContext;

export const PermissionProvider = ({ children }) => {
  const api = useAxios();
  const { user } = useContext(AuthContext);
  const [permissionList, setPermissionList] = useState({
    is_page_loaded: 0,
    user_allotted_cnt: 0,
    user_remaing_cnt: 0,
    document_allotted_cnt: 0,
    document_remaing_cnt: 0,
    e_signature_allotted_cnt: 0,
    e_signature_remaing_cnt: 0,
    automagic_on: 0,
    manual_on: 0,
    preset_on: 0,
    aadhar_on: 0,
    aadhar_quota: 0,
    template_allotted_cnt: 0,
    template_remaing_cnt: 0,
    custom_template_on: 0,
    custom_template_allotted_cnt: 0,
    custom_template_remaing_cnt: 0,
    legal_template_on: 0,
    legal_template_allotted_cnt: 0,
    legal_template_remaing_cnt: 0,
    ai_template_on: 0,
    ai_template_allotted_cnt: 0,
    ai_template_remaing_cnt: 0,
    lawyer_partner_network_on: 0,
    quoqo_legecy_integration_on: 0,    
    total_original_user_qty: 0,
    total_remaining_user_quantity: 0,
    seal_and_stamp_on: 0,
    e_stamp_attachment_on: 0,
    workflow_on: 0,
    email_notify_on: 0,
    sms_email_reminder_on: 0,
    contact_book_on: 0,
    document_search_on: 0,
    account_billing_management_on: 0,
    multiple_user_on: 0,
    admin_login_on: 0,
    google_microsoft_login_on: 0,
    Qcm_on: 0,
    nda_review_on: 0,
    deligence_on: 0,
    Transaction_no: 0,
    rbac_on: 0,
  });

  const [isValidCompany, setIsValidCompany] = useState(false);

  const updatePermissionList01 = (postData) => {
    if (postData) {
      setPermissionList({
        ...permissionList,
        is_page_loaded: 1,
        user_allotted_cnt: postData.user_allotted_cnt,
        user_remaing_cnt: postData.user_remaing_cnt,
        document_allotted_cnt: postData.document_allotted_cnt,
        document_remaing_cnt: postData.document_remaing_cnt,
        e_signature_allotted_cnt: postData.e_signature_allotted_cnt,
        e_signature_remaing_cnt: postData.e_signature_remaing_cnt,
        automagic_on: postData.automagic_on,
        manual_on: postData.manual_on,
        preset_on: postData.preset_on,
        aadhar_on: postData.aadhar_on,
        aadhar_quota: postData.aadhar_quota,
        template_allotted_cnt: postData.template_allotted_cnt,
        template_remaing_cnt: postData.template_remaing_cnt,
        custom_template_on: postData.custom_template_on,
        custom_template_allotted_cnt: postData.custom_template_allotted_cnt,
        custom_template_remaing_cnt: postData.custom_template_remaing_cnt,
        legal_template_on: postData.legal_template_on,
        legal_template_allotted_cnt: postData.legal_template_allotted_cnt,
        legal_template_remaing_cnt: postData.legal_template_remaing_cnt,
        ai_template_on: postData.ai_template_on,
        ai_template_allotted_cnt: postData.ai_template_allotted_cnt,
        ai_template_remaing_cnt: postData.ai_template_remaing_cnt,
        lawyer_partner_network_on: postData.lawyer_partner_network_on,
        quoqo_legecy_integration_on: postData.quoqo_legecy_integration_on,
      });
    }
  };

  const updatePermissionList = async (postData = {}) => {
   
    const authTokens = localStorage.getItem("authTokens")
      ? JSON.parse(localStorage.getItem("authTokens"))
      : null;
    const userQuota = await axios.get(
      `${process.env.REACT_APP_BACKEND_BASE_URL}/user/quota/`,
      {
        headers: {
          Authorization: `Bearer ${authTokens?.access}`,
          "Content-Type": "application/json",
        },
      }
    );
    if(userQuota.data && userQuota.data.UserQuota){
        const postData = userQuota.data.UserQuota;
        setPermissionList({
            ...permissionList,
            is_page_loaded: 1,
            user_allotted_cnt: postData.user_allotted_cnt,
            user_remaing_cnt: postData.user_remaing_cnt,
            document_allotted_cnt: postData.document_allotted_cnt,
            document_remaing_cnt: postData.document_remaing_cnt,
            e_signature_allotted_cnt: postData.e_signature_allotted_cnt,
            e_signature_remaing_cnt: postData.e_signature_remaing_cnt,
            automagic_on: postData.automagic_on,
            manual_on: postData.manual_on,
            preset_on: postData.preset_on,
            aadhar_on: postData.aadhar_on,
            aadhar_quota: postData.aadhar_quota,
            template_allotted_cnt: postData.template_allotted_cnt,
            template_remaing_cnt: postData.template_remaing_cnt,
            custom_template_on: postData.custom_template_on,
            custom_template_allotted_cnt: postData.custom_template_allotted_cnt,
            custom_template_remaing_cnt: postData.custom_template_remaing_cnt,
            legal_template_on: postData.legal_template_on,
            legal_template_allotted_cnt: postData.legal_template_allotted_cnt,
            legal_template_remaing_cnt: postData.legal_template_remaing_cnt,
            ai_template_on: postData.ai_template_on,
            ai_template_allotted_cnt: postData.ai_template_allotted_cnt,
            ai_template_remaing_cnt: postData.ai_template_remaing_cnt,
            lawyer_partner_network_on: postData.lawyer_partner_network_on,
            quoqo_legecy_integration_on: postData.quoqo_legecy_integration_on,
            total_original_user_qty: postData.total_original_user_qty,
            total_remaining_user_quantity: postData.total_remaining_user_quantity,
            seal_and_stamp_on: postData.seal_and_stamp_on,
            e_stamp_attachment_on: postData.e_stamp_attachment_on,
            workflow_on: postData.workflow_on,
            email_notify_on: postData.email_notify_on,
            sms_email_reminder_on: postData.sms_email_reminder_on,
            contact_book_on: postData.contact_book_on,
            document_search_on: postData.document_search_on,
            account_billing_management_on: postData.account_billing_management_on,
            multiple_user_on: postData.multiple_user_on,
            admin_login_on: postData.admin_login_on,
            google_microsoft_login_on: postData.google_microsoft_login_on,
            Qcm_on: postData.Qcm_on,
            nda_review_on: postData.nda_review_on,
            deligence_on: postData.deligence_on,
            Transaction_no: postData.Transaction_no,
            rbac_on: postData.rbac_on,
            
        })
    }
  };

  const getComapnyInfoApi = async () => {
    let isValidDetail = true;
    const response = await api.get(
      `${process.env.REACT_APP_BACKEND_BASE_URL}/user/detail/`,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    if (response.data) {
      let companyInfo = response.data.company_id;

      if (
        companyInfo.country_id == "" ||
        companyInfo.country_id == null ||
        companyInfo.state == "" ||
        companyInfo.state == null ||
        companyInfo.city == "" ||
        companyInfo.city == null ||
        companyInfo.entity == "" ||
        companyInfo.entity == null
      ) {
        isValidDetail = false;
      }
    }

    if (response.error) {
      isValidDetail = false;
    }
    setIsValidCompany(isValidDetail);
    return isValidDetail;
  };

  useEffect(() => {
    if (
      permissionList.is_page_loaded === 0 &&
      localStorage.getItem("userPermissions")
    ) {
      setPermissionList(JSON.parse(localStorage.getItem("userPermissions")));
    } else if (permissionList.is_page_loaded === 0) {
      setPermissionList({ ...permissionList, is_page_loaded: 1 });
      localStorage.setItem("userPermissions", JSON.stringify(permissionList));
    } else {
      localStorage.setItem("userPermissions", JSON.stringify(permissionList));
    }
  }, [permissionList]);

  const contextData = {
    permissionList,
    setPermissionList,
    updatePermissionList,
    isValidCompany,
    getComapnyInfoApi,
  };
  return (
    <PermissionContext.Provider value={contextData}>
      {children}
    </PermissionContext.Provider>
  );
};
