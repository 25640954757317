import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import CommonModulelegacy from './CommonComponentlegacy';
import useAxios from '../../hooks/useAxios';
import { resolve } from '../../api/resolve';
import { userTableColumns } from '../../constants/constants';
import { useTranslation } from 'react-i18next';

const Reviewerslegacy = () => {
  const api = useAxios();
  const { t } = useTranslation();
  const [users, setUsers] = useState([]);
  const [nextPage, setIsNextPage] = useState(null);
  const [prevPage, setIsPrevPage] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [errMsg, setErrMsg] = useState('');
  const [pageLoading, setPageLoading] = useState(false);
  const [sortingOrder, setSortingOrder] = useState('desc');
  const [sortColumn, setSortColumn] = useState('created_at');

  const getUsers = async () => {
    const res = await resolve(
      api
        .get(
          `/legacy_user/reviewer/view_create/?ordering=${sortColumn}&page=${currentPage}`
        )
        .then((res) => res.data)
    );
    if (res.data) {
      const { results, next, previous } = res.data;
      const filterData = [];
      if (results.length > 0) {
        results.forEach((result) => {
          const userObj = {
            first_name: result.first_name,
            dateCreated: dayjs(result.created_at).format('D MMMM YYYY'),
            status: result.is_active_user ? 'Active' : 'In-Active',
            email: result.email,
            confirm_email: result.email,
            company_id: result.company_id,
            company_name: result.company_name,
            id: result.id,
            is_active_user: result.is_active_user,
            phone: result.phone,
            position: result.position,
          };
          filterData.push(userObj);
        });
      } else {
        setErrMsg(t('allModule.No users added yet'));
      }
      setUsers([...filterData]);
      setIsNextPage(next);
      setIsPrevPage(previous);
    }
    setPageLoading(false);
  };

  useEffect(() => {
    getUsers();
  }, [currentPage, sortColumn]);
  return (
    <CommonModulelegacy
      heading='Reviewerslegacy'
      inputPlaceholder={t('allModule.Enter user name here')}
      addBtnText={t('allModule.Add New Reviewer')}
      errMsg={errMsg}
      moduleData={users}
      moduleTableColumns={userTableColumns}
      currentPage={currentPage}
      setCurrentPage={setCurrentPage}
      isCompany={false}
      nextPage={nextPage}
      prevPage={prevPage}
      activeLinkTitle='ReviewerFormlegacy'
      pageLoading={pageLoading}
      sortingOrder={sortingOrder}
      setSortingOrder={setSortingOrder}
      setSortColumn={setSortColumn}
      getModuleData={getUsers}
    />
  );
};

export default Reviewerslegacy;
