import React, { useEffect, useState, useContext } from "react";
import dayjs from "dayjs";
import Select from "react-select";
import { resolve } from "../../api/resolve";
import useAxios from "../../hooks/useAxios";
import { contractTableColumns } from "../../constants/constants";
import LoadingScreen from "../loader/LoadingScreen";
import Search from "../../assets/icons/Search.svg";
import CloseIcon from "../../assets/icons/Close.svg";
import ContractsTablelegacy from "./ContractsTablelegacy";
import ViewContractlegacy from "./ViewContractlegacy";
import AppContext from "../../context/AppContext";
import AuthContext from "../../context/AuthContext";
import AdvanceSearchModal from "../modallegacy/AdvanceSearchModallegacy";
import AddReminderModal from "../modallegacy/AddReminderModallegacy";
import { customStyles, dropDownStyles } from "../../constants/styles";
import DeleteModal from "../modallegacy/DeleteModallegacy";
import { useTranslation } from "react-i18next";

const SearchContractComponentlegacy = () => {
  const api = useAxios();
  const { t } = useTranslation();
  const {
    repoName,
    contractsHeading,
    isRepoList,
    setRepoName,
    setContractsHeading,
    setIsRepoList,
    routedSearchID,
    setRoutedSearchID,
    repoID,
    setRepoID,
    getNotificationsCount,
  } = useContext(AppContext);
  const { user } = useContext(AuthContext);
  const [searchText, setSearchText] = useState("");
  const [advanceSearchText, setAdvanceSearchText] = useState("");
  const [errMsg, setErrMsg] = useState("");
  const [nextPage, setIsNextPage] = useState(null);
  const [prevPage, setIsPrevPage] = useState(null);
  const [pageLimit, setPageLimit] = useState(5);
  const [contractCount, setContractCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [sortingOrder, setSortingOrder] = useState("desc");
  const [sortColumn, setSortColumn] = useState("-created_at");
  const [contracts, setContracts] = useState([]);
  const [pageLoading, setPageLoading] = useState(true);
  const [selectedContract, setSelectedContract] = useState({
    file_url: "",
    doc_name: "",
    converted_file_url: "",
    id: "",
    contract_clause: "",
  });
  const [isAdvanceSearch, setIsAdvanceSearch] = useState(false);
  const [showAdvanceSearchData, setShowAdvanceSearchData] = useState(false);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [dropDownData, setDropDownData] = useState({
    selectedType: "",
    selectedClause: "",
    selectedTag: "",
  });
  const [multiClauseData, setMultiClauseData] = useState([]);
  const [contractClauses, setContractClauses] = useState([]);
  const [typesData, setTypesData] = useState([]);
  const [clausesData, setClausesData] = useState([]);
  const [tagsData, setTagsData] = useState([]);
  const [isRemainderOpen, setIsRemainderOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [showClearIcon, setShowClearIcon] = useState(false);
  const [repos, setRepos] = useState([{ value: "All", label: "All" }]);
  const [isRenewalAbsent, setIsRenewalAbsent] = useState(false);
  const [showDrawer, setShowDrawer] = useState(true);
  const getContracts = async () => {
    if (user.user_type == "user") setShowDrawer(false);
    setIsPrevPage(null);
    setIsNextPage(null);
    const res = isRepoList
      ? await resolve(
          api
            .get(
              `legacy_contract/get_contract_repos/${repoID}?ordering=${sortColumn}&page=${currentPage}&page_size=${pageLimit}`
            )
            .then((res) => res.data)
        )
      : await resolve(
          api
            .get(
              `legacy_contract/view_create/?ordering=${sortColumn}&page=${currentPage}&page_size=${pageLimit}`
              // `contract/view/?skip=${
              //   (currentPage - 1) * pageLimit
              // }&limit=${pageLimit}&ordering=${sortColumn}`
            )
            .then((res) => res.data)
        );
    if (res.data) {
      const { results, next, previous, count } = res.data;
      const filterData = [];
      if (results.length > 0) {
        setContractCount(count);
        results.forEach((result) => {
          if (user.user_type === "user" && result.uploaded_by === user.user_id)
            setShowDrawer(true);
          const contractObj = {
            name: result.name || result.doc_name,
            created_at: dayjs(result.created_at).format("DD MMMM YYYY"),
            tags: result.tags,
            type: result.type,
            updated_at: dayjs(result.updated_at).format("D MMMM YYYY"),
            renewal_date: result.renewal_date
              ? dayjs(result.renewal_date).format("DD MMMM YYYY")
              : "Not Added",
            company_id: result.company_id,
            id: result.id,
            converted_file_url: result.converted_file_url,
            file_url: result.file_url,
            doc_name: result.doc_name,
            reminder_days: result.remainder_days,
            reminder_date: result.remainder_date,
            uploaded_by: result.uploaded_by,
            uploaded_user_name: result.uploaded_user_name,
            path: result.path,
          };
          filterData.push(contractObj);
        });
      } else {
        if (currentPage === 1)
          setErrMsg(t("adminDashboard.No contracts added yet"));
        else setErrMsg(t("adminDashboard.No more contracts to view"));
      }
      setContracts([...filterData]);
      setSelectedContract({
        ...selectedContract,
        file_url: filterData.length > 0 && filterData[0].file_url,
        doc_name: filterData.length > 0 && filterData[0].doc_name,
        converted_file_url:
          filterData.length > 0 && filterData[0].converted_file_url,
        id: filterData.length > 0 && filterData[0].id,
        contract_clause: "",
      });
      setIsNextPage(next);
      setIsPrevPage(previous);
    } else {
      setIsNextPage(null);
    }
    setPageLoading(false);
  };
  const searchContractlegacy = async ({ searchText, searchID, isPrimary }) => {
    // console.log("searchID",searchID);
    if (isPrimary) {
      setCurrentPage(1);
      setPageLimit(5);
    }
    if (searchText?.trim().length > 0 || searchID) {
      const res = isRepoList
        ? await resolve(
            api
              .get(
                `/legacy_contract/get_contract_repos/${repoID}?search=${searchText}`
              )
              .then((res) => res.data)
          )
        : routedSearchID
        ? await resolve(
            api
              .get(`/legacy_contract/retrieve_update/${searchID}`)
              .then((res) => res.data)
          )
        : await resolve(
            api
              .get(
                // `/contract/view_create/?search=${searchText}`
                `/legacy_contract/view_create/?search=${searchText}&ordering=${sortColumn}&page=${
                  isPrimary ? 1 : currentPage
                }&page_size=${isPrimary ? 5 : pageLimit}`
                // `/contract/view/?search=${searchText}&ordering=${sortColumn}&skip=${
                //   (currentPage - 1) * pageLimit
                // }&limit=${pageLimit}`
              )
              .then((res) => res.data)
          );
      console.log("res.data", res.data, searchID);
      if (res.data) {
        const { results, next, previous, count } = res.data;
        const filterData = [];
        if (results?.length > 0) {
          setContractCount(count);
          results.forEach((result) => {
            const contractObj = {
              name: result.name || result.doc_name,
              created_at: dayjs(result.created_at).format("D MMMM YYYY"),
              tags: result.tags,
              type: result.type,
              updated_at: dayjs(result.updated_at).format("D MMMM YYYY"),
              renewal_date: result.renewal_date
                ? dayjs(result.renewal_date).format("DD MMMM YYYY")
                : "Not Added",
              company_id: result.company_id,
              id: result.id,
              converted_file_url: result.converted_file_url,
              file_url: result.file_url,
              doc_name: result.doc_name,
              reminder_days: result.remainder_days,
              reminder_date: result.remainder_date,
              uploaded_by: result.uploaded_by,
              uploaded_user_name: result.uploaded_user_name,
              path: result.path,
            };
            filterData.push(contractObj);
          });
        } else if (res.data.count === 0) {
          setErrMsg(t("allModule.No Contract found"));
        }
        let routedSearchObj = !results && {
          name: res.data.name || res.data.doc_name,
          created_at: dayjs(res.data.created_at).format("D MMMM YYYY"),
          tags: res.data.tags,
          type: res.data.type,
          updated_at: dayjs(res.data.updated_at).format("D MMMM YYYY"),
          renewal_date: res.data.renewal_date
            ? dayjs(res.data.renewal_date).format("DD MMMM YYYY")
            : "Not Added",
          company_id: res.data.company_id,
          id: res.data.id,
          converted_file_url: res.data.converted_file_url,
          file_url: res.data.file_url,
          doc_name: res.data.doc_name,
          reminder_days: res.data.remainder_days,
          reminder_date: res.data.remainder_date,
          uploaded_user_name: res.data.uploaded_user_name,
          path: res.data.path,
        };
        setContracts(results ? [...filterData] : [routedSearchObj]);
        setSelectedContract({
          ...selectedContract,
          file_url: results
            ? filterData.length > 0 && filterData[0].file_url
            : res.data.file_url,
          doc_name: results
            ? filterData.length > 0 && filterData[0].doc_name
            : res.data.doc_name,
          converted_file_url: results
            ? filterData.length > 0 && filterData[0].converted_file_url
            : res.data.converted_file_url,
          id: filterData.length > 0 && filterData[0].id,
        });
        if (routedSearchObj) {
          setSelectedContract({
            ...selectedContract,
            file_url: routedSearchObj.file_url,
            doc_name: routedSearchObj.doc_name,
            converted_file_url: routedSearchObj.converted_file_url,
            id: routedSearchObj.id,
          });
        }
        setIsNextPage(next);
        setIsPrevPage(previous);
      } else if (res.error) {
        setErrMsg(t("allModule.No Contract found"));
      }
    } else {
      getContracts();
    }
    setPageLoading(false);
  };
  const getAdvancedSearchData = async (isPrimary) => {
    if (isPrimary) {
      setCurrentPage(1);
      setPageLimit(5);
    }
    let isEmptyObj = true;
    const advanceSearchObj = {
      search_term: advanceSearchText,
      clause: dropDownData.selectedClause,
      tag_list: dropDownData.selectedTag,
      contract_type: dropDownData.selectedType,
      start_date: startDate,
      end_date: endDate,
    };
    Object.values(advanceSearchObj).forEach((i) => {
      if (i) {
        isEmptyObj = false;
        return;
      }
    });
    if (!isEmptyObj) {
      setPageLoading(true);
      setIsAdvanceSearch(false);
      const res = await resolve(
        api
          .post(
            `/legacy_contract/advance_search/?page=${
              isPrimary ? 1 : currentPage
            }&page_size=${isPrimary ? 5 : pageLimit}`,
            JSON.stringify(advanceSearchObj),
            {
              headers: {
                "Content-Type": "application/json",
              },
            }
          )
          .then((res) => res.data)
      );
      if (res.data) {
        const { results, next, previous, count } = res.data;
        setContractCount(count);
        const filterData = [];
        if (results.length > 0) {
          results.forEach((result) => {
            const contractObj = {
              name: result.name || result.doc_name,
              created_at: dayjs(result.created_at).format("DD MMMM YYYY"),
              tags: result.tags,
              type: result.type,
              updated_at: dayjs(result.updated_at).format("D MMMM YYYY"),
              renewal_date: result.renewal_date
                ? dayjs(result.renewal_date).format("DD MMMM YYYY")
                : "Not Added",
              company_id: result.company_id,
              id: result.id,
              converted_file_url: result.converted_file_url,
              file_url: result.file_url,
              doc_name: result.doc_name,
              uploaded_by: result.uploaded_by,
              uploaded_user_name: result.uploaded_user_name,
              path: result.path,
            };
            filterData.push(contractObj);
          });
          setSelectedContract({
            ...selectedContract,
            file_url: filterData.length > 0 && filterData[0].file_url,
            doc_name: filterData.length > 0 && filterData[0].doc_name,
            converted_file_url:
              filterData.length > 0 && filterData[0].converted_file_url,
            id: filterData.length > 0 && filterData[0].id,
            contract_clause: dropDownData.selectedClause,
            search_term: advanceSearchText,
          });
        } else {
          setErrMsg(t("allModule.No Contract found"));
        }
        setContracts([...filterData]);
        setShowAdvanceSearchData(true);
        setIsNextPage(next);
        setIsPrevPage(previous);
      }
      // setDropDownData({
      //   ...dropDownData,
      //   selectedType: '',
      //   selectedClause: '',
      //   selectedTag: '',
      // });
      setPageLoading(false);
    }
  };
  const getRepos = async () => {
    const res = await resolve(
      api.get(`legacy_contract/get_all_repos/`).then((res) => res.data)
    );
    if (res.data) {
      const filterData = [];
      if (res.data && res.data.length > 0) {
        res.data.forEach((result) => {
          const contractObj = {
            value: result.repo_name,
            label: result.repo_name,
            id: result.id,
          };
          filterData.push(contractObj);
        });
      } else {
        setErrMsg(t("allModule.No repositories added yet"));
      }
      setRepos([...repos, ...filterData]);
    }
  };

  const deleteContract = async () => {
    setPageLoading(true);
    const res = await resolve(
      api
        .delete(`legacy_contract/delete_contract/${selectedContract.id}`)
        .then((res) => res.data)
    );
    if (res.data) {
      if (res.data.success) {
        getContracts();
      }
    }
    setIsDeleteModalOpen(false);
    setPageLoading(false);
  };

  useEffect(() => {
    let hubspot = document.querySelector("#hubspot-messages-iframe-container");
    if (hubspot) {
      hubspot.style.visibility = "hidden";
    }
    if (searchText.length > 0) {
      searchContractlegacy({ searchText });
    } else if (showAdvanceSearchData) {
      getAdvancedSearchData();
    } else {
      if (!routedSearchID) getContracts();
    }
    return () => {
      // setIsRepoList(false);
      setRepoName("");
      setRoutedSearchID(null);
      if (hubspot) {
        hubspot.style.visibility = "visible";
      }
    };
  }, [currentPage, sortColumn, pageLimit]);

  useEffect(() => {
    if (currentPage === 1) {
      getNotificationsCount();
    }
    return () => {
      if (!isRepoList) setIsRepoList(false);
    };
  }, [currentPage]);

  // useEffect(() => {
  //   if (searchText.length > 0) {
  //     searchContract({ searchText });
  //   } else if (showAdvanceSearchData) {
  //     getAdvancedSearchData();
  //   } else {
  //     getContracts();
  //   }
  // }, [pageLimit]);

  useEffect(() => {
    if (searchText?.trim().length > 0 || routedSearchID) {
      searchContractlegacy({ searchText, searchID: routedSearchID });
    } else if (repoName) getContracts();
  }, [repoName]);

  useEffect(() => {
    getRepos();
    if (routedSearchID) {
      searchContractlegacy({ searchID: routedSearchID });
    }
  }, [routedSearchID]);

  if (pageLoading) {
    return <LoadingScreen />;
  }
  return (
    <div className="text-primary">
      <h1 className="text-4xl font-bold">{contractsHeading}</h1>
      <form>
        <div className="flex mt-3 search_desi">
          <Select
            options={repos}
            placeholder={t("allModule.Select Repository")}
            styles={dropDownStyles}
            isSearchable={true}
            className="w-3/12 text-primary"
            onChange={(repo) => {
              setCurrentPage(1);
              setPageLimit(5);
              setRepoName(repo.value);
              setRepoID(repo.id);
              if (repo.value !== "All") setIsRepoList(true);
              else setIsRepoList(false);
            }}
            defaultValue={repos[0]}
          />
          <div className="flex justify-between items-center border-2 border-primary rounded-md rounded-tl-lg rounded-bl-lg bg-inputBackground px-2 mr-2 w-1/3 ml-2">
            <input
              placeholder={t("allModule.Enter Contract Name here")}
              className="px-1 py-1 focus:outline-none bg-transparent w-11/12"
              value={searchText}
              onChange={(e) => {
                setSearchText(e.target.value);
                setShowClearIcon(true);
                if (!e.target.value.trim()) {
                  setShowClearIcon(false);
                }
              }}
            />
            {showClearIcon && (
              <img
                src={CloseIcon}
                width={14}
                className="cursor-pointer"
                onClick={() => {
                  setSearchText("");
                  getContracts();
                  setShowClearIcon(false);
                }}
              />
            )}
          </div>
          <button
            type="submit"
            className="flex bg-primary text-white  px-4 rounded-lg items-center justify-between"
            onClick={(e) => {
              e.preventDefault();
              searchContractlegacy({ searchText, isPrimary: true });
            }}
          >
            <img src={Search} style={{ width: "1rem" }} />
            <span className="font-semibold ml-1">{t("allModule.Search")}</span>
          </button>
        </div>
      </form>
      <div
        className="my-1 inline-block"
        onClick={() => setIsAdvanceSearch(true)}
      >
        {/* <input type='radio' className='cursor-pointer' checked={false} /> */}
        <label className="font-semibold ml-1 cursor-pointer">
          {t("allModule.Advanced Search")}
        </label>
      </div>
      <div className="mt-3 rounded-lg">
        {contracts.length < 1 && (
          <div className="flex">
            <p className="text-red-500 text-sm font-medium">{errMsg}</p>
            {showAdvanceSearchData && (
              <button
                className="ml-3 w-14 bg-primary rounded-lg text-white text-sm space-x-1"
                onClick={() => {
                  setShowAdvanceSearchData(false);
                  getContracts();
                }}
              >
                <b>X</b> {t("common.Clear")}
              </button>
            )}
          </div>
        )}

        <ContractsTablelegacy
          contractTableColumns={contractTableColumns}
          contractsData={contracts}
          nextPage={nextPage}
          prevPage={prevPage}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          sortingOrder={sortingOrder}
          setSortingOrder={setSortingOrder}
          setSortColumn={setSortColumn}
          selectedContract={selectedContract}
          setSelectedContract={setSelectedContract}
          setIsRemainderOpen={setIsRemainderOpen}
          setIsDeleteModalOpen={setIsDeleteModalOpen}
          pageLimit={pageLimit}
          setPageLimit={setPageLimit}
          contractCount={contractCount}
          setContractCount={setContractCount}
          showAdvanceSearchData={showAdvanceSearchData}
          setShowAdvanceSearchData={setShowAdvanceSearchData}
          getContracts={getContracts}
          setIsRenewalAbsent={setIsRenewalAbsent}
          showDrawer={showDrawer}
          setPageLoading={setPageLoading}
          setAdvanceSearchText={setAdvanceSearchText}
        />
      </div>
      {contracts.length > 0 && (
        <ViewContractlegacy
          selectedContract={selectedContract}
          setSelectedContract={setSelectedContract}
          contractClauses={contractClauses}
          setContractClauses={setContractClauses}
          setMultiClauseData={setMultiClauseData}
          multiClauseData={multiClauseData}
          advanceSearchText={advanceSearchText}
        />
      )}
      <AddReminderModal
        modalIsOpen={isRemainderOpen}
        setIsOpen={setIsRemainderOpen}
        getContracts={getContracts}
        isRenewalAbsent={isRenewalAbsent}
      />
      <DeleteModal
        deleteContract={deleteContract}
        isDeleteModalOpen={isDeleteModalOpen}
        setIsDeleteModalOpen={setIsDeleteModalOpen}
        title={t("allModule.Delete Contracts")}
        bodyText={t(
          "common.This action cannot be undone. Are you sure you want to continue?"
        )}
      />
      <AdvanceSearchModal
        modalIsOpen={isAdvanceSearch}
        setIsOpen={setIsAdvanceSearch}
        advanceSearchText={advanceSearchText}
        setAdvanceSearchText={setAdvanceSearchText}
        startDate={startDate}
        endDate={endDate}
        setStartDate={setStartDate}
        setEndDate={setEndDate}
        dropDownData={dropDownData}
        setDropDownData={setDropDownData}
        setContracts={setContracts}
        setPageLoading={setPageLoading}
        typesData={typesData}
        setTypesData={setTypesData}
        clausesData={clausesData}
        setClausesData={setClausesData}
        tagsData={tagsData}
        setTagsData={setTagsData}
        setErrMsg={setErrMsg}
        selectedContract={selectedContract}
        setSelectedContract={setSelectedContract}
        setIsNextPage={setIsNextPage}
        setIsPrevPage={setIsPrevPage}
        setContractCount={setContractCount}
        getAdvancedSearchData={getAdvancedSearchData}
      />
    </div>
  );
};

export default SearchContractComponentlegacy;
