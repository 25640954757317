import React, { useContext } from "react";
import Modal from "react-modal";
import "./modal.css";
import CloseIcon from "../../assets/icons/Close.svg";
import AuthContext from "../../context/AuthContext";
import AppContext from "../../context/AppContext";
import FormContext from "../../context/FormContext";
import { useTranslation } from "react-i18next";

const customStyles = {
  content: {
    top: "40%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    maxWidth: "400px",
    maxHeight: "200px",
    borderRadius: "11px",
    minWidth: "300px",
  },
  overlay: {
    backgroundColor: "rgba(64, 8, 53, 0.63)",
  },
};

Modal.setAppElement("#root");
const AppModal = ({
  modalIsOpen,
  setIsOpen,
  title,
  bodyText,
  isLogin,
  activeLink,
  shouldShowRepoList,
  setContractObj,
  contractObj,
  setMultipleContractsObj,
  setIsContractModalOpen,
  modelAction,
  apiResStatus,
  deleteDocumentConfimed,
  aadhaarSigneeNConfirmation,
}) => {
  const { logoutUser } = useContext(AuthContext);
  const { setActiveLink, setIsRepoList } = useContext(AppContext);
  const { setIsEditing, shouldLogout } = useContext(FormContext); 
  const { t } = useTranslation();

  return (
    <div className="">
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={() => {
          if (modelAction !== "RecipientScreenIfAadhaar") {
            setIsOpen(false);
          }
        }}
        style={customStyles}
        contentLabel="Example Modal"
        onAfterClose={() => {
          shouldShowRepoList && setIsRepoList(true);
          activeLink?.length > 0 && setActiveLink(activeLink);
          if (setContractObj) {
            setContractObj({
              ...contractObj,
              name: "",
              type: "",
              tags: [],
              renewal_date: "",
              doc_name: "",
              document: "",
            });
            setMultipleContractsObj([]);
          }
          if (shouldLogout) {
            logoutUser();
            setActiveLink("Home");
          }
        }}
      >
        {/* <img
          src={CloseIcon}
          className='close-icon'
          onClick={() => setIsOpen(false)}
        /> */}
        <div className="text-center modal-container">
          <h1 className="text-primary font-bold text-lg">{title}</h1>
          <p className="text-primary font-normal text-md mt-2">{bodyText}</p>
          {isLogin && (
            <div className="flex justify-center mt-5">
              <button
                className="bg-primary hover:bg-secondary font-semibold text-white p-1 pl-8 pr-8 rounded-lg mr-5"
                onClick={() => {
                  setActiveLink("Home");
                  logoutUser();
                }}
              >
                {t("form.Log out")}
              </button>
              <button
                className="bg-[#606060] font-semibold text-white p-1 pl-8 pr-8 rounded-lg"
                onClick={() => setIsOpen(false)}
              >
                {t("common.Cancel")}
              </button>
            </div>
          )}

          {modelAction == "RecipientDelete" && (
            <div className="flex justify-center mt-5">
              <button
                className="bg-primary hover:bg-secondary font-semibold text-white p-1 pl-8 pr-8 rounded-lg mr-5"
                onClick={() => {
                  setIsOpen(false);
                  deleteDocumentConfimed();
                }}
              >
                {t("form.Confirm")}
              </button>
              <button
                className="bg-[#606060] font-semibold text-white p-1 pl-8 pr-8 rounded-lg"
                onClick={() => setIsOpen(false)}
              >
                {t("common.Cancel")}
              </button>
            </div>
          )}

          {modelAction == "ContactBook" && (
            <button
              className="bg-primary font-semibold text-white p-1 mt-3 rounded-lg w-2/3"
              onClick={() => {
                if (apiResStatus == true) {
                  setActiveLink("ContactBook");
                }
                setIsOpen(false);
              }}
            >
              {t("form.Ok")}
            </button>
          )}

          {modelAction == "GroupForm" && (
            <button
              className="bg-primary font-semibold text-white p-1 mt-3 rounded-lg w-2/3"
              onClick={() => {
                if (apiResStatus == true) {
                  setActiveLink("ContactBook");
                }
                setIsOpen(false);
              }}
            >
              {t("form.Ok")}
            </button>
          )}

          {modelAction == "AdminUserForm" && (
            <button
              className="bg-primary font-semibold text-white p-1 mt-3 rounded-lg w-2/3"
              onClick={() => {
                if (apiResStatus == true) {
                  setActiveLink("AdminUser");
                }
                setIsOpen(false);
              }}
            >
              {t("form.Ok")}
            </button>
          )}

          {modelAction == "AdminUserFormBySA" && (
            <button
              className="bg-primary font-semibold text-white p-1 mt-3 rounded-lg w-2/3"
              onClick={() => {
                if (apiResStatus == true) {
                  setIsEditing(true);
                  setActiveLink("DemoUserForm");                  
                }
                setIsOpen(false);
              }}
            >
              {t("form.Ok")}
            </button>
          )}

          {modelAction == "DemoUserForm" && (
            <button
              className="bg-primary font-semibold text-white p-1 mt-3 rounded-lg w-2/3"
              onClick={() => {
                if (apiResStatus == true) {
                  setActiveLink("DemoUserList");
                }
                setIsOpen(false);
              }}
            >
              {t("form.Ok")}
            </button>
          )}

          {modelAction == "RecipientScreen" && (
            <div className="flex justify-center mt-5">
              <button
                className="bg-primary hover:bg-secondary font-semibold text-white p-1 pl-8 pr-8 rounded-lg mr-5"
                onClick={() => {
                  setActiveLink("Wallet");
                  setIsOpen(false);
                }}
              >
                {t("form.Ok")}
              </button>
              <button
                className="bg-[#606060] font-semibold text-white p-1 pl-8 pr-8 rounded-lg"
                onClick={() => setIsOpen(false)}
              >
                {t("common.Cancel")}
              </button>
            </div>
          )}

          {modelAction == "RecipientScreenIfAadhaar" && (
            <div className="flex justify-center mt-5">
              <button
                className="bg-primary hover:bg-secondary font-semibold text-white p-1 pl-8 pr-8 rounded-lg mr-5"
                onClick={() => {
                  setIsOpen(false);
                  aadhaarSigneeNConfirmation();
                }}
              >
                {t("form.Ok")}
              </button>
            </div>
          )}

          {modelAction == "PlanForm" && (
            <button
              className="bg-primary font-semibold text-white p-1 mt-3 rounded-lg w-2/3"
              onClick={() => {
                if (apiResStatus == true) {
                  setActiveLink("SuperAdminPlanList");
                }
                setIsOpen(false);
              }}
            >
              {t("form.Ok")}
            </button>
          )}

          {modelAction == "LegalDocumentForm" && (
            <button
              className="bg-primary font-semibold text-white p-1 mt-3 rounded-lg w-2/3"
              onClick={() => {
                if (apiResStatus == true) {
                  setActiveLink("LegalDocumentList");
                }
                setIsOpen(false);
              }}
            >
              {t("form.Ok")}
            </button>
          )}

          {modelAction == "CompanyAuthorizationNotificaton" && (
            <button
              className="bg-primary font-semibold text-white p-1 mt-3 rounded-lg w-2/3"
              onClick={() => {
                setIsOpen(false);
              }}
            >
              {t("form.Ok")}
            </button>
          )}

          {modelAction == "ContractForm" && (
            <button
              className="bg-primary font-semibold text-white p-1 mt-3 rounded-lg w-2/3"
              onClick={() => {
                setIsContractModalOpen && setIsContractModalOpen(false);
                setIsOpen(false);
              }}
            >
              {t("form.Ok")}
            </button>
          )}

          {modelAction == "SectionForm" && (
            <button
              className="bg-primary font-semibold text-white p-1 mt-3 rounded-lg w-2/3"
              onClick={() => {
                if (apiResStatus == true) {
                  setActiveLink("ReviewSection");
                }
                setIsOpen(false);
              }}
            >
              {t("form.Ok")}
            </button>
          )}

          {modelAction == "SectionTaskForm" && (
            <button
              className="bg-primary font-semibold text-white p-1 mt-3 rounded-lg w-2/3"
              onClick={() => {
                if (apiResStatus == true) {
                  setActiveLink("ReviewSectionTask");
                }
                setIsOpen(false);
              }}
            >
              {t("form.Ok")}
            </button>
          )}

          {modelAction == "Office365SettingForm" && (
            <button
              className="bg-primary font-semibold text-white p-1 mt-3 rounded-lg w-2/3"
              onClick={() => {
                if (apiResStatus == true) {
                  setActiveLink("Office365SettingList");
                }
                setIsOpen(false);
              }}
            >
              {t("form.Ok")}
            </button>
          )}
        </div>
      </Modal>
    </div>
  );
};

export default AppModal;
