import React, { useContext, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import './navbar.css';
import navbarLogo from '../../assets/fusion-logo.png';
import Home from '../../assets/icons/Home.svg';
import Logout from '../../assets/icons/Logout.svg';
import SuperAdmin from './SuperAdmin';
import AdminUser from './AdminUser';
import AuthContext from '../../context/AuthContext';
import FormContext from '../../context/FormContext';
import AppContext from '../../context/AppContext';
import AppModal from '../modals/Modal';
import PermissionContext from '../../context/PermissionContext';
import DocumentContext from '../../context/DocumentContext';
import { useTranslation } from 'react-i18next';
// import TaskList from '../task_manager/TaskList';
// import task_icon from '../../assets/icons/task_icon.png'

const Navbar = ({ activeLink, setActiveLink }) => {
  const { user } = useContext(AuthContext);
  const { currentForm } = useContext(FormContext);
  const { showDropdown, setShowDropdown, activePrevLink, clickOnLeftMenu } = useContext(AppContext);
  const { permissionList, setPermissionList } = useContext(PermissionContext);
  const { recipientCount } = useContext(DocumentContext);
  const [modalIsOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <div className='bg-primary nav_coy'>
      <div className='logo-box'>
        <Link to='/dashboard'>
          <img src={navbarLogo} />
        </Link>
      </div>
      <div className='mt-10'>
        <ul className='main_menu '>
          <li
            onClick={() => {
              setActiveLink('Home')
              navigate('/dashboard')
            }}
            className={activePrevLink === 'Home' ? 'active-link' : undefined}
          >
            <a><img src={Home} />
              <span>{t('menu.Dashboard')}</span></a>
          </li>
         


          <>
            {user.user_type === 'super_admin' && (
              <SuperAdmin
                activeLink={activeLink}
                setActiveLink={setActiveLink}
                showDropdown={showDropdown}
                setShowDropdown={setShowDropdown}
                currentForm={currentForm}
                activePrevLink={activePrevLink}
                clickOnLeftMenu={clickOnLeftMenu}
              />
            )}



            {(user.user_type == 'user' || user.user_type === 'admin') && (
              <AdminUser
                activeLink={activeLink}
                setActiveLink={setActiveLink}
                showDropdown={showDropdown}
                setShowDropdown={setShowDropdown}
                user={user}
                currentForm={currentForm}
                permissionList={permissionList}
                setPermissionList={setPermissionList}
                recipientCount={recipientCount}
                activePrevLink={activePrevLink}

              />
            )}

          </>

          <li onClick={() => setIsOpen(true)}>
            <a><img src={Logout} />
              <span>{t('menu.Logout')}</span>
            </a>
          </li>
        </ul>
      </div>
      <AppModal
        setIsOpen={setIsOpen}
        modalIsOpen={modalIsOpen}
        isLogin={true}
        title={t('menu.Do you want to Log out?')}
      />
    </div>
  );
};

export default Navbar;
