import React, { useEffect, useContext, useState } from "react";
import LoginForm from "./LoginForm";
import Footer from "../footer/Footer";
import { appName } from "../../constants/constants";
import appLogo from "../../assets/fusion-logo.png";
import "./login.css";
import FormContext from "../../context/FormContext";
import AuthContext from "../../context/AuthContext";
import Modal from "react-modal";
import { validateEmail, validatePassword } from "../../utils/utils";
import LoadingScreen from "../loader/LoadingScreen";
import { googleLogout, useGoogleLogin } from "@react-oauth/google";
import axios from "axios";
import { autoCloseTimer, delay } from "../../constants/constants";
import { toast } from "react-toastify";
import MicrosoftLogin from "react-microsoft-login";
import PermissionContext from "../../context/PermissionContext";
import eye_off from "../../assets/icons/eye_off.png";
import eye_on from "../../assets/icons/eye_on.png";
import { useTranslation } from "react-i18next";
import AppContext from "../../context/AppContext";

const customStyles = {
  content: {
    right: "auto",
    bottom: "auto",
  },
};
const LoginUI = ({
  title,
  formErrors,
  onSubmit,
  msalInstance,
  onMsalInstanceChange,
}) => {
  const { language, setLanguage, langObj } = useContext(AppContext);
  const { t, i18n } = useTranslation();
  const [modalData, setModalData] = useState({ title: "" });
  const [signUpStep, setSignUpStep] = useState("");
  const [isShowPassword, setIsShowPassword] = useState(false);
  const [userSignUpObj, setUserSignUpObj] = useState({
    userEmailId: "",
    userName: "",
    userPassword: "",
    userAgreeTerm: false,
  });

  const [signUpFormErrors, setSignUpFormErrors] = useState({
    userEmailId_step1: "",
    userEmailId_step2: "",
    userName: "",
    userPassword: "",
    userAgreeTerm: "",
  });
  const [loading, setLoading] = useState(false);
  const baseURL = process.env.REACT_APP_BACKEND_BASE_URL;
  const { updatePermissionList } = useContext(PermissionContext);

  useEffect(() => {
    document.title = `${appName} | ${title}`;
  });
  const { setIsOpen, modalIsOpen } = useContext(FormContext);

  const { userSignUp, resentVerificationEmailApi, googleLoginUser } =
    useContext(AuthContext);

  const openModal = () => {
    setUserSignUpObj({
      userEmailId: "",
      userName: "",
      userPassword: "",
      userAgreeTerm: false,
    });
    setSignUpFormErrors({
      ...signUpFormErrors,
      userEmailId_step1: "",
      userEmailId_step2: "",
      userEmailId_step3: "",
      userName: "",
      userPassword: "",
    });

    setIsOpen(true);
    setModalData({
      ...modalData,
      title: t("loginPage.Signup for Qsign"),
    });
    setSignUpStep("step1");
  };
  const closeModal = () => {
    if (signUpStep == "step2") {
      setSignUpStep("step1");
    } else {
      setIsOpen(false);
    }
  };
  const afterOpenModal = () => {};
  const startTrial = () => {
    setSignUpFormErrors({
      ...signUpFormErrors,
      userEmailId_step1: "",
    });

    if (userSignUpObj.userEmailId.trim().length < 1) {
      setSignUpFormErrors({
        ...signUpFormErrors,
        userEmailId_step1: t("loginPage.Email Id is required"),
      });
    } else if (!validateEmail(userSignUpObj.userEmailId)) {
      setSignUpFormErrors({
        ...signUpFormErrors,
        userEmailId_step1: t("loginPage.Please enter a valid email!"),
      });
    } else {
      setModalData({
        ...modalData,
        title: t("loginPage.Signup for Qsign"),
      });
      setSignUpStep("step2");
    }
  };

  const signUpUser = async () => {
    for (const key in signUpFormErrors) {
      if (signUpFormErrors.hasOwnProperty(key)) {
        signUpFormErrors[key] = "";
      }
    }
    let tempErrors = { ...signUpFormErrors };

    var isValidForm = true;
    if (userSignUpObj.userEmailId.trim().length < 1) {
      tempErrors.userEmailId_step2 = t("loginPage.Email Id is required");
      isValidForm = false;
    } else if (!validateEmail(userSignUpObj.userEmailId)) {
      tempErrors.userEmailId_step2 = t("loginPage.Please enter a valid email!");
      isValidForm = false;
    }

    if (userSignUpObj.userName.trim().length < 1) {
      tempErrors.userName = t("loginPage.User name is required");
      isValidForm = false;
    }

    if (userSignUpObj.userPassword.trim().length < 8) {
      tempErrors.userPassword = t(
        "loginPage.Please provide a password of at least 8 characters"
      );
      isValidForm = false;
    } else if (!validatePassword(userSignUpObj.userPassword)) {
      tempErrors.userPassword = t(
        "loginPage.Password should contain : 1 Alphabet, 1 Number, 1 Special character, Minimum 8 characters"
      );
      isValidForm = false;
    }

    if (userSignUpObj.userAgreeTerm == false) {
      tempErrors.userAgreeTerm = t(
        "loginPage.Please agree to the terms and conditions"
      );
      isValidForm = false;
    }

    if (isValidForm) {
      setModalData({
        ...modalData,
        title: t("loginPage.Signup for Qsign"),
      });
      userSignUp(userSignUpObj, setSignUpStep, setLoading, setUserSignUpObj);
    } else {
      setSignUpFormErrors(tempErrors);
    }
  };

  const resendVerificationEmail = () => {
    setModalData({
      ...modalData,
      title: t("loginPage.Resend Verification Email"),
    });

    setSignUpStep("step4");
  };

  const sendVerificationEmailAgain = () => {
    setSignUpFormErrors({
      ...signUpFormErrors,
      userEmailId_step3: "",
    });

    if (userSignUpObj.userEmailId.trim().length < 1) {
      setSignUpFormErrors({
        ...signUpFormErrors,
        userEmailId_step3: t("loginPage.Email Id is required"),
      });
    } else if (!validateEmail(userSignUpObj.userEmailId)) {
      setSignUpFormErrors({
        ...signUpFormErrors,
        userEmailId_step3: t("loginPage.Please enter a valid email!"),
      });
    } else {
      resentVerificationEmailApi(userSignUpObj, setSignUpStep, setLoading);
    }
  };

  //////////////////////// google login //////////////////////////////////////////
  const googleLogin = useGoogleLogin({
    onSuccess: (user) => {
      closeModal();
      axios
        .get(
          `https://www.googleapis.com/oauth2/v1/userinfo?access_token=${user.access_token}`,
          {
            headers: {
              Authorization: `Bearer ${user.access_token}`,
              Accept: "application/json",
            },
          }
        )
        .then((res) => {
          const postData = {
            email: res.data.email,
            name: res.data.name,
          };
          googleLoginUser(postData, setLoading, updatePermissionList);
        })
        .catch((err) => {
          setLoading(false);
          toast.error(
            t("loginPage.Google login failed. Please try again later.")
          );
        });
    },
    onError: (error) => {
      closeModal();
      toast.error(t("loginPage.Google login failed. Please try again later."));
    },
  });

  //////////////////////// Microsoft Login login //////////////////////////////////////////
  const microsoftUserLogin = (err, data, msal) => {
    if (!err && data) {
      const postData = {
        email: data.account.username,
        name: data.account.name,
      };
      googleLoginUser(postData, setLoading, updatePermissionList);

      onMsalInstanceChange(msal);
    }
  };

  return (
    <>
      <div className="flex w-full h-screen login-container">
        <div className="bg-[#F5F2FA] login-form-container">
          <LoginForm
            formErrors={formErrors}
            onSubmit={onSubmit}
            googleLogin={googleLogin}
            microsoftUserLogin={microsoftUserLogin}
            MicrosoftLogin={MicrosoftLogin}
            openModal={openModal}
          />
        </div>
        <div className="login-content-container text-primary ">
          <div className="set_btn">
            <img src={appLogo} alt="app-logo" />
            <div>
              {/* <a href="#" onClick={openModal} className='btn btn_theme3 font-bold'>Signup for Qsign</a> */}
              {/* <a href='https://launch.quoqo.com/meetings/chetan12/quoqo-platform-demo-60-minutes'
                                target='_blank' className='btn btn_theme font-bold'>Request a Demo</a> */}
            </div>
          </div>

          <div className="login-language-dropdown mt-5">
            <span className="text-base font-bold mr-2">
              {t("common.Language")} :
            </span>
            <select
              className="border-2 border-primary rounded-lg outline-none p-0.5 pl-2"
              onChange={(e) => {
                i18n.changeLanguage(e.target.value);
                setLanguage(e.target.value);
              }}
              value={language}
            >
              {Object.keys(langObj).map((lng) => (
                <option key={lng} value={lng}>
                  {langObj[lng].nativeName}
                </option>
              ))}
            </select>
          </div>

          <p className="text-2xl mt-4">
            <strong>
              {t("landingPage.Quoqo Fusion")}
              {": "}&nbsp;
              {t(
                "landingPage.Advanced Contract Management and Document Solutions Powered by Generative AI."
              )}
            </strong>
            {t("")}
          </p>
          <div className="mt-3 flex flex-wrap gap-4">
            <div className="flex-1 min-w-[calc(50%-1rem)] landingdiv p-4 bg-[rgba(214,211,212,0.85)] h-60 rounded-md">
              <h2 className="text-2xl font-bold">
                {t("landingPage.Advanced AI-Driven Document Solutions")}
              </h2>
              <ul className="pl-6 mt-1 text-medium">
                <li>
                  <>{t("landingPage.Generative AI Document Management")}</>
                  {": "}&nbsp;
                  {t(
                    "landingPage.Efficient document creation and management with customizable templates and AI-driven search capabilities."
                  )}
                </li>
                <li>
                  <>{t("landingPage.AI-Powered Review and Analysis")}</>
                  {": "}&nbsp;
                  {t(
                    "landingPage.Conduct thorough reviews for due diligence, M&A, IP, and industry-specific requirements using advanced AI tools."
                  )}
                </li>
              </ul>
            </div>

            <div className="flex-1 min-w-[calc(50%-1rem)] landingdiv p-4 bg-[rgba(73,4,61,0.30)] h-60 rounded-md">
              <h2 className="text-2xl font-bold">
                {t("landingPage.Efficient Digital Signatures and Compliance")}
              </h2>
              <ul className="pl-6 mt-1 text-medium">
                <li>
                  {t("landingPage.QSign Integration")}
                  {" : "}&nbsp;
                  {t(
                    "landingPage.Seamlessly transition from wet signatures to electronic or digital signatures."
                  )}
                </li>
                <li>
                  {t("landingPage.Photo KYC Feature")}
                  {": "}&nbsp;
                  {t(
                    "landingPage.Simplify regulatory compliance with mobile device verification, eliminating manual form filling."
                  )}
                </li>
              </ul>
            </div>

            <div className="flex-1 min-w-[calc(50%-1rem)] landingdiv p-4 h-60 bg-[rgba(73,4,61,0.30)] rounded-md">
              <h2 className="text-2xl font-bold">
                {t("landingPage.Robust Workflow Management")}
              </h2>
              <ul className="pl-6 mt-1 text-medium">
                <li>
                  <>{t("landingPage.Workflow Optimization")}</>
                  {": "}&nbsp;
                  {t(
                    "landingPage.Automate internal approvals, external signatures, email dispatch, and obligation setting."
                  )}
                </li>
                <li>
                  <>{t("landingPage.Adaptive Workflows")}</>
                  {": "}&nbsp;
                  {t(
                    "landingPage.Tailored solutions for diverse document and contract management needs."
                  )}
                </li>
              </ul>
            </div>

            <div className="flex-1 min-w-[calc(50%-1rem)] landingdiv p-4 h-60 bg-[rgba(214,211,212,0.85)] rounded-md">
              <h2 className="text-2xl font-bold">
                {t("landingPage.Secure Enterprise Integration")}
              </h2>
              <ul className="pl-6 mt-1 text-medium">
                <li>
                  <>{t("landingPage.Enterprise-Grade Security")}</>
                  {": "}&nbsp;
                  {t(
                    "landingPage.SOC-2 compliant secure cloud infrastructure ensures data safety."
                  )}
                </li>
                <li>
                  <>{t("landingPage.Integration Capabilities")}</>
                  {": "}&nbsp;
                  {t(
                    "landingPage.Role-Based Access Control (RBAC) for seamless user management."
                  )}
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <Footer />

      <Modal
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="SignUp"
      >
        <div className="header_md">
          <h2>{modalData.title}</h2>
          <button onClick={closeModal}>X</button>
        </div>

        {/* /////////////////////////// start trail /////////////////////////// */}
        {signUpStep == "step1" && (
          <div className="form_design">
            {loading ? (
              <LoadingScreen />
            ) : (
              <>
                <div className="mb-3">
                  <img
                    src={appLogo}
                    style={{ width: "130px" }}
                    alt="app-logo"
                  />
                </div>
                <div className="mb-3" style={{ marginTop: "5%" }}>
                  {/* <label htmlFor="">Sign up for a free 7 day trial. Plans start at $#/mo</label> */}
                  <div className="d-flex">
                    <input
                      placeholder={t("loginPage.Your company email")}
                      value={userSignUpObj.userEmailId}
                      onChange={(e) => {
                        setUserSignUpObj({
                          ...userSignUpObj,
                          userEmailId: e.target.value,
                        });
                      }}
                    />
                    <button onClick={startTrial} className="btn btn_theme">
                      {t("loginPage.Start Trial")}
                    </button>
                  </div>
                  <p className="text-sm text-red-500">
                    {signUpFormErrors.userEmailId_step1}
                  </p>
                </div>
                {/* <p className='text-center'>
                                    or sign up quickly <a href="#" onClick={() => {
                                        googleLogin();
                                        closeModal();
                                    }}>with Google Authentication</a>
                                </p> */}
              </>
            )}
          </div>
        )}

        {/* /////////////////////////// user basic info /////////////////////////// */}
        {signUpStep == "step2" && (
          <>
            {loading ? (
              <LoadingScreen />
            ) : (
              <>
                <div className="mb-3 form_design">
                  <img
                    src={appLogo}
                    style={{ width: "130px" }}
                    alt="app-logo"
                  />
                </div>
                <div className="pl-5 pr-5 ">
                  {/* <div className='form_design'>
                                    <p className='font-normal ' style={{ marginLeft: '-15px' }}>
                                        Sign up quickly <a href="#" onClick={() => {
                                            googleLogin();
                                            closeModal();
                                        }}>with Google Authentication</a>, or fill in the form below:
                                    </p>
                                </div> */}
                  <form className="mt-5">
                    <div className="flex flex-col mb-3">
                      <label>
                        {t("common.Email Address")}{" "}
                        <span style={{ color: "red" }}>*</span>
                      </label>
                      <input
                        type="email"
                        placeholder={t("loginPage.Enter email address")}
                        value={userSignUpObj.userEmailId}
                        onChange={(e) => {
                          setUserSignUpObj({
                            ...userSignUpObj,
                            userEmailId: e.target.value,
                          });
                        }}
                        className="border-2 border-primary rounded-lg outline-none p-1 pl-2"
                      />
                      <p className="text-sm text-red-500">
                        {signUpFormErrors.userEmailId_step2}
                      </p>
                    </div>
                    <div className="flex flex-col mb-3">
                      <label>
                        {t("loginPage.Full Name")}{" "}
                        <span style={{ color: "red" }}>*</span>
                      </label>
                      <input
                        type="text"
                        value={userSignUpObj.userName}
                        placeholder={t("loginPage.Enter full Name")}
                        onChange={(e) => {
                          setUserSignUpObj({
                            ...userSignUpObj,
                            userName: e.target.value,
                          });
                        }}
                        className="border-2 border-primary rounded-lg outline-none p-1 pl-2"
                      />
                      <p className="text-sm text-red-500">
                        {signUpFormErrors.userName}
                      </p>
                    </div>
                    <div className="flex flex-col mb-3">
                      <label>
                        {t("loginPage.Choose a password")}{" "}
                        <span style={{ color: "red" }}>*</span>
                      </label>
                      <div className="password-container">
                        <input
                          type={isShowPassword ? "text" : "password"}
                          placeholder="Enter password"
                          value={userSignUpObj.userPassword}
                          onChange={(e) => {
                            if (
                              e.target.value.length >= 8 &&
                              validatePassword(e.target.value)
                            ) {
                              setSignUpFormErrors({
                                ...signUpFormErrors,
                                userPassword: "",
                              });
                              e.target.style.borderColor = "green";
                            } else {
                              e.target.style.borderColor = "red";
                            }
                            setUserSignUpObj({
                              ...userSignUpObj,
                              userPassword: e.target.value,
                            });
                          }}
                          className="border-2 border-primary rounded-lg outline-none p-1 pl-2 w-full"
                        />
                        <img
                          src={isShowPassword ? eye_on : eye_off}
                          onClick={() => setIsShowPassword(!isShowPassword)}
                          className="eye-icon"
                        />
                      </div>
                      <p className="text-sm text-red-500">
                        {signUpFormErrors.userPassword}
                      </p>
                    </div>
                    <div className="flex flex-col mb-3 ">
                      <div className="flex ">
                        <input
                          type="checkbox"
                          checked={userSignUpObj.userAgreeTerm}
                          onChange={(e) => {
                            if (e.target.checked == true) {
                              setSignUpFormErrors({
                                ...signUpFormErrors,
                                userAgreeTerm: "",
                              });
                            } else {
                              setSignUpFormErrors({
                                ...signUpFormErrors,
                                userAgreeTerm: t(
                                  "loginPage.Please agree to the terms and conditions"
                                ),
                              });
                            }
                            setUserSignUpObj({
                              ...userSignUpObj,
                              userAgreeTerm: e.target.checked,
                            });
                          }}
                        />
                        <span
                          style={{ padding: "5px" }}
                          className="form_design"
                        >
                          {" "}
                          <p>
                            <span style={{ color: "red" }}>*</span>{" "}
                            {t("loginPage.I have read and agree to Quoqos")}{" "}
                            <a href="#">{t("loginPage.Privacy Policy")}</a>{" "}
                            {t("loginPage.and")}{" "}
                            <a href="#">{t("loginPage.Terms of Service")}</a>.
                          </p>
                        </span>
                      </div>
                      <p className="text-sm text-red-500">
                        {signUpFormErrors.userAgreeTerm}
                      </p>
                    </div>

                    <hr />
                    <div
                      className="flex mb-3 signup-step-footer form_design "
                      style={{ justifyContent: "end" }}
                    >
                      {/* <p className='form_design'><a href="#"
                                            onClick={resendVerificationEmail}
                                        >Resend Verification Email</a></p> */}

                      <button
                        type="button"
                        className="btn btn_theme btn-continue"
                        onClick={signUpUser}
                      >
                        {t("loginPage.Continue")}
                      </button>
                    </div>
                  </form>
                </div>
              </>
            )}
          </>
        )}

        {/* /////////////////////////// account verification email screen  /////////////////////////// */}
        {signUpStep == "step3" && (
          <div className="signup-step3 pl-5 pr-5">
            <div className="content">
              <h1>{t("loginPage.Check your email!")}</h1>
              <p>
                {t("loginPage.We sent an account verification email to")}{" "}
                <b>{userSignUpObj.userEmailId}</b>.
              </p>
              <p>
                {t(
                  "loginPage.Please click the link in the email in order to verify your account and continue."
                )}
              </p>
              <p>{t("loginPage.You can close this page now.")}</p>
            </div>
            <hr />
            <div className="flex mb-3 form_design">
              <p className="">
                {t("loginPage.Did not get the email")}?
                <a href="#" onClick={resendVerificationEmail}>
                  {t("loginPage.Click here")}
                </a>
              </p>
            </div>
          </div>
        )}

        {/* /////////////////////////// Resend Verification Email screen  /////////////////////////// */}
        {signUpStep == "step4" && (
          <div className="pl-5 pr-5">
            {loading ? (
              <LoadingScreen />
            ) : (
              <>
                <p
                  className="font-normal form_design"
                  style={{ paddingLeft: 0 }}
                >
                  {t(
                    "loginPage.Did not receive a verification email? Lets try sending it again!"
                  )}
                </p>
                <form className="mt-5">
                  <div className="flex flex-col mb-3">
                    <label>
                      {t("loginPage.Your Email address")}{" "}
                      <span style={{ color: "red" }}>*</span>
                    </label>
                    <input
                      type="email"
                      value={userSignUpObj.userEmailId}
                      onChange={(e) => {
                        setUserSignUpObj({
                          ...userSignUpObj,
                          userEmailId: e.target.value,
                        });
                      }}
                      placeholder={t("loginPage.Enter email address")}
                      className="border-2 border-primary rounded-lg outline-none p-1 pl-2"
                    />
                    <p className="text-sm text-red-500">
                      {signUpFormErrors.userEmailId_step3}
                    </p>
                  </div>

                  <hr />
                  <div className="flex mb-3 signup-step-footer form_design">
                    <p>
                      <a href="#" onClick={startTrial}>
                        {t("loginPage.Go back")}
                      </a>
                    </p>

                    <button
                      type="button"
                      className="btn btn_theme btn-continue"
                      onClick={sendVerificationEmailAgain}
                    >
                      {t("loginPage.Send me the verification email again")}
                    </button>
                  </div>
                </form>
              </>
            )}
          </div>
        )}

        {/* /////////////////////////// Resend Verification Email success screen  /////////////////////////// */}
        {signUpStep == "step5" && (
          <div className="signup-step5 pl-5 pr-5">
            <div className="content">
              <p>
                {t("loginPage.Check your email at")}{" "}
                <b>{userSignUpObj.userEmailId}</b>.
              </p>
              <p>
                {t("loginPage.Make sure your check your SPAM folder as well!")}
              </p>
              <p>
                {t(
                  "loginPage.If you still dont receive the email, please email us at"
                )}{" "}
                support@quoqo.com.
              </p>
            </div>
            <hr />
            <div className="form_design text-right">
              <button
                type="button"
                className="btn btn_theme"
                onClick={closeModal}
              >
                {t("common.Close")}
              </button>
            </div>
          </div>
        )}
      </Modal>
    </>
  );
};

export default LoginUI;
