import React, { useState, useContext, useRef, useEffect } from "react";
import LoadingScreen from "../loader/LoadingScreen";

import upload_documents from "../../assets/icons/upload_documents.png";
import useAxios from "../../hooks/useAxios";
import AuthContext from "../../context/AuthContext";
import AppContext from "../../context/AppContext";
import DiligenceModal from "./DiligenceModal";
import { toast } from "react-toastify";
import { resolve } from "../../api/resolve";
import Download from "../../assets/icons/Download.svg";
import pending from "../../assets/icons/pending.png";
import { docStatus } from "../../constants/constants";
import FormContext from "../../context/FormContext";
import { use } from "i18next";
import { useTranslation } from "react-i18next";
import HelpIcon from "../../assets/icons/Help-sm.svg";
import ReactTooltip from "react-tooltip";
import PermissionContext from "../../context/PermissionContext";
import ma from "../../assets/icons/M&A_icon.png";

function truncateText(text, maxLength) {
  if (text.length <= maxLength) {
    return text;
  }
  return text.substr(0, maxLength) + "...";
}

const QuoqoTransaction = () => {
  const api = useAxios();
  const { t } = useTranslation();
  const { permissionList } = useContext(PermissionContext);
  const { user } = useContext(AuthContext);
  const { setActiveLink, setShowDropdown } = useContext(AppContext);
  const { getNotifications } = useContext(FormContext);
  const inputRef = useRef();
  const [loading, setLoading] = useState(false);
  const [file, setFile] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [reviewDocList, setReviewDocList] = useState([]);
  const [reviewDocSearchText, setReviewDocSearchText] = useState("");
  const [reviewDocCurrentPage, setReviewDocCurrentPage] = useState(1);
  const [reviewDocPageLimit, setReviewDocPageLimit] = useState(5);
  const [reviewDocNextBtnLimit, setReviewDocNextBtnLimit] = useState(0);
  const [reviewDocCount, setReviewDocCount] = useState(0);
  const [errMsgReviewDoc, setErrMsgReviewDoc] = useState("");
  const [sortingOrderReviewDoc, setSortingOrderReviewDoc] = useState("desc");
  const [sortColumnReviewDoc, setSortColumnReviewDoc] = useState("id");

  const [sectionTaskList, setSectionTaskList] = useState([]);
  const [countryListData, setCountryListData] = useState([]);
  const [sectionList, setSectionList] = useState([]);
  const [isDragging, setIsDragging] = useState(false);

  const uploadQuoqoDeligence = async (postData, file) => {
    setLoading(true);
    try {
      let taskId = postData.selectedPerspective;
      let taskInfo = sectionTaskList.find((task) => task.id == taskId);
      let dataInfo = {};
      if (taskInfo) {
        dataInfo[taskInfo["section_id"].section_name] = [taskInfo.task_name];
      }

      let formData = new FormData();
      formData.append("file", file);
      formData.append("sections", JSON.stringify(dataInfo));
      formData.append("perspective", postData.reviewReason);
      formData.append("governing_law", postData.governing_law);
      formData.append("taskdecpt", postData.taskDescription);
      formData.append("customprompt", postData.customePrompt);

      const res = await api.post(
        `${process.env.REACT_APP_BACKEND_BASE_URL}/nda_report/transaction/upload/`,
        formData
      );
      if (res.status == 200) {
        toast.success(t("allModule.Document uploaded successfully"));
        getReviewDocList();
        setTimeout(() => {
          getNotifications();
          checkDocumentStatus(res.data.doc_ids[0]);
        }, 5000);
      }
    } catch (error) {
      if (error?.response?.data?.message) {
        toast.error(error.response.data.message);
      } else {
        toast.error(
          t("allModule.Something went wrong, please try again later.")
        );
      }
    }
    setLoading(false);
    setIsModalOpen(false);
  };

  const getReviewDocList = async (clearText) => {
    const res = await resolve(
      api
        .get(
          `contract/document/list/?type=2&search=${reviewDocSearchText}&ordering=${sortColumnReviewDoc}&sortingOrder=${sortingOrderReviewDoc}&page=${reviewDocCurrentPage}&limit=${reviewDocPageLimit}&review_doc=1`
        )
        .then((res) => res.data)
    );
    if (res.data) {
      const { data } = res;
      const { results, count } = data;
      const filterData = [];
      if (results.length > 0) {
        setReviewDocCount(count);
        results.forEach((result) => {
          const reviewDocObj = {
            id: result.id,
            name: result.name,
            type: result.type,
            uniqueId: result.tags,
            taskdecpt: result.taskdecpt,
            json_data: {
              section: "",
              task: "",
            },
            status: docStatus[result.status],
          };
          if (result.json_data) {
            let jsonInfo = JSON.parse(result.json_data);
            let keyList = Object.keys(jsonInfo);
            if (keyList.length > 0) {
              reviewDocObj["json_data"] = {
                section: keyList[0],
                task: jsonInfo[keyList[0]][0],
              };
            }
          }
          filterData.push(reviewDocObj);
        });
      } else {
        if (reviewDocCurrentPage === 1)
          setErrMsgReviewDoc(t("home.No document added yet"));
        else setErrMsgReviewDoc(t("home.No more document to view"));
      }
      setReviewDocList([...filterData]);
      setReviewDocNextBtnLimit(Math.ceil(data.count / reviewDocPageLimit));
    } else {
      setReviewDocNextBtnLimit(0);
    }
  };

  const downloadReport = async (dataInfo) => {
    let date = new Date();
    var year = date.getFullYear();
    var month = date.getMonth() + 1;
    var day = date.getDate();
    var hours = date.getHours();
    var minutes = date.getMinutes();
    var seconds = date.getSeconds();

    const currentDateTime =
      year +
      "_" +
      month +
      "_" +
      day +
      "_" +
      hours +
      "_" +
      minutes +
      "_" +
      seconds;

    await api
      .get(
        `${process.env.REACT_APP_BACKEND_BASE_URL}/nda_report/download/${dataInfo.uniqueId}`
      )
      .then((resp) => {
        let str = resp?.data?.report;
        // setPdfBase64(resp?.data?.report)
        if (str) {
          let binaryData = window.atob(`${str}`);
          let array = [];
          for (let i = 0; i < binaryData.length; i++) {
            array.push(binaryData.charCodeAt(i));
          }
          let uint8Array = new Uint8Array(array);
          let blob = new Blob([uint8Array], { type: "application/pdf" });
          let url = URL.createObjectURL(blob);
          let link = document.createElement("a");
          link.href = url;

          let fileName = dataInfo?.name.split(".");
          if (fileName.length > 0) {
            link.download = `${fileName[0]}_Report_Diligence_${currentDateTime}.pdf`;
          } else {
            link.download = resp?.data?.reportName;
          }
          document.body.appendChild(link);
          link.click();
        } else {
          window.open(
            `${process.env.REACT_APP_BACKEND_BASE_URL}/nda_report/download/${dataInfo.uniqueId}`,
            "_blank"
          );
        }
      })
      .catch((error) => {
        toast.error(error.response.data["status"].msg);
      });
  };

  const getSectionTaskList = async (sectionId) => {
    setSectionTaskList([]);
    const res = await resolve(
      api
        .get(
          `nda_report/diligence/task/list/?section_id=${sectionId}&ordering=description&sortingOrder=asc`
        )
        .then((res) => res.data)
    );
    if (res.data) {
      const { data } = res;
      const { results, count } = data;
      setSectionTaskList(results);
    } else {
      setSectionTaskList([]);
    }
  };

  // const checkDocumentStatus = (documentId) => {
  //   const sse = new EventSource(
  //     `${process.env.REACT_APP_BACKEND_BASE_URL}/contract/docstatus_stream/${documentId}/diligence/`
  //   );
  //   function getRealtimeData(data) {
  //     getReviewDocList();
  //     sse.close();
  //   }
  //   sse.onmessage = (e) => getRealtimeData(e.data);
  // };

  const checkDocumentStatus = async (documentId) => {
    const url = `contract/docstatus_stream/${documentId}/diligence/`;
    const responceData = await resolve(api.get(url).then((res) => res.data));
    if (responceData["data"]) {
      if (responceData["data"]["count"] == 0) {
        setTimeout(() => {
          checkDocumentStatus(documentId);
        }, 5000);
      } else {
        getReviewDocList();
      }
    }
  };

  const getCountryListApi = async () => {
    const responceData = await resolve(
      api.get("/company/countries/list/").then((res) => res.data)
    );
    if (responceData["data"]) {
      setCountryListData(responceData["data"]);
    }
  };

  const getSectionList = async (clearText) => {
    const res = await resolve(
      api
        .get(
          `nda_report/diligence/section/list/?SecCat=Transaction&ordering=section_name&sortingOrder=asc`
        )
        .then((res) => res.data)
    );
    if (res.data) {
      const { data } = res;
      const { results, count } = data;
      setSectionList(results);
    }
  };

  useEffect(() => {
    if (file) {
      setIsModalOpen(true);
    }
  }, [file]);

  useEffect(() => {
    getReviewDocList();
  }, [
    reviewDocCurrentPage,
    reviewDocPageLimit,
    sortColumnReviewDoc,
    sortingOrderReviewDoc,
  ]);

  useEffect(() => {
    // getSectionList();
    getSectionList();
    getCountryListApi();
  }, []);

  if (loading) {
    return <LoadingScreen />;
  }

  return (
    <div className="text-primary text-base font-normal font-sans mt_set">
      <div className="main_head flex items-center">
        <img src={ma} width={120} height={120} />
        <h1
          className="text-4xl font-bold text_c "
          style={{ marginLeft: "10px" }}
        >
          {t("constant.Review a Transaction Document")}
        </h1>
      </div>
      {permissionList["Transaction_no"] == false ? (
        <div className="flex justify-center items-center h-96 flex-column ">
          <h3 className="text-2xl font-bold text_c">
            {t("allModule.You dont have permission to access this page")}
          </h3>
        </div>
      ) : (
        <div className="w-full items-center generate_docuement">
          <div className="row">
            <div className="col-md-8">
              <div
                onClick={(e) => {
                  e.preventDefault();
                  inputRef.current.click();
                }}
                
                className={`p-5 border-2 border-primary text-primary rounded-lg text-center mt-5 mb-10 ${
                  isDragging ? "bg-[#f0f0f0]" : ""
                }`}
              >
                <img
                  style={{ display: "inline" }}
                  src={upload_documents}
                  width={50}
                  height={50}
                />
                <h4 className="font-bold text-xl mt-3">
                  {t(
                    "allModule.Upload any document for AI assisted transaction review"
                  )}
                </h4>
              </div>
              <input
                type="file"
                accept=".pdf,.doc, .docx"
                hidden
                ref={inputRef}
                onChange={(e) => {
                  // const sizeInMb = 2; // 5MB
                  // const maxFileSize = sizeInMb*1024; // 5Kb
                  // const selectedFileSizeInMb = e.target.files[0].size/1024;

                  // if(selectedFileSizeInMb > maxFileSize){
                  //     toast.error('File size should be less than '+sizeInMb+'MB')
                  // }else{
                  //     setFile(e.target.files[0])
                  // }
                  setFile(e.target.files[0]);
                }}
              />

              <ul>
                {/* <li>* {t('allModule.Please upload a file with no redlines and in PDF format.')}</li> */}
                <li>
                  *{" "}
                  {t(
                    "allModule.An AI reviewed report will be generated and can be found in"
                  )}{" "}
                  <span
                    className="text-red-600 font-bold cursor-pointer"
                    onClick={(e) => {
                      setActiveLink("MyDocuments");
                      setShowDropdown(undefined);
                    }}
                  >
                    {t("allModule.My Documents")}
                  </span>
                  .
                </li>
                <li>
                  *{" "}
                  {t(
                    "allModule.This report is not meant to be or is a substitute for legal advice, and please consult a licensed professional."
                  )}
                </li>
                <li>
                  *{" "}
                  {t(
                    "allModule.The document review can take 5-10 mins based on the length of the document uploaded and type of task requested."
                  )}
                </li>
              </ul>
            </div>
            <div className="col-md-4">
              <h4 className="text-xl font-bold  mb-2 text_c ">
                {t("allModule.Document List")}
              </h4>
              <div className="seachbox mb-2">
                <input
                  type="text"
                  value={reviewDocSearchText}
                  className="border-2 border-primary rounded-lg outline-none p-1 pl-2 w-2/3"
                  placeholder={t("allModule.Search")}
                  onChange={(e) => {
                    setReviewDocSearchText(e.target.value);
                  }}
                  onKeyUp={(e) => {
                    if (e.key === "Enter" || e.target.value == "") {
                      setReviewDocCurrentPage(1);
                      getReviewDocList(reviewDocSearchText);
                    }
                  }}
                />
                <button
                  className="btn pb-1 pt-0.5 pl-6 pr-6 text_c font-bold rounded-lg btn_theme3"
                  onClick={(e) => {
                    setReviewDocCurrentPage(1);
                    getReviewDocList(reviewDocSearchText);
                  }}
                >
                  {t("allModule.Search")}
                </button>
              </div>
              <div className="template_list_container pad_0">
                {reviewDocList.length > 0 && (
                  <>
                    {reviewDocList.map((item, index) => {
                      return (
                        <div
                          key={index}
                          className="template_box"
                          style={{ position: "relative" }}
                        >
                          <div className="template_box_head">
                            <h1 className="text-2xl font-bold text_c">
                              {item.name}
                            </h1>
                            {item.status === "Completed" ? (
                              <>
                                <p>
                                  {t("form.Status")} : {item.status}
                                </p>
                                <p>
                                  {t("form.Task")} :{" "}
                                  <span
                                    data-tip={item.taskdecpt}
                                    data-for={`taskTooltip-${index}`}
                                  >
                                    {truncateText(item.taskdecpt, 25)}
                                  </span>
                                </p>
                              </>
                            ) : (
                              <>
                                <p className="flex">
                                  {t("form.Status")}:{" "}
                                  <img
                                    style={{
                                      marginTop: "5px",
                                      marginLeft: "10px",
                                      height: "20px",
                                    }}
                                    src={pending}
                                  />
                                </p>
                                <p>
                                  {t("form.Task")} :{" "}
                                  <span
                                    data-tip={item.taskdecpt}
                                    data-for={`taskTooltip-${index}`}
                                  >
                                    {truncateText(item.taskdecpt, 75)}
                                  </span>
                                </p>
                              </>
                            )}
                          </div>
                          {item.status === "Completed" && (
                            <>
                              <button
                                style={{
                                  position: "absolute",
                                  bottom: "5px",
                                  right: "5px",
                                }}
                                className="btn btn_theme2"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  downloadReport(item);
                                }}
                                data-tip={t("allModule.Download")}
                                data-for={`taskTooltip-${index}`}
                              >
                                <img src={Download} />
                              </button>
                            </>
                          )}
                          <ReactTooltip
                            id={`taskTooltip-${index}`}
                            place="top"
                            effect="solid"
                            backgroundColor="#400835"
                            textColor="#ffffff"
                          />
                        </div>
                      );
                    })}
                    <div
                      style={{
                        flexBasis: "100%",
                        display: "flex",
                        justifyContent: "end",
                      }}
                    >
                      <div className="flex text-primary  border-t-2 border-b-2 border-primary rounded-lg">
                        <button
                          className={`btn-sm border-r-2 border-l-2 border-primary font-semibold rounded-tl-lg rounded-bl-lg ${
                            reviewDocCurrentPage <= 1 && "bg-inputBackground"
                          }`}
                          disabled={reviewDocCurrentPage <= 1}
                          onClick={(e) => {
                            if (reviewDocCurrentPage > 1) {
                              setReviewDocCurrentPage(reviewDocCurrentPage - 1);
                            }
                          }}
                        >
                          {t("common.Prev")}
                        </button>
                        <span className="btn-sm font-semibold">
                          {reviewDocCurrentPage}
                        </span>
                        <button
                          className={`btn-sm border-l-2 border-r-2 border-primary font-semibold rounded-tr-lg rounded-br-lg ${
                            reviewDocCurrentPage >= reviewDocNextBtnLimit &&
                            "bg-inputBackground"
                          }`}
                          disabled={
                            reviewDocCurrentPage >= reviewDocNextBtnLimit
                          }
                          onClick={(e) => {
                            if (reviewDocCurrentPage < reviewDocNextBtnLimit) {
                              setReviewDocCurrentPage(reviewDocCurrentPage + 1);
                            }
                          }}
                        >
                          {t("common.Next")}
                        </button>
                      </div>
                    </div>
                  </>
                )}

                {reviewDocList.length == 0 && (
                  <div className="flex justify-center items-center h-96 flex-column w-full">
                    <h3 className="text-2xl font-bold text_c">
                      {t("allModule.No Data Found")}
                    </h3>
                  </div>
                )}
              </div>
            </div>
          </div>
          <DiligenceModal
            isModalOpen={isModalOpen}
            setIsModalOpen={setIsModalOpen}
            file={file}
            uploadQuoqoDeligence={uploadQuoqoDeligence}
            sectionTaskList={sectionTaskList}
            getSectionTaskList={getSectionTaskList}
            countryListData={countryListData}
            sectionList={sectionList}
            inputRef={inputRef}
            setSectionTaskList={setSectionTaskList}
          />
        </div>
      )}
    </div>
  );
};

export default QuoqoTransaction;
