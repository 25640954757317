import React, { useEffect, useContext, useState } from 'react';
import ReactTooltip from 'react-tooltip';
import FormContext from '../../context/FormContext';
import AppModal from '../modals/Modal';
import 'react-tagsinput/react-tagsinput.css';
import useAxios from '../../hooks/useAxios';
import HelpIcon from '../../assets/icons/Help-sm.svg';
import ToggleSwitch from '../../shared/ToggleSwitch';


const SectionForm = () => {
  const api = useAxios();
  const {
    modalData,
    setIsOpen,
    modalIsOpen,
    isEditing,
    editFormData,
    saveOrEditSection,
  } = useContext(FormContext);

  const [sectionDetail, setSectionDetail] = useState({
    sectionName: '',
    SecCat: '',
  });

  const [sectionError, setSectionError] = useState({
    sectionName: '',
    SecCat: '',
  });
  const [sectionId, setSectionId] = useState(0);

  const submitData = async () => {
    for (const key in sectionError) {
      if (sectionError.hasOwnProperty(key)) {
        sectionError[key] = "";
      }
    }
    if (sectionDetail.sectionName.trim().length < 1) {
      setSectionError({ ...sectionError, sectionName: 'Section name is required' })
    }else if (sectionDetail.SecCat.trim().length < 1) {
      setSectionError({ ...sectionError, SecCat: 'Category type is required' })
    } else {
      setSectionError({ ...sectionError, sectionName: '', SecCat: '' })

      const postData = {
        "SectionId": sectionId,
        "SectionName": sectionDetail.sectionName,
        "SecCat": sectionDetail.SecCat?sectionDetail.SecCat:"",
      }
      
      saveOrEditSection(postData);
    }
  }



  useEffect(() => {

    if (isEditing) {
      setSectionDetail({
        sectionName: editFormData.section_name,
        SecCat: editFormData.SecCat,
      });
      setSectionId(editFormData.id);
    }
  }, [isEditing, editFormData]);




  return (
    <div className='text-primary text-base font-normal font-sans'>
      <div className="main_head">
        <h1 className='text-4xl font-bold'>
          {!isEditing ? 'Add Section' : 'Edit Section'}
        </h1>
      </div>

      <div className=' flex w-full items-center h-full'>
        <form className='w-2/5'>
        
          <div className='flex flex-col mb-3'>
            <div className='flex'>
              <label className='mb-2'>Section Name</label>
              <>
                <img
                  src={HelpIcon}
                  width={12}
                  className='ml-1 -mt-2 cursor-pointer'
                  data-tip='Insert section name'
                />
                <ReactTooltip backgroundColor='#400835' textColor='#ffffff' />
              </>
            </div>
            <input
              placeholder='Insert section name'
              className='border-2 border-primary rounded-lg outline-none p-1 pl-2'
              value={sectionDetail.sectionName}
              onChange={(e) => {
                setSectionDetail({ ...sectionDetail, sectionName: e.target.value });
              }}
            />
            <p className='text-sm text-red-500 font-normal'>{sectionError.sectionName}</p>
          </div>
          <div className='flex flex-col mb-3'>
            <div className='flex'>
              <label className='mb-2'>Category Type</label>
              <>
                <img
                  src={HelpIcon}
                  width={12}
                  className='ml-1 -mt-2 cursor-pointer'
                  data-tip='Category Type'
                />
                <ReactTooltip backgroundColor='#400835' textColor='#ffffff' />
              </>
            </div>
            <select
              className='border-2 border-primary rounded-lg outline-none p-1 pl-2'
              value={sectionDetail.SecCat}
              onChange={(e) => {
                setSectionDetail({ ...sectionDetail, SecCat: e.target.value });
              }}
            >
              <option value="">Select Category Type</option>
              <option value="Transaction">Transaction</option>
              <option value="diligence">Diligence</option>
              <option value="IP">IP</option>
              <option value="SectoralReview">Sectoral Review</option>
              <option value="playbook">Playbook</option>
              

            </select>
            <p className='text-sm text-red-500 font-normal'>{sectionError.SecCat}</p>
            
          </div>

          {!isEditing ? (
            <>              
              <button
                className='bg-primary font-bold text-white w-full p-1 rounded-lg mt-3'
                onClick={(e) => {
                  e.preventDefault();
                  submitData();
                }}
              >
                Add Section
              </button>

            </>
          ) : (
            <>             
              <button
                className='bg-secondary font-bold text-white w-full p-1 rounded-lg'
                onClick={(e) => {
                  e.preventDefault();
                  submitData();
                }}
              >
                Update Section
              </button>
            </>

          )}
        </form>

      </div>

      <AppModal
        title={modalData.title}
        bodyText={modalData.bodyText}
        setIsOpen={setIsOpen}
        modalIsOpen={modalIsOpen}
        modelAction="SectionForm"
        apiResStatus={modalData.apiResStatus}
      />

    </div>
  );
};

export default SectionForm;
