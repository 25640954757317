import React, { useEffect, useRef, useState } from 'react';
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
import { typeOfSign } from '../../constants/constants';
import './modal.css';
import { useTranslation } from 'react-i18next';
function PhotoModel(props) {

    const id = uuidv4();
    const { t } = useTranslation();

    const {
        showWebcam,
        setShowWebcam,
        setIsSignModalOpen,
        setImgSrc,
        setSrc,
        stream,
        setStream,
        videoRef,
        recptId
    } = props;
    const [formData, setFormData] = useState(null);
    const canvasRef = useRef(null);
    const inputRef = useRef();
    const [imageSrc, setImageSrc] = useState(null);


    const savePhoto = async () => {
        await setStream(null);
        setImgSrc(imageSrc);

        await setShowWebcam(false);


        const data = new FormData();
        data.append('image', formData);
        data.append('document_recipients_id', recptId);
        data.append('qrcode_id', id);
        data.append('type_of_sign', typeOfSign.PHOTOGRAPH);


        data.append(
            'webhook_url',
            `${process.env.REACT_APP_BACKEND_BASE_URL}/contract/webhook_signature_pad/`
        );
        data.append('is_mobile', 'false');
        axios.post(`${process.env.REACT_APP_BACKEND_BASE_URL}/contract/save-image/`, data, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        })
            .then((res) => {
                setSrc('');
            })
            .catch((err) => {
                
            });

        setIsSignModalOpen(false);


    }


    const handleCaptureClick = async () => {
        setShowWebcam(false);
        if (stream) {
            const context = canvasRef.current.getContext('2d');
            context.drawImage(videoRef.current, 0, 0, canvasRef.current.width, canvasRef.current.height+20);
            setImageSrc(canvasRef.current.toDataURL());

            fetch(canvasRef.current.toDataURL())
                .then((res) => {
                    res.blob().then(function (blob) {
                        setFormData(blob);
                    })
                })

            await stream.getTracks().forEach(track => {
                if (track.kind === 'video') {
                    track.stop();
                }
            });
        }
    }

    useEffect(() => {
        return () => {
            if (stream) {
                stream.getTracks().forEach((track) => track.stop());
            }
        };
    }, [stream]);

    return (
        <div>
            <div className='text-center font-bold text-18' color="primary">{t('form.UPLOAD LIVE PHOTO')}</div>
           
            {showWebcam && (
                <div className='p-5' style={{ display: 'grid' }}>
                    <div className='flex justify-center'>
                    <canvas ref={canvasRef} width="300" height="240" className='set_cavs' />
                    <video ref={videoRef}  width="300" height="240"  autoPlay />
                        </div>
                    <button variant="contained" className='mt-2' fullwidth="true" color="primary" onClick={handleCaptureClick}>{t('form.Capture')}</button>
                </div>
            )}
            {imageSrc && (
                <div className='p-5 photo_model_img_div' style={{ display: 'grid' }}>
                    <img src={imageSrc}  width="360" height="240" alt="Captured" />
                    <button variant="contained" className='mt-2' color='primary' onClick={savePhoto} >{t('form.SAVE')}</button>
                </div>
            )}

        </div>
    )
}

export default PhotoModel