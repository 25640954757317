import React, { useContext } from 'react';
import Modal from 'react-modal';
import { useTranslation } from 'react-i18next';
// import './modal.css';

const customStyles = {
  content: {
    top: '40%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    maxWidth: '400px',
    maxHeight: '200px',
    borderRadius: '11px',
    minWidth: '300px',
  },
  overlay: {
    backgroundColor: 'rgba(64, 8, 53, 0.63)',
  },
};

Modal.setAppElement('#root');
const ReviewModal = ({
  isModalOpen,
  setIsModalOpen,
  file,
  sendNdaDoc,

}) => {

const { t } = useTranslation();
  return (
    <div className=''>
      <Modal
        isOpen={isModalOpen}
        onRequestClose={() => {
          setIsModalOpen(false);
        }}
        style={customStyles}
        contentLabel='Example Modal'
        onAfterClose={() => {
          setIsModalOpen(false);
        }}
      >

        <div className='text-center modal-container'>
          {/* <h1 className='text-primary font-bold text-lg'>File To Upload</h1> */}
          <p className='text-primary font-normal text-md mt-2'>{t('form.File Name')}: {file && file.name}</p>

          <div className='flex justify-center mt-5'>
            <button
              className='bg-primary hover:bg-secondary font-semibold text-white p-1 pl-8 pr-8 rounded-lg mr-5'
              onClick={(e) => {
                e.stopPropagation();
                sendNdaDoc()
              }}
            >
              {t('allModule.Upload')}
            </button>
            <button
              className='bg-[#606060] font-semibold text-white p-1 pl-8 pr-8 rounded-lg'
              onClick={() => setIsModalOpen(false)}
            >
              {t('common.Cancel')}
            </button>
          </div>


        </div>
      </Modal>
    </div>
  );
};

export default ReviewModal;
