import React, { useEffect, useState, useContext } from "react";
import Modal from "react-modal";
import "./modal.css";
import useAxios from "../../hooks/useAxios";
import { resolve } from "../../api/resolve";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import AppContext from "../../context/AppContext";

const customStyles = {
  content: {
    top: "40%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    minWidth: 300,
    maxWidth: 400,
    maxHeight: "520px",
    borderRadius: "11px",
  },
  overlay: {
    backgroundColor: "rgba(64, 8, 53, 0.63)",
  },
};

Modal.setAppElement("#root");

const Moverepolegacy = ({
  modalmoveIsOpen,
  setmodalmoveIsOpen,
  isUpdating,
  setIsUpdating,
  parentid,
  repoName,
  modalmultimoveIsOpen,
  selectedfolders,
  selectedfiles,
  handleSubFolderClick,
  getSubFolderDoc,
}) => {
  const api = useAxios();
  const { t } = useTranslation();
  const [errMsg, setErrMsg] = useState("");
  const [reposval, setReposval] = useState([]);
  const [validationError, setValidationError] = useState("");
  const [selectedRepos, setSelectedRepos] = useState([
    { repo_id: "", children: [] },
  ]);
  const { getNotificationsCountlegacy } = useContext(AppContext);

  const getRepos = async () => {
    const res = await resolve(
      api.get(`legacy_contract/get_all_repos/`).then((res) => res.data)
    );

    if (res.data) {
      const filterData = res.data
        .filter(
          (result) =>
            result.repo_name.toLowerCase() !== "shared repo" &&
            result.repo_name.toLowerCase() !== "default" &&
            result.id !== parentid
        )
        .map((result) => ({
          repo_name: result.repo_name,
          id: result.id,
        }));
      setReposval(filterData);
    }
  };

  const getReposByParentId = async (parentId, index) => {
    const passval = { repo_id: parentId };
    const res = await resolve(
      api
        .post(`legacy_contract/getRepoparent/`, passval)
        .then((res) => res.data)
    );
    if (res.data && res.data.length > 0) {
      const childRepos = res.data.map((result) => ({
        repo_name: result.repo_name,
        id: result.id,
      }));

      const updatedSelectedRepos = [...selectedRepos];
      updatedSelectedRepos[index].children = childRepos;
      // Add a new empty dropdown only if there are children
      updatedSelectedRepos.splice(index + 1);
      updatedSelectedRepos.push({ repo_id: "", children: [] });
      setSelectedRepos(updatedSelectedRepos);
    } else {
      // If no children, reset the subsequent dropdowns
      const updatedSelectedRepos = [...selectedRepos];
      updatedSelectedRepos.splice(index + 1);
      setSelectedRepos(updatedSelectedRepos);
    }
  };

  const handleChangeforparentrepo = (index, event) => {
    const { value } = event.target;
    const updatedSelectedRepos = [...selectedRepos];
    updatedSelectedRepos[index].repo_id = value;
    // Reset all subsequent dropdowns
    updatedSelectedRepos.splice(index + 1);
    setSelectedRepos(updatedSelectedRepos);

    if (value) {
      getReposByParentId(value, index);
    }
  };

  const changeparentRepo = async () => {
    const formData = new FormData();
    let parentRepoId = "";
    for (let i = selectedRepos.length - 1; i >= 0; i--) {
      if (selectedRepos[i].repo_id !== "") {
        parentRepoId = selectedRepos[i].repo_id;
        break;
      }
    }
    const repoParentName = async (id) => {
      const res = await resolve(
        api.get(`legacy_contract/get_repo_name/${id}/`).then((res) => res.data)
      );
      return res.data.repoName;
    };

    formData.append("repo_name", repoName);
    formData.append("repo_ids", selectedfolders);
    formData.append("file_ids", selectedfiles);
    formData.append("parent_repo_id", parentRepoId);

    try {
      const res2 = await resolve(
        api
          .post(`/legacy_contract/RepoParentListLevel/`, formData, {
            headers: {
              "content-type": "application/json",
            },
          })
          .then((res2) => res2.data)
      );
      if (res2.data.message) {
        toast.success(
          t("fileBrowser.Selected Repository or files moved successfully")
        );
        const reponame = await repoParentName(parentRepoId);
        const repo = {
          id: parentid,
          repo_name: reponame,
        };
        handleSubFolderClick(repo);
        getSubFolderDoc(parentRepoId);
      } else if (res2.error.response) {
        toast.error(
          t(
            "fileBrowser.You are not authorized to move the selected repository/ files."
          )
        );
      } else {
        toast.error(
          t(
            "fileBrowser.Failed to move selected repository/ files. Please try again"
          )
        );
      }
      setTimeout(() => {
        getNotificationsCountlegacy();
      }, 2000);

      setmodalmoveIsOpen(false);
      setIsUpdating(false);
    } catch (error) {
      toast.error(
        t(
          "fileBrowser.Failed to move selected repository/ files. Please try again"
        )
      );
    }

    setmodalmoveIsOpen(false);
    setIsUpdating(false);
  };

  useEffect(() => {
    getRepos();
  }, []);

  useEffect(() => {
    if (modalmoveIsOpen) {
      // Reset state when modal is opened
      setSelectedRepos([{ repo_id: "", children: [] }]);
      setValidationError("");
      setErrMsg("");
      getRepos();
    }
  }, [modalmoveIsOpen]);

  return (
    <div className="">
      <Modal
        isOpen={modalmoveIsOpen}
        onRequestClose={() => setmodalmoveIsOpen(false)}
        style={customStyles}
        contentLabel="Example Modal"
        onAfterClose={() => {
          setErrMsg("");
          setIsUpdating(false);
        }}
      >
        {errMsg ? (
          <>
            <h1 className="text-primary font-semibold text-lg">{errMsg}</h1>
            <div className="flex justify-center mt-3">
              <button
                type="button"
                className="bg-primary font-semibold text-white p-1 px-5 rounded-lg w-2/3"
                onClick={() => setmodalmoveIsOpen(false)}
              >
                {t("modal.Accept")}
              </button>
            </div>
          </>
        ) : (
          <div className="text-center modal-container">
            <form
              onSubmit={(e) => {
                e.preventDefault();
                changeparentRepo(repoName);
              }}
            >
              <h1 className="text-primary font-bold text-lg">
                {isUpdating
                  ? t("fileBrowser.Rename Repository")
                  : t("fileBrowser.Move Repository")}
              </h1>
              {selectedRepos.map((repo, index) => (
                <div className="flex p-2" key={index}>
                  <select
                    id={`parent_repo_id_${index}`}
                    style={{
                      width: "100%",
                      border: "1px solid #ccc",
                      padding: "5px",
                      borderRadius: "5px",
                    }}
                    value={repo.repo_id}
                    onChange={(e) => handleChangeforparentrepo(index, e)}
                  >
                    <option value="">
                      {t("fileBrowser.Select Parent Repository")}
                    </option>
                    {(index === 0
                      ? reposval
                      : selectedRepos[index - 1].children
                    ).map((item) => (
                      <option key={item.id} value={item.id}>
                        {item.repo_name}
                      </option>
                    ))}
                  </select>
                </div>
              ))}
              <p className="text-red-500 mb-2">{validationError}</p>
              <div className="flex justify-center">
                <button
                  type="submit"
                  className={`${
                    isUpdating ? "bg-secondary" : "bg-primary"
                  } font-semibold text-white p-1 px-5 rounded-lg w-2/3`}
                >
                  {isUpdating ? t("modal.Update") : t("modal.Save")}
                </button>
              </div>
            </form>
          </div>
        )}
      </Modal>
    </div>
  );
};

export default Moverepolegacy;
