import React, { useEffect, useContext, useState } from "react";
import "./recipient.css";
import right_arrow from "../../assets/icons/right-arrow.png";
import right_arrow2 from "../../assets/icons/right-arrow2.png";
import delete_u from "../../assets/icons/delete.png";
import contact_d from "../../assets/icons/contact.png";
import DocumentContext from "../../context/DocumentContext";
import Creatable from "react-select/creatable";
import Select from "react-select";
import { customStyles } from "../../constants/styles";
import useAxios from "../../hooks/useAxios";
import { resolve } from "../../api/resolve";
import AppContext from "../../context/AppContext";
import AuthContext from "../../context/AuthContext";
import HelpIcon from "../../assets/icons/Help-sm.svg";
import ReactTooltip from "react-tooltip";
import AppModal from "../modals/Modal";
import FormContext from "../../context/FormContext";
import { toast } from "react-toastify";
import DownArrow from "../../assets/icons/down-arrow.svg";
import { Worker, Viewer } from "@react-pdf-viewer/core";
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";
import LoadingScreen from "../loader/LoadingScreen";
import PermissionContext from "../../context/PermissionContext";
import eye_off from "../../assets/icons/eye_off.png";
import eye_on from "../../assets/icons/eye_on.png";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import AddContactPopup from "../my-document/AddContactPopup";

const Recipient = () => {
  const {
    documentListData,
    activeDocInfo,
    setActiveDocInfo,
    apiCurrentPage,
    setApiCurrentPage,
    getDocumentListApi,
    apiDataLimitRecpt,
    nextBtnLimitRecpt,
    recipientDetail,
    setRecipientDetail,
    submitDocumentStep1Api,
    setDocumentListData,
    setIsShowDocPreview,
  } = useContext(DocumentContext);
  const { setIsOpen, modalIsOpen, modalData } = useContext(FormContext);
  const { permissionList, getComapnyInfoApi, isValidCompany } =
    useContext(PermissionContext);

  const { user } = useContext(AuthContext);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const {
    setActiveLink,
    setShowDropdown,
    highlightToken,
    getSasToken,
    getHighlightSasToken,
  } = useContext(AppContext);
  const [contactSearchBy, setContactSearchBy] = useState("contact_search");
  const [receipientListData, setReceipientListData] = useState([]);
  const [deligateeListData, setDeligateeListData] = useState([]);
  const [selectedRecipients, setSelectedRecipients] = useState([]);
  const [isMultiUserSelect, setIsMultiUserSelect] = useState(true);
  const [receipientError, setReceipientError] = useState("");
  const [checkBoxCheck, setCheckBoxCheck] = useState(true);
  const [deleteDocumentId, setDeleteDocumentId] = useState(false);
  const [loading] = useState(false);
  const [docUrl, setDocUrl] = useState("");
  const [activeDocInfoStatus, setActiveDocInfoStatus] = useState("off");
  const [defaultRecipientInfo, setDefaultRecipientInfo] = useState({
    id: user.user_id,
    name: user.name,
    email: user.user_email,
    phone: user.phone,
    signingCapacity: "",
    typeOfSignature: "",
    errorMsg: "",
    isDeligacy: false,
    deligaceContactId: 0,
  });
  const [selfInfo, setSelfInfo] = useState({
    id: user.user_id,
    name: user.name,
    email: user.user_email,
    phone: user.phone,
    signingCapacity: "",
    typeOfSignature: "",
    errorMsg: "",
    isKeepMe: false,
  });
  const [isOpenAadharPurchase, setIsOpenAadharPurchase] = useState(false);
  const [isAadhaarSignee, setIsAadhaarSignee] = useState(false);

  const api = useAxios();
  const getReceipientListApi = async () => {
    setSelectedRecipients([]);
    setRecipientDetail([]);
    setReceipientListData([]);
    setDeligateeListData([]);

    if (contactSearchBy == "contact_search") {
      const responceData = await resolve(
        api.get(`/user/contact/list/`).then((res) => res.data)
      );
      if (responceData["data"]) {
        const listData = responceData.data.results;
        const filteredArr = [];
        const filteredArrDLT = [];
        if (listData.length > 0) {
          listData.forEach((contactInfo) => {
            filteredArr.push({
              value: contactInfo.id + "",
              label: contactInfo.name,
              id: contactInfo.id,
              email: contactInfo.email,
              phone: contactInfo.phone,
              name: contactInfo.name,
              signingCapacity: "",
              typeOfSignature: "",
            });
            if (contactInfo.delegatee) {
              filteredArrDLT.push({
                id: contactInfo.id,
                name: contactInfo.name,
              });
            }
          });
          setReceipientListData(filteredArr);
        }
        setDeligateeListData(filteredArrDLT);
      }
    } else {
      const responceData = await resolve(
        api.get(`/user/contact/group/list/`).then((res) => res.data)
      );
      if (responceData["data"]) {
        const listData = responceData.data.results;
        const filteredArr = [];
        if (listData.length > 0) {
          listData.forEach((groupInfo) => {
            filteredArr.push({
              value: groupInfo.id + "",
              label: groupInfo.group_name,
              id: groupInfo.id,
            });
          });
          setReceipientListData(filteredArr);
        }
      }
    }
  };

  const getDocumentInfoApi = async (documentId) => {
    setSelectedRecipients([]);
    setRecipientDetail([]);
    setDefaultRecipientInfo({
      ...defaultRecipientInfo,
      signingCapacity: "",
      typeOfSignature: "",
      errorMsg: "",
    });
    setReceipientError("");
    const responceData = await resolve(
      api
        .get(`/contract/document/recipients/list/${documentId}`)
        .then((res) => res.data)
    );
    if (responceData["data"] && responceData["data"].length > 0) {
      const listData = responceData.data;
      const tempSelectedRecipients = [];
      const tempRecipientDetail = [];

      let isDeligacySetted = 0;
      let isUserObjExist = 0;
      let selfObjInfo = selfInfo;
      listData.forEach((recipientInfo) => {
        if (recipientInfo.user_contacts_id === null) {
          isUserObjExist = 1;
        } else if (recipientInfo.is_deligacy) {
          isDeligacySetted = 1;
        }
      });

      listData.forEach((recipientInfo) => {
        if (recipientInfo.user_contacts_id === null) {
          setDefaultRecipientInfo({
            ...defaultRecipientInfo,
            phone: recipientInfo.user_id.phone,
            signingCapacity: recipientInfo.signingCapacity,
            typeOfSignature: recipientInfo.typeOfSignature,
            errorMsg: "",
          });
          selfObjInfo["signingCapacity"] = recipientInfo.signingCapacity;
          selfObjInfo["typeOfSignature"] = recipientInfo.typeOfSignature;
          selfObjInfo["phone"] = recipientInfo.user_id.phone;
        } else {
          // check if user is delegatee true then set isDeligacy true
          if (recipientInfo.is_deligacy) {
            setDefaultRecipientInfo({
              ...defaultRecipientInfo,
              isDeligacy: true,
              deligaceContactId: recipientInfo["user_contacts_id"].id,
              signingCapacity: recipientInfo.signingCapacity,
              typeOfSignature: recipientInfo.typeOfSignature,
              errorMsg: "",
            });
          } else {
            tempSelectedRecipients.push({
              id: recipientInfo.user_contacts_id.id,
              value: recipientInfo.user_contacts_id.id + "",
              label: recipientInfo.user_contacts_id.name,
              email: recipientInfo.user_contacts_id.email,
              phone: recipientInfo.user_contacts_id.phone,
              name: recipientInfo.user_contacts_id.name,
              signingCapacity: recipientInfo.signingCapacity,
              typeOfSignature: recipientInfo.typeOfSignature,
            });
            tempRecipientDetail.push({
              id: recipientInfo.user_contacts_id.id,
              name: recipientInfo.user_contacts_id.name,
              email: recipientInfo.user_contacts_id.email,
              phone: recipientInfo.user_contacts_id.phone,
              signingCapacity: recipientInfo.signingCapacity,
              typeOfSignature: recipientInfo.typeOfSignature,
            });
          }
        }
      });

      if (isDeligacySetted === 1 && isUserObjExist === 1) {
        selfObjInfo["isKeepMe"] = true;
        setSelfInfo(selfObjInfo);
      }
      setSelectedRecipients(tempSelectedRecipients);
      setRecipientDetail(tempRecipientDetail);
    }
  };

  const removeRecipient = (recipientId) => {
    let filteredRecipients = selectedRecipients.filter((selectedRecipient) => {
      return selectedRecipient.id !== recipientId;
    });
    setSelectedRecipients(filteredRecipients);
    let filteredRecipientDetail = recipientDetail.filter((recipientDetails) => {
      return recipientDetails.id !== recipientId;
    });
    setRecipientDetail(filteredRecipientDetail);
  };

  useEffect(() => {
    if (
      (defaultRecipientInfo.signingCapacity === "Stakeholder" &&
        defaultRecipientInfo.isDeligacy === true) ||
      (recipientDetail.length === 0 &&
        defaultRecipientInfo.signingCapacity === "Stakeholder")
    ) {
      setDefaultRecipientInfo({
        ...defaultRecipientInfo,
        signingCapacity: "",
        typeOfSignature: "",
      });
    }
  }, [recipientDetail, defaultRecipientInfo]);

  const submitDocumentStep1 = () => {
    setReceipientError("");
    let isAnyError = false;
    let newRecipientDetail = [...recipientDetail];
    newRecipientDetail.map((recipient) => {
      if (
        recipient.signingCapacity === "" ||
        recipient.signingCapacity === undefined
      ) {
        isAnyError = true;
        recipient.errorMsg = t("allModule.Please select signing capacity");
      } else if (
        recipient.typeOfSignature === "" ||
        recipient.typeOfSignature === undefined
      ) {
        isAnyError = true;
        recipient.errorMsg = t("allModule.Please select type of signature");
      } else {
        recipient.errorMsg = "";
      }
    });

    setDefaultRecipientInfo({ ...defaultRecipientInfo, errorMsg: "" });

    if (
      defaultRecipientInfo.signingCapacity === "" ||
      defaultRecipientInfo.signingCapacity === undefined
    ) {
      isAnyError = true;
      setDefaultRecipientInfo({
        ...defaultRecipientInfo,
        errorMsg: "Please select signing capacity",
      });
    } else if (
      defaultRecipientInfo.typeOfSignature === "" ||
      defaultRecipientInfo.typeOfSignature === undefined
    ) {
      isAnyError = true;
      setDefaultRecipientInfo({
        ...defaultRecipientInfo,
        errorMsg: t("allModule.Please select type of signature"),
      });
    } else if (
      defaultRecipientInfo.isDeligacy &&
      defaultRecipientInfo.deligaceContactId == 0
    ) {
      isAnyError = true;
      setDefaultRecipientInfo({
        ...defaultRecipientInfo,
        errorMsg: t("allModule.Please select deligacy"),
      });
    } else {
      setDefaultRecipientInfo({ ...defaultRecipientInfo, errorMsg: "" });
    }

    if (defaultRecipientInfo.isDeligacy && selfInfo.isKeepMe) {
      if (
        selfInfo.signingCapacity === "" ||
        selfInfo.signingCapacity === undefined
      ) {
        isAnyError = true;
        setSelfInfo({
          ...selfInfo,
          errorMsg: t("allModule.Please select signing capacity"),
        });
      } else if (
        selfInfo.typeOfSignature === "" ||
        selfInfo.typeOfSignature === undefined
      ) {
        isAnyError = true;
        setSelfInfo({
          ...selfInfo,
          errorMsg: t("allModule.Please select type of signature"),
        });
      } else {
        setSelfInfo({ ...selfInfo, errorMsg: "" });
      }
    }
    if (isAnyError) {
      setRecipientDetail(newRecipientDetail);
    } else {
      let aadharSignCnt = 0;
      let eSignCnt = 0;
      recipientDetail.map((recipient) => {
        recipient.isDeligacy = false;
        if (recipient.typeOfSignature === "E-signature") {
          eSignCnt++;
        } else if (recipient.typeOfSignature === "Aadhaar_Digital_Signature") {
          aadharSignCnt++;
        }
      });
      if (defaultRecipientInfo["typeOfSignature"] === "E-signature") {
        eSignCnt++;
      } else if (
        defaultRecipientInfo["typeOfSignature"] === "Aadhaar_Digital_Signature"
      ) {
        aadharSignCnt++;
      }
      if (defaultRecipientInfo.isDeligacy && selfInfo.isKeepMe) {
        if (selfInfo["typeOfSignature"] === "E-signature") {
          eSignCnt++;
        } else if (
          selfInfo["typeOfSignature"] === "Aadhaar_Digital_Signature"
        ) {
          aadharSignCnt++;
        }
      }

      if (permissionList["aadhar_quota"] < aadharSignCnt) {
        modalData["title"] = "Notificaton";
        if (permissionList["aadhar_quota"] === 0) {
          modalData["bodyText"] = t(
            "allModule.Your Aadhaar balance is empty. Please purchase from Wallet page."
          );
        } else {
          modalData["bodyText"] = `${t("allModule.You can add only")} ${
            permissionList["aadhar_quota"]
          } ${t(
            "allModule.recipients as aadhaar signee. To add more recipients please purchase Aadhaar Digital Signature."
          )}`;
        }
        setIsOpenAadharPurchase(true);
      } else if (
        permissionList["e_signature_allotted_cnt"] > 0 &&
        permissionList["e_signature_remaing_cnt"] < eSignCnt
      ) {
        toast.error(
          `${t("allModule.You can add only")} ${
            permissionList["e_signature_remaing_cnt"]
          } ${t("allModule.recipeints as e-signature signee")}`
        );
      } else {
        if (aadharSignCnt > 1) {
          setIsAadhaarSignee(true);
        } else {
          let recipientList = [...recipientDetail];
          let selfUserInfo = {};
          if (defaultRecipientInfo.isDeligacy == false) {
            selfUserInfo = { ...defaultRecipientInfo };
          } else if (defaultRecipientInfo.isDeligacy && selfInfo.isKeepMe) {
            selfUserInfo = { ...selfInfo };
          }

          if (defaultRecipientInfo.isDeligacy) {
            let tempDeligacyInfo = receipientListData.filter((recipient) => {
              return recipient.id == defaultRecipientInfo.deligaceContactId;
            });
            if (tempDeligacyInfo.length > 0) {
              tempDeligacyInfo[0].signingCapacity =
                defaultRecipientInfo.signingCapacity;
              tempDeligacyInfo[0].typeOfSignature =
                defaultRecipientInfo.typeOfSignature;
              tempDeligacyInfo[0].isDeligacy = true;
              recipientList.push(tempDeligacyInfo[0]);
            }
          }
          submitDocumentStep1Api(
            recipientList,
            setActiveLink,
            selfUserInfo,
            activeDocInfoStatus
          );
        }
      }
    }
  };

  const documentChange = (item) => {
    setActiveDocInfo(item);
    const newState = documentListData.map((obj) => {
      if (obj.id === item.id && docUrl !== "") {
        return { ...obj, isShowDoc: true };
      } else {
        return { ...obj, isShowDoc: false };
      }
    });
    setDocumentListData(newState);

    if (docUrl !== "") {
      setDocUrl(item.original_file_pdf_url);
    } else {
      setDocUrl("");
    }
  };

  const aadhaarSigneeNConfirmation = () => {
    submitDocumentStep1Api(
      recipientDetail,
      setActiveLink,
      defaultRecipientInfo
    );
  };

  const getGroupMemberList = async (groupInfo) => {
    // setSelectedRecipients({groupInfo})
    const responceData = await resolve(
      api
        .get(`/user/contact/group/member_list/${groupInfo.id}`)
        .then((res) => res.data)
    );
    if (responceData["data"]) {
      const listData = responceData.data;
      const filteredArr = [];
      if (listData.length > 0) {
        listData.forEach((contactObj) => {
          let contactInfo = contactObj.group_member_id;
          filteredArr.push({
            value: contactInfo.id + "",
            label: contactInfo.name,
            id: contactInfo.id,
            email: contactInfo.email,
            phone: contactInfo.phone,
            signingCapacity: "",
            typeOfSignature: "",
            errorMsg: "",
          });
        });
        setRecipientDetail(filteredArr);
      }
    }
  };

  const deleteDocument = async (documentId) => {
    setDeleteDocumentId(documentId);
    setIsOpen(true);
  };
  const deleteDocumentConfimed = async () => {
    setIsOpen(false);
    const responceData = await resolve(
      api
        .get(`/contract/document/unassigned/${deleteDocumentId}`)
        .then((res) => res.data)
    );
    if (responceData["data"]) {
      toast.success("Document removed successfully");
      getDocumentListApi(apiCurrentPage, apiDataLimitRecpt, "2,6");
    }

    if (responceData["error"]) {
      toast.error("Document not removed");
    }
  };

  const handleTypeAndCapacityChange = (recipientId, index, type, value) => {
    setSelectedRecipients(
      selectedRecipients.map((item) => {
        if (item.id === recipientId) {
          if (type === "signingCapacity") {
            return { ...item, signingCapacity: value };
          } else if (type === "typeOfSignature") {
            return { ...item, typeOfSignature: value };
          }
        } else {
          return item;
        }
      })
    );
  };

  const getUserDetails = async () => {
    const res = await resolve(
      api
        .get(`/user/detail/`, {
          headers: { "content-type": "application/json" },
        })
        .then((res) => res.data)
    );
    if (res.data) {
      setDefaultRecipientInfo({
        ...defaultRecipientInfo,
        name: res.data.name,
        email: res.data.email,
        phone: res.data.phone,
      });
    }
  };

  useEffect(() => {
    getDocumentListApi(apiCurrentPage, apiDataLimitRecpt, "2,6");
  }, [apiCurrentPage, apiDataLimitRecpt]);

  useEffect(() => {
    getReceipientListApi();
    if (activeDocInfo?.id) {
      getDocumentInfoApi(activeDocInfo.id);
    }
  }, [contactSearchBy]);

  useEffect(() => {
    // get active doc recipients info from db
    if (activeDocInfo?.id) {
      getDocumentInfoApi(activeDocInfo.id);
    }
  }, [activeDocInfo]);

  useEffect(() => {
    getSasToken();
    getHighlightSasToken();
    getUserDetails();
    setIsShowDocPreview(false);
  }, []);

  const renderToolbar = (Toolbar) => (
    <Toolbar>
      {(slots) => {
        const {
          CurrentPageInput,
          GoToNextPage,
          GoToPreviousPage,
          NumberOfPages,

          CurrentScale,
          ZoomIn,
          ZoomOut,
        } = slots;

        return (
          <div className={`w-full`}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                width: "100%",
                justifyContent: "space-between",
              }}
            >
              <div className={`flex ml-8 items-center justify-center`}>
                <div className="w-10">
                  <CurrentPageInput />
                </div>
                <div className="mx-2">
                  /{" "}
                  <span>
                    <NumberOfPages />
                  </span>
                </div>
                <div className="flex flex-col ml-1">
                  <GoToPreviousPage>
                    {(props) => (
                      <>
                        <span
                          disabled={props.isDisabled}
                          onClick={props.onClick}
                          style={{
                            cursor: "pointer",
                            transform: "rotate(180deg)",
                            width: 15,
                            height: 15,
                          }}
                          className="ml-1"
                          data-tip="Previous Page"
                        >
                          <img src={DownArrow} alt="img" />
                        </span>
                        <ReactTooltip
                          backgroundColor="#400835"
                          textColor="#ffffff"
                        />
                      </>
                    )}
                  </GoToPreviousPage>
                  <GoToNextPage>
                    {(props) => (
                      <>
                        <span
                          disabled={props.isDisabled}
                          onClick={props.onClick}
                          style={{
                            cursor: "pointer",
                            marginTop: 2,
                            width: 15,
                            height: 15,
                          }}
                          className="ml-1"
                          data-tip="Next Page"
                        >
                          <img src={DownArrow} alt="img" />
                        </span>
                        <ReactTooltip
                          backgroundColor="#400835"
                          textColor="#ffffff"
                        />
                      </>
                    )}
                  </GoToNextPage>
                </div>
              </div>
              <div className="max-w-fit flex items-center">
                <ZoomIn />
                <span className="mx-1">{CurrentScale()}</span>
                <ZoomOut />
              </div>
            </div>
          </div>
        );
      }}
    </Toolbar>
  );
  /////////// pdf search fuctionality ////////////////////
  const defaultLayoutPluginInstance = defaultLayoutPlugin({
    renderToolbar,
    sidebarTabs: () => [],
  });

  return (
    <div className="text-primary text-base font-normal font-sans mt_set">
      <div className="main_head">
        <div className="flex">
          <h1 className="text-4xl font-bold text_c">
            {t("allModule.Details of the Signing Parties")}
          </h1>
          <>
            <img
              src={HelpIcon}
              width={12}
              className="ml-1 -mt-2 cursor-pointer"
              data-tip={t(
                "allModule.Recipients are the people who will be signing the document. You can add recipients from your contact list or add them manually. You can also add a group of contacts as a recipient."
              )}
            />
            <ReactTooltip backgroundColor="#400835" textColor="#ffffff" />
          </>
        </div>
      </div>

      {documentListData.length > 0 ? (
        <>
          <div className="row">
            <div className="col-md-12">
              <div className="w-full doc_list_btn">
                <div className="flex flex-col mb-4">
                  <div className="btn_us3">
                    <button
                      className="btn pb-1 pt-0.5 pl-6 pr-6 text_c font-bold rounded-lg next-pre-btn "
                      onClick={() => {
                        if (apiCurrentPage > 1) {
                          setApiCurrentPage(apiCurrentPage - 1);
                        }
                      }}
                    >
                      <img src={right_arrow} className="right rotateIcon" />
                    </button>

                    {documentListData.map((item, index) => {
                      return (
                        <React.Fragment key={index}>
                          <button
                            style={{ position: "relative" }}
                            className={`set_w2 btn pb-1 pt-0.5 pl-6 pr-6 text_c font-semibold ${
                              item.id == activeDocInfo?.id ? "active" : ""
                            }`}
                            onClick={() => documentChange(item)}
                          >
                            {item.name}

                            <img
                              onClick={(e) => {
                                e.stopPropagation();
                                let isShowDoc = false;
                                const newState = documentListData.map((obj) => {
                                  if (obj.id === item.id) {
                                    isShowDoc = !item.isShowDoc;
                                    return {
                                      ...obj,
                                      isShowDoc: !item.isShowDoc,
                                    };
                                  } else {
                                    return { ...obj, isShowDoc: false };
                                  }
                                });
                                if (isShowDoc) {
                                  setIsShowDocPreview(isShowDoc);
                                }

                                setDocumentListData(newState);

                                if (!item.isShowDoc) {
                                  setDocUrl(item.original_file_pdf_url);
                                } else {
                                  setDocUrl("");
                                }
                                setActiveDocInfoStatus(
                                  !item.isShowDoc ? "off" : "on"
                                );
                              }}
                              src={!item.isShowDoc ? eye_off : eye_on}
                              className="eye-icon-document"
                            />

                            <span
                              onClick={(e) => {
                                e.stopPropagation();
                                deleteDocument(item.id);
                              }}
                              className="recipient-cross-icon"
                            >
                              X
                            </span>
                          </button>
                        </React.Fragment>
                      );
                    })}
                    <button
                      onClick={() => {
                        if (apiCurrentPage < nextBtnLimitRecpt) {
                          setApiCurrentPage(apiCurrentPage + 1);
                        }
                      }}
                      className="btn pb-1 pt-0.5 pl-6 pr-6 text_c font-bold rounded-lg next-pre-btn float-right"
                    >
                      <img src={right_arrow} className="right" />
                    </button>
                  </div>
                </div>
              </div>

              {activeDocInfo?.id ? (
                <div className="pdf_box3">
                  <h4 className="text-xl font-bold  mb-2 text_c text-center">
                    {" "}
                    {activeDocInfo?.id ? activeDocInfo.name : ""}
                  </h4>
                  {docUrl && (
                    <div className="box_u1 p-4">
                      <div className="flex justify-start pb-2">
                        <button
                          className="bg-[#606060] font-semibold text-white p-1 pl-2 pr-2 rounded-lg"
                          onClick={(e) => {
                            e.stopPropagation();
                            const newState = documentListData.map((obj) => {
                              return { ...obj, isShowDoc: false };
                            });
                            setDocumentListData(newState);
                            setDocUrl("");
                          }}
                        >
                          X
                        </button>
                      </div>
                      <div className="box_u2 sele_scroll">
                        <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.13.216/build/pdf.worker.min.js">
                          {loading ? (
                            <LoadingScreen />
                          ) : (
                            <>
                              {docUrl && (
                                <Viewer
                                  fileUrl={`${docUrl}?${highlightToken.sas_token}`}
                                  plugins={[
                                    defaultLayoutPluginInstance,
                                    // searchPluginInstance,
                                  ]}
                                  onDocumentAskPassword={(e) => {}}
                                  renderError={() => (
                                    <div className="grid place-items-center h-full w-full">
                                      <h1 className="font-semibold">
                                        {t(
                                          "common.Contract indexing in progress. Check back later"
                                        )}
                                      </h1>
                                    </div>
                                  )}
                                  renderLoader={() => <LoadingScreen />}
                                />
                              )}
                            </>
                          )}
                        </Worker>
                      </div>
                    </div>
                  )}
                  <div className="flex justify-between mt-5">
                    <h4 className="text-xl font-bold  mb-2 text_c">
                      {" "}
                      <img src={contact_d} className="mr-1 w_d1" alt="" />{" "}
                      {t("allModule.Recipients")}
                    </h4>
                    <AddContactPopup
                      docId={""}
                      onClick={(e) => {
                        e.stopPropagation();
                      }}
                      className="btn btn_theme btn-sm mr-1 wh_spab mb-2"
                      data-tip={t("allModule.Add Contact")}
                    >
                      {/* {t('constant.Download document')} */}
                    </AddContactPopup>
                    {/* <button
                      onClick={(e) => {
                        e.stopPropagation();
                        navigate("/contact-book/contact");
                        setActiveLink("ContactBookForm");
                      }}
                      className="btn btn_theme btn-sm mr-1 wh_spab mb-2"
                    >
                      {t("allModule.Add Contact")}
                    </button> */}
                  </div>

                  <div className=" mb-4">
                    <div className="set_sekl w-100">
                      <div className="searh" style={{ width: "70%" }}>
                        <Select
                          options={receipientListData}
                          placeholder={
                            contactSearchBy === "contact_search"
                              ? t("allModule.Search by name or email")
                              : t("allModule.Search by group name")
                          }
                          styles={customStyles}
                          isMulti={isMultiUserSelect}
                          isSearchable={true}
                          onChange={(arr) => {
                            if (contactSearchBy === "group_search") {
                              setSelectedRecipients([arr]);
                              getGroupMemberList(arr);
                            } else {
                              setSelectedRecipients([...arr]);
                              let tempArr = [];
                              arr.forEach((obj) =>
                                tempArr.push({
                                  id: obj.id,
                                  email: obj.email,
                                  phone: obj.phone,
                                  name: obj.name,
                                  signingCapacity: obj.signingCapacity,
                                  typeOfSignature: obj.typeOfSignature,
                                  errorMsg: "",
                                })
                              );

                              setRecipientDetail(tempArr);
                            }
                          }}
                          isClearable={false}
                          className="w-full text-primary rounded-lg"
                          value={selectedRecipients}
                        />
                      </div>
                      <div className="sekecc w-50" style={{ width: "30%" }}>
                        <select
                          value={contactSearchBy}
                          onChange={(e) => {
                            setSelectedRecipients([]);
                            setRecipientDetail([]);
                            if (e.target.value === "group_search") {
                              setIsMultiUserSelect(false);
                            } else {
                              setIsMultiUserSelect(true);
                            }
                            setContactSearchBy(e.target.value);
                          }}
                          className="border-2 border-primary rounded-lg outline-none p-0.5 pl-2 heg4 w-full"
                        >
                          <option value="contact_search">
                            {t("allModule.Search by contact")}
                          </option>
                          <option value="group_search">
                            {t("allModule.Search by group")}
                          </option>
                        </select>
                      </div>
                    </div>
                    <p className="error_msg">{receipientError}</p>
                  </div>
                  {defaultRecipientInfo && (
                    <div className="set_checkk border-2 border-primary p-3 rounded-lg mb-3 default-recipient-info">
                      <input type="checkbox" defaultChecked />
                      <div className="haed_1">
                        <div className="flex">
                          <img className="ridh2 mr-1" src={right_arrow2} />
                          <h4 className="text-md font-bold">
                            {defaultRecipientInfo.name !== undefined
                              ? defaultRecipientInfo.name
                              : selfInfo.name}{" "}
                          </h4>
                          <span className="ml-5">
                            [{t("allModule.Email")}:{" "}
                            {defaultRecipientInfo.email}] [
                            {t("allModule.Mobile No")}.{" "}
                            {defaultRecipientInfo.phone}]
                          </span>

                          <div className="ml-5  is-deligacy-checkbox">
                            <input
                              style={{ cursor: "pointer" }}
                              type="checkbox"
                              id="is_deligacy_id"
                              checked={defaultRecipientInfo.isDeligacy}
                              onChange={(e) => {
                                if (!e.target.checked) {
                                  setSelfInfo({
                                    ...selfInfo,
                                    isKeepMe: e.target.checked,
                                  });
                                } else {
                                  setSelfInfo({
                                    ...selfInfo,
                                    isKeepMe: e.target.checked,
                                    signingCapacity: "",
                                    typeOfSignature: "",
                                  });
                                }
                                setDefaultRecipientInfo({
                                  ...defaultRecipientInfo,
                                  isDeligacy: e.target.checked,
                                  deligaceContactId:
                                    deligateeListData.length == 1
                                      ? deligateeListData[0].id
                                      : 0,
                                });
                              }}
                            />
                            <label
                              style={{ cursor: "pointer" }}
                              htmlFor="is_deligacy_id"
                              className="ml-2"
                            >
                              {t("allModule.Set Delegatee")}
                            </label>

                            {defaultRecipientInfo.isDeligacy && (
                              <select
                                className="border-2 border-primary rounded-lg outline-none p-0.5 pl-2  ml-2"
                                value={defaultRecipientInfo.deligaceContactId}
                                onChange={(e) => {
                                  setDefaultRecipientInfo({
                                    ...defaultRecipientInfo,
                                    deligaceContactId: e.target.value,
                                  });
                                }}
                              >
                                <option value="0">
                                  {t("allModule.Select Delegatee")}
                                </option>
                                {deligateeListData.map((recipient, index) => {
                                  return (
                                    <option
                                      key={recipient.id}
                                      value={recipient.id}
                                    >
                                      {recipient.name}
                                    </option>
                                  );
                                })}
                              </select>
                            )}
                          </div>
                          <span className="ml-5 error_msg">
                            {defaultRecipientInfo.errorMsg}
                          </span>
                        </div>
                        <div className="flex">
                          <img src={right_arrow} alt="" className="right" />
                        </div>
                      </div>

                      <div className="hhh_us">
                        <div className="grid grid-cols-2 gap-x-4 mt-3">
                          <div className="">
                            <select
                              value={defaultRecipientInfo.signingCapacity}
                              onChange={(e) => {
                                setDefaultRecipientInfo({
                                  ...defaultRecipientInfo,
                                  signingCapacity: e.target.value,
                                });
                              }}
                              className="border-2 border-primary rounded-lg outline-none p-0.5 pl-2 heg4 w-full"
                            >
                              <option value="">
                                {t("allModule.Select Signing Capacity")}
                              </option>
                              <option value="Signer">
                                {t("allModule.Signer")}
                              </option>
                              <option value="Witness">
                                {t("allModule.Witness")}
                              </option>
                              <option value="ConsentingParty">
                                {t("allModule.Consenting Party")}
                              </option>
                              {recipientDetail.length > 0 &&
                                defaultRecipientInfo.isDeligacy == false && (
                                  <option value="Stakeholder">
                                    {t("form.Stakeholder")}
                                  </option>
                                )}
                            </select>
                          </div>
                          <div className="">
                            <select
                              value={defaultRecipientInfo.typeOfSignature}
                              onChange={(e) => {
                                setDefaultRecipientInfo({
                                  ...defaultRecipientInfo,
                                  typeOfSignature: e.target.value,
                                });
                              }}
                              className="border-2 border-primary rounded-lg outline-none p-0.5 pl-2 heg4 w-full"
                            >
                              <option value="">
                                {t("allModule.Select Type of Signature")}
                              </option>
                              {defaultRecipientInfo.signingCapacity ===
                              "Stakeholder" ? (
                                <>
                                  <option value="Approver">
                                    {t("form.Approver")}
                                  </option>
                                  <option value="Reviewer">
                                    {t("form.Reviewer")}
                                  </option>
                                </>
                              ) : (
                                <>
                                  <option value="E-signature">
                                    {t("allModule.E-signature")}
                                  </option>
                                  {permissionList.aadhar_on && (
                                    <option value="Aadhaar_Digital_Signature">
                                      {t("allModule.Aadhaar Digital Signature")}
                                    </option>
                                  )}
                                </>
                              )}
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}

                  {selfInfo.isKeepMe && (
                    <div className="set_checkk border-2 border-primary p-3 rounded-lg mb-3 default-recipient-info">
                      <input type="checkbox" defaultChecked />
                      <div className="haed_1">
                        <div className="flex">
                          <img className="ridh2 mr-1" src={right_arrow2} />
                          <h4 className="text-md font-bold">
                            {selfInfo.name}{" "}
                          </h4>
                          <span className="ml-5">
                            [{t("allModule.Email")}: {selfInfo.email}] [
                            {t("allModule.Mobile No")}. {selfInfo.phone}]
                          </span>
                          <span className="ml-5 error_msg">
                            {selfInfo.errorMsg}
                          </span>
                        </div>
                        <div className="flex">
                          <img
                            src={delete_u}
                            alt=""
                            className="delett delete-icon"
                            onClick={(e) => {
                              e.stopPropagation();
                              setSelfInfo({
                                ...selfInfo,
                                isKeepMe: false,
                                signingCapacity: "",
                                typeOfSignature: "",
                              });
                            }}
                          />
                          <img src={right_arrow} alt="" className="right" />
                        </div>
                      </div>

                      <div className="hhh_us">
                        <div className="grid grid-cols-2 gap-x-4 mt-3">
                          <div className="">
                            <select
                              value={selfInfo.signingCapacity}
                              onChange={(e) => {
                                setSelfInfo({
                                  ...selfInfo,
                                  signingCapacity: e.target.value,
                                });
                              }}
                              className="border-2 border-primary rounded-lg outline-none p-0.5 pl-2 heg4 w-full"
                            >
                              <option value="">
                                {t("allModule.Select Signing Capacity")}
                              </option>
                              <option value="Signer">
                                {t("allModule.Signer")}
                              </option>
                              <option value="Witness">
                                {t("allModule.Witness")}
                              </option>
                              <option value="ConsentingParty">
                                {t("allModule.Consenting Party")}
                              </option>
                              <option value="Stakeholder">
                                {t("form.Stakeholder")}
                              </option>
                            </select>
                          </div>
                          <div className="">
                            <select
                              value={selfInfo.typeOfSignature}
                              onChange={(e) => {
                                setSelfInfo({
                                  ...selfInfo,
                                  typeOfSignature: e.target.value,
                                });
                              }}
                              className="border-2 border-primary rounded-lg outline-none p-0.5 pl-2 heg4 w-full"
                            >
                              <option value="">
                                {t("allModule.Select Type of Signature")}
                              </option>
                              {selfInfo.signingCapacity === "Stakeholder" ? (
                                <>
                                  <option value="Approver">
                                    {t("form.Approver")}
                                  </option>
                                  <option value="Reviewer">
                                    {t("form.Reviewer")}
                                  </option>
                                </>
                              ) : (
                                <>
                                  <option value="E-signature">
                                    {t("allModule.E-signature")}
                                  </option>
                                  {permissionList.aadhar_on && (
                                    <option value="Aadhaar_Digital_Signature">
                                      {t("allModule.Aadhaar Digital Signature")}
                                    </option>
                                  )}
                                </>
                              )}
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}

                  {recipientDetail.map((recipient, index) => {
                    return (
                      <div
                        key={"recipient_" + index}
                        className="set_checkk border-2 border-primary p-3 rounded-lg mb-3"
                      >
                        <input type="checkbox" defaultChecked />
                        <div className="haed_1">
                          <div className="flex">
                            <img className="ridh2 mr-1" src={right_arrow2} />
                            <h4 className="text-md font-bold">
                              {recipient.name}{" "}
                            </h4>
                            <span className="ml-5">
                              [{t("allModule.Email")}: {recipient.email}] [
                              {t("allModule.Mobile No")}. {recipient.phone}]
                            </span>
                            <span className="ml-5 error_msg">
                              {recipient.errorMsg}
                            </span>
                          </div>
                          <div className="flex">
                            <img
                              src={delete_u}
                              alt=""
                              className="delett delete-icon"
                              onClick={(e) => {
                                e.stopPropagation();
                                removeRecipient(recipient.id);
                              }}
                            />
                            <img src={right_arrow} alt="" className="right" />
                          </div>
                        </div>

                        <div className="hhh_us">
                          <div className="grid grid-cols-2 gap-x-4 mt-3">
                            <div className="">
                              <select
                                value={recipient.signingCapacity}
                                onChange={(e) => {
                                  recipient.signingCapacity = e.target.value;
                                  setRecipientDetail([...recipientDetail]);
                                  handleTypeAndCapacityChange(
                                    recipient.id,
                                    index,
                                    "signingCapacity",
                                    e.target.value
                                  );
                                }}
                                className="border-2 border-primary rounded-lg outline-none p-0.5 pl-2 heg4 w-full"
                              >
                                <option value="">
                                  {t("allModule.Select Signing Capacity")}
                                </option>
                                <option value="Signer">
                                  {t("allModule.Signer")}
                                </option>
                                <option value="Witness">
                                  {t("allModule.Witness")}
                                </option>
                                <option value="ConsentingParty">
                                  {t("allModule.Consenting Party")}
                                </option>
                                <option value="Stakeholder">
                                  {t("form.Stakeholder")}
                                </option>
                              </select>
                            </div>
                            <div className="">
                              <select
                                value={recipient.typeOfSignature}
                                onChange={(e) => {
                                  recipient.typeOfSignature = e.target.value;
                                  setRecipientDetail([...recipientDetail]);
                                  handleTypeAndCapacityChange(
                                    recipient.id,
                                    index,
                                    "typeOfSignature",
                                    e.target.value
                                  );
                                }}
                                className="border-2 border-primary rounded-lg outline-none p-0.5 pl-2 heg4 w-full"
                              >
                                <option value="">
                                  {t("allModule.Select Type of Signature")}
                                </option>
                                {recipient.signingCapacity == "Stakeholder" ? (
                                  <>
                                    <option value="Approver">
                                      {t("form.Approver")}
                                    </option>
                                    <option value="Reviewer">
                                      {t("form.Reviewer")}
                                    </option>
                                  </>
                                ) : (
                                  <>
                                    <option value="E-signature">
                                      {t("allModule.E-signature")}
                                    </option>
                                    {permissionList.aadhar_on && (
                                      <option value="Aadhaar_Digital_Signature">
                                        {t(
                                          "allModule.Aadhaar Digital Signature"
                                        )}
                                      </option>
                                    )}
                                  </>
                                )}
                              </select>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              ) : (
                <div className="no_document_available_box">
                  <p>{t("allModule.Please select document")}.</p>
                </div>
              )}
            </div>
          </div>
          {activeDocInfo?.id && (
            <div className="set_btn2 mt-4">
              <button
                onClick={(e) => {
                  e.preventDefault();
                  navigate("/upload-documents");
                  setShowDropdown("CreateDocument");
                  setActiveLink("ContractForm");
                }}
                className="btn pb-1 pt-0.5 pl-6 pr-6 text_c font-bold rounded-lg btn_theme3"
              >
                {t("allModule.Previous")}
              </button>

              <button
                onClick={(e) => {
                  submitDocumentStep1();
                }}
                className="btn pb-1 pt-0.5 pl-6 pr-6 text_c font-bold rounded-lg btn_theme3"
              >
                {t("common.Next")}
              </button>
            </div>
          )}

          <AppModal
            title={t(
              "allModule.Are you sure you want to remove the document from this page?"
            )}
            bodyText={t(
              "allModule.Note: This will not delete the document permanently"
            )}
            setIsOpen={setIsOpen}
            modalIsOpen={modalIsOpen}
            modelAction={`RecipientDelete`}
            deleteDocumentConfimed={deleteDocumentConfimed}
          />
        </>
      ) : (
        <div className="no_document_available_box">
          <p>{t("allModule.No Assigned Document Available.")}</p>
        </div>
      )}

      <AppModal
        title={modalData.title}
        bodyText={modalData.bodyText}
        setIsOpen={setIsOpenAadharPurchase}
        modalIsOpen={isOpenAadharPurchase}
        modelAction="RecipientScreen"
      />

      <AppModal
        title={t("allModule.Notification")}
        bodyText={t(
          "allModule.Please ensure that a counterparts clause is included in your contract"
        )}
        setIsOpen={setIsAadhaarSignee}
        modalIsOpen={isAadhaarSignee}
        modelAction="RecipientScreenIfAadhaar"
        aadhaarSigneeNConfirmation={aadhaarSigneeNConfirmation}
      />
    </div>
  );
};

export default Recipient;
