import React, { useEffect, useContext, useState } from 'react';
import ReactTooltip from 'react-tooltip';
import FormContext from '../../context/FormContext';
import AppModal from '../modals/Modal';
import 'react-tagsinput/react-tagsinput.css';
import useAxios from '../../hooks/useAxios';
import HelpIcon from '../../assets/icons/Help-sm.svg';
import { resolve } from '../../api/resolve';

const SectionTaskForm = () => {
  const api = useAxios();
  const {
    modalData,
    setIsOpen,
    modalIsOpen,
    isEditing,
    editFormData,
    saveOrEditTask,
  } = useContext(FormContext);

  const [sectionDetail, setSectionDetail] = useState({
    taskName: '',
    taskDescription: '',
    sectionId: '',
    tool_tip: '',
  });

  const [sectionError, setSectionError] = useState({
    taskName: '',
    taskDescription: '',
    sectionId: '',
    tool_tip: '',
  });
  const [taskId, setTaskId] = useState(0);
  const [sectionList, setSectionList] = useState([]);

  const submitData = async () => {
    for (const key in sectionError) {
      if (sectionError.hasOwnProperty(key)) {
        sectionError[key] = "";
      }
    }
    if (sectionDetail.sectionId < 1) {
      setSectionError({ ...sectionError, sectionId: 'Section is required' })
    }else if (sectionDetail.taskName.trim().length < 1) {
      setSectionError({ ...sectionError, taskName: 'Prompt is required' })
    }else if (sectionDetail.taskDescription.trim().length < 1) {
      setSectionError({ ...sectionError, taskDescription: 'Task description is required' })
    }else if (sectionDetail.tool_tip.trim().length < 1) {
      setSectionError({ ...sectionError, tool_tip: 'Task tooltip is required' })
    }else{
      setSectionError({ ...sectionError, taskName: '', sectionId: '', taskDescription: '', tool_tip: '' })  
      let postData = {
        "TaskId": taskId,
        "description": sectionDetail.taskDescription,
        "SectionId": sectionDetail.sectionId,
        "tool_tip": sectionDetail.tool_tip,
      }
      if(isEditing){
        postData["TaskName"] = sectionDetail.taskName;
      }else{
        postData["TaskDetail"] = [sectionDetail.taskName];
      }      
      saveOrEditTask(postData);
    }
  }

  const getSectionList = async (clearText) => {

    const res = await resolve(
        api
            .get(
                `nda_report/diligence/section/list/` 
            )
            .then((res) => res.data)
    );
    if (res.data) {
        const { data } = res;
        const { results, count } = data;   
        setSectionList(results);
    } 
};

  useEffect(() => {
    if (isEditing) {
      setSectionDetail({
        taskName: editFormData.task_name,
        taskDescription: editFormData.description?editFormData.description:"",
        sectionId: editFormData.section_id,
        tool_tip: editFormData.tool_tip?editFormData.tool_tip:"",
      });
      setTaskId(editFormData.id);
    }
  }, [isEditing, editFormData]);

  useEffect(() => {
    getSectionList()
  }, []);


  return (
    <div className='text-primary text-base font-normal font-sans'>
      <div className="main_head">
        <h1 className='text-4xl font-bold'>
          {!isEditing ? 'Add Task' : 'Edit Task'}
        </h1>
      </div>

      <div className=' flex w-full items-center h-full'>
        <form className='w-2/5'>
          <div className='flex flex-col mb-3'>
            <div className='flex'>
              <label className='mb-2'>Section Name</label>
              <>
                <img
                  src={HelpIcon}
                  width={12}
                  className='ml-1 -mt-2 cursor-pointer'
                  data-tip='select section name'
                />
                <ReactTooltip backgroundColor='#400835' textColor='#ffffff' />
              </>
            </div>
            <select
              className='border-2 border-primary rounded-lg outline-none p-1 pl-2'
              value={sectionDetail.sectionId}
              onChange={(e) => {
                setSectionDetail({ ...sectionDetail, sectionId: e.target.value });
              }}
            >
              <option value={0}>Select Section</option>
              {sectionList.map((item,index) => (
                <option key={index} value={item.id}>{item.section_name}</option>
              ))}
            </select>
            <p className='text-sm text-red-500 font-normal'>{sectionError.sectionId}</p>
            
          </div>
          <div className='flex flex-col mb-3'>
            <div className='flex'>
              <label className='mb-2'>Prompt</label>
              <>
                <img
                  src={HelpIcon}
                  width={12}
                  className='ml-1 -mt-2 cursor-pointer'
                  data-tip='Prompt'
                />
                <ReactTooltip backgroundColor='#400835' textColor='#ffffff' />
              </>
            </div>
            <input
              placeholder='Prompt'
              className='border-2 border-primary rounded-lg outline-none p-1 pl-2'
              value={sectionDetail.taskName}
              onChange={(e) => {
                setSectionDetail({ ...sectionDetail, taskName: e.target.value });
              }}
            />
            <p className='text-sm text-red-500 font-normal'>{sectionError.taskName}</p>
          </div>

          <div className='flex flex-col mb-3'>
            <div className='flex'>
              <label className='mb-2'>Task Description</label>
              <>
                <img
                  src={HelpIcon}
                  width={12}
                  className='ml-1 -mt-2 cursor-pointer'
                  data-tip='Insert task description'
                />
                <ReactTooltip backgroundColor='#400835' textColor='#ffffff' />
              </>
            </div>
            <input
              placeholder='Insert task description'
              className='border-2 border-primary rounded-lg outline-none p-1 pl-2'
              value={sectionDetail.taskDescription}
              onChange={(e) => {
                
                setSectionDetail({ ...sectionDetail, taskDescription: e.target.value });
              }}
            />
            <p className='text-sm text-red-500 font-normal'>{sectionError.taskDescription}</p>
          </div>

          <div className='flex flex-col mb-3'>
            <div className='flex'>
              <label className='mb-2'>Task Tooltip</label>
              <>
                <img
                  src={HelpIcon}
                  width={12}
                  className='ml-1 -mt-2 cursor-pointer'
                  data-tip='Insert task tooltip'
                />
                <ReactTooltip backgroundColor='#400835' textColor='#ffffff' />
              </>
            </div>
            <input
              placeholder='Insert task tooltip'
              className='border-2 border-primary rounded-lg outline-none p-1 pl-2'
              value={sectionDetail.tool_tip}
              onChange={(e) => {
                
                setSectionDetail({ ...sectionDetail, tool_tip: e.target.value });
              }}
            />
            <p className='text-sm text-red-500 font-normal'>{sectionError.tool_tip}</p>
          </div>


          {!isEditing ? (
            <>
              <button
                className='bg-primary font-bold text-white w-full p-1 rounded-lg mt-3'
                onClick={(e) => {
                  e.preventDefault();
                  submitData();
                }}
              >
                Add Task
              </button>

            </>
          ) : (
            <>
              <button
                className='bg-secondary font-bold text-white w-full p-1 rounded-lg'
                onClick={(e) => {
                  e.preventDefault();
                  submitData();
                }}
              >
                Update Task
              </button>
            </>

          )}
        </form>

      </div>

      <AppModal
        title={modalData.title}
        bodyText={modalData.bodyText}
        setIsOpen={setIsOpen}
        modalIsOpen={modalIsOpen}
        modelAction="SectionTaskForm"
        apiResStatus={modalData.apiResStatus}
      />

    </div>
  );
};

export default SectionTaskForm;
