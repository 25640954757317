import React, { useContext } from 'react';
import { Navigate, useParams } from 'react-router-dom';
import AuthContext from '../../context/AuthContext';
import ResetPasswordUI from './ResetPasswordUI';
import jwtDecode from 'jwt-decode';


const ResetPassword = ({ title }) => {
  const { resetPassword, user } = useContext(AuthContext);
  

  const params = useParams();
  if (user) {
    return <Navigate to='/dashboard' />;
  }
  const onSubmit = (password) => {
    const accessTokenData = jwtDecode(params.token);
    const userObj = {
      email: accessTokenData.email,
      password,
      token: params.token,
    };
    resetPassword(userObj);
  };
  return <ResetPasswordUI title={title} onSubmit={onSubmit} />;
};

export default ResetPassword;
