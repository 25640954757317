import React, { useContext, useState, useEffect } from 'react';
import Modal from 'react-modal';
import Select from 'react-select';
import ReactTooltip from 'react-tooltip';
import dayjs from 'dayjs';
import './modal.css';
import useAxios from '../../hooks/useAxios';
import { resolve } from '../../api/resolve';
import DatePickerComponent from '../../shared/DatePickerComponent';
import { dropDownStyles } from '../../constants/styles';
import HelpIcon from '../../assets/icons/Help-sm.svg';
import { formatClause } from '../../utils/utils';
import { useTranslation } from 'react-i18next';

Modal.setAppElement('#root');
const AdvanceSearchModal = ({
  modalIsOpen,
  setIsOpen,
  advanceSearchText,
  setAdvanceSearchText,
  setDropDownData,
  dropDownData,
  startDate,
  endDate,
  setStartDate,
  setEndDate,
  setContracts,
  setPageLoading,
  typesData,
  setTypesData,
  clausesData,
  setClausesData,
  tagsData,
  setTagsData,
  setErrMsg,
  selectedContract,
  setSelectedContract,
  setIsNextPage,
  setIsPrevPage,
  setContractCount,
  getAdvancedSearchData,
}) => {
  const api = useAxios();
  const { t } = useTranslation();
  const [isDisabled, setIsDisabled] = useState(true);
  const processText = (text) => {
    let result = '';
    if (text.length > 0) {
      const newText = text.toLowerCase().replaceAll('clause', '');
      const wordArray = newText.split(' ');
      wordArray.forEach((word) => {
        if (word.length > 0)
          result += ` ${word[0].toUpperCase()}${word.slice(1)}`;
      });
    }
    return result;
  };
  const getTypesData = async () => {
    const types = await resolve(
      api.get('/legacy_contract/get_all_contract_types/').then((res) => res.data)
    );
    if (types.data) {
      const filteredArr = [];
      const { contract_type_list } = types.data;
      contract_type_list.forEach((type) => {
        filteredArr.push({
          value: type.name,
          label: processText(type.name),
          id: type.id,
        });
      });
      setTypesData(filteredArr);
    }
  };
  const getClausesData = async () => {
    const clauses = await resolve(
      api.get('/contract/get_all_contract_clause/').then((res) => res.data)
    );
    if (clauses.data) {
      const filteredArr = [];
      const { contract_clause_list } = clauses.data;
      contract_clause_list.forEach((type) => {
        const formattedText = type.name.split('_').join(' ');
        filteredArr.push({
          value: formattedText,
          label: processText(formattedText),
          id: type.id,
        });
      });
      setClausesData(filteredArr);
    }
  };
  const getTagsData = async () => {
    const tags = await resolve(
      api.get('/legacy_contract/get_all_contract_tags/').then((res) => res.data)
    );
    if (tags.data) {
      const filteredArr = [];
      const { contract_tags_list } = tags.data;
      contract_tags_list.forEach((type) => {
        if (type.name && type.name.length > 0)
          filteredArr.push({
            value: type.name,
            label: processText(type.name),
            id: type.id,
          });
      });
      setTagsData(filteredArr);
    }
  };

  useEffect(() => {
    getTypesData();
    getClausesData();
    getTagsData();
  }, []);
  return (
    <div>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={() => setIsOpen(false)}
        onAfterOpen={() => {
          setDropDownData({
            ...dropDownData,
            selectedType: '',
            selectedClause: '',
            selectedTag: '',
          });
        }}
        style={{
          content: {
            margin: 'auto',
            overflow: 'auto',
            top: 10,
            bottom: 10,
            maxWidth: '80%',
          },
          overlay: {
            backgroundColor: 'rgba(64, 8, 53, 0.9)',
          },
        }}
      >
        <div className='text-primary pl-2 0'>
          <div className='flex'>
            <h1 className='text-2xl font-bold'>{t('modal.Advanced Search')}</h1>
            <>
              <img
                src={HelpIcon}
                width={12}
                className='ml-1 mt-2 cursor-pointer'
                data-tip={t('modal.Search for exact match or with Booleans(AND/OR)')}
              />
              <ReactTooltip backgroundColor='#400835' textColor='#ffffff' />
            </>
          </div>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              getAdvancedSearchData(true);
            }}
          >
            <div className='flex mt-3 advancgh_us'>
              <div className='border-2 border-primary rounded-md rounded-tl-lg rounded-bl-lg bg-inputBackground pl-1 mr-2 w-3/5'>
                <input
                  placeholder={t('allModule.Enter Search Term')}
                  value={advanceSearchText}
                  className='px-1 py-1 focus:outline-none bg-transparent w-full'
                  onChange={(e) => setAdvanceSearchText(e.target.value)}
                />
              </div>
            </div>
            <div className='grid w-full grid-cols-3 mt-10 advancgh_us '>
              <div className='text-primary font-bold text-sm'>
                <div className='flex'>
                  <h2 className='mb-2'>{t('modal.Browse by Contract Type')}</h2>
                  <>
                    <img
                      src={HelpIcon}
                      width={12}
                      className='ml-1 -mt-2 cursor-pointer'
                      data-tip={t('modal.Search by contract types')}
                    />
                    <ReactTooltip
                      backgroundColor='#400835'
                      textColor='#ffffff'
                    />
                  </>
                </div>
                <Select
                  options={typesData}
                  placeholder={t('modal.Type of Contract')}
                  styles={dropDownStyles}
                  isSearchable={true}
                  className='w-4/5 text-primary'
                  onChange={(type) => {
                    setIsDisabled(false);
                    setDropDownData({
                      ...dropDownData,
                      selectedType: type.value,
                    });
                  }}
                />
              </div>
              <div className='text-primary font-bold text-sm'>
                <div className='flex'>
                  <h2 className='mb-2'>{t('modal.Browse by clause')}</h2>
                  <>
                    <img
                      src={HelpIcon}
                      width={12}
                      className='ml-1 -mt-2 cursor-pointer'
                      data-tip={t('modal.Search by contract clauses')}
                    />
                    <ReactTooltip
                      backgroundColor='#400835'
                      textColor='#ffffff'
                    />
                  </>
                </div>
                <Select
                  options={clausesData}
                  placeholder={t('modal.Type of Clause')}
                  styles={dropDownStyles}
                  isSearchable={true}
                  // isDisabled={isDisabled}
                  onChange={(clause) =>
                    setDropDownData({
                      ...dropDownData,
                      selectedClause: clause.value.replaceAll(' ', '_'),
                    })
                  }
                  className='w-4/5 text-primary'
                />
              </div>
              <div className='text-primary font-bold text-sm'>
                <div className='flex'>
                  <h2 className='mb-2'>{t('modal.Browse by Tags')}</h2>
                  <>
                    <img
                      src={HelpIcon}
                      width={12}
                      className='ml-1 -mt-2 cursor-pointer'
                      data-tip={t('modal.Search by contract tags')}
                    />
                    <ReactTooltip
                      backgroundColor='#400835'
                      textColor='#ffffff'
                    />
                  </>
                </div>
                <Select
                  options={tagsData}
                  placeholder={t('allModule.Tag Name')}
                  styles={dropDownStyles}
                  isSearchable={true}
                  onChange={(tag) =>
                    setDropDownData({
                      ...dropDownData,
                      selectedTag: tag.value,
                    })
                  }
                  className='w-4/5 text-primary'
                  // isDisabled={isDisabled}
                />
              </div>
            </div>
            <div className='mt-8 flex flex-col  justify-center items-center'>
              <div className='text-primary font-bold text-sm -ml-6'>
                <div className='flex'>
                  <h2 className='mb-2'>{t('modal.Browse by Date')}</h2>
                  <>
                    <img
                      src={HelpIcon}
                      width={12}
                      className='ml-1 -mt-2 cursor-pointer'
                      data-tip={t('modal.Search by date or date range')}
                    />
                    <ReactTooltip
                      backgroundColor='#400835'
                      textColor='#ffffff'
                    />
                  </>
                </div>
                <DatePickerComponent
                  inline
                  startDate={startDate}
                  setStartDate={setStartDate}
                  endDate={endDate}
                  setEndDate={setEndDate}
                  selectsRange
                />
              </div>
              <div className='w-2/6 mt-3 ful_m'>
                <button
                  className='bg-primary font-semibold text-white w-full py-1 rounded-lg '
                  type='submit'
                >
                  {t('modal.Search Contracts')}
                </button>
              </div>
            </div>
          </form>
        </div>
      </Modal>
    </div>
  );
};

export default AdvanceSearchModal;
