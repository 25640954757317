import React, { useState, useContext } from 'react';
import { useTable } from 'react-table';
import DownArrow from '../../assets/icons/down-arrow.svg';
import FormContext from '../../context/FormContext';
import AppContext from '../../context/AppContext';
import DeleteModal from '../modals/DeleteModal';
import { useTranslation } from 'react-i18next';
import { log } from 'devtools-detector';

export const NoDataComponent = ({ colLength }) => {
  const [rows] = useState(Array(4).fill(''));
  const [cols] = useState(Array(colLength).fill(''));
  return (
    <>
      {rows.map((value, index) => (
        <tr key={index}>
          {cols.map((value01, key01) => (
            <td key={key01} className='px-5 py-2 font-medium text-sm text-center'>--</td>
          ))}
        </tr>
      ))}
    </>
  );
};

const PurchaseListTable = ({
  contractsData,
  contractTableColumns,
  prevPage,
  nextPage,
  currentPage,
  setCurrentPage,
  setSelectedNotification,
  sortingOrder,
  setSortingOrder,
  setSortColumn,
  setIsOpen,
  updateNotificationSeen,
  updateNotificationCleared,
  clearAllNotifications,
}) => {
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({ columns: contractTableColumns, data: contractsData });

  const [isDeleteSingleModalOpen, setIsDeleteSignleModalOpen] = useState(false);
  const [isDeleteAllModalOpen, setIsDeleteAllModalOpen] = useState(false);
  const [rowId, setRowId] = useState(0);
  const { t } = useTranslation();

  const handleSingleDelete = (id) => {
    setRowId(id);
    setIsDeleteSignleModalOpen(true);
  };

  
  const confirmSingleDelete = () => {
    setIsDeleteSignleModalOpen(false);
    updateNotificationCleared(rowId);
  };
  return (
    <>
      <div className='mt-5 flex flex-col table-container'>
        <div>
          <div className='table_desin'>
            <div className='shadow overflow-hidden border-2 border-primary  sm:rounded-lg max-h-96'>
              <table {...getTableProps()} className='w-full '>
                <thead>
                  {headerGroups.map((headerGroup) => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                      {headerGroup.headers.map((column) => (
                        // Add the sorting props to control sorting. For this example
                        // we can add them into the header props

                        <th
                          scope='col'
                          className='text-left px-2 pt-2'
                          {...column
                            .getHeaderProps
                            // column.getSortByToggleProps()
                            ()}
                        >

                          <div className='flex items-center'>
                            <span>{t('constant.' + column.render('Header'))}</span>

                            {column.isSorted !== undefined &&
                              (column.isSorted ? (
                                <span
                                  onClick={(e) => {
                                    setSortingOrder('asc');
                                    setSortColumn(column.id);
                                    column.isSorted = false;
                                  }}
                                  style={{ cursor: 'pointer', marginTop: 2 }}
                                  className='ml-1'
                                >
                                  <img src={DownArrow} />
                                </span>
                              ) : (
                                <span
                                  onClick={(e) => {
                                    setSortingOrder('desc');
                                    setSortColumn(`${column.id}`);
                                    column.isSorted = true;
                                  }}
                                  style={{
                                    cursor: 'pointer',
                                    transform: 'rotate(180deg)',
                                  }}
                                  className='ml-1'
                                >
                                  <img src={DownArrow} />
                                </span>
                              ))
                            }
                          </div>
                        </th>
                      ))}
                      
                    </tr>
                  ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                  {contractsData.length < 1 && (
                    <NoDataComponent colLength={contractTableColumns.length} />
                  )}
                  {contractsData.length > 0 &&
                    rows.map((row, i) => {
                      // new
                      prepareRow(row);
                      return (
                        <tr
                          {...row.getRowProps()}
                          className={`text-center `}
                          onClick={() => { }}
                        >
                          {row.cells.map((cell) => {
                            return (
                              <>
                                <td
                                  {...cell.getCellProps()}
                                  className={`px-2 py-2 font-medium text-sm contracts-col-data  ${cell.value === 'Not Seen' && 'text-red-500'
                                    }`}
                                >
                                  {cell.value === 'Seen' ? (
                                    <span className='text-lg'>&#10003;</span>
                                  ) : cell.value === 'Not Seen' ? (
                                    <span className='text-lg'>&#10008;</span>
                                  ) : (
                                    <span
                                    className='text_fixed'
                                    >{cell.render('Cell')}</span>
                                  )}
                                </td>
                              </>
                            );
                          })}
                         
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      {contractsData.length > 0 && (
        <div className='mt-3 flex justify-end'>
         
          <div className='flex text-primary  border-t-2 border-b-2 border-primary rounded-lg'>
            <button
              className={`btn-sm border-r-2 border-l-2 border-primary font-semibold rounded-tl-lg rounded-bl-lg ${!prevPage && 'bg-inputBackground'
                }`}
              disabled={!prevPage}
              onClick={() => setCurrentPage(currentPage - 1)}
            >
              {t('common.Prev')}
            </button>
            <span className='btn-sm font-semibold'>{currentPage}</span>
            <button
              className={`btn-sm border-l-2 border-r-2 border-primary font-semibold rounded-tr-lg rounded-br-lg ${!nextPage && 'bg-inputBackground'
                }`}
              disabled={!nextPage}
              onClick={() => { setCurrentPage(currentPage + 1)}}
            >
              {t('common.Next')}
            </button>
          </div>
        </div>
      )}
     
    </>
  );
};

export default PurchaseListTable;
