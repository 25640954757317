export const appName = "QUOQO";

export const delay = 300;

export const autoCloseTimer = 3000;

export const companyTableColumns = [
  {
    Header: "Company",
    accessor: "name", // accessor is the "key" in the data
    isSorted: true,
  },
  {
    Header: "Date Created",
    accessor: "dateCreated",
    isSorted: true,
  },
  {
    Header: "Status",
    accessor: "status",
    isSorted: true,
  },
];

export const office365SettingTableColumns = [
  {
    Header: "Client Id",
    accessor: "clientId", // accessor is the "key" in the data
    isSorted: true,
  },
  {
    Header: "Tenant Id",
    accessor: "tenantId",
    isSorted: true,
  },
  {
    Header: "User Id",
    accessor: "redirectUrl",
    isSorted: true,
  },
  {
    Header: "Secret Key",
    accessor: "secretKey",
    isSorted: true,
  },
];
export const adminTableColumns = [
  {
    Header: "User Name",
    accessor: "first_name", // accessor is the "key" in the data
    isSorted: true,
  },
  {
    Header: "Email",
    accessor: "email", // accessor is the "key" in the data
    isSorted: true,
  },
  {
    Header: "Company",
    accessor: "company_name", // accessor is the "key" in the data
    isSorted: true,
  },
  {
    Header: "Date Created",
    accessor: "dateCreated",
    isSorted: true,
  },
  {
    Header: "Status",
    accessor: "status",
    isSorted: true,
  },
];
export const userTableColumns = [
  {
    Header: "User Name",
    accessor: "first_name", // accessor is the "key" in the data
    isSorted: true,
  },
  {
    Header: "Email",
    accessor: "email", // accessor is the "key" in the data
  },
  {
    Header: "Phone Number",
    accessor: "phone", // accessor is the "key" in the data
  },
  {
    Header: "Designation",
    accessor: "designation", // accessor is the "key" in the data
  },
  {
    Header: "Activate Status",
    accessor: "status", // accessor is the "key" in the data
  },
];

export const reviewDocTableColumns = [
  {
    Header: "Document Name",
    accessor: "name", // accessor is the "key" in the data
    isSorted: true,
  },
  {
    Header: "Review Type",
    accessor: "type", // accessor is the "key" in the data
    isSorted: true,
  },
];

export const adminListTableColumns = [
  {
    Header: "User Name",
    accessor: "first_name", // accessor is the "key" in the data
    isSorted: true,
  },
  {
    Header: "Email",
    accessor: "email", // accessor is the "key" in the data
  },
  {
    Header: "Phone Number",
    accessor: "phone", // accessor is the "key" in the data
  },
  {
    Header: "Document Status",
    accessor: "document_status", // accessor is the "key" in the data
    isSorted: true,
  },
  {
    Header: "Plan Name",
    accessor: "plan_name", // accessor is the "key" in the data
  },
  {
    Header: "Paid Amount",
    accessor: "paid_amount", // accessor is the "key" in the data
  },
  {
    Header: "Valid Till",
    accessor: "valid_till", // accessor is the "key" in the data
  },
];

export const mainUserListTableColumns = [
  {
    Header: "First Name",
    accessor: "first_name", // accessor is the "key" in the data
    isSorted: true,
  },
  {
    Header: "Last Name",
    accessor: "last_name", // accessor is the "key" in the data
    isSorted: true,
  },
  {
    Header: "Email",
    accessor: "email", // accessor is the "key" in the data
  },
  {
    Header: "Phone Number",
    accessor: "phone", // accessor is the "key" in the data
  },
  {
    Header: "Activate Status",
    accessor: "status", // accessor is the "key" in the data
  },
  {
    Header: "Last login",
    accessor: "date", // accessor is the "key" in the data
    isSorted: true,
  },
  {
    Header: "Plan Name",
    accessor: "plan_name", // accessor is the "key" in the data
  },
  {
    Header: "Paid Amount",
    accessor: "paid_amount", // accessor is the "key" in the data
  },
  {
    Header: "Valid Till",
    accessor: "valid_till", // accessor is the "key" in the data
  },
];

export const mainUserListTableColumns02 = [
  {
    Header: "First Name",
    accessor: "first_name", // accessor is the "key" in the data
    isSorted: true,
  },
  {
    Header: "Last Name",
    accessor: "last_name", // accessor is the "key" in the data
    isSorted: true,
  },
  {
    Header: "Email",
    accessor: "email", // accessor is the "key" in the data
  },
  {
    Header: "Phone Number",
    accessor: "phone", // accessor is the "key" in the data
  },
  {
    Header: "Activate Status",
    accessor: "status", // accessor is the "key" in the data
  },
  {
    Header: "Last login",
    accessor: "date", // accessor is the "key" in the data
    isSorted: true,
  },
];

export const DemoUserListTableColumns = [
  {
    Header: "First Name",
    accessor: "name", // accessor is the "key" in the data
    isSorted: true,
  },
  {
    Header: "Email",
    accessor: "email", // accessor is the "key" in the data
  },
  {
    Header: "Designation",
    accessor: "designation", // accessor is the "key" in the data
  },
  {
    Header: "Created At",
    accessor: "create_at", // accessor is the "key" in the data
    isSorted: true,
  },
  {
    Header: "Plan Name",
    accessor: "plan_name", // accessor is the "key" in the data
  },
  {
    Header: "Paid Amount",
    accessor: "paid_amount", // accessor is the "key" in the data
  },
  {
    Header: "Valid Till",
    accessor: "valid_till", // accessor is the "key" in the data
  },
  {
    Header: "Active Users",
    accessor: "is_active", // accessor is the "key" in the data
  },
];

export const DemoUser2ListTableColumns = [
  {
    Header: "First Name",
    accessor: "name", // accessor is the "key" in the data
    isSorted: true,
  },
  {
    Header: "Email",
    accessor: "email", // accessor is the "key" in the data
  },
  {
    Header: "Designation",
    accessor: "designation", // accessor is the "key" in the data
  },
  {
    Header: "Document Status",
    accessor: "document_status", // accessor is the "key" in the data
  },
  {
    Header: "Created At",
    accessor: "create_at", // accessor is the "key" in the data
    isSorted: true,
  },
  {
    Header: "Plan Name",
    accessor: "plan_name", // accessor is the "key" in the data
  },
  {
    Header: "Paid Amount",
    accessor: "paid_amount", // accessor is the "key" in the data
  },
  {
    Header: "Valid Till",
    accessor: "valid_till", // accessor is the "key" in the data
  },
  {
    Header: "Active Users",
    accessor: "is_active", // accessor is the "key" in the data
  },
];

export const DemoUser3ListTableColumns = [
  {
    Header: "First Name",
    accessor: "name", // accessor is the "key" in the data
    isSorted: true,
  },
  {
    Header: "Email",
    accessor: "email", // accessor is the "key" in the data
  },
  {
    Header: "Created At",
    accessor: "create_at", // accessor is the "key" in the data
    isSorted: true,
  },
];
export const corporateTransactionListTableColumns = [
  {
    Header: "Transaction No",
    accessor: "Transaction_no", // accessor is the "key" in the data
    isSorted: true,
  },
  {
    Header: "Amount",
    accessor: "paid_amount", // accessor is the "key" in the data
  },
  {
    Header: "Bank Name",
    accessor: "bank_name", // accessor is the "key" in the data
  },
  {
    Header: "Cheque No",
    accessor: "cheque_no", // accessor is the "key" in the data
    isSorted: true,
  },
  {
    Header: "Payment Mode",
    accessor: "mode_of_payment", // accessor is the "key" in the data
    isSorted: true,
  },

  {
    Header: "Pyament Date",
    accessor: "date_of_payment", // accessor is the "key" in the data
    isSorted: true,
  },
];

export const contractTableColumns = [
  {
    Header: "Contract Title",
    accessor: "name", // accessor is the "key" in the data
    isSorted: true,
    // width: "350px",
  },
  {
    Header: "Created By",
    accessor: "uploaded_user_name", // accessor is the "key" in the data
    isSorted: true,
  },
  {
    Header: "Date Uploaded",
    accessor: "created_at", // accessor is the "key" in the data
    isSorted: true,
    // width: 50,
  },
  // {
  //   Header: 'Tag',
  //   accessor: 'tags',
  //   isSorted: true,
  // },
  {
    Header: "Contract Type",
    accessor: "type",
    isSorted: true,
    width: "10px",
  },
  {
    Header: "Renewal Date",
    accessor: "renewal_date",
    isSorted: true,
    // width: '50px',
  },
];
export const notificationTableColumns = [
  {
    Header: "Title",
    accessor: "title", // accessor is the "key" in the data
  },
  {
    Header: "Date",
    accessor: "date", // accessor is the "key" in the data
    isSorted: true,
  },
  {
    Header: "Read",
    accessor: "read", // accessor is the "key" in the data
    width: "10",
  },
];
export const notificationTableColumnslegacy = [
  {
    Header: "Title",
    accessor: "title", // accessor is the "key" in the data
  },
  {
    Header: "Date",
    accessor: "date", // accessor is the "key" in the data
    isSorted: true,
  },
  {
    Header: "Read",
    accessor: "read", // accessor is the "key" in the data
  },
];

export const notificationListColumns = [
  {
    Header: "Contract Name",
    accessor: "name", // accessor is the "key" in the data
    isSorted: true,
  },
  // {
  //   Header: 'Repository Name',
  //   accessor: 'repo_name', // accessor is the "key" in the data
  //   isSorted: true,
  // },
];
export const PurchaseListTableColumns = [
  {
    Header: "Plan Name",
    accessor: "plan_name", // accessor is the "key" in the data
  },
  {
    Header: "Plan Price",
    accessor: "plan_unit_price", // accessor is the "key" in the data
  },
  {
    Header: "Original Licence Quantity",
    accessor: "original_plan_qty", // accessor is the "key" in the data
  },
  {
    Header: "Remaining Licence Quantity",
    accessor: "plan_quantity", // accessor is the "key" in the data
  },
  {
    Header: "Total Paid Amount",
    accessor: "total_paid_amount", // accessor is the "key" in the data
  },
  {
    Header: "Purchase Date",
    accessor: "date", // accessor is the "key" in the data
    isSorted: true,
  },
];
export const adminDashbaoradTableColumns = [
  {
    Header: "Contract Name",
    accessor: "name", // accessor is the "key" in the data
    isSorted: true,
  },
  {
    Header: "Date Created",
    accessor: "created_at", // accessor is the "key" in the data
    isSorted: true,
  },
  {
    Header: "Type of Contract",
    accessor: "type",
    isSorted: true,
  },
];

export const contactTableColumns = [
  {
    Header: "Contact Name",
    accessor: "name",
    isSorted: true,
  },
  {
    Header: "Designation",
    accessor: "designation",
  },
  {
    Header: "Contact Email",
    accessor: "email",
  },
  {
    Header: "Phone Number",
    accessor: "phone",
  },
];

export const documentTableColumns = [
  {
    Header: "Document Name",
    accessor: "name",
    isSorted: true,
  },
  {
    Header: "Status",
    accessor: "status",
    isSorted: true,
  },
  {
    Header: "Created By",
    accessor: "created_by",
  },
  {
    Header: "Last Changes",
    accessor: "updated_at",
  },
];

export const groupTableColumns = [
  {
    Header: "Group Name",
    accessor: "group_name",
    isSorted: true,
  },
];

export const pendingDocTableColumns = [
  {
    Header: "Document Name",
    accessor: "name",
    isSorted: true,
  },
  // {
  //   Header: 'Status',
  //   accessor: 'status',
  //   isSorted: true,
  // },
  // {
  //   Header: 'Created By',
  //   accessor: 'created_by',

  // },
  {
    Header: "Last Changes",
    accessor: "updated_at",
    isSorted: true,
  },
];

export const workflowDocTableColumns = [
  {
    Header: "Document Name",
    accessor: "name",
    isSorted: true,
  },
  // {
  //   Header: 'Status',
  //   accessor: 'status',
  //   isSorted: true,
  // },
  // {
  //   Header: 'Created By',
  //   accessor: 'created_by',
  // },
  {
    Header: "Last Changes",
    accessor: "updated_at",
    isSorted: true,
  },
];

export const completedDocTableColumns = [
  {
    Header: "Document Name",
    accessor: "name",
    isSorted: true,
  },
  // {
  //   Header: 'Status',
  //   accessor: 'status',
  //   isSorted: true,
  // },
  // {
  //   Header: 'Created By',
  //   accessor: 'created_by',
  // },
  {
    Header: "Date of Completion",
    accessor: "updated_at",
    isSorted: true,
  },
];

export const rejectedDocTableColumns = [
  {
    Header: "Document Name",
    accessor: "name",
    isSorted: true,
  },
  // {
  //   Header: 'Status',
  //   accessor: 'status',
  //   isSorted: true,
  // },
  // {
  //   Header: 'Created By',
  //   accessor: 'created_by',
  // },
  {
    Header: "Date of Rejection",
    accessor: "updated_at",
    isSorted: true,
  },
];

export const otherDocTableColumns = [
  {
    Header: "Document Name",
    accessor: "name",
    isSorted: true,
  },
  // {
  //   Header: 'Status',
  //   accessor: 'status',
  //   isSorted: true,
  // },
  {
    Header: "Created By",
    accessor: "created_by",
  },
  {
    Header: "Date of Completion",
    accessor: "updated_at",
    isSorted: true,
  },
];

export const unassignedDocTableColumns = [
  {
    Header: "Select",
    accessor: "checkbox",
  },
  {
    Header: "Document Name",
    accessor: "name",
    isSorted: true,
  },
  // {
  //   Header: 'Status',
  //   accessor: 'status',
  //   isSorted: true,
  // },
  // {
  //   Header: 'Created By',
  //   accessor: 'created_by',

  // },
  {
    Header: "Last Changes",
    accessor: "updated_at",
    isSorted: true,
  },
];

export const unassignedDocOfAdminUserTableColumns = [
  {
    Header: "Document Name",
    accessor: "name",
    isSorted: true,
  },
  {
    Header: "Status",
    accessor: "status",
    isSorted: true,
  },
  {
    Header: "Created By",
    accessor: "created_by",
  },
  {
    Header: "Last Changes",
    accessor: "updated_at",
  },
];

export const deletedDocTableColumns = [
  {
    Header: "Document Name",
    accessor: "name",
    isSorted: true,
  },
  // {
  //   Header: 'Status',
  //   accessor: 'status',
  //   // isSorted: true,
  // },
  {
    Header: "Date of Deletion",
    accessor: "updated_at",
    isSorted: true,
  },
  // {
  //   Header: 'Created By',
  //   accessor: 'created_by',
  // },
];
export const sharedDocTableColumns = [
  {
    Header: "Document Name",
    accessor: "name",
    isSorted: true,
  },
  {
    Header: "Shared Type",
    accessor: "type",
    isSorted: true,
  },
  {
    Header: "Date of Sharing",
    accessor: "updated_at",
    isSorted: true,
  },
];

export const sharedRepoTableColumns = [
  {
    Header: "Repository Name",
    accessor: "name",
    isSorted: true,
  },
  {
    Header: "Shared With",
    accessor: "SharedWith",
    isSorted: true,
  },
  {
    Header: "Expiry Date",
    accessor: "updated_at",
    isSorted: true,
  },
];

export const adminPlanTableColumns = [
  {
    Header: "Plan Name",
    accessor: "name",
  },
  {
    Header: "Company",
    accessor: "company",
  },
  {
    Header: "User",
    accessor: "user",
  },
  {
    Header: "Plan Type",
    accessor: "plan_type",
  },
];

export const typeOfSign = {
  SIGNATURE: 1,
  SEAL: 2,
  PHOTOGRAPH: 3,
  DIGITALSIGNATURE: 4,
  STAMPPAD: 5,
  SIGNATURE_SEAL: 6,
  SIGNATURE_STAMPPAD: 7,
  AADHAR_SIGNATURE: 8,
};

export const docStatus = {
  1: "Unassigned",
  2: "Assigned",
  6: "WorkFlow",
  3: "InProcess",
  4: "Completed",
  5: "Rejected",
};

export const sharestatus = {
  1: "Unassigned",
  2: "Legal",
  3: "External",
};

export const aadharQuotaTableColumns = [
  {
    Header: "Date of Purchase",
    accessor: "date_of_purchase", // accessor is the "key" in the data
    isSorted: true,
  },
  {
    Header: "Purchase Quantity",
    accessor: "current_quantity", // accessor is the "key" in the data
    isSorted: true,
  },
  {
    Header: "Balance",
    accessor: "remaining_quantity", // accessor is the "key" in the data
  },
  {
    Header: "Status",
    accessor: "is_active", // accessor is the "key" in the data
  },
];

export const aadharPurchaseTableColumns = [
  {
    Header: "User Name",
    accessor: "first_name", // accessor is the "key" in the data
    isSorted: true,
  },
  {
    Header: "Company Name",
    accessor: "company_name", // accessor is the "key" in the data
    isSorted: true,
  },
  {
    Header: "Purchase Quantity",
    accessor: "purchase_quantity", // accessor is the "key" in the data
    isSorted: true,
  },
  {
    Header: "Purchase Date",
    accessor: "created_at", // accessor is the "key" in the data
    isSorted: true,
  },
];

export const viewplaybookcols = [
  {
    Header: "Playbook Document",
    accessor: "name", // accessor is the "key" in the data
    // isSorted: true,
  },
  {
    Header: "Created at",
    accessor: "created_at", // accessor is the "key" in the data
    // isSorted: true,
  },
];

export const legalDocumentTableColumns = [
  {
    Header: "Template Id",
    accessor: "template", // accessor is the "key" in the data
    isSorted: true,
  },
  {
    Header: "Template Name",
    accessor: "template_name", // accessor is the "key" in the data
    isSorted: true,
  },
  {
    Header: "Template Token",
    accessor: "token", // accessor is the "key" in the data
  },
];

export const marketPlaceTable = [
  {
    Header: "Firm Name",
    accessor: "firm_name", // accessor is the "key" in the data
    isSorted: true,
  },
  {
    Header: "Contact Person",
    accessor: "contact_person", // accessor is the "key" in the data
    isSorted: true,
  },
  {
    Header: "Specialization",
    accessor: "specialisation", // accessor is the "key" in the data
  },
];

export const clausetablecolumns = [
  {
    Header: "Clause Name",
    accessor: "clause_name",
    isSorted: true,
  },
  {
    Header: "Description",
    accessor: "clause_text",
  },
];

export const legalWorkflowTableColumns = [
  {
    Header: "Template Id",
    accessor: "template", // accessor is the "key" in the data
    isSorted: true,
  },
  {
    Header: "Template Name",
    accessor: "template_name", // accessor is the "key" in the data
    isSorted: true,
  },
  {
    Header: "Token",
    accessor: "token", // accessor is the "key" in the data
  },
  {
    Header: "Remarks",
    accessor: "template_description", // accessor is the "key" in the data
  },
];

export const adminSectionTableColumns = [
  {
    Header: "Section Name",
    accessor: "section_name",
    isSorted: true,
  },
  {
    Header: "Category Type",
    accessor: "SecCat",
    isSorted: true,
  },
];

export const adminSectionTaskTableColumns = [
  {
    Header: "Section Name",
    accessor: "section_name",
    isSorted: true,
  },
  {
    Header: "Task Description",
    accessor: "description",
    isSorted: true,
  },
  {
    Header: "Prompt",
    accessor: "task_name",
    isSorted: true,
  },
];

export const adminLogTableColumns = [
  {
    Header: "Document Name",
    accessor: "doc_name",
    isSorted: true,
  },
  {
    Header: "Log Data",
    accessor: "log_data",
    isSorted: true,
  },
  {
    Header: "Created Date",
    accessor: "created_at",
    isSorted: true,
  },
];

export const ssoSetingTableColumns = [
  {
    Header: "Entity Id",
    accessor: "entityId", // accessor is the "key" in the data
  },
  {
    Header: "Single Sign On Service",
    accessor: "singleSignOnService", // accessor is the "key" in the data
  },
  {
    Header: "Single Logout Service",
    accessor: "singleLogoutService", // accessor is the "key" in the data
  },
  {
    Header: "SSO Certificate",
    accessor: "sso_cretificate",
  },
];

export const licencingTableColumns = [
  {
    Header: "Licensing Key",
    accessor: "licence_key", // accessor is the "key" in the data
  },
];
