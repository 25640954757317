import React, { useState, useEffect, useContext } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import MonthPicker from './monthpicker';
import { resolve } from '../../api/resolve';
import useAxios from '../../hooks/useAxios';
import AuthContext from '../../context/AuthContext';
import DownloadIcon from '../../assets/icons/Download.svg';
import ActivityDatePicker from '../modals/ActivityDatePicker';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';

const ActivityLog = () => {
  const api = useAxios();
  const { t } = useTranslation();
  const { user } = useContext(AuthContext);
  const [date, setDate] = useState(new Date());
  const [modalIsOpen, setIsOpen] = useState(false);
  const [events, setEvents] = useState([]);

  const getRecentActivity = async () => {
    const res = await resolve(
      api.get(`/contract/recent_activity_logs/`).then((res) => res.data)
    );
    if (res.data) {
      setEvents(res.data);
    }
  };

  useEffect(() => {
    getRecentActivity();
  }, []);

  return (
    <div
      className='border-2 border-primary stacked-container p-5  stacked-chart-container'
    // style={{ overflow: 'scroll', height:'320px' }}
    >
      <div className='flex justify-between items-center w-full mb-5'>
        <h1 className='font-bold text-xl text-primary'>{t('homePage.Recent Activity')}</h1>
        <div>
          <button
            onClick={() => setIsOpen(true)}
            className='btn btn_theme2 btn-sm flex items-center justify-center'
          >
            <span>{t('homePage.Export Logs')}</span>
            <svg
              width='17'
              height='17'
              viewBox='0 0 37 46'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
              className='ml-1'
            >
              <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M18.3048 0.134277C19.5579 0.134277 20.5738 1.15014 20.5738 2.40327V26.4224L25.7764 21.2198C26.6625 20.3337 28.0991 20.3337 28.9852 21.2198C29.8713 22.1059 29.8713 23.5426 28.9852 24.4287L19.9092 33.5047C19.0231 34.3908 17.5865 34.3908 16.7004 33.5047L7.6244 24.4287C6.7383 23.5426 6.7383 22.1059 7.6244 21.2198C8.5105 20.3337 9.94714 20.3337 10.8332 21.2198L16.0358 26.4224V2.40327C16.0358 1.15014 17.0517 0.134277 18.3048 0.134277ZM2.42183 34.1692C3.67496 34.1692 4.69083 35.1851 4.69083 36.4382V40.9762H31.9188V36.4382C31.9188 35.1851 32.9347 34.1692 34.1878 34.1692C35.4409 34.1692 36.4568 35.1851 36.4568 36.4382V40.9762C36.4568 43.4825 34.4251 45.5142 31.9188 45.5142H4.69083C2.18456 45.5142 0.152832 43.4825 0.152832 40.9762V36.4382C0.152832 35.1851 1.1687 34.1692 2.42183 34.1692Z'
                fill='#ffffff'
              />
            </svg>
          </button>
        </div>
      </div>
      <ul style={{ overflow: 'auto', maxHeight: '230px' }} className='set_recent'>
        {events.map((event, index) => (
          <React.Fragment key={index}>           
            <li className=''>            
              <strong>{event.user__email}</strong> {event.action}
              <span>{dayjs(event.created_at).format('dddd, MMM D, YYYY')}</span>
            </li>
          </React.Fragment>
        ))}
      </ul>
      <ActivityDatePicker modalIsOpen={modalIsOpen} setIsOpen={setIsOpen} />
    </div>
  );
};

export default ActivityLog;
