import React, { useEffect, useContext, useState } from "react";
import { resolve } from "../../api/resolve";
import useAxios from "../../hooks/useAxios";
import AppContext from "../../context/AppContext";
import PermissionContext from "../../context/PermissionContext";
import TemplateNameModel from "../modals/TemplateNameModel";
import LegalConfirmModal from "../modals/LegalConfirmModal";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import { useTranslation } from "react-i18next";

const CreateTemplateLibrary = () => {
  const [defaultTemplateList, setDefaultTemplateList] = useState([]);
  const [search, setSearch] = useState("");
  const [activaTab, setActivaTab] = useState("selectTemplate");
  const [iframSrc, setIframeUrl] = useState("");
  const [openTemplateNameModal, setOpenTemplateNameModal] = useState(false);
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [documentId, setDocumentId] = useState(null);
  const [templateType, setTemplateType] = useState("all");
  const { t } = useTranslation();

  const {
    activeLink,
    setActiveLink,
    setPrevActiveLink,
    setShowDropdown,
    setTempSaveConfirmation,
    templateConfirmationOpen,
    setTemplateConfirmationOpen,
  } = useContext(AppContext);

  const {
    permissionList,
    getComapnyInfoApi,
    isValidCompany,
    updatePermissionList,
  } = useContext(PermissionContext);

  const baseURL = process.env.REACT_APP_BACKEND_BASE_URL;
  const api = useAxios();
  const navigate = useNavigate();
  const getDefaultTemplateListApi = async () => {
    const response = await api
      .get(`${baseURL}/contract/legal/template/list/`, {
        params: {
          search: search, // Use the global variable
          templateType: templateType, // Use the global variable
        },
      })
      .then((res) => res.data);

    if (response && response.results) {
      setDefaultTemplateList(response.results);
    }
  };

  const [selectedtempType, setSelectedtempType] = useState("");

  const handleTempChange = (selectedOption) => {
    setSelectedtempType(selectedOption ? selectedOption.value : "");
    setTemplateType(selectedOption ? selectedOption.value : "");
  };

  useEffect(() => {
    if (templateType) {
      getDefaultTemplateListApi();
    }
  }, [templateType]);

  const tempTypeOptions = [
    { value: "all", label: t("All") },
    { value: "generic", label: t("Generic") },
    { value: "company", label: t("Company Specific") },
  ];

  const deleteDocument = async () => {
    const responceData = await resolve(
      api
        .get(`${baseURL}/contract/legal/template/delete/${documentId}`)
        .then((res) => res.data)
    );
  };

  const handleDefaultTemplateClick = async (item) => {
    setSelectedTemplate(item);
    setOpenTemplateNameModal(true);
  };

  useEffect(() => {
    getDefaultTemplateListApi();
    getComapnyInfoApi();
  }, []);

  return (
    <>
      <div className="text-primary text-base font-normal font-sans mt_set">
        <div className="main_head">
          <h1 className="text-4xl font-bold text_c">
            {t("menu.Select from Template Library")}
          </h1>
        </div>

        {!isValidCompany ? (
          <div className="flex justify-center items-center h-96 flex-column ">
            <h3 className="text-2xl font-bold text_c">
              {t("allModule.Please update your company info.")}
            </h3>
          </div>
        ) : permissionList["legal_template_on"] == 1 ? (
          <div className="w-full items-center generate_docuement">
            <div className="row">
              {activaTab == "selectTemplate" && (
                <>
                  {permissionList.legal_template_on === false ? (
                    <div className="flex justify-center items-center h-96 flex-column w-full">
                      <h3 className="text-2xl font-bold text_c">
                        {t(
                          "allModule.You dont have permission to access this page"
                        )}
                      </h3>
                    </div>
                  ) : permissionList.legal_template_allotted_cnt > 0 &&
                    permissionList.legal_template_remaing_cnt === 0 ? (
                    <div className="flex justify-center items-center h-96 flex-column w-full">
                      <h3 className="text-2xl font-bold text_c">
                        {t(
                          "allModule.You have no more limit to create template."
                        )}
                      </h3>
                    </div>
                  ) : (
                    <>
                      <div className="col-md-9">
                        <div className="bottom_area">
                          {iframSrc ? (
                            <iframe id="template-frame" src={iframSrc}></iframe>
                          ) : (
                            <div className="text-center">
                              <h1 className="text-2xl font-bold text_c">
                                {t("allModule.Please select a template")}
                              </h1>
                            </div>
                          )}
                        </div>
                      </div>

                      <div className="col-md-3">
                        <h4 className="text-xl font-bold  mb-2 text_c ">
                          {t("allModule.Select Template")}
                        </h4>
                        <div className="mb-2">
                          <Select
                            value={tempTypeOptions.find(
                              (option) => option.value === selectedtempType
                            )}
                            onChange={(selectedOption) => {
                              handleTempChange(selectedOption);

                              //   console.log(selectedOption.value);
                              //   getDefaultTemplateListApi();
                            }}
                            options={tempTypeOptions}
                            className="border-2 border-primary rounded-lg-1 outline-none"
                            placeholder={t("Template type")}
                            // classNamePrefix="custom-select"
                            styles={{
                              control: (base) => ({
                                ...base,
                                borderRadius: "4px",
                                border: "none",
                                height: "40px",
                                minHeight: "40px",
                                width: "100%",
                              }),
                              option: (base, state) => ({
                                ...base,
                                backgroundColor: state.isFocused
                                  ? "#f2f2f2"
                                  : "white",
                                color: state.isFocused ? "#310128" : "black",
                                "&:hover": {
                                  backgroundColor: "#f2f2f2",
                                  color: "#310128",
                                },
                              }),
                            }}
                          />
                        </div>
                        <div className="seachbox mb-2 flex items-center">
                          <input
                            type="text"
                            className="border-2 border-primary rounded-lg-1 outline-none p-1 pl-2 flex-grow"
                            placeholder={t("allModule.Search a Template")}
                            onChange={(e) => {
                              setSearch(e.target.value);
                            }}
                            onKeyUp={(e) => {
                              if (e.key === "Enter" || e.target.value === "") {
                                getDefaultTemplateListApi();
                              }
                            }}
                          />

                          <button
                            className="btn pb-1 pt-0.5 pl-6 pr-6 text_c font-bold rounded-lg btn_theme3 ml-2"
                            onClick={(e) => getDefaultTemplateListApi(search)}
                          >
                            {t("allModule.Search")}
                          </button>
                        </div>

                        <div
                          className="template_list_container pad_0"
                          style={{ height: "80%" }}
                        >
                          {defaultTemplateList.length > 0 &&
                            defaultTemplateList.map((item, index) => {
                              return (
                                <div
                                  key={index}
                                  onClick={(e) => {
                                    handleDefaultTemplateClick(item);
                                  }}
                                  className="template_box mb-1 mr-1"
                                >
                                  <div className="template_box_head">
                                    <h1 className="text-2xl font-bold text_c">
                                      {" "}
                                      {item.template_name}{" "}
                                    </h1>
                                  </div>
                                </div>
                              );
                            })}

                          {defaultTemplateList.length == 0 && (
                            <div className="flex justify-center items-center h-96 flex-column w-full">
                              <h3 className="text-2xl font-bold text_c">
                                {t("allModule.No Template Found")}
                              </h3>
                            </div>
                          )}
                        </div>
                        {/* <div className="set_btn2 mt-4"> */}
                        <button
                          onClick={(e) => {
                            e.preventDefault();
                            navigate("/my-documents/set-up-signatures");
                            setActiveLink("MyDocuments");
                            setShowDropdown("");
                          }}
                          className="btn pb-1 pt-0.5 mt-2 pl-6 pr-6 text_c font-bold rounded-lg btn_theme3"
                        >
                          {t("allModule.My Documents")}
                        </button>
                        {/* </div> */}
                      </div>
                    </>
                  )}
                </>
              )}
            </div>
          </div>
        ) : (
          <div className="flex justify-center items-center h-96 flex-column ">
            <h3 className="text-2xl font-bold text_c">
              {t("allModule.You dont have permission to access this page")}
            </h3>
          </div>
        )}

        <TemplateNameModel
          isOpen={openTemplateNameModal}
          setIsOpen={setOpenTemplateNameModal}
          selectedTemplate={selectedTemplate}
          setIframeUrl={setIframeUrl}
          setDocumentId={setDocumentId}
          setTempSaveConfirmation={setTempSaveConfirmation}
          setType="legal template"
        />

        <LegalConfirmModal
          title={t("allModule.Confirmation")}
          bodyText={t("allModule.Are you sure you want to leave?")}
          isConfirmModalOpen={templateConfirmationOpen}
          setIsConfirmModalOpen={setTemplateConfirmationOpen}
          setTempSaveConfirmation={setTempSaveConfirmation}
          activeLink={activeLink}
          setPrevActiveLink={setPrevActiveLink}
          deleteDocument={deleteDocument}
        />
      </div>
    </>
  );
};

export default CreateTemplateLibrary;
