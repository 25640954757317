import React, { useState, useContext, useEffect } from "react";
import { useTable } from "react-table";
import "../contracts/contracts.css";
import DownArrow from "../../assets/icons/down-arrow.svg";
import ReactTooltip from "react-tooltip";

import { resolve } from "../../api/resolve";
import useAxios from "../../hooks/useAxios";
import { autoCloseTimer, delay } from "../../constants/constants";
import { toast } from "react-toastify";
import RejectDocModal from "../modals/RejectDocModal";
import AppContext from "../../context/AppContext";
import EditIcon from "../../assets/icons/Edit.svg";
import DeleteIcon from "../../assets/icons/Delete.svg";
import DeleteModal from "../modals/DeleteModal";
import FormContext from "../../context/FormContext";
import { useTranslation } from "react-i18next";
import EyeOnIconWhite from "../../assets/icons/eye_on_white.png";
import DocumentPreviewModal from "../modals/DocumentPreviewModal";

export const NoDataComponent = ({ colLength }) => {
  const [rows] = useState(Array(4).fill(""));
  const [cols] = useState(Array(colLength).fill(""));

  return (
    <>
      {rows.map((data, index) => (
        <tr key={`group_ndf_row_${index}`}>
          {cols.map((data, index01) => (
            <td
              key={`group_ndf_td_${index01}`}
              className="px-5 py-2 font-medium text-smW"
            >
              --
            </td>
          ))}
        </tr>
      ))}
    </>
  );
};

const ViewPlaybookTable = ({
  tableData,
  tableColumns,
  currentPage,
  setCurrentPage,
  nextBtnLimit,
  itemCount,
  setPageLimit,
  getListData,

  sortingOrder,
  setSortingOrder,
  setSortColumn,
  showActionBtn,
  showPageLimit,
}) => {
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({ columns: tableColumns, data: tableData });

  const [documentId, setDocumentId] = useState(0);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const { setEditFormData, setIsEditing } = useContext(FormContext);
  const { setActiveLink } = useContext(AppContext);
  const [previewDocument, setPreviewDocument] = useState(false);
  const [previewDocumentData, setPreviewDocumentData] = useState({});

  const api = useAxios();
  const { t } = useTranslation();

  const deleteDocument = async () => {
    const res = await resolve(api.get(`/contract/doc/cancle/${documentId}/1/`));
    if (res.data) {
      toast.success("Document deleted successfully", {
        autoClose: autoCloseTimer,
      });
      getListData();
    }
    setIsDeleteModalOpen(false);
  };

  const handleEditData = async (rowData) => {
    setEditFormData(rowData);
    setIsEditing(true);
    setActiveLink("LegalDocumentForm");
  };

  return (
    <>
      <div className="mt-2 flex flex-col table-container mb-5">
        <div>
          <div className="table_desin">
            <div className="shadow overflow-hidden border-2 border-primary  sm:rounded-lg max-h-96 overflow-y-auto">
              <table {...getTableProps()} className="w-full ">
                <thead>
                  {headerGroups.map((headerGroup) => (
                    <tr
                      className={`cursor-pointer bg-[#F6C107]`}
                      {...headerGroup.getHeaderGroupProps()}
                    >
                      {headerGroup.headers.map((column) => (
                        // Add the sorting props to control sorting. For this example
                        // we can add them into the header props

                        <th
                          scope="col"
                          className="text-left px-2 py-2"
                          {...column
                            .getHeaderProps
                            // column.getSortByToggleProps()
                            ()}
                        >
                          <div className="flex items-center">
                            <span>{t("constant." + column.render("Header"))}</span>

                            {column.isSorted !== undefined &&
                              (column.isSorted ? (
                                <span
                                  onClick={(e) => {
                                    setSortingOrder("asc");
                                    setSortColumn(column.id);
                                    column.isSorted = false;
                                  }}
                                  style={{ cursor: "pointer", marginTop: 2 }}
                                  className="ml-1"
                                >
                                  <img src={DownArrow} />
                                </span>
                              ) : (
                                <span
                                  onClick={(e) => {
                                    setSortingOrder("desc");
                                    setSortColumn(`${column.id}`);
                                    column.isSorted = true;
                                  }}
                                  style={{
                                    cursor: "pointer",
                                    transform: "rotate(180deg)",
                                  }}
                                  className="ml-1"
                                >
                                  <img src={DownArrow} />
                                </span>
                              ))}
                          </div>
                        </th>
                      ))}
                      {showActionBtn && (
                        <th scope="col" className="text-left px-4 pt-2">
                          {t('common.Action')}
                        </th>
                      )}
                    </tr>
                  ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                  {tableData.length < 1 && (
                    <NoDataComponent colLength={tableColumns.length} />
                  )}
                  {tableData.length > 0 &&
                    rows.map((row, i) => {
                      // new
                      prepareRow(row);
                      return (
                        <tr {...row.getRowProps()}>
                          {row.cells.map((cell, row_cell_index) => {
                            return (
                              <td
                                {...cell.getCellProps()}
                                className={`px-2 py-2 font-medium text-sm contracts-col-data ${
                                  cell.value === "In-Active" && "text-red-500"
                                }`}
                              >
                                <span>{cell.render("Cell")}</span>
                              </td>
                            );
                          })}
                          {showActionBtn && (
                            <td className="py-2 flex">
                              <button
                                onClick={(e) => {
                                  e.stopPropagation();
                                  setPreviewDocument(true);
                                  setPreviewDocumentData(row.original);
                                  console.log(row.original);
                                }}
                                className="btn btn_theme btn-sm mr-1 wh_spab w-10"
                                data-tip={t("constant.View")}

                                // title="View Document"
                              >
                                {/* {t("constant.View")} */}
                                <img
                                  src={EyeOnIconWhite}
                                  className="ml-0.5"
                                  width={20}
                                  alt="View Document"
                                />
                              </button>
                              <button
                                className="btn btn_theme btn-sm mr-1 wh_spab w-10"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  setDocumentId(row.original.id);
                                  setIsDeleteModalOpen(true);
                                }}
                                data-tip={t("constant.Delete document")}
                              >
                                <img
                                  src={DeleteIcon}
                                  className="ml-0.5"
                                  width={20}
                                  alt="Delete Document"
                                />
                              </button>
                            </td>
                          )}
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        {tableData.length > 0 && (
          <div
            className={`mt-3 flex ${
              showPageLimit ? "justify-between" : "justify-end"
            }`}
          >
            {showPageLimit && (
              <div>
                {itemCount > 5 && (
                  <>
                    <span>{t("constant.Show")}</span>
                    <select
                      className="border-2 border-primary outline-none mx-1 rounded-md cursor-pointer btn-sm"
                      onChange={(e) => {
                        setCurrentPage(1);
                        setPageLimit(e.target.value);
                      }}
                      defaultValue="5"
                    >
                      <option>5</option>
                      <option>10</option>
                      <option>15</option>
                      <option>25</option>
                    </select>
                    <span>{t("common.Results")}</span>
                  </>
                )}
              </div>
            )}
            <div className="flex text-primary  border-t-2 border-b-2 border-primary rounded-lg">
              <button
                className={`btn-sm border-r-2 border-l-2 border-primary font-semibold rounded-tl-lg rounded-bl-lg ${
                  currentPage <= 1 && "bg-inputBackground"
                }`}
                disabled={currentPage <= 1}
                onClick={(e) => {
                  if (currentPage > 1) {
                    setCurrentPage(currentPage - 1);
                  }
                }}
              >
                {t("common.Prev")}
              </button>
              <span className="btn-sm font-semibold">{currentPage}</span>
              <button
                className={`btn-sm border-l-2 border-r-2 border-primary font-semibold rounded-tr-lg rounded-br-lg ${
                  currentPage >= nextBtnLimit && "bg-inputBackground"
                }`}
                disabled={currentPage >= nextBtnLimit}
                onClick={(e) => {
                  if (currentPage < nextBtnLimit) {
                    setCurrentPage(currentPage + 1);
                  }
                }}
              >
                {t("common.Next")}
              </button>
            </div>
          </div>
        )}
      </div>

      <DeleteModal
        title="Delete Document"
        bodyText="This action cannot be undone. Are you sure you want to continue?"
        isDeleteModalOpen={isDeleteModalOpen}
        setIsDeleteModalOpen={setIsDeleteModalOpen}
        deleteDocument={deleteDocument}
      />
      <DocumentPreviewModal
        isOpen={previewDocument}
        setIsOpen={setPreviewDocument}
        previewDocumentData={previewDocumentData}
      />
    </>
  );
};

export default ViewPlaybookTable;
