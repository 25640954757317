import React, { useEffect, useState, useContext } from 'react';
import Search from '../../assets/icons/Search.svg';
import CloseIcon from '../../assets/icons/Close.svg';
import { marketPlaceTable } from '../../constants/constants';
import useAxios from '../../hooks/useAxios';
import { resolve } from '../../api/resolve';
import AddButton from '../../shared/AddButton';
import Adminmarkettable from './Adminmarkettable'
import { parseISO, format } from 'date-fns'

const AdminMarketPlace = () => {

    const api = useAxios();
    // pending doc related states
    const [AdminMarketPlace, setAdminMarketPlace] = useState([]);
    const [searchText, setSearchText] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [pageLimit, setPageLimit] = useState(5);
    const [nextBtnLimit, setNextBtnLimit] = useState(0);
    const [count, setUserCount] = useState(0);
    const [errMsg, setErrMsg] = useState('');
    const [showClearIcon, setShowClearIcon] = useState(false);
    const [sortingOrder, setSortingOrder] = useState('desc');
    const [sortColumn, setSortColumn] = useState('template_name');



    const getAdminMarketPlace = async (clearText) => {
        const res = await resolve(api.get(`contract/marketplace/view/`).then((res) => res.data));
        console.log("marketplacedata",res.data);
        const { results } = res.data;
        // const res = await resolve(api.get(`contract/legal/template/list/?ordering=${sortColumn}&sortingOrder=${sortingOrder}&search=${clearText ? '' : searchText}&page=${currentPage}&limit=${pageLimit}`).then((res) => res.data));
        if (res.data) {
            // const { data } = res;
            // const { results } = res.data;
            console.log("results",res.data);
            if(currentPage>1 && res.data.length == 0){
                setCurrentPage(currentPage-1)
                return false;
            }

            const filterData = [];
            if (res.data.length > 0) {
                setUserCount(count);
                res.data.forEach((result) => {
                    const tempObj = {
                        id: result.id,
                        firm_name: result.firm_name,
                        contact_person: result.contact_person,
                        specialisation: result.specialisation,
                    };
                    filterData.push(tempObj);
                });
            } else {
                if (currentPage === 1) setErrMsg('No data added yet');
                else setErrMsg('No more data to view');
            }
            setAdminMarketPlace([...filterData]);
            console.log("adminmarket",AdminMarketPlace)
            setNextBtnLimit(Math.ceil(count / pageLimit))
        } else {
            setNextBtnLimit(0)
        }
    };

    useEffect(() => {
        getAdminMarketPlace();
        
    }, [sortColumn, sortingOrder, currentPage, pageLimit])

    return (
        <>
            <div className='text-primary'>
                <div className="main_head">
                    <h1 className='text-4xl font-bold'>Marketplace</h1>
                </div>

                <div className="tab_main">
                    <div className={`tab_us show`}>
                        <div className="row justify-between">
                            <div className="col-md-5 flex_contact">
                                <form>
                                    <div className='flex mt-3  w-full'>

                                        <div className='flex justify-between items-center border-2 border-primary rounded-md rounded-tl-lg rounded-bl-lg bg-inputBackground px-2 mr-2 w-full '>
                                            <input
                                                placeholder='Search.. '
                                                className='px-1 py-1 focus:outline-none bg-transparent w-11/12'
                                                value={searchText}
                                                onChange={(e) => {
                                                    setSearchText(e.target.value);
                                                    setShowClearIcon(true);
                                                    if (!e.target.value.trim()) {
                                                        setShowClearIcon(false);
                                                    }
                                                }}
                                            />
                                            {showClearIcon && (
                                                <img
                                                    src={CloseIcon}
                                                    width={14}
                                                    className='cursor-pointer'
                                                    onClick={() => {
                                                        setSearchText('');
                                                        getAdminMarketPlace(true);
                                                        setShowClearIcon(false);
                                                    }}
                                                />
                                            )}
                                        </div>
                                        <button
                                            type='submit'
                                            className='flex btn btn_theme2 w_125 items-center justify-between'
                                            onClick={(e) => {
                                                e.preventDefault();
                                                setCurrentPage(1)
                                                getAdminMarketPlace();
                                            }}
                                        >
                                            <img src={Search} style={{ width: '1rem' }} />
                                            <span className='font-semibold ml-1'>Search</span>
                                        </button>
                                    </div>
                                </form>
                            </div>
                            <div className="col-md-5">
                                <div className='flex justify-end mt-3'>
                                    <AddButton text="Add Firm " currentForm="MarketPlaceForm" />
                                </div>
                            </div>

                        </div>
                        <div className='mt-3 rounded-lg'>
                            {AdminMarketPlace.length < 1 && (
                                <p className='text-red-500 text-sm font-medium'>{errMsg}</p>
                            )}
                        </div>
                        <Adminmarkettable
                            tableColumns={marketPlaceTable}
                            getListData={getAdminMarketPlace}
                            tableData={AdminMarketPlace}
                            showActionBtn
                            nextBtnLimit={nextBtnLimit}
                            currentPage={currentPage}
                            setCurrentPage={setCurrentPage}
                            itemCount={count}
                            setPageLimit={setPageLimit}
                            showPageLimit={true}
                            sortingOrder={sortingOrder}
                            setSortingOrder={setSortingOrder}
                            setSortColumn={setSortColumn}
                        />


                    </div>

                </div>
            </div>
        </>
    )
}

export default AdminMarketPlace;