import React, { useState, useContext } from "react";
import { useTable } from "react-table";
import "../contracts/contracts.css";
import DownArrow from "../../assets/icons/down-arrow.svg";
import LoadingScreen from "../loader/LoadingScreen";
import { toast } from "react-toastify";
import AppContext from "../../context/AppContext";
import DocumentContext from "../../context/DocumentContext";
import DeleteIcon from "../../assets/icons/Delete.svg";
import EyeOnIconWhite from "../../assets/icons/eye_on_white.png";
import EditIcon from "../../assets/icons/wordEdit_white1.png";
import DownloadIcon from "../../assets/icons/downloadd1.png";
import { autoCloseTimer, delay } from "../../constants/constants";
import ShareButton from "./share_button";
import ShareButtonExt from "./share_button_ext";

import { resolve } from "../../api/resolve";
import useAxios from "../../hooks/useAxios";
import DeleteModal from "../modals/DeleteModal";
import { useTranslation } from "react-i18next";
import { Client } from "@microsoft/microsoft-graph-client";
import { useNavigate } from "react-router-dom";
import { use } from "i18next";
import { useEffect } from "react";
import axios from "axios";
import Office365EditModel from "../modals/Office365EditModel";
import ReactTooltip from "react-tooltip";

export const NoDataComponent = ({ colLength }) => {
  const [rows] = useState(Array(4).fill(""));
  const [cols] = useState(Array(colLength).fill(""));
  return (
    <>
      {rows.map((data, index) => (
        <tr key={`group_ndf_row_${index}`}>
          {cols.map((data, index01) => (
            <td
              key={`group_ndf_td_${index01}`}
              className="px-5 py-2 font-medium text-smW"
            >
              --
            </td>
          ))}
        </tr>
      ))}
    </>
  );
};

const UnassignedDocTable = ({
  tableData,
  tableColumns,
  currentPage,
  setCurrentPage,
  nextBtnLimit,
  itemCount,
  pageLimit,
  setPageLimit,
  getListData,
  getRejectedDocList,
  sortingOrder,
  setSortingOrder,
  setSortColumn,
  showActionBtn,
  showPageLimit,
  forSignatureList,
  setForSignatureList,
  setPreviewDocument,
  setPreviewDocumentData,
}) => {
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({ columns: tableColumns, data: tableData });
  const [loading, setLoading] = useState(false);
  const [openOffice365EditModal, setOpenOffice365EditModal] = useState(false);
  const [docInfo, setDocInfo] = useState(null);

  const { setActiveLink, setShowDropdown, activePrevLink } =
    useContext(AppContext);

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [deleteDocumentId, setDeleteDocumentId] = useState(false);
  const [batchName, setbatchName] = useState("");
  const api = useAxios();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const baseUrl = process.env.REACT_APP_BACKEND_BASE_URL;
  const [docId, setDocId] = useState(0);
  const downlaodDocument = async (docId) => {
    const apiUrl = `${baseUrl}/contract/download/document/${docId}`;
    let authTokens = localStorage.getItem("authTokens")
      ? JSON.parse(localStorage.getItem("authTokens"))
      : null;
    const headers = {
      Authorization: `Bearer ${authTokens?.access}`,
    };
    const res = await api.get(apiUrl, {
      headers,
      "Content-Type": "application/json",
    });
    if (res.data) {
      authTokens = localStorage.getItem("authTokens")
        ? JSON.parse(localStorage.getItem("authTokens"))
        : null;
      const apiUrl = `${baseUrl}/contract/download/document/${docId}?token=${authTokens?.access}`;
      window.open(apiUrl);
    }

    if (res.error) {
      if (res["error"]?.response?.data?.message) {
        toast.error(res["error"].response["data"].message, {
          autoClose: autoCloseTimer,
          hideProgressBar: true,
        });
      } else {
        toast.error(t("common.Something went wrong"), {
          autoClose: autoCloseTimer,
          hideProgressBar: true,
        });
      }
    }
  };

  const deleteDocument = async () => {
    const res = await resolve(
      api.get(`/contract/doc/cancle/${deleteDocumentId}/1/`)
    );
    if (res.data) {
      getListData();
    }
    setIsDeleteModalOpen(false);
  };
  const createBatch = async () => {
    // setLoading(true);
    let data = {
      document_ids: forSignatureList.map((x) => x.id),
    };
    if (0 && batchName.length < 1 && data.document_ids.length > 1) {
      toast.error("Please Enter the batch name");
    } else {
      setActiveLink("SelectedDocRecipients");
      setShowDropdown("Recipients");
    }

    // const res = await resolve(api.post(`/contract/batch/`, data));
    // if (res.data) {
    //   setLoading(false);

    // }
  };

  return (
    <>
      {loading ? (
        <LoadingScreen />
      ) : (
        <div className="mt-2 flex flex-col table-container mb-5">
          <div>
            <div className="table_desin">
              <div
                style={{ maxHeight: "60vh" }}
                className="shadow border-2 border-primary  sm:rounded-lg"
              >
                <table {...getTableProps()} className="w-full ">
                  <thead>
                    {headerGroups.map((headerGroup) => (
                      <tr
                        className={`cursor-pointer bg-[#F6C107]`}
                        {...headerGroup.getHeaderGroupProps()}
                      >
                        {headerGroup.headers.map((column) => (
                          <th
                            scope="col"
                            className="text-left px-2 py-2"
                            {...column
                              .getHeaderProps
                              // column.getSortByToggleProps()
                              ()}
                          >
                            <div className="flex items-center">
                              <span>
                                {t("constant." + column.render("Header"))}
                              </span>

                              {column.isSorted !== undefined &&
                                (column.isSorted ? (
                                  <span
                                    onClick={(e) => {
                                      setSortingOrder("asc");
                                      setSortColumn(column.id);
                                      column.isSorted = false;
                                    }}
                                    style={{ cursor: "pointer", marginTop: 2 }}
                                    className="ml-1"
                                  >
                                    <img src={DownArrow} />
                                  </span>
                                ) : (
                                  <span
                                    onClick={(e) => {
                                      setSortingOrder("desc");
                                      setSortColumn(`${column.id}`);
                                      column.isSorted = true;
                                    }}
                                    style={{
                                      cursor: "pointer",
                                      transform: "rotate(180deg)",
                                    }}
                                    className="ml-1"
                                  >
                                    <img src={DownArrow} />
                                  </span>
                                ))}
                            </div>
                          </th>
                        ))}
                        {showActionBtn && (
                          <th scope="col" className="text-left px-4 pt-2">
                            {t("constant.Action")}
                          </th>
                        )}
                      </tr>
                    ))}
                  </thead>
                  <tbody {...getTableBodyProps()}>
                    {tableData.length < 1 && (
                      <NoDataComponent colLength={tableColumns.length} />
                    )}
                    {tableData.length > 0 &&
                      rows.map((row, i) => {
                        // new
                        prepareRow(row);
                        return (
                          <tr {...row.getRowProps()}>
                            {row.cells.map((cell, row_cell_index) => {
                              return (
                                <td
                                  {...cell.getCellProps()}
                                  className={`px-2 py-2 font-medium text-sm`}
                                >
                                  {cell.column.Header === "Select" ? (
                                    <div>
                                      <input
                                        value={row.original.id}
                                        type="checkbox"
                                        checked={
                                          forSignatureList.findIndex(
                                            (x) => x.id == row.original.id
                                          ) !== -1
                                            ? true
                                            : false
                                        }
                                        onChange={(e) => {
                                          let index =
                                            forSignatureList.findIndex(
                                              (x) => x.id == e.target.value
                                            );
                                          if (index === -1) {
                                            setForSignatureList([
                                              ...forSignatureList,
                                              row.original,
                                            ]);
                                          } else {
                                            let temp = [...forSignatureList];
                                            temp.splice(index, 1);
                                            setForSignatureList(temp);
                                          }
                                        }}
                                        className="form-checkbox h-5 w-5 text-primary"
                                      />
                                    </div>
                                  ) : (
                                    <>
                                      <span
                                        title={
                                          cell.column.Header === "Document Name"
                                            ? cell.value
                                            : ""
                                        }
                                        className={`${
                                          cell.column.Header === "Document Name"
                                            ? "text_fixed cursor-pointer"
                                            : ""
                                        }`}
                                      >
                                        {cell.render("Cell")}
                                      </span>
                                    </>
                                  )}
                                </td>
                              );
                            })}
                            {showActionBtn && (
                              <td className="py-2 flex">
                                <button
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    setPreviewDocument(true);
                                    setPreviewDocumentData(row.original);
                                  }}
                                  className="btn btn_theme btn-sm mr-1 wh_spab w-10"
                                  data-tip={t("constant.View")}

                                  // title="View Document"
                                >
                                  {/* {t("constant.View")} */}
                                  <img
                                    src={EyeOnIconWhite}
                                    className="ml-0.5"
                                    width={20}
                                    alt="View Document"
                                  />
                                </button>
                                <button
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    setDocId(row.original.id);
                                    downlaodDocument(row.original.id);
                                  }}
                                  className="btn btn_theme btn-sm mr-1 wh_spab w-10"
                                  data-tip={t("constant.Download document")}
                                >
                                  {/* {t('constant.Download document')} */}
                                  <img
                                    src={DownloadIcon}
                                    className="ml-0.5"
                                    width={20}
                                    alt="Download Document"
                                  />
                                </button>

                                <button
                                  className="btn btn_theme btn-sm mr-1 wh_spab w-10"
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    setDeleteDocumentId(row.original.id);
                                    setIsDeleteModalOpen(true);
                                  }}
                                  data-tip={t("constant.Delete document")}
                                >
                                  <img
                                    src={DeleteIcon}
                                    className="ml-0.5"
                                    width={20}
                                    alt="Delete Document"
                                  />
                                </button>
                                <ShareButton
                                  docId={row.original.id}
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    setDocId(row.original.id);
                                    downlaodDocument(row.original.id);
                                  }}
                                  className="btn btn_theme btn-sm mr-1 wh_spab w-10"
                                  data-tip={t("constant.share button")}
                                >
                                  {/* {t('constant.Download document')} */}
                                </ShareButton>
                                <ShareButtonExt
                                  docId={row.original.id}
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    setDocId(row.original.id);
                                    downlaodDocument(row.original.id);
                                  }}
                                  className="btn btn_theme btn-sm mr-1 wh_spab w-10"
                                  data-tip={t("constant.share button")}
                                >
                                  {/* {t('constant.Download document')} */}
                                </ShareButtonExt>

                                {(row.original["ext"] === "docx" ||
                                  row.original["ext"] === "doc") &&
                                  (row.original["office365_status"] === 1 ? (
                                    <button
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        setOpenOffice365EditModal(true);
                                        setDocInfo(row.original);
                                      }}
                                      data-tip={t(
                                        "constant.Collaborate using O365 is running"
                                      )}
                                      style={{
                                        backgroundColor: "#0d5c78",
                                        color: "white",
                                      }}
                                      className="btn btn_theme btn-sm mr-1 wh_spab w-10"
                                    >
                                      <img
                                        src={EditIcon}
                                        className="ml-0.5"
                                        width={20}
                                        alt="Edit"
                                      />
                                    </button>
                                  ) : row.original["office365_status"] === 2 ? (
                                    // Add your code for status === 11 here
                                    <button
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        setOpenOffice365EditModal(true);
                                        setDocInfo(row.original);
                                      }}
                                      data-tip={t(
                                        "constant.Collaborate using O365 is done"
                                      )}
                                      className="btn btn_theme2 btn-sm mr-1 wh_spab w-10"
                                    >
                                      <img
                                        src={EditIcon}
                                        className="ml-0.5"
                                        width={20}
                                        alt="Edit"
                                      />
                                    </button>
                                  ) : (
                                    <button
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        setOpenOffice365EditModal(true);
                                        setDocInfo(row.original);
                                      }}
                                      data-tip={t(
                                        "constant.Collaborate using O365"
                                      )}
                                      className="btn btn_theme btn-sm mr-1 wh_spab w-10"
                                    >
                                      <img
                                        src={EditIcon}
                                        className="ml-0.5"
                                        width={20}
                                        alt="Edit"
                                      />
                                    </button>
                                  ))}

                                {/* ) : (
                                   <></>
                                <button
                                    onClick={(e) => {
                                      e.stopPropagation();
                                    }}
                                    style={{
                                      backgroundColor: '#606060',
                                    }}
                                    className="btn btn_theme btn-sm mr-1 wh_spab"
                                  >
                                    Finalized Document
                                   </button>
                                 ) 
                                 } */}
                              </td>
                            )}
                            <ReactTooltip
                              backgroundColor="#400835"
                              textColor="#ffffff"
                            />
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          {tableData.length > 0 && (
            <div
              className={`mt-3 flex ${
                showPageLimit ? "justify-between" : "justify-end"
              }`}
            >
              {showPageLimit && (
                <div>
                  {itemCount > 5 && (
                    <>
                      <span>{t("constant.Show")}</span>
                      <select
                        className="border-2 border-primary outline-none mx-1 rounded-md cursor-pointer btn-sm"
                        onChange={(e) => {
                          setCurrentPage(1);
                          setPageLimit(e.target.value);
                        }}
                        value={pageLimit}
                      >
                        <option>5</option>
                        <option>10</option>
                        <option>15</option>
                        <option>25</option>
                      </select>
                      <span>{t("common.Results")}</span>
                    </>
                  )}
                </div>
              )}
              <div className="flex">
                {activePrevLink === "MyDocuments" && ( // new
                  <>
                    {/* {forSignatureList.length > 1 && (
                      <input
                        placeholder="Enter batch name"
                        className="px-1 py-1 focus:outline-none bg-transparent w-11/12"
                        value={batchName}
                        onChange={(e) => {
                          setbatchName(e.target.value);
                        }}
                      />
                    )} */}
                    <button
                      type="button"
                      className="flex btn btn_theme2  items-center justify-between mr-5"
                      onClick={() => {
                        if (forSignatureList.length > 0) {
                          createBatch();
                        } else {
                          toast.error(
                            "Please select atleat one document to sign"
                          );
                        }
                      }}
                    >
                      <span className="font-semibold ml-1">
                        {forSignatureList.length == 1 &&
                          t("constant.Add Recipient for Signature")}
                        {forSignatureList.length != 1 &&
                          t("constant.Add Recipient(s) for Signature")}
                      </span>
                    </button>
                  </>
                )}
                <div className="flex text-primary border-t-2 border-b-2 border-primary rounded-lg">
                  <button
                    className={`btn-sm border-r-2 border-l-2 border-primary font-semibold rounded-tl-lg rounded-bl-lg ${
                      currentPage <= 1 && "bg-inputBackground"
                    }`}
                    disabled={currentPage <= 1}
                    onClick={(e) => {
                      if (currentPage > 1) {
                        setCurrentPage(currentPage - 1);
                      }
                    }}
                  >
                    {t("common.Prev")}
                  </button>
                  <span className="btn-sm font-semibold">{currentPage}</span>
                  <button
                    className={`btn-sm border-l-2 border-r-2 border-primary font-semibold rounded-tr-lg rounded-br-lg ${
                      currentPage >= nextBtnLimit && "bg-inputBackground"
                    }`}
                    disabled={currentPage >= nextBtnLimit}
                    onClick={(e) => {
                      if (currentPage < nextBtnLimit) {
                        setCurrentPage(currentPage + 1);
                      }
                    }}
                  >
                    {t("common.Next")}
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>
      )}

      <DeleteModal
        title="Delete Document"
        bodyText="Are you sure you want to delete document?"
        isDeleteModalOpen={isDeleteModalOpen}
        setIsDeleteModalOpen={setIsDeleteModalOpen}
        deleteDocument={deleteDocument}
      />

      <Office365EditModel
        isOpen={openOffice365EditModal}
        setIsOpen={setOpenOffice365EditModal}
        getListData={getListData}
        docInfo={docInfo}
      />
    </>
  );
};

export default UnassignedDocTable;
