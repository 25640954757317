import React, { useState, useRef, useEffect } from "react";
import axios from "axios";
import "./clauseai.css";
import copy from "../../assets/icons/copy-clause.png";
import useAxios from "../../hooks/useAxios";
import clauseicon from "../../assets/icons/aichat.svg";
import LoadingScreen from "../loader/LoadingScreen";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import ReactTooltip from "react-tooltip";

const DocChatAI = React.forwardRef((props, ref) => {
  const { aiContent, updateContent, className } = props;
  const [searchText, setSearchText] = useState("");
  const [isPopupOpen, setPopupOpen] = useState(false);
  const [isResizing, setResizing] = useState(false);
  const [recommendations, setRecommendations] = useState([]);
  const { t } = useTranslation();
  const [selectedRecommendation, setSelectedRecommendation] = useState("");
  const [hasContent, setHasContent] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [copiedText, setCopiedText] = useState(null);
  const [conversationHistory, setConversationHistory] = useState([]);
  const baseURL = process.env.REACT_APP_BACKEND_BASE_URL;
  const api = useAxios();
  const popupRef = useRef(null);
  const prevMousePos = useRef({ x: 0, y: 0 });

  useEffect(() => {
    const handleGlobalKeyDown = (event) => {
      if (event.ctrlKey && event.key === "/") {
        setPopupOpen(true);
        setSearchText("");
        setHasContent(false);
        setLoading(false);
      }
    };

    window.addEventListener("keydown", handleGlobalKeyDown);

    return () => {
      window.removeEventListener("keydown", handleGlobalKeyDown);
    };
  }, []);

  useEffect(() => {
    if (recommendations.length > 0) {
      setLoading(false); // Set loading to false when recommendations are loaded
    }
  }, [recommendations]);

  const handleSearch = async () => {
    try {
      updateContent();
      setLoading(true);
      const payload = {
        searchText,
        aiContent,
        conversationHistory,
      };

      const response = await axios.post(
        `${baseURL}/contract/generatedocchat/`,
        payload
      );

      if (response.status === 200) {
        const recommendations = response.data;
        setRecommendations(Object.values(recommendations));
        setConversationHistory([
          ...conversationHistory,
          { user: searchText, assistant: recommendations },
        ]);
      } else {
        throw new Error("Failed to fetch recommendations");
      }
    } catch (error) {
      console.error("Error fetching recommendations:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleOpenPopup = () => {
    updateContent();
    setPopupOpen(true);
    setSearchText("");
    setHasContent(false);
    setLoading(false);
  };

  const handleClosePopup = () => {
    setPopupOpen(false);
    setRecommendations([]);
    setSearchText("");
    setHasContent(false);
  };

  const handleMouseDown = (e) => {
    if (e.target.classList.contains("resize-handle")) {
      setResizing(true);
    } else {
      setResizing(false);
      prevMousePos.current = { x: e.clientX, y: e.clientY };
      document.addEventListener("mousemove", handleMouseMove);
      document.addEventListener("mouseup", handleMouseUp);
    }
  };

  const handleMouseMove = (e) => {
    if (!isResizing) {
      const dx = e.clientX - prevMousePos.current.x;
      const dy = e.clientY - prevMousePos.current.y;
      prevMousePos.current = { x: e.clientX, y: e.clientY };

      const popup = popupRef.current;
      popup.style.left = popup.offsetLeft + dx + "px";
      popup.style.top = popup.offsetTop + dy + "px";
    }
  };

  const handleMouseUp = () => {
    document.removeEventListener("mousemove", handleMouseMove);
    document.removeEventListener("mouseup", handleMouseUp);
  };

  const handleEnterPress = (e) => {
    if (e.key === "Enter") {
      handleSearch();
    }
  };

  const handleRecommendationSelect = (selectedRecommendation) => {
    setSelectedRecommendation(selectedRecommendation);
  };

  const handleClear = () => {
    setSearchText("");
    setRecommendations([]);
    setHasContent(false);
  };

  const handleSearchChange = (event) => {
    setSearchText(event.target.value);
    setHasContent(event.target.value.trim() !== "");
  };

  const handleCancelClick = () => {
    setPopupOpen(false);
    setRecommendations([]);
    setHasContent(false);
    setSearchText("");
    setConversationHistory([]);
  };

  const handleCopyClick = (textToCopy) => {
    try {
      const textarea = document.createElement("textarea");
      textarea.value = textToCopy;
      document.body.appendChild(textarea);
      textarea.select();
      document.execCommand("copy");
      document.body.removeChild(textarea);
      setCopiedText(textToCopy);
      toast.success("Copied to clipboard");
    } catch (error) {
      toast.error("Failed to copy to clipboard");
    }
  };

  return (
    <div>
      <button
        onClick={() => {
          handleOpenPopup();
        }}
        id="myButton"
        className="q-ai-button btn pb-1 pt-0.5 pl-6 pr-6 text_c font-bold rounded-lg btn_theme3"
        data-tip={t("constant.Chat with Document")}
      >
        <img src={clauseicon} alt="AI Image" className="ai-image" />
      </button>
      <ReactTooltip
        backgroundColor="#400835"
        textColor="#ffffff"
        className="max-w-lg break-words"
      />

      {isPopupOpen && (
        <div
          className={`popup-container${isResizing ? " resizing" : ""}`}
          ref={popupRef}
          onMouseDown={handleMouseDown}
        >
          <div className="clause-container">
            <button className="cancel-button" onClick={handleCancelClick}>
              x
            </button>
            <div className="clause-title">
              <h2 style={{ fontWeight: "bold", fontSize: "1.2em" }}>
                {t("Chat with Document")}
                {/* {aiContent} */}
              </h2>
              {isLoading && (
                <LoadingScreen /> // Use the LoadingScreen component when loading
              )}
            </div>
            <div className="search-container">
              <div className="inputBox1">
                <input
                  type="text"
                  placeholder={t("Type something...")}
                  value={searchText}
                  onChange={handleSearchChange}
                  onKeyPress={handleEnterPress}
                  style={{ width: "100%" }}
                />
              </div>
              <button onClick={handleSearch}>{t("allModule.Search")}</button>
              {hasContent && (
                <button className="ml-2" onClick={handleClear}>
                  {t("allModule.Clear")}
                </button>
              )}
            </div>
            <div className="recommendations-container">
              {recommendations.map((recommendation, index) => (
                <div key={index} className="recommendation-item">
                  <span
                    onClick={() => handleRecommendationSelect(recommendation)}
                    style={{
                      color:
                        copiedText === recommendation ? "#310128" : "black",
                      fontWeight:
                        copiedText === recommendation ? 500 : "normal",
                    }}
                  >
                    {recommendation}
                  </span>
                  <button onClick={() => handleCopyClick(recommendation)}>
                    <img
                      src={copy}
                      alt="Copy Icon"
                      className="copy-icon"
                      style={{ width: "20px", height: "20px" }}
                    />
                  </button>
                </div>
              ))}
            </div>
            <div className="resize-handle"></div>
          </div>
        </div>
      )}
    </div>
  );
});

export default DocChatAI;
