import React, { useState, useRef, useEffect } from "react";
import axios from "axios";
import "./clauseai.css";
import copy from "../../assets/icons/copy-clause.png";
import searchIcon from "../../assets/icons/Search-sm.svg"; // Import your search icon
import clearIcon from "../../assets/icons/clear.png"; // Import your clear icon
import useAxios from "../../hooks/useAxios";
import LoadingScreen from "../loader/LoadingScreen";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import ReactTooltip from "react-tooltip";
import { SearchIcon } from "@react-pdf-viewer/search";

function htmlToPlainText(html) {
  const tempDiv = document.createElement("div");
  tempDiv.innerHTML = html;
  return tempDiv.textContent || tempDiv.innerText || "";
}

const ClauseAINew = React.forwardRef((props, ref) => {
  const { documentData, className } = props;
  const [searchText, setSearchText] = useState("");
  const [isResizing, setResizing] = useState(false);
  const [recommendations, setRecommendations] = useState([]);
  const { t } = useTranslation();
  const [selectedRecommendation, setSelectedRecommendation] = useState("");
  const [hasContent, setHasContent] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [copiedText, setCopiedText] = useState(null);
  const baseURL = process.env.REACT_APP_BACKEND_BASE_URL;
  const api = useAxios();
  const prevMousePos = useRef({ x: 0, y: 0 });

  useEffect(() => {
    const handleGlobalKeyDown = (event) => {
      if (event.ctrlKey && event.key === "/") {
        setSearchText("");
        setHasContent(false);
        setLoading(false);
      }
    };

    window.addEventListener("keydown", handleGlobalKeyDown);

    return () => {
      window.removeEventListener("keydown", handleGlobalKeyDown);
    };
  }, []);

  useEffect(() => {
    if (recommendations.length > 0) {
      setLoading(false); // Set loading to false when recommendations are loaded
    }
  }, [recommendations]);

  const handleSearch = async () => {
    try {
      setLoading(true); // Set loading to true when searching
      const payload = {
        searchText: searchText,
        aiContent: htmlToPlainText(documentData),
      };
      const response = await axios.post(
        `${baseURL}/contract/generatecombination/`,
        payload
      );

      if (response.status === 200) {
        const recommendations = response.data;
        setRecommendations(Object.values(recommendations));
      } else {
        throw new Error("Failed to fetch recommendations");
      }
    } catch (error) {
      console.error("Error fetching recommendations:", error);
      setLoading(false); // Set loading to false in case of error
    }
  };

  const handleMouseDown = (e) => {
    if (e.target.classList.contains("resize-handle")) {
      setResizing(true);
    } else {
      setResizing(false);
      prevMousePos.current = { x: e.clientX, y: e.clientY };
      // document.addEventListener("mousemove", handleMouseMove);
      document.addEventListener("mouseup", handleMouseUp);
    }
  };

  const handleMouseUp = () => {
    // document.removeEventListener("mousemove", handleMouseMove);
    document.removeEventListener("mouseup", handleMouseUp);
  };

  const handleEnterPress = (e) => {
    if (e.key === "Enter") {
      handleSearch();
    }
  };

  const handleRecommendationSelect = (selectedRecommendation) => {
    setSelectedRecommendation(selectedRecommendation);
  };

  const handleClear = () => {
    setSearchText("");
    setRecommendations([]);
    setHasContent(false);
  };

  const handleSearchChange = (event) => {
    setSearchText(event.target.value);
    setHasContent(event.target.value.trim() !== "");
  };

  const handleCancelClick = () => {
    setRecommendations([]);
    setHasContent(false);
  };

  const handleCopyClick = (textToCopy) => {
    try {
      const textarea = document.createElement("textarea");
      textarea.value = textToCopy;
      document.body.appendChild(textarea);
      textarea.select();
      document.execCommand("copy");
      document.body.removeChild(textarea);
      setCopiedText(textToCopy);
      toast.success("Copied to clipboard");
    } catch (error) {
      toast.error("Failed to copy to clipboard");
    }
  };

  return (
    <div className={`chat-interface ${className}`}>
      <ReactTooltip
        backgroundColor="#400835"
        textColor="#ffffff"
        className="max-w-lg break-words"
      />

      <div className="clause-container">
        <button className="cancel-button" onClick={handleCancelClick}>
          x
        </button>
        <div className="clause-title">
          <h2 style={{ fontWeight: "bold", fontSize: "1.2em" }}>
            {t("allModule.Clause Recommender")}
          </h2>
          {isLoading && (
            <LoadingScreen /> // Use the LoadingScreen component when loading
          )}
        </div>
        <div className="pt-1 search-container">
          <div className="inputBox1">
            <input
              type="text"
              placeholder={t("Type Clause name...")}
              value={searchText}
              onChange={handleSearchChange}
              onKeyPress={handleEnterPress}
              style={{ width: "100%", paddingRight: "30px" }} // Add padding to make space for the icon
            />
            <img src={searchIcon} alt="Search" onClick={handleSearch} />
          </div>
          {/* <div
            className="button-container"
            style={{
              display: "flex",
              // justifyContent: "flex-end",
              alignItems: "center",
            }}
          > */}
          {/* <button
              onClick={handleSearch}
              className="icon-button"
              data-tip={t("Search")}
              style={{ width: "30%" }}
            >
              <img
                src={searchIcon}
                alt="Search"
                // style={{ width: "30px", height: "30px" }}
              />
            </button>
            {hasContent && (
              <button
                onClick={handleClear}
                className="icon-button ml-2"
                data-tip={t("Clear")}
                style={{ width: "30%" }}
              >
                <img
                  src={clearIcon}
                  alt="Clear"
                  // style={{ width: "30px", height: "30px" }}
                />
              </button>
            )} */}
          {/* </div> */}
        </div>
        <div className="recommendations-container">
          {recommendations.map((recommendation, index) => (
            <div key={index} className="recommendation-item">
              <span
                onClick={() => handleRecommendationSelect(recommendation)}
                style={{
                  color: copiedText === recommendation ? "#310128" : "black",
                  fontWeight: copiedText === recommendation ? 500 : "normal",
                }}
              >
                {recommendation}
              </span>
              <button onClick={() => handleCopyClick(recommendation)}>
                <img
                  src={copy}
                  alt="Copy Icon"
                  className="copy-icon"
                  style={{ width: "30px", height: "30px" }}
                />
              </button>
            </div>
          ))}
        </div>
        <div className="resize-handle"></div>
      </div>
    </div>
  );
});

export default ClauseAINew;
