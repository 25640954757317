import React from "react";
import "./ToggleSwitch.css";
  
const ToggleSwitch = ({ label, planDetail,setPlanDetail }) => {
  return (
    <div className="swchhh">
      {label}{" "}
      <div className="toggle-switch">
        <input type="checkbox" 
        // Seal And Stamp => seal_and_stamp_on
        // E-Stamp Attachment => e_stamp_attachment_on
        // Workflow => workflow_on
        // Email Notify => email_notify_on
        // SMS-Email Reminder => sms_email_reminder_on
        // Contact Book => contact_book_on
        // Document Search => document_search_on
        // Account Billing Access => account_billing_management_on
        // Multiple User => multiple_user_on
        // Admin Login => admin_login_on
        // Social Login => google_microsoft_login_on
        // QCM => Qcm_on
        // NDA Review => nda_review_on
        // Deligence => deligence_on
        // Transaction => Transaction_no
        // RBAC => rbac_on
        // Lawyer Partner Network => lawyer_partner_network_on
        
        checked={
            label=="AutoMagic"?planDetail.autoMagic:
            label=="Manual"?planDetail.manual:
            label=="Preset"?planDetail.presets:
            label=="Aadhaar Signee"?planDetail.aadhar:
            label=="Custom Template"?planDetail.customeTemplate:
            label=="Legal Template"?planDetail.legalTemplate:
            label=="AI Template"?planDetail.aiTemplate:
            label=="Legecy Integration"?planDetail.legecy_integration:
            label=="Available for Company"?planDetail.available_for_company:
            label=="Available for User"?planDetail.available_for_user:
            label=="Seal And Stamp"?planDetail.seal_and_stamp_on:
            label=="E-Stamp Attachment"?planDetail.e_stamp_attachment_on:
            label=="Workflow"?planDetail.workflow_on:
            label=="Email Notify"?planDetail.email_notify_on:
            label=="SMS-Email Reminder"?planDetail.sms_email_reminder_on:
            label=="Contact Book"?planDetail.contact_book_on:
            label=="Document Search"?planDetail.document_search_on:
            label=="Account Billing Access"?planDetail.account_billing_management_on:
            label=="Multiple User"?planDetail.multiple_user_on:
            label=="Admin Login"?planDetail.admin_login_on:
            label=="Social Login"?planDetail.google_microsoft_login_on:
            label=="QCM"?planDetail.Qcm_on:
            label=="NDA Review"?planDetail.nda_review_on:
            label=="Deligence"?planDetail.deligence_on:
            label=="Transaction"?planDetail.Transaction_no:
            label=="RBAC"?planDetail.rbac_on:
            label=="Lawyer Partner Network"?planDetail.lawyer_partner_network_on:
            false}
        onChange={(e) => 
            label=="AutoMagic"?setPlanDetail({...planDetail,autoMagic:e.target.checked}):
            label=="Manual"?setPlanDetail({...planDetail,manual:e.target.checked}):
            label=="Preset"?setPlanDetail({...planDetail,presets:e.target.checked}):
            label=="Aadhaar Signee"?setPlanDetail({...planDetail,aadhar:e.target.checked}):
            label=="Custom Template"?setPlanDetail({...planDetail,customeTemplate:e.target.checked}):
            label=="Legal Template"?setPlanDetail({...planDetail,legalTemplate:e.target.checked}):
            label=="AI Template"?setPlanDetail({...planDetail,aiTemplate:e.target.checked}):
            label=="Legecy Integration"?setPlanDetail({...planDetail,legecy_integration:e.target.checked}):
            label=="Available for Company"?setPlanDetail({...planDetail,available_for_company:e.target.checked}):
            label=="Available for User"?setPlanDetail({...planDetail,available_for_user:e.target.checked}):
            label=="Seal And Stamp"?setPlanDetail({...planDetail,seal_and_stamp_on:e.target.checked}):
            label=="E-Stamp Attachment"?setPlanDetail({...planDetail,e_stamp_attachment_on:e.target.checked}):
            label=="Workflow"?setPlanDetail({...planDetail,workflow_on:e.target.checked}):
            label=="Email Notify"?setPlanDetail({...planDetail,email_notify_on:e.target.checked}):
            label=="SMS-Email Reminder"?setPlanDetail({...planDetail,sms_email_reminder_on:e.target.checked}):
            label=="Contact Book"?setPlanDetail({...planDetail,contact_book_on:e.target.checked}):
            label=="Document Search"?setPlanDetail({...planDetail,document_search_on:e.target.checked}):
            label=="Account Billing Access"?setPlanDetail({...planDetail,account_billing_management_on:e.target.checked}):
            label=="Multiple User"?setPlanDetail({...planDetail,multiple_user_on:e.target.checked}):
            label=="Admin Login"?setPlanDetail({...planDetail,admin_login_on:e.target.checked}):
            label=="Social Login"?setPlanDetail({...planDetail,google_microsoft_login_on:e.target.checked}):
            label=="QCM"?setPlanDetail({...planDetail,Qcm_on:e.target.checked}):
            label=="NDA Review"?setPlanDetail({...planDetail,nda_review_on:e.target.checked}):
            label=="Deligence"?setPlanDetail({...planDetail,deligence_on:e.target.checked}):
            label=="Transaction"?setPlanDetail({...planDetail,Transaction_no:e.target.checked}):
            label=="RBAC"?setPlanDetail({...planDetail,rbac_on:e.target.checked}):
            label=="Lawyer Partner Network"?setPlanDetail({...planDetail,lawyer_partner_network_on:e.target.checked}):
            null}
        className="checkbox" 
               name={label} id={label} />
        <label className="label" htmlFor={label}>
          <span className="inner" />
          <span className="switch" />
        </label>
      </div>
    </div>
  );
};
  
export default ToggleSwitch;