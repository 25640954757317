import React, { useEffect, useContext, useState, useRef } from "react";
import ReactTooltip from "react-tooltip";
import LoadingScreen from "../loader/LoadingScreen";
import "../forms/companyForm.css";
import FormContext from "../../context/FormContext";
import AppModal from "../modals/Modal";
import "react-tagsinput/react-tagsinput.css";
import HelpIcon from "../../assets/icons/Help-sm.svg";
import LegalDocumentFile from "../../assets/LegalDocument.csv";

import Papa from "papaparse";
import useAxios from "../../hooks/useAxios";
import { useNavigate } from "react-router-dom";
import { use } from "i18next";
import { list } from "postcss";

const LegalDocumentForm = () => {
  const api = useAxios();
  const navigate = useNavigate();

  const {
    setCurrentForm,
    LegalDocumentFormErrors,
    modalData,
    setIsOpen,
    modalIsOpen,
    isEditing,
    editFormData,
    setEditFormData,
    setIsEditing,
    saveOrEditLegalDocument,
    loading,
    progressValue,
  } = useContext(FormContext);

  const [LegalDocumentForm, setLegalDocumentForm] = useState({
    template: "",
    template_name: "",
    token: "",
    company_id: "",
  });
  const fileInput = useRef();
  const [responceMsg, setResponceMsg] = useState({
    msg: "",
    toast: "",
  });
  const [open, setOpen] = useState(false);
  const [value, setValue] = useState(null);
  const [error, setError] = useState(null);
  const [companyList, setCompanyList] = useState([]);

  const handleFileChange = async (e) => {
    const { files } = e.target;
    setValue(files[0]);
    Papa.parse(files[0], {
      header: true,
      skipEmptyLines: true,
      complete: async function (results) {},
    });
  };

  useEffect(() => {
    fetchCompany();
  }, []);

  useEffect(() => {
    const list = companyList;
  }, [companyList]);

  const fetchCompany = async () => {
    try {
      const res = await api.get(
        `${process.env.REACT_APP_BACKEND_BASE_URL}/company/all/`
      );
      if (res.data) {
        setCompanyList(res.data.results);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const deleteFile = (e) => {
    fileInput.current.value = "";
    setValue(null);
  };

  const bulkUploadDocument = () => {
    let formData = new FormData();
    formData.append("file", value);

    api
      .post(
        `${process.env.REACT_APP_BACKEND_BASE_URL}/user/legal/template/bulk_upload/`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      )
      .then((resp) => {
        setResponceMsg({
          msg: resp.data.message,
          toast: "success",
        });
        setOpen(true);
        setTimeout(() => {
          // setActiveLink('ContactBook');
        }, 2000);
      })
      .catch((err) => {
        err.response.data.messages
          ? setResponceMsg({
              msg: err.response.data.messages[0].message,
              toast: "error",
            })
          : setResponceMsg({
              msg: err.response.data.message,
              toast: "error",
            });
        setOpen(true);
        if (!err.response.data.message) {
          setTimeout(() => {
            navigate("/login");
          }, 2000);
        }
      });
  };

  if (loading) {
    return <LoadingScreen />;
  }

  return (
    <div className="text-primary text-base font-normal font-sans upload_doc_page">
      <div className="main_head">
        <h1 className="text-4xl font-bold">
          {!isEditing ? "Add Legal Document" : "Edit Legal Document"}
        </h1>
      </div>

      <div className=" flex w-full items-center h-full ">
        <form className="w-2/5">
          <div className="flex flex-col mb-3 contract-type">
            <div className="flex">
              <label className="mb-2">Template Id</label>
              <>
                <img
                  src={HelpIcon}
                  width={12}
                  className="ml-1 -mt-2 cursor-pointer"
                  data-tip="Insert template id"
                />
                <ReactTooltip backgroundColor="#400835" textColor="#ffffff" />
              </>
            </div>
            <input
              type="number"
              placeholder="Insert template id"
              className="border-2 border-primary rounded-lg outline-none p-1 pl-2"
              value={
                !isEditing ? LegalDocumentForm.template : editFormData.template
              }
              onChange={(e) => {
                !isEditing
                  ? setLegalDocumentForm({
                      ...LegalDocumentForm,
                      template: e.target.value,
                    })
                  : setEditFormData({
                      ...editFormData,
                      template: e.target.value,
                    });
              }}
            />
            <p className="text-sm text-red-500 font-normal">
              {LegalDocumentFormErrors.template}
            </p>
          </div>

          <div className="flex flex-col mb-3">
            <div className="flex">
              <label className="mb-2">Template Name</label>
              <>
                <img
                  src={HelpIcon}
                  width={12}
                  className="ml-1 -mt-2 cursor-pointer"
                  data-tip="Template name"
                />
                <ReactTooltip backgroundColor="#400835" textColor="#ffffff" />
              </>
            </div>
            <input
              placeholder="Template name"
              className="border-2 border-primary rounded-lg outline-none p-1 pl-2"
              value={
                !isEditing
                  ? LegalDocumentForm.template_name
                  : editFormData.template_name
              }
              onChange={(e) => {
                !isEditing
                  ? setLegalDocumentForm({
                      ...LegalDocumentForm,
                      template_name: e.target.value,
                    })
                  : setEditFormData({
                      ...editFormData,
                      template_name: e.target.value,
                    });
              }}
            />
            <p className="text-sm text-red-500 font-normal">
              {LegalDocumentFormErrors.template_name}
            </p>
          </div>

          <div className="flex flex-col mb-3">
            <div className="flex">
              <label className="mb-2">Template Token</label>
              <>
                <img
                  src={HelpIcon}
                  width={12}
                  className="ml-1 -mt-2 cursor-pointer"
                  data-tip="Insert template token"
                />
                <ReactTooltip backgroundColor="#400835" textColor="#ffffff" />
              </>
            </div>
            <input
              placeholder="Insert template token"
              className="border-2 border-primary rounded-lg outline-none p-1 pl-2"
              value={!isEditing ? LegalDocumentForm.token : editFormData.token}
              onChange={(e) => {
                !isEditing
                  ? setLegalDocumentForm({
                      ...LegalDocumentForm,
                      token: e.target.value,
                    })
                  : setEditFormData({
                      ...editFormData,
                      token: e.target.value,
                    });
              }}
            />
            <p className="text-sm text-red-500 font-normal">
              {LegalDocumentFormErrors.token}
            </p>
          </div>

          <div className="flex flex-col mb-3 contract-type">
            <div className="flex">
              <label className="mb-2">Company Id</label>
              <>
                <img
                  src={HelpIcon}
                  width={12}
                  className="ml-1 -mt-2 cursor-pointer"
                  data-tip="Select Company"
                />
                <ReactTooltip backgroundColor="#400835" textColor="#ffffff" />
              </>
            </div>
            <input
              type="number"
              placeholder="Insert Company id"
              className="border-2 border-primary rounded-lg outline-none p-1 pl-2"
              value={
                !isEditing
                  ? LegalDocumentForm.company_id
                  : editFormData.company_id
              }
              onChange={(e) => {
                !isEditing
                  ? setLegalDocumentForm({
                      ...LegalDocumentForm,
                      company_id: e.target.value,
                    })
                  : setEditFormData({
                      ...editFormData,
                      company_id: e.target.value,
                    });
              }}
            />
            {/* <select
              className="border-2 border-primary rounded-lg outline-none p-1 pl-2"
              value={
                !isEditing
                  ? LegalDocumentForm.company_id
                  : editFormData.company_id
              }
              onChange={(e) => {
                const selectedCompanyId = e.target.value;
                !isEditing
                  ? setLegalDocumentForm({
                      ...LegalDocumentForm,
                      company_id: selectedCompanyId,
                    })
                  : setEditFormData({
                      ...editFormData,
                      company_id: selectedCompanyId,
                    });
              }}
            >
              <option value="">Select Company</option>
              {companyList.map((company) => (
                <option key={company.id} value={company.id}>
                  {company.name}
                </option>
              ))}
            </select> */}
            {/* <p className='text-sm text-red-500 font-normal'>
                            {LegalDocumentFormErrors.company_id}
                        </p> */}
          </div>

          {!isEditing ? (
            <>
              <button
                className="bg-primary font-bold text-white w-full p-1 rounded-lg mt-3"
                onClick={(e) => {
                  e.preventDefault();
                  saveOrEditLegalDocument(LegalDocumentForm);
                }}
              >
                Add Document
              </button>
              <p className="text-sm text-red-500 font-normal">
                {LegalDocumentFormErrors.document}
              </p>
            </>
          ) : (
            <button
              className="bg-secondary font-bold text-white w-full p-1 rounded-lg"
              onClick={(e) => {
                e.preventDefault();
                saveOrEditLegalDocument(editFormData);
              }}
            >
              Update Document
            </button>
          )}
        </form>

        {!isEditing && (
          <>
            <div className=" ml-12  w-2/5 flex items-center">
              <div
                className="bodder mb-4"
                style={{
                  height: "300px",
                  width: 5,
                  background: "#400835",
                  borderRadius: 10,
                }}
              ></div>
              <div className="w-full flex contact-book-right-btn">
                <a
                  className="bg-secondary ml-2 font-bold text-white p-1 px-2 rounded-lg mb-3"
                  href={LegalDocumentFile}
                >
                  Download Template
                </a>

                <p className="text-sm text-red-500 font-normal ml-5 mt-3">
                  {LegalDocumentFormErrors.multi_document}
                </p>

                <button
                  className="bg-secondary font-bold text-white ml-2 p-1 px-2 rounded-lg contact-book-right-btn mb-3"
                  onClick={() => fileInput.current.click()}
                >
                  Bulk Upload Document
                </button>
                <input
                  id="files"
                  style={{ display: "none" }}
                  type="file"
                  ref={fileInput}
                  onChange={handleFileChange}
                  accept=".csv"
                />
                <p className="text-sm text-red-500 font-normal ml-5 mt-3">
                  {LegalDocumentFormErrors.multi_document}
                </p>
              </div>
            </div>

            <div className=" ml-5  w-2/5">
              {open && <div>{responceMsg.msg}</div>}

              {value !== null && error == null && (
                <>
                  {value?.name}

                  <button
                    onClick={deleteFile}
                    className="btn btn_theme2 btn-sm ml-3 mb-3"
                  >
                    delete
                  </button>
                  <button
                    className="btn btn_theme btn-sm ml-3 mb-3"
                    onClick={bulkUploadDocument}
                  >
                    Save Document
                  </button>
                </>
              )}
            </div>
          </>
        )}
      </div>

      <AppModal
        title={modalData.title}
        bodyText={modalData.bodyText}
        setIsOpen={setIsOpen}
        modalIsOpen={modalIsOpen}
        modelAction="LegalDocumentForm"
        apiResStatus={modalData.apiResStatus}
      />
    </div>
  );
};

export default LegalDocumentForm;
