import React, { useEffect, useContext, useState, useRef } from 'react';
import ReactTooltip from 'react-tooltip';
import { useTranslation } from "react-i18next";
import LoadingScreen from '../loader/LoadingScreen';
import '../forms/companyForm.css';
import FormContext from '../../context/FormContext';
import AppModal from '../modals/Modal';
import 'react-tagsinput/react-tagsinput.css';
import HelpIcon from '../../assets/icons/Help-sm.svg';
import LegalDocumentFile from '../../assets/LegalDocument.csv'
import addbuttons from "../../assets/icons/addbuttons.svg";
import Marketplace from "../../assets/icons/marketPlace.png";
import CustomPhoneInput from "../forms/PhoneInput";
import { toast } from "react-toastify";
import AppContext from '../../context/AppContext';
import { resolve } from '../../api/resolve';


import Papa from 'papaparse';
import useAxios from '../../hooks/useAxios';
import { useNavigate } from 'react-router-dom';

const MarketPlaceForm = () => {
    const api = useAxios();
    const navigate = useNavigate();

    const {
        setCurrentForm,
        LegalDocumentFormErrors,
        modalData,
        setIsOpen,
        modalIsOpen,
        isEditing,
        editFormData,
        setEditFormData,
        setIsEditing,
        saveOrEditLegalDocument,
        loading,
        progressValue,

    } = useContext(FormContext);

    const { setActiveLink, setShowDropdown } = useContext(AppContext);
    

    const [MarketPlaceForm, setMarketPlaceForm] = useState({
        firm_name: '',
        contact_person: '',
        specialisation: '',
        address:'',
        city:'',
        country:'',
        email:'',
        phone:'',
        state:'',
        
    });
    const { t } = useTranslation();
    const fileInput = useRef();
    const [responceMsg, setResponceMsg] = useState({
        msg: '',
        toast: '',
    });
    const [open, setOpen] = useState(false);
    const [value, setValue] = useState(null);
    const [error, setError] = useState(null);
    const [selectedspecialisation, setselectedspecialisation] = useState('');
    const [specialisationlist, setspecialisationlist] = useState([]);
    const [isValidname, setIsValidname] = useState(true);
    const [isValidnamelength, setisValidnamelength] = useState(true);
    const [isValidemail, setIsValidemail] = useState(true);
    const [isValidfirmnamelength, setisValidfirmnamelength] = useState(true);
    const [isValidaddress, setisValidaddress] = useState(true);
    const [isValidspecialisationlength, setisValidspecialisationlength] = useState(true);
    const fullNameRegex = /^[a-zA-Z]+(?:\s+[a-zA-Z]+)+$/;
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const [Addspecialisationdata, setAddspecialisationdata] = useState({
        specialisation: "",
      });
    const [Addmarketplace,setAddmarketplace] = useState({
        firm_name: '',
        contact_person: '',
        specialisation: '',
        address:'',
        city:'',
        country:'',
        email:'',
        phone:'',
        state:'',
        logo:''
    });
    const [countrylist, setcountrylist] = useState([]);
    const [StateListData, setStateListData] = useState([]);
    const [CityListData, setCityListData] = useState([]);
    const [selectedcountry, setselectedcountry] = useState('');
    const [selectedstate, setselectedstate] = useState('');
    const [selectedcity, setselectedcity] = useState('');
    const [contactshowError, setcontactshowError] = useState(true);
    const [file, setFile] = useState(null);
    const baseUrl = process.env.REACT_APP_BACKEND_BASE_URL;
    
    const handleFileChange = async (e) => {
        const { files } = e.target;
        setValue(files[0]);
        Papa.parse(files[0], {
            header: true,
            skipEmptyLines: true,
            complete: async function (results) {

            },

        });

    };
    
    
    const handlemarketcallforeditfirst = async (e) => {    
        console.log("edit form data values",editFormData);
        const handlemarketcallforedit = async (e) => {
            try {
                
            // const res = await api.get(`${baseUrl}/company/title/list/${selectedName}`);
            const res = await api.get(`${baseUrl}/contract/marketplace/view/single/${editFormData.id}/`);
            //   const res = await resolve(api.get(`contract/marketplace/view/single/${editFormData.id}/`,));          
            //   setMarketPlaceForm(res);
            setEditFormData(res.data);
            console.log("editformdata",editFormData);
            } catch (error) {
            // Handle errors
            console.error("Error submitting share data:", error.response.data);
            // Optionally display an error message to the user
            }
        };
        handlemarketcallforedit();
        // setMarketPlaceForm((prevState) => ({
        //     ...prevState,
        //     [name]: value,
        //   }));
    };
    


    const deleteFile = (e) => {
        fileInput.current.value = '';
        setValue(null);
    };

    const handletitleapicall = async () => {
        try {
          // const res = await api.get(`${baseUrl}/company/title/list/${selectedName}`);
          const res = await api.get(`${baseUrl}/contract/specialisationmarket/view/`);          
          setspecialisationlist(res.data);
          console.log(res.data);
         
        } catch (error) {
          // Handle errors
          console.error("Error submitting share data:", error.response.data);
          // Optionally display an error message to the user
        }
      };

    useEffect(() => {
        // Call handletitleapicall function when component mounts
        handletitleapicall();
        handleButtonaddcompany();
        if (isEditing==true){
            handlemarketcallforeditfirst();
        }
    
        // Cleanup function (optional) if needed
        return () => {
          // Perform any cleanup here if necessary
        };
    }, []);
    const handleButtonaddcompany = () => {
        const handlecountryapicall = async () => {
          try {
            const res = await api.get(`${baseUrl}/company/countries/list/`);
    
            setcountrylist(res.data)
            setStateListData([]);
            setCityListData([]);
          } catch (error) {
            // Handle errors
            console.error("Error submitting share data:", error.response.data);
            // Optionally display an error message to the user
          }
        };
        handlecountryapicall();
    
      };
    const handleChangeforspecialisation = (e) => {

        const selectedName = e.target.value;
    
        const selectedObject = specialisationlist.find(item => item.id == selectedName);
        console.log("specialisationlist",specialisationlist,selectedName);
        // handletitleapicall();
        // Update selected option in state
        setselectedspecialisation(selectedName);
    
        // Update shareData with selected name and its corresponding value
        // setAddcontactdata(prevState => ({
        //   ...prevState,
        //   [name]: selectedName,
        //   // Assuming you want to set both the name and email from selectedObject
        //   // company_id: selectedObject ? selectedObject.id : '',
        //   // email: selectedObject ? selectedObject.email : ''
        // }));
        const { name, value } = e.target;
        if (isEditing==true){
            setEditFormData((prevState) => ({
                ...prevState,
                [name]: selectedObject.specialisation,
              }));
        }
        else{
        setMarketPlaceForm((prevState) => ({
          ...prevState,
          [name]: selectedObject.specialisation,
        }));
    }
      };
    const [showAdditionalFieldsspecialisation, setshowAdditionalFieldsspecialisation] = useState(false);
    const handleButtonaddspecialisation = (e) => {
        // e.stoppropagation();
        e.preventDefault();
        console.log("Jhh",e)
        setshowAdditionalFieldsspecialisation(true);
        handletitleapicall();
    };
    const handleaddspecialisation = async (e) => {
        try{
            e.preventDefault();
            let specialisation = Addspecialisationdata.specialisation;
            console.log(specialisation);
            const res = (specialisation) && await api.post(`${baseUrl}/contract/specialisationmarket/create/`, Addspecialisationdata);
            setshowAdditionalFieldsspecialisation(false);
            handletitleapicall();

        }catch (error) {
            // Handle errors
            console.error("Error submitting specialisation:", error.response.data);
            // Optionally display an error message to the user
          }
    };
    const handleChangeforaddcountry = (e) => {
        const selectedName = e.target.value;
    
        const { name, value } = e.target;
    
        const selectedObject = countrylist.find(item => item.id == selectedName);
        // console.log("country",selectedObject.name, value)
        
        const handlecountryapicall = async () => {
          try {
            const res = await api.get(`${baseUrl}/company/state/list/${value}`);
        //     let countryId = 231;
        //     if(value==101 || value==230 || value==0){
        //       countryId = value
        //   }else{
        //       countryId = 231;
        //   }
            // const resent = await api.get(`${baseUrl}/user/entities/list/${countryId}`);
            // user/entities/list/${name}
            setStateListData(res.data);
            // console.log(StateListData);
            // setCityListData(resent.data);
            // setEntityListData(resent.data);
           
          } catch (error) {
            // Handle errors
            console.error("Error submitting share data:", error.response.data);
            // Optionally display an error message to the user
          }
        };
        handlecountryapicall();
    
        // const dummylist = [selectedName];
        // Update selected option in state
        setselectedcountry(selectedName);
        if (isEditing==true){            
            setEditFormData((prevState) => ({
                ...prevState,
                [name]: selectedObject.name,
              }));
              
        }
        else{
        setMarketPlaceForm((prevState) => ({
          ...prevState,
          [name]: selectedObject.name,
        }));
    }
      };
    const handleChangeforaddcompanystate = (e) => {
    const selectedName = e.target.value;

    const { name, value } = e.target;

    const selectedObject = StateListData.find(item => item.id == selectedName);
    
    const handlecountryapicall = async () => {
        try {
        const res = await api.get(`${baseUrl}/company/cities/list/${value}`);

        setCityListData(res.data);
        console.log("CITY",CityListData)
        } catch (error) {
        // Handle errors
        console.error("Error submitting share data:", error.response.data);
        // Optionally display an error message to the user
        }
    };
    handlecountryapicall();
    setselectedstate(selectedName)
    if (isEditing==true){            
        setEditFormData((prevState) => ({
            ...prevState,
            [name]: selectedObject.name,
          }));
          
    }
    else{
    setMarketPlaceForm((prevState) => ({
        ...prevState,
        [name]: selectedObject.name,
    }));
}
    };

    const handleChangeforaddspecialisation = (e) => {
        const { name, value } = e.target;
        if (value.length>49){
            setisValidspecialisationlength(false);
        }
        setAddspecialisationdata((prevState) => ({
        ...prevState,
        [name]: value,
        }));
    };
    const handleChangeforaddcompany = (e) => {
        const selectedName = e.target.value;
    
        const { name, value } = e.target;
        if (name == "city") {
          setselectedcity(selectedName);
          const selectedObject = CityListData.find(item => item.id == selectedName);
        //   value = selectedObject.name; 
          console.log("city name",value,selectedName)
          console.log("city object",selectedObject)
          if (isEditing==true){            
            setEditFormData((prevState) => ({
                ...prevState,
                [name]: selectedObject.name,
              }));
              
        }
        else{
        setMarketPlaceForm((prevState) => ({
            ...prevState,
            [name]: selectedObject.name,
        }));
    }

        }
        else{
        setMarketPlaceForm((prevState) => ({
          ...prevState,
          [name]: value,
        }));}
      };

    const bulkUploadDocument = () => {

        let formData = new FormData();
        formData.append("file", value);

        api
            .post(
                `${process.env.REACT_APP_BACKEND_BASE_URL}/contract/marketplace/create/`,
                formData,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                }
            )
            .then((resp) => {

                setResponceMsg({
                    msg: resp.data.message,
                    toast: 'success',
                });
                setOpen(true);
                setTimeout(() => {
                    // setActiveLink('ContactBook');
                }, 2000);
            })
            .catch((err) => {

                err.response.data.messages
                    ? setResponceMsg({
                        msg: err.response.data.messages[0].message,
                        toast: 'error',
                    })
                    : setResponceMsg({
                        msg: err.response.data.message,
                        toast: 'error',
                    });
                setOpen(true);
                if (!err.response.data.message) {
                    setTimeout(() => {
                        navigate('/login');
                    }, 2000);
                }
            });
    };
    const handleChange = (e) => {

        const selectedName = e.target.value;
        // const selectedObject = contactList.find(item => item.name === selectedName);
        // setSelectedOption(e.target.value);
    
    
        const { name, value } = e.target;
        setAddmarketplace((prevState) => ({
          ...prevState,
          [name]: value,
        }));
    
      };

    if (loading) {
        return <LoadingScreen />;
    }

    const saveMarketplace = async () => {
        try {
            const address = MarketPlaceForm.address+", "+MarketPlaceForm.city+", "+MarketPlaceForm.state+", "+MarketPlaceForm.country;
            
            const marketplaceadminform = new FormData();
            marketplaceadminform.append('logo', file);
            // Append other form data to MarketPlaceForm as needed
            marketplaceadminform.append('firm_name', MarketPlaceForm.firm_name);
            marketplaceadminform.append('contact_person', MarketPlaceForm.contact_person);
            marketplaceadminform.append('specialisation', MarketPlaceForm.specialisation);
            marketplaceadminform.append('address', address);
            marketplaceadminform.append('city', MarketPlaceForm.city);
            marketplaceadminform.append('country', MarketPlaceForm.country);
            marketplaceadminform.append('email', MarketPlaceForm.email);
            marketplaceadminform.append('phone', MarketPlaceForm.phone);
            marketplaceadminform.append('state', MarketPlaceForm.state);                   
        //   setcontactshowError(name&&phone&&email&&designation&&group_id)
        setcontactshowError(MarketPlaceForm.firm_name!=""&&MarketPlaceForm.contact_person!=""&&MarketPlaceForm.specialisation!=""
            &&address!=""&&MarketPlaceForm.city!=""&&MarketPlaceForm.country!=""&&MarketPlaceForm.email!=""&&MarketPlaceForm.phone!=""
            &&MarketPlaceForm.state!=""&&file);
            console.log("setcontactshowError",contactshowError)
        
          const res = (MarketPlaceForm.firm_name&&MarketPlaceForm.contact_person&&MarketPlaceForm.specialisation
            &&address&&MarketPlaceForm.city&&MarketPlaceForm.country&&MarketPlaceForm.email&&MarketPlaceForm.phone
            &&MarketPlaceForm.state&&file) && await api.post(`${baseUrl}/contract/marketplace/create/`, marketplaceadminform);
          if (res.data.message === "Added Successfully") {
            // If API call is successful, hide the popup and change button color
            toast.success(res.data.message);
            setActiveLink('AdminMarketPlace');
            setMarketPlaceForm({
                firm_name: '',
                contact_person: '',
                specialisation: '',
                address:'',
                city:'',
                country:'',
                email:'',
                phone:'',
                state:'',
                
            });
            // You can optionally perform additional actions here
          } else {
            toast.error("Failed to Add contact");
            
            // Handle error cases
            throw new Error("Failed to Add Contact");    
          }
          setselectedstate("");
          setselectedcountry("");
          setselectedcity("");
        } catch (error) {
            // setActiveLink('AdminMarketPlace')
          // Handle errors
          console.error("Error submitting share data:", error.response.data.message);
          // Optionally display an error message to the user
        }
        setisValidaddress(true);
        setisValidfirmnamelength(true);
        setisValidnamelength(true);
        setisValidspecialisationlength(true);
        setIsValidemail(true);
        
      };

    const handleClosePopup = async () => {
        setisValidaddress(true);
        setisValidfirmnamelength(true);
        setisValidnamelength(true);
        setisValidspecialisationlength(true);
        setIsValidemail(true);
        setActiveLink('AdminMarketPlace');
    };
    const editMarketplace = async () => {
        try {
            const address = editFormData.address+", "+editFormData.city+", "+editFormData.state+", "+editFormData.country;
            console.log("editFormData",editFormData);
            const marketplaceadminform = new FormData();
            marketplaceadminform.append('logo', file);
            // Append other form data to MarketPlaceForm as needed
            marketplaceadminform.append('firm_name', editFormData.firm_name);
            marketplaceadminform.append('id', editFormData.id);
            marketplaceadminform.append('contact_person', editFormData.contact_person);
            marketplaceadminform.append('specialisation', editFormData.specialisation);
            marketplaceadminform.append('address', address);
            marketplaceadminform.append('city', editFormData.city);
            marketplaceadminform.append('country', editFormData.country);
            marketplaceadminform.append('email', editFormData.email);
            marketplaceadminform.append('phone', editFormData.phone);
            marketplaceadminform.append('state', editFormData.state);
            console.log("editFormDatafinal",marketplaceadminform);
                    

        //   setcontactshowError(name&&phone&&email&&designation&&group_id)
          const res = await api.put(`${baseUrl}/contract/marketplace/edit/`, marketplaceadminform);
          console.log("Hi")
          
          if (res.data) {
            // If API call is successful, hide the popup and change button color
            toast.success("Updated Successfully");
            setActiveLink('AdminMarketPlace')
            
            setMarketPlaceForm({
                firm_name: '',
                contact_person: '',
                specialisation: '',
                address:'',
                city:'',
                country:'',
                email:'',
                phone:'',
                state:'',
                
            });
            // You can optionally perform additional actions here
          } else {
            toast.error("Failed to Update");
            setActiveLink('AdminMarketPlace')
            // Handle error cases
            throw new Error("Failed to Update");
    
          }
          setselectedstate("");
          setselectedcountry("");
          setselectedcity("");
        } catch (error) {
            
            toast.error("Failed to Update");
            setActiveLink('AdminMarketPlace')
          // Handle errors
          console.error("Error submitting share data:", error.response.data.message);
          // Optionally display an error message to the user
        }
        setisValidaddress(true);
        setisValidfirmnamelength(true);
        setisValidnamelength(true);
        setisValidspecialisationlength(true);
        setIsValidemail(true);
        
      };
    const updatecontactperson = async (e) => {
        value = e.target.value;
        if (value.length>100){
            setisValidnamelength(false);
        }
        if(!isEditing){
            setMarketPlaceForm({ ...MarketPlaceForm, contact_person: e.target.value })
        }
        else{
            setEditFormData({
            ...editFormData,
            contact_person: e.target.value,
        });
    }
    };
    return (
        <div className='text-primary text-base font-normal font-sans upload_doc_page'>
            <div className="main_head">
                <h1 className='text-4xl font-bold'>
                    {!isEditing ? 'Add MarketPlace' : 'Edit MarketPlace'}
                    {/* <button
                className="bg-[#606060] font-semibold text-white p-1 pl-1 pr-1 rounded-lg"
                onClick={handleClosePopup}
              >
                X
              </button> */}
                </h1>
                
            </div>

            <div className=' flex w-full items-center h-full '>
                <form className='w-2/4'>
                    <div className='flex flex-col mb-3 contract-type'>
                        <div className='flex'>
                            <label className='mb-2'>{t('form.Firm Name')}</label>
                            <>
                                <img
                                    src={HelpIcon}
                                    width={12}
                                    className='ml-1 -mt-2 cursor-pointer'
                                    data-tip={t('form.Firm Name')}
                                />
                                <ReactTooltip backgroundColor='#400835' textColor='#ffffff' />
                            </>
                        </div>
                        <input
                            type='text'
                            placeholder={t('form.Insert Firm Name')}
                            className='border-2 border-primary rounded-lg outline-none p-1 pl-2'
                            value={!isEditing ? MarketPlaceForm.firm_name : editFormData.firm_name}
                            onChange={(e) => {
                                if (e.target.value.length>100){setisValidfirmnamelength(false);}
                                !isEditing
                                    ? setMarketPlaceForm({ ...MarketPlaceForm, firm_name: e.target.value })
                                    : setEditFormData({
                                        ...editFormData,
                                        firm_name: e.target.value,
                                    });
                            }}
                        />
                        {!isValidfirmnamelength &&<span className="error-text">Firm Name can't have more than 100 characters</span>}
                        <p className='text-sm text-red-500 font-normal'>
                            {LegalDocumentFormErrors.template}
                        </p>
                    </div>

                    <div className='flex flex-col mb-3'>
                        <div className='flex'>
                            <label className='mb-2'>{t('form.Contact Person')}</label>
                            <>
                                <img
                                    src={HelpIcon}
                                    width={12}
                                    className='ml-1 -mt-2 cursor-pointer'
                                    data-tip={t('form.Contact Person')}
                                />
                                <ReactTooltip backgroundColor='#400835' textColor='#ffffff' />
                            </>
                        </div>
                        <input
                            placeholder={t('form.Enter Contact Person Name')}
                            className='border-2 border-primary rounded-lg outline-none p-1 pl-2'
                            value={!isEditing ? MarketPlaceForm.contact_person : editFormData.contact_person}
                            onChange={(e) => {
                                if (e.target.value.length>50){setisValidnamelength(false);}
                                !isEditing
                                    ? setMarketPlaceForm({ ...MarketPlaceForm, contact_person: e.target.value })
                                    : setEditFormData({
                                        ...editFormData,
                                        contact_person: e.target.value,
                                    });
                                
                            }}
                        />
                        {!isValidnamelength &&<span className="error-text">Contact Name can't have more than 50 characters</span>}
                        <p className='text-sm text-red-500 font-normal'>
                            {LegalDocumentFormErrors.template_name}
                        </p>
                    </div>

                    <div className='flex flex-col mb-3'>
                        <div className='flex'>
                            <label className='mb-2'>{t('form.Specialisation')}</label>
                            <>
                                <img
                                    src={HelpIcon}
                                    width={12}
                                    className='ml-1 -mt-2 cursor-pointer'
                                    data-tip={t('form.Specialisation')}
                                />
                                <ReactTooltip backgroundColor='#400835' textColor='#ffffff' />
                            </>
                        </div>
                        <div className="flex">
                            <select
                            id="specialisation"
                            name="specialisation"
                            style={{ width: '400px'}}
                            value={selectedspecialisation} onChange={handleChangeforspecialisation}>
                            <option value="">{t('form.Select Specialisation')}</option>
                            {specialisationlist.map(item => (
                                <option key={item.id} value={item.id}>{item.specialisation}</option>
                            ))}
                            </select>
                            <button className="btn " onClick={handleButtonaddspecialisation}>                  
                            <img
                                src={addbuttons}
                                className="ml-0.5"
                                width={15}
                                data-tip={t('form.Specialisation')}
                            // alt="Share with External Person"
                            />
                            <ReactTooltip backgroundColor='#400835' textColor='#ffffff' />
                            </button>
                        </div>
                        
                    </div>
                    
                    {showAdditionalFieldsspecialisation &&
                    <div className="flex flex-col mb-3">
                        <div className="flex">
                        <label className="mb-2">{t('form.Add Specialisation')}</label>
                        <>
                            <img
                            src={HelpIcon}
                            width={12}
                            className="ml-1 -mt-2 cursor-pointer"
                            data-tip={t('form.Insert an unique Specialisation')}
                            />
                            <ReactTooltip backgroundColor="#400835" textColor="#ffffff" />
                        </>
                        </div>
                        <input
                        type="text"
                        id="specialisation"
                        name="specialisation"
                        placeholder={t('loginPage.Enter Specialisation')}
                        value={Addspecialisationdata.specialisation}
                        onChange={handleChangeforaddspecialisation}
                        className="input-field-small"
                        />
                    </div>
                                        
                    }
                    {!isValidspecialisationlength &&<span className="error-text">Specialisation can't have more than 50 characters</span>}
                    {showAdditionalFieldsspecialisation &&
                        <button className="btn btn_theme btn-sm mr-1 wh_spab submit-button" onClick={handleaddspecialisation}>
                        {t('form.Add Specialisation')}
                      </button>
                    }
                    <div className="flex flex-col mb-3 contract-type">
                        <div className="flex">
                            <label className="mb-2">{t('form.Phone')}</label>
                            <>
                            <img
                                src={HelpIcon}
                                width={12}
                                className="ml-1 -mt-2 cursor-pointer"
                                data-tip={t('common.Enter Phone Number')}
                            />
                            <ReactTooltip backgroundColor="#400835" textColor="#ffffff" />
                            </>
                        </div>
                        
                        <CustomPhoneInput
                        value={!isEditing ? MarketPlaceForm.phone : editFormData.phone}
                        onChange={(phone) => {
                            phone= "+" + phone
                            setMarketPlaceForm({ ...MarketPlaceForm, phone })
                            
                        }}
                        />
                        
                    </div>
                    <div className="flex flex-col mb-3">
                        <div className="flex">
                        <label className="mb-2">{t('form.Email')}</label>
                        <>
                            <img
                            src={HelpIcon}
                            width={12}
                            className="ml-1 -mt-2 cursor-pointer"
                            data-tip={t('form.Insert an unique email address')}
                            />
                            <ReactTooltip backgroundColor="#400835" textColor="#ffffff" />
                        </>
                        </div>
                        <input
                        type="email"
                        id="email"
                        name="email"
                        placeholder={t('loginPage.Enter email address')}
                        
                        value={!isEditing ? MarketPlaceForm.email : editFormData.email}
                        onChange={(e) => {

                            setIsValidemail(emailRegex.test(e.target.value));
                            !isEditing
                                ? setMarketPlaceForm({ ...MarketPlaceForm, email: e.target.value })
                                : setEditFormData({
                                    ...editFormData,
                                    email: e.target.value,
                                });
                        }}
                        className="input-field-small"
                        />
                    </div>
                    {!isValidemail &&<span className="error-text">Email is not in correct format</span>}
                    <div className="flex flex-col mb-3">
                        <div className="flex">
                        <label className="mb-2">{t('form.Address')}</label>
                        <>
                            <img
                            src={HelpIcon}
                            width={12}
                            className="ml-1 -mt-2 cursor-pointer"
                            data-tip={t('form.Enter complete address')}
                            />
                            <ReactTooltip backgroundColor="#400835" textColor="#ffffff" />
                        </>
                        </div>
                        <input
                        type="text"
                        id="address"
                        name="address"
                        placeholder={t('form.Enter complete address')}
                        value={!isEditing ? MarketPlaceForm.address : editFormData.address}
                        onChange={(e) => {
                            if (e.target.value.length>199){
                                setisValidaddress(false)
                            }
                            !isEditing
                                ? setMarketPlaceForm({ ...MarketPlaceForm, address: e.target.value })
                                : setEditFormData({
                                    ...editFormData,
                                    address: e.target.value,
                                });
                        }}
                        className="input-field-small"
                        />
                    </div>
                    {!isValidaddress &&<span className="error-text">Address can't be more than 200 characters</span>}
                    <div className="flex flex-col mb-3">
                    
                    <select
                    id="country"
                    name="country"
                    value={selectedcountry} onChange={handleChangeforaddcountry}>
                    <option value="">{t('allModule.Select Country')}</option>
                    {countrylist.map(item => (
                        <option key={item.id} value={item.id}>{item.name}</option>
                    ))}
                    </select>
                </div>
                
                <div className="flex flex-col mb-3">                    
                    <select
                    id="state"
                    name="state"
                    value={selectedstate} onChange={handleChangeforaddcompanystate}>
                    <option value="">{t('form.Select state')}</option>
                    {StateListData.map(item => (
                        <option key={item.id} value={item.id}>{item.name}</option>
                    ))}
                    </select>
                </div>
                <div className="flex flex-col mb-3">                    
                    <select
                    id="city"
                    name="city"
                    value={selectedcity} onChange={handleChangeforaddcompany}>
                    <option value="">{t('form.Select city')}</option>
                    {CityListData.map(item => (
                        <option key={item.id} value={item.id}>{item.name}</option>
                    ))}
                    </select>
                </div>
                <div className="flex flex-col mb-3">
                        <div className="flex">
                        <label className="mb-2">{t('form.Logo')}</label>
                        <>
                            <img
                            src={HelpIcon}
                            width={12}
                            className="ml-1 -mt-2 cursor-pointer"
                            data-tip={t('form.Upload Logo')}
                            />
                            <ReactTooltip backgroundColor="#400835" textColor="#ffffff" />
                        </>
                        </div>
                        <input
                        type="file"
                        accept="image/png, image/jpeg, image/jpg"
                        id="logo"
                        name="logo"                        
                        onChange={(e) => setFile(e.target.files[0])}
                        className="input-field-small"
                        />
                    </div>
                    {!contactshowError && <span className="error-text">All Fields are required</span>}&nbsp;
                    {!isEditing ? (
                        <>
                        
                            <button
                                className='bg-primary font-bold text-white w-full p-1 rounded-lg mt-3'
                                onClick={(e) => {
                                    e.preventDefault();
                                    saveMarketplace(MarketPlaceForm);
                                }}
                            >
                                Add to Market Place
                            </button>
                            
                        </>
                    ) : (
                        <button
                            className='bg-secondary font-bold text-white w-full p-1 rounded-lg'
                            onClick={(e) => {
                                e.preventDefault();
                                editMarketplace(editFormData);
                            }}
                        >
                            Update Marketplace
                        </button>
                    )}
                    <>
                    <button
                        className='bg-primary font-bold text-white w-full p-1 rounded-lg mt-3'
                        onClick={(e) => {
                            e.preventDefault();
                            handleClosePopup();
                        }}
                    >
                        Back to Previous Screen
                    </button>
                            
                </>
                </form>
                

                {!isEditing && (
                    <>
                        

                        <div className=' ml-5  w-2/5'>

                            {open && <div>{responceMsg.msg}</div>}

                            {value !== null && error == null && (
                                <>
                                    {value?.name}

                                    <button
                                        onClick={deleteFile}
                                        className='btn btn_theme2 btn-sm ml-3 mb-3'>delete</button>
                                    <button
                                        className='btn btn_theme btn-sm ml-3 mb-3'
                                    onClick={bulkUploadDocument}
                                    >
                                        Save Document
                                    </button>
                                </>
                            )}
                        </div>
                    </>

                )}
            </div>

            <AppModal
                title={modalData.title}
                bodyText={modalData.bodyText}
                setIsOpen={setIsOpen}
                modalIsOpen={modalIsOpen}
                modelAction="MarketPlaceForm"
                apiResStatus={modalData.apiResStatus}
            />
        </div>
    )
}

export default MarketPlaceForm;
