import React, { useEffect, useContext, useState } from "react";
import "./dashboard.css";
import { useIdleTimer } from "react-idle-timer";
import { appName } from "../../constants/constants";
import AuthContext from "../../context/AuthContext";
import AppContext from "../../context/AppContext";
import FormContext from "../../context/FormContext";
import AuthContextlegacy from "../../contextlegacy/AuthContextlegacy";
import AppContextlegacy from "../../contextlegacy/AppContextlegacy";
import FormContextlegacy from "../../contextlegacy/FormContextlegacy";
import Navbar from "../navbar/Navbar";
import BellComponent from "../notifications/BellComponent";
import HomePage from "../homepage/Homepage";
import ContractForm from "../contracts/ContractForm";
import NotFoundComponent from "../not-found/NotFoundComponent";
import Notifications from "../notifications/Notifications";
import HelpModule from "../help-module/HelpModule";
import Account from "../settings/Account";
import Recipient from "../contracts/Recipient";
import Marketplace from "../marketplace/marketplace";
import Workflow from "../contracts/Workflow";
import ContactBook from "../contact-book/ContactBook";
import ContactBookForm from "../contact-book/ContactBookForm";
import GroupForm from "../contact-book/GroupForm";
import StampsAndSeals from "../contracts/StampsAndSeals";
import AutoMagic from "../contracts/AutoMagic";
import Presets from "../contracts/Presets";
import ManualMode from "../contracts/ManualMode";
import AttachStampPaper from "../contracts/AttachStampPaper";
import EmailNotifications from "../contracts/EmailNotifications";
import MyDocuments from "../my-document/MyDocuments";
import AffixMySignature from "../my-document/AffixMySignature";
import CreateDocument from "../contracts/CreateDocument";
import CreateTemplate from "../contracts/CreateTemplate";
import Search from "../search/Search";
import Invoices from "../settings/Invoices";
import PurchaseSignatures from "../settings/PurchaseSignatures";
import Wallet from "../settings/Wallet";
import GenerateDocument from "../contracts/GenerateDocument";
//import TaskList from '../task_manager/TaskList';

import PlanList from "../super-admin-plan/PlanList";
import AdminUser from "../admin-user/AdminUser";
import Bots from "../bots/bots";
import UserRighSection from "../header/UserRighSection";
import PlanForm from "../super-admin-plan/PlanForm";
import AdminUserForm from "../admin-user/AdminUserForm";
import AdminList from "../super-admin-admin/AdminList";
import AdminDetail from "../super-admin-admin/AdminDetail";
import AadharQuotaList from "../aadhar-quota/AadharQuotaList";
import AdminMarketPlace from "../marketplace/AdminMarketPlace";
import Adminmarkettable from "../marketplace/Adminmarkettable";
import MarketPlaceForm from "../marketplace/MarketPlaceForm";
import AddContactPopup from "../my-document/AddContactPopup";

import AadharPurchaseList from "../aadhar-quota/AadharPurchaseList";
import LegalDocumentList from "../legal-document/LegalDocumentList";
import ClauseLibaryList from "../legal-document/ClauseLibaryList";
import LegalDocumentForm from "../legal-document/LegalDocumentForm";
import ClauseLibaryForm from "../legal-document/ClauseLibaryForm";

import CreateTemplateLibrary from "../contracts/CreateTemplateLibrary";
//import Clauseai from '../contracts/clauseai'
import Qflow from "../contracts/Qflow";
import SelectedDocRecipients from "../contracts/SelectedDocRecipients";
import QuoqoDiligence from "../diligence/QuoqoDiligence";
import QuoqoIP from "../diligence/QuoqoIP";
import QuoqoSectoralReview from "../diligence/QuoqoSectoralReview";
import ReviewDocument from "../review-document/ReviewDocument";
import CreatePlaybook from "../Playrite/CreatePlaybook";
import DeviationAnalysis from "../Playrite/DeviationAnalysis";
import ViewPlaybookList from "../Playrite/ViewPlaybookList";

import ReviewSection from "../review-document/ReviewSection";
import SectionForm from "../review-document/SectionForm";
import ReviewSectionTask from "../review-document/ReviewSectionTask";
import SectionTaskForm from "../review-document/SectionTaskForm";
import MainUserList from "../super-admin-admin/MainUserList";
import AdminUserList from "../super-admin-admin/AdminUserList";
import DemoUserList from "../super-admin-admin/DemoUserList";
import DemoUserForm from "../super-admin-admin/DemoUserForm";
import Logs from "../logs/Logs";
import Qbot from "../qbot/qbot";
import PurchaseList from "../purchaseList/PurchaseList";
import QuoqoTransaction from "../diligence/QuoqoTransaction";
import EditDocument365 from "../contracts/EditDocument365";
import Office365SettingList from "../office-365-setting/Office365SettingList";
import Office365SettingForm from "../office-365-setting/Office365SettingForm";
import { Link, useNavigate } from "react-router-dom";
// legacy starts here
import BellComponentlegacy from "../notificationslegacy/BellComponentlegacy";
import Companieslegacy from "../all-moduleslegacy/Companieslegacy";
import Adminslegacy from "../all-moduleslegacy/Adminslegacy";
import Userslegacy from "../all-moduleslegacy/Userslegacy";
import CompanyFormlegacy from "../formslegacy/CompanyFormlegacy";
import AdminFormlegacy from "../formslegacy/AdminFormlegacy";
import UserFormlegacy from "../formslegacy/UserFormlegacy";
import SearchContractComponentlegacy from "../contractslegacy/SearchContractlegacy";
import RepoContainerlegacy from "../contractslegacy/RepoContainerlegacy";
import BrowseDocuments from "../contractslegacy/BrowseDocuments";
import SharedRepolegacy from "../contractslegacy/SharedRepolegacy";
import ContractFormlegacy from "../contractslegacy/ContractFormlegacy";
// import NotFoundComponent from '../not-found/NotFoundComponent';
import Notificationslegacy from "../notificationslegacy/Notificationslegacy";
import Reviewerslegacy from "../all-moduleslegacy/Reviewerslegacy";
import ReviewerFormlegacy from "../formslegacy/ReviewerFormlegacy";
import SSOSettinglegacy from "../all-moduleslegacy/SSOSettinglegacy";
import SSOSettingFormlegacy from "../formslegacy/SSOSettingFormlegacy";
import Licencinglegacy from "../all-moduleslegacy/Licencinglegacy";
import LicencingFormlegacy from "../formslegacy/LicencingFormlegacy";
import HomePagelegacy from "../homepagelegacy/Homepagelegacy";

const timeout = 1000 * 60 * 30;
const reviewerTimeout = 1000 * 60 * 15;

const Dashboard = ({ title }) => {
  const { activeLink, setActiveLink, adminId, activePrevLink } =
    useContext(AppContext);

  const { user, logoutUser } = useContext(AuthContext);
  const { setShouldLogout } = useContext(FormContext);
  const navigate = useNavigate();
  const [showBellComponent, setShowBellComponent] = useState(false);
  const [openSideBar, setOpenSideBar] = useState(false);
  const idleTimer = useIdleTimer({
    onIdle: () => logoutUser(),
    timeout: user.user_type === "reviewer" ? reviewerTimeout : timeout,
    events: [
      "mousemove",
      "keydown",
      "wheel",
      "DOMMouseScroll",
      "mousewheel",
      "mousedown",
      "touchstart",
      "touchmove",
      "MSPointerDown",
      "MSPointerMove",
      "visibilitychange",
    ],
    startOnMount: true,
    immediateEvents: [],
  });

  const handleScroll = (e) => {
    let element = e.target;
    if (element.scrollTop > 20) {
      document.querySelector(".bell-notifications-container").style.display =
        "none";
    } else {
      document.querySelector(".bell-notifications-container").style.display =
        "block";
    }
  };

  useEffect(() => {
    document.title = `${appName} | ${title}`;
    setShouldLogout(false);
    if (user.user_type === "admin" || user.user_type === "user") {
      setShowBellComponent(true);
    }
  }, []);
  return (
    <>
      <div
        className={`h-screen flex side_bar_set  ${
          openSideBar ? `open_sidebar` : ``
        }`}
      >
        {" "}
        {/* min_small_view */}
        <div
          onClick={(e) => {
            e.stopPropagation();
            setOpenSideBar(!openSideBar);
          }}
          className={`men_side ${openSideBar ? `open_mm` : ``}`}
        >
          <span></span>
          <span></span>
          <span></span>
        </div>
        <div className="navbar-container">
          <Navbar activeLink={activeLink} setActiveLink={setActiveLink} />
          {/* Navbar */}
        </div>
        <div
          className="dashboard-data-container p-10 pl-16 font-sans"
          onScroll={handleScroll}
        >
          {activePrevLink === "Home" && <HomePage />}
          {activePrevLink === "Help" && <HelpModule />}
          {activePrevLink === "Account" && <Account />}
          {activePrevLink === "Invoices" && <Invoices />}
          {activePrevLink === "PurchaseSignatures" && <PurchaseSignatures />}
          {activePrevLink === "Wallet" && <Wallet />}
          {activePrevLink === "Qbot" && <Qbot />}
          {activePrevLink === "Marketplace" && <Marketplace />}
          {activePrevLink === "AdminMarketPlace" && <AdminMarketPlace />}
          {activePrevLink === "MarketPlaceForm" && <MarketPlaceForm />}
          {activePrevLink === "AddContactPopup" && <AddContactPopup />}

          {activePrevLink === "CreateDocument" && <CreateDocument />}
          {activePrevLink === "GenerateDocument" && <GenerateDocument />}
          {activePrevLink === "CreateTemplate" && <CreateTemplate />}

          {activePrevLink === "CreateTemplateLibrary" && (
            <CreateTemplateLibrary />
          )}
          {activePrevLink === "CreatePlaybook" && <CreatePlaybook />}
          {activePrevLink === "DeviationAnalysis" && <DeviationAnalysis />}
          {activePrevLink === "ViewPlaybookList" && <ViewPlaybookList />}
          {activePrevLink === "Qflow" && <Qflow />}
          {activePrevLink === "EditDocument365" && <EditDocument365 />}

          {activePrevLink === "Bots" && <Bots />}
          {activePrevLink === "ContractForm" && <ContractForm />}
          {activePrevLink === "ContractO365Form" && <ContractForm />}
          {activePrevLink === "Recipients" && <Recipient />}
          {activePrevLink === "SelectedDocRecipients" && (
            <SelectedDocRecipients />
          )}
          {activePrevLink === "Workflows" && <Workflow />}
          {activePrevLink === "StampsAndSeals" && <StampsAndSeals />}
          {activePrevLink === "AutoMagic" && <AutoMagic />}
          {activePrevLink === "Presets" && <Presets />}
          {activePrevLink === "ManualMode" && <ManualMode />}
          {activePrevLink === "AttachStampPaper" && <AttachStampPaper />}
          {activePrevLink === "EmailNotifications" && <EmailNotifications />}

          {activePrevLink === "MyDocuments" && <MyDocuments />}
          {/* {activePrevLink === "DocumentStatus" && <MyDocuments />} */}
          {activePrevLink === "AdminUserDocument" && <MyDocuments />}
          {activePrevLink === "AffixMySignature" && <AffixMySignature />}

          {activePrevLink === "Notifications" && <Notifications />}
          {activePrevLink === "purchaseList" && <PurchaseList />}
          {activePrevLink === "ContactBook" && <ContactBook />}
          {activePrevLink === "Search" && <Search />}

          {activePrevLink === "ContactBookForm" && <ContactBookForm />}
          {activePrevLink === "GroupForm" && <GroupForm />}
          {activePrevLink === "NotFound" && <NotFoundComponent />}
          {activePrevLink === "SuperAdminPlanList" && <PlanList />}
          {activePrevLink === "PlanForm" && <PlanForm from="AddNewPlan" />}
          {activePrevLink === "PlanCorporateForm" && (
            <PlanForm from="AddCorporatePlan" />
          )}

          {/* super admin */}
          {activePrevLink === "AdminUser" && <AdminUser />}
          {activePrevLink === "AdminUserForm" && <AdminUserForm />}
          {activePrevLink === "AdminUserFormBySA" && <AdminUserForm />}

          {activePrevLink === "AdminList" && <AdminList />}
          {activePrevLink === "AdminDetail" && (
            <AdminDetail adminId={adminId} />
          )}
          {activePrevLink === "AadharQuotaList" && <AadharQuotaList />}
          {activePrevLink === "AadharPurchaseList" && <AadharPurchaseList />}
          {activePrevLink === "LegalDocumentList" && <LegalDocumentList />}
          {activePrevLink === "ClauseLibaryList" && <ClauseLibaryList />}
          {activePrevLink === "LegalDocumentForm" && <LegalDocumentForm />}
          {activePrevLink === "ClauseLibaryForm" && <ClauseLibaryForm />}
          {activePrevLink === "Office365SettingList" && (
            <Office365SettingList />
          )}
          {activePrevLink === "Office365SettingForm" && (
            <Office365SettingForm />
          )}

          {activePrevLink === "MainUserList" && <MainUserList />}
          {activePrevLink === "AdminUserList" && <AdminUserList />}
          {activePrevLink === "DemoUserList" && <DemoUserList />}
          {activePrevLink === "DemoUserForm" && <DemoUserForm />}

          {activePrevLink === "QuoqoDiligence" && <QuoqoDiligence />}
          {activePrevLink === "QuoqoIP" && <QuoqoIP />}
          {activePrevLink === "QuoqoSectoralReview" && <QuoqoSectoralReview />}

          {activePrevLink === "QuoqoTransaction" && <QuoqoTransaction />}
          {activePrevLink === "ReviewDocument" && <ReviewDocument />}
          {activePrevLink === "ReviewSection" && <ReviewSection />}
          {activePrevLink === "SectionForm" && <SectionForm />}
          {activePrevLink === "ReviewSectionTask" && <ReviewSectionTask />}
          {activePrevLink === "SectionTaskForm" && <SectionTaskForm />}
          {activePrevLink === "Logs" && <Logs />}
          {/* legacy starts here */}
          {activeLink === "Userslegacy" && <Userslegacy />}
          {activeLink === "SSOSettinglegacy" && <SSOSettinglegacy />}
          {(activeLink === "SSOSettingFormlegacy" ||
            activeLink === "SSOSettingFormEdit") && <SSOSettingFormlegacy />}
          {activeLink === "Licencinglegacy" && <Licencinglegacy />}
          {(activeLink === "LicencingFormlegacy" ||
            activeLink === "LicencingFormEdit") && <LicencingFormlegacy />}
          {activeLink === "Adminslegacy" && <Adminslegacy />}
          {activeLink === "Companieslegacy" && <Companieslegacy />}
          {(activeLink === "CompanyFormlegacy" ||
            activeLink === "CompanyFormEdit") && <CompanyFormlegacy />}
          {(activeLink === "AdminFormlegacy" ||
            activeLink === "AdminFormEdit") && <AdminFormlegacy />}
          {(activeLink === "UserFormlegacy" ||
            activeLink === "UserFormEdit") && <UserFormlegacy />}
          {(activeLink === "ReviewerFormlegacy" ||
            activeLink === "ReviewerFormEdit") && <ReviewerFormlegacy />}
          {(activeLink === "ContractFormlegacy" ||
            activePrevLink === "ContractFormEdit") && <ContractFormlegacy />}
          {activeLink === "Notificationslegacy" && <Notificationslegacy />}
          {activeLink === "Reviewerslegacy" && <Reviewerslegacy />}
          {activePrevLink === "homepagelegacy" && <HomePagelegacy />}
          {activeLink === "Searchlegacy" && <SearchContractComponentlegacy />}
          {activeLink === "AddContractlegacy" && <RepoContainerlegacy />}
          {activeLink === "BrowseDocuments" && <BrowseDocuments />}
          {activeLink === "SharedRepolegacy" && <SharedRepolegacy />}
          {activePrevLink === "Searchnormal" && <MyDocuments />}
        </div>
      </div>
      {showBellComponent && <BellComponent />}

      <UserRighSection />
    </>
  );
};

export default Dashboard;
