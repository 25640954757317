import React, { useContext, useState } from 'react';
import Modal from 'react-modal';
import './modal.css';

import { toast } from 'react-toastify';
import useAxios from '../../hooks/useAxios';
import { resolve } from '../../api/resolve';
import { useTranslation } from 'react-i18next';

const customStyles = {
    content: {
        top: '30%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        maxWidth: '400px',
        maxHeight: 'auto',
        borderRadius: '11px',
        minWidth: '300px',
    },
    overlay: {
        backgroundColor: 'rgba(64, 8, 53, 0.63)',
    },
};

Modal.setAppElement('#root');
const NotifyMeModel = ({
    isOpen,
    setIsOpen,
    notifyMeData,
}) => {
    const api = useAxios();
    const { t } = useTranslation();
    const [quantity, setQuantity] = useState('');
    const [quantityError, setQuantityError] = useState('');


    const saveAadharNotifyMe = async () => {
        setQuantityError('');
        if (quantity === "") {
            setQuantityError(t('form.Please enter Quantity'));
        } else {
            const postData =
            {
                "id": 1,
                "key_name": "master_aadhar_alert_quota",
                "value": parseFloat(quantity)
            }
                ;
            const res = await resolve(api.post(`user/site/setting/update/`, postData).then((res) => res.data));
            if (res.data) {
                toast.success(t('form.Saved successfully'));
                setIsOpen(false);
                setQuantity('');
                setQuantityError('');
            }

            if (res.error) {
                toast.error(t('common.Something went wrong'));
            }
        }
    };

    return (
        <div className=''>
            <Modal
                isOpen={isOpen}
                onRequestClose={() => setIsOpen(true)}
                style={customStyles}
                onAfterClose={() => {
                    setQuantity('');
                    setQuantityError('');
                }}
            >

                <div className=' modal-container'>
                    <h1 className='text-center text-primary font-bold text-lg'>{t('form.Notify Me')}</h1>

                    <div className='mt-5'>
                        <div className='flex'>
                            <label className='flex flex-start mb-1'>{t('form.Quantity')}</label>
                        </div>
                        <input
                            type='number'
                            value={quantity?quantity:notifyMeData.value}
                            className='border-2 border-primary rounded-lg outline-none p-1 pl-2 w-full '
                            onChange={(e) => setQuantity(e.target.value)}
                        />
                        <p className='flex flex-start error_msg'>{quantityError}</p>
                    </div>



                    <div className='flex justify-center mt-5'>
                        <button
                            onClick={(e) => {
                                e.preventDefault();
                                saveAadharNotifyMe();
                            }}
                            className='bg-primary hover:bg-secondary font-semibold text-white p-1 pl-2 pr-2 rounded-lg mr-5'
                        >
                            {t('common.Save')}
                        </button>

                        <button
                            onClick={(e) => {
                                e.preventDefault();
                                setIsOpen(false);
                            }}
                            className='bg-[#606060] font-semibold text-white p-1 pl-2 pr-2 rounded-lg'
                        >
                            {t('common.Cancel')}
                        </button>

                    </div>



                </div>
            </Modal>
        </div>
    );
};

export default NotifyMeModel;
