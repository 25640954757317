import React, { useEffect, useContext, useState, useRef } from 'react';
import ReactTooltip from 'react-tooltip';
import LoadingScreen from '../loader/LoadingScreen';
import '../forms/companyForm.css';
import FormContext from '../../context/FormContext';
import AppModal from '../modals/Modal';
import 'react-tagsinput/react-tagsinput.css';
import HelpIcon from '../../assets/icons/Help-sm.svg';
import LegalDocumentFile from '../../assets/LegalDocument.csv'

import Papa from 'papaparse';
import useAxios from '../../hooks/useAxios';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { use } from 'i18next';

const Office365SettingForm = () => {
    const api = useAxios();
    const navigate = useNavigate();
    const { t } = useTranslation();

    const {
        office365SettingFormErrors,
        modalData,
        setIsOpen,
        modalIsOpen,
        isEditing,
        editFormData,
        setEditFormData,
        setIsEditing,
        saveOrEditOffice365Setting,
        loading,

    } = useContext(FormContext);

    const [Office365SettingForm, setOffice365SettingForm] = useState({
        clientId: '',
        tenantId: '',
        redirectUrl: '',
        secretKey: '',
    });
    
    if (loading) {
        return <LoadingScreen />;
    }

    return (
        <div className='text-primary text-base font-normal font-sans upload_doc_page'>
            <div className="main_head">
                <h1 className='text-4xl font-bold'>
                    {!isEditing ? 'Add' : 'Edit'} {t('constant.Office 365 Setting')}
                </h1>
            </div>

            <div className=' flex w-full items-center h-full '>
                <form className='w-2/5'>
                    <div className='flex flex-col mb-3 contract-type'>
                        <div className='flex'>
                            <label className='mb-2'>{t('constant.Client Id')}</label>
                            <>
                                <img
                                    src={HelpIcon}
                                    width={12}
                                    className='ml-1 -mt-2 cursor-pointer'
                                    data-tip={t('constant.Insert client Id')}
                                />
                                <ReactTooltip backgroundColor='#400835' textColor='#ffffff' />
                            </>
                        </div>
                        <input
                            type='text'
                            placeholder={t('constant.Insert client Id')}
                            className='border-2 border-primary rounded-lg outline-none p-1 pl-2'
                            value={!isEditing ? Office365SettingForm.clientId : editFormData.clientId}
                            onChange={(e) => {
                                !isEditing
                                    ? setOffice365SettingForm({ ...Office365SettingForm, clientId: e.target.value })
                                    : setEditFormData({
                                        ...editFormData,
                                        clientId: e.target.value,
                                    });
                            }}
                        />
                        <p className='text-sm text-red-500 font-normal'>
                            {office365SettingFormErrors.clientId}
                        </p>
                    </div>

                    <div className='flex flex-col mb-3'>
                        <div className='flex'>
                            <label className='mb-2'>{t('constant.Tenant Id')}</label>
                            <>
                                <img
                                    src={HelpIcon}
                                    width={12}
                                    className='ml-1 -mt-2 cursor-pointer'
                                    data-tip={t('constant.Insert tenant id')}
                                />
                                <ReactTooltip backgroundColor='#400835' textColor='#ffffff' />
                            </>
                        </div>
                        <input
                            placeholder={t('constant.Insert tenant id')}
                            className='border-2 border-primary rounded-lg outline-none p-1 pl-2'
                            value={!isEditing ? Office365SettingForm.tenantId : editFormData.tenantId}
                            onChange={(e) => {
                                !isEditing
                                    ? setOffice365SettingForm({ ...Office365SettingForm, tenantId: e.target.value })
                                    : setEditFormData({
                                        ...editFormData,
                                        tenantId: e.target.value,
                                    });
                            }}
                        />
                        <p className='text-sm text-red-500 font-normal'>
                            {office365SettingFormErrors.tenantId}
                        </p>
                    </div>

                    <div className='flex flex-col mb-3'>
                        <div className='flex'>
                            <label className='mb-2'>{t('constant.Redirect url')}</label>
                            <>
                                <img
                                    src={HelpIcon}
                                    width={12}
                                    className='ml-1 -mt-2 cursor-pointer'
                                    data-tip={t('constant.Insert redirect url')}
                                />
                                <ReactTooltip backgroundColor='#400835' textColor='#ffffff' />
                            </>
                        </div>
                        <input
                            placeholder={t('constant.Insert redirect url')}
                            className='border-2 border-primary rounded-lg outline-none p-1 pl-2'
                            value={!isEditing ? Office365SettingForm.redirectUrl : editFormData.redirectUrl}
                            onChange={(e) => {
                                !isEditing
                                    ? setOffice365SettingForm({ ...Office365SettingForm, redirectUrl: e.target.value })
                                    : setEditFormData({
                                        ...editFormData,
                                        redirectUrl: e.target.value,
                                    });
                            }}
                        />
                        <p className='text-sm text-red-500 font-normal'>
                            {office365SettingFormErrors.redirectUrl}
                        </p>
                    </div>
                    <div className='flex flex-col mb-3'>
                        <div className='flex'>
                            <label className='mb-2'>{t('constant.Secret Key')}</label>
                            <>
                                <img
                                    src={HelpIcon}
                                    width={12}
                                    className='ml-1 -mt-2 cursor-pointer'
                                    data-tip={t('constant.Insert secret key')}
                                />
                                <ReactTooltip backgroundColor='#400835' textColor='#ffffff' />
                            </>
                        </div>
                        <input
                            placeholder={t('constant.Insert secret key')}
                            className='border-2 border-primary rounded-lg outline-none p-1 pl-2'
                            value={!isEditing ? Office365SettingForm.secretKey : editFormData.secretKey}
                            onChange={(e) => {
                                !isEditing
                                    ? setOffice365SettingForm({ ...Office365SettingForm, secretKey: e.target.value })
                                    : setEditFormData({
                                        ...editFormData,
                                        secretKey: e.target.value,
                                    });
                            }}
                        />
                        <p className='text-sm text-red-500 font-normal'>
                            {office365SettingFormErrors.secretKey}
                        </p>
                    </div>

                    {!isEditing ? (
                        <>
                            <button
                                className='bg-primary font-bold text-white w-full p-1 rounded-lg mt-3'
                                onClick={(e) => {
                                    e.preventDefault();
                                    saveOrEditOffice365Setting(Office365SettingForm);
                                }}
                            >
                                {t('common.Save')}
                            </button>
                            <p className='text-sm text-red-500 font-normal'>
                                {/* {office365SettingFormErrors.document} */}
                            </p>
                        </>
                    ) : (
                        <button
                            className='bg-secondary font-bold text-white w-full p-1 rounded-lg'
                            onClick={(e) => {
                                e.preventDefault();
                                saveOrEditOffice365Setting(editFormData);
                            }}
                        >
                            {t('common.Update')}
                        </button>
                    )}
                </form>              
            </div>

            <AppModal
                title={modalData.title}
                bodyText={modalData.bodyText}
                setIsOpen={setIsOpen}
                modalIsOpen={modalIsOpen}
                modelAction="Office365SettingForm"
                apiResStatus={modalData.apiResStatus}
            />
        </div>
    )
}

export default Office365SettingForm;
