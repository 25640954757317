import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { GoogleOAuthProvider } from '@react-oauth/google';
import './i18n';
ReactDOM.render(
  <GoogleOAuthProvider clientId="305828262078-nm0dc9af6k1jd99qp99nv52uub8tjnnu.apps.googleusercontent.com">
  {/* <GoogleOAuthProvider clientId="1059598175851-9hj6cfqb0g7sc6n7s0d2ukcta4f328l8.apps.googleusercontent.com"> */}
    <React.StrictMode>
      <Suspense fallback="loading">
        <App />
      </Suspense>
    </React.StrictMode>
  </GoogleOAuthProvider>,
  document.getElementById('root')
);

