// import React, { useState, useContext } from 'react';
// import { useTable } from 'react-table';
// import DownArrow from '../../assets/icons/down-arrow.svg';
// import FormContext from '../../context/FormContext';
// import AppContext from '../../context/AppContext';
// import { useTranslation } from 'react-i18next';

// export const NoDataComponent = ({ colLength }) => {
//   const [rows] = useState(Array(4).fill(''));
//   const [cols] = useState(Array(colLength).fill(''));
//   return (
//     <>
//       {rows.map(() => (
//         <tr>
//           {cols.map(() => (
//             <td className='px-5 py-2 font-medium text-sm text-center'>--</td>
//           ))}
//         </tr>
//       ))}
//     </>
//   );
// };

// const NotificationsTablelegacy = ({
//   contractsData,
//   contractTableColumnslegacy,
//   prevPage,
//   nextPage,
//   currentPage,
//   setCurrentPage,
//   setSelectedNotificationlegacy,
//   sortingOrder,
//   setSortingOrder,
//   setSortColumn,
//   setIsOpen,
//   updateNotificationSeenlegacy,
//   updateNotificationClearedlegacy,
//   clearAllNotificationslegacy,
// }) => {
//   const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
//     useTable({ columns: contractTableColumnslegacy, data: contractsData });
//   const { t } = useTranslation();
//   return (
//     <>
//       <div className='mt-5 flex flex-col table-container'>
        
//             <div className='shadow overflow-hidden border-2 border-primary  sm:rounded-lg max-h-96 tab_resppp'>
//               <table {...getTableProps()} className='w-full '>
//                 <thead>
//                   {headerGroups.map((headerGroup) => (
//                     <tr {...headerGroup.getHeaderGroupProps()}>
//                       {headerGroup.headers.map((column) => (
//                         // Add the sorting props to control sorting. For this example
//                         // we can add them into the header props

//                         <th
//                           scope='col'
//                           className='text-left px-2 pt-2'
//                           {...column
//                             .getHeaderProps
//                             // column.getSortByToggleProps()
//                             ()}
//                         >
//                           <div className='flex items-center  justify-center'>
//                             <span>{t('constant.' + column.render('Header'))}</span>
//                             {column.id === 'date' && (
//                               <>
//                                 {sortingOrder === 'desc' ? (
//                                   <span
//                                     onClick={(e) => {
//                                       setSortingOrder('asc');
//                                       setSortColumn(column.id);
//                                     }}
//                                     style={{ cursor: 'pointer', marginTop: 2 }}
//                                     className='ml-1'
//                                   >
//                                     {/* &#11165; */}
//                                     <img src={DownArrow} />
//                                   </span>
//                                 ) : (
//                                   <span
//                                     onClick={(e) => {
//                                       setSortingOrder('desc');
//                                       setSortColumn(`-${column.id}`);
//                                     }}
//                                     style={{
//                                       cursor: 'pointer',
//                                       transform: 'rotate(180deg)',
//                                     }}
//                                     className='ml-1'
//                                   >
//                                     <img src={DownArrow} />
//                                   </span>
//                                 )}
//                               </>
//                             )}
//                           </div>
//                         </th>
//                       ))}
//                       <th scope='col' className='text-center px-6 pt-2'>
//                         {t('common.Action')}
//                       </th>
//                       {/* <th scope='col' className='text-left px-6 pt-2'>
//                         Notifications
//                       </th> */}
//                     </tr>
//                   ))}
//                 </thead>
//                 <tbody {...getTableBodyProps()}>
//                   {contractsData.length < 1 && (
//                     <NoDataComponent colLength={contractTableColumnslegacy.length} />
//                   )}
//                   {contractsData.length > 0 &&
//                     rows.map((row, i) => {
//                       // new
//                       prepareRow(row);
//                       return (
//                         <tr
//                           {...row.getRowProps()}
//                           className={`text-center `}
//                           onClick={() => console.log(row.original)}
//                         >
//                           {row.cells.map((cell) => {
//                             return (
//                               <>
//                                 <td
//                                   {...cell.getCellProps()}
//                                   className={`px-2 py-2 font-medium text-sm contracts-col-data  ${cell.value === 'Not Seen' && 'text-red-500'
//                                     }`}
//                                 >
//                                   {cell.value === 'Seen' ? (
//                                     <span className='text-lg'>&#10003;</span>
//                                   ) : cell.value === 'Not Seen' ? (
//                                     <span className='text-lg'>&#10008;</span>
//                                   ) : (
//                                     cell.render('Cell')
//                                   )}
//                                 </td>
//                               </>
//                             );
//                           })}
//                           <td className='px-4 py-2 flex justify-center'>
//                             {row.original.contract_details &&
//                               row.original.type !==
//                               'notify_indexing_failed' && (
//                                 <button
//                                   className='bg-secondary text-white font-semibold p-1 pl-4 pr-4 rounded-lg'
//                                   onClick={() => {
//                                     updateNotificationSeenlegacy(row.original.id);
//                                     let contractObj =
//                                       row.original.contract_details;
//                                     contractObj[0].type = row.original.type;
//                                     setSelectedNotificationlegacy(contractObj);
//                                     setIsOpen(true);
//                                   }}
//                                 >
//                                   {t('common.View')}
//                                 </button>
//                               )}
//                             <button
//                               className='ml-3 bg-secondary text-white font-semibold p-1 pl-4 pr-4 rounded-lg'
//                               onClick={() =>
//                                 updateNotificationClearedlegacy(row.original.id)
//                               }
//                             >
//                               {t('common.Clear')}
//                             </button>
//                           </td>
//                         </tr>
//                       );
//                     })}
//                 </tbody>
//               </table>
//             </div>
//       </div>
//       {contractsData.length > 0 && (
//         <div className='mt-3 flex justify-between'>
//           <div>
//             <button
//               className='bg-secondary text-white font-semibold p-1 pl-4 pr-4 rounded-md'
//               onClick={() => clearAllNotificationslegacy()}
//             >
//               {t('modal.Clear All')}
//             </button>
//           </div>
//           <div className='flex text-primary  border-t-2 border-b-2 border-primary rounded-lg'>
//             <button
//               className={`pl-5 pr-3 border-r-2 border-l-2 border-primary font-semibold rounded-tl-lg rounded-bl-lg ${!prevPage && 'bg-inputBackground'
//                 }`}
//               disabled={!prevPage}
//               onClick={() => setCurrentPage(currentPage - 1)}
//             >
//               {t('common.Prev')}
//             </button>
//             <span className='px-2 pt-1 font-semibold'>{currentPage}</span>
//             <button
//               className={`pr-5 pl-3 border-l-2 border-r-2 border-primary font-semibold rounded-tr-lg rounded-br-lg ${!nextPage && 'bg-inputBackground'
//                 }`}
//               disabled={!nextPage}
//               onClick={() => setCurrentPage(currentPage + 1)}
//             >
//               {t('common.Next')}
//             </button>
//           </div>
//         </div>
//       )}
//     </>
//   );
// };

// export default NotificationsTablelegacy;


import React, { useState, useContext } from 'react';
import { useTable } from 'react-table';
import DownArrow from '../../assets/icons/down-arrow.svg';
import FormContext from '../../context/FormContext';
import AppContext from '../../context/AppContext';
import DeleteModal from '../modals/DeleteModal';
import { useTranslation } from 'react-i18next';
import DeleteIcon from "../../assets/icons/Delete.svg";
import EyeOnIconWhite from "../../assets/icons/eye_on_white.png";

export const NoDataComponent = ({ colLength }) => {
  const [rows] = useState(Array(4).fill(''));
  const [cols] = useState(Array(colLength).fill(''));
  return (
    <>
      {rows.map((value, index) => (
        <tr key={index}>
          {cols.map((value01, key01) => (
            <td key={key01} className='px-5 py-2 font-medium text-sm text-center'>--</td>
          ))}
        </tr>
      ))}
    </>
  );
};

const NotificationsTablelegacy = ({
  contractsData,
  contractTableColumns,
  prevPagelegacy,
  nextPagelegacy,
  currentPagelegacy,
  setCurrentPagelegacy,
  setSelectedNotification,
  setSelectedNotificationlegacy,
  sortingOrder,
  setSortingOrderlegacy,
  setSortColumnlegacy,
  setIsOpen,
  updateNotificationSeen,
  updateNotificationCleared,
  clearAllNotifications,
  updateNotificationSeenlegacy,
  updateNotificationClearedlegacy,
  clearAllNotificationslegacy,
  setnotiftype,
}) => {
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({ columns: contractTableColumns, data: contractsData });

  const [isDeleteSingleModalOpen, setIsDeleteSignleModalOpen] = useState(false);
  const [isDeleteAllModalOpen, setIsDeleteAllModalOpen] = useState(false);
  const [rowId, setRowId] = useState(0);
  const { t } = useTranslation();

  const handleSingleDelete = (id) => {
    setRowId(id);
    setIsDeleteSignleModalOpen(true);
  };

  const consoleprintincrease = (currentPagelegacy) => {
    setCurrentPagelegacy(currentPagelegacy - 1)
    console.log("current page",currentPagelegacy);
  };

  
  const confirmSingleDelete = () => {
    setIsDeleteSignleModalOpen(false);
    updateNotificationClearedlegacy(rowId);
  };
  return (
    <>
      <div className='mt-5 flex flex-col table-container'>
        <div>
          <div className='table_desin'>
            <div className='shadow overflow-hidden border-2 border-primary  sm:rounded-lg max-h-96'>
              <table {...getTableProps()} className='w-full '>
                <thead>
                  {headerGroups.map((headerGroup) => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                      {headerGroup.headers.map((column) => (
                        // Add the sorting props to control sorting. For this example
                        // we can add them into the header props

                        <th
                          scope='col'
                          className='text-left px-2 pt-2'
                          {...column
                            .getHeaderProps
                            // column.getSortByToggleProps()
                            ()}
                        >

                          <div className='flex items-center'>
                            <span>{t('constant.' + column.render('Header'))}</span>

                            {column.isSorted !== undefined &&
                              (column.isSorted ? (
                                <span
                                  onClick={(e) => {
                                    setSortingOrderlegacy('asc');
                                    setSortColumnlegacy(column.id);
                                    column.isSorted = false;
                                  }}
                                  style={{ cursor: 'pointer', marginTop: 2 }}
                                  className='ml-1'
                                >
                                  <img src={DownArrow} />
                                </span>
                              ) : (
                                <span
                                  onClick={(e) => {
                                    setSortingOrderlegacy('desc');
                                    setSortColumnlegacy(`${column.id}`);
                                    column.isSorted = true;
                                  }}
                                  style={{
                                    cursor: 'pointer',
                                    transform: 'rotate(180deg)',
                                  }}
                                  className='ml-1'
                                >
                                  <img src={DownArrow} />
                                </span>
                              ))
                            }
                          </div>
                        </th>
                      ))}
                      <th scope='col' className='text-center px-6 pt-2'>
                      {t('common.Action')}
                      </th>
                      {/* <th scope='col' className='text-left px-6 pt-2'>
                        Notifications
                      </th> */}
                    </tr>
                  ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                  {contractsData.length < 1 && (
                    <NoDataComponent colLength={contractTableColumns.length} />
                  )}
                  {contractsData.length > 0 &&
                    rows.map((row, i) => {
                      // new
                      prepareRow(row);
                      return (
                        <tr
                          {...row.getRowProps()}
                          className={`text-center `}
                          onClick={() => { }}
                        >
                          {row.cells.map((cell) => {
                            return (
                              <>
                                <td
                                  {...cell.getCellProps()}
                                  className={`px-2 py-2 font-medium text-sm contracts-col-data  ${cell.value === 'Not Seen' && 'text-red-500'
                                    }`}
                                >
                                  {cell.value === 'Seen' ? (
                                    <span className='text-lg'>&#10003;</span>
                                  ) : cell.value === 'Not Seen' ? (
                                    <span className='text-lg'>&#10008;</span>
                                  ) : (
                                    <span
                                    className='text_fixed'
                                    >{cell.render('Cell')}</span>
                                  )}
                                </td>
                              </>
                            );
                          })}
                          <td className='px-4 py-2 flex' style={{ textAlign: 'center' }}>
                          {row.original.contract_details &&
                              row.original.type !==
                              'notify_indexing_failed' && (
                                <button
                                  className='btn btn_theme btn-sm mr-1 wh_spab w-10'
                                  onClick={() => {
                                    updateNotificationSeenlegacy(row.original.id);  
                                    setnotiftype("legacy");                                  
                                    let contractObj =
                                      row.original.contract_details;
                                    console.log("contractObj",contractObj);
                                    contractObj[0].type = row.original.type;
                                    setSelectedNotification(contractObj);
                                    setIsOpen(true);
                                  }}
                                >
                                  {/* {t('common.View')} */}
                                  <img
                                    src={EyeOnIconWhite}
                                    className="ml-0.5"
                                    width={20}
                                    alt="View Document"
                                  />
                                
                                </button>
                              )}
                            <button
                              className='btn btn_theme btn-sm mr-1 wh_spab w-10'
                              onClick={() =>
                                handleSingleDelete(row.original.id)
                              }
                            >
                              {/* {t('allModule.Clear')} */}
                              <img
                                    src={DeleteIcon}
                                    className="ml-0.5"
                                    width={20}
                                    alt="Delete Document"
                                  />
                            </button>
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      {contractsData.length > 0 && (
        <div className='mt-3 flex justify-between'>
          <div>
            <button
              className='bg-secondary text-white font-semibold p-1 pl-4 pr-4 rounded-md'
              onClick={() => setIsDeleteAllModalOpen(true)}
            >
              {t('allModule.Clear All')}
            </button>
          </div>
          <div className='flex text-primary  border-t-2 border-b-2 border-primary rounded-lg'>
            <button
              className={`btn-sm border-r-2 border-l-2 border-primary font-semibold rounded-tl-lg rounded-bl-lg ${!prevPagelegacy && 'bg-inputBackground'
                }`}
              disabled={!prevPagelegacy}
              onClick={() => setCurrentPagelegacy(currentPagelegacy - 1)}
            >
              {t('common.Prev')}
            </button>
            <span className='btn-sm font-semibold'>{currentPagelegacy}</span>
            <button
              className={`btn-sm border-l-2 border-r-2 border-primary font-semibold rounded-tr-lg rounded-br-lg ${!nextPagelegacy && 'bg-inputBackground'
                }`}
              disabled={!nextPagelegacy}
              onClick={() => setCurrentPagelegacy(currentPagelegacy + 1)}
            >
              {t('common.Next')}
            </button>
          </div>
        </div>
      )}
      <DeleteModal
        title={t('allModule.Clear Notification')}
        bodyText={t('allModule.This action cannot be undone. Are you sure you want to continue?')}
        isDeleteModalOpen={isDeleteSingleModalOpen}
        setIsDeleteModalOpen={setIsDeleteSignleModalOpen}
        confirmSingleDelete={confirmSingleDelete}
      />
      <DeleteModal
        title={t('allModule.Clear All Notification')}
        bodyText={t('allModule.This action cannot be undone. Are you sure you want to continue?')}
        isDeleteModalOpen={isDeleteAllModalOpen}
        setIsDeleteModalOpen={setIsDeleteAllModalOpen}
        clearAllNotificationslegacy={clearAllNotificationslegacy}
      />
    </>
  );
};

export default NotificationsTablelegacy;
