import React, { useEffect, useState, useContext } from 'react';
import AppContext from '../../context/AppContext';
import AuthContext from '../../context/AuthContext';
import { resolve } from '../../api/resolve';
import useAxios from '../../hooks/useAxios';
import Search from '../../assets/icons/Search.svg';
import RepositoryIcon from '../../assets/icons/Repository.svg';
import LoadingScreen from '../loader/LoadingScreen';
import AddNewRepo from '../modallegacy/AddNewRepolegacy';
import Group from '../../assets/icons/group.png';
import Pencil from '../../assets/icons/pencil.png';
import DeleteModal from '../modallegacy/DeleteModallegacy';
import { useTranslation } from 'react-i18next';

const RepoItem = ({
  repo,
  setRepoName,
  setContractsHeading,
  setActiveLink,
  setRepoID,
  isDeleteModalOpen,
  setIsDeleteModalOpen,
  setIsModalOpen,
  setIsUpdating,
}) => {
  const [isHovered, setIsHovered] = useState(false);
  const { user } = useContext(AuthContext);
    return (
    <div
      className='mb-10 w-fit'
      
      onClick={() => {
        
          setContractsHeading('Contracts');
          setRepoName(repo.repo_name);
          setRepoID(repo.id);
          // setIsRepoList(true);
          // setActiveLink('Search');
          repo.repo_name.toLowerCase() != 'default' &&
          setActiveLink('ContractFormlegacy');
      }}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <div className='flex'>
        <img src={RepositoryIcon} className='cursor-pointer mr-1' />
        {isHovered &&
          user.user_type === 'admin' &&
          repo.repo_name.toLowerCase() != 'default' && (
            <div className='flex flex-col'>
              <svg
                fill='#400835'
                xmlns='http://www.w3.org/2000/svg'
                viewBox='0 0 16 16'
                width='20px'
                height='20px'
                className='cursor-pointer'
                onClick={(e) => {
                  e.stopPropagation();
                  setRepoID(repo.id);
                  setIsDeleteModalOpen(true);
                }}
              >
                <path d='M 6.496094 1 C 5.675781 1 5 1.675781 5 2.496094 L 5 3 L 2 3 L 2 4 L 3 4 L 3 12.5 C 3 13.328125 3.671875 14 4.5 14 L 10.5 14 C 11.328125 14 12 13.328125 12 12.5 L 12 4 L 13 4 L 13 3 L 10 3 L 10 2.496094 C 10 1.675781 9.324219 1 8.503906 1 Z M 6.496094 2 L 8.503906 2 C 8.785156 2 9 2.214844 9 2.496094 L 9 3 L 6 3 L 6 2.496094 C 6 2.214844 6.214844 2 6.496094 2 Z M 5 5 L 6 5 L 6 12 L 5 12 Z M 7 5 L 8 5 L 8 12 L 7 12 Z M 9 5 L 10 5 L 10 12 L 9 12 Z' />
              </svg>
              {/* <svg
                fill='#400835'
                xmlns='http://www.w3.org/2000/svg'
                viewBox='0 0 48 48'
                width='20px'
                height='20px'
                className='cursor-pointer'
                onClick={(e) => {
                  e.stopPropagation();
                  setIsUpdating(true);
                  setIsModalOpen(true);
                }}
              >
                <path d='M 36 5.0097656 C 34.205301 5.0097656 32.410791 5.6901377 31.050781 7.0507812 L 8.9160156 29.183594 C 8.4960384 29.603571 8.1884588 30.12585 8.0253906 30.699219 L 5.0585938 41.087891 A 1.50015 1.50015 0 0 0 6.9121094 42.941406 L 17.302734 39.974609 A 1.50015 1.50015 0 0 0 17.304688 39.972656 C 17.874212 39.808939 18.39521 39.50518 18.816406 39.083984 L 40.949219 16.949219 C 43.670344 14.228094 43.670344 9.7719064 40.949219 7.0507812 C 39.589209 5.6901377 37.794699 5.0097656 36 5.0097656 z M 36 7.9921875 C 37.020801 7.9921875 38.040182 8.3855186 38.826172 9.171875 A 1.50015 1.50015 0 0 0 38.828125 9.171875 C 40.403 10.74675 40.403 13.25325 38.828125 14.828125 L 36.888672 16.767578 L 31.232422 11.111328 L 33.171875 9.171875 C 33.957865 8.3855186 34.979199 7.9921875 36 7.9921875 z M 29.111328 13.232422 L 34.767578 18.888672 L 16.693359 36.962891 C 16.634729 37.021121 16.560472 37.065723 16.476562 37.089844 L 8.6835938 39.316406 L 10.910156 31.521484 A 1.50015 1.50015 0 0 0 10.910156 31.519531 C 10.933086 31.438901 10.975086 31.366709 11.037109 31.304688 L 29.111328 13.232422 z' />
              </svg> */}
              {/* <img
                src={Pencil}
                width={18}
                className='cursor-pointer mt-1'
                onClick={(e) => {
                  e.stopPropagation();
                  setIsUpdating(true);
                  setIsModalOpen(true);
                }}
              /> */}
            </div>
          )}
      </div>
      <p className='text-sm font-bold cursor-pointer'>{`${repo.repo_name[0].toUpperCase()}${repo.repo_name.slice(
        1
      )} `}</p>
    </div>
  );
};

const RepoContainerlegacy = () => {
  const api = useAxios();
  const { t } = useTranslation()
  const {
    setRepoName,
    setContractsHeading,
    setIsRepoList,
    setActiveLink,
    setRepoID,
    repoID,
  } = useContext(AppContext);
  const { user } = useContext(AuthContext);
  const [searchText, setSearchText] = useState('');
  const [errMsg, setErrMsg] = useState('');
  const [repos, setRepos] = useState([]);
  const [pageLoading, setPageLoading] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  // const [setRepoID, setIsDeleteModalOpen] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const getRepos = async () => {
    const passval = {'repo_id':""}
    const res = await resolve(
      
      api.get(`legacy_contract/get_all_repos/`,passval).then((res) => res.data)
    );
    if (res.data) {
      const filterData = [];
      if (res.data && res.data.length > 0) {
        res.data.forEach((result) => {
          const contractObj = {
            repo_name: result.repo_name,
            id: result.id,
          };
          filterData.push(contractObj);
        });
      } else {
        setErrMsg(t('allModule.No repositories added.'));
      }
      setRepos([...filterData]);
    }
    setPageLoading(false);
  };
  const searchRepoByName = async (searchText) => {
    if (searchText.trim().length > 0) {
      const res = await resolve(
        api
          .get(`/legacy_contract/get_all_repos/?search=${searchText}`)
          .then((res) => res.data)
      );
      if (res.data) {
        const filterData = [];
        if (res.data && res.data.length > 0) {
          res.data.forEach((result) => {
            const contractObj = {
              repo_name: result.repo_name,
              id: result.id,
            };
            filterData.push(contractObj);
          });
        } else {
          setErrMsg(t('allModule.No Repository found'));
        }
        setRepos([...filterData]);
      }
    } else {
      getRepos();
    }
  };
  const deleteRepository = async () => {
    setPageLoading(true);
    const res = await resolve(
      api.delete(`legacy_contract/delete_repo/${repoID}`).then((res) => res.data)
    );
    if (res.data) {
      if (res.data.success) {
        getRepos();
      }
    }
    setIsDeleteModalOpen(false);
    setPageLoading(false);
  };
  useEffect(() => {
    getRepos();
  }, []);

  if (pageLoading) {
    return <LoadingScreen />;
  }
  return (
    <div className='text-primary conta_nesss'>
      <h1 className='text-4xl font-bold'>{t('allModule.Add New Contract')}</h1>
      <form>
        <div className='flex mt-3'>
          <div className='border-2 border-primary rounded-md rounded-tl-lg rounded-bl-lg bg-inputBackground pl-1 mr-2 w-3/6'>
            <input
              placeholder={t('allModule.Enter Repository Name')}
              className='px-1 py-1 focus:outline-none bg-transparent w-full'
              onChange={(e) => setSearchText(e.target.value)}
            />
          </div>
          <button
            type='submit'
            className='flex bg-primary text-white  px-4 rounded-lg items-center justify-between'
            onClick={(e) => {
              e.preventDefault();
              searchRepoByName(searchText);
            }}
          >
            <img src={Search} style={{ width: '1rem' }} />
            <span className='font-semibold ml-1'>{t('common.Search')}</span>
          </button>
        </div>
      </form>

      <div className='mt-5 rounded-lg'>
        {/* add new repo placeholder */}
        <div className='flex w-full'>
          {repos.length < 1 && (
            <p className='text-red-500 text-sm font-medium'>{`${errMsg} ${
              user.user_type == 'user'
                ? t('allModule.Please contact admin for the access.')
                : ''
            }`}</p>
          )}
          {repos.length > 0 && (
            <div className='flex flex-col w-2/3'>
              <p className='font-semibold my-1'>
                {t('allModule.Select a repository to add a contract.')}
              </p>

              <div className='grid grid-cols-4  w-full justify-between new_conttt'>
                {repos.map((repo) => (
                  <RepoItem
                    repo={repo}                    
                    key={repo.repo_name}
                    setActiveLink={setActiveLink}
                    setRepoID={setRepoID}
                    setContractsHeading={setContractsHeading}
                    setRepoName={setRepoName}
                    setIsDeleteModalOpen={setIsDeleteModalOpen}
                    setIsModalOpen={setIsModalOpen}
                    setIsUpdating={setIsUpdating}
                  />
                ))}
              </div>
            </div>
          )}
          {user.user_type === 'admin' && (
            <div className='ml-auto my-1'>
              <button
                className='bg-primary text-white p-1 pl-5 pr-5 ml-2 rounded-lg'
                onClick={() => setIsModalOpen(true)}
              >
                <span className='flex'>
                  <img src={Group} width={20} className='rounded-md' />
                  <span className='font-semibold ml-2'>{t('allModule.Add Repository')}</span>
                </span>
              </button>
            </div>
          )}
        </div>
        <AddNewRepo
          setIsOpen={setIsModalOpen}
          modalIsOpen={isModalOpen}
          getRepos={getRepos}
          isUpdating={isUpdating}
          setIsUpdating={setIsUpdating}
        />
        <DeleteModal
          title={t('allModule.Delete Repository')}
          bodyText={t('common.This action cannot be undone. Are you sure you want to continue?')}
          isDeleteModalOpen={isDeleteModalOpen}
          setIsDeleteModalOpen={setIsDeleteModalOpen}
          deleteRepository={deleteRepository}
        />
      </div>
    </div>
  );
};

export default RepoContainerlegacy;
