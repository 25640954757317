import React, { useEffect, useContext, useState } from 'react';
import TagsInput from 'react-tagsinput';
import Creatable from 'react-select/creatable';
import ReactTooltip from 'react-tooltip';
import LoadingScreen from '../loader/LoadingScreen';
import {
  CircularProgressbarWithChildren,
  buildStyles,
} from 'react-circular-progressbar';
import dayjs from 'dayjs';
import '../forms/companyForm.css';
import { resolve } from '../../api/resolve';
import { customStyles } from '../../constants/styles';
import FormContext from '../../context/FormContext';
import AppContext from '../../context/AppContext';
import AppModal from '../modals/Modal';
import DatePickerComponent from '../../shared/DatePickerComponent';
import AddContractModal from '../modals/AddContractModal';
import 'react-tagsinput/react-tagsinput.css';
import useAxios from '../../hooks/useAxios';
import HelpIcon from '../../assets/icons/Help-sm.svg';
import CloseIcon from '../../assets/icons/Close.svg';
import CustomPhoneInput from '../forms/PhoneInput';
import './contact_book.css';
import { useTranslation } from 'react-i18next';

const GroupForm = () => {
  const {
    setCurrentForm,
    groupFormErrors,
    modalData,
    setIsOpen,
    modalIsOpen,
    isEditing,
    editFormData,
    setEditFormData,
    setIsEditing,
    setGroupFormErrors,
    saveOrEditGroup,
    loading,
    progressValue,
  } = useContext(FormContext);
  const api = useAxios();
  const { t } = useTranslation();
  const {
    setActiveLink,
    repoName,
    setRepoName,
    isRepoList,
    setRepoID,
    repoID,
  } = useContext(AppContext);
  const [startDate, setStartDate] = useState(
    isEditing &&
    editFormData?.renewal_date &&
    editFormData.renewal_date !== 'Not Added' &&
    new Date(editFormData?.renewal_date)
  );
  const [groupObj, setGroupObj] = useState({
    group_name: '',
    contactList:
      isEditing && editFormData.contactList && editFormData.contactList.length > 0
        ? [...editFormData.contactList]
        : [],
  });

  const [contactList, setContactList] = useState([]);
  const [defaultValues, setDefaultValues] = useState([]);
  const getContactList = async () => {
    const contactListData = await resolve(
      api.get('user/contact/list/').then((res) => res.data)
    );

    if (contactListData['data']) {
      const filteredArr = [];
      const listData = contactListData['data'].results;
      if (listData.length > 0) {
        listData.forEach((contactInfo) => {
          filteredArr.push({ value: contactInfo.id + "", label: contactInfo.name, id: contactInfo.id });
        });
        setContactList(filteredArr);
      }

    }
  };
  useEffect(() => {
    getContactList();
    setGroupFormErrors({
      ...groupFormErrors,
      group_name: '',
      contactList: '',
    });
    setCurrentForm('GroupForm');

    if (editFormData && editFormData.contactList && editFormData.contactList.length > 0) {
      let filteredArr = editFormData.contactList;
      if (filteredArr.length > 0) {
        filteredArr.forEach((contactInfo) => {
          if (contactInfo) {
            defaultValues.push({ value: (contactInfo.id) + "", label: contactInfo.name });
          }
        });
      }
    }
    return () => setCurrentForm('');
  }, []);
  useEffect(() => {
    return () => {
      if (isEditing) {
        setEditFormData('');
        setIsEditing(false);
      }
    };
  }, [isEditing]);

  if (loading && !isEditing) {
    return (
      <div className='grid place-items-center h-full'>
        <div
          style={{
            width: 150,
            height: 150,
          }}
        >
          <CircularProgressbarWithChildren
            value={progressValue - 1}
            styles={buildStyles({
              strokeLinecap: 'butt',
              pathColor: `#400835`,
              textColor: '#f88',
              trailColor: '#d6d6d6',
              backgroundColor: '#3e98c7',
            })}
          >
            <div className='text-center text-primary'>
              <span className='font-bold text-4xl ml-2'>
                {progressValue - 1}%
              </span>
              <p className='text-xs font-bold mt-1 space-x-10'>{t('allModule.Uploading')}</p>
            </div>
          </CircularProgressbarWithChildren>
        </div>
      </div>
    );
  } else if (loading && isEditing) {
    return <LoadingScreen />;
  }
  return (
    <div className='text-primary text-base font-normal font-sans'>
      <div className="main_head">
        <h1 className='text-4xl font-bold'>
          {!isEditing ? 'Add Group' : 'Edit Document'}
        </h1>
      </div>

      <div className=' flex w-full items-center h-full upload_doc_page'>
        <form className='w-2/5'>
          <div className='flex flex-col mb-3 contract-type'>
            <div className='flex'>
              <label className='mb-2'>{t('constant.Group Name')}</label>
              <>
                <img
                  src={HelpIcon}
                  width={12}
                  className='ml-1 -mt-2 cursor-pointer'
                  data-tip={t('form.Insert group name')}
                />
                <ReactTooltip backgroundColor='#400835' textColor='#ffffff' />
              </>
            </div>
            <input
              placeholder={t('form.Insert group name')}
              className='border-2 border-primary rounded-lg outline-none p-1 pl-2'
              value={!isEditing ? groupObj.group_name : editFormData.group_name}
              onChange={(e) => {
                !isEditing
                  ? setGroupObj({ ...groupObj, group_name: e.target.value })
                  : setEditFormData({
                    ...editFormData,
                    group_name: e.target.value,
                  });
              }}
            />
            <p className='text-sm text-red-500 font-normal'>
              {groupFormErrors.group_name}
            </p>
          </div>

          <div className='flex flex-col mb-3 contract-type'>
            <div className='flex'>
              <label className='mb-2'>{t('form.Contacts')}</label>
              <>
                <img
                  src={HelpIcon}
                  width={12}
                  className='ml-1 -mt-2 cursor-pointer'
                  data-tip={t('form.Select Contact')}
                />
                <ReactTooltip backgroundColor='#400835' textColor='#ffffff' />
              </>
            </div>
            <Creatable
              options={contactList}
              placeholder={t('form.Select Contact')}
              styles={customStyles}
              isMulti={true}
              isSearchable={true}
              onChange={(arr) => {
                let formatedArr = [];
                arr.forEach((obj) => formatedArr.push(parseInt(obj.value.trim())));
                !isEditing
                  ? setGroupObj({
                    ...groupObj,
                    contactList: formatedArr,
                  })
                  : setEditFormData({
                    ...editFormData,
                    contactList: formatedArr,
                  });
                setDefaultValues([...arr]);
              }}
              isClearable={false}
              className='w-full text-primary rounded-lg'
              defaultValue={defaultValues}
            />
            <p className='text-sm text-red-500 font-normal'>
              {groupFormErrors.contactList}
            </p>
          </div>




          {!isEditing ? (
            <>
              <button
                className='bg-primary font-bold text-white w-full p-1 rounded-lg mt-3'
                onClick={(e) => {
                  e.preventDefault();
                  saveOrEditGroup(groupObj);
                }}
              >
                {t('form.Add Group')}
              </button>
              <p className='text-sm text-red-500 font-normal'>
                {groupFormErrors.document}
              </p>
            </>
          ) : (
            <button
              className='bg-secondary font-bold text-white w-full p-1 rounded-lg'
              onClick={(e) => {
                e.preventDefault();
                saveOrEditGroup(editFormData, repoName);
              }}
            >
              {t('form.Update Group')}
            </button>
          )}
        </form>

      </div>

      <AppModal
        title={modalData.title}
        bodyText={modalData.bodyText}
        setIsOpen={setIsOpen}
        modalIsOpen={modalIsOpen}
        modelAction="GroupForm"
        apiResStatus={modalData.apiResStatus}
      />

    </div>
  );
};

export default GroupForm;
