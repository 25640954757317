import React, { useState, useContext, useEffect } from 'react';
import useAxios from '../../hooks/useAxios';
import { resolve } from '../../api/resolve';
import { adminLogTableColumns } from '../../constants/constants';
import CloseIcon from '../../assets/icons/Close.svg';
import Search from '../../assets/icons/Search.svg';
import LogTable from './LogTable';
import { parseISO, format } from 'date-fns'


const Logs = () => {

    const api = useAxios();
    // pending doc related states
    const [logList, setLogList] = useState([]);
    const [logSearchText, setLogListSearchText] = useState('');
    const [logCurrentPage, setLogListCurrentPage] = useState(1);
    const [logPageLimit, setLogListPageLimit] = useState(10);
    const [logNextBtnLimit, setLogListNextBtnLimit] = useState(0);
    const [logCount, setLogListCount] = useState(0);
    const [errMsgLogList, setErrMsgLogList] = useState('');
    const [showLogListClearIcon, setShowLogListClearIcon] = useState(false);
    const [sortingOrder, setSortingOrder] = useState('desc');
    const [sortColumn, setSortColumn] = useState('id');

    const getLogList = async (clearText) => {

        const res = await resolve(
            api.get(`contract/error/list/?search=${clearText ? '' : logSearchText}&page=${logCurrentPage}&limit=${logPageLimit}`)
            .then((res) => res.data)
        );
        if (res.data) {
            const { data } = res;
            const { results, count } = data;
            const filterData = [];
            
            if (results.length > 0) {
                setLogListCount(count);
                results.forEach((result) => {
                    const logListObj = {
                        id: result.id,
                        doc_name: result["doc_id"].name,
                        log_data: result.log_data,
                        created_at: format(parseISO(result.created_at), 'ccc, dd MMM yyyy, HH:mm'),
                    };
                    filterData.push(logListObj);
                });
            } else {
                if (logCurrentPage === 1) setErrMsgLogList('No log added yet');
                else setErrMsgLogList('No more log to view');
            }
            setLogList([...filterData]);
            setLogListNextBtnLimit(Math.ceil(data.count / logPageLimit))
        } else {
            setLogListNextBtnLimit(0)
        }
    };

    useEffect(() => {
        getLogList();
    }, [sortColumn, sortingOrder, logCurrentPage, logPageLimit]);

    return (
        <>
            <div className='text-primary'>
                <div className="main_head">
                    <h1 className='text-4xl font-bold'>Logs</h1>
                </div>

                <div className="tab_main">
                    <div className={`tab_us show`}>
                        <h1 className='text-xl font-bold'>Log List</h1>
                        <div className="row justify-between">
                            <div className="col-md-5">
                                <form>
                                    <div className='flex mt-3  w-full'>

                                        <div className='flex justify-between items-center border-2 border-primary rounded-md rounded-tl-lg rounded-bl-lg bg-inputBackground px-2 mr-2 w-full '>
                                            <input
                                                placeholder='Search for log'
                                                className='px-1 py-1 focus:outline-none bg-transparent w-11/12'
                                                value={logSearchText}
                                                onChange={(e) => {
                                                    setLogListSearchText(e.target.value);
                                                    setShowLogListClearIcon(true);
                                                    if (!e.target.value.trim()) {
                                                        setShowLogListClearIcon(false);
                                                    }
                                                }}
                                            />
                                            {showLogListClearIcon && (
                                                <img
                                                    src={CloseIcon}
                                                    width={14}
                                                    className='cursor-pointer'
                                                    onClick={() => {
                                                        setLogListCurrentPage(1);
                                                        setLogListSearchText('');
                                                        getLogList(true);
                                                        setShowLogListClearIcon(false);
                                                    }}
                                                />
                                            )}
                                        </div>
                                        <button
                                            type='submit'
                                            className='flex btn btn_theme2 w_125 items-center justify-between mr-2'
                                            onClick={(e) => {
                                                e.preventDefault();
                                                setLogListCurrentPage(1);
                                                getLogList();
                                            }}
                                        >
                                            <img src={Search} style={{ width: '1rem' }} />
                                            <span className='font-semibold ml-1'>Search</span>
                                        </button>

                                        <button
                                            type='submit'
                                            className='flex btn btn_theme2 w_125 items-center justify-between'
                                            onClick={(e) => {
                                                e.preventDefault();
                                                setLogListCurrentPage(1);
                                                getLogList();
                                            }}
                                        >
                                            {/* <img src={Search} style={{ width: '1rem' }} /> */}
                                            <span className='font-semibold ml-1'>Reload</span>
                                            
                                        </button>
                                    </div>
                                </form>
                            </div>
                           
                        </div>


                        <div className='mt-3 rounded-lg'>
                            {logList.length < 1 && (
                                <p className='text-red-500 text-sm font-medium'>{errMsgLogList}</p>
                            )}
                        </div>
                        <LogTable
                            tableColumns={adminLogTableColumns}
                            getListData={getLogList}
                            tableData={logList}
                            // showActionBtn
                            nextBtnLimit={logNextBtnLimit}
                            currentPage={logCurrentPage}
                            setCurrentPage={setLogListCurrentPage}
                            itemCount={logCount}
                            setPageLimit={setLogListPageLimit}
                            showPageLimit={true}
                            sortingOrder={sortingOrder}
                            setSortingOrder={setSortingOrder}
                            setSortColumn={setSortColumn}
                        />
                    </div>
                </div>
            </div>
        </>
    )
}
export default Logs;