import React, { useContext } from 'react';
import Modal from 'react-modal';
import './modal.css';
import CloseIcon from '../../assets/icons/Close.svg';
import AuthContext from '../../context/AuthContext';
import AppContext from '../../context/AppContext';
import FormContext from '../../context/FormContext';
import { useTranslation } from 'react-i18next';

const customStyles = {
  content: {
    top: '40%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    maxWidth: '400px',
    maxHeight: '180px',
    borderRadius: '11px',
    minWidth: '300px',
  },
  overlay: {
    backgroundColor: 'rgba(64, 8, 53, 0.63)',
  },
};

Modal.setAppElement('#root');
const AppModal = ({
  modalIsOpen,
  setIsOpen,
  title,
  bodyText,
  isLogin,
  activeLink,
  shouldShowRepoList,
  setContractObj,
  contractObj,
  setMultipleContractsObj,
  setIsContractModalOpen,
}) => {
  const { t } = useTranslation()
  const { logoutUser } = useContext(AuthContext);
  const { setActiveLink, setIsRepoList } = useContext(AppContext);
  const { shouldLogout } = useContext(FormContext);

  return (
    <div className=''>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={() => setIsOpen(false)}
        style={customStyles}
        contentLabel='Example Modal'
        onAfterClose={() => {
          shouldShowRepoList && setIsRepoList(true);
          activeLink?.length > 0 && setActiveLink(activeLink);
          if (setContractObj) {
            setContractObj({
              ...contractObj,
              name: '',
              type: '',
              tags: [],
              renewal_date: '',
              doc_name: '',
              document: '',
            });
            setMultipleContractsObj([]);
          }
          if (shouldLogout) {
            logoutUser();
            setActiveLink('Home');
          }
        }}
      >
        {/* <img
          src={CloseIcon}
          className='close-icon'
          onClick={() => setIsOpen(false)}
        /> */}
        <div className='text-center modal-container'>
          <h1 className='text-primary font-bold text-lg'>{title}</h1>
          <p className='text-primary font-normal text-md mt-2'>{bodyText}</p>
          {isLogin && (
            <div className='flex justify-center mt-5'>
              <button
                className='bg-primary hover:bg-secondary font-semibold text-white p-1 pl-8 pr-8 rounded-lg mr-5'
                onClick={() => {
                  setActiveLink('Home');
                  logoutUser();
                }}
              >
                {t('modal.Log out')}
              </button>
              <button
                className='bg-[#606060] font-semibold text-white p-1 pl-8 pr-8 rounded-lg'
                onClick={() => setIsOpen(false)}
              >
                {t('modal.Cancel')}
              </button>
            </div>
          )}
          {!isLogin && (
            <button
              className='bg-primary font-semibold text-white p-1 mt-3 rounded-lg w-2/3'
              onClick={() => {
                setIsContractModalOpen && setIsContractModalOpen(false);
                setIsOpen(false);
              }}
            >
              {t('modal.Accept')}
            </button>
          )}
        </div>
      </Modal>
    </div>
  );
};

export default AppModal;
