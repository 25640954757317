import React, { useEffect, useState, useContext } from 'react';
import LoadingScreen from '../loader/LoadingScreen';
import Search from '../../assets/icons/Search.svg';
import CloseIcon from '../../assets/icons/Close.svg';
import DocumentTable from './DocumentTable';
import { documentTableColumns, docStatus as docStatusList } from '../../constants/constants';
import useAxios from '../../hooks/useAxios';
import { resolve } from '../../api/resolve';
import { parseISO, format } from 'date-fns'
import './document.css'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import AuthContext from '../../context/AuthContext';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

const Document = () => {
  const api = useAxios();
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const [errMsg, setErrMsg] = useState('');
  const [documentSearchText, setDocumentSearchText] = useState('');
  const [nextPage, setIsNextPage] = useState(null);
  const [prevPage, setIsPrevPage] = useState(null);
  const [pageLimit, setPageLimit] = useState(5);
  const [contactCount, setContactCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [sortingOrder, setSortingOrder] = useState('desc');
  const [sortColumn, setSortColumn] = useState('created_at');
  const [documents, setDocuments] = useState([]);
  const [pageLoading, setPageLoading] = useState(true);
  const [showDocumentClearIcon, setDocumentShowClearIcon] = useState(false);
  const [nextBtnLimit, setNextBtnLimit] = useState(0);
  const [docStatus, setDocStatus] = useState('');
  const [dateRange, setDateRange] = useState([null, null]);
  const [startDate, endDate] = dateRange;
  const [createdBy, setCreatedBy] = useState('');
  const [userList, setUserList] = useState([]);

  const { user } = useContext(AuthContext);


  const getDocumentList = async (clearText) => {
    let startDateTmp = startDate ? format(startDate, 'yyyy-MM-dd 00:00:00') : '';
    let endDateTmp = endDate ? format(endDate, 'yyyy-MM-dd 23:59:59') : '';

    let param = "1=1";
    if (startDateTmp != "") {
      param += "&s_date=" + startDateTmp;
    }
    if (endDateTmp != "") {
      param += "&e_date=" + endDateTmp;
    }
    if (docStatus != "") {
      param += "&docStatus=" + docStatus;
    }
    if (currentPage != "") {
      param += "&page=" + currentPage;
    }
    if (pageLimit != "") {
      param += "&limit=" + pageLimit;
    }
    if (createdBy != "") {
      param += "&createdBy=" + createdBy;
    }

    const res = await resolve(
      api
        .get(
          `contract/document/list/?ordering=${sortColumn}&sortingOrder=${sortingOrder}&search=${clearText ? '' : documentSearchText}&${param}`
        )
        .then((res) => res.data)
    );
    if (res.data) {
      const { data } = res;
      const { results, count } = data;
      const filterData = [];
      if (results.length > 0) {
        setContactCount(count);
        results.forEach((result) => {
          const contactObj = {
            id: result.id,
            name: result.name,
            status: docStatusList[result.status],
            updated_at: format(parseISO(result.updated_at), 'ccc, dd MMM yyyy, HH:mm'),
            created_by: result.uploaded_by['first_name'],
          };
          filterData.push(contactObj);
        });
      } else {
        if (currentPage === 1) setErrMsg(t('homePage.No document added yet'));
        else setErrMsg(t('homePage.No more document to view'));
      }
      setDocuments([...filterData]);
      setNextBtnLimit(Math.ceil(data.count / pageLimit))
    } else {
      setNextBtnLimit(0)
    }
    setPageLoading(false);
  };

  const getUserList = async () => {
    const userListData = await resolve(
      api.get('user/list/').then((res) => res.data)
    );

    if (userListData['data']) {     
      const filteredArr = [{ label: user.name, id: user.user_id }];
      const listData = userListData['data'].data['results'];     
      if (listData.length > 0) {
        listData.forEach((userInfo) => {
          filteredArr.push({ label: userInfo.first_name, id: userInfo.id });
        });
        setUserList(filteredArr);
      } else {
        setUserList([]);
      }
    }
  };

  const sendEmail = async (id) => {
    setLoading(true);
    const postData = {
      id: id,
    };
    const responceData = await resolve(
      api.get(`user/reminder/send/email/${id}`,postData).then((res) => res.data)
    );

    if (responceData['data']) {
      toast.success(t('homePage.Successfully sent Email'));
    }

    if (responceData['error']) {
      if(responceData["error"]?.response?.data?.message){
        toast.error(responceData["error"].response.data.message);
      }else{
        toast.error(t('homePage.Failed to send Email'));
      }
    }
    setLoading(false);
  }

  const sendSms = async (id) => {
    const postData = {
      id: id,
    };
    const responceData = await resolve(
      api.get(`user/reminder/send/sms/${id}`,postData).then((res) => res.data)
    );

    if (responceData['data']) {
      toast.success(t('homePage.Successfully sent SMS'));
    }

    if (responceData['error']) {
      if(responceData["error"]?.response?.data?.message){
        toast.error(responceData["error"].response.data.message);
      }else{
        toast.error(t('homePage.Failed to send SMS'));
      }
    }
  }

  useEffect(() => {
    if (dateRange[0] == null && dateRange[1] == null) {
      getDocumentList();
    }
  }, [dateRange]);

  useEffect(() => {
    setLoading(false);
    getUserList();
    return () => {
      setDocuments([]);
    }
  }, []);

  useEffect(() => {
    getDocumentList();
  }, [sortColumn, sortingOrder, pageLimit, currentPage]);

  if (loading) {
    return <LoadingScreen />;
  }
  return (
    <>
      <div className='text-primary'>
        <div className='document_filter_box'>
          <div className='sec_bdd'>
            <div className={`row justify-${user.user_type !== "user" ? `between` : `end`}`}>
              <div className="col-md-3">
                <div className="search">
                  <div className='search_box'>
                    <div className='heg_in flex justify-between items-center border-2 border-primary rounded-md rounded-tl-lg rounded-bl-lg bg-inputBackground px-2 w-full mr-2'>
                      <input
                        placeholder={t('homePage.Enter Name of Document')}
                        className='px-1 py-1 focus:outline-none bg-transparent w-full '
                        value={documentSearchText}
                        onChange={(e) => {
                          setDocumentSearchText(e.target.value);
                          setDocumentShowClearIcon(true);
                          if (!e.target.value.trim()) {
                            setDocumentShowClearIcon(false);
                          }
                        }}

                        onKeyUp={(e) => {
                          if (e.key === 'Enter') {
                            getDocumentList();
                          }
                        }}

                      />
                      {showDocumentClearIcon && (
                        <img
                          src={CloseIcon}
                          width={14}
                          className='cursor-pointer'
                          onClick={() => {
                            setCurrentPage(1);
                            setDocumentSearchText('');
                            getDocumentList(true);
                            setDocumentShowClearIcon(false);
                          }}
                        />
                      )}
                    </div>

                  </div>

                </div>
              </div>

              <div className="col-md-3">
                <select onChange={(e) => setDocStatus(e.target.value)} className='border-2 border-primary rounded-lg outline-none p-1 pl-2 w-full heg_in'>
                  <option value="">{t('homePage.Filter by status')}</option>
                  <option value="">All</option>
                  {/* <option value="2">{t('homePage.Assigned')}</option> */}
                  {/* <option value="1">{t('homePage.Unassigned')}</option> */}
                  <option value="3">{t('homePage.In Progress')}</option>
                  <option value="4">{t('homePage.Completed')}</option>
                  <option value="5">{t('homePage.Rejected')}</option>
                </select>
              </div>
              <div className="col-md-3">
                <div className='startend_date_picker'>
                  <DatePicker
                    selectsRange={true}
                    startDate={startDate}
                    endDate={endDate}
                    onChange={(update) => {
                      setDateRange(update);

                    }}
                    isClearable={true}
                    dateFormat='dd-MM-yyyy'
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                    placeholderText={t('homePage.Date Range')}

                  />
                </div>
              </div>
              {user.user_type !== "user" && (
                <div className="col-md-3">
                  <select onChange={(e) => setCreatedBy(e.target.value)} className='border-2 border-primary rounded-lg outline-none p-1 pl-2 w-full heg_in'>
                    <option value="">{t('constant.Created By')}</option>
                    {userList.map((userInfo, index) => {
                      return (
                        <option key={index} value={userInfo.id}>{userInfo.label}</option>
                      )
                    })}
                  </select>
                </div>
              )}


            </div>
            <button
              type='submit'
              className='flex  items-center justify-between btn btn_theme2 btn-sm'
              onClick={(e) => {
                e.preventDefault();
                setCurrentPage(1)
                getDocumentList();
              }}
            >
              <img src={Search} style={{ width: '1rem' }} />
              <span className='font-semibold ml-1'>{t('allModule.Search')}</span>
            </button>
          </div>


        </div>

        <div className='mt-3 rounded-lg'>
          {documents.length < 1 && (
            <p className='text-red-500 text-sm font-medium'>{errMsg}</p>
          )}
        </div>
        <DocumentTable
          documentTableColumns={documentTableColumns}
          documentListData={documents}
          nextPage={nextPage}
          prevPage={prevPage}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          sortingOrder={sortingOrder}
          setSortingOrder={setSortingOrder}
          setSortColumn={setSortColumn}
          pageLimit={pageLimit}
          setPageLimit={setPageLimit}
          contactCount={contactCount}
          setContactCount={setContactCount}
          showActionBtn
          showPageLimit={true}
          getDocumentList={getDocumentList}
          nextBtnLimit={nextBtnLimit}
          sendSms={sendSms}
          sendEmail={sendEmail}
        />
      </div>
    </>
  );
};

export default Document;
