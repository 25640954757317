import React, { useEffect, useState, useContext } from "react";
import { Worker, Viewer } from "@react-pdf-viewer/core";
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";
import { searchPlugin } from "@react-pdf-viewer/search";
import ReactTooltip from "react-tooltip";
import { saveAs } from "file-saver";
import "./contracts.css";
import dayjs from "dayjs";
import useAxios from "../../hooks/useAxios";
import { resolve } from "../../api/resolve";
import AppContext from "../../context/AppContext";
import NewTabIcon from "../../assets/icons/NewTab.svg";
import DownloadIcon from "../../assets/icons/Download1srd.svg";
import DownArrow from "../../assets/icons/down-arrow.svg";
import SearchIcon from "../../assets/icons/Search-sm.svg";
import PrintIcon from "../../assets/icons/Print.png";
import ListIcon from "../../assets/icons/List.png";
import LoadingScreen from "../loader/LoadingScreen";
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
import ClausesModal from "../modallegacy/ClausesModallegacy";
import DocPasswordModal from "../modallegacy/DocPasswordModallegacy";
import { formatSelectedClause } from "../../utils/utils";
import AuthContext from "../../context/AuthContext";
import { useTranslation } from "react-i18next";

const ViewContractlegacy = ({
  selectedContract,
  setSelectedContract,
  setContractClauses,
  contractClauses,
  multiClauseData,
  setMultiClauseData,
  advanceSearchText,
}) => {
  const api = useAxios();
  const { t } = useTranslation();
  const [clauseValue, setClauseValue] = useState("");
  const [loading, setLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isPasswordModelOpen, setIsPasswordModalOpen] = useState(false);
  const { getSasToken, sasToken, highlightToken, getHighlightSasToken } =
    useContext(AppContext);
  const { user } = useContext(AuthContext);
  const [docFormat, SetDocFormat] = useState();
  const [docUrl, setDocUrl] = useState("");
  const [clauseList, setClauseList] = useState([]);
  const [selectedClauses, setSelectedClauses] = useState([]);
  const [downloadDocClauses, setDownloadDocClauses] = useState([]);
  const [currentClauseID, setCurrentClauseID] = useState(null);
  const [currentClause, setCurrentClause] = useState(selectedClauses[0]);
  const [primaryUrl, setPrimaryUrl] = useState("");
  const [keyword, setKeyword] = useState("");
  const [highlightedList, setHighlightedList] = useState([]);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const searchPluginInstance = searchPlugin({
    onHighlightKeyword: (props) => {
      props.highlightEle.style.backgroundColor = "red";
    },
  });
  const { Search } = searchPluginInstance;
  const renderToolbar = (Toolbar) => (
    <Toolbar>
      {(slots) => {
        const {
          CurrentPageInput,
          GoToNextPage,
          GoToPreviousPage,
          NumberOfPages,
          ShowSearchPopover,
          Print,
          CurrentScale,
          ZoomIn,
          ZoomOut,
        } = slots;
        return (
          <div className={`w-full res_header`}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                width: "100%",
                justifyContent: "space-between",
              }}
            >
              <div className="vie_pdf1" style={{ padding: "0px 2px" }}>
                <ShowSearchPopover>
                  {(props) => (
                    <Search>
                      {(renderSearchProps) => {
                        return (
                          <div className="flex">
                            <div
                              className="border-2 searchBox border-primary bg-[#EBE9E9] w-60 p-1 rounded-lg flex"
                              onLoadCapture={() => {
                                renderSearchProps.setKeyword(keyword); //enable it once watermark is ready
                                // if (clauseValue) {
                                //   let keyword = '';
                                //   const keywordArr = clauseValue
                                //     .replaceAll('#@#', '')
                                //     .split('\n');
                                //   if (keywordArr.length > 1) {
                                //     keyword = keywordArr[1].trim();
                                //   } else {
                                //     keyword = keywordArr[0].trim();
                                //   }
                                //   renderSearchProps.setKeyword(keyword);
                                // }
                              }}
                              onLoad={() => {
                                // enable it once watermark is ready
                                if (keyword) {
                                  renderSearchProps
                                    .search()
                                    .then(() =>
                                      renderSearchProps.setKeyword("")
                                    );
                                }
                                // if (clauseValue) {
                                //   renderSearchProps
                                //     .search()
                                //     .then(() =>
                                //       renderSearchProps.setKeyword('')
                                //     );
                                // }
                              }}
                            >
                              {screenWidth > 1000 && (
                                <img
                                  src={SearchIcon}
                                  style={{
                                    width: 20,
                                    height: 20,
                                    margin: 2,
                                    marginTop: "5px",
                                  }}
                                />
                              )}
                              <input
                                // style={{width: 45, height: 20, margin: 2}}
                                className="bg-transparent focus:border-none outline-none w-full text-sm font-bold text-primary"
                                placeholder={t(
                                  "allModule.Search within the Document"
                                )}
                                type="text"
                                onChange={(e) => {
                                  renderSearchProps.clearKeyword();
                                  renderSearchProps.setKeyword(e.target.value);
                                }}
                                onKeyDown={(e) => {
                                  if (
                                    e.keyCode === 13 &&
                                    renderSearchProps.keyword
                                  ) {
                                    e.preventDefault();
                                    renderSearchProps.search();
                                  }
                                }}
                              />
                            </div>

                            {renderSearchProps.keyword &&
                              renderSearchProps.numberOfMatches > 0 && (
                                <div className="flex ml-1 items-center">
                                  <p>
                                    {renderSearchProps.currentMatch} of{" "}
                                    {renderSearchProps.numberOfMatches}
                                  </p>
                                  <div className="flex flex-col items-center ml-1">
                                    <span
                                      disabled={props.isDisabled}
                                      onClick={
                                        renderSearchProps.jumpToPreviousMatch
                                      }
                                      style={{
                                        cursor: "pointer",
                                        transform: "rotate(180deg)",
                                        width: 15,
                                        height: 15,
                                      }}
                                      className="ml-1"
                                      data-tip={t("allModule.Previous match")}
                                    >
                                      <img src={DownArrow} />
                                    </span>
                                    <ReactTooltip
                                      backgroundColor="#400835"
                                      textColor="#ffffff"
                                    />
                                    <span
                                      onClick={
                                        renderSearchProps.jumpToNextMatch
                                      }
                                      style={{
                                        cursor: "pointer",
                                        width: 15,
                                        height: 15,
                                      }}
                                      className="ml-1"
                                      data-tip={t("allModule.Next match")}
                                    >
                                      <img src={DownArrow} />
                                    </span>
                                    <ReactTooltip
                                      backgroundColor="#400835"
                                      textColor="#ffffff"
                                    />
                                  </div>
                                </div>
                              )}
                            {selectedClauses.length > 0 && (
                              <div
                                className="cursor-pointer ml-2"
                                style={{ width: "fit-content" }}
                              >
                                <div className="flex justify-between items-center">
                                  <span
                                    className="mr-1 font-semibold"
                                    style={{ fontSize: 12 }}
                                  >
                                    {t("allModule.Current Clause")}:{" "}
                                  </span>
                                  <select
                                    // enable it once watermark is ready
                                    onChange={(e) => {
                                      const val =
                                        highlightedList.length > 0 &&
                                        highlightedList.filter(
                                          (item) =>
                                            item.key.trim().toLowerCase() ===
                                            e.target.value.trim().toLowerCase()
                                        );
                                      if (val[0]?.value) {
                                        let formattedValue = "";
                                        const keywordArr = val[0].value
                                          .replaceAll("#@#", "")
                                          .split("\n");
                                        if (keywordArr.length > 1) {
                                          formattedValue = keywordArr[1].trim();
                                        } else {
                                          formattedValue = keywordArr[0].trim();
                                        }
                                        setKeyword(formattedValue);
                                      }
                                      setCurrentClause(e.target.value);
                                    }}
                                    // onChange={(e) =>
                                    //   setCurrentClause(e.target.value)
                                    // }
                                    value={currentClause}
                                    style={{ minWidth: 180 }}
                                    className="border-2 border-primary outline-none rounded-md p-1"
                                  >
                                    {selectedClauses.length > 0 &&
                                      selectedClauses.map((clause, idx) => (
                                        <option
                                          value={clause}
                                          className="text-sm font-semibold my-1"
                                        >
                                          {idx + 1}){" "}
                                          {formatSelectedClause(
                                            clause.replaceAll("_", " ")
                                          )}{" "}
                                        </option>
                                      ))}
                                  </select>
                                </div>
                              </div>
                            )}
                          </div>
                        );
                      }}
                    </Search>
                  )}
                </ShowSearchPopover>
              </div>
              {screenWidth > 790 && (
                <div
                  className={`flex ml-8 items-center ${
                    clauseList.length < 1 ? "-ml-32" : ""
                  } justify-center`}
                >
                  <div className="w-7">
                    <CurrentPageInput />
                  </div>
                  <div className="mx-2">
                    /{" "}
                    <span>
                      <NumberOfPages />
                    </span>
                  </div>
                  <div className="flex flex-col ml-1">
                    <GoToPreviousPage>
                      {(props) => (
                        <>
                          <span
                            disabled={props.isDisabled}
                            onClick={props.onClick}
                            style={{
                              cursor: "pointer",
                              transform: "rotate(180deg)",
                              width: 15,
                              height: 15,
                            }}
                            className="ml-1"
                            data-tip="Previous Page"
                          >
                            <img src={DownArrow} />
                          </span>
                          <ReactTooltip
                            backgroundColor="#400835"
                            textColor="#ffffff"
                          />
                        </>
                      )}
                    </GoToPreviousPage>
                    <GoToNextPage>
                      {(props) => (
                        <>
                          <span
                            disabled={props.isDisabled}
                            onClick={props.onClick}
                            style={{
                              cursor: "pointer",
                              marginTop: 2,
                              width: 15,
                              height: 15,
                            }}
                            className="ml-1"
                            data-tip="Next Page"
                          >
                            <img src={DownArrow} />
                          </span>
                          <ReactTooltip
                            backgroundColor="#400835"
                            textColor="#ffffff"
                          />
                        </>
                      )}
                    </GoToNextPage>
                  </div>
                </div>
              )}
              <div className="max-w-fit flex items-center ">
                <ZoomIn />
                <span className="mx-1">{CurrentScale()}</span>
                <ZoomOut />
              </div>
              <div className="flex  pr-2 mb-2 hover:cursor-pointer items-center">
                {contractClauses.length > 0 && (
                  <img
                    src={ListIcon}
                    className="mr-2"
                    onClick={() => setIsModalOpen(true)}
                    data-tip={t("allModule.Highlight text by multiple clause")}
                  />
                )}
                <ReactTooltip backgroundColor="#400835" textColor="#ffffff" />
                {clauseValue ? (
                  <a
                    // href={`${primaryUrl}?${highlightToken.sas_token}`}
                    target="_blank"
                    onClick={() => {
                      setIsPasswordModalOpen(true);
                      setTimeout(() => {
                        window.open(
                          `${primaryUrl}?${highlightToken.sas_token}`,
                          "_blank",
                          "noopener,noreferrer"
                        );
                      }, 1000);
                    }}
                  >
                    <img
                      src={NewTabIcon}
                      className="mr-2"
                      data-tip={t("allModule.Open in new tab")}
                    />
                    <ReactTooltip
                      backgroundColor="#400835"
                      textColor="#ffffff"
                    />
                  </a>
                ) : (
                  <a
                    href={`${selectedContract.converted_file_url}?${sasToken.sas_token}`}
                    target="_blank"
                  >
                    <img
                      src={NewTabIcon}
                      className="mr-2"
                      data-tip={t("allModule.Open in new tab")}
                    />
                    <ReactTooltip
                      backgroundColor="#400835"
                      textColor="#ffffff"
                    />
                  </a>
                )}

                {clauseValue ? (
                  <a
                    download={selectedContract.doc_name}
                    target="_blank"
                    // enable it once watermark is ready
                    onClick={() => {
                      downloadHighlightedDoc();
                    }}
                    // href={`${process.env.REACT_APP_LEGACY_BACKEND_BASE_URL}/contract/download_highlighted_contract/?file_url=${primaryUrl}`}
                  >
                    <img
                      src={DownloadIcon}
                      data-tip={t("allModule.Download document")}
                    />
                    <ReactTooltip
                      backgroundColor="#400835"
                      textColor="#ffffff"
                    />
                  </a>
                ) : (
                  <a
                    href={`${process.env.REACT_APP_BACKEND_BASE_URL}/legacy_contract/download_contract/?file_url=${selectedContract.file_url}&id=${selectedContract.id}`}
                    download={selectedContract.doc_name}
                    target="_blank"
                    data-tip={t("allModule.Download document")}
                  >
                    <img src={DownloadIcon} />
                    <ReactTooltip
                      backgroundColor="#400835"
                      textColor="#ffffff"
                    />
                  </a>
                )}
                {user.user_type === "admin" && (
                  <Print>
                    {(props) => {
                      return (
                        <>
                          <img
                            src={PrintIcon}
                            className="ml-2 cursor-pointer"
                            style={{ width: 20 }}
                            onClick={() => {
                              // disable it once watermark is ready
                              // if (primaryUrl) {
                              //   setDocUrl(primaryUrl);
                              // }
                              props.onClick();
                            }}
                            data-tip={t("allModule.Print document")}
                          />
                          <ReactTooltip
                            backgroundColor="#400835"
                            textColor="#ffffff"
                          />
                        </>
                      );
                    }}
                  </Print>
                )}
              </div>
            </div>
          </div>
        );
      }}
    </Toolbar>
  );
  const defaultLayoutPluginInstance = defaultLayoutPlugin({
    renderToolbar,
    sidebarTabs: () => [],
  });
  const getClauseValue = async (id) => {
    const res = await api.get(`/legacy_contract/get_clause_value/${id}/`);
    if (res.data) {
      const filteredArr = [];
      const { contract_key_list } = res.data;
      if (contract_key_list && contract_key_list.length > 0) {
        contract_key_list.forEach((obj) => {
          if (obj.key && obj.key.length > 0 && obj.value) {
            const formattedText = obj.key.split("_").join(" ");
            filteredArr.push(formattedText);
          }
        });
      }
      setContractClauses([...filteredArr]);
    }
  };

  const getHighlightedDoc = async ({ id, clause, showPrimary }) => {
    if (id == false) {
      return false;
    }
    setLoading(true);
    const res = await resolve(
      api.post(
        "/legacy_contract/highlight_pdf/",
        JSON.stringify({
          contract_id: id,
          key_list: clause,
          search_term: advanceSearchText ? advanceSearchText : "",
        }),
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
    );
    if (res.data?.data) {
      setClauseValue(res.data.data.value);
      let value = res.data.data.value;
      if (value) {
        let formattedValue = "";
        const keywordArr = value.replaceAll("#@#", "").split("\n");
        if (keywordArr.length > 1) {
          formattedValue = keywordArr[1].trim();
        } else {
          formattedValue = keywordArr[0].trim();
        }
        setKeyword(formattedValue);
      }
      const val = res.data.data.selected_key.replaceAll("_", " ");
      const idx = clauseList.indexOf(val);
      const clauseIdx = selectedClauses.indexOf(
        res.data.data.selected_key.trim().toLowerCase()
      );
      if (idx > -1) setCurrentClauseID(idx);
      if (clauseIdx > -1) setCurrentClause(selectedClauses[clauseIdx]);
      setDocUrl(res.data.data.file_url);
      if (res.data.data.value && showPrimary) {
        setPrimaryUrl(res.data.data.file_url);
        setHighlightedList([...res.data.data.response_list]);
      }
    }
    setLoading(false);
  };
  const downloadHighlightedDoc = async () => {
    setLoading(true);
    const res = await resolve(
      api.post(
        "/legacy_contract/download_highlighted_contract/",
        JSON.stringify({
          contract_id: selectedContract.id,
          key_list: downloadDocClauses,
          search_term: advanceSearchText ? advanceSearchText : "",
        }),
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
    );
    if (res.data) {
      saveAs(
        `${res.data.data.file_url}?${highlightToken.sas_token}`,
        `${res.data.data.file_name}`
      );
      setIsPasswordModalOpen(true);
    }
    setLoading(false);
  };
  useEffect(() => {
    if (selectedContract.file_url) {
      SetDocFormat(
        selectedContract.file_url.split(".")[
          selectedContract.file_url.split(".").length - 1
        ]
      );
    }
    if (selectedContract) {
      getClauseValue(selectedContract.id);
    }
    if (clauseList.length > 0) {
      setClauseList([]);
      setSelectedClauses([]);
      setDownloadDocClauses([]);
    }
  }, [selectedContract.file_url, selectedContract]);
  useEffect(() => {
    setKeyword("");
    const isExpired = dayjs.unix(sasToken.expiry).diff(dayjs()) < 25000;
    const isHighlightTokenExpired =
      dayjs.unix(highlightToken.expiry).diff(dayjs()) < 1;
    if (isExpired) {
      getSasToken();
    }
    if (isHighlightTokenExpired) {
      getHighlightSasToken();
    }
  }, [selectedContract]);
  useEffect(() => {
    getSasToken();
    getHighlightSasToken();
  }, []);

  useEffect(() => {
    if (
      selectedContract.contract_clause ||
      advanceSearchText ||
      multiClauseData
    ) {
      getHighlightedDoc({
        id: selectedContract.id,
        clause:
          selectedContract.contract_clause.length > 0
            ? [selectedContract.contract_clause]
            : [],
        showPrimary: false,
      });
    }
  }, [selectedContract]);
  // useEffect(() => {
  //   if (clauseList.length > 0 && currentClause) {
  //     getHighlightedDoc({
  //       id: selectedContract.id,
  //       clause: [currentClause],
  //       showPrimary: false,
  //     });
  //   }
  // }, [clauseList]); //remove currentclause once watermark is ready
  return (
    <div className="mt-5">
      <div
        style={{
          paddingBottom: "50px",
          height: 700,
        }}
      >
        <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.13.216/build/pdf.worker.min.js">
          {clauseValue ? (
            <>
              {loading ? (
                <LoadingScreen />
              ) : (
                <>
                  {docUrl && (
                    <Viewer
                      fileUrl={`${docUrl}?${highlightToken.sas_token}`}
                      plugins={[
                        defaultLayoutPluginInstance,
                        searchPluginInstance,
                      ]}
                      onDocumentAskPassword={(e) =>
                        e.verifyPassword(
                          `${user.user_email
                            .trim()
                            .slice(0, 4)
                            .toUpperCase()}${user.user_company
                            .trim()
                            .replaceAll(" ", "")
                            .slice(0, 4)
                            .toUpperCase()}`
                        )
                      }
                      renderError={() => (
                        <div className="grid place-items-center h-full w-full">
                          <h1 className="font-semibold">
                            {t(
                              "common.Contract indexing in progress. Check back later"
                            )}
                          </h1>
                        </div>
                      )}
                      renderLoader={() => <LoadingScreen />}
                    />
                  )}
                </>
              )}
            </>
          ) : (
            <>
              {loading ? (
                <LoadingScreen />
              ) : (
                <Viewer
                  fileUrl={`${selectedContract.converted_file_url}?${sasToken.sas_token}`}
                  plugins={[defaultLayoutPluginInstance, searchPluginInstance]}
                  renderError={() => (
                    <div className="grid place-items-center h-full w-full">
                      <h1 className="font-semibold">
                        {t(
                          "common.Contract indexing in progress. Check back later"
                        )}
                      </h1>
                    </div>
                  )}
                  renderLoader={() => <LoadingScreen />}
                />
              )}
            </>
          )}
        </Worker>
      </div>
      <ClausesModal
        setMultiClauseData={setMultiClauseData}
        contractClauses={contractClauses}
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        getHighlightedDoc={getHighlightedDoc}
        selectedContract={selectedContract}
        multiClauseData={multiClauseData}
        clauseList={clauseList}
        setClauseList={setClauseList}
        setSelectedClauses={setSelectedClauses}
        setDownloadDocClauses={setDownloadDocClauses}
        downloadDocClauses={downloadDocClauses}
      />
      <DocPasswordModal
        setIsOpen={setIsPasswordModalOpen}
        modalIsOpen={isPasswordModelOpen}
      />
    </div>
  );
};

export default ViewContractlegacy;
