import React from "react";
import Modal from "react-modal";
import "./modal.css";
import { useTranslation } from "react-i18next";

const customStyles = {
  content: {
    top: "40%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    maxWidth: "400px",
    maxHeight: "220px",
    borderRadius: "11px",
    overflow: "hidden",
  },
  overlay: {
    backgroundColor: "rgba(64, 8, 53, 0.63)",
  },
};

Modal.setAppElement("#root");
const DeleteModal = ({
  deleteContract,
  isDeleteModalOpen,
  setIsDeleteModalOpen,
  title,
  bodyText,
  deleteRepository,
  discardChanges,
  deleteClause,
  deleteContracts,
  deleteContact,
  deleteGroup,
  deleteDocument,
  deletePlan,
  deleteSection,
  deleteTask,
  confirmSingleDelete,
  clearAllNotifications,
  clearAllNotificationslegacy,
  deleteDemoUser,
  deleteTemplate,
  deleteVersion,
}) => {
  const { t } = useTranslation();
  return (
    <div className="">
      <Modal
        isOpen={isDeleteModalOpen}
        onRequestClose={() => setIsDeleteModalOpen(false)}
        style={customStyles}
        contentLabel="Example Modal"
        onAfterClose={() => {}}
      >
        <div className="text-center modal-container">
          <h1 className="text-primary font-bold text-lg">{title}</h1>
          <p className="text-primary font-normal text-md mt-2">{bodyText}</p>
          <div className="flex justify-center mt-5">
            <button
              className="bg-primary hover:bg-secondary font-semibold text-white p-1 pl-8 pr-8 rounded-lg mr-5"
              onClick={(e) => {
                e.stopPropagation();
                deleteGroup
                  ? deleteGroup()
                  : deleteContact
                  ? deleteContact()
                  : deleteContract
                  ? deleteContract()
                  : deleteRepository
                  ? deleteRepository()
                  : deleteClause
                  ? deleteClause()
                  : deleteContracts
                  ? deleteContracts()
                  : deleteDocument
                  ? deleteDocument()
                  : deletePlan
                  ? deletePlan()
                  : deleteSection
                  ? deleteSection()
                  : deleteTask
                  ? deleteTask()
                  : deleteDemoUser
                  ? deleteDemoUser()
                  : deleteTemplate
                  ? deleteTemplate()
                  : deleteVersion
                  ? deleteVersion()
                  : confirmSingleDelete
                  ? confirmSingleDelete()
                  : clearAllNotifications
                  ? clearAllNotifications()
                  : clearAllNotificationslegacy
                  ? clearAllNotificationslegacy()
                  : discardChanges();
              }}
            >
              {t("form.Confirm")}
            </button>
            <button
              className="bg-[#606060] font-semibold text-white p-1 pl-8 pr-8 rounded-lg"
              onClick={(e) => {
                e.stopPropagation();
                setIsDeleteModalOpen(false);
              }}
            >
              {t("common.Cancel")}
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default DeleteModal;
