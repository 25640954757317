import React, { useContext, useState } from "react";
import Modal from "react-modal";
import "./modal.css";
import LoadingScreen from "../loader/LoadingScreen";
import { parseISO, format } from "date-fns";
import { useTranslation } from "react-i18next";
import PermissionContext from "../../context/PermissionContext";

const customStyles = {
  content: {
    top: "30%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    minWidth: "1200px",
    maxHeight: "auto",
    borderRadius: "11px",
  },
  overlay: {
    backgroundColor: "rgba(64, 8, 53, 0.63)",
  },
};

Modal.setAppElement("#root");
const SigneeInfoModal = ({
  signeeInfoModelOpen,
  setSigneeInfoModelOpen,
  recipientList,
  sendEmail,
  sendSms,
  screenName,
}) => {
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const { permissionList } = useContext(PermissionContext);

  return (
    <div className="">
      <Modal
        isOpen={signeeInfoModelOpen}
        onRequestClose={() => setSigneeInfoModelOpen(false)}
        style={customStyles}
        contentLabel="Example Modal"
        onAfterClose={() => {}}
      >
        <div className="text-center modal-container">
          <h1 className="text-primary font-bold text-lg mb-2">
            {t("form.Recipient Status")}
          </h1>
          <hr />
          {loading == false ? (
            <>
              {screenName == "MyDocuments" &&
                permissionList["sms_email_reminder_on"] == true && (
                  <div className="flex justify-end mt-3 mb-3 set_bymnnn">
                    <button
                      onClick={(e) => {
                        e.stopPropagation();
                        sendSms(setLoading);
                      }}
                      className="btn btn_theme btn-sm mr-1 wh_spab"
                    >
                      {t("form.Send SMS Reminder")}
                    </button>
                    <button
                      onClick={(e) => {
                        e.stopPropagation();
                        sendEmail(setLoading);
                      }}
                      className="btn btn_theme btn-sm wh_spab"
                    >
                      {t("form.Send Email Reminder")}
                    </button>
                  </div>
                )}
              <div className="table_desin">
                <div className="shadow overflow-hidden border-2 border-primary  sm:rounded-lg overflow-y-auto">
                  <table className="w-full">
                    <thead>
                      <tr>
                        <th className="px-4 py-2">{t("form.Name")}</th>
                        <th className="px-4 py-2">{t("form.Email")}</th>
                        <th className="px-4 py-2">{t("form.Status")}</th>
                        <th className="px-4 py-2">
                          {t("form.Reason of Rejection")}
                        </th>
                        {screenName == "MyDocuments" && (
                          <>
                            <th className="px-4 py-2">
                              {t("form.Email Count")}
                            </th>
                            <th className="px-4 py-2">{t("form.SMS Count")}</th>
                            <th className="px-4 py-2">
                              {t("form.Last Email sent date")}
                            </th>
                            <th className="px-4 py-2">
                              {t("form.Last SMS sent date")}
                            </th>
                          </>
                        )}
                      </tr>
                    </thead>
                    <tbody>
                      {recipientList.map((recipient, index) => (
                        <tr key={index}>
                          <td className="border px-4 py-2">
                            {recipient.user_name}
                          </td>
                          <td className="border px-4 py-2">
                            {recipient.email_of_user}
                          </td>
                          {/* <td className='border px-4 py-2'>{recipient.is_rejected===true?'Rejected':recipient.is_signed ? 'Signed' : 'Not Signed'}</td> */}
                          <td className="border px-4 py-2">
                            {recipient.is_rejected === true
                              ? "Rejected"
                              : recipient.typeOfSignature === "Approver"
                              ? recipient.is_signed
                                ? "Approved"
                                : "Approval Pending"
                              : recipient.typeOfSignature === "Reviewer"
                              ? recipient.is_signed
                                ? "Reviewed"
                                : "Review Pending"
                              : recipient.is_signed
                              ? "Signed"
                              : "Not Signed"}
                          </td>
                          <td className="border px-4 py-2">
                            <span className="set_cont2">
                              {recipient.rejection_reason
                                ? recipient.rejection_reason
                                : "-"}
                            </span>
                          </td>
                          {screenName == "MyDocuments" && (
                            <>
                              <td className="border px-4 py-2">
                                {recipient.mail_count}
                              </td>
                              <td className="border px-4 py-2">
                                {recipient.sms_count}
                              </td>
                              <td className="border px-4 py-2">
                                {recipient.mailing_date
                                  ? format(
                                      parseISO(recipient.mailing_date),
                                      "ccc, dd MMM yyyy, HH:mm"
                                    )
                                  : "-"}
                              </td>
                              <td className="border px-4 py-2">
                                {recipient.sms_send_date
                                  ? format(
                                      parseISO(recipient.sms_send_date),
                                      "ccc, dd MMM yyyy, HH:mm"
                                    )
                                  : "-"}
                              </td>
                            </>
                          )}
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>

              <div
                className={`flex justify-${
                  screenName == "MyDocuments" || screenName == "RejectedScreen"
                    ? "end"
                    : "between"
                } mt-5`}
              >
                {/* {screenName == "finalSignatureScreen" && (
                  <span style={{ textAlign: "left" }}>
                    <b>{t("form.Note")}: </b>
                    {t(
                      "form.If one of the signatories has rejected the document, the other signatures remain valid until the document is cancelled by the creator. Please contact the document creator for any queries."
                    )}
                  </span>
                )} */}
                <button
                  className="bg-[#606060] font-semibold text-white p-1 pl-8 pr-8 rounded-lg w-1/5 h-10"
                  onClick={() => setSigneeInfoModelOpen(false)}
                >
                  {t("common.Close")}
                </button>
              </div>
            </>
          ) : (
            <div className="flex justify-center items-center h-96">
              <LoadingScreen />
            </div>
          )}
        </div>
      </Modal>
    </div>
  );
};

export default SigneeInfoModal;
