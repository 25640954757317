import React, { useState, useEffect, useRef, useContext } from "react";
import axios from "axios";
import { Worker, Viewer } from "@react-pdf-viewer/core";
import { useHref, useNavigate } from "react-router-dom";
import ipLoaderImg from "../../assets/loader.gif";
// Import the styles
import "@react-pdf-viewer/core/lib/styles/index.css";
import "./workflow-to-sign.css";
import { autoCloseTimer, delay } from "../../constants/constants";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";
import SignatureModal from "../signaturePad/SignatureModal";
import { resolve } from "../../api/resolve";
import AuthContext from "../../context/AuthContext";
import LoadingScreen from "../loader/LoadingScreen";
import AppContext from "../../context/AppContext";
import { parseISO, format } from "date-fns";
import jwt_decode from "jwt-decode";
import SigneeInfoModal from "../modals/SigneeInfoModal";
import useAxios from "../../hooks/useAxios";
import { useTranslation } from "react-i18next";

const WorkflowToSign = () => {
  const { t, i18n } = useTranslation();

  const [canvasType, setCanvasType] = useState("");
  const [isSignModalOpen, setIsSignModalOpen] = useState(false);
  const [src, setSrc] = useState("");
  const [acceptReject, setAcceptReject] = useState("none");
  const [rejectReason, setRejectReason] = useState("");
  const [loading, setLoading] = useState(true);
  const [loadingApi, setLoadingApi] = useState(false);
  const [loadingPreview, setLoadingPreview] = useState(false);
  const [companyName, setCompanyName] = useState("");
  const [imgSrc, setImgSrc] = useState("");
  const videoRef = useRef(null);
  const [showWebcam, setShowWebcam] = useState(false);
  const [stream, setStream] = useState(null);
  const [formError, setFormError] = useState({
    accept: "",
    reject: "",
  });
  let { recptId } = useParams();
  const [docUrl, setDocUrl] = useState("");
  const [ip, setIP] = useState("");
  const [signingInfo, setSigningInfo] = useState([]);
  const [typeOfSignature, setTypeOfSignature] = useState(null);
  const [userInfo, setUserInfo] = useState([]);
  const { user } = useContext(AuthContext);
  const [sasToken, setSasToken] = useState("");
  const [isShowPreviewBtn, setIsShowPreviewBtn] = useState(false);
  const [isShowRejectBtn, setIsShowRejectBtn] = useState(true);
  const [createAccount, setCreateAccount] = useState(false);
  const [isShowCreateAccountBtn, setIsShowCreateAccountBtn] = useState(true);
  const [isShowBackToDashboardBtn, setIsShowBackToDashboardBtn] =
    useState(false);
  const [isShowReTryBtn, setIsShowReTryBtn] = useState(false);
  const [signingTab, setSigningTab] = useState("sign");
  const [documentStatus, setDocumentStatus] = useState(3);
  const { setActiveLink, language, setLanguage, langObj } =
    useContext(AppContext);
  const [recipientList, setRecipientList] = useState([]);
  const [signeeInfoModelOpen, setSigneeInfoModelOpen] = useState(false);
  const [documentId, setDocumentId] = useState(0);

  const navigate = useNavigate();
  const iframeRef = useRef("");
  const decodedToken = jwt_decode(recptId);
  recptId = decodedToken.document_id;

  const baseURL = process.env.REACT_APP_BACKEND_BASE_URL;
  const getUserIpAddress = async () => {
    const res = await axios.get("https://api.ipify.org/?format=json");
    setIP(res.data.ip);
  };
  const api = useAxios();

  const handleAcceptReject = async (btnClicked) => {
    setFormError({ ...formError, accept: "", reject: "" });
    if (
      (btnClicked === "accept" || btnClicked === "review") &&
      acceptReject !== "accept"
    ) {
      setFormError({
        ...formError,
        accept: t("form.Please accept term and condition"),
        reject: "",
      });
    } else if (btnClicked === "reject" && acceptReject !== "reject") {
      setFormError({
        ...formError,
        accept: "",
        reject: t("form.Please accept terms"),
      });
    } else if (btnClicked === "reject" && rejectReason === "") {
      setFormError({
        ...formError,
        accept: "",
        reject: t("form.please provide rejection reason"),
      });
    } else {
      let isSubmitForm = true;
      if (btnClicked === "accept") {
        signingInfo.map((item) => {
          if (item.is_sign === 0) {
            isSubmitForm = false;
          }
        });

        if (isSubmitForm === false) {
          setFormError({
            ...formError,
            accept: t("form.Please provide all seal and signs"),
            reject: "",
          });
        }
      }
      let postData = {
        ip: ip ? ip : await getUserIpAddress(),
        document_recpt_id: recptId,
        status: acceptReject === "accept" ? true : false,
        reason: acceptReject === "reject" ? rejectReason : "",
        createAccount: createAccount,
      };
      if (isSubmitForm) {
        setLoadingApi(true);
        axios
          .post(`${baseURL}/contract/document/finalization/`, postData)
          .then((resp) => {
            setLoadingApi(false);
            toast.success(t("form.Document Updated Successfully..."), {
              delay,
              autoClose: autoCloseTimer,
              onClose: () => setLoadingApi(false),
            });

            setTimeout(() => {
              navigate("/workflow-thankyou");
            }, 2000);
          })
          .catch((err) => {
            setLoadingApi(false);
            toast.error(t("common.Something went wrong"), {
              delay,
              autoClose: autoCloseTimer,
              onClose: () => setLoadingApi(false),
            });
          });
      }
    }
  };

  // const scrollToDiv = () => {
  //   const div = document.getElementById('signdiv'); // Replace 'your-div-id' with the actual ID of your div
  //   if (div) {
  //     div.scrollIntoView({ behavior: 'smooth', block: 'start' });
  //   }
  // };

  const getDocumentInfoApi = async (recptId) => {
    const responceData = await resolve(
      axios
        .post(`${baseURL}/contract/document/email/sign/`, {
          recpt_id: recptId,
          final: false,
        })
        .then((res) => res.data)
    );

    if (responceData["data"]) {
      let doc_info = responceData["data"].doc_info;
      setDocUrl(doc_info[0].converted_image_url);

      let signature_info = responceData["data"].signature_info;
      signature_info.map((item) => {
        item.is_sign = 0;
        // item.is_sign = 1;
      });

      setTypeOfSignature(responceData["data"].type_of_signature);
      if (responceData["data"].type_of_signature === "Reviewer") {
        setSigningTab("Reviewer");
        setSigningInfo([]);
      }
      if (
        responceData["data"].type_of_signature == "Aadhaar_Digital_Signature"
      ) {
        setSigningTab("digitalSign");
        setSigningInfo([]);
      } else {
        setSigningInfo(signature_info);
      }

      let user_info = responceData["data"].userInfo[0];
      setUserInfo(user_info);

      if (user_info["is_register"]) {
        setIsShowCreateAccountBtn(false);
      } else {
        setIsShowCreateAccountBtn(true);
      }
      setSasToken(responceData["data"].sas_token);

      //set already signed signature
      if (responceData["data"].already_signed_signature) {
        setTimeout(() => {
          setSrc(responceData["data"].already_signed_signature);
          setCanvasType("signature");
          setSrc("");
          setCanvasType("seal");
        }, 2000);
      }

      setDocumentId(responceData["data"].document_id);
      setLoading(false);
    }

    if (responceData["error"]) {
      setLoading(false);
      if (
        responceData["error"].response["data"].message ===
        "document was cancled"
      ) {
        setDocumentStatus(5);
      } else if (
        responceData["error"].response["data"].message ===
        "document link was expired"
      ) {
        setDocumentStatus(7);
      } else if (
        responceData["error"].response["data"].message ===
        "document Already Signed!"
      ) {
        setDocumentStatus(8);
      } else {
        toast.error(responceData["error"].response["data"].message, {
          delay,
          autoClose: autoCloseTimer,
          onClose: () => setLoading(false),
        });
      }
    }
  };

  const showPreview = async () => {
    setLoadingPreview(true);
    const responceData = await resolve(
      axios
        .post(`${baseURL}/contract/document/email/sign/`, {
          recpt_id: recptId,
          final: true,
        })
        .then((res) => res.data)
    );

    if (responceData.data) {
      setLoadingPreview(false);
      toast.success(t("form.Preview loaded successfully"));
      let doc_info = responceData.data.doc_info;
      setSasToken(responceData.data.sas_token);
      setDocUrl(doc_info[0].converted_image_url);
    }

    if (responceData["error"]) {
      toast.success(t("form.Preview loading failed"));
      setLoadingPreview(false);
    }
  };

  const handleCheckStatus = async () => {
    const res = await resolve(
      axios.get(`${baseURL}/contract/workflow/sign/status/${documentId}`)
    );
    if (res.data) {
      setRecipientList(res.data["data"].data);
    }

    if (res.error) {
      setRecipientList([]);
    }
    setSigneeInfoModelOpen(true);
  };

  const addSealAndSign = (targetName) => {
    if (targetName === "signature") {
      setSrc("");
      setCanvasType("signature");
      setIsSignModalOpen(true);
    } else if (targetName === "stamp") {
      setSrc("");
      setCanvasType("stamp");
      setCompanyName("");
      setIsSignModalOpen(true);
    } else if (targetName === "seal") {
      setSrc("");
      setCanvasType("seal");
    } else if (targetName === "photo") {
      setCanvasType("photo");
      setIsSignModalOpen(true);
      setShowWebcam(true);
      navigator.mediaDevices.getUserMedia({ video: true }).then((s) => {
        setStream(s);
        videoRef.current.srcObject = s;
      });
    }
  };

  useEffect(() => {
    let showPreviewBtn = signingInfo.length > 0 ? true : false;
    signingInfo.map((item) => {
      if (item.is_sign === 0) {
        showPreviewBtn = false;
      }
    });
    setTimeout(() => {
      setIsShowPreviewBtn(showPreviewBtn);
    }, 2000);
  }, [signingInfo]);

  useEffect(() => {
    if (imgSrc) {
      let htmlTag = document.getElementById("photograph_box_inner");
      htmlTag.innerHTML = `<img src=${imgSrc} alt="upload photo" />`;

      // update signing info
      let oldSigningInfo = [...signingInfo];
      let newSigningInfo = oldSigningInfo.map((item) => {
        if (item.type === "PHOTOGRAPH") {
          item.is_sign = 1;
        }
        return item;
      });
      setSigningInfo(newSigningInfo);
    }
  }, [imgSrc]);

  useEffect(() => {
    if (src) {
      if (canvasType == "stamp" || canvasType == "signature") {
        let element = document.querySelector("#stamp_box_inner");

        let text = `<div style="border:2px dashed #444484; height:160px; padding:5px;margin-bottom:5px">
                <span style="color:#444484">For ${userInfo.company_name}</span>
                <div style="display:flex; flex-direction:column; align-items:flex-end">
                <img src=${src} style="width:200px; height:80px"/>
                <h4 style="color:#444484">Authorised signatory</h4>
                </div>
                </div>`;

        if (element) {
          element.innerHTML = text;
        }

        // update signing info
        let oldSigningInfo = [...signingInfo];
        let newSigningInfo = oldSigningInfo.map((item) => {
          if (item.type === "STAMPPAD") {
            item.is_sign = 1;
          }
          return item;
        });
        setSigningInfo(newSigningInfo);
      }

      if (canvasType == "signature" || canvasType == "stamp") {
        let htmlTag = document.getElementById("signature_box_inner");
        if (htmlTag) {
          htmlTag.innerHTML = `<img src=${src} alt="signature" /><p>Name​: ${userInfo.name}</p>`;
        }

        // update signing info
        let oldSigningInfo = [...signingInfo];
        let newSigningInfo = oldSigningInfo.map((item) => {
          if (item.type === "SIGNATURE") {
            item.is_sign = 1;
          }
          return item;
        });
        setSigningInfo(newSigningInfo);
      }
    } else if (canvasType == "seal" && userInfo.company_name) {
      let htmlTag = document.getElementById("seal_box_inner");
      let currentDateTime = new Date();
      let text = `
            <div className="seal_container">
                <div className="seal_dotted_box">
                    <div className="seal_circular_box">
                        <div className="seal_circular_inner_box">
                            <h2>${userInfo.company_name}</h2>
                        </div>
                    </div>
                </div>
            </div>
            <p>Name​: ${userInfo.name}</p>
            <p>Title: ${userInfo.title_name}</p>
            <p>Timestamp: ${format(
              currentDateTime,
              "ccc, dd MMM yyyy, HH:mm"
            )}</p>
            <p>Document ID: ${recptId}</p>
            <p>IP address: ${
              ip ? ip : '<span class="ip_loader"></span>'
            }</p>           
            `;
      if (htmlTag) {
        htmlTag.innerHTML = text;
      }

      // update signing info
      let oldSigningInfo = [...signingInfo];
      let newSigningInfo = oldSigningInfo.map((item) => {
        if (item.type === "SEAL") {
          item.is_sign = 1;
        }
        return item;
      });
      setSigningInfo(newSigningInfo);
    }
  }, [src, canvasType]);

  useEffect(() => {
    getDocumentInfoApi(recptId);
    getUserIpAddress();
  }, []);

  const handleIframeMessage = (event) => {
    // Check if the message received is the content of the iframe
    if (
      event.origin == process.env.REACT_APP_BACKEND_BASE_URL &&
      event.data.requestFrom === "BackEnd"
    ) {
      if (event.data.status === "200") {
        setIsShowPreviewBtn(true);
        setIsShowRejectBtn(false);
        setIsShowBackToDashboardBtn(true);
      } else {
        setIsShowPreviewBtn(false);
        setIsShowRejectBtn(true);
        setIsShowBackToDashboardBtn(true);
        setIsShowReTryBtn(true);
      }
    }
  };

  // Attach the message event listener to the window
  window.addEventListener("message", handleIframeMessage);

  return (
    <>
      <div
        className="mb-3"
        style={{ position: "absolute", top: 0, right: "15px" }}
      >
        <span className="text-base font-bold mr-2">
          {t("common.Language")} :
        </span>
        <select
          className="border-2 border-primary rounded-lg outline-none p-0.5 pl-2"
          onChange={(e) => {
            i18n.changeLanguage(e.target.value);
            setLanguage(e.target.value);
          }}
          value={language}
        >
          {Object.keys(langObj).map((lng) => (
            <option key={lng} value={lng}>
              {langObj[lng].nativeName}
            </option>
          ))}
        </select>
      </div>
      {(signingInfo.length > 0 ||
        typeOfSignature == "Aadhaar_Digital_Signature") &&
      loading == false ? (
        <div className="span_man">
          <div className="row_sig">
            <div className="col-me-8 pdf_left_section">
              <div className="border_us">
                {docUrl && (
                  <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.13.216/build/pdf.worker.min.js">
                    {loadingPreview ? (
                      <LoadingScreen />
                    ) : (
                      <Viewer fileUrl={`${docUrl}?${sasToken}`} />
                    )}
                  </Worker>
                )}
              </div>
            </div>
            <div className="col-me-4 mt-5 pdf_right_section">
              {loadingApi ? (
                <LoadingScreen />
              ) : (
                <div className="border_us">
                  <div className="mb-20">
                    {typeOfSignature === "Aadhaar_Digital_Signature" &&
                      signingInfo.length > 0 && (
                        <div style={{ display: "flex", marginBottom: "10px" }}>
                          <button
                            style={{
                              marginRight: 10,
                              border: "none",
                              color: "gray",
                              background: "transparent",
                              fontWeight: 500,
                              fontSize: 16,
                              cursor: "pointer",
                              padding: "5px",
                              borderBottom:
                                signingTab == "sign"
                                  ? "2px solid black"
                                  : "none",
                            }}
                            onClick={() => {
                              setSigningTab("sign");
                            }}
                          >
                            {t("form.Seal or Sign")}
                          </button>
                          <button
                            style={{
                              border: "none",
                              marginRight: 10,
                              color: "gray",
                              background: "transparent",
                              fontWeight: 500,
                              fontSize: 16,
                              cursor: "pointer",
                              padding: "5px",
                              borderBottom:
                                signingTab == "digitalSign"
                                  ? "2px solid black"
                                  : "none",
                            }}
                            onClick={() => {
                              setSigningTab("digitalSign");
                            }}
                          >
                            {t("form.Aadhaar Digital Signature")}
                          </button>
                        </div>
                      )}

                    {signingInfo.length > 0 && signingTab == "sign" ? (
                      signingInfo.map((itemInfo, index) => {
                        return itemInfo.type === "SIGNATURE" ? (
                          <div key={index} className="signature_box ">
                            <div className="bordr_us3">
                              <h4>
                                {t("form.Signature")}
                                <button
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    addSealAndSign("signature");
                                  }}
                                  className="btn btn_theme btn-sm"
                                >
                                  {t("form.Add")}
                                </button>
                              </h4>
                              <div id="signature_box_inner"></div>
                            </div>
                          </div>
                        ) : itemInfo.type === "SEAL" ? (
                          <div key={index} className="seal_box ">
                            <div className="bordr_us3">
                              <h4>
                                {t("form.Seals")}
                                <button
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    addSealAndSign("seal");
                                  }}
                                  className="btn btn_theme btn-sm"
                                >
                                  {t("form.Add")}{" "}
                                </button>
                              </h4>
                              <div id="seal_box_inner"></div>
                            </div>
                          </div>
                        ) : itemInfo.type === "PHOTOGRAPH" ? (
                          <div key={index} className="photograph_box ">
                            <div className="bordr_us3">
                              <h4>
                                {t("form.Photo")}
                                <button
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    addSealAndSign("photo");
                                  }}
                                  className="btn btn_theme btn-sm"
                                >
                                  {t("form.Add")}{" "}
                                </button>
                              </h4>
                              <div id="photograph_box_inner"></div>
                            </div>
                          </div>
                        ) : itemInfo.type === "STAMPPAD" ? (
                          <div key={index} className="stamp_box ">
                            <div id="stamp_box_inner"></div>
                            <button
                              onClick={(e) => {
                                e.stopPropagation();
                                addSealAndSign("stamp");
                              }}
                              className="bg-secondary px-1  py-1 rounded-md flex justify-between text-white"
                            >
                              {t("form.Add Stamp")}
                            </button>
                          </div>
                        ) : (
                          <React.Fragment key={index}></React.Fragment>
                        );
                      })
                    ) : (
                      <></>
                    )}

                    {typeOfSignature === "Aadhaar_Digital_Signature" &&
                    signingTab == "digitalSign" ? (
                      <>
                        <iframe
                          width="100%"
                          height="500px"
                          ref={iframeRef}
                          src={`/aadhar-signature/${recptId}`}
                        />
                      </>
                    ) : (
                      <></>
                    )}
                  </div>
                  {typeOfSignature === "Approver" && (
                    <button
                      className="btn pb-1 pt-0.5 pl-6 pr-6 text_c font-bold rounded-lg btn_theme3 w-full mb-5"
                      onClick={(e) => {
                        handleCheckStatus();
                      }}
                    >
                      {t("form.Check status of Workflow")}
                    </button>
                  )}
                  {typeOfSignature !== "Reviewer" &&
                    typeOfSignature !== "Approver" && (
                      <button
                        className="btn pb-1 pt-0.5 pl-6 pr-6 text_c font-bold rounded-lg btn_theme3 w-full mb-5"
                        onClick={(e) => {
                          handleCheckStatus();
                        }}
                      >
                        {t("form.Check Status of Other Signatories")}
                      </button>
                    )}

                  {signingInfo.length > 0 && signingTab != "Reviewer" && (
                    <>
                      {isShowPreviewBtn && (
                        <div className="mb-4">
                          <button
                            className="btn pb-1 pt-0.5 pl-6 pr-6 text_c font-bold rounded-lg btn_theme3 w-full"
                            onClick={(e) => {
                              showPreview();
                            }}
                          >
                            {t("form.See Preview")}
                          </button>
                        </div>
                      )}
                    </>
                  )}

                  {isShowBackToDashboardBtn && (
                    <button
                      onClick={(e) => {
                        e.stopPropagation();
                        setActiveLink("Home");
                        navigate("/dashboard");
                      }}
                      className="btn btn_theme btn-sm mt-5 mr-5"
                    >
                      {t("form.Back to Quoqo Fusion")}
                    </button>
                  )}
                  {/* <button
                    onClick={(e) => {
                      e.stopPropagation();
                      scrollToDiv();
                    }}
                    className="btn btn_theme btn-sm mt-5 mr-5 mobile-fixed-button"
                  >
                    {t("form.Tap to sign")}
                  </button> */}

                  {isShowReTryBtn && (
                    <button
                      onClick={(e) => {
                        e.stopPropagation();
                        iframeRef.current.src = `/aadhar-signature/${recptId}`;
                        setIsShowReTryBtn(false);
                      }}
                      className="btn btn_theme btn-sm mt-5 "
                    >
                      {t("form.Retry")}
                    </button>
                  )}

                  {isShowCreateAccountBtn && (
                    <div className="check_b  mb-3">
                      <input
                        type="checkbox"
                        onClick={(e) => {
                          e.stopPropagation();
                          if (e.target.checked) {
                            setCreateAccount(true);
                          } else {
                            setCreateAccount(false);
                          }
                        }}
                      />{" "}
                      {t("form.Click here to create account")}
                      <span></span>
                    </div>
                  )}

                  {typeOfSignature !== "Aadhaar_Digital_Signature" && (
                    <div>
                      <div className="check_b radioo mb-3">
                        <input
                          type="radio"
                          name="accept_reject_btn"
                          checked={acceptReject === "accept"}
                          onChange={() => setAcceptReject("accept")}
                        />
                        {typeOfSignature === "Approver"
                          ? t(
                              "form.By Clicking the Approve button I consent that I agree to the term and conditions stated herein"
                            )
                          : typeOfSignature === "Reviewer"
                          ? t(
                              "form.By clicking the Submit Review button, I consent that I agree to the terms and conditionsstated herein"
                            )
                          : t(
                              "form.By submitting the signature I consent that I agree to the term and conditions stated herein"
                            )}
                        <span></span>
                      </div>
                      <div className="mb-4">
                        <p className="error_msg">{formError.accept}</p>
                        {typeOfSignature === "Reviewer" ? (
                          <button
                            className="btn pb-1 pt-0.5 pl-6 pr-6 text_c font-bold rounded-lg btn_theme3 w-full"
                            onClick={() => handleAcceptReject("review")}
                          >
                            {t("form.Reviewed")}
                          </button>
                        ) : typeOfSignature === "Approver" ? (
                          <button
                            className="btn pb-1 pt-0.5 pl-6 pr-6 text_c font-bold rounded-lg btn_theme3 w-full"
                            onClick={() => handleAcceptReject("accept")}
                          >
                            {t("form.Approve")}
                          </button>
                        ) : (
                          <button
                            className="btn pb-1 pt-0.5 pl-6 pr-6 text_c font-bold rounded-lg btn_theme3 w-full"
                            onClick={() => handleAcceptReject("accept")}
                          >
                            {t("form.Accept")}
                          </button>
                        )}
                      </div>
                    </div>
                  )}

                  {isShowRejectBtn && (
                    <>
                      <div className="check_b radioo mb-3">
                        <input
                          type="radio"
                          name="accept_reject_btn"
                          checked={acceptReject == "reject" ? true : false}
                          onChange={(e) => {
                            setAcceptReject("reject");
                          }}
                        />
                        {t(
                          "form.I reject this agreement due to following reasons"
                        )}
                        <span></span>
                      </div>
                      <div className="mb-3">
                        <textarea
                          onChange={(e) => setRejectReason(e.target.value)}
                          className="border-2 border-primary rounded-lg outline-none p-0.5 pl-2  w-full"
                          rows={4}
                          placeholder={t("form.Reason for rejection")}
                          value={rejectReason}
                          style={{ resize: "none" }}
                        ></textarea>
                      </div>
                      <div className="mb-4">
                        <p className="error_msg">{formError.reject}</p>
                        <button
                          // disabled={acceptReject == 'reject' ? false : true}
                          onClick={(e) => {
                            handleAcceptReject("reject");
                          }}
                          className="btn pb-1 pt-0.5 pl-6 pr-6 text_c font-bold rounded-lg btn_theme3 w-full"
                        >
                          {t("form.Reject")}
                        </button>
                      </div>
                    </>
                  )}
                </div>
              )}

              <div style={{ position: "absolute", left: "10px", top: 0 }}>
                <button
                  onClick={(e) => {
                    e.stopPropagation();
                    setActiveLink("Home");
                    navigate("/dashboard");
                  }}
                  className="btn btn_theme btn-sm mt-5"
                >
                  {t("form.Back to Quoqo Fusion")}
                </button>
              </div>
            </div>
          </div>
        </div>
      ) : loading ? (
        <div className="flex justify-center items-center h-screen">
          <div className="text-center">
            <h1 className="text-2xl font-bold">{t("form.Document Loading")}</h1>
            <p className="text-lg">{t("form.Please wait...")}</p>
          </div>
        </div>
      ) : documentStatus === 5 ? (
        <div className="flex justify-center items-center h-screen">
          <div className="text-center">
            <h1 className="text-2xl font-bold">{t("form.Document Cancel")}</h1>
            <p className="text-lg">{t("form.Please check your document")}</p>
            <button
              onClick={(e) => {
                e.stopPropagation();
                setActiveLink("Home");
                navigate("/dashboard");
              }}
              className="btn btn_theme btn-sm mt-5"
            >
              {t("form.Back to Quoqo Fusion")}
            </button>
          </div>
        </div>
      ) : documentStatus === 7 ? (
        <div className="flex justify-center items-center h-screen">
          <div className="text-center">
            <h1 className="text-2xl font-bold">{t("form.Invalid Document")}</h1>
            <p className="text-lg">{t("form.Document link was expired...")}</p>
            <button
              onClick={(e) => {
                e.stopPropagation();
                setActiveLink("Home");
                navigate("/dashboard");
              }}
              className="btn btn_theme btn-sm mt-5"
            >
              {t("form.Back to Quoqo Fusion")}
            </button>
          </div>
        </div>
      ) : documentStatus === 8 ? (
        <div className="flex justify-center items-center h-screen">
          <div className="text-center">
            <h1 className="text-2xl font-bold">{t("form.Already Signed")}</h1>
            <p className="text-lg">
              {t("form.You have already signed the document.")}
            </p>
            <button
              onClick={(e) => {
                e.stopPropagation();
                setActiveLink("Home");
                navigate("/dashboard");
              }}
              className="btn btn_theme btn-sm mt-5"
            >
              {t("form.Back to Quoqo Fusion")}
            </button>
          </div>
        </div>
      ) : typeOfSignature != "Aadhaar_Digital_Signature" ? (
        <div className="flex justify-center items-center h-screen">
          <div className="text-center">
            <h1 className="text-2xl font-bold">
              {t("form.No Signing Info Found")}
            </h1>
            <p className="text-lg">{t("form.Please check your document")}</p>
            <button
              onClick={(e) => {
                e.stopPropagation();
                setActiveLink("Home");
                navigate("/dashboard");
              }}
              className="btn btn_theme btn-sm mt-5"
            >
              {t("form.Back to Quoqo Fusion")}
            </button>
          </div>
        </div>
      ) : (
        <>
          <div className="flex justify-center items-center h-screen">
            <div className="text-center">
              <h1 className="text-2xl font-bold">
                {t("form.Invalid Document")}
              </h1>
              <button
                onClick={(e) => {
                  e.stopPropagation();
                  setActiveLink("Home");
                  navigate("/dashboard");
                }}
                className="btn btn_theme btn-sm mt-5"
              >
                {t("form.Back to Quoqo Fusion")}
              </button>
            </div>
          </div>
        </>
      )}
      <div id="signdiv">
        <SignatureModal
          isSignModalOpen={isSignModalOpen}
          setIsSignModalOpen={setIsSignModalOpen}
          showWebcam={showWebcam}
          // digitalSign={digitalSign}
          // setDigitalSign={setDigitalSign}
          stream={stream}
          setStream={setStream}
          setShowWebcam={setShowWebcam}
          // src={src}
          videoRef={videoRef}
          imgSrc={imgSrc}
          setImgSrc={setImgSrc}
          setSrc={setSrc}
          canvasType={canvasType}
          setCanvasType={setCanvasType}
          setCompanyName={setCompanyName}
          companyName={companyName}
          recptId={recptId}
        />

        <SigneeInfoModal
          signeeInfoModelOpen={signeeInfoModelOpen}
          setSigneeInfoModelOpen={setSigneeInfoModelOpen}
          recipientList={recipientList}
          screenName="finalSignatureScreen"
        />
      </div>
    </>
  );
};

export default WorkflowToSign;
