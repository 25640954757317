import React, { useContext, useEffect, useState } from "react";
import "./homepagelegacy.css";
import PieChart from "../demographicslegacy/PieChartlegacy";
import StackedBarChart from "../demographicslegacy/StackedBarChartlegacy";
import CircularProgress from "../demographicslegacy/CircularProgresslegacy";
import AuthContext from "../../context/AuthContext";
import ActivityLog from "../demographicslegacy/ActivityLoglegacy";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import AppContext from "../../context/AppContext";

const HomePagelegacy = () => {
  
  const { user } = useContext(AuthContext);
  const navigate = useNavigate();
  const { activeLink, setActiveLink, adminId, activePrevLink } =
    useContext(AppContext);
  const [isReviewer, setisReviewer] = useState(user.user_type === "reviewer");
  const { t } = useTranslation();

  useEffect(() => {
    let hubspot = document.querySelector("#hubspot-messages-iframe-container");
    if (hubspot) {
      hubspot.style.visibility = "hidden";
    }
    return () => {
      if (hubspot) {
        hubspot.style.visibility = "visible";
      }
    };
  });
  return (
    <div className="demographics-container">
      <div className="main_head">
        <h1 className="text-primary text-4xl font-bold">
          {t("homePage.title")}
        </h1>
      </div>
      <div className="nav_tab mb-4">
        <ul className="ul_set">
          <li
            style={{ cursor: "pointer", paddingRight: "5px" }}
            className={activePrevLink === "Dashboard" ? "active" : ""}
            onClick={(e) => {
              e.stopPropagation();
              setActiveLink("Home");
              navigate("/dashboard");
            }}
          >
            {t("allModule.Document Status")}
          </li>
          <li
            style={{ paddingLeft: "5px" }}
            className={activePrevLink === "homepagelegacy" ? "active" : ""}
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
            }}
          >
            <a href="/homepagelegacy">{t("allModule.Document Repository")}</a>
          </li>
        </ul>
      </div>
      {isReviewer ? (
        <StackedBarChart />
      ) : (
        <>
          {user.user_type === "super_admin" ? (
            <>
              <div className="grid grid-cols-2 gap-x-4">
                <CircularProgress user={user} />
                <PieChart />
              </div>
              <div className="grid grid-cols-2 gap-x-4">
                <StackedBarChart />
                <ActivityLog />
              </div>
            </>
          ) : user.user_type == "admin" ? (
            <>
              <div className="grid grid-cols-2 gap-x-4 ">
                <CircularProgress user={user} />
                <ActivityLog />
              </div>
              <StackedBarChart />
            </>
          ) : (
            <>
              <div className="grid grid-cols-2 gap-x-4 dash_des">
                <CircularProgress user={user} />
              </div>
              <StackedBarChart />
            </>
          )}
        </>
      )}
    </div>
  );
};

export default HomePagelegacy;
