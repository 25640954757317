import React from "react";
import Modal from "react-modal";
import "./modal.css";
import { useTranslation } from "react-i18next";

const customStyles = {
  content: {
    top: "40%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "600px",
    maxHeight: "500px",
    borderRadius: "11px",
  },
  overlay: {
    backgroundColor: "rgba(64, 8, 53, 0.63)",
  },
};

Modal.setAppElement("#root");
const DocPasswordModal = ({ modalIsOpen, setIsOpen }) => {
  const { t } = useTranslation();
  return (
    <div className="">
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={() => setIsOpen(false)}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <div className="important-padding">
          <h1 className="text-primary font-semibold text-base text-center">
            {t("modal.Document is password protected.")} <br />
            {t(
              "modal.Your password is first 4 letters of your email + first 4 letters of your organization name (All in Uppercase)"
            )}
          </h1>
          <button
            className="bg-primary font-semibold text-white p-1 mt-3 rounded-lg w-2/3 mx-auto block"
            onClick={() => setIsOpen(false)}
          >
            {t("modal.Accept")}
          </button>
        </div>
      </Modal>
    </div>
  );
};

export default DocPasswordModal;
