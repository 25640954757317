import { createContext, useEffect, useState } from 'react';
import useAxios from '../hooks/useAxios';
import { resolve } from '../api/resolve';
import { useNavigate } from 'react-router-dom';

const AppContextlegacy = createContext();

export default AppContextlegacy;

export const AppProvider = ({ children }) => {
  const api = useAxios();
  const navigate = useNavigate();
  const [activeLink, setActiveLink] = useState(localStorage.getItem('activeLink') || 'Home');
  // const [activeLink, setActiveLink] = useState('Training');
  const [companiesList, setCompaniesList] = useState([]);
  const [contractsHeading, setContractsHeading] = useState('Search');
  const [isRepoList, setIsRepoList] = useState(false);
  const [repoName, setRepoName] = useState('');
  const [repoID, setRepoID] = useState(null);
  const [sasToken, setSasToken] = useState({
    sas_token: '',
    expiry: '',
  });
  const [highlightToken, setHighlightToken] = useState({
    sas_token: '',
    expiry: '',
  });
  const [notificationCount, setNotificationCount] = useState(0);
  const [totalNotificationCount, setTotalNotificationCount] = useState(0);
  const [routedSearchID, setRoutedSearchID] = useState(null);
  const [contractTypes, setContractTypes] = useState([]);
  const [selectedContractType, setSelectedContractType] = useState(null);
  const [reviewSelection, setReviewSelection] = useState({
    file_url: '',
    doc_name: '',
    converted_file_url: '',
    id: '',
    contract_clause: '',
  });
  const [modelID, setModelID] = useState(null);
  let [language, setLanguage] = useState(() => localStorage.getItem('i18nextLng') ? localStorage.getItem('i18nextLng') : 'en');
  const langObj = {
    en: { nativeName: 'English' },
    fr: { nativeName: 'French ' }
  };

  const getCompanies = async () => {
    const res = await resolve(api.get(`/legacy_company/all/`).then((res) => res.data));
    if (res.data) {
      const { results } = res.data;
      const filterData = [];
      if (results.length > 0) {
        results.forEach((result) => {
          filterData.push({
            value: result.name,
            label: result.name,
            id: result.id,
            company_name: result.name,
          });
        });
      }
      setCompaniesList([...filterData]);
    }
  };

  const getSasToken = async () => {
    const res = await resolve(
      api.get(`/legacy_contract/get_sas_token/`).then((res) => res.data)
    );
    if (res.data) {
      const { sas_token, expiry } = res.data;
      setSasToken({ sas_token, expiry });
    }
  };
  const getHighlightSasToken = async () => {
    const res = await resolve(
      api.get(`/legacy_contract/get_sas_token_highlight/`).then((res) => res.data)
    );
    if (res.data) {
      const { sas_token, expiry } = res.data;
      setHighlightToken({ sas_token, expiry });
    }
  };

  const getNotificationsCount = async () => {
    const res = await resolve(
      api.get(`legacy_notification/update_is_seen/0`).then((res) => res.data)
    );
    if (res.data) {
      const { notif_count, total_notif_count } = res.data;
      setNotificationCount(notif_count);
      setTotalNotificationCount(total_notif_count);
    }
  };

  useEffect(() => {
    // console.log("activeLink",activeLink);
    // if(activeLink =="Home"){
    //   navigate('/dashboard');  
    // }else 
    if(activeLink ==="Search"){
      navigate('/search');
    }else if(activeLink ==="AddContract"){
      navigate('/add-contract');
    }else if(activeLink ==="Account"){
      navigate('/account');
    }else if(activeLink ==="Help"){
      navigate('/help');
    }else if(activeLink ==="Notifications"){
      navigate('/notifications');
    }else if(activeLink ==="DetailedReview"){
      navigate('/detailed-review');
    }else if(activeLink ==="TrainingResults"){
      navigate('/training-results');
    }else if(activeLink ==="ContractForm"){
      navigate('/contract-add');
    }else if(activeLink ==="ContractFormEdit"){
      navigate('/contract-edit');
    }else if(activeLink ==="Review"){
      navigate('/review');
    }else if(activeLink ==="Training"){
      navigate('/training');
    }else if(activeLink ==="Users"){
      navigate('/legacy_users');
    }else if(activeLink ==="Companieslegacy"){
      navigate('/legacy_companies');
    }else if(activeLink ==="Adminslegacy"){
      navigate('/legacy_admins');
    }else if(activeLink ==="Reviewerslegacy"){
      navigate('/legacy_reviewers');
    }else if(activeLink ==="NotFound"){
      navigate('/not-found');
    }else if(activeLink ==="CompanyFormlegacy"){
      navigate('/legacy_company-add');
    }else if(activeLink ==="CompanyFormEditlegacy"){
      navigate('/legacy_company-edit');
    }else if(activeLink ==="AdminFormlegacy"){
      navigate('/legacy_admin-add');
    }else if(activeLink ==="AdminFormEditlegacy"){
      navigate('/legacy_admin-edit');
    }else if(activeLink ==="UserFormlegacy"){
      navigate('/legacy_user-add');
    }else if(activeLink ==="UserFormEditlegacy"){
      navigate('/legacy_user-edit');
    }else if(activeLink ==="ReviewerFormlegacy"){
      navigate('/legacy_reviewer-add');
    }else if(activeLink ==="ReviewerFormEditlegacy"){
      navigate('/reviewer-add');
    }else if(activeLink ==="SSOSettinglegacy"){
      navigate('/legacy_sso-setting');
    }else if(activeLink ==="Licencing"){
      navigate('/legacy_licencing');
    }
    localStorage.setItem('activeLink', activeLink);
  }, [activeLink]);

  useEffect(() => {
    getCompanies();
    getSasToken();
  }, []);
  const contextData = {
    activeLink,
    setActiveLink,
    companiesList,
    getCompanies,
    repoName,
    setRepoName,
    repoID,
    setRepoID,
    contractsHeading,
    setContractsHeading,
    isRepoList,
    setIsRepoList,
    sasToken,
    setSasToken,
    getSasToken,
    notificationCount,
    getNotificationsCount,
    setRoutedSearchID,
    routedSearchID,
    getHighlightSasToken,
    setHighlightToken,
    highlightToken,
    setNotificationCount,
    totalNotificationCount,
    contractTypes,
    setContractTypes,
    selectedContractType,
    setSelectedContractType,
    reviewSelection,
    setReviewSelection,
    modelID,
    setModelID,

    language,
    setLanguage,
    langObj
  };

  return (
    <AppContextlegacy.Provider value={contextData}>{children}</AppContextlegacy.Provider>
  );
};
