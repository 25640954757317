import React, { useEffect, useState, useContext } from "react";
import Search from "../../assets/icons/Search.svg";
import CloseIcon from "../../assets/icons/Close.svg";
import { userTableColumns } from "../../constants/constants";
import useAxios from "../../hooks/useAxios";
import { resolve } from "../../api/resolve";
import AddButton from "../../shared/AddButton";
import UserTable from "./UserTable";
import { useTranslation } from "react-i18next";
import PermissionContext from "../../context/PermissionContext";

const AdminUser = () => {
  const api = useAxios();
  const { t } = useTranslation();

  // pending doc related states
  const [userList, setUserList] = useState([]);
  const [userSearchText, setUserSearchText] = useState("");
  const [userCurrentPage, setUserCurrentPage] = useState(1);
  const [userPageLimit, setUserPageLimit] = useState(5);
  const [userNextBtnLimit, setUserNextBtnLimit] = useState(0);
  const [userCount, setUserCount] = useState(0);
  const [errMsgUser, setErrMsgUser] = useState("");
  const [showUserClearIcon, setShowUserClearIcon] = useState(false);
  const [sortingOrder, setSortingOrder] = useState("desc");
  const [sortColumn, setSortColumn] = useState("id");
  const { permissionList } = useContext(PermissionContext);

  const getUserList = async (clearText) => {
    const res = await resolve(
      api
        .get(
          `user/list/?ordering=${sortColumn}&sortingOrder=${sortingOrder}&search=${
            clearText ? "" : userSearchText
          }&page=${userCurrentPage}&limit=${userPageLimit}`
        )
        .then((res) => res.data)
    );
    if (res.data) {
      const { data } = res;
      const { results, count } = data["data"];
      const filterData = [];
      if (results.length > 0) {
        setUserCount(count);
        results.forEach((result) => {
          const userObj = {
            id: result.id,
            first_name: result.first_name,
            email: result.email,
            confirm_email: result.email,
            phone: result.phone,
            designation: result.designation,
            position: result.position,
            company_id: result.company_id,
            company_name: result.company_name,
            dateCreated: result.created_at,
            status: result.is_active_user ? "Active" : "Inactive",
          };
          filterData.push(userObj);
        });
      } else {
        if (userCurrentPage === 1)
          setErrMsgUser(t("allModule.No user added yet"));
        else setErrMsgUser(t("allModule.No more user to view"));
      }
      setUserList([...filterData]);
      setUserNextBtnLimit(Math.ceil(count / userPageLimit));
    } else {
      setUserNextBtnLimit(0);
    }
  };

  useEffect(() => {
    getUserList();
  }, [sortColumn, sortingOrder, userCurrentPage, userPageLimit]);

  return (
    <>
      <div className="text-primary">
        <div className="main_head">
          <h1 className="text-4xl font-bold">{t("allModule.Users")}</h1>
        </div>
        {permissionList["multiple_user_on"] == false ? (
          <div className="flex justify-center items-center h-96 flex-column ">
            <h3 className="text-2xl font-bold text_c">
              {t("allModule.You dont have permission to access this page")}
            </h3>
          </div>
        ) : (
          <div className="tab_main">
            <div className={`tab_us show`}>
              <div className="row justify-between">
                <div className="col-md-5 flex_contact">
                  <form>
                    <div className="flex mt-3  w-full">
                      <div className="flex justify-between items-center border-2 border-primary rounded-md rounded-tl-lg rounded-bl-lg bg-inputBackground px-2 mr-2 w-full ">
                        <input
                          placeholder={t("allModule.Search for User")}
                          className="px-1 py-1 focus:outline-none bg-transparent w-11/12"
                          value={userSearchText}
                          onChange={(e) => {
                            setUserSearchText(e.target.value);
                            setShowUserClearIcon(true);
                            if (!e.target.value.trim()) {
                              setShowUserClearIcon(false);
                            }
                          }}
                        />
                        {showUserClearIcon && (
                          <img
                            src={CloseIcon}
                            width={14}
                            className="cursor-pointer"
                            onClick={() => {
                              setUserSearchText("");
                              getUserList(true);
                              setShowUserClearIcon(false);
                            }}
                          />
                        )}
                      </div>
                      <button
                        type="submit"
                        className="flex btn btn_theme2 w_125 items-center justify-between"
                        onClick={(e) => {
                          e.preventDefault();
                          setUserCurrentPage(1);
                          getUserList();
                        }}
                      >
                        <img src={Search} style={{ width: "1rem" }} />
                        <span className="font-semibold ml-1">
                          {t("allModule.Search")}
                        </span>
                      </button>
                    </div>
                  </form>
                </div>
                <div className="col-md-5">
                  <div className="flex justify-end mt-3">
                    <AddButton
                      text={t("allModule.Add New User")}
                      currentForm="AdminUserForm"
                    />
                  </div>
                </div>
              </div>
              <div className="mt-3 rounded-lg">
                {userList.length < 1 && (
                  <p className="text-red-500 text-sm font-medium">
                    {errMsgUser}
                  </p>
                )}
              </div>
              <UserTable
                tableColumns={userTableColumns}
                getListData={getUserList}
                tableData={userList}
                showActionBtn
                nextBtnLimit={userNextBtnLimit}
                currentPage={userCurrentPage}
                setCurrentPage={setUserCurrentPage}
                itemCount={userCount}
                setPageLimit={setUserPageLimit}
                showPageLimit={true}
                sortingOrder={sortingOrder}
                setSortingOrder={setSortingOrder}
                setSortColumn={setSortColumn}
              />
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default AdminUser;
