import React, { useState, useContext, useEffect } from "react";
import useAxios from "../../hooks/useAxios";
import { resolve } from "../../api/resolve";
import { adminSectionTableColumns } from "../../constants/constants";
import CloseIcon from "../../assets/icons/Close.svg";
import Search from "../../assets/icons/Search.svg";
import SectionListTable from "./SectionListTable";
import AddButton from "../../shared/AddButton";
import { use } from "i18next";

const ReviewSection = () => {
  const api = useAxios();
  // pending doc related states
  const [sectionList, setSectionList] = useState([]);
  const [sectionSearchText, setSectionListSearchText] = useState(
    localStorage.getItem("searchText") ? localStorage.getItem("searchText") : ""
  );
  const [sectionCurrentPage, setSectionListCurrentPage] = useState(1);
  const [sectionPageLimit, setSectionListPageLimit] = useState(10);
  const [sectionNextBtnLimit, setSectionListNextBtnLimit] = useState(0);
  const [sectionCount, setSectionListCount] = useState(0);
  const [errMsgSectionList, setErrMsgSectionList] = useState("");
  const [showSectionListClearIcon, setShowSectionListClearIcon] = useState(
    localStorage.getItem("searchText") ? true : false
  );
  const [sortingOrder, setSortingOrder] = useState("desc");
  const [sortColumn, setSortColumn] = useState("id");

  const getSectionListList = async (clearText) => {
    const res = await resolve(
      api
        .get(
          `nda_report/diligence/section/list/?ordering=${sortColumn}&sortingOrder=${sortingOrder}&search=${
            clearText ? "" : sectionSearchText
          }&page=${sectionCurrentPage}&limit=${sectionPageLimit}`
        )
        .then((res) => res.data)
    );
    if (res.data) {
      const { data } = res;
      const { results, count } = data;
      const filterData = [];
      if (results.length > 0) {
        setSectionListCount(count);

        results.forEach((result) => {
          const sectionListObj = {
            id: result.id,
            section_name: result.section_name,
            SecCat: result.SecCat,
            created_at: result.created_at,
          };
          filterData.push(sectionListObj);
        });
      } else {
        if (sectionCurrentPage === 1)
          setErrMsgSectionList("No section added yet");
        else setErrMsgSectionList("No more section to view");
      }
      setSectionList([...filterData]);
      setSectionListNextBtnLimit(Math.ceil(data.count / sectionPageLimit));
    } else {
      setSectionListNextBtnLimit(0);
    }
  };

  useEffect(() => {
    getSectionListList();
  }, [sortColumn, sortingOrder, sectionCurrentPage, sectionPageLimit]);

  useEffect(() => {
    localStorage.setItem("searchText", sectionSearchText);
  }, [sectionSearchText]);

  return (
    <>
      <div className="text-primary">
        <div className="main_head">
          <h1 className="text-4xl font-bold">Review Section</h1>
        </div>
        <div className="tab_main">
          <div className={`tab_us show`}>
            <div className="row justify-between">
              <div className="col-md-5">
                <form>
                  <div className="flex mt-3  w-full">
                    <div className="flex justify-between items-center border-2 border-primary rounded-md rounded-tl-lg rounded-bl-lg bg-inputBackground px-2 mr-2 w-full ">
                      <input
                        placeholder="Search for Section"
                        className="px-1 py-1 focus:outline-none bg-transparent w-11/12"
                        value={sectionSearchText}
                        onChange={(e) => {
                          setSectionListSearchText(e.target.value);
                          setShowSectionListClearIcon(true);
                          if (!e.target.value.trim()) {
                            setShowSectionListClearIcon(false);
                          }
                        }}
                      />
                      {showSectionListClearIcon && (
                        <img
                          src={CloseIcon}
                          width={14}
                          className="cursor-pointer"
                          onClick={() => {
                            setSectionListCurrentPage(1);
                            setSectionListSearchText("");
                            getSectionListList(true);
                            setShowSectionListClearIcon(false);
                          }}
                        />
                      )}
                    </div>
                    <button
                      type="submit"
                      className="flex btn btn_theme2 w_125 items-center justify-between"
                      onClick={(e) => {
                        e.preventDefault();
                        setSectionListCurrentPage(1);
                        getSectionListList();
                      }}
                    >
                      <img src={Search} style={{ width: "1rem" }} />
                      <span className="font-semibold ml-1">Search</span>
                    </button>
                  </div>
                </form>
              </div>
              <div className="col-md-5">
                <div className="flex justify-end mt-3">
                  <AddButton text="Add New Section" currentForm="SectionForm" />
                </div>
              </div>
            </div>

            <div className="mt-3 rounded-lg">
              {sectionList.length < 1 && (
                <p className="text-red-500 text-sm font-medium">
                  {errMsgSectionList}
                </p>
              )}
            </div>
            <SectionListTable
              tableColumns={adminSectionTableColumns}
              getListData={getSectionListList}
              tableData={sectionList}
              showActionBtn
              nextBtnLimit={sectionNextBtnLimit}
              currentPage={sectionCurrentPage}
              setCurrentPage={setSectionListCurrentPage}
              itemCount={sectionCount}
              setPageLimit={setSectionListPageLimit}
              showPageLimit={true}
              sortingOrder={sortingOrder}
              setSortingOrder={setSortingOrder}
              setSortColumn={setSortColumn}
            />
          </div>
        </div>
      </div>
    </>
  );
};
export default ReviewSection;
