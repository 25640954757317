import axios from 'axios';
import jwt_decode from 'jwt-decode';
import dayjs from 'dayjs';
import { useContext } from 'react';
import AuthContext from '../context/AuthContext';
import { toast } from 'react-toastify';
import {getCookie} from '../utils/utils'

const baseURL = process.env.REACT_APP_BACKEND_BASE_URL;

const useAxios = () => {
  const csrftoken = getCookie('csrftoken');
  const { authTokens, setUser, setAuthTokens, logoutUser } =
  useContext(AuthContext);

  const axiosInstance = axios.create({
    baseURL,
    headers: {
      Authorization: `Bearer ${authTokens?.access}`,
      'X-CSRFToken': csrftoken,
    },
  });

  axiosInstance.interceptors.request.use(async (req) => {
    const user = jwt_decode(authTokens?.access);
    const isExpired = dayjs.unix(user?.exp).diff(dayjs()) < 25000;

    if (!isExpired) return req;
    try {
      const response = await axios.post(`${baseURL}/api/token/refresh/`, {
        refresh: authTokens.refresh,
      });
      let data = {
        refresh: authTokens.refresh,
        access: response.data.access,
      };
      const user = jwt_decode(data.access);
      localStorage.setItem('authTokens', JSON.stringify(data));

      setAuthTokens(data);
      setUser(jwt_decode(data.access));

      req.headers.Authorization = `Bearer ${data.access}`;
      return req;
    } catch (error) {
      /* let data;
      if (error && error.response) data = error.response.data;
      if (data?.detail === 'Subscription expired please contact system admin') {
        toast.error('Contract Expired');
      }else if (data?.code === 'ALREADY_LOGGED_IN') {
        toast.error('Logout from other device and try again');
      } */
      logoutUser();
    }
  });

  return axiosInstance;
};

export default useAxios;
