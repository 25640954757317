import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';
import AIIcon from '../../assets/icons/ai-icon.png';
import nvcaImage from '../../assets/icons/nvca_icon.svg';
import femaImage from '../../assets/icons/fema_icon.svg';
import taxImage from '../../assets/icons/tax_icon.svg';
import bankingImage from '../../assets/icons/banking_icon.svg';

const QbotsTab = () => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const [selectedCountry, setSelectedCountry] = useState(null);

    const handleCountrySelect = (selectedOption) => {
        setSelectedCountry(selectedOption);
    };

    const handleButtonClick = (link) => {
        switch (link) {
            case '/usa-link':
                window.open('https://nvca.q-bot.ai', '_blank');
                break;
            case '/india-link-1':
                window.open('https://fema.q-bot.ai', '_blank');
                break;
            case '/india-link-2':
                window.open('https://tax.q-bot.ai', '_blank');
                break;
            case '/india-link-3':
                window.open('https://banking.q-bot.ai', '_blank');
                break;
            default:
                break;
        }
    };

    const countryOptions = [
        { value: 'USA', label: t('allModule.USA') },
        { value: 'India', label: t('allModule.India') },
    ];

    const customStyles = {
        control: (provided) => ({
            ...provided,
            width: '200px', // Adjust the width of the dropdown
            border: '2px solid #310128',
            borderRadius: '5px',
            boxShadow: 'none',
            '&:hover': {
                borderColor: 'transparent', // Remove hover effect
            },
        }),
        option: (provided, state) => ({
            ...provided,
            backgroundColor: state.isSelected ? '#310128' : (state.isFocused ? '#f2f2f2' : 'white'),
            color: state.isSelected ? 'white' : '#310128',
            '&:hover': {
                backgroundColor: state.isSelected ? '#310128' : '#f2f2f2',
                color: state.isSelected ? 'white' : '#808080',
                borderColor: 'transparent', // Remove hover effect
            },
        }),
        dropdownIndicator: (provided) => ({
            ...provided,
            color: '#310128',
        }),
        indicatorSeparator: () => ({
            display: 'none',
        }),
        singleValue: (provided) => ({
            ...provided,
            color: '#310128',
        }),
        menu: (provided) => ({
            ...provided,
            border: '2px solid transparent', // Set border transparent
            borderRadius: '5px',
            width: '200px', // Set width to match the dropdown width
            marginTop: '5px', // Adjust the top margin for neater spacing
        }),
    };

    return (
        <div className="text-primary text-base font-normal font-sans mt_set">
            <div className="flex items-center">
                <img src={AIIcon} alt="AI Icon" className="w-20 h-20 mr-4" />
                <h1 className="text-4xl font-bold text_c mb-4">{t('allModule.Q-Bots')}</h1>
            </div>
            <div className="main_head">
                <div className="row mb-7">
                    <div className="col-md-5" style={{ marginTop: '40px' }}>
                        <Select
                            value={selectedCountry}
                            onChange={handleCountrySelect}
                            options={countryOptions}
                            placeholder={t('allModule.Select Country')}
                            className="rounded-lg outline-none"
                            classNamePrefix="custom-select"
                            styles={customStyles}
                            menuPlacement="bottom"
                        />
                    </div>
                </div>
            </div>

            {selectedCountry && (
                <div className="row">
                    {selectedCountry.value === 'USA' && (
                        <div className="col-md-5" style={{ border: '2px solid transparent', borderRadius: '5px', width: '200px' }}>
                            <div
                                className="p-3 border-2 border-primary text-primary rounded-lg text-center mt-5"
                                onClick={() => handleButtonClick('/usa-link')}
                                style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
                            >
                                <img src={nvcaImage} alt="NVCA" className="mb-2" style={{ maxWidth: '70px', maxHeight: '70px' }} />
                                <h4 className="font-bold text-sm mt-3">{t('allModule.NVCA Q-Bot')}</h4>
                            </div>
                        </div>
                    )}

                    {selectedCountry.value === 'India' && (
                        <div className="col-md-5" style={{ border: '2px solid transparent', borderRadius: '5px', width: '200px' }}>
                            <div
                                className="p-3 border-2 border-primary text-primary rounded-lg text-center mt-5"
                                onClick={() => handleButtonClick('/india-link-1')}
                                style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
                            >
                                <img src={femaImage} alt="FEMA" className="mb-2" style={{ maxWidth: '70px', maxHeight: '70px' }} />
                                <h4 className="font-bold text-sm mt-3">{t('allModule.FEMA Q-Bot')}</h4>
                            </div>
                        </div>
                    )}

                    {selectedCountry.value === 'India' && (
                        <div className="col-md-5" style={{ border: '2px solid transparent', borderRadius: '5px', width: '200px' }}>
                            <div
                                className="p-3 border-2 border-primary text-primary rounded-lg text-center mt-5"
                                onClick={() => handleButtonClick('/india-link-2')}
                                style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
                            >
                                <img src={taxImage} alt="Tax" className="mb-2" style={{ maxWidth: '70px', maxHeight: '70px' }} />
                                <h4 className="font-bold text-sm mt-3">{t('allModule.Tax Q-Bot')}</h4>
                            </div>
                        </div>
                    )}

                    {selectedCountry.value === 'India' && (
                        <div className="col-md-5" style={{ border: '2px solid transparent', borderRadius: '5px', width: '200px' }}>
                            <div
                                className="p-3 border-2 border-primary text-primary rounded-lg text-center mt-5"
                                onClick={() => handleButtonClick('/india-link-3')}
                                style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
                            >
                                <img src={bankingImage} alt="Banking" className="mb-2" style={{ maxWidth: '70px', maxHeight: '70px' }} />
                                <h4 className="font-bold text-sm mt-3">{t('allModule.Banking Q-Bot')}</h4>
                            </div>
                        </div>
                    )}
                </div>
            )}
        </div>
    );
};

export default QbotsTab;
