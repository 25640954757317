import React, { useState, useContext } from 'react';
import { useTable } from 'react-table';
import './table.css';
import DownArrow from '../assets/icons/down-arrow.svg';
import FormContext from '../context/FormContext';
import AppContext from '../context/AppContext';

export const NoDataComponent = ({ colLength }) => {
  const [rows] = useState(Array(4).fill(''));
  const [cols] = useState(Array(colLength).fill(''));
  return (
    <>
      {rows.map(() => (
        <tr>
          {cols.map(() => (
            <td className='px-5 py-2 font-medium text-smW'>--</td>
          ))}
        </tr>
      ))}
    </>
  );
};

const Table = ({
  moduleData,
  moduleTableColumns,
  prevPage,
  nextPage,
  currentPage,
  setCurrentPage,
  heading,
  activeLinkTitle,
  sortingOrder,
  setSortingOrder,
  setSortColumn,
}) => {
  const { setActiveLink } = useContext(AppContext);
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({
      columns: moduleTableColumns,
      data: moduleData,
    });
  const { setModuleToEdit, setEditFormData, setIsEditing } =
    useContext(FormContext);

  const getColumnName = (colName) => {
    switch (colName) {
      case 'name':
        return 'name';
      case 'first_name':
        return 'first_name';
      case 'email':
        return 'email';
      case 'dateCreated':
        return 'created_at';
      case 'company_name':
        return 'company_id__name';
      case 'status':
        return heading === 'Companies' ? 'is_active' : 'is_active_user';
    }
  };

  return (
    <>
      <div className='mt-2 flex flex-col table-container'>
        <div>
          <div>
            <div className='shadow overflow-hidden border-2 border-primary  sm:rounded-lg max-h-72'>
              <table {...getTableProps()} className='w-full '>
                <thead>
                  {headerGroups.map((headerGroup) => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                      {heading === 'Companies' && (
                        <th scope='col' className='text-left px-5 pt-2'>
                          <div className='flex items-center'>
                            <span>ID</span>
                            {/* Add a sort direction indicator */}
                            {sortingOrder === 'desc' ? (
                              <span
                                onClick={(e) => {
                                  setSortingOrder('asc');
                                  setSortColumn('id');
                                }}
                                style={{ cursor: 'pointer', marginTop: 2 }}
                                className='ml-1'
                              >
                                {/* &#11165; */}
                                <img src={DownArrow} />
                              </span>
                            ) : (
                              <span
                                onClick={(e) => {
                                  setSortingOrder('desc');
                                  setSortColumn(`-id`);
                                }}
                                style={{
                                  cursor: 'pointer',
                                  transform: 'rotate(180deg)',
                                }}
                                className='ml-1'
                              >
                                <img src={DownArrow} />
                              </span>
                            )}
                          </div>
                        </th>
                      )}
                      {headerGroup.headers.map((column) => (
                        // Add the sorting props to control sorting. For this example
                        // we can add them into the header props
                        <th
                          scope='col'
                          className='text-left px-5 pt-2'
                          {...column
                            .getHeaderProps
                            // column.getSortByToggleProps()
                            ()}
                        >
                          <div className='flex items-center'>
                            <span>{column.render('Header')}</span>
                            {/* Add a sort direction indicator */}
                            {sortingOrder === 'desc' ? (
                              <span
                                onClick={(e) => {
                                  setSortingOrder('asc');
                                  setSortColumn(getColumnName(column.id));
                                }}
                                style={{ cursor: 'pointer', marginTop: 2 }}
                                className='ml-1'
                              >
                                {/* &#11165; */}
                                <img src={DownArrow} />
                              </span>
                            ) : (
                              <span
                                onClick={(e) => {
                                  setSortingOrder('desc');
                                  setSortColumn(`-${getColumnName(column.id)}`);
                                }}
                                style={{
                                  cursor: 'pointer',
                                  transform: 'rotate(180deg)',
                                }}
                                className='ml-1'
                              >
                                <img src={DownArrow} />
                              </span>
                            )}
                          </div>
                        </th>
                      ))}
                      <th scope='col' className='text-left px-6 pt-2'>
                        Action
                      </th>
                    </tr>
                  ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                  {moduleData.length < 1 && (
                    <NoDataComponent colLength={moduleTableColumns.length} />
                  )}
                  {moduleData.length > 0 &&
                    rows.map((row, i) => {
                      // new
                      prepareRow(row);
                      return (
                        <tr {...row.getRowProps()}>
                          {heading === 'Companies' && (
                            <td className={`px-5 py-2 font-medium text-sm`}>
                              {row.original.id}
                            </td>
                          )}
                          {row.cells.map((cell) => {
                            return (
                              <>
                                <td
                                  {...cell.getCellProps()}
                                  className={`px-5 py-2 font-medium text-sm col-data ${
                                    cell.value === 'In-Active' && 'text-red-500'
                                  }`}
                                >
                                  {cell.render('Cell')}
                                </td>
                              </>
                            );
                          })}
                          <td className='px-6 py-2'>
                            <button
                              className='bg-secondary text-white font-semibold p-1 pl-6 pr-6 rounded-lg'
                              onClick={() => {
                                setEditFormData(row.original);
                                setModuleToEdit(heading);
                                setIsEditing(true);
                                setActiveLink(activeLinkTitle);
                              }}
                            >
                              Edit
                            </button>
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      {moduleData.length > 0 && (
        <div className='mt-3 flex justify-end'>
          <div className='flex text-primary  border-t-2 border-b-2 border-primary rounded-lg'>
            <button
              className={`pl-5 pr-3 border-r-2 border-l-2 border-primary font-semibold rounded-tl-lg rounded-bl-lg ${
                !prevPage && 'bg-inputBackground'
              }`}
              disabled={!prevPage}
              onClick={() => setCurrentPage(currentPage - 1)}
            >
              Previous
            </button>
            <span className='px-2 font-semibold'>{currentPage}</span>
            <button
              className={`pr-5 pl-3 border-l-2 border-r-2 border-primary font-semibold rounded-tr-lg rounded-br-lg ${
                !nextPage && 'bg-inputBackground'
              }`}
              disabled={!nextPage}
              onClick={() => setCurrentPage(currentPage + 1)}
            >
              Next
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default Table;
