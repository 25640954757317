import React, { useState, useContext } from "react";
import { useTable } from "react-table";
import axios from "axios";
import useAxios from "../../hooks/useAxios";
import DownArrow from "../../assets/icons/down-arrow.svg";
import FormContext from "../../context/FormContext";
import AppContext from "../../context/AppContext";
import DeleteModal from "../modals/DeleteModal";
import { useTranslation } from "react-i18next";
import DeleteIcon from "../../assets/icons/Delete.svg";
import EyeOnIconWhite from "../../assets/icons/eye_on_white.png";

export const NoDataComponent = ({ colLength }) => {
  const [rows] = useState(Array(4).fill(""));
  const [cols] = useState(Array(colLength).fill(""));
  return (
    <>
      {rows.map((value, index) => (
        <tr key={index}>
          {cols.map((value01, key01) => (
            <td
              key={key01}
              className="px-5 py-2 font-medium text-sm text-center"
            >
              --
            </td>
          ))}
        </tr>
      ))}
    </>
  );
};

const NotificationsTable = ({
  contractsData,
  contractTableColumns,
  prevPage,
  nextPage,
  currentPage,
  setCurrentPage,
  setSelectedNotification,
  setSelectedNotificationlegacy,
  sortingOrder,
  setSortingOrder,
  setSortColumn,
  fetchData,
  setIsOpen,
  updateNotificationSeen,
  updateNotificationCleared,
  clearAllNotifications,
  updateNotificationSeenlegacy,
  updateNotificationClearedlegacy,
  clearAllNotificationslegacy,
  setnotiftype,
}) => {
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({ columns: contractTableColumns, data: contractsData });

  const [isDeleteSingleModalOpen, setIsDeleteSignleModalOpen] = useState(false);
  const [isDeleteAllModalOpen, setIsDeleteAllModalOpen] = useState(false);
  const [rowId, setRowId] = useState(0);
  const { t } = useTranslation();

  const handleSingleDelete = (id) => {
    setRowId(id);
    setIsDeleteSignleModalOpen(true);
  };

  const confirmSingleDelete = () => {
    setIsDeleteSignleModalOpen(false);
    updateNotificationCleared(rowId);
  };
  return (
    <>
      <div className="mt-5 flex flex-col table-container">
        <div>
          <div className="table_desin">
            <div className="shadow overflow-hidden border-2 border-primary  sm:rounded-lg max-h-96">
              <table {...getTableProps()} className="w-full ">
                <thead>
                  {headerGroups.map((headerGroup) => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                      {headerGroup.headers.map((column) => (
                        // Add the sorting props to control sorting. For this example
                        // we can add them into the header props

                        <th
                          scope="col"
                          className="text-left px-2 pt-2"
                          {...column
                            .getHeaderProps
                            // column.getSortByToggleProps()
                            ()}
                        >
                          <div className="flex items-center">
                            <span>
                              {t("constant." + column.render("Header"))}
                            </span>

                            {column.isSorted !== undefined &&
                              (column.isSorted ? (
                                <span
                                  onClick={(e) => {
                                    setSortingOrder("asc");
                                    setSortColumn(column.id);
                                    column.isSorted = false;
                                  }}
                                  style={{ cursor: "pointer", marginTop: 2 }}
                                  className="ml-1"
                                >
                                  <img src={DownArrow} />
                                </span>
                              ) : (
                                <span
                                  onClick={(e) => {
                                    setSortingOrder("desc");
                                    setSortColumn(`${column.id}`);
                                    column.isSorted = true;
                                  }}
                                  style={{
                                    cursor: "pointer",
                                    transform: "rotate(180deg)",
                                  }}
                                  className="ml-1"
                                >
                                  <img src={DownArrow} />
                                </span>
                              ))}
                          </div>
                        </th>
                      ))}
                      <th scope="col" className="text-center px-6 pt-2">
                        {t("common.Action")}
                      </th>
                      {/* <th scope='col' className='text-left px-6 pt-2'>
                        Notifications
                      </th> */}
                    </tr>
                  ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                  {contractsData.length < 1 && (
                    <NoDataComponent colLength={contractTableColumns.length} />
                  )}
                  {contractsData.length > 0 &&
                    rows.map((row, i) => {
                      // new
                      prepareRow(row);
                      return (
                        <tr
                          {...row.getRowProps()}
                          className={`text-center `}
                          onClick={() => {}}
                        >
                          {row.cells.map((cell) => {
                            return (
                              <>
                                <td
                                  {...cell.getCellProps()}
                                  className={`px-2 py-2 font-medium text-sm contracts-col-data  ${
                                    cell.value === "Not Seen" && "text-red-500"
                                  }`}
                                >
                                  {cell.value === "Seen" ? (
                                    <span className="text-lg">&#10003;</span>
                                  ) : cell.value === "Not Seen" ? (
                                    <span className="text-lg">&#10008;</span>
                                  ) : cell.column.Header == "Title" ? (
                                    <span className="text_fixed2">
                                      {cell.render("Cell")}
                                    </span>
                                  ) : cell.column.Header == "Date" ||
                                    cell.column.Header == "Read" ? (
                                    <span className="text_fixed3">
                                      {cell.render("Cell")}
                                    </span>
                                  ) : (
                                    <span className="text_fixed">
                                      {cell.render("Cell")}
                                    </span>
                                  )}
                                </td>
                              </>
                            );
                          })}
                          <td className="px-4 py-2 flex">
                            {row.original.contract_details &&
                              row.original.type !==
                                "notify_indexing_failed" && (
                                <button
                                  className="btn btn_theme btn-sm mr-1 wh_spab w-10"
                                  onClick={() => {
                                    updateNotificationSeen(row.original.id);
                                    setnotiftype("normal");
                                    let contractObj =
                                      row.original.contract_details;
                                    contractObj[0].type = row.original.type;
                                    setSelectedNotification(contractObj);
                                    fetchData(contractObj[0].id);
                                    setIsOpen(true);
                                  }}
                                >
                                  <img
                                    src={EyeOnIconWhite}
                                    className="ml-0.5"
                                    width={20}
                                    alt="View Document"
                                  />
                                </button>
                              )}
                            <button
                              className="btn btn_theme btn-sm mr-1 wh_spab w-10"
                              onClick={() =>
                                handleSingleDelete(row.original.id)
                              }
                            >
                              {/* {t('allModule.Clear')} */}
                              <img
                                src={DeleteIcon}
                                className="ml-0.5"
                                width={20}
                                alt="Delete Document"
                              />
                            </button>
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      {contractsData.length > 0 && (
        <div className="mt-3 flex justify-between">
          <div>
            <button
              className="bg-secondary text-white font-semibold p-1 pl-4 pr-4 rounded-md"
              onClick={() => setIsDeleteAllModalOpen(true)}
            >
              {t("allModule.Clear All")}
            </button>
          </div>
          <div className="flex text-primary  border-t-2 border-b-2 border-primary rounded-lg">
            <button
              className={`btn-sm border-r-2 border-l-2 border-primary font-semibold rounded-tl-lg rounded-bl-lg ${
                !prevPage && "bg-inputBackground"
              }`}
              disabled={!prevPage}
              onClick={() => setCurrentPage(currentPage - 1)}
            >
              {t("common.Prev")}
            </button>
            <span className="btn-sm font-semibold">{currentPage}</span>
            <button
              className={`btn-sm border-l-2 border-r-2 border-primary font-semibold rounded-tr-lg rounded-br-lg ${
                !nextPage && "bg-inputBackground"
              }`}
              disabled={!nextPage}
              onClick={() => setCurrentPage(currentPage + 1)}
            >
              {t("common.Next")}
            </button>
          </div>
        </div>
      )}
      <DeleteModal
        title={t("allModule.Clear Notification")}
        bodyText={t(
          "allModule.This action cannot be undone. Are you sure you want to continue?"
        )}
        isDeleteModalOpen={isDeleteSingleModalOpen}
        setIsDeleteModalOpen={setIsDeleteSignleModalOpen}
        confirmSingleDelete={confirmSingleDelete}
      />
      <DeleteModal
        title={t("allModule.Clear All Notification")}
        bodyText={t(
          "allModule.This action cannot be undone. Are you sure you want to continue?"
        )}
        isDeleteModalOpen={isDeleteAllModalOpen}
        setIsDeleteModalOpen={setIsDeleteAllModalOpen}
        clearAllNotifications={clearAllNotifications}
      />
    </>
  );
};

export default NotificationsTable;
