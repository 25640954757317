import React, { useEffect, useContext, useState } from "react";
import "./recipient.css";
import right_arrow from "../../assets/icons/right-arrow.png";
import DocumentContext from "../../context/DocumentContext";
import useAxios from "../../hooks/useAxios";
import { resolve } from "../../api/resolve";
import AppContext from "../../context/AppContext";
import {
  Worker,
  Viewer,
  SpecialZoomLevel,
  MinimalButton,
  Tooltip,
  Position,
  Icon,
} from "@react-pdf-viewer/core";
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
import LoadingScreen from "../loader/LoadingScreen";
import ReactTooltip from "react-tooltip";
import DownArrow from "../../assets/icons/down-arrow.svg";
import magic_wand from "../../assets/icons/magic-wand.png";
import copydd from "../../assets/icons/copy.png";
import contact2 from "../../assets/icons/contact2.png";
import attach from "../../assets/icons/attach-paperclip-symbol.png";
import printing from "../../assets/icons/printing.png";
import Dropbox from "../../assets/icons/Dropbox.svg";
import Draggable from "react-draggable";
import signatureImg from "../../assets/document/signature_img.png";
// import sealImg from '../../assets/document/seal_img.webp'
import sealImg from "../../assets/document/seal_img.png";
import userPicImg from "../../assets/document/user_pic.png";
// import addText from "../../assets/document/add_text.png";

import { autoCloseTimer, delay } from "../../constants/constants";
import { toast } from "react-toastify";
import {
  pageNavigationPlugin,
  RenderGoToPageProps,
} from "@react-pdf-viewer/page-navigation";
import HelpIcon from "../../assets/icons/Help-sm.svg";
import PermissionContext from "../../context/PermissionContext";
import { useTranslation } from "react-i18next";

const ManualMode = () => {
  const {
    documentListData,
    activeDocInfo,
    setActiveDocInfo,
    apiCurrentPage,
    setApiCurrentPage,
    getDocListWithStatusApi,
    apiDataLimit,
    nextBtnLimit,

    isAutoMagicApplied,
    isPresetsApplied,
    isManualApplied,
    isStampApplied,
    setIsAutoMagicApplied,
    setIsPresetsApplied,
    setIsManualApplied,
    setIsStampApplied,

    rightMenuClickFromOtherPage,
    setRightMenuClickFromOtherPage,

    docUrl,
    setDocUrl,
    documentChange,
    removePresetsApi,
    removeManualApi,
  } = useContext(DocumentContext);

  const {
    setActiveLink,
    getSasToken,
    highlightToken,
    getHighlightSasToken,
    setShowDropdown,
  } = useContext(AppContext);

  const [receipientListData, setReceipientListData] = useState([]);
  const [isShowSignature, setIsShowSignature] = useState(true);
  const [loading, setLoading] = useState(false);
  const [loadingApi, setLoadingApi] = useState(false);
  const [contactId, setContactId] = useState(0);
  const [selectedUserName, setSelectedUserName] = useState("");

  const [pdfCurrentPage, setPdfCurrentPage] = useState(0);
  const [pdfTotalPage, setPdfTotalPage] = useState(0);
  const [itemListData, setItemListData] = useState([]);
  const [handleFixed, setHandleFixed] = useState(false);
  const [pagesDimensions, setPagesDimensions] = useState([]);

  const [errorMsg, setErrorMsg] = useState("");
  const { permissionList } = useContext(PermissionContext);
  const { t } = useTranslation();

  const handleItemClick = (type) => {
    if (type == "signature") {
      const updatedItem = {
        ...itemListData[pdfCurrentPage],
        items: [
          ...itemListData[pdfCurrentPage].items,
          {
            contactId: contactId,
            selectedUserName: selectedUserName,
            type: "SIGNATURE",
            x: 0,
            y: 0,
            icon: signatureImg,
          },
        ],
      };
      const newItem = [...itemListData];
      newItem[pdfCurrentPage] = updatedItem;
      setItemListData(newItem);
    } else if (type == "seal") {
      const updatedItem = {
        ...itemListData[pdfCurrentPage],
        items: [
          ...itemListData[pdfCurrentPage].items,
          {
            contactId: contactId,
            selectedUserName: selectedUserName,
            type: "SEAL",
            x: 0,
            y: 0,
            icon: sealImg,
          },
        ],
      };
      const newItem = [...itemListData];
      newItem[pdfCurrentPage] = updatedItem;
      setItemListData(newItem);
    }
    // else if (type == "addText") {
    //   const updatedItem = {
    //     ...itemListData[pdfCurrentPage],
    //     items: [
    //       ...itemListData[pdfCurrentPage].items,
    //       {
    //         contactId: contactId,
    //         selectedUserName: selectedUserName,
    //         type: "ADDTEXT",
    //         x: 0,
    //         y: 0,
    //         icon: addText,
    //       },
    //     ],
    //   };
    //   const newItem = [...itemListData];
    //   newItem[pdfCurrentPage] = updatedItem;
    //   setItemListData(newItem);
    // }
    else if (type == "userPic") {
      const updatedItem = {
        ...itemListData[pdfCurrentPage],
        items: [
          ...itemListData[pdfCurrentPage].items,
          {
            contactId: contactId,
            selectedUserName: selectedUserName,
            type: "PHOTOGRAPH",
            x: 0,
            y: 0,
            icon: userPicImg,
          },
        ],
      };
      const newItem = [...itemListData];
      newItem[pdfCurrentPage] = updatedItem;
      setItemListData(newItem);
    }
  };
  const handleRightSectionResponsive = () => {
    setHandleFixed(!handleFixed);
  };

  const trackStopPos = (data, itemIndex) => {
    const updatedItem = { ...itemListData[pdfCurrentPage] };
    updatedItem.items[itemIndex].x = data.x;
    updatedItem.items[itemIndex].y = data.y;
    const newItem = [...itemListData];
    newItem[pdfCurrentPage] = updatedItem;
    setItemListData(newItem);
  };

  const api = useAxios();
  const getDocumentInfoApi = async (documentId) => {
    const responceData = await resolve(
      api
        .get(`/contract/document/recipients/list/${documentId}`)
        .then((res) => res.data)
    );

    if (responceData["data"] && responceData["data"].length > 0) {
      setReceipientListData(responceData["data"]);
      // getAutoMagicInfo(documentId, responceData['data'].length);
    }
  };

  const handleRightMenu = (menuName) => {
    if (menuName == "ManualMode") {
      if (isManualApplied == true) {
        removeManualApi(setContactId, contactId, setLoadingApi);
      }
    }
    if (menuName == "AutoMagic") {
      setRightMenuClickFromOtherPage(true);
    } else if (menuName == "Presets") {
      setRightMenuClickFromOtherPage(true);
    } else if (menuName == "AttachStampPaper") {
      setRightMenuClickFromOtherPage(true);
    }
    setActiveLink(menuName);
    setShowDropdown("StampsAndSeals");
  };

  const handleRecipientChange = (recipientInfo) => {
    let tempContactId = recipientInfo;

    setContactId(tempContactId);
    if (tempContactId != 0) {
      let contactInfo = receipientListData.filter((item) => {
        if (item["user_contacts_id"] === null) {
          return item["user_id"].id + "_user" == tempContactId;
        } else {
          return item["user_contacts_id"].id + "_contact" == tempContactId;
        }
      });
      if (
        (contactInfo.length > 0 &&
          contactInfo[0].typeOfSignature == "E-signature")
      ) {
        setIsShowSignature(true);
      } else {
        setIsShowSignature(false);
      }
    } else {
      setIsShowSignature(true);
      let newListItem = [...itemListData];
      newListItem.map((value, index) => {
        value.items = [];
      });
      setItemListData(newListItem);
    }
  };

  const handleManualApplyToDoc = async () => {
    let returnData = false;
    setErrorMsg("");
    if (activeDocInfo.id == undefined) {
      setErrorMsg(t("allModule.Please select document first."));
    } else if (contactId == 0) {
      setErrorMsg(t("allModule.Please select recipient first."));
    } else {
      let signTypeCnt = 0;
      itemListData.map((value, index) => {
        if (value.items.length > 0) {
          signTypeCnt++;
        }
      });

      if (signTypeCnt == 0) {
        setErrorMsg(
          t("allModule.Please select atleast one item for the document")
        );
      } else {
        let sealAndSignArr = [];
        itemListData.map((value, index) => {
          if (value.items.length > 0) {
            value.items.map((itemInfo) => {
              ///////////// x position calculation ///////////
              let xPosition = itemInfo.x;
              if (xPosition <= 10) {
                xPosition = 0;
              } else {
                xPosition = xPosition - 12;
              }
              ///////////// y position calculation ///////////
              let yPosition = itemInfo.y;
              if (yPosition <= 10) {
                yPosition = 0;
              } else {
                yPosition = yPosition - 10;
              }

              let contactInfo = sealAndSignArr.filter(
                (item) => item.contactId == itemInfo.contactId
              );
              if (contactInfo.length === 0) {
                sealAndSignArr.push({
                  contactId: itemInfo.contactId,
                  itemListData: [],
                });
              }
              let newContactIndex = sealAndSignArr.findIndex(
                (item) => item.contactId == itemInfo.contactId
              );
              if (pdfTotalPage - 1 == index) {
                sealAndSignArr[newContactIndex]["itemListData"].push({
                  type: itemInfo.type,
                  x: xPosition,
                  y: yPosition,
                  page: -2,
                });
              } else {
                sealAndSignArr[newContactIndex]["itemListData"].push({
                  type: itemInfo.type,
                  x: xPosition,
                  y: yPosition,
                  page: index,
                });
              }
            });
          }
        });

        setLoadingApi(true);
        if (sealAndSignArr.length > 0) {
          const processedItems = await Promise.all(
            sealAndSignArr.map(async (recipientInfo, index) => {
              let recipientInfoArr = recipientInfo["contactId"].split("_");
              let postData = {};
              if (recipientInfoArr[1] == "user") {
                postData = {
                  documentId: activeDocInfo.id,
                  contactId: null,
                  userId: parseInt(recipientInfoArr[0]),
                  itemListData: recipientInfo["itemListData"],
                  manual_on: true,
                };
              } else {
                postData = {
                  documentId: activeDocInfo.id,
                  contactId: parseInt(recipientInfoArr[0]),
                  userId: null,
                  itemListData: recipientInfo["itemListData"],
                  manual_on: true,
                };
              }
              let responceData = await resolve(
                api
                  .post(
                    `/contract/pdf/manual/update/`,
                    JSON.stringify(postData),
                    {
                      headers: {
                        "Content-Type": "application/json",
                      },
                    }
                  )
                  .then((res) => res.data)
              );
              if (index == sealAndSignArr.length - 1) {
                if (responceData["data"]) {
                  setIsManualApplied(
                    responceData["data"].file_url["manual_on"]
                  );
                  setDocUrl(
                    responceData["data"].file_url["converted_file_url"]
                  );
                  toast.success(t("allModule.Applied successfully"), {
                    delay,
                    autoClose: autoCloseTimer,
                    onClose: () => setLoading(false),
                  });
                  returnData = true;
                }

                if (responceData["error"]) {
                  toast.error(t("common.Something went wrong"), {
                    delay,
                    autoClose: autoCloseTimer,
                    onClose: () => setLoading(false),
                  });
                }
                setLoadingApi(false);
              }
              return recipientInfo;
            })
          );
        }
      }
    }
    return returnData;
  };

  const handleRemoveItem = (index) => {
    if (index !== -1) {
      const updatedItem = { ...itemListData[pdfCurrentPage] };
      updatedItem.items.splice(index, 1);
      const newItem = [...itemListData];
      newItem[pdfCurrentPage] = updatedItem;
      setItemListData(newItem);
    }
  };

  const onPageChange = (page) => {
    const currentPageNumber = page.currentPage;
    if (pdfTotalPage - 1 == currentPageNumber) {
    } else {
      pagesDimensions.filter((item) => {
        if (item.page_number == currentPageNumber) {
          if (item.page_width > 0 && item.page_height > 0) {
            document.getElementById(`pdf_wrapper_box`).style.width =
              item.page_width + 15 + "px";
            document.getElementById(`pdf_wrapper_box`).style.height =
              item.page_height + 15 + "px";
          }
        }
      });
    }
    setPdfCurrentPage(currentPageNumber);
  };

  const onDocumentLoad = (document) => {
    // make empty array for each page
    let temp = [];
    for (let i = 0; i < document["doc"].numPages; i++) {
      temp.push({
        page: i,
        items: [],
      });
    }
    setItemListData(temp);

    // set document total page
    setPdfTotalPage(document["doc"].numPages);
  };

  const getPagesDimensions = async (documentId) => {
    const responceData = await resolve(
      api
        .get(`/contract/manual/pagelist/${documentId}`, {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then((res) => res.data)
    );
    if (responceData["data"]) {
      setPagesDimensions(responceData["data"].data);
    }

    if (responceData["error"]) {
    }
  };

  useEffect(() => {
    getDocListWithStatusApi(apiCurrentPage, apiDataLimit, 6);
  }, [apiCurrentPage, apiDataLimit]);

  useEffect(() => {
    if (activeDocInfo?.id) {
      if (rightMenuClickFromOtherPage === true) {
        if (isManualApplied == true) {
          removeManualApi(setContactId, contactId, setLoadingApi);
        }
        setHandleFixed(false);
        setRightMenuClickFromOtherPage(false);
      }
      getDocumentInfoApi(activeDocInfo.id);
      getPagesDimensions(activeDocInfo.id);
    }
  }, [activeDocInfo]);

  useEffect(() => {
    getSasToken();
    getHighlightSasToken();
  }, []);

  const disableScrollPlugin = (): Plugin => {
    const renderViewer = (props: RenderViewer) => {
      const { slot } = props;

      if (slot.subSlot && slot.subSlot.attrs && slot.subSlot.attrs.style) {
        slot.subSlot.attrs.style = Object.assign({}, slot.subSlot.attrs.style, {
          // Disable scrolling in the pages container
          overflow: "hidden",
        });
      }

      return slot;
    };

    return {
      renderViewer,
    };
  };

  const pageNavigationPluginInstance = pageNavigationPlugin();
  const { GoToNextPage, GoToPreviousPage } = pageNavigationPluginInstance;
  const disableScrollPluginInstance = disableScrollPlugin();

  return (
    <div className="text-primary text-base font-normal font-sans mt_set">
      <div className="main_head">
        <div className="flex">
          <h1 className="text-4xl font-bold text_c">
            {t("menu.Manual mode")}
          </h1>
          <>
            <img
              src={HelpIcon}
              width={12}
              className="ml-1 -mt-2 cursor-pointer"
              data-tip="Manual mode allows you to apply stamps and seals to your documents manually. You can select the recipient and the items you want to apply the stamp or seal to. You can also select the page number of the document to apply the stamp or seal to."
            />
            <ReactTooltip backgroundColor="#400835" textColor="#ffffff" />
          </>
        </div>
      </div>

      {permissionList["manual_on"] == false ? (
        <div className="flex justify-center items-center h-96 flex-column ">
          <h3 className="text-2xl font-bold text_c">
            {t("allModule.You dont have permission to access this page")}
          </h3>
        </div>
      ) : documentListData.length === 0 ? (
        <div className="no_document_available_box">
          <p>{t("allModule.No Assigned Document Available.")}</p>
        </div>
      ) : (
        <>
          <div
            className={`row ${handleFixed && 0 ? "set_fix1" : ""}`}
            id="hd_show"
          >
            <div className="col-md-9">
              <div className="w-full doc_list_btn">
                <div className="flex flex-col mb-4">
                  <div className="btn_us3 ">
                    <button
                      className="btn pb-1 pt-0.5 pl-6 pr-6 text_c font-bold rounded-lg next-pre-btn "
                      onClick={() => {
                        if (apiCurrentPage > 1) {
                          setApiCurrentPage(apiCurrentPage - 1);
                        }
                      }}
                    >
                      <img src={right_arrow} className="right rotateIcon" />
                    </button>
                    {documentListData.map((item, index) => {
                      return (
                        <button
                          key={"doclist_" + index}
                          className={`set_w2 btn pb-1 pt-0.5 pl-6 pr-6 text_c font-semibold ${
                            item.id == activeDocInfo?.id ? "active" : ""
                          }`}
                          onClick={() => documentChange(item)}
                        >
                          {item.name}{" "}
                        </button>
                      );
                    })}
                    <button
                      onClick={() => {
                        if (apiCurrentPage < nextBtnLimit) {
                          setApiCurrentPage(apiCurrentPage + 1);
                        }
                      }}
                      className="btn pb-1 pt-0.5 pl-6 pr-6 text_c font-bold rounded-lg next-pre-btn float-right"
                    >
                      <img src={right_arrow} className="right" />
                    </button>
                  </div>
                </div>
              </div>
              <h4 className="text-xl font-bold  mb-2 text_c text-center">
                {" "}
                {activeDocInfo?.id ? activeDocInfo.name : ""}
              </h4>

              <div className="box_u1 p-4">
                <div className="box_u2 manual_pdf_parent_box">
                  <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.13.216/build/pdf.worker.min.js">
                    {loading ? (
                      <LoadingScreen />
                    ) : (
                      <>
                        {docUrl && (
                          // <div id='pdf_wrapper_box' style={{ width: '100%', height: '1800px', border: '1px solid', position: 'relative', margin: '0 auto' }}>
                          <div
                            id="pdf_wrapper_box"
                            style={{
                              width: "612px",
                              height: "792px",
                              border: "1px solid",
                              position: "relative",
                              margin: "0 auto",
                            }}
                          >
                            <div
                              style={{
                                left: 0,
                                position: "absolute",
                                top: "30%",
                                transform: "translate(45px, -50%)",
                                zIndex: 1,
                              }}
                            >
                              <GoToPreviousPage>
                                {(props: RenderGoToPageProps) => (
                                  <Tooltip
                                    position={Position.BottomCenter}
                                    target={
                                      <MinimalButton onClick={props.onClick}>
                                        <Icon size={16}>
                                          <path d="M18.4.5,5.825,11.626a.5.5,0,0,0,0,.748L18.4,23.5" />
                                        </Icon>
                                      </MinimalButton>
                                    }
                                    content={() => "Previous page"}
                                    offset={{ left: 0, top: 8 }}
                                  />
                                )}
                              </GoToPreviousPage>
                            </div>
                            <div
                              style={{
                                position: "absolute",
                                right: 0,
                                top: "30%",
                                transform: "translate(-45px, -50%)",
                                zIndex: 1,
                              }}
                            >
                              <GoToNextPage>
                                {(props: RenderGoToPageProps) => (
                                  <Tooltip
                                    position={Position.BottomCenter}
                                    target={
                                      <MinimalButton onClick={props.onClick}>
                                        <Icon size={16}>
                                          <path d="M5.651,23.5,18.227,12.374a.5.5,0,0,0,0-.748L5.651.5" />
                                        </Icon>
                                      </MinimalButton>
                                    }
                                    content={() => "Next page"}
                                    offset={{ left: 0, top: 8 }}
                                  />
                                )}
                              </GoToNextPage>
                            </div>
                            <Viewer
                              fileUrl={`${docUrl}?${highlightToken.sas_token}`}
                              plugins={[
                                disableScrollPluginInstance,
                                pageNavigationPluginInstance,
                              ]}
                              onPageChange={onPageChange}
                              onDocumentLoad={onDocumentLoad}
                              defaultScale={1}
                              // defaultScale={SpecialZoomLevel.PageFit}
                              renderError={() => (
                                <div className="grid place-items-center h-full w-full">
                                  <h1 className="font-semibold">
                                    {t(
                                      "common.Contract indexing in progress. Check back later"
                                    )}
                                  </h1>
                                </div>
                              )}
                              renderLoader={() => <LoadingScreen />}
                            />

                            {itemListData.length > 0 &&
                              itemListData[pdfCurrentPage].items.map(
                                (itemInfo, index) => {
                                  return (
                                    <Draggable
                                      key={index}
                                      position={{
                                        x: itemInfo.x,
                                        y: itemInfo.y,
                                      }}
                                      onStop={(e, data) =>
                                        trackStopPos(data, index)
                                      }
                                      bounds="parent"
                                    >
                                      <div
                                        className={
                                          itemInfo.type == "SIGNATURE"
                                            ? `manual_mode_item_signature`
                                            : itemInfo.type == "SEAL"
                                            ? `manual_mode_item_stamp`
                                            : `manual_mode_item`
                                        }
                                      >
                                        <span
                                          onClick={(e) => {
                                            e.stopPropagation();
                                            handleRemoveItem(index);
                                          }}
                                        >
                                          x
                                        </span>
                                        <img
                                          draggable="false"
                                          src={itemInfo.icon}
                                          alt="img"
                                        />
                                        <div
                                          className={
                                            itemInfo.type == "SIGNATURE"
                                              ? `username_manual_signature`
                                              : itemInfo.type == "SEAL"
                                              ? `username_manual_stamp`
                                              : `username_manual_item`
                                          }
                                        >
                                          {itemInfo.selectedUserName}
                                        </div>
                                      </div>
                                    </Draggable>
                                  );
                                }
                              )}
                          </div>
                        )}
                      </>
                    )}
                  </Worker>
                </div>
              </div>
            </div>
            <div className="col-md-3">
              <div className="tool rounded-md">
                <ul>
                  <li
                    data-tip={t("menu.Automagic")}
                    style={{
                      backgroundColor: isAutoMagicApplied
                        ? "var(--orgg)"
                        : "transparent",
                    }}
                  >
                    <a
                      onClick={(e) => {
                        e.preventDefault();
                        handleRightMenu("AutoMagic");
                      }}
                      href="#"
                    >
                      <img src={magic_wand} alt="" />
                    </a>
                  </li>

                  <li
                    data-tip={t("menu.Presets")}
                    style={{
                      backgroundColor: isPresetsApplied
                        ? "var(--orgg)"
                        : "transparent",
                    }}
                  >
                    <a
                      onClick={(e) => {
                        e.preventDefault();
                        handleRightMenu("Presets");
                      }}
                      href="#"
                    >
                      <img src={copydd} alt="" />
                    </a>
                  </li>

                  <li
                    data-tip={t("menu.Manual mode")}
                    className="active_left_menu"
                    style={{
                      backgroundColor: isManualApplied
                        ? "var(--orgg)"
                        : "transparent",
                    }}
                  >
                    <a
                      onClick={(e) => {
                        e.preventDefault();
                        handleRightMenu("ManualMode");
                      }}
                      href="#"
                    >
                      <img src={contact2} alt="" />
                    </a>
                  </li>

                  <li
                    data-tip={t("menu.Attach stamp paper")}
                    style={{
                      backgroundColor: isStampApplied
                        ? "var(--orgg)"
                        : "transparent",
                    }}
                  >
                    <a
                      onClick={(e) => {
                        e.preventDefault();
                        handleRightMenu("AttachStampPaper");
                      }}
                      href="#"
                    >
                      <img src={attach} alt="" />
                    </a>
                  </li>

                  <li data-tip={t("menu.Email Notifications")}>
                    <a
                      onClick={(e) => {
                        e.preventDefault();
                        handleRightMenu("EmailNotifications");
                      }}
                      href="#"
                    >
                      <img src={printing} alt="" />
                    </a>
                  </li>
                </ul>
                <div className="rounded-md hd_2">
                  {loadingApi ? (
                    <LoadingScreen />
                  ) : (
                    <>
                      <select
                        value={contactId}
                        onChange={(e) => {
                          setSelectedUserName(
                            e.target.options[e.target.selectedIndex].text
                          );
                          handleRecipientChange(e.target.value);
                        }}
                        className="border-2 border-primary rounded-lg outline-none p-1 pl-2 w-full mb-3 mt-3"
                      >
                        <option value="0">
                          {t("allModule.Select Recipient")}
                        </option>

                        {receipientListData.map((item, index) => {
                          return item["user_contacts_id"] === null ? (
                            <option
                              key={index}
                              value={item["user_id"].id + "_user"}
                            >
                              {item["user_id"].first_name}
                            </option>
                          ) : (
                            <option
                              key={index}
                              value={item["user_contacts_id"].id + "_contact"}
                            >
                              {item["user_contacts_id"].name}{" "}
                              {item.is_deligacy == true ? " (Delegatee)" : ""}
                            </option>
                          );
                        })}
                      </select>
                      {isShowSignature ? (
                        <>
                          <div className="manual_mode_sign_type">
                            <div
                              onClick={(e) => {
                                e.stopPropagation();
                                if (contactId == 0) {
                                  toast.error(
                                    t(
                                      "allModule.Please select recipient first."
                                    )
                                  );
                                } else {
                                  handleItemClick("signature");
                                }
                              }}
                            >
                              <img
                                src={signatureImg}
                                alt="img"
                                width="100"
                                height="100"
                                style={{ cursor: "pointer" }}
                              />
                            </div>

                            <div
                              onClick={(e) => {
                                e.stopPropagation();
                                if (contactId == 0) {
                                  toast.error(
                                    t(
                                      "allModule.Please select recipient first."
                                    )
                                  );
                                } else {
                                  handleItemClick("seal");
                                }
                              }}
                            >
                              <img
                                src={sealImg}
                                alt="img"
                                width="100"
                                height="100"
                                style={{ cursor: "pointer" }}
                              />
                            </div>

                            {/* <div
                              onClick={(e) => {
                                e.stopPropagation();
                                if (contactId == 0) {
                                  toast.error(
                                    t(
                                      "allModule.Please select recipient first."
                                    )
                                  );
                                } else {
                                  handleItemClick("addText");
                                }
                              }}
                            >
                              <img
                                src={addText}
                                alt="img"
                                width="100"
                                height="100"
                                style={{ cursor: "pointer" }}
                              />
                            </div> */}

                            <div
                              onClick={(e) => {
                                e.stopPropagation();
                                if (contactId == 0) {
                                  toast.error(
                                    t(
                                      "allModule.Please select recipient first."
                                    )
                                  );
                                } else {
                                  handleItemClick("userPic");
                                }
                              }}
                            >
                              <img
                                src={userPicImg}
                                alt="img"
                                width="100"
                                height="100"
                                style={{ cursor: "pointer" }}
                              />
                            </div>
                          </div>

                          <div className="w-full mt-5">
                            <p className="error_msg">{errorMsg}</p>
                            <input
                              type="button"
                              onClick={handleManualApplyToDoc}
                              style={{ cursor: "pointer" }}
                              className="btn pb-1 pt-0.5 pl-6 pr-6 text_c font-bold rounded-lg btn_theme3"
                              value={t("allModule.Apply to document")}
                            />
                          </div>
                          <div className="document_content">
                            <h1 className="font-bold text-center text-2xl mb-5">
                              <img
                                className="signee-icon"
                                src={contact2}
                                alt=""
                              />{" "}
                              {t("allModule.Manual")}
                            </h1>
                            <p>
                              {t(
                                "allModule.Users can use Manual mode to select, paste and drag Signatures / Seals / Photographs to any location on the document"
                              )}
                              .​
                            </p>
                            <p>
                              {t(
                                "allModule.Users need to Select the recipient, click on the items they want to add and drag them where they want them to be placed."
                              )}
                            </p>
                            <p>
                              {t(
                                "allModule.Dont forget to click on Apply to Document before clicking next."
                              )}
                            </p>
                          </div>
                        </>
                      ) : (
                        <h4>
                          {t(
                            "allModule.This feature is not available for Stakeholder/ Aadhaar Signatories"
                          )}
                        </h4>
                      )}
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="set_btn2 mt-4">
            <button
              onClick={(e) => {
                e.preventDefault();
                setActiveLink("Presets");
                setShowDropdown("Recipients");
                setApiCurrentPage(1);
              }}
              className="btn pb-1 pt-0.5 pl-6 pr-6 text_c font-bold rounded-lg btn_theme3"
            >
              {t("allModule.Previous")}
            </button>
            <button
              onClick={async (e) => {
                e.preventDefault();

                let signTypeCnt = 0;
                itemListData.map((value, index) => {
                  if (value.items.length > 0) {
                    signTypeCnt++;
                  }
                });
                if (signTypeCnt > 0) {
                  let res = await handleManualApplyToDoc();
                  if (res) {
                    setActiveLink("AttachStampPaper");
                    setShowDropdown("Recipients");
                    setApiCurrentPage(1);
                  }
                } else {
                  setActiveLink("AttachStampPaper");
                  setShowDropdown("Recipients");
                  setApiCurrentPage(1);
                }
              }}
              className="btn pb-1 pt-0.5 pl-6 pr-6 text_c font-bold rounded-lg btn_theme3"
            >
              {t("common.Next")}
            </button>
          </div>
        </>
      )}
    </div>
  );
};

export default ManualMode;
