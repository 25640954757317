import React, { useContext, useEffect, useState } from "react";
import "./homepage.css";
import StackedBarChart from "../demographics/StackedBarChart";
import CircularProgress from "../demographics/CircularProgress";
import AuthContext from "../../context/AuthContext";
import ActivityLog from "../demographics/ActivityLog";
import Document from "../document-home/Document";
import VerifyMobile from "../modals/VerifyMobile";
import FormContext from "../../context/FormContext";
import ViewPlan from "../modals/ViewPlan";
import PermissionContext from "../../context/PermissionContext";
import AddCompanyModel from "../modals/AddCompanyModel";
import useAxios from "../../hooks/useAxios";
import { resolve } from "../../api/resolve";
import { toast } from "react-toastify";
import { autoCloseTimer, delay } from "../../constants/constants";
import AppModal from "../modals/Modal";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import AppContext from "../../context/AppContext";

const HomePage = () => {
  const api = useAxios();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { activeLink, setActiveLink, adminId, activePrevLink } =
    useContext(AppContext);
  const { user } = useContext(AuthContext);
  const [companyModelOpen, setCompanyModelOpen] = useState(false);
  const [companyAuthModelOpen, setCompanyAuthModelOpen] = useState(false);
  const [companyInfo, setCompanyInfo] = useState(null);
  const [loading, setLoading] = useState(false);

  const [userDetail, setUserDetail] = useState({
    phone: "",
    entity_title: "",
    designation: "",
  });

  const { setIsOpen, modalIsOpen, planModalIsOpen, setPlanModelIsOpen } =
    useContext(FormContext);

  const getUserDetails = async () => {
    const res = await resolve(
      api
        .get(`/user/detail/`, {
          headers: {
            "content-type": "application/json",
          },
        })
        .then((res) => res.data)
    );

    if (res.data) {
      setUserDetail({
        ...userDetail,
        phone: res.data.phone,
        entity_title: res.data.entity_title,
        designation: res.data.designation,
      });
      setCompanyInfo(res.data.company_id);
      let companyInfo = res.data.company_id;
      if (
        companyInfo.country_id == "" ||
        companyInfo.country_id == null ||
        companyInfo.state == "" ||
        companyInfo.state == null ||
        companyInfo.city == "" ||
        companyInfo.city == null ||
        companyInfo.entity == "" ||
        companyInfo.entity == null
      ) {
        setCompanyModelOpen(true);
      }
    }

    if (res.error) {
      toast.error(t("homePage.Something went wrong, to get user info"), {
        delay,
        autoClose: autoCloseTimer,
        onClose: () => setLoading(false),
      });
    }
  };

  useEffect(() => {
    let hubspot = document.querySelector("#hubspot-messages-iframe-container");
    if (hubspot) {
      hubspot.style.visibility = "hidden";
    }
    return () => {
      if (hubspot) {
        hubspot.style.visibility = "visible";
      }
    };
  });

  useEffect(async () => {
    if (user.user_type != "super_admin" && user.phone_verified === false) {
      setIsOpen(true);
    } else if (
      user.user_type != "super_admin" &&
      user.is_subscription_active === false
    ) {
      setPlanModelIsOpen(true);
    } else if (user.user_type != "super_admin") {
      getUserDetails();
    }
  }, []);

  return (
    <div className="demographics-container">
      <div className="main_head">
        <h1 className="text-primary text-4xl font-bold">
          {t("homePage.title")}
        </h1>
      </div>
      <div className="nav_tab mb-4">
        <ul className="ul_set">
          <li
            style={{ paddingRight: "5px", cursor: "default" }}
            className={activePrevLink === "Home" ? "active" : ""}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
            }}
          >
            <a href="/home">{t("allModule.Document Status")}</a>
          </li>
          <li
            style={{ cursor: "pointer", paddingLeft: "5px" }}
            className={activePrevLink === "homepagelegacy" ? "active" : ""}
            onClick={(e) => {
              e.stopPropagation();
              setActiveLink("homepagelegacy");
              navigate("/dashboard");
            }}
          >
            {t("allModule.Document Repository")}
          </li>
        </ul>
      </div>
      <>
        {user.user_type === "super_admin" ? (
          <>
            <div className="grid grid-cols-2 gap-x-4">
              <CircularProgress user={user} />
              <ActivityLog />
            </div>
          </>
        ) : user.user_type == "admin" ? (
          <>
            <div className="grid grid-cols-2 gap-x-4">
              <CircularProgress user={user} />
              <ActivityLog />
            </div>
            {/* <StackedBarChart /> */}
          </>
        ) : (
          <>
            <div className="grid grid-cols-2 gap-x-4">
              <CircularProgress user={user} />
              <ActivityLog />
            </div>
          </>
        )}
      </>
      {user.user_type !== "super_admin" && <Document />}

      <AppModal
        title="Notification"
        bodyText="Please upload Company Authorization documents in Account Page"
        setIsOpen={setCompanyAuthModelOpen}
        modalIsOpen={companyAuthModelOpen}
        modelAction="CompanyAuthorizationNotificaton"
      />

      <VerifyMobile
        setIsOpen={setIsOpen}
        modalIsOpen={modalIsOpen}
        setPlanModelIsOpen={setPlanModelIsOpen}
        getUserDetails={getUserDetails}
      />

      <ViewPlan
        setPlanModelIsOpen={setPlanModelIsOpen}
        planModalIsOpen={planModalIsOpen}
      />

      <AddCompanyModel
        companyModelOpen={companyModelOpen}
        setCompanyModelOpen={setCompanyModelOpen}
        companyInfo={companyInfo}
        setCompanyInfo={setCompanyInfo}
        userDetail={userDetail}
        getUserDetails={getUserDetails}
        pageName="HomePage"
        user={user}
        setCompanyAuthModelOpen={setCompanyAuthModelOpen}
      />
    </div>
  );
};

export default HomePage;
