import React, { useState, useEffect, useContext } from 'react';
import { resolve } from '../../api/resolve';
import useAxios from '../../hooks/useAxios';
import Admin from '../../assets/icons/Admin-sm.svg';
import User from '../../assets/icons/User-sm.svg';
import Contract from '../../assets/icons/Contract-sm.svg';
import Chart from 'react-google-charts';
import grap from '../../assets/icons/grap.png';
import { docStatus } from '../../constants/constants';
import AppContext from '../../context/AppContext';
import { Link, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const CircularProgress = ({ user }) => {
  const api = useAxios();
  const { t } = useTranslation();
  var navigate = useNavigate();
  const [chartData, setChartData] = useState(null);
  const [docStatusData, setDocStatusData] = useState([
    ["Task", "Hours per Day"],
    ["Unassigned", 0],
    ["In Progress", 0],
    ["Completed", 0],
    ["Rejected", 0],

  ]);
  const [options, setOptions] = useState({
    pieHole: 0.4,
    is3D: false,
    legend: { position: 'bottom' },
    pieSliceText: "value",
    chartArea: { 'width': '100%', 'height': '80%' },
    colors: ['red']

  });
  const { setActiveLink } = useContext(AppContext);
  const getProgressChartData = async () => {
    const res = await resolve(
      api.get(`/contract/dashboard/user/count/`).then((res) => res.data)
    );
    if (res.data) {
      setChartData(res.data);
    }
  };

  const getPieChartData = async () => {
    const res = await resolve(
      api.get(`/contract/dashboard/status/`).then((res) => res.data)
    );
    if (res.data) {
      let tempColor = ['#3b82f6', '#ffc83d', '#008000', '#808080'];

      let unAssignedCnt = 0;
      let inProcessCnt = 0;
      let completed = 0;
      let rejected = 0;
      res.data['data'].map((item) => {
        if (item.status == 1) {
          unAssignedCnt += item.total;
        } else if (item.status == 2 || item.status == 3 || item.status == 6) {
          inProcessCnt += item.total;
        } else if (item.status == 4) {
          completed += item.total;
        } else if (item.status == 5) {
          rejected += item.total;
        }
      })
      setDocStatusData([
        ["Task", "Hours per Day"],
        [t('homePage.Unassigned'), unAssignedCnt],
        [t('homePage.In Progress'), inProcessCnt],
        [t('homePage.Completed'), completed],
        [t('homePage.Rejected'), rejected],
      ]);
      setOptions({ ...options, colors: tempColor });
    }

  };


  useEffect(() => {
    getProgressChartData();
    getPieChartData();
  }, []);
  return (
    <div className='border-2 border-primary text-primary circular-container p-5 w-full'>
      <h1 className='font-bold text-xl mb-5'>{t('homePage.Usage in repository')}</h1>

      <div className=''>

        {chartData && (
          <div className=''>
            <div className="row set_scardd">
              {user.user_type !== 'super_admin' ? (
                <>
                  <div className="col-md-12">
                    <div className='Chat_dasb1'>
                      <Chart
                        chartType="PieChart"
                        width="100%"
                        height="250px"
                        data={docStatusData}
                        options={options}
                      />
                    </div>
                  </div>
                  {/* <div className="col-md-3">
                    <div
                      onClick={(e) => {
                        e.preventDefault();
                        if (user.user_type !== 'super_admin') {
                          navigate('/account')
                          setActiveLink('Account');
                        }
                        

                      }}
                      className='box_admin'>
                      <img src={Admin} />
                      <h4>
                        <span >{chartData.total_admin} </span> {t('homePage.Admins')}
                      </h4>

                    </div>


                    <div
                      onClick={(e) => {
                        e.preventDefault();
                        if (user.user_type == 'admin') {
                          navigate('/account/add-users')
                          setActiveLink('AdminUser');
                        }
                      }}
                      className='box_admin'>
                      <img src={User} />
                      <h4>
                        <span className='ml-1'>{chartData.total_user} </span> {t('homePage.Users')}
                      </h4>
                    </div>
                    <div
                      onClick={(e) => {
                        e.preventDefault();
                        
                        if (user.user_type !== 'super_admin') {
                          navigate('/my-documents/set-up-signatures')
                          setActiveLink('MyDocuments');
                        }
                      }}
                      className='box_admin'>
                      <img src={Contract} />
                      <h4>
                        <span className='ml-1'>
                          {chartData.total_document}
                        </span> {t('homePage.Documents')}
                      </h4>
                    </div>
                  </div> */}
                </>
              ) : (<>
                <div className="col-md-6">
                  <div
                    onClick={(e) => {
                      e.preventDefault();                     
                        setActiveLink('Account');
                    }}
                    className='box_admin'>
                    <img src={Admin} />
                    <h4>
                      <span >{chartData.total_admin} </span> {t('homePage.Admins')}
                    </h4>

                  </div>
                </div>
                <div className="col-md-6">
                  <div
                    className='box_admin'>
                    <img src={User} />
                    <h4>
                      <span className='ml-1'>{chartData.total_user} </span> {t('homePage.Users')}
                    </h4>
                  </div>
                </div>
                <div className="col-md-6">
                  <div
                    className='box_admin'>
                    <img src={Contract} />
                    <h4>
                      <span className='ml-1'>
                        {chartData.total_document}
                      </span> {t('homePage.Documents')}
                    </h4>
                  </div>
                </div>
              </>)}
            </div>
            <div>
            </div>
            <div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default CircularProgress;
