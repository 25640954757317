import React, { useContext, useEffect, useState } from 'react';
import './notifications.css';
import AppContext from '../../context/AppContext';
import { Link, useNavigate } from 'react-router-dom';
import Bell from '../../assets/icons/Bell.svg';
import { useTranslation } from 'react-i18next';

const BellComponentlegacy = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { setActiveLink, notificationCount, getNotificationsCount } =
    useContext(AppContext);
  useEffect(() => {
    getNotificationsCount();
  }, [notificationCount]);
  return (
    <div className='bell-notifications-container'>
      <button
        className=' flex btn btn_theme btn-sm'
        onClick={() => {
          navigate('/account/notification')
          setActiveLink('Notifications')
          // setShowDropdown('')
        }}
      >
        <span>{t('homePage.Notifications')}</span>
        <span className='ml-2 relative'>
          <img src={Bell} alt='Bell-icon' />
          {notificationCount > 0 && (
            <span
              className='absolute -top-3 left-3.5 bg-[#c00]'
              style={{
                minWidth: 24,
                height: 24,
                borderRadius: 50,
                padding: '0 5px',
              }}
            >
              {notificationCount}
            </span>
          )}
        </span>
      </button>
    </div>
  );
};

export default BellComponentlegacy;
