import React, { useEffect, useContext, useState, useRef } from "react";
import Creatable from "react-select/creatable";
import ReactTooltip from "react-tooltip";
import LoadingScreen from "../loader/LoadingScreen";
import {
  CircularProgressbarWithChildren,
  buildStyles,
} from "react-circular-progressbar";
import "../forms/companyForm.css";
import { resolve } from "../../api/resolve";
import { customStyles } from "../../constants/styles";
import FormContext from "../../context/FormContext";
import AppContext from "../../context/AppContext";
import AppModal from "../modals/Modal";
import "react-tagsinput/react-tagsinput.css";
import useAxios from "../../hooks/useAxios";
import HelpIcon from "../../assets/icons/Help-sm.svg";
import CustomPhoneInput from "../forms/PhoneInput";
import "./contact_book.css";
import ContactsFile from "../../assets/Contact.csv";
import Papa from "papaparse";
import { useNavigate } from "react-router-dom";
import AddCompanyModel from "../modals/AddCompanyModel";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

const ContactBookForm = () => {
  const {
    setCurrentForm,
    contactFormErrors,
    modalData,
    setIsOpen,
    modalIsOpen,
    isEditing,
    editFormData,
    setEditFormData,
    setIsEditing,
    setContactFormErrors,
    saveOrEditContact,
    loading,
    progressValue,
  } = useContext(FormContext);

  const api = useAxios();
  const { t } = useTranslation();
  const { repoName, setActiveLink } = useContext(AppContext);

  const [contactObj, setContactObj] = useState({
    name: "",
    designation: "",
    phone: "",
    email: "",
    confirmEmail: "",
    groupList:
      isEditing && editFormData.groupList && editFormData.groupList.length > 0
        ? [...editFormData.groupList]
        : [],

    companyId: "",
    delegatee: false,
    titleId: "",
  });
  const [contractModalIsOpen, setIsContractModalOpen] = useState(false);

  const [multipleContractsObj, setMultipleContractsObj] = useState([]);
  const [groupListData, setGroupListData] = useState([]);
  const [defaultValues, setDefaultValues] = useState([]);
  const [errorContactList, setErrorContactList] = useState([]);
  const [isShowDesignation, setIsShowDesignation] = useState(false);

  const fileInput = useRef();
  const [value, setValue] = useState(null);
  const [error, setError] = useState(null);
  const [open, setOpen] = useState(false);
  const [companyModelOpen, setCompanyModelOpen] = useState(false);
  const [responceMsg, setResponceMsg] = useState({
    msg: "",
    toast: "",
  });
  const navigate = useNavigate();

  const [companyListData, setCompnayListData] = useState([]);
  const [entityTitleListData, setEntityTitleListData] = useState([]);

  const getCompanyListApi = async () => {
    const responceData = await resolve(
      api.get("/company/list/").then((res) => res.data)
    );
    if (responceData["data"]) {
      setCompnayListData(responceData["data"]);
    }
  };

  const getEntitytitleListApi = async (companyId) => {
    if (companyId > 0) {
      const responceData = await resolve(
        api.get(`/company/title/list/${companyId}`).then((res) => res.data)
      );
      if (responceData["data"]) {
        setEntityTitleListData(responceData["data"]);
      }
    } else {
      setEntityTitleListData([]);
    }
  };

  const getGroupListApi = async () => {
    const responceData = await resolve(
      api.get("/user/contact/group/list/").then((res) => res.data)
    );
    if (responceData["data"]) {
      const groupListData = responceData["data"].results;
      const filteredArr = [];
      if (groupListData.length > 0) {
        groupListData.forEach((groupInfo) => {
          filteredArr.push({
            value: groupInfo.id + "",
            label: groupInfo.group_name,
            id: groupInfo.id,
          });
        });
        setGroupListData(filteredArr);
      }
    }
  };

  const handleFileChange = async (e) => {
    const { files } = e.target;
    if (files && files.length > 0) {
      setError(null);
      setValue(files[0]);
      setErrorContactList([]);
      Papa.parse(files[0], {
        header: true,
        skipEmptyLines: true,
        complete: async function (results) {},
      });
    }
  };

  const handleTitleChange = (title) => {
    if (title === "other") {
      setContactObj({ ...contactObj, titleId: title, designation: "" });
      setIsShowDesignation(true);
    } else {
      // setContactObj({ ...contactObj, designation: '' });
      setIsShowDesignation(false);
    }
  };

  const bulkUploadContacts = () => {
    let formData = new FormData();
    formData.append("file", value);

    api
      .post(
        `${process.env.REACT_APP_BACKEND_BASE_URL}/user/contact/bulk_upload/`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      )
      .then((resp) => {
        setErrorContactList(resp["data"]["data"]);
        toast.success(t("allModule.Contacts uploaded successfully!"));
        setValue(null);
        setError(null);
        fileInput.current.value = "";
        navigate("/contact-book/contact");
        // setActiveLink("ContactBook");
      })
      .catch((err) => {
        setValue(null);
        setError(null);
        fileInput.current.value = "";
        if (err.response.data.messages) {
          toast.error(err.response.data.messages[0].message);
        } else {
          toast.error(err.response.data.message);
        }

        // err.response.data.messages
        //   ? setResponceMsg({
        //     msg: err.response.data.messages[0].message,
        //     toast: 'error',
        //   })
        //   : setResponceMsg({
        //     msg: err.response.data.message,
        //     toast: 'error',
        //   });
        setOpen(true);
        if (!err.response.data.message) {
          setTimeout(() => {
            navigate("/login");
          }, 2000);
        }
      });
  };

  const deleteFile = (e) => {
    fileInput.current.value = "";
    setValue(null);
  };

  const wrongFile = () => {
    setValue(null);
    setError("data error");
    setResponceMsg({
      msg: "data error",
      toast: "error",
    });
    setOpen(true);
  };

  useEffect(() => {
    getGroupListApi();
    getCompanyListApi();

    getEntitytitleListApi(editFormData?.companyId);
    setContactFormErrors({
      ...contactFormErrors,
      name: "",
      designation: "",
      phone: "",
      email: "",
      confirmEmail: "",
      groupList: "",
      companyId: "",
      titleId: "",
    });
    setCurrentForm("ContactForm");

    if (
      editFormData &&
      editFormData.groupList &&
      editFormData.groupList.length > 0
    ) {
      let filteredArr = editFormData.groupList;
      if (filteredArr.length > 0) {
        filteredArr.forEach((groupInfo) => {
          if (groupInfo) {
            defaultValues.push({
              value: groupInfo.id + "",
              label: groupInfo.group_name,
            });
          }
        });
      }
    }
    return () => setCurrentForm("");
  }, []);

  useEffect(() => {
    if (isEditing) {
      if (!(editFormData.titleId > 0)) {
        editFormData.titleId = "other";
        setIsShowDesignation(true);
      }
    }
    return () => {
      if (isEditing) {
        setEditFormData("");
        setIsEditing(false);
      }
    };
  }, [isEditing]);

  if (loading && !isEditing) {
    return (
      <div className="grid place-items-center h-full">
        <div
          style={{
            width: 150,
            height: 150,
          }}
        >
          <CircularProgressbarWithChildren
            value={progressValue - 1}
            styles={buildStyles({
              strokeLinecap: "butt",
              pathColor: `#400835`,
              textColor: "#f88",
              trailColor: "#d6d6d6",
              backgroundColor: "#3e98c7",
            })}
          >
            <div className="text-center text-primary">
              <span className="font-bold text-4xl ml-2">
                {progressValue - 1}%
              </span>
              <p className="text-xs font-bold mt-1 space-x-10">
                {t("allModule.Uploading")}
              </p>
            </div>
          </CircularProgressbarWithChildren>
        </div>
      </div>
    );
  } else if (loading && isEditing) {
    return <LoadingScreen />;
  }
  return (
    <div className="text-primary text-base font-normal font-sans">
      <div className="main_head">
        <h1 className="text-4xl font-bold">
          {!isEditing
            ? t("allModule.Add Contact")
            : t("allModule.Edit Contact")}
        </h1>
      </div>

      <div className=" flex w-full items-center h-full upload_doc_page">
        <form className="w-2/5">
          <div className="flex flex-col mb-3 contract-type">
            <div className="flex">
              <label className="mb-2">{t("form.Name")}</label>
              <>
                <img
                  src={HelpIcon}
                  width={12}
                  className="ml-1 -mt-2 cursor-pointer"
                  data-tip={t("form.Insert full name")}
                />
                <ReactTooltip backgroundColor="#400835" textColor="#ffffff" />
              </>
            </div>
            <input
              placeholder={t("form.Insert full name")}
              className="border-2 border-primary rounded-lg outline-none p-1 pl-2"
              value={!isEditing ? contactObj.name : editFormData.name}
              onChange={(e) => {
                !isEditing
                  ? setContactObj({ ...contactObj, name: e.target.value })
                  : setEditFormData({
                      ...editFormData,
                      name: e.target.value,
                    });
              }}
            />
            <p className="text-sm text-red-500 font-normal">
              {contactFormErrors.name}
            </p>
          </div>

          <div className="flex flex-col mb-3">
            <div className="flex">
              <label className="mb-2">{t("form.Email")}</label>
              <>
                <img
                  src={HelpIcon}
                  width={12}
                  className="ml-1 -mt-2 cursor-pointer"
                  data-tip={t("form.Insert an unique email address")}
                />
                <ReactTooltip backgroundColor="#400835" textColor="#ffffff" />
              </>
            </div>
            <input
              placeholder={t("form.Insert an unique email address")}
              className="border-2 border-primary rounded-lg outline-none p-1 pl-2"
              value={!isEditing ? contactObj.email : editFormData.email}
              onChange={(e) => {
                !isEditing
                  ? setContactObj({ ...contactObj, email: e.target.value })
                  : setEditFormData({
                      ...editFormData,
                      email: e.target.value,
                    });
              }}
            />
            <p className="text-sm text-red-500 font-normal">
              {contactFormErrors.email}
            </p>
          </div>

          <div className="flex flex-col mb-3">
            <div className="flex">
              <label className="mb-2">{t("form.Confirm Email")}</label>
              <>
                <img
                  src={HelpIcon}
                  width={12}
                  className="ml-1 -mt-2 cursor-pointer"
                  data-tip={t("form.Confirm email address")}
                />
                <ReactTooltip backgroundColor="#400835" textColor="#ffffff" />
              </>
            </div>
            <input
              placeholder={t("form.Confirm email address")}
              className="border-2 border-primary rounded-lg outline-none p-1 pl-2"
              value={
                !isEditing ? contactObj.confirmEmail : editFormData.confirmEmail
              }
              onChange={(e) => {
                !isEditing
                  ? setContactObj({
                      ...contactObj,
                      confirmEmail: e.target.value,
                    })
                  : setEditFormData({
                      ...editFormData,
                      confirmEmail: e.target.value,
                    });
              }}
            />
            <p className="text-sm text-red-500 font-normal">
              {contactFormErrors.confirmEmail}
            </p>
          </div>

          <div className="flex flex-col mb-3">
            <label>{t("form.Phone")}</label>

            <CustomPhoneInput
              value={!isEditing ? contactObj.phone : editFormData.phone}
              onChange={(phone) => {
                !isEditing
                  ? setContactObj({ ...contactObj, phone })
                  : setEditFormData({
                      ...editFormData,
                      phone,
                    });
              }}
            />
            <p className="text-sm text-red-500 font-normal">
              {contactFormErrors.phone}
            </p>
          </div>

          <div className="flex flex-col mb-3 contract-type">
            <div className="flex">
              <label className="mb-2">{t("allModule.Group")}</label>
              <>
                <img
                  src={HelpIcon}
                  width={12}
                  className="ml-1 -mt-2 cursor-pointer"
                  data-tip={t("form.Select Group")}
                />
                <ReactTooltip backgroundColor="#400835" textColor="#ffffff" />
              </>
            </div>
            <Creatable
              options={groupListData}
              placeholder={t("form.Select Group")}
              styles={customStyles}
              isMulti={true}
              isSearchable={true}
              onChange={(arr) => {
                let formatedArr = [];
                arr.forEach((obj) =>
                  formatedArr.push(parseInt(obj.value.trim()))
                );
                //
                !isEditing
                  ? setContactObj({
                      ...contactObj,
                      groupList: formatedArr,
                    })
                  : setEditFormData({
                      ...editFormData,
                      groupList: formatedArr,
                    });
                setDefaultValues([...arr]);
              }}
              isClearable={false}
              className="w-full text-primary rounded-lg"
              defaultValue={defaultValues}
            />
            <p className="text-sm text-red-500 font-normal">
              {contactFormErrors.groupList}
            </p>
          </div>

          <div className="flex flex-col mb-3 contract-type">
            <div className="flex">
              <label className="mb-2">{t("form.Company")}</label>
              <>
                <img
                  src={HelpIcon}
                  width={12}
                  className="ml-1 -mt-2 cursor-pointer"
                  data-tip={t("form.Select Company")}
                />
                <ReactTooltip backgroundColor="#400835" textColor="#ffffff" />

                <input
                  type="button"
                  onClick={(e) => {
                    e.stopPropagation();
                    setCompanyModelOpen(true);
                  }}
                  className="bg-primary font-semibold text-white p-1 px-2 rounded-lg ml-auto mb-1"
                  value={t("form.Add Company")}
                />
              </>
            </div>
            <select
              value={!isEditing ? contactObj.companyId : editFormData.companyId}
              onChange={(e) => {
                !isEditing
                  ? setContactObj({ ...contactObj, companyId: e.target.value })
                  : setEditFormData({
                      ...editFormData,
                      companyId: e.target.value,
                    });
                getEntitytitleListApi(e.target.value);
              }}
              className="border-2 border-primary rounded-lg outline-none p-1 pl-2"
            >
              <option value="">{t("form.Select Company")}</option>
              {companyListData.map((item, index) => {
                return (
                  <option key={index} value={item.id}>
                    {item.name}
                  </option>
                );
              })}
            </select>
            <p className="text-sm text-red-500 font-normal">
              {contactFormErrors.companyId}
            </p>
          </div>

          <div className="flex flex-col mb-3 contract-type">
            <div className="flex">
              <label className="mb-2">{t("form.Title")}</label>
              <>
                <img
                  src={HelpIcon}
                  width={12}
                  className="ml-1 -mt-2 cursor-pointer"
                  data-tip={t("form.Select Title")}
                />
                <ReactTooltip backgroundColor="#400835" textColor="#ffffff" />
              </>
            </div>

            <select
              value={!isEditing ? contactObj.titleId : editFormData.titleId}
              onChange={(e) => {
                !isEditing
                  ? setContactObj({ ...contactObj, titleId: e.target.value })
                  : setEditFormData({
                      ...editFormData,
                      titleId: e.target.value,
                    });
                handleTitleChange(e.target.value);
              }}
              className="border-2 border-primary rounded-lg outline-none p-1 pl-2"
            >
              <option value="">{t("form.Select Title")}</option>
              {entityTitleListData.map((item, index) => {
                return (
                  <option key={index} value={item.id}>
                    {item.name}
                  </option>
                );
              })}
              <option value="other">{t("form.Other")}</option>
            </select>
            <p className="text-sm text-red-500 font-normal">
              {contactFormErrors.titleId}
            </p>
          </div>

          {isShowDesignation && (
            <div className="flex flex-col mb-3">
              <div className="flex">
                <label className="mb-2">{t("form.Enter Title")}</label>
                <>
                  <img
                    src={HelpIcon}
                    width={12}
                    className="ml-1 -mt-2 cursor-pointer"
                    data-tip={t("form.Enter Title")}
                  />
                  <ReactTooltip backgroundColor="#400835" textColor="#ffffff" />
                </>
              </div>
              <input
                placeholder={t("form.Enter Title")}
                className="border-2 border-primary rounded-lg outline-none p-1 pl-2"
                value={
                  !isEditing ? contactObj.designation : editFormData.designation
                }
                onChange={(e) => {
                  !isEditing
                    ? setContactObj({
                        ...contactObj,
                        designation: e.target.value,
                      })
                    : setEditFormData({
                        ...editFormData,
                        designation: e.target.value,
                      });
                }}
              />
              <p className="text-sm text-red-500 font-normal">
                {contactFormErrors.designation}
              </p>
            </div>
          )}

          <div className="flex flex-col mb-3">
            <div className="flex">
              <label className="mb-2">{t("form.Set as Delegatee")}</label>
              <>
                <img
                  src={HelpIcon}
                  width={12}
                  className="ml-1 -mt-2 cursor-pointer"
                  data-tip={t("form.Set as Delegatee")}
                />
                <ReactTooltip backgroundColor="#400835" textColor="#ffffff" />
              </>
            </div>

            <div className="flex">
              <div className="toggle-switch">
                <input
                  type="checkbox"
                  checked={
                    !isEditing ? contactObj.delegatee : editFormData.delegatee
                  }
                  onChange={(e) => {
                    !isEditing
                      ? setContactObj({
                          ...contactObj,
                          delegatee: e.target.checked,
                        })
                      : setEditFormData({
                          ...editFormData,
                          delegatee: e.target.checked,
                        });
                  }}
                  className="checkbox"
                  name={"delegatee"}
                  id={"delegatee"}
                />
                <label className="label" htmlFor={"delegatee"}>
                  <span className="inner" />
                  <span className="switch" />
                </label>
              </div>
            </div>
          </div>

          {!isEditing ? (
            <>
              <button
                className="bg-primary font-bold text-white w-full p-1 rounded-lg mt-3 mb-4"
                onClick={(e) => {
                  e.preventDefault();
                  saveOrEditContact(contactObj);
                }}
              >
                {t("allModule.Add Contact")}
              </button>
              <p className="text-sm text-red-500 font-normal">
                {contactFormErrors.document}
              </p>
            </>
          ) : (
            <button
              className="bg-secondary font-bold text-white w-full p-1 rounded-lg"
              onClick={(e) => {
                e.preventDefault();
                saveOrEditContact(editFormData, repoName);
              }}
            >
              {t("allModule.Update Contact")}
            </button>
          )}
        </form>
        {!isEditing && (
          <>
            <div className=" ml-12  w-2/5 flex items-center wrap_uys">
              <div
                className="bodder mb-4 pos_uu1"
                style={{
                  height: "300px",
                  width: 5,
                  background: "#400835",
                  borderRadius: 10,
                }}
              ></div>
              <div className="w-full flex pd_md1d">
                <a
                  className="bg-secondary font-bold text-white p-1 px-2 rounded-lg mb-3"
                  download="contact_sample.csv"
                  href={ContactsFile}
                >
                  {t("form.Download Template")}
                </a>

                <p className="text-sm text-red-500 font-normal ml-5 mt-3">
                  {contactFormErrors.multi_document}
                </p>

                <button
                  className="bg-secondary font-bold text-white ml-2 p-1 px-2 rounded-lg contact-book-right-btn mb-3"
                  onClick={() => fileInput.current.click()}
                >
                  {t("form.Bulk Upload Contacts")}
                </button>
                <input
                  id="files"
                  style={{ display: "none" }}
                  type="file"
                  ref={fileInput}
                  onChange={handleFileChange}
                  accept=".csv"
                />
                <p className="text-sm text-red-500 font-normal ml-5 mt-3">
                  {contactFormErrors.multi_document}
                </p>
              </div>
              {errorContactList && errorContactList.length > 0 && (
                <div
                  className="pd_md1d mt-5"
                  style={{
                    width: "100%",
                  }}
                >
                  <div className="table_desin mb-4">
                    <div className="">
                      <div className="flex justify-between">
                        <p className="pl-2">
                          {t("form.Contact Failed to Save")}
                        </p>
                      </div>
                      <table className="w-full">
                        <thead>
                          <tr>
                            <th>{t("form.Email")}</th>
                            <th>{t("form.Phone")}</th>
                          </tr>
                        </thead>
                        <tbody>
                          {errorContactList.map((item, index) => {
                            return (
                              <tr key={index}>
                                <td>{item.email}</td>
                                <td>{item.phone}</td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              )}
            </div>

            <div className=" ml-5  w-2/5">
              {value !== null && error == null && (
                <>
                  <div>{responceMsg.msg}</div>

                  <div className="set_buttonclod">
                    {value?.name}

                    <button
                      onClick={deleteFile}
                      className="btn btn_theme2 btn-sm ml-3 mb-3"
                    >
                      X
                    </button>
                  </div>
                  <button
                    className="btn btn_theme btn-sm ml-3 mb-3"
                    onClick={bulkUploadContacts}
                  >
                    {t("form.Save Contacts")}
                  </button>
                </>
              )}
            </div>
          </>
        )}
      </div>

      <AppModal
        title={modalData.title}
        bodyText={modalData.bodyText}
        setIsOpen={setIsOpen}
        modalIsOpen={modalIsOpen}
        setContactObj={setContactObj}
        contactObj={contactObj}
        setMultipleContractsObj={setMultipleContractsObj}
        setIsContractModalOpen={setIsContractModalOpen}
        modelAction="ContactBook"
        apiResStatus={modalData.apiResStatus}
      />

      <AddCompanyModel
        companyModelOpen={companyModelOpen}
        setCompanyModelOpen={setCompanyModelOpen}
        getCompanyListApi={getCompanyListApi}
      />
    </div>
  );
};

export default ContactBookForm;
