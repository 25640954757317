import React, { useEffect, useState, useContext } from "react";
import Search from "../../assets/icons/Search.svg";
import CloseIcon from "../../assets/icons/Close.svg";
import { DemoUserListTableColumns, DemoUser2ListTableColumns, DemoUser3ListTableColumns } from "../../constants/constants";
import useAxios from "../../hooks/useAxios";
import { resolve } from "../../api/resolve";
import DemoUserListTable from "./DemoUserListTable";
import { parseISO, format } from "date-fns";
import AddButton from "../../shared/AddButton";
import AssignRoleModel from "../modals/AssignRoleModel";
import DemoLogListTable from "./DemoLogListTable";

const DemoUserList = () => {
  const api = useAxios();
  // pending doc related states
  const [userList, setUserList] = useState([]);
  const [userSearchText, setUserSearchText] = useState("");
  const [userCurrentPage, setUserCurrentPage] = useState(1);
  const [userPageLimit, setUserPageLimit] = useState(5);
  const [userNextBtnLimit, setUserNextBtnLimit] = useState(0);
  const [userCount, setUserCount] = useState(0);
  const [errMsgUser, setErrMsgUser] = useState("");
  const [showUserClearIcon, setShowUserClearIcon] = useState(false);
  const [sortingOrder, setSortingOrder] = useState("desc");
  const [sortColumn, setSortColumn] = useState("id");

  const [userListReg, setUserListReg] = useState([]);
  const [userSearchTextReg, setUserSearchTextReg] = useState("");
  const [userCurrentPageReg, setUserCurrentPageReg] = useState(1);
  const [userPageLimitReg, setUserPageLimitReg] = useState(5);
  const [userNextBtnLimitReg, setUserNextBtnLimitReg] = useState(0);
  const [userCountReg, setUserCountReg] = useState(0);
  const [errMsgUserReg, setErrMsgUserReg] = useState("");
  const [showUserClearIconReg, setShowUserClearIconReg] = useState(false);
  const [sortingOrderReg, setSortingOrderReg] = useState("desc");
  const [sortColumnReg, setSortColumnReg] = useState("id");
  const [activeTable, setActiveTable] = useState("demoUser");
  const [showAssignRoleModal, setShowAssignRoleModal] = useState(false);
  const [assignedToRoleList, setAssignedToRoleList] = useState([]);
  const [corporateInfo, setCorporateInfo] = useState({});


  const [userListLog, setUserListLog] = useState([]);
  const [userSearchTextLog, setUserSearchTextLog] = useState("");
  const [userCurrentPageLog, setUserCurrentPageLog] = useState(1);
  const [userPageLimitLog, setUserPageLimitLog] = useState(5);
  const [userNextBtnLimitLog, setUserNextBtnLimitLog] = useState(0);
  const [userCountLog, setUserCountLog] = useState(0);
  const [errMsgUserLog, setErrMsgUserLog] = useState("");
  const [showUserClearIconLog, setShowUserClearIconLog] = useState(false);
  const [sortingOrderLog, setSortingOrderLog] = useState("desc");
  const [sortColumnLog, setSortColumnLog] = useState("id");

  const getDemoUserList = async (clearText) => {
    const res = await resolve(
      api
        .get(
          `user/corportate/user/?ordering=${sortColumn}&sortingOrder=${sortingOrder}&search=${clearText ? "" : userSearchText
          }&page=${userCurrentPage}&limit=${userPageLimit}&is_demo_account=true`
        )
        .then((res) => res.data)
    );

    if (res.data) {
      const { data } = res;
      const { results, count } = data;
      // const count= 5;
      // const results =   data;
      const filterData = [];
      if (results.length > 0) {
        setUserCount(count);
        results.forEach((result) => {
          const userObj = {
            id: result.id,
            name: result.first_name,
            designation: result.designation,
            companyName: result.company_name,
            planId: result.plan_id.toString(),
            days: result.days?.toString(),
            email: result.email,
            is_active: result.is_active_user
            ? "Yes" : "No",
            is_demo_account: result.is_demo_account ? "Yes" : "No",
            isDemoUser: result.is_demo_account,
            isGenericTemplatesRequire: result.generic_template,
            create_at: format(
              parseISO(result.created_at),
              "ccc, dd MMM yyyy, HH:mm"
            ),
            plan_name: result.plan_name,
            paid_amount: result.paid_amount,
            valid_till: result.valid_till?format(parseISO(result.valid_till), 'ccc, dd MMM yyyy, HH:mm'):'-',
          };
          filterData.push(userObj);
        });
      } else {
        if (userCurrentPage === 1) setErrMsgUser("No user added yet");
        else setErrMsgUser("No more user to view");
      }
      setUserList([...filterData]);
      setUserNextBtnLimit(Math.ceil(count / userPageLimit));
    } else {
      setUserNextBtnLimit(0);
    }
  };

  const getDemoUserListReg = async (clearText) => {
    const res = await resolve(
      api
        .get(
          `user/corportate/user/?ordering=${sortColumnReg}&sortingOrder=${sortingOrderReg}&search=${clearText ? "" : userSearchTextReg
          }&page=${userCurrentPageReg}&limit=${userPageLimitReg}&is_demo_account=false`
        )
        .then((res) => res.data)
    );

    if (res.data) {
      const { data } = res;
      const { results, count } = data;
      const filterData = [];
      if (results.length > 0) {
        setUserCountReg(count);
        results.forEach((result) => {
          const userObj = {
            id: result.id,
            name: result.first_name,
            designation: result.designation,
            companyName: result.company_name,
            planId: result.plan_id.toString(),
            days: result.days?.toString(),
            email: result.email,
            is_active: result.is_active_user
            ? "Yes" : "No",
            is_demo_account: result.is_demo_account ? "Yes" : "No",
            isDemoUser: result.is_demo_account,
            isGenericTemplatesRequire: result.generic_template,
            document_status: result.document_status == 0 ? 'Not uploaded yet' :
                            result.document_status == 1 ? 'Review' :
                                result.document_status == 2 ? 'Accepted' : 'Rejected',
            create_at: format(
              parseISO(result.created_at),
              "ccc, dd MMM yyyy, HH:mm"
            ),

            plan_name: result.plan_name,
            paid_amount: result.paid_amount,
            valid_till: result.valid_till?format(parseISO(result.valid_till), 'ccc, dd MMM yyyy, HH:mm'):'-',
          };
          filterData.push(userObj);
        });
      } else {
        if (userCurrentPageReg === 1) setErrMsgUserReg("No user added yet");
        else setErrMsgUserReg("No more user to view");
      }
      setUserListReg([...filterData]);
      setUserNextBtnLimitReg(Math.ceil(count / userPageLimit));
    } else {
      setUserNextBtnLimitReg(0);
    }
  };

  const getDemoUserListLog = async (clearText) => {
    const res = await resolve(
      api
        .get(
          `user/demo/log/?ordering=${sortColumnLog}&sortingOrder=${sortingOrderLog}&search=${clearText ? "" : userSearchTextLog
          }&page=${userCurrentPageLog}&limit=${userPageLimitLog}&is_demo_account=false`
        )
        .then((res) => res.data)
    );

    if (res.data) {
      const { data } = res;
      const { results, count } = data;
      const filterData = [];
      if (results.length > 0) {
        setUserCountLog(count);
        results.forEach((result) => {
          const userObj = {
            id: result.id,
            name: result.name,
            email: result.DemoUserEmail,
            is_demo_account: "Yes",
            isGenericTemplatesRequire: result.generic_template,
            isDemoUser: true,
            create_at: format(
              parseISO(result.created_at),
              "ccc, dd MMM yyyy, HH:mm"
            ),
          };
          filterData.push(userObj);
        });
      } else {
        if (userCurrentPageLog === 1) setErrMsgUserLog("No user added yet");
        else setErrMsgUserLog("No more user to view");
      }
      setUserListLog([...filterData]);
      setUserNextBtnLimitLog(Math.ceil(count / userPageLimit));
    } else {
      setUserNextBtnLimitLog(0);
    }
  };

  const handleActiveList = (tableName) => {
    setActiveTable(tableName);
  };

  const getAdminUserList = async (adminId) => {
    const res = await resolve(
      api.get(`user/UserList/?AdminId=${adminId}`).then((res) => res.data)
    );
    const filterData = [];
    if (res.data) {
      const { data } = res;
      const { results, count } = data;
      
      if (results.length > 0) {        
        results.forEach((result) => {
          const userObj = {
            id: result.id,
            first_name: result.first_name,
            last_name: result.last_name ? result.last_name : "",
            email: result.email,
            phone: result.phone,
          };
          filterData.push(userObj);
        });
      } 
    } 
    setAssignedToRoleList([...filterData]);
  };
  const changeRole = async (userInfo) => {
    if (userInfo.isDemoUser===false) {
      setCorporateInfo(userInfo);
      getAdminUserList(userInfo.id);
      setShowAssignRoleModal(true);
    }
  };

  useEffect(() => {
    getDemoUserList();
  }, [sortColumn, sortingOrder, userCurrentPage, userPageLimit]);

  useEffect(() => {
    getDemoUserListReg();
  }, [sortColumnReg, sortingOrderReg, userCurrentPageReg, userPageLimitReg]);

  useEffect(() => {
    getDemoUserListLog();
  }, [sortColumnLog, sortingOrderLog, userCurrentPageLog, userPageLimitLog]);

  return (
    <>
      <div className="text-primary">
        <div className="main_head">
          <h1 className="text-4xl font-bold">List of Corporate Users</h1>
        </div>
        <div className="nav_tab mb-4">
          <ul className="ul_set">
            <li
              onClick={(e) => {
                e.stopPropagation();
                handleActiveList("demoUser");
                if(activeTable !== "demoUser"){
                  setUserCurrentPage(1);
                  getDemoUserList();
                }
              }}
              className={activeTable === "demoUser" ? "active" : ""}
            >
              <a href="#">Demo User </a>
            </li>
            <li
              onClick={(e) => {
                e.stopPropagation();
                handleActiveList("regularUser");
                if(activeTable !== "regularUser"){
                  setUserCurrentPageReg(1);
                  getDemoUserListReg();
                }
              }}
              className={activeTable === "regularUser" ? "active" : ""}
            >
              <a href="#">Regular User </a>
            </li>

            <li
              onClick={(e) => {
                e.stopPropagation();
                handleActiveList("demoLog");
                if(activeTable !== "demoLog"){
                  setUserCurrentPageLog(1);
                  getDemoUserListLog();
                }
              }}
              className={activeTable === "demoLog" ? "active" : ""}
            >
              <a href="#">Deleted Demo Log</a>
            </li>
          </ul>
        </div>

        <div className="tab_main">
          <div className={`tab_us ${activeTable === "demoUser" ? "show" : ""}`}>
            <div className="row justify-between">
              <div className="col-md-5 flex_contact">
                <form>
                  <div className="flex mt-3  w-full">
                    <div className="flex justify-between items-center border-2 border-primary rounded-md rounded-tl-lg rounded-bl-lg bg-inputBackground px-2 mr-2 w-full ">
                      <input
                        placeholder="Search for user"
                        className="px-1 py-1 focus:outline-none bg-transparent w-11/12"
                        value={userSearchText}
                        onChange={(e) => {
                          setUserSearchText(e.target.value);
                          setShowUserClearIcon(true);
                          if (!e.target.value.trim()) {
                            setShowUserClearIcon(false);
                          }
                        }}
                      />
                      {showUserClearIcon && (
                        <img
                          src={CloseIcon}
                          width={14}
                          className="cursor-pointer"
                          onClick={() => {
                            setUserSearchText("");
                            getDemoUserList(true);
                            setShowUserClearIcon(false);
                          }}
                        />
                      )}
                    </div>
                    <button
                      type="submit"
                      className="flex btn btn_theme2 w_125 items-center justify-between"
                      onClick={(e) => {
                        e.preventDefault();
                        setUserCurrentPage(1);
                        getDemoUserList();
                      }}
                    >
                      <img src={Search} style={{ width: "1rem" }} />
                      <span className="font-semibold ml-1">Search</span>
                    </button>
                  </div>
                </form>
              </div>
              <div className="col-md-5">
                <div className="flex justify-end mt-3">
                  <AddButton text="Add New User" currentForm="DemoUserForm" />
                </div>
              </div>
            </div>
            <div className="mt-3 rounded-lg">
              {userList.length < 1 && (
                <p className="text-red-500 text-sm font-medium">{errMsgUser}</p>
              )}
            </div>
            <DemoUserListTable
              tableColumns={DemoUserListTableColumns}
              getListData={getDemoUserList}
              tableData={userList}
              showActionBtn
              nextBtnLimit={userNextBtnLimit}
              currentPage={userCurrentPage}
              setCurrentPage={setUserCurrentPage}
              itemCount={userCount}
              setPageLimit={setUserPageLimit}
              showPageLimit={true}
              sortingOrder={sortingOrder}
              setSortingOrder={setSortingOrder}
              setSortColumn={setSortColumn}
            />
          </div>
          <div className={`tab_us ${activeTable === "regularUser" ? "show" : ""}`}>
            <div className="row justify-between">
              <div className="col-md-5 flex_contact">
                <form>
                  <div className="flex mt-3  w-full">
                    <div className="flex justify-between items-center border-2 border-primary rounded-md rounded-tl-lg rounded-bl-lg bg-inputBackground px-2 mr-2 w-full ">
                      <input
                        placeholder="Search for user"
                        className="px-1 py-1 focus:outline-none bg-transparent w-11/12"
                        value={userSearchTextReg}
                        onChange={(e) => {
                          setUserSearchTextReg(e.target.value);
                          setShowUserClearIconReg(true);
                          if (!e.target.value.trim()) {
                            setShowUserClearIconReg(false);
                          }
                        }}
                      />
                      {showUserClearIconReg && (
                        <img
                          src={CloseIcon}
                          width={14}
                          className="cursor-pointer"
                          onClick={() => {
                            setUserSearchTextReg("");
                            getDemoUserListReg(true);
                            setShowUserClearIconReg(false);
                          }}
                        />
                      )}
                    </div>
                    <button
                      type="submit"
                      className="flex btn btn_theme2 w_125 items-center justify-between"
                      onClick={(e) => {
                        e.preventDefault();
                        setUserCurrentPageReg(1);
                        getDemoUserListReg();
                      }}
                    >
                      <img src={Search} style={{ width: "1rem" }} />
                      <span className="font-semibold ml-1">Search</span>
                    </button>
                  </div>
                </form>
              </div>
              <div className="col-md-5">
                <div className="flex justify-end mt-3">
                  <AddButton text="Add New User" currentForm="DemoUserForm" />
                </div>
              </div>
            </div>
            <div className="mt-3 rounded-lg">
              {userListReg.length < 1 && (
                <p className="text-red-500 text-sm font-medium">{errMsgUserReg}</p>
              )}
            </div>
            <DemoUserListTable
              tableColumns={DemoUser2ListTableColumns}
              getListData={getDemoUserListReg}
              tableData={userListReg}
              showActionBtn
              nextBtnLimit={userNextBtnLimitReg}
              currentPage={userCurrentPageReg}
              setCurrentPage={setUserCurrentPageReg}
              itemCount={userCountReg}
              setPageLimit={setUserPageLimitReg}
              showPageLimit={true}
              sortingOrder={sortingOrderReg}
              setSortingOrder={setSortingOrderReg}
              setSortColumn={setSortColumnReg}
              changeRole={changeRole}
              activeTable={activeTable}
            />
          </div>
          <div className={`tab_us ${activeTable === "demoLog" ? "show" : ""}`}>
            <div className="row justify-between">
              <div className="col-md-5 flex_contact">
                <form>
                  <div className="flex mt-3  w-full">
                    <div className="flex justify-between items-center border-2 border-primary rounded-md rounded-tl-lg rounded-bl-lg bg-inputBackground px-2 mr-2 w-full ">
                      <input
                        placeholder="Search for user"
                        className="px-1 py-1 focus:outline-none bg-transparent w-11/12"
                        value={userSearchTextLog}
                        onChange={(e) => {
                          setUserSearchTextLog(e.target.value);
                          setShowUserClearIconLog(true);
                          if (!e.target.value.trim()) {
                            setShowUserClearIconLog(false);
                          }
                        }}
                      />
                      {showUserClearIconLog && (
                        <img
                          src={CloseIcon}
                          width={14}
                          className="cursor-pointer"
                          onClick={() => {
                            setUserSearchTextLog("");
                            getDemoUserListLog(true);
                            setShowUserClearIconLog(false);
                          }}
                        />
                      )}
                    </div>
                    <button
                      type="submit"
                      className="flex btn btn_theme2 w_125 items-center justify-between"
                      onClick={(e) => {
                        e.preventDefault();
                        setUserCurrentPageLog(1);
                        getDemoUserListLog();
                      }}
                    >
                      <img src={Search} style={{ width: "1rem" }} />
                      <span className="font-semibold ml-1">Search</span>
                    </button>
                  </div>
                </form>
              </div>
              
            </div>
            <div className="mt-3 rounded-lg">
              {userListLog.length < 1 && (
                <p className="text-red-500 text-sm font-medium">{errMsgUserLog}</p>
              )}
            </div>
            <DemoLogListTable
              tableColumns={DemoUser3ListTableColumns}
              getListData={getDemoUserListLog}
              tableData={userListLog}
              showActionBtn
              nextBtnLimit={userNextBtnLimitLog}
              currentPage={userCurrentPageLog}
              setCurrentPage={setUserCurrentPageLog}
              itemCount={userCountLog}
              setPageLimit={setUserPageLimitLog}
              showPageLimit={true}
              sortingOrder={sortingOrderLog}
              setSortingOrder={setSortingOrderLog}
              setSortColumn={setSortColumnLog}
              changeRole={changeRole}
            />
          </div>

        </div>
      </div>

      <AssignRoleModel
        isOpen={showAssignRoleModal}
        setIsOpen={setShowAssignRoleModal}
        assignedToRoleList={assignedToRoleList}
        corporateInfo={corporateInfo}
        getDemoUserListReg={getDemoUserListReg}
      />
    </>
  );
};

export default DemoUserList;
