export const validateEmail = (email) => {
  return String(email)
    .toLowerCase()
    .trim()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};

export const validatePassword = (password) => {
  return String(password)
    .toLowerCase()
    .trim()
    .match(/^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,16}$/);
};
export const validatePhone = (phoneNumber) => {
  return String(phoneNumber).match(/^[0-9]{10}$/g);
};

export const formatClause = (clause) => {
  let wordArray = clause.replace('clause', '').split(' ');
  let result = '';
  wordArray.forEach((word) => {
    if (word.length > 0) {
      result += ` ${word[0].toUpperCase()}${word.slice(1)}`;
    }
  });
  return result;
};

export const formatSelectedClause = (clause) => {
  if (clause) {
    let wordArray = clause.toLowerCase().replaceAll('clause', '').split(' ');
    let result = '';
    wordArray.forEach((word) => {
      if (word && word.length > 0) {
        result += ` ${word[0].toUpperCase()}${word.slice(1)}`;
      }
    });
    return result;
  }
};

export const getCookie = (name) => {
  var cookieValue = null;
  if (document.cookie && document.cookie !== '') {
    var cookies = document.cookie.split(';');
    for (var i = 0; i < cookies.length; i++) {
      var cookie = cookies[i].trim();
      if (cookie.substring(0, name.length + 1) === name + '=') {
        cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
        break;
      }
    }
  }
  return cookieValue;
};
