import React, { useState, useEffect, useContext } from "react";
import { useTranslation } from "react-i18next";
import Select from "react-select";
import SearchIcon from "../../assets/icons/searchicon.png";
import quoqo from "../../assets/icons/quoqo.png";
import CNLaw from "../../assets/icons/CNLaw.jpg";
import useAxios from "../../hooks/useAxios";
import LoadingScreen from "../loader/LoadingScreen";
import ReactTooltip from "react-tooltip";
import "./marketplace.css";
import AuthContext from "../../context/AuthContext";

function truncate(str, maxLength) {
  return str.length > maxLength ? str.slice(0, maxLength) + ".." : str;
}

const Marketplace = () => {
  const { t } = useTranslation();
  const [searchText, setSearchText] = useState("");
  const [selectedSpecialization, setSelectedSpecialization] = useState(null);
  const [firmDetails, setFirmDetails] = useState([]);
  const [specializations, setSpecializations] = useState([]);
  const [loading, setLoading] = useState(true);
  const baseURL = process.env.REACT_APP_BACKEND_BASE_URL;
  const api = useAxios();
  const { theme, toggleTheme } = useContext(AuthContext);

  const fetchFirmData = async () => {
    try {
      const firmDataResponse = await api.get(
        `${baseURL}/contract/marketplace/view/user`
      );

      setFirmDetails(firmDataResponse.data);

      const specializationDataResponse = await api.get(
        `${baseURL}/contract/specialisationmarket/view/`
      );
      const apiSpecializations = specializationDataResponse.data.map(
        (item) => ({
          value: item.id,
          label: item.specialisation,
        })
      );
      const specializationsWithAll = [
        { value: "all", label: "All" },
        ...apiSpecializations,
      ];
      setSpecializations(specializationsWithAll);
      setSelectedSpecialization(specializationsWithAll[0]);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchFirmData();
  }, []);

  const handleSpecializationChange = (selectedOption) => {
    setSelectedSpecialization(selectedOption);
  };

  const handleUseClick = async (e, firmId) => {
    e.stopPropagation();
    try {
      const postData = { marketplace_id: firmId };
      const response = await api.post(
        `${baseURL}/contract/marketplace/user/add`,
        postData
      );
      fetchFirmData();
    } catch (error) {
      console.error("Error using firm:", error);
    }
  };

  const handleRemoveClick = async (e, firmId) => {
    e.stopPropagation();
    try {
      const postData = { marketplace_id: firmId };
      const response = await api.post(
        `${baseURL}/contract/marketplace/user/delete`,
        postData
      );
      fetchFirmData();
    } catch (error) {
      console.error("Error using firm:", error);
    }
  };

  const renderDetails = () => {
    if (selectedSpecialization) {
      let filteredFirmDetails = [];

      // Filter firms based on selected specialization
      if (selectedSpecialization.value === "all") {
        filteredFirmDetails = firmDetails;
      } else {
        filteredFirmDetails = firmDetails.filter(
          (firm) => firm.specialisation === selectedSpecialization.label
        );
      }

      if (searchText) {
        filteredFirmDetails = filteredFirmDetails.filter((firm) => {
          const addressString = `${firm.address}`;
          // const addressString = `${firm.address}, ${firm.city}, ${firm.state}, ${firm.country}`;
          return addressString.toLowerCase().includes(searchText.toLowerCase());
        });
      }

      return (
        <div className="card-container">
          {/* Default card for Quoqo */}
          {/* <button onClick={toggleTheme}>
            Switch to {theme === "light" ? "Dark" : "Light"} Theme
          </button> */}
          <div className="card">
            <div className="card-body text-center">
              <img src={CNLaw} alt="CNLAW" className="card-img-default" />
              <h5 className="card-title">Chetan Nagendra Law Offices</h5>
              <p className="card-text">Corporate Law</p>
              <div className="card-actions">
                {/* <button className="btn btn_theme2 m-2">{t("Button")}</button> */}
              </div>
            </div>
          </div>

          {/* Render filtered firm details */}
          {filteredFirmDetails.map((firm, index) => (
            <div
              key={index}
              className={"card" + (firm.isSelected ? " selected" : "")}
            >
              <div className="card-body text-center">
                {/* Render firm logo */}
                {firm.logo_upload ? (
                  <img
                    src={`data:image/png;base64,${firm.logo_upload}`}
                    alt="Firm Logo"
                    className="card-img"
                  />
                ) : (
                  <img
                    src={`data:image/png;base64,${quoqo}`}
                    alt="Firm Logo"
                    className="card-img"
                  />
                )}
                <ReactTooltip backgroundColor="#400835" textColor="#ffffff" />
                {/* Render firm name */}
                <div>
                  <h5
                    className={
                      "card-title" + (firm.isSelected ? " selected" : "")
                    }
                    data-tip={firm.firm_name.toUpperCase()}
                  >
                    {truncate(firm.firm_name.toUpperCase(), 50)}
                  </h5>
                  {/* Render firm specialization */}
                  <p
                    className={
                      "card-text" + (firm.isSelected ? " selected" : "")
                    }
                  >
                    {firm.specialisation}
                  </p>
                </div>
                <div className="card-actions">
                  {/* Render Use/Remove button based on firm selection */}
                  {firm.isSelected ? (
                    <button
                      className="btn btn_theme2 m-2"
                      onClick={(e) => handleRemoveClick(e, firm.id)}
                    >
                      {t("Remove")}
                    </button>
                  ) : (
                    <button
                      className="btn btn_theme2 m-2"
                      onClick={(e) => handleUseClick(e, firm.id)}
                    >
                      {t("Use")}
                    </button>
                  )}
                </div>
              </div>
            </div>
          ))}
        </div>
      );
    }
  };

  if (loading) {
    return <LoadingScreen />;
  }

  return (
    <div className="text-primary text-base font-normal font-sans mt-5">
      <div className="flex items-center">
        <h1 className="text-4xl font-bold mb-4">{t("menu.Service Providers")}</h1>
      </div>
      <div
        className="flex justify-content-end mb-3"
        style={{ width: "100%", marginTop: "20px", marginBottom: "40px" }}
      >
        <div
          style={{
            width: "20%",
            marginRight: "10px",
            position: "relative",
            marginLeft: "auto",
          }}
        >
          <input
            type="text"
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
            placeholder={t("Search by Location")}
            className="p-2 outline-none w-full"
            style={{
              border: "2px solid #310128",
              borderRadius: "5px",
              height: "40px",
            }}
          />
          <img
            src={SearchIcon}
            alt="Search"
            style={{
              position: "absolute",
              right: "12px",
              top: "calc(50% - 10px)",
              height: "20px",
              cursor: "pointer",
            }}
            // onClick={handleSearchClick}
          />
        </div>
        <div style={{ width: "20%" }}>
          <Select
            value={selectedSpecialization}
            onChange={handleSpecializationChange}
            options={specializations} // Use fetched specializations for the dropdown
            placeholder={t("Select Specialization")}
            classNamePrefix="custom-select"
            styles={{
              control: (base) => ({
                ...base,
                border: "2px solid #310128",
                borderRadius: "5px",
                height: "40px",
                minHeight: "40px",
                width: "100%",
                "&:hover": { borderColor: "#310128" },
              }),
              option: (base, state) => ({
                ...base,
                backgroundColor: state.isFocused ? "#f2f2f2" : "white",
                color: state.isFocused ? "#310128" : "black",
                "&:hover": {
                  backgroundColor: "#f2f2f2",
                  color: "#310128",
                },
              }),
              dropdownIndicator: (base) => ({ ...base, padding: "0 8px" }),
              indicatorSeparator: (base) => ({ ...base, display: "none" }),
              singleValue: (base) => ({ ...base, color: "#310128" }),
              menu: (base) => ({
                ...base,
                border: "2px solid #310128",
                borderRadius: "5px",
                boxShadow: "none",
                marginTop: "2px",
              }),
            }}
          />
        </div>
      </div>
      {renderDetails()}
    </div>
  );
};

export default Marketplace;
