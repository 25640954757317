import React, { useState } from "react";
import "./clauseai.css"; // Ensure this file contains your custom styles

// Import your specific chat components
import ClauseAINew from "./clauseaiNew";
import DocChatAINew from "./docChatAINew";
import ClauseLibraryChatNew from "./ClauseLibraryChatNew";
import GenrateDocumentAnalysis from "./GenrateDocumentAnalysis";

// Import icons
import clauseIcon from "../../assets/icons/artificial-intelligence.png";
import docChatIcon from "../../assets/icons/aichat.svg";
import libraryIcon from "../../assets/icons/Libaryai.svg";
import ip from "../../assets/icons/ip_black.png";

const QChat = React.forwardRef((props, ref) => {
  const { documentData, className } = props;
  const [activeTab, setActiveTab] = useState("ClauseAINew");

  const renderActiveTab = () => {
    switch (activeTab) {
      case "ClauseAINew":
        return <ClauseAINew documentData={documentData} />;
      case "DocChatAINew":
        return <DocChatAINew documentData={documentData} />;
      case "ClauseLibraryChatNew":
        return <ClauseLibraryChatNew documentData={documentData} />;
      case "GenrateDocumentAnalysis":
        return <GenrateDocumentAnalysis documentData={documentData} />;
      default:
        return null;
    }
  };

  return (
    <div className={`chat-window ${className}`}>
      <div className="tab-header">
        <div
          className={`tab ${activeTab === "ClauseAINew" ? "active" : ""}`}
          onClick={() => setActiveTab("ClauseAINew")}
        >
          <img src={clauseIcon} alt="Clause AI" className="tab-icon" />
        </div>
        <div
          className={`tab ${activeTab === "DocChatAINew" ? "active" : ""}`}
          onClick={() => setActiveTab("DocChatAINew")}
        >
          <img src={docChatIcon} alt="Doc Chat AI" className="tab-icon" />
        </div>
        <div
          className={`tab ${
            activeTab === "ClauseLibraryChatNew" ? "active" : ""
          }`}
          onClick={() => setActiveTab("ClauseLibraryChatNew")}
        >
          <img
            src={libraryIcon}
            alt="Clause Library Chat"
            className="tab-icon"
          />
        </div>
        <div
          className={`tab ${
            activeTab === "GenrateDocumentAnalysis" ? "active" : ""
          }`}
          onClick={() => setActiveTab("GenrateDocumentAnalysis")}
        >
          <img
            src={ip}
            alt="Genrate Document Analysis"
            className="tab-icon"
          />
        </div>
      </div>
      <div className="tab-content">{renderActiveTab()}</div>
    </div>
  );
});

export default QChat;
