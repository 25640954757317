import React, { useState,useEffect } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import "./shareButton.css"; // Update the path accordingly
import axios from "axios";
import useAxios from "../../hooks/useAxios";
import documentsharingiconlegal from "../../assets/icons/documentsharingiconlegal.svg";
import ReactTooltip from 'react-tooltip';
import HelpIcon from '../../assets/icons/Help-sm.svg';
import addbuttons from "../../assets/icons/addbuttons.svg";


const ShareButton = ({docId,onClick, onShare, onDelete }) => {
  const [showSharePopup, setShowSharePopup] = useState(false);
  const [showError, setShowError] = useState(true);
  const [selectedspecialisation, setselectedspecialisation] = useState('');
  const [specialisationlist, setspecialisationlist] = useState([]);
  const [FirmNameList,setFirmNameList] = useState([]);
  const [selectedfirm,setselectedfirm] = useState('');
  const [MarketPlaceData,setMarketPlaceData] = useState([]);
  const [shareData, setShareData] = useState({
    kindOfLegalAssistance: "",
    selectedProvider: "",
    description: "",
    contactDetails: "",
    email: "",
    phoneNumber: "",
    expectedDate: "",
    name : "",
  });
  const [shareSuccess, setShareSuccess] = useState(false);
  const baseUrl = process.env.REACT_APP_BACKEND_BASE_URL;

  const { t } = useTranslation();

  const handleShareClick = () => {
    setShowSharePopup(true);
    document.body.style.overflow = "hidden"; // Prevent scrolling on the body when popup is open
    setspecialisationlist([]);
    setFirmNameList([]);
    fetchData();
    
  };

  const handleClosePopup = () => {
    setShowSharePopup(false);
    setShareSuccess(false);
    document.body.style.overflow = ""; // Enable scrolling on the body when popup is closed
    setselectedfirm("");
    setselectedspecialisation("");
    setShareData({
      kindOfLegalAssistance: "",
      selectedProvider: "",
      description: "",
      contactDetails: "",
      email: "",
      phoneNumber: "",
      expectedDate: "",
      specialisation:"",
      firm_name:"",
      name:"",
    });
  };

  const api = useAxios();

  const handleShareSubmit = async () => {
    try {
      shareData.docId = docId;
      let kindOfLegalAssistance = shareData.kindOfLegalAssistance;
      let description = shareData.description;
      let email = shareData.email;
      let expectedDate = shareData.expectedDate
      setShowError(kindOfLegalAssistance&&description&&expectedDate)
      const res = (kindOfLegalAssistance&&description&&expectedDate)&&await api.post(`${baseUrl}/contract/ShareDocument/`, shareData);
      //console.log(res);

      if (res.data.message==="Document Shared Successfully.") {
        // If API call is successful, hide the popup and change button color
        toast.success(res.data.message);
        setShowSharePopup(false);
        setShareSuccess(true);
        setselectedfirm("");
        setselectedspecialisation("");
        setShareData({
          kindOfLegalAssistance: "",
          selectedProvider: "",
          description: "",
          contactDetails: "",
          email: "",
          phoneNumber: "",
          expectedDate: "",
        });
        // You can optionally perform additional actions here
      } else {
        toast.error("Failed to submit share data");
        // Handle error cases
        throw new Error("Failed to submit share data");
      }
    } catch (error) {
      // Handle errors
      console.error("Error submitting share data:", error.response.data);
      // Optionally display an error message to the user
    }
  };
  const fetchData = async () => {
    try {
      // Fetch data from API
      const response = await api.get(`${baseUrl}/contract/marketplace/view/user`);
      // Extract data from response
      const responseData = response.data;
      // Update state with fetched data
      setMarketPlaceData(responseData);
      const specialisationresponse = await api.get(`${baseUrl}/contract/specialisationmarket/view/`);
      setspecialisationlist(specialisationresponse.data);


      // setFirmNameList([]);
      // //console.log("marketplace",responseData);
      // responseData.forEach((contactObj) => {
      //   if (contactObj.isSelected) {
      //     let contactInfo = contactObj.specialisation;
      //     FirmNameList.push(contactObj);
      //     if (!specialisationlist.includes(contactInfo)) {
      //       specialisationlist.push(contactObj);
      //       //console.log("contactInfo", contactInfo);
      //     }
      //   }
      // });

      //console.log("specialisation list",specialisationlist)
      // //console.log("firm list",FirmNameList)
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };
  // useEffect(() => {
  //   // Call fetchData function when component mounts
  //   fetchData();

  //   // Cleanup function (optional) if needed
  //   return () => {
  //     // Perform any cleanup here if necessary
  //   };
  // }, []);
  const handleChangeforspecialisation = (e) => {

    const selectedName = e.target.value;
    

    // const selectedObject = specialisationlist.find(item => item.specialisation == selectedName);
    // //console.log("specialisationlist",specialisationlist,selectedName);
    setselectedspecialisation(selectedName);
    const { name, value } = e.target;
    setShareData((prevState) => ({
      ...prevState,
      [name]: selectedName,
    }));
    //console.log(shareData);
  };
  const handleChangeforfirmname = (e) => {
    const selectedName = e.target.value;
    const { name, value } = e.target;
    //console.log("selectedfirmname",name,value);
    const selectedObject = MarketPlaceData.find(item => item.id == selectedName);
    let email = selectedObject.email
    let phone = selectedObject.phone
    let firm_name = selectedObject.firm_name
    let contact_person = selectedObject.contact_person
    setselectedfirm(selectedName);
    // const { name, value } = e.target;
    setShareData((prevState) => ({
      ...prevState,
      [name]: firm_name,
    }));
    setShareData((prevState) => ({
      ...prevState,
      ["email"]: email,
    }));
    setShareData((prevState) => ({
      ...prevState,
      ["phoneNumber"]: phone,
    }));
    setShareData((prevState) => ({
      ...prevState,
      ["name"]: contact_person,
    }));
    //console.log(shareData);

  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setShareData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  function getCurrentDate() {
    const today = new Date();
    const year = today.getFullYear();
    let month = today.getMonth() + 1;
    let day = today.getDate();

    // Add leading zero if month or day is less than 10
    if (month < 10) {
      month = `0${month}`;
    }
    if (day < 10) {
      day = `0${day}`;
    }

    return `${year}-${month}-${day}`;
  }

  return (
    <>
    <button
        className={`btn btn_theme btn-sm mr-1 wh_spab ${shareSuccess ? "success-color" : ""}`}
        data-tip={t("constant.Share with Legal")}
        onClick={handleShareClick}
      >
        <img
            src={documentsharingiconlegal}
            className="ml-0.5"
            width={15}
            alt="Share with Legal"
          />
        {/* Share */}
      </button>
      {showSharePopup && (
        <div className="modal-overlay">
          <div className="popup-container-sharelegal" onClick={(e) => e.stopPropagation()}>
            <div className="popup-header">
              <h1 className="text-center text-primary font-bold text-lg mr-15">
              {t('form.Request for Legal Services')}
              </h1>
              <button
                className="bg-[#606060] font-semibold text-white p-1 pl-2 pr-2 rounded-lg"
                onClick={handleClosePopup}
              >
                X
              </button>
            </div>
            <div className='flex flex-col mb-3'>
              <div className='flex'>
                  <label className='mb-2'>{t('form.Nature of Legal Assistance')}:</label>
                  {/* <>
                      <img
                          src={HelpIcon}
                          width={12}
                          className='ml-1 -mt-2 cursor-pointer'
                          data-tip={t('form.Nature of Legal Assistance')}
                      />
                      <ReactTooltip backgroundColor='#400835' textColor='#ffffff' />
                  </> */}
              </div>
              {/* <label htmlFor="kindOfLegalAssistance">
                <strong>{t('form.Nature of Legal Assistance')}:</strong>
              </label> */}
              <div className="flex">
                <select
                  id="kindOfLegalAssistance"
                  style={{ width: '300px'}}
                  name="kindOfLegalAssistance"
                  value={shareData.kindOfLegalAssistance}
                  onChange={handleChange}
                  className="input-field-small"
                >
                  <option value="">{t('constant.Please select task')}</option>
                  <option value="Drafting">Drafting</option>
                  <option value="Review">Review</option>
                  <option value="Advisory">Advisory</option>
                </select>
              </div>
            </div>

            <div className='flex flex-col mb-3'>
                  <div className='flex'>
                      <label className='mb-2'>{t('form.Specialisation')}:</label>
                      {/* <>
                          <img
                              src={HelpIcon}
                              width={12}
                              className='ml-1 -mt-2 cursor-pointer'
                              data-tip='Specialisation'
                          />
                          <ReactTooltip backgroundColor='#400835' textColor='#ffffff' />
                      </> */}
                  </div>
                    <div className="flex">
                        <select
                        id="specialisation"
                        name="specialisation"
                        style={{ width: '300px'}}
                        value={selectedspecialisation} onChange={handleChangeforspecialisation}>
                        <option value="">{t('form.Select Specialisation')}</option>                        
                        {specialisationlist.map(item => (
                            <option key={item.id} value={item.specialisation}>{item.specialisation}</option>
                        ))}
                        </select>
                    </div>                        
            </div>
            <div className='flex flex-col mb-3'>
                  <div className='flex'>
                      <label className='mb-2'>{t('form.Firm Name')}:</label>
                      {/* <>
                          <img
                              src={HelpIcon}
                              width={12}
                              className='ml-1 -mt-2 cursor-pointer'
                              data-tip='Firm Name'
                          />
                          <ReactTooltip backgroundColor='#400835' textColor='#ffffff' />
                      </> */}
                  </div>
                    <div className="flex">
                        <select
                        id="firm_name"
                        name="firm_name"
                        style={{ width: '300px'}}
                        value={selectedfirm} onChange={handleChangeforfirmname}>
                        <option value="">{t('form.Select Firm Name')}</option>
                        {MarketPlaceData
                        .filter(item => item.specialisation === selectedspecialisation && item.isSelected === true)
                        .map(item => (
                          <option key={item.id} value={item.id}>
                            {item.firm_name}
                          </option>
                        ))}
                        </select>
                    </div>                        
            </div>

            <div className='flex flex-col mb-3 contract-type'>
              <div className='flex'>
                    <label className='mb-2'>{t('form.Description of Document')}</label>
                    {/* <>
                        <img
                            src={HelpIcon}
                            width={12}
                            className='ml-1 -mt-2 cursor-pointer'
                            data-tip='Description of Document in Detail'
                        />
                        <ReactTooltip backgroundColor='#400835' textColor='#ffffff' />
                    </> */}
                </div>
              {/* <label htmlFor="description">
                <strong>{t('form.Description of Document')}</strong><br></br>
                <strong>in Detail:</strong>
              </label> */}
              <textarea
                id="description"
                name="description"
                placeholder={t('form.Enter description')}
                
                value={shareData.description}
                onChange={handleChange}
                className="input-field-large input-field-multiline"
                style={{ resize: 'none' }}
              />
              
            </div>
  
            <div className='flex flex-col mb-3'>
            <div className='flex'>
                    <label className='mb-2'>Expected Date of Completion</label>
                    {/* <>
                        <img
                            src={HelpIcon}
                            width={12}
                            className='ml-1 -mt-2 cursor-pointer'
                            data-tip='Expected Date of Completion'
                        />
                        <ReactTooltip backgroundColor='#400835' textColor='#ffffff' />
                    </> */}
                </div>
              {/* <label htmlFor="expectedDate" >
                <strong>{t('form.Expected Date')} </strong><br></br>
                <strong>{t('form.of Completion')}:</strong>
              </label> */}
              
              <div className='flex'>
              <input
                type="date"
                id="expectedDate"
                name="expectedDate"
                value={shareData.expectedDate}
                onChange={handleChange}
                min = {getCurrentDate()}                
              />
              </div>
            </div>
            <div className="buttons-container">
            {!showError && <span className="error-text">All Fields are required</span>}&nbsp; 
              <button className="btn btn_theme btn-sm mr-1 wh_spab submit-button" onClick={handleShareSubmit}>
              {t("allModule.Submit")}
              </button>
            </div>
        </div>
        </div>
      )}
    </>
  );
};

export default ShareButton;
