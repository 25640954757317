import { createContext, useState, useEffect, useContext } from "react";
import { toast } from "react-toastify";
import axios from "axios";
import HugeUploader from "huge-uploader";
import { resolve } from "../api/resolve";
import AppContext from "./AppContext";
import AuthContext from "./AuthContext";
import { validateEmail, validatePassword, validatePhone } from "../utils/utils";
import useAxios from "../hooks/useAxios";
import { getCookie } from "../utils/utils";
import { autoCloseTimer, delay } from "../constants/constants";
import { useTranslation } from "react-i18next";
import DocumentContext from "./DocumentContext";
import { useNavigate } from "react-router-dom";
import { upload } from "@testing-library/user-event/dist/upload";

const FormContext = createContext();

export default FormContext;

export const FormProvider = ({ children }) => {
  const api = useAxios();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const {
    getCompanies,
    setNotificationCount,
    totalNotificationCount,
    activeLink,
    setActiveLink,
    setShowDropdown,
    adminId,
  } = useContext(AppContext);
  const { user, authTokens } = useContext(AuthContext);

  const [modalIsOpen, setIsOpen] = useState(false);
  const [planModalIsOpen, setPlanModelIsOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [modalData, setModalData] = useState({
    title: "",
    bodyText: "",
    apiResStatus: false,
  });
  const [currentForm, setCurrentForm] = useState("");
  const { forSignatureList, setForSignatureList } = useContext(DocumentContext);

  const [office365SettingFormErrors, setOffice365SettingFormErrors] = useState({
    clientId: "",
    tenantId: "",
    redirectUrl: "",
    secretKey: "",
  });
  const [companyFormErrors, setCompanyFormErrors] = useState({
    name: "",
    phone: "",
    address: "",
    renew_date: "",
  });
  const [adminFormErrors, setAdminFormErrors] = useState({
    name: "",
    company_id: "",
    email: "",
    confirm_email: "",
    phone: "",
  });

  const [reviewerFormErrors, setReviewerFormErrors] = useState({
    name: "",
    company_id: "",
    email: "",
    confirm_email: "",
    phone: "",
  });
  const [contractFormErrors, setContractFormErrors] = useState({
    name: "",
    type: "",
    renewal_date: "",
    document: "",
    multi_document: "",
    drive_errors: "",
    checkSumExists: false,
  });
  const [contactFormErrors, setContactFormErrors] = useState({
    name: "",
    designation: "",
    phone: "",
    email: "",
    confirmEmail: "",
    groupList: "",
  });
  const [groupFormErrors, setGroupFormErrors] = useState({
    group_name: "",
    contactList: "",
  });
  const [userFormErrors, setUserFormErrors] = useState({
    name: "",
    designation: "",
    email: "",
  });
  const [demoUserFormErrors, setDemoUserFormErrors] = useState({
    name: "",
    designation: "",
    email: "",
    companyName: "",
    planId: "",
    password: "",
    isDemoUser: "",
    days: "",
  });
  const [passwordFormErrors, setPasswordFormErrors] = useState({
    current: "",
    password: "",
    confirm: "",
  });
  const [LegalDocumentFormErrors, setLegalDocumentFormErrors] = useState({
    template: "",
    template_name: "",
    token: "",
    multi_document: "",
  });
  const [ClauseLibaryFormErrors, setClauseLibaryFormErrors] = useState({
    template: "",
    template_name: "",
    token: "",
    multi_document: "",
  });

  const [moduleToEdit, setModuleToEdit] = useState("");
  const [editFormData, setEditFormData] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [shouldLogout, setShouldLogout] = useState(false);
  const [shouldRedirect, setShouldRedirect] = useState(true);
  const [showSubmitBtn, setShowSubmitBtn] = useState(true);
  const [progressValue, setProgressValue] = useState(1);

  const userSignUp = async (userSignUpObj) => {
    if (
      userSignUpObj.userEmailId.trim().length > 1 &&
      userSignUpObj.userName.trim().length > 1 &&
      userSignUpObj.userPassword.trim().length > 1
    ) {
      var postData = {
        first_name: userSignUpObj.userName,
        email: userSignUpObj.userEmailId,
        password: userSignUpObj.userPassword,
      };

      /*  const res = await resolve(
        api
          .post('user/register/', JSON.stringify(postData), {
            headers: {
              'Content-Type': 'application/json',
            },
          })
          .then((res) => res.data)
      )



      if (res.data) {
       
      }

      if (res.error) {
        toast.error(res.error.message, {
          delay,
          autoClose: autoCloseTimer,
          onClose: () => setLoading(false),
        });
       
      } */
    } else {
    }
  };

  const saveOrEditCompanylegacy = async (companyObj) => {
    const { name, phone, address, renew_date } = !isEditing
      ? companyObj
      : editFormData;
    if (name.trim().length < 1)
      setCompanyFormErrors({
        ...companyFormErrors,
        name: t("form.Company Name is required."),
      });
    else if (phone.trim().length < 10)
      setCompanyFormErrors({
        ...companyFormErrors,
        phone: t("form.Phone Number is required."),
      });
    else if (address.trim().length < 10)
      setCompanyFormErrors({
        ...companyFormErrors,
        address: t("form.Address is required."),
      });
    else if (renew_date.trim().length < 1 || renew_date === "Invalid Date")
      setCompanyFormErrors({
        ...companyFormErrors,
        renew_date: t("form.Renewal Date is required."),
      });
    else {
      const res = !isEditing
        ? await resolve(
            api
              .post(
                "/legacy_company/view_create/",
                JSON.stringify(companyObj),
                {
                  headers: {
                    "Content-Type": "application/json",
                  },
                }
              )
              .then((res) => res.data)
          )
        : await resolve(
            api
              .patch(
                `/legacy_company/retrieve_update/${editFormData.id}`,
                JSON.stringify(editFormData),
                {
                  headers: {
                    "Content-Type": "application/json",
                  },
                }
              )
              .then((res) => res.data)
          );
      if (res.data) {
        setShouldRedirect(true);
        setIsOpen(true);
        setModalData({
          ...modalData,
          title: !isEditing
            ? t("form.Company Added")
            : t("form.Company Updated"),
          bodyText: `${t("form.You have successfully")} ${
            !isEditing ? t("form.added") : t("form.updated")
          } ${t("form.company")}.`,
        });
        getCompanies();
      }
      if (res.error) {
        setShouldRedirect(false);
        setIsOpen(true);
        setModalData({
          ...modalData,
          title: t("common.Something Went wrong"),
          bodyText: t("form.Company was not added. Please try again"),
        });
      }
    }
  };
  // const saveOrEditAdminlegacy = async (adminObj) => {
  //   const { first_name, phone, email, confirm_email, company_id } = !isEditing
  //     ? adminObj
  //     : editFormData;
  //   if (first_name.trim().length < 1)
  //     setAdminFormErrors({
  //       ...adminFormErrors,
  //       name: t("form.Admin Name is required."),
  //     });
  //   else if (!company_id)
  //     setAdminFormErrors({
  //       ...adminFormErrors,
  //       company_id: t("form.Please select a company."),
  //     });
  //   else if (!validateEmail(email))
  //     setAdminFormErrors({
  //       ...adminFormErrors,
  //       email: t("form.Please Enter a valid email"),
  //     });
  //   else if (email !== confirm_email)
  //     setAdminFormErrors({
  //       ...adminFormErrors,
  //       confirm_email: t("form.Email does not match"),
  //     });
  //   else if (phone.trim().length < 10)
  //     setAdminFormErrors({
  //       ...adminFormErrors,
  //       phone: t("form.Please enter a valid number"),
  //     });
  //   else {
  //     const res = !isEditing
  //       ? await resolve(
  //           api
  //             .post("/legacy_user/view_create/", JSON.stringify(adminObj), {
  //               headers: {
  //                 "Content-Type": "application/json",
  //               },
  //             })
  //             .then((res) => res.data)
  //         )
  //       : await resolve(
  //           api
  //             .patch(
  //               `/legacy_user/retrieve_update/${editFormData.id}`,
  //               JSON.stringify(editFormData),
  //               {
  //                 headers: {
  //                   "Content-Type": "application/json",
  //                 },
  //               }
  //             )
  //             .then((res) => res.data)
  //         );
  //     if (res.data) {
  //       setShouldRedirect(true);
  //       setIsOpen(true);
  //       setModalData({
  //         ...modalData,
  //         title: !isEditing ? t("form.Admin Added") : t("form.Admin Updated"),
  //         bodyText: `${t("form.You have successfully")} ${
  //           !isEditing ? t("form.added") : t("form.updated")
  //         } ${t("form.admin")}.`,
  //       });
  //     }
  //     if (res.error) {
  //       setShouldRedirect(false);
  //       const emailExists = res.error?.response?.data.email;
  //       setIsOpen(true);
  //       if (emailExists?.length > 0) {
  //         setModalData({
  //           ...modalData,
  //           title: t("form.Email already exists"),
  //           bodyText: t("form.Admin email already exists"),
  //         });
  //       } else {
  //         setModalData({
  //           ...modalData,
  //           title: t("common.Something Went wrong"),
  //           bodyText: t("form.Admin was not added. Please try again"),
  //         });
  //       }
  //     }
  //   }
  // };
  const saveOrEditAdminlegacy = async (adminObj) => {
    const { first_name, phone, email, confirm_email, company_id } = !isEditing
      ? adminObj
      : editFormData;
    if (first_name.trim().length < 1)
      setAdminFormErrors({
        ...adminFormErrors,
        name: t("form.Admin Name is required."),
      });
    else if (!company_id)
      setAdminFormErrors({
        ...adminFormErrors,
        company_id: t("form.Please select a company."),
      });
    else if (!validateEmail(email))
      setAdminFormErrors({
        ...adminFormErrors,
        email: t("form.Please Enter a valid email"),
      });
    else if (email !== confirm_email)
      setAdminFormErrors({
        ...adminFormErrors,
        confirm_email: t("form.Email does not match"),
      });
    else if (phone.trim().length < 10)
      setAdminFormErrors({
        ...adminFormErrors,
        phone: t("form.Please enter a valid number"),
      });
    else {
      const res = !isEditing
        ? await resolve(
            api
              .post("/legacy_user/view_create/", JSON.stringify(adminObj), {
                headers: {
                  "Content-Type": "application/json",
                },
              })
              .then((res) => res.data)
          )
        : await resolve(
            api
              .patch(
                `/legacy_user/retrieve_update/${editFormData.id}`,
                JSON.stringify(editFormData),
                {
                  headers: {
                    "Content-Type": "application/json",
                  },
                }
              )
              .then((res) => res.data)
          );
      if (res.data) {
        setShouldRedirect(true);
        setIsOpen(true);
        setModalData({
          ...modalData,
          title: !isEditing ? t("form.Admin Added") : t("form.Admin Updated"),
          bodyText: `${t("form.You have successfully")} ${
            !isEditing ? t("form.added") : t("form.updated")
          } ${t("form.admin")}.`,
        });
      }
      if (res.error) {
        setShouldRedirect(false);
        console.log("res.error", res.error?.response?.data);
        const emailExists = res.error?.response?.data.email;
        const status_code = res.error?.response?.data.status_code;
        setIsOpen(true);
        if (emailExists?.length > 0) {
          setModalData({
            ...modalData,
            title: t("form.Email already exists"),
            bodyText: t("form.Admin email already exists"),
          });
        } else if (
          status_code?.length > 0 &&
          status_code === "Your subscription was expired"
        ) {
          setModalData({
            ...modalData,
            title: t("form.Subscription Expired"),
            bodyText: t("form.Your subscription was expired"),
          });
        } else if (
          status_code?.length > 0 &&
          status_code === "maximum_allowed_user_reached"
        ) {
          setModalData({
            ...modalData,
            title: t("form.Limit Expired"),
            bodyText: t(
              "form.You have reached the maximum number of allowed users."
            ),
          });
        } else {
          setModalData({
            ...modalData,
            title: t("form.Something Went wrong"),
            bodyText: t("form.Admin was not added. Please try again"),
          });
        }
      }
    }
  };

  const saveOrEditReviewerlegacy = async (reviewerObj) => {
    const { first_name, email, confirm_email, company_array } = !isEditing
      ? reviewerObj
      : editFormData;
    if (first_name.trim().length < 1)
      setReviewerFormErrors({
        ...reviewerFormErrors,
        name: t("form.Reviewer Name is required."),
      });
    else if (company_array && company_array.length < 1)
      setReviewerFormErrors({
        ...reviewerFormErrors,
        company_id: t("form.Please select a company."),
      });
    else if (!validateEmail(email))
      setReviewerFormErrors({
        ...reviewerFormErrors,
        email: t("form.Please Enter a valid email"),
      });
    else if (email !== confirm_email)
      setReviewerFormErrors({
        ...reviewerFormErrors,
        confirm_email: t("form.Email does not match"),
      });
    else {
      const res = !isEditing
        ? await resolve(
            api
              .post(
                "/legacy_user/reviewer/view_create/",
                JSON.stringify(reviewerObj),
                {
                  headers: {
                    "Content-Type": "application/json",
                  },
                }
              )
              .then((res) => res.data)
          )
        : await resolve(
            api
              .patch(
                `/legacy_user/reviewer/retrieve_update/${editFormData.id}`,
                JSON.stringify(editFormData),
                {
                  headers: {
                    "Content-Type": "application/json",
                  },
                }
              )
              .then((res) => res.data)
          );
      if (res.data) {
        setShouldRedirect(true);
        setIsOpen(true);
        setModalData({
          ...modalData,
          title: !isEditing
            ? t("form.Reviewer Added")
            : t("form.Reviewer Updated"),
          bodyText: `${t("form.You have successfully")} ${
            !isEditing ? t("form.added") : t("form.updated")
          } ${t("form.Reviewer")}.`,
        });
      }
      if (res.error) {
        setShouldRedirect(false);
        const emailExists = res.error?.response?.data.email;
        setIsOpen(true);
        if (emailExists?.length > 0) {
          setModalData({
            ...modalData,
            title: t("form.Email already exists"),
            bodyText: t("form.Reviewer email already exists"),
          });
        } else {
          setModalData({
            ...modalData,
            title: t("common.Something went wrong"),
            bodyText: t("form.Reviewer was not added. Please try again"),
          });
        }
      }
    }
  };

  // const saveOrEditUserlegacy = async (userObj, updatePermissionList) => {
  //   let { name, designation, email } = !isEditing ? userObj : editFormData;

  //   for (const key in userFormErrors) {
  //     if (userFormErrors.hasOwnProperty(key)) {
  //       userFormErrors[key] = "";
  //     }
  //   }

  //   if (name.trim().length < 1) {
  //     setUserFormErrors({
  //       ...userFormErrors,
  //       name: t("form.User name is required."),
  //     });
  //   } else if (designation.trim().length < 1) {
  //     setUserFormErrors({
  //       ...userFormErrors,
  //       designation: t("form.Designation is required."),
  //     });
  //   } else if (email.trim().length < 1) {
  //     setUserFormErrors({
  //       ...userFormErrors,
  //       email: t("form.Email is required."),
  //     });
  //   } else if (!validateEmail(email)) {
  //     setUserFormErrors({
  //       ...userFormErrors,
  //       email: t("loginPage.Please enter a valid email!"),
  //     });
  //   } else {
  //     let postData = {};
  //     if (!isEditing) {
  //       postData = {
  //         name: name,
  //         email: email,
  //         designation: designation,
  //         AdminId: adminId,
  //       };
  //     } else {
  //       postData = {
  //         name: name,
  //         email: email,
  //         designation: designation,
  //       };
  //     }
  //     try {
  //       setLoading(true);
  //       let res = "";
  //       if (!isEditing) {
  //         res = await api.post(`/legacy_user/byadmin/`, postData, {
  //           headers: {
  //             "Content-Type": "application/json",
  //           },
  //         });
  //       } else {
  //         res = await api.post(`/legacy_user/byadmin/edit/`, postData, {
  //           headers: {
  //             "Content-Type": "application/json",
  //           },
  //         });
  //       }
  //       setLoading(false);
  //       if (res.data) {
  //         updatePermissionList(res.data["User_permission"][0]);

  //         setIsOpen(true);
  //         setModalData({
  //           ...modalData,
  //           title: !isEditing ? t("form.User Added") : t("form.User Updated"),
  //           bodyText: `${t("form.You have successfully")} ${
  //             !isEditing ? t("form.added") : t("form.updated")
  //           } ${t("form.user")}.`,
  //           apiResStatus: true,
  //         });
  //       }
  //       setUserFormErrors({
  //         name: "",
  //         designation: "",
  //         email: "",
  //       });
  //     } catch (error) {
  //       setLoading(false);
  //       let data;
  //       if (error && error.response) data = error.response.data;

  //       let showError = 0;
  //       if (data && data.message) {
  //         let check01 = data.message.includes("Duplicate entry");
  //         let check02 = data.message.includes("for key 'email'");
  //         if (check01 && check02) {
  //           showError = 1;
  //         }
  //       }

  //       if (showError) {
  //         toast.error(t("form.Email id already exist."));
  //       } else {
  //         setIsOpen(true);
  //         let action = !isEditing ? t("form.added") : t("form.updated");
  //         setModalData({
  //           ...modalData,
  //           title: t("common.Something went wrong"),
  //           bodyText: `${t("form.User was not")} ${action}. ${t(
  //             "form.Please try again"
  //           )}`,
  //           apiResStatus: false,
  //         });
  //       }
  //     }
  //   }
  // };
  const saveOrEditUserlegacy = async (userObj) => {
    const {
      first_name,
      phone,
      email,
      confirm_email,
      position,
      company_id,
      repo_array,
      is_reviewer,
    } = !isEditing ? userObj : editFormData;
    console.log("position", position);
    if (first_name.trim().length < 1)
      setUserFormErrors({
        ...userFormErrors,
        name: t("form.User Name is required."),
      });
    else if (user.user_type === "super_admin" && !company_id)
      setUserFormErrors({
        ...userFormErrors,
        company_id: t("form.Please select a company."),
      });
    else if (position && position.trim().length < 1)
      setUserFormErrors({
        ...userFormErrors,
        position: t("form.User position is required."),
      });
    else if (!validateEmail(email))
      setUserFormErrors({
        ...userFormErrors,
        email: t("form.Please Enter a valid email"),
      });
    else if (email !== confirm_email)
      setUserFormErrors({
        ...userFormErrors,
        confirm_email: t("form.Email does not match"),
      });
    else if (phone && phone.trim().length < 10)
      setUserFormErrors({
        ...userFormErrors,
        phone: t("form.Please enter a valid number"),
      });
    else {
      const res = !isEditing
        ? await resolve(
            api
              .post("/legacy_user/view_create/", JSON.stringify(userObj), {
                headers: {
                  "Content-Type": "application/json",
                },
              })
              .then((res) => res.data)
          )
        : await resolve(
            api
              .patch(
                `/legacy_user/retrieve_update/${editFormData.id}`,
                JSON.stringify(editFormData),
                {
                  headers: {
                    "Content-Type": "application/json",
                  },
                }
              )
              .then((res) => res.data)
          );
      if (res.data) {
        setShouldRedirect(true);
        setIsOpen(true);
        setModalData({
          ...modalData,
          title: !isEditing ? t("form.User Added") : t("form.User Updated"),
          bodyText: `${t("form.You have successfully")} ${
            !isEditing ? t("form.added") : t("form.updated")
          } ${t("form.user")}.`,
        });
      }
      if (res.error) {
        setShouldRedirect(false);
        const emailExists = res.error?.response?.data.email;
        const status_code = res.error?.response?.data.status_code;
        setIsOpen(true);
        if (emailExists?.length > 0) {
          setModalData({
            ...modalData,
            title: t("form.Email already exists"),
            bodyText: t("form.User email already exists"),
          });
        } else if (
          status_code?.length > 0 &&
          status_code === "Your subscription was expired"
        ) {
          setModalData({
            ...modalData,
            title: t("form.Subscription Expired"),
            bodyText: t("form.Your subscription was expired"),
          });
        } else if (
          status_code?.length > 0 &&
          status_code === "maximum_allowed_user_reached"
        ) {
          setModalData({
            ...modalData,
            title: t("form.Limit Expired"),
            bodyText: t(
              "form.You have reached the maximum number of allowed users."
            ),
          });
        } else {
          setModalData({
            ...modalData,
            title: t("form.Something Went wrong"),
            bodyText: t("form.User was not added. Please try again"),
          });
        }
      }
    }
  };

  const saveOrEditCompany = async (companyObj) => {
    const { name, phone, address, renew_date } = !isEditing
      ? companyObj
      : editFormData;
    if (name.trim().length < 1)
      setCompanyFormErrors({
        ...companyFormErrors,
        name: t("form.Company Name is required."),
      });
    else if (phone.trim().length < 10)
      setCompanyFormErrors({
        ...companyFormErrors,
        phone: t("form.Phone Number is required."),
      });
    else if (address.trim().length < 10)
      setCompanyFormErrors({
        ...companyFormErrors,
        address: t("form.Address is required."),
      });
    else if (renew_date.trim().length < 1 || renew_date === "Invalid Date")
      setCompanyFormErrors({
        ...companyFormErrors,
        renew_date: t("form.Renewal Date is required."),
      });
    else {
      const res = !isEditing
        ? await resolve(
            api
              .post("/company/view_create/", JSON.stringify(companyObj), {
                headers: {
                  "Content-Type": "application/json",
                },
              })
              .then((res) => res.data)
          )
        : await resolve(
            api
              .patch(
                `/company/retrieve_update/${editFormData.id}`,
                JSON.stringify(editFormData),
                {
                  headers: {
                    "Content-Type": "application/json",
                  },
                }
              )
              .then((res) => res.data)
          );
      if (res.data) {
        setShouldRedirect(true);
        setIsOpen(true);
        setModalData({
          ...modalData,
          title: !isEditing
            ? t("form.Company Added")
            : t("form.Company Updated"),
          bodyText: `${t("form.You have successfully")} ${
            !isEditing ? t("form.added") : t("form.updated")
          } ${t("form.company")}.`,
        });
        getCompanies();
      }
      if (res.error) {
        setShouldRedirect(false);
        setIsOpen(true);
        setModalData({
          ...modalData,
          title: t("common.Something Went wrong"),
          bodyText: t("form.Company was not added. Please try again"),
        });
      }
    }
  };

  const saveOrEditAdmin = async (adminObj) => {
    const { first_name, phone, email, confirm_email, company_id } = !isEditing
      ? adminObj
      : editFormData;
    if (first_name.trim().length < 1)
      setAdminFormErrors({
        ...adminFormErrors,
        name: t("form.Admin Name is required."),
      });
    else if (!company_id)
      setAdminFormErrors({
        ...adminFormErrors,
        company_id: t("form.Please select a company."),
      });
    else if (!validateEmail(email))
      setAdminFormErrors({
        ...adminFormErrors,
        email: t("form.Please Enter a valid email"),
      });
    else if (email !== confirm_email)
      setAdminFormErrors({
        ...adminFormErrors,
        confirm_email: t("form.Email does not match"),
      });
    else if (phone.trim().length < 10)
      setAdminFormErrors({
        ...adminFormErrors,
        phone: t("form.Please enter a valid number"),
      });
    else {
      const res = !isEditing
        ? await resolve(
            api
              .post("/user/view_create/", JSON.stringify(adminObj), {
                headers: {
                  "Content-Type": "application/json",
                },
              })
              .then((res) => res.data)
          )
        : await resolve(
            api
              .patch(
                `/user/retrieve_update/${editFormData.id}`,
                JSON.stringify(editFormData),
                {
                  headers: {
                    "Content-Type": "application/json",
                  },
                }
              )
              .then((res) => res.data)
          );
      if (res.data) {
        setShouldRedirect(true);
        setIsOpen(true);
        setModalData({
          ...modalData,
          title: !isEditing ? t("form.Admin Added") : t("form.Admin Updated"),
          bodyText: `${t("form.You have successfully")} ${
            !isEditing ? t("form.added") : t("form.updated")
          } ${t("form.admin")}.`,
        });
      }
      if (res.error) {
        setShouldRedirect(false);
        const emailExists = res.error?.response?.data.email;
        setIsOpen(true);
        if (emailExists?.length > 0) {
          setModalData({
            ...modalData,
            title: t("form.Email already exists"),
            bodyText: t("form.Admin email already exists"),
          });
        } else {
          setModalData({
            ...modalData,
            title: t("common.Something Went wrong"),
            bodyText: t("form.Admin was not added. Please try again"),
          });
        }
      }
    }
  };

  const saveOrEditReviewer = async (reviewerObj) => {
    const { first_name, email, confirm_email, company_array } = !isEditing
      ? reviewerObj
      : editFormData;
    if (first_name.trim().length < 1)
      setReviewerFormErrors({
        ...reviewerFormErrors,
        name: t("form.Reviewer Name is required."),
      });
    else if (company_array && company_array.length < 1)
      setReviewerFormErrors({
        ...reviewerFormErrors,
        company_id: t("form.Please select a company."),
      });
    else if (!validateEmail(email))
      setReviewerFormErrors({
        ...reviewerFormErrors,
        email: t("form.Please Enter a valid email"),
      });
    else if (email !== confirm_email)
      setReviewerFormErrors({
        ...reviewerFormErrors,
        confirm_email: t("form.Email does not match"),
      });
    else {
      const res = !isEditing
        ? await resolve(
            api
              .post(
                "/user/reviewer/view_create/",
                JSON.stringify(reviewerObj),
                {
                  headers: {
                    "Content-Type": "application/json",
                  },
                }
              )
              .then((res) => res.data)
          )
        : await resolve(
            api
              .patch(
                `/user/reviewer/retrieve_update/${editFormData.id}`,
                JSON.stringify(editFormData),
                {
                  headers: {
                    "Content-Type": "application/json",
                  },
                }
              )
              .then((res) => res.data)
          );
      if (res.data) {
        setShouldRedirect(true);
        setIsOpen(true);
        setModalData({
          ...modalData,
          title: !isEditing
            ? t("form.Reviewer Added")
            : t("form.Reviewer Updated"),
          bodyText: `${t("form.You have successfully")} ${
            !isEditing ? t("form.added") : t("form.updated")
          } ${t("form.Reviewer")}.`,
        });
      }
      if (res.error) {
        setShouldRedirect(false);
        const emailExists = res.error?.response?.data.email;
        setIsOpen(true);
        if (emailExists?.length > 0) {
          setModalData({
            ...modalData,
            title: t("form.Email already exists"),
            bodyText: t("form.Reviewer email already exists"),
          });
        } else {
          setModalData({
            ...modalData,
            title: t("common.Something went wrong"),
            bodyText: t("form.Reviewer was not added. Please try again"),
          });
        }
      }
    }
  };

  const checkReviewerEmailexists = async (email) => {
    setShowSubmitBtn(true);
    if (email.trim().length > 0) {
      const res = await resolve(
        api.get(`/user/reviewer/check_email/${email}`).then((res) => res.data)
      );
      if (res.error) {
        setIsOpen(true);
        setShowSubmitBtn(false);
        setModalData({
          ...modalData,
          title: t("form.Email already exists"),
          bodyText: res.error.response.data.msg,
        });
      }
    }
  };

  const getNotifications = () => {
    console.log("getNotifications");
    const sse = new EventSource(
      `${process.env.REACT_APP_BACKEND_BASE_URL}/notification/stream/${user.user_id}/${totalNotificationCount}`
    );
    function getRealtimeData(data) {
      setNotificationCount(data);
      sse.close();
    }
    sse.onmessage = (e) => getRealtimeData(e.data);
  };

  const getNotificationslegacy = () => {
    console.log("getNotificationslegacy");
    const sse = new EventSource(
      `${process.env.REACT_APP_BACKEND_BASE_URL}/legacy_notification/stream/${user.user_id}/${totalNotificationCount}`
    );
    function getRealtimeData(data) {
      console.log("data");
      setNotificationCount(data);
      sse.close();
    }
    sse.onmessage = (e) => getRealtimeData(e.data);
  };

  const saveOrEditContract = async (contractObj, updatePermissionList) => {
    let {
      name,
      type,
      tags,
      doc_name,
      repo_name,
      renewal_date,
      document,
      repoID,
      checkSumExists,
      checkSumValue,
    } = !isEditing ? contractObj : editFormData;
    tags = !isEditing ? tags.join(",") : tags;

    for (const key in contractFormErrors) {
      if (contractFormErrors.hasOwnProperty(key)) {
        contractFormErrors[key] = "";
      }
    }

    if (name.trim().length < 1)
      setContractFormErrors({
        ...contractFormErrors,
        name: t("form.Document name is required."),
      });
    /* else if (type.trim().length < 1)
      setContractFormErrors({
        ...contractFormErrors,
        type: 'Please select a type.',
      }); */ else if (!isEditing && !document)
      setContractFormErrors({
        ...contractFormErrors,
        document: t("form.Please select a document."),
      });
    else if (contractFormErrors.checkSumExists) {
      setContractFormErrors({
        ...contractFormErrors,
        checkSumExists: true,
      });
    } else {
      let formData;
      if (!isEditing) {
        formData = new FormData();
        formData.append("name", name);
        // formData.append('type', type);
        formData.append("type", "test");
        formData.append("tags", tags);
        formData.append("doc_name", doc_name);
        formData.append("repo_name", repo_name);
        formData.append("repo", repoID);
        formData.append("renewal_date", renewal_date ? renewal_date : "");
        formData.append("document", document);
        formData.append("md5sum", [checkSumValue]);
      } else {
        let date;
        if (
          (renewal_date && renewal_date === "1970-01-01") ||
          (renewal_date && renewal_date === "Invalid Date")
        ) {
          date = "";
        } else {
          date = renewal_date;
        }
        formData = new FormData();
        formData.append("name", name);
        // formData.append('type', type);
        formData.append("type", "test");
        formData.append("tags", tags);
        formData.append("renewal_date", date);
      }
      setLoading(true);
      const res = !isEditing
        ? await resolve(
            api
              .post("/contract/view_create/", formData, {
                headers: {
                  "Content-Type": "application/json",
                },
                onUploadProgress: (e) => {
                  setProgressValue(Math.round((e.loaded / e.total) * 100));
                },
              })
              .then((res) => res.data)
          )
        : await resolve(
            api
              .patch(`/contract/retrieve_update/${editFormData.id}`, formData, {
                headers: {
                  "Content-Type": "application/json",
                },
              })
              .then((res) => res.data)
          );
      setLoading(false);
      if (res.data) {
        getNotifications();
        // setIsOpen(true);
        // setModalData({
        //   ...modalData,
        //   title: !isEditing ? t('form.Document Added') : t('form.Document Updated'),
        //   bodyText: `${!isEditing
        //     ? t('form.Document have been successfully uploaded.')
        //     : t('form.Document updated successfully')
        //     }`,
        // });
        updatePermissionList(res.data["user_quota"]);

        toast.success(t("form.Document have been successfully uploaded."));
        let tempObj = {
          converted_file_url: res.data["converted_file_url"],
          created_by: res.data.uploaded_by["first_name"],
          id: res.data["id"],
          name: res.data["doc_name"],
          original_file_pdf_url: res.data["original_file_pdf_url"],
          status: res.data["status"],
        };
        setForSignatureList([tempObj]);
        if (activeLink == "ContractForm") {
          setActiveLink("SelectedDocRecipients");
          navigate("/apply-signature/select-recipients");
        } else {
          setActiveLink("MyDocuments");
          setShowDropdown("");
          navigate("/my-documents/set-up-signatures");
        }
      }
      if (res.error) {
        setIsOpen(true);
        if (
          res.error?.response?.data?.message &&
          res.error?.response?.data?.message ==
            "Your subscription plan is expired"
        ) {
          setModalData({
            ...modalData,
            title: "",
            bodyText: t("constant.Your subscription plan is expired"),
          });
        } else {
          setModalData({
            ...modalData,
            title: t("common.Something went wrong"),
            bodyText: t("form.Document was not added. Please try again"),
          });
        }
      }
      setContractFormErrors({
        name: "",
        type: "",
        renewal_date: "",
        document: "",
        multi_document: "",
        drive_errors: "",
      });
    }
  };
  const saveOrEditContractlegacy = async (
    contractObj,
    updatePermissionList
  ) => {
    let {
      name,
      type,
      tags,
      doc_name,
      repo_name,
      renewal_date,
      document,
      repoID,
      checkSumExists,
      checkSumValue,
    } = !isEditing ? contractObj : editFormData;
    tags = !isEditing ? tags.join(",") : tags;
    if (name.trim().length < 1)
      setContractFormErrors({
        ...contractFormErrors,
        name: t("form.Contract Name is required."),
      });
    else if (type.trim().length < 1)
      setContractFormErrors({
        ...contractFormErrors,
        type: t("form.Please select a type."),
      });
    // else if (renewal_date.trim().length < 1 || renewal_date === 'Invalid Date')
    //   setContractFormErrors({
    //     ...contractFormErrors,
    //     renewal_date: 'Renewal Date is required.',
    //   });
    else if (!isEditing && !document)
      setContractFormErrors({
        ...contractFormErrors,
        document: t("form.Please select a document."),
      });
    else if (contractFormErrors.checkSumExists) {
      setContractFormErrors({
        ...contractFormErrors,
        checkSumExists: true,
      });
    } else {
      let formData;
      console.log("contract form legacy");
      if (!isEditing) {
        formData = new FormData();
        formData.append("name", name);
        formData.append("type", type);
        formData.append("tags", tags);
        formData.append("doc_name", doc_name);
        formData.append("repo_name", repo_name);
        formData.append("repo", repoID);
        formData.append("renewal_date", renewal_date ? renewal_date : "");
        formData.append("document", document);
        formData.append("md5sum", [checkSumValue]);
      } else {
        let date;
        if (
          (renewal_date && renewal_date === "1970-01-01") ||
          (renewal_date && renewal_date === "Invalid Date")
        ) {
          date = "";
        } else {
          date = renewal_date;
        }
        formData = new FormData();
        formData.append("name", name);
        formData.append("type", type);
        formData.append("tags", tags);
        formData.append("renewal_date", date);
      }
      setLoading(true);
      const res = !isEditing
        ? await resolve(
            api
              .post("/legacy_contract/view_create/", formData, {
                headers: {
                  "Content-Type": "application/json",
                },
                onUploadProgress: (e) => {
                  setProgressValue(Math.round((e.loaded / e.total) * 100));
                },
              })
              .then((res) => res.data)
          )
        : await resolve(
            api
              .patch(
                `/legacy_contract/retrieve_update/${editFormData.id}`,
                formData,
                {
                  headers: {
                    "Content-Type": "application/json",
                  },
                }
              )
              .then((res) => res.data)
          );
      setLoading(false);
      if (res.data) {
        console.log("after saving data, calling notifications");
        getNotificationslegacy();
        setIsOpen(true);
        setModalData({
          ...modalData,
          title: !isEditing
            ? t("form.Contract Added")
            : t("form.Contract Updated"),
          bodyText: `${
            !isEditing
              ? t(
                  "form.You will receive notification once contract is ready to view"
                )
              : t("form.Contract updated successfully")
          }`,
        });
        // setActiveLink('Searchlegacy');
      }
      if (res.error) {
        setIsOpen(true);
        setModalData({
          ...modalData,
          title: t("form.Something Went wrong"),
          bodyText: t("form.Contract was not added. Please try again"),
        });
      }
      setContractFormErrors({
        name: "",
        type: "",
        renewal_date: "",
        document: "",
        multi_document: "",
        drive_errors: "",
      });
    }
  };
  const saveOrEditContact = async (contactObj) => {
    let {
      name,
      designation,
      phone,
      email,
      confirmEmail,
      groupList,
      companyId,
      titleId,
      delegatee,
    } = !isEditing ? contactObj : editFormData;

    let tempGroupList = [];
    if (isEditing && groupList.length > 0 && groupList[0].id != undefined) {
      groupList.forEach((groupInfo, index) => {
        tempGroupList.push(groupInfo.id);
      });
      groupList = tempGroupList;
    }

    for (const key in contactFormErrors) {
      if (contactFormErrors.hasOwnProperty(key)) {
        contactFormErrors[key] = "";
      }
    }

    if (name.trim().length < 1) {
      setContactFormErrors({
        ...contactFormErrors,
        name: t("form.Contact name is required."),
      });
      //  }
      //else if (designation.trim().length < 1) {
      //   setContactFormErrors({
      //     ...contactFormErrors,
      //     designation: 'Designation is required.',
      //   });
    } else if (email.trim().length < 1) {
      setContactFormErrors({
        ...contactFormErrors,
        email: t("form.Email is required."),
      });
    } else if (!validateEmail(email)) {
      setContactFormErrors({
        ...contactFormErrors,
        email: t("loginPage.Please enter a valid email!"),
      });
    } else if (confirmEmail.trim().length < 1) {
      setContactFormErrors({
        ...contactFormErrors,
        confirmEmail: t("form.Confirm Email is required."),
      });
    } else if (!validateEmail(confirmEmail)) {
      setContactFormErrors({
        ...contactFormErrors,
        confirmEmail: t("loginPage.Please enter a valid email!"),
      });
    } else if (email !== confirmEmail) {
      setContactFormErrors({
        ...contactFormErrors,
        confirmEmail: t("form.Email and Confirm Email should be same"),
      });
    } else if (phone.trim().length < 1) {
      setContactFormErrors({
        ...contactFormErrors,
        phone: t("form.Phone is required."),
      });
    } else if (companyId.trim().length < 1 || companyId == "null") {
      setContactFormErrors({
        ...contactFormErrors,
        companyId: t("form.Company is required."),
      });
    } else if (titleId.trim().length < 1) {
      setContactFormErrors({
        ...contactFormErrors,
        titleId: t("form.Title is required."),
      });
    } else if (titleId == "other" && designation.trim().length < 1) {
      setContactFormErrors({
        ...contactFormErrors,
        designation: t("form.Enter title"),
      });
    } else {
      let postData = {};
      if (!isEditing) {
        postData = {
          name: name,
          email: email,
          phone: phone.startsWith("+") ? phone : "+" + phone,
          designation: designation,
          group_id: groupList,
          company_id: companyId,
          title_id: titleId,
          delegatee: delegatee,
        };
      } else {
        postData = {
          name: name,
          email: email,
          phone: phone.startsWith("+") ? phone : "+" + phone,
          designation: designation,
          group_id_list: groupList,
          company_id: companyId,
          title_id: titleId,
          delegatee: delegatee,
        };
      }

      setLoading(true);
      try {
        let res = "";
        if (!isEditing) {
          res = await api.post(`/user/contact/save/`, postData, {
            headers: {
              "Content-Type": "application/json",
            },
          });
        } else {
          res = await api.post(
            `/user/contact/edit/${editFormData.id}`,
            postData,
            {
              headers: {
                "Content-Type": "application/json",
              },
            }
          );
        }
        setLoading(false);
        if (res.data) {
          getNotifications();
          setIsOpen(true);
          setModalData({
            ...modalData,
            title: !isEditing
              ? t("form.Contact Added")
              : t("form.Contact Updated"),
            bodyText: `${t("form.You have successfully")} ${
              !isEditing ? t("form.added") : t("form.updated")
            } ${t("form.contact")}.`,
            apiResStatus: true,
          });
        }
      } catch (error) {
        setLoading(false);
        let data;
        if (error && error.response) data = error.response.data;
        if (data && data.message) {
          toast.error(data.message);
        } else {
          setIsOpen(true);
          let action = !isEditing ? t("form.added") : t("form.updated");
          setModalData({
            ...modalData,
            title: t("common.Something went wrong"),
            bodyText: `${t("form.Contact was not")} ${action}. ${t(
              "form.Please try again"
            )}`,
            apiResStatus: false,
          });
        }

        /* let showError = 0;
        let mobile = 0;
        let email = 0;
        if (data && data.message) {
          let check01 = data.message.includes('Duplicate entry')
          let check02 = data.message.includes("for key 'site_user_contacts_email")
          let check03 = data.message.includes("for key 'site_user_contacts_phone")

          if (check01 && check02) {
            showError = 1;
            email = 1;

          } else if (check01 && check03) {
            showError = 1;
            mobile = 1;
          }
        }

        if (showError) {
          if (email) {
            toast.error("Email id already exist.")
          } else if (mobile) {
            toast.error("Mobile number already exist.")
          } else {
            toast.error("Something Went wrong.")
          }

        } else {
          setIsOpen(true);
          let action = !isEditing ? 'added' : 'updated';
          setModalData({
            ...modalData,
            title: 'Something Went wrong',
            bodyText: `Contact was not ${action}. Please try again`,
            apiResStatus: false
          });
        } */
      }

      setContactFormErrors({
        name: "",
        designation: "",
        phone: "",
        email: "",
        confirmEmail: "",
        groupList: "",
      });
    }
  };

  const saveOrEditGroup = async (groupObj) => {
    let { group_name, contactList } = !isEditing ? groupObj : editFormData;

    let tempContactList = [];
    if (isEditing && contactList.length > 0 && contactList[0].id != undefined) {
      contactList.forEach((contactInfo, index) => {
        tempContactList.push(contactInfo.id);
      });
      contactList = tempContactList;
    }

    setGroupFormErrors({
      ...groupFormErrors,
      group_name: "",
      contactList: "",
    });

    if (group_name.trim().length < 1) {
      setGroupFormErrors({
        ...groupFormErrors,
        group_name: t("form.Group name is required."),
      });
    } else if (contactList == undefined || contactList.length < 1) {
      setGroupFormErrors({
        ...groupFormErrors,
        contactList: t("form.Please select Contact"),
      });
    } else {
      const postData = {
        group_name: group_name,
        contactList: contactList,
      };
      setLoading(true);
      const res = !isEditing
        ? await resolve(
            api
              .post("/user/group/add/recipient/", postData, {
                headers: {
                  "Content-Type": "application/json",
                },
                onUploadProgress: (e) => {
                  setProgressValue(Math.round((e.loaded / e.total) * 100));
                },
              })
              .then((res) => res.data)
          )
        : await resolve(
            api
              .post(`/user/contact/edit/group/${editFormData.id}`, postData, {
                headers: {
                  "Content-Type": "application/json",
                },
              })
              .then((res) => res.data)
          );

      setLoading(false);

      if (res.data) {
        getNotifications();
        setIsOpen(true);
        setModalData({
          ...modalData,
          title: !isEditing ? t("form.Group Added") : t("form.Group Updated"),
          bodyText: `${t("form.You have successfully")} ${
            !isEditing ? t("form.added") : t("form.updated")
          } group.`,
          apiResStatus: true,
        });
        setGroupFormErrors({
          group_name: "",
          contactList: "",
        });
      }
      if (res.error) {
        let data;
        if (res.error && res.error.response) data = res.error.response.data;
        if (data.message.match("Duplicate entry")) {
          setGroupFormErrors({
            ...groupFormErrors,
            group_name: t("form.Group name already exist."),
          });
          // setModalData({
          //   ...modalData,
          //   title: 'Group name already exist',
          //   bodyText: 'group was not added. Please try again',
          //   apiResStatus: false
          // });
        } else {
          setIsOpen(true);
          setModalData({
            ...modalData,
            title: t("common.Something went wrong"),
            bodyText: t("form.group was not added. Please try again"),
            apiResStatus: false,
          });
          setGroupFormErrors({
            group_name: "",
            contactList: "",
          });
        }
      }
    }
  };

  const saveOrEditUser = async (userObj, updatePermissionList) => {
    let { name, designation, email } = !isEditing ? userObj : editFormData;

    for (const key in userFormErrors) {
      if (userFormErrors.hasOwnProperty(key)) {
        userFormErrors[key] = "";
      }
    }

    if (name.trim().length < 1) {
      setUserFormErrors({
        ...userFormErrors,
        name: t("form.User name is required."),
      });
    } else if (designation.trim().length < 1) {
      setUserFormErrors({
        ...userFormErrors,
        designation: t("form.Designation is required."),
      });
    } else if (email.trim().length < 1) {
      setUserFormErrors({
        ...userFormErrors,
        email: t("form.Email is required."),
      });
    } else if (!validateEmail(email)) {
      setUserFormErrors({
        ...userFormErrors,
        email: t("loginPage.Please enter a valid email!"),
      });
    } else {
      let postData = {};
      if (!isEditing) {
        postData = {
          name: name,
          email: email,
          designation: designation,
          AdminId: adminId,
        };
      } else {
        postData = {
          name: name,
          email: email,
          designation: designation,
        };
      }
      try {
        setLoading(true);
        let res = "";
        console.log("postData", postData);
        if (!isEditing) {
          res = await api.post(`/user/byadmin/`, postData, {
            headers: {
              "Content-Type": "application/json",
            },
          });
        } else {
          res = await api.post(`/user/byadmin/edit/`, postData, {
            headers: {
              "Content-Type": "application/json",
            },
          });
        }
        setLoading(false);
        if (res.data) {
          updatePermissionList(res.data["User_permission"][0]);

          setIsOpen(true);
          setModalData({
            ...modalData,
            title: !isEditing ? t("form.User Added") : t("form.User Updated"),
            bodyText: `${t("form.You have successfully")} ${
              !isEditing ? t("form.added") : t("form.updated")
            } ${t("form.user")}.`,
            apiResStatus: true,
          });
        }
        setUserFormErrors({
          name: "",
          designation: "",
          email: "",
        });
      } catch (error) {
        setLoading(false);
        let data;
        if (error && error.response) data = error.response.data;

        let showError = 0;
        if (data && data.message) {
          let check01 = data.message.includes("Duplicate entry");
          let check02 = data.message.includes("for key 'email'");
          if (check01 && check02) {
            showError = 1;
          }
        }

        if (showError) {
          toast.error(t("form.Email id already exist."));
        } else {
          setIsOpen(true);
          let action = !isEditing ? t("form.added") : t("form.updated");
          setModalData({
            ...modalData,
            title: t("common.Something went wrong"),
            bodyText: `${t("form.User was not")} ${action}. ${t(
              "form.Please try again"
            )}`,
            apiResStatus: false,
          });
        }
      }
    }
  };

  const saveOrEditDemoUser = async (userObj) => {
    let {
      name,
      designation,
      email,
      companyName,
      planId,
      password,
      isDemoUser,
      days,
      isGenericTemplatesRequire,
    } = !isEditing ? userObj : editFormData;

    for (const key in demoUserFormErrors) {
      if (demoUserFormErrors.hasOwnProperty(key)) {
        demoUserFormErrors[key] = "";
      }
    }

    if (name.trim().length < 1) {
      setDemoUserFormErrors({
        ...demoUserFormErrors,
        name: t("form.User name is required."),
      });
    } else if (designation.trim().length < 1) {
      setDemoUserFormErrors({
        ...demoUserFormErrors,
        designation: t("form.Designation is required."),
      });
    } else if (email.trim().length < 1) {
      setDemoUserFormErrors({
        ...demoUserFormErrors,
        email: t("form.Email is required."),
      });
    } else if (!validateEmail(email)) {
      setDemoUserFormErrors({
        ...demoUserFormErrors,
        email: t("loginPage.Please enter a valid email!"),
      });
    } else if (companyName.trim().length < 1) {
      setDemoUserFormErrors({
        ...demoUserFormErrors,
        companyName: t("form.Company name is required."),
      });
    } else if (!isEditing && password.trim().length < 1) {
      setDemoUserFormErrors({
        ...demoUserFormErrors,
        password: t("form.Password is required."),
      });
    } else if (!isEditing && !validatePassword(password)) {
      setDemoUserFormErrors({
        ...demoUserFormErrors,
        password: t(
          "loginPage.Password should contain : 1 Alphabet, 1 Number, 1 Special character, Minimum 8 characters"
        ),
      });
    } else if (planId.trim().length < 1) {
      setDemoUserFormErrors({
        ...demoUserFormErrors,
        planId: t("form.plan is required."),
      });
    } else if (isDemoUser && days.trim().length < 1) {
      setDemoUserFormErrors({
        ...demoUserFormErrors,
        days: t("form.plan duration is required."),
      });
    } else {
      let postData = {};
      if (!isEditing) {
        postData = {
          first_name: name,
          email: email,
          designation: designation,
          company_name: companyName,
          demo_package: planId,
          is_demo_account: isDemoUser,
          is_active_user: userObj.is_active,
          password: password,
          days: days ? parseInt(days) : 0,
          isGenericTemplatesRequire: isGenericTemplatesRequire,
        };
      } else {
        postData = {
          id: editFormData.id,
          first_name: name,
          email: email,
          designation: designation,
          company_name: companyName,
          demo_package: planId,
          is_demo_account: isDemoUser,
          is_active_user: userObj.is_active,
          days: days ? parseInt(days) : 0,
          isGenericTemplatesRequire: isGenericTemplatesRequire,
        };
      }

      try {
        setLoading(true);
        let res = "";
        if (!isEditing) {
          res = await api.post(`/user/corportate/user/`, postData, {
            headers: {
              "Content-Type": "application/json",
            },
          });
        } else {
          res = await api.post(`/user/corportate/user/`, postData, {
            headers: {
              "Content-Type": "application/json",
            },
          });
        }

        setLoading(false);
        if (res.data) {
          setIsOpen(true);
          setModalData({
            ...modalData,
            title: !isEditing ? t("form.User Added") : t("form.User Updated"),
            bodyText: `${t("form.You have successfully")} ${
              !isEditing ? t("form.added") : t("form.updated")
            } ${t("form.user")}.`,
            apiResStatus: true,
          });
        }
      } catch (error) {
        setLoading(false);
        let data;
        if (error && error.response) data = error.response.data;

        let showError = 0;
        if (data && data.message) {
          let check01 = data.message.includes("already exist");
          let check02 = data.message.includes("Email Id");
          if (check01 && check02) {
            showError = 1;
          }
        }

        if (showError) {
          toast.error(t("form.Email id already exist."));
        } else {
          setIsOpen(true);
          let action = !isEditing ? t("form.added") : t("form.updated");
          setModalData({
            ...modalData,
            title: t("common.Something went wrong"),
            bodyText: `${t("form.User was not")} ${action}. ${t(
              "form.Please try again"
            )}`,
            apiResStatus: false,
          });
        }
      }

      setDemoUserFormErrors({
        name: "",
        designation: "",
        email: "",
        companyName: "",
        planId: "",
        password: "",
        isDemoUser: "",
        isActiveUser: "",
      });
    }
  };

  const handleMultipleContracts = async (
    fileList,
    repoName,
    repoID,
    checkSumValues,
    updatePermissionList
  ) => {
    const hasErrors = false;
    if (fileList.length > 0) {
      if (fileList.length > 100) {
        setIsOpen(true);
        setModalData({
          ...modalData,
          title: t("form.Maximum limit reached"),
          bodyText: t("form.Upload a maximum of 100 files"),
        });
        return;
      }
      let formData = new FormData();
      fileList.forEach((file, id) => {
        if (file.exists) {
          hasErrors = true;
        }
        formData.append("doc_name", file.name);
        formData.append("repo_name", repoName);
        formData.append("document", file.document || file);
        formData.append("md5sum", checkSumValues[id]);
      });
      if (!hasErrors) {
        setLoading(true);
        const res = await resolve(
          api
            .post("/contract/add_multiple_contract/", formData, {
              headers: {
                "Content-Type": "application/json",
              },
              onUploadProgress: (e) => {
                setProgressValue(Math.round((e.loaded / e.total) * 100));
              },
            })
            .then((res) => res.data)
        );
        setLoading(false);
        if (res.data) {
          getNotifications();
          toast.success(
            t(
              // "form.You will receive notification once contracts are ready to view"
              "Document uploaded successfully"
            )
          );
          updatePermissionList();
          navigate("/my-documents/set-up-signatures");
          setActiveLink("MyDocuments");
          setShowDropdown("");
        }

        if (res.error) {
          // if(res.error?.response?.data)
          setIsOpen(true);
          // setModalData({
          //   ...modalData,
          //   title: t("common.Something went wrong"),
          //   bodyText: t("form.Contracts were not added. Please try again"),
          // });

          if (
            res.error?.response?.data?.message &&
            res.error?.response?.data?.message ==
              "Your subscription plan is expired"
          ) {
            setModalData({
              ...modalData,
              title: "",
              bodyText: t("constant.Your subscription plan is expired"),
            });
          } else if (
            res.error?.response?.data?.message &&
            res.error?.response?.data?.message ==
              "Your have uploading more than your limit"
          ) {
            setModalData({
              ...modalData,
              title: "",
              bodyText: "Your have uploading more than your limit",
            });
          } else if (
            res.error?.response?.data?.message &&
            res.error?.response?.data?.message == "Invalid file format"
          ) {
            setModalData({
              ...modalData,
              title: "",
              bodyText: "Invalid file format, please upload a valid file",
            });
          } else {
            setModalData({
              ...modalData,
              title: t("common.Something went wrong"),
              bodyText: t("form.Document was not added. Please try again"),
            });
          }
        }
      }
    }
  };

  const handleMultipleContractslegacy = async (
    fileList,
    repoName,
    repoID,
    checkSumValues,
    updatePermissionList
  ) => {
    const hasErrors = false;
    if (fileList.length > 0) {
      if (fileList.length > 100) {
        setIsOpen(true);
        setModalData({
          ...modalData,
          title: t("form.Maximum limit reached"),
          bodyText: t("form.Upload a maximum of 100 files"),
        });
        return;
      }
      let formData = new FormData();
      fileList.forEach((file, id) => {
        if (file.exists) {
          hasErrors = true;
        }
        formData.append("doc_name", file.name);
        formData.append("repo_name", repoName);
        formData.append("repo_id", repoID);
        formData.append("document", file.document || file);
        formData.append("md5sum", checkSumValues[id]);
      });
      if (!hasErrors) {
        setLoading(true);
        const res = await resolve(
          api
            .post("/legacy_contract/add_multiple_contract/", formData, {
              headers: {
                "Content-Type": "application/json",
              },
              onUploadProgress: (e) => {
                setProgressValue(Math.round((e.loaded / e.total) * 100));
              },
            })
            .then((res) => res.data)
        );
        setLoading(false);
        if (res.data) {
          getNotifications();
          toast.success(
            t(
              // "form.You will receive notification once contracts are ready to view"
              "Document/s uploaded successfully"
            )
          );
          setIsOpen(true);
          setModalData({
            ...modalData,
            title: t("common.Contract Upload in Progress"),
            bodyText: t(
              "form.You will receive notification once contracts are ready to view"
            ),
          });
          updatePermissionList();
          navigate("/my-documents/set-up-signatures");
          setActiveLink("Searchlegacy");
          setShowDropdown("");
        }

        if (res.error) {
          // if(res.error?.response?.data)
          setIsOpen(true);
          // setModalData({
          //   ...modalData,
          //   title: t("common.Something went wrong"),
          //   bodyText: t("form.Contracts were not added. Please try again"),
          // });

          if (
            res.error?.response?.data?.message &&
            res.error?.response?.data?.message ==
              "Your subscription plan is expired"
          ) {
            setModalData({
              ...modalData,
              title: "",
              bodyText: t("constant.Your subscription plan is expired"),
            });
          } else if (
            res.error?.response?.data?.message &&
            res.error?.response?.data?.message ==
              "Your have uploading more than your limit"
          ) {
            setModalData({
              ...modalData,
              title: "",
              bodyText: "Your have uploading more than your limit",
            });
          } else {
            setModalData({
              ...modalData,
              title: t("common.Something went wrong"),
              bodyText: t("form.Document was not added. Please try again"),
            });
          }
        }
      }
    }
  };

  const handleZipUploadlegacy = async (fileList, repoName) => {
    const csrftoken = getCookie("csrftoken");
    if (fileList.length > 0) {
      const uploader = new HugeUploader({
        endpoint: `${process.env.REACT_APP_BACKEND_BASE_URL}/legacy_contract/bulk_upload_contracts/${repoName}`,
        file: fileList[0],
        chunkSize: 10,
        headers: {
          Authorization: `Bearer ${authTokens.access}`,
          "X-CSRFToken": csrftoken,
        },
      });

      setLoading(true);
      uploader.on("error", (err) => {
        setLoading(false);
        setIsOpen(true);
        setModalData({
          ...modalData,
          title: t("form.Something Went wrong"),
          bodyText: t("form.Contracts were not added. Please try again"),
        });
        console.error("Something bad happened", err.detail);
      });

      uploader.on("progress", (progress) => {
        setProgressValue(progress.detail);
        console.log(`The upload is at ${progress.detail}%`);
      });

      uploader.on("finish", () => {
        getNotifications();
        setLoading(false);
        setIsOpen(true);

        setModalData({
          ...modalData,

          title: !isEditing
            ? t("form.Contracts Added")
            : t("form.Contracts Updated"),

          bodyText: `${
            !isEditing
              ? t(
                  "form.You will receive notification once contracts are ready to view"
                )
              : t("form.Contracts updated successfully")
          }`,
        });
      });
      // let formData = new FormData();
      // fileList.forEach((file, id) => {
      //   formData.append('zip_file', file);
      //   formData.append('repo_name', repoName);
      // });
      // setLoading(true);
      // const res = await resolve(
      //   api
      //     .post('/contract/bulk_upload_contracts/', formData, {
      //       onUploadProgress: (e) => {
      //         setProgressValue(Math.round((e.loaded / e.total) * 100));
      //       },
      //     })
      //     .then((res) => res.data)
      // );
      // setLoading(false);
      // if (res.data) {
      //   setIsOpen(true);
      //   setModalData({
      //     ...modalData,
      //     title: !isEditing ? 'Contracts Added' : 'Contracts Updated',
      //     bodyText: `${
      //       !isEditing
      //         ? 'You will receive notification once contracts are ready to view'
      //         : 'Contracts updated successfully'
      //     }`,
      //   });
      // }
      // if (res.error) {
      //   setIsOpen(true);
      //   setModalData({
      //     ...modalData,
      //     title: 'Something Went wrong',
      //     bodyText: 'Contracts were not added. Please try again',
      //   });
      // }
    }
  };

  const handleZipUpload = async (fileList, repoName, updatePermissionList) => {
    const csrftoken = getCookie("csrftoken");
    const endpoint = repoName
      ? `${process.env.REACT_APP_BACKEND_BASE_URL}/contract/bulk_upload_contracts/${repoName}`
      : `${process.env.REACT_APP_BACKEND_BASE_URL}/contract/bulk_upload_contracts/`;

    if (fileList.length > 0) {
      const uploader = new HugeUploader({
        endpoint: endpoint,
        file: fileList[0],
        chunkSize: 10,
        headers: {
          Authorization: `Bearer ${authTokens.access}`,
          "X-CSRFToken": csrftoken,
        },
      });

      setLoading(true);
      uploader.on("error", (err) => {
        setLoading(false);
        setIsOpen(true);
        setModalData({
          ...modalData,
          title: t("common.Something went wrong"),
          bodyText: t("form.Contracts were not added. Please try again"),
        });
      });

      uploader.on("progress", (progress) => {
        setProgressValue(progress.detail);
      });

      uploader.on("finish", () => {
        getNotifications();
        setLoading(false);
        setIsOpen(true);

        setModalData({
          ...modalData,

          title: !isEditing
            ? t("form.Contracts Added")
            : t("form.Contracts Updated"),

          bodyText: `${
            !isEditing
              ? t(
                  "form.You will receive notification once contracts are ready to view"
                )
              : t("form.Contracts updated successfully")
          }`,
        });
        toast.success(
          t(
            // "form.You will receive notification once contracts are ready to view"
            "Document uploaded successfully"
          )
        );
        updatePermissionList();
        navigate("/my-documents/set-up-signatures");
        setActiveLink("MyDocuments");
        setShowDropdown("");
      });
    }
  };
  // Function to add increasing characters after each character
  function xorEncryptDecrypt(input, key) {
    let output = "";
    for (let i = 0; i < input.length; i++) {
      output += String.fromCharCode(
        input.charCodeAt(i) ^ key.charCodeAt(i % key.length)
      );
    }
    return btoa(output);
  }
  const updatePassword = async (passwordObj) => {
    setPasswordFormErrors({ current: "", password: "", confirm: "" });
    const { current, password, confirm, email } = passwordObj;
    if (current.trim().length < 1) {
      setPasswordFormErrors({
        ...passwordFormErrors,
        current: t("form.Please enter your current password."),
      });
    } else if (!validatePassword(password)) {
      setPasswordFormErrors({
        ...passwordFormErrors,
        password: t(
          "loginPage.Password should contain : 1 Alphabet, 1 Number, 1 Special character, Minimum 8 characters"
        ),
      });
    } else if (confirm !== password) {
      setPasswordFormErrors({
        ...passwordFormErrors,
        confirm: t("form.Password and confirm password are not same."),
      });
    } else {
      const key = `${process.env.REACT_APP_LOGIN_PASSWORD_KEY}`;
      const oldpass1 = xorEncryptDecrypt(current, key);
      const newpass1 = xorEncryptDecrypt(password, key);
      const payload = {
        email,
        old_pass: oldpass1,
        new_pass: newpass1,
      };
      const res = await resolve(
        api
          .post("/user/update_password/", payload, {
            headers: {
              "Content-Type": "application/json",
            },
          })
          .then((res) => res.data)
      );
      if (res.data) {
        setShouldLogout(true);
        setIsOpen(true);
        setModalData({
          title: t("form.Password updated successfully."),
          bodyText: "Please login again to continue",
        });
      } else {
        if (res.error.response.data.code === "old_password_incorrect") {
          setIsOpen(true);
          setModalData({
            title: t("form.Current password is incorrect."),
            bodyText: t("form.Please try again"),
          });
        } else {
          setIsOpen(true);
          setModalData({
            title: "Something went wrong",
            bodyText: t("form.Please try again"),
          });
        }
      }
      setPasswordFormErrors({ current: "", password: "", confirm: "" });
    }
  };

  const saveOrEditLegalDocument = async (postDataObj) => {
    let { template, template_name, token } = !isEditing
      ? postDataObj
      : editFormData;

    for (const key in LegalDocumentFormErrors) {
      if (LegalDocumentFormErrors.hasOwnProperty(key)) {
        LegalDocumentFormErrors[key] = "";
      }
    }

    if (template === "") {
      setLegalDocumentFormErrors({
        ...LegalDocumentFormErrors,
        template: t("form.Template id is required."),
      });
    } else if (template_name.trim().length < 1) {
      setLegalDocumentFormErrors({
        ...LegalDocumentFormErrors,
        template_name: t("form.Template name is required."),
      });
    } else if (token.trim().length < 1) {
      setLegalDocumentFormErrors({
        ...LegalDocumentFormErrors,
        token: t("form.Template token is required."),
      });
    } else {
      let postData = {};
      if (!isEditing) {
        postData = {
          template_id: template,
          template_name: template_name,
          token: token,
          company_id: postDataObj.company_id,
        };
      } else {
        postData = {
          id: editFormData.id,
          template_id: template,
          template_name: template_name,
          token: token,
          company_id: postDataObj.company_id,
        };
      }

      setLoading(true);
      try {
        let res = "";
        if (!isEditing) {
          res = await api.post(`/contract/create/legal/template/`, postData, {
            headers: {
              "Content-Type": "application/json",
            },
          });
        } else {
          res = await api.post(`/contract/edit/legal/template/`, postData, {
            headers: {
              "Content-Type": "application/json",
            },
          });
        }
        setLoading(false);
        if (res.data) {
          setIsOpen(true);
          setModalData({
            ...modalData,
            title: !isEditing
              ? t("form.Document Added")
              : t("form.Document Updated"),
            bodyText: `${t("form.You have successfully")} ${
              !isEditing ? t("form.added") : t("form.updated")
            } ${t("form.Document")}.`,
            apiResStatus: true,
          });
        }
      } catch (error) {
        setLoading(false);
        let data;
        if (error && error.response) data = error.response.data;

        if (data && data.message) {
          setIsOpen(true);
          let action = !isEditing ? t("form.added") : t("form.updated");
          setModalData({
            ...modalData,
            title: t("form.Something went wrong"),
            bodyText: data.message,
            apiResStatus: false,
          });
        } else {
          setIsOpen(true);
          let action = !isEditing ? t("form.added") : t("form.updated");
          setModalData({
            ...modalData,
            title: t("form.Something went wrong"),
            bodyText: `${t("form.Document was not")} ${action}. ${t(
              "form.Please try again"
            )}`,
            apiResStatus: false,
          });
        }
      }

      setLegalDocumentFormErrors({
        template: "",
        template_name: "",
        token: "",
      });
    }
  };

  const saveOrEditClause = async (postDataObj) => {
    let { clause_name, clause_text, company_id } = !isEditing
      ? postDataObj
      : postDataObj;

    for (const key in LegalDocumentFormErrors) {
      if (LegalDocumentFormErrors.hasOwnProperty(key)) {
        LegalDocumentFormErrors[key] = "";
      }
    }

    if (clause_name === "") {
      setClauseLibaryFormErrors({
        ...ClauseLibaryFormErrors,
        clause_name: t("form.Clause name is required."),
      });
    } else if (clause_text.length === 0) {
      setClauseLibaryFormErrors({
        ...ClauseLibaryFormErrors,
        clause_text: t("form.Atleast 1 Clause Description is required."),
      });
    } else if (company_id < 0) {
      setClauseLibaryFormErrors({
        ...LegalDocumentFormErrors,
        company_id: t("form.Please check company id."),
      });
    } else {
      let postData = {};
      if (!isEditing) {
        postData = {
          clause_name: clause_name,
          clause_text: clause_text,
          company_id: company_id,
        };
      } else {
        postData = {
          id: editFormData.id,
          clause_name: clause_name,
          clause_text: clause_text,
          company_id: company_id,
        };
      }

      setLoading(true);
      try {
        let res = "";
        if (!isEditing) {
          res = await api.post(`/contract/create/clause/`, postData, {
            headers: {
              "Content-Type": "application/json",
            },
          });
        } else {
          res = await api.post(`/contract/edit/clause/`, postData, {
            headers: {
              "Content-Type": "application/json",
            },
          });
        }
        setLoading(false);
        if (res.data) {
          toast.success("Clause Added/ updated successfully");
          setActiveLink("ClauseLibaryList");
        }
      } catch (error) {
        setLoading(false);
        let data;
        if (error && error.response) data = error.response.data;

        if (data && data.message) {
          setIsOpen(true);
          let action = !isEditing ? t("form.added") : t("form.updated");
          setModalData({
            ...modalData,
            title: t("form.Something went wrong"),
            bodyText: data.message,
            apiResStatus: false,
          });
        } else {
          setIsOpen(true);
          let action = !isEditing ? t("form.added") : t("form.updated");
          setModalData({
            ...modalData,
            title: t("form.Something went wrong"),
            bodyText: `${t("form.Document was not")} ${action}. ${t(
              "form.Please try again"
            )}`,
            apiResStatus: false,
          });
        }
      }

      setLegalDocumentFormErrors({
        template: "",
        template_name: "",
        token: "",
        template_description: "",
      });
    }
  };

  const saveOrEditOffice365Setting = async (postDataObj) => {
    let { clientId, tenantId, redirectUrl, secretKey } = !isEditing
      ? postDataObj
      : editFormData;

    for (const key in office365SettingFormErrors) {
      if (office365SettingFormErrors.hasOwnProperty(key)) {
        office365SettingFormErrors[key] = "";
      }
    }

    if (clientId === "") {
      setOffice365SettingFormErrors({
        ...office365SettingFormErrors,
        clientId: t("constant.client id is required."),
      });
    } else if (tenantId.trim().length < 1) {
      setOffice365SettingFormErrors({
        ...office365SettingFormErrors,
        tenantId: t("constant.tenant id is required."),
      });
    } else if (redirectUrl.trim().length < 1) {
      setOffice365SettingFormErrors({
        ...office365SettingFormErrors,
        redirectUrl: t("constant.redirect url is required."),
      });
    } else if (secretKey.trim().length < 1) {
      setOffice365SettingFormErrors({
        ...office365SettingFormErrors,
        secretKey: t("constant.secret key is required."),
      });
    } else {
      let postData = {};
      if (!isEditing) {
        postData = {
          ClientId: clientId,
          TenantId: tenantId,
          RedirectUrl: redirectUrl,
          secretKey: secretKey,
        };
      } else {
        postData = {
          id: editFormData.id,
          ClientId: clientId,
          TenantId: tenantId,
          RedirectUrl: redirectUrl,
          secretKey: secretKey,
        };
      }

      setLoading(true);
      try {
        let res = "";
        if (!isEditing) {
          res = await api.post(`/user/micro/detail/`, postData, {
            headers: {
              "Content-Type": "application/json",
            },
          });
        } else {
          res = await api.post(`/user/micro/detail/`, postData, {
            headers: {
              "Content-Type": "application/json",
            },
          });
        }
        setLoading(false);
        if (res.data) {
          setIsOpen(true);
          setModalData({
            ...modalData,
            title: !isEditing
              ? t("constant.Setting Added")
              : t("constant.Setting Updated"),
            bodyText: `${t("form.You have successfully")} ${
              !isEditing ? t("form.added") : t("form.updated")
            } ${t("constant.Office 365 Setting")}.`,
            apiResStatus: true,
          });
        }
      } catch (error) {
        setLoading(false);
        let data;
        if (error && error.response) data = error.response.data;

        if (data && data.message) {
          setIsOpen(true);
          let action = !isEditing ? t("form.added") : t("form.updated");
          setModalData({
            ...modalData,
            title: t("common.Something went wrong"),
            bodyText: data.message,
            apiResStatus: false,
          });
        } else {
          setIsOpen(true);
          let action = !isEditing ? t("form.added") : t("form.updated");
          setModalData({
            ...modalData,
            title: t("common.Something went wrong"),
            bodyText: `${t(
              "constant.Office 365 Setting"
            )} was not ${action}. ${t("form.Please try again")}`,
            apiResStatus: false,
          });
        }
      }

      setOffice365SettingFormErrors({
        clientId: "",
        tenantId: "",
        redirectUrl: "",
      });
    }
  };

  const saveOrEditPlan = async (postData) => {
    try {
      let res = "";
      if (!isEditing) {
        res = await api.post(`/user/plan/create/`, postData, {
          headers: {
            "Content-Type": "application/json",
          },
        });
      } else {
        res = await api.post(`/user/plan/update/`, postData, {
          headers: {
            "Content-Type": "application/json",
          },
        });
      }
      setLoading(false);
      if (res.data) {
        setIsOpen(true);
        setModalData({
          ...modalData,
          title: !isEditing ? t("form.Plan Added") : t("form.Plan Updated"),
          bodyText: `${t("form.You have successfully")} ${
            !isEditing ? t("form.added") : t("form.updated")
          } ${t("form.Plan")}.`,
          apiResStatus: true,
        });
      }
    } catch (error) {
      setLoading(false);
      let data;
      if (error && error.response) data = error.response.data;
      let action = !isEditing ? t("form.added") : t("form.updated");
      if (data && data.message) {
        setModalData({
          ...modalData,
          title: t("form.Something went wrong"),
          bodyText: data.message,
          apiResStatus: false,
        });
      } else {
        setModalData({
          ...modalData,
          title: t("form.Something went wrong"),
          bodyText: `${t("form.Plan was not")} ${action}. ${t(
            "form.Please try again"
          )}`,
          apiResStatus: false,
        });
      }
    }
  };

  const saveOrEditSection = async (postData) => {
    try {
      let res = "";
      if (!isEditing) {
        res = await api.post(
          `/nda_report/diligence/section/create/`,
          postData,
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
      } else {
        res = await api.post(`/nda_report/diligence/section/edit/`, postData, {
          headers: {
            "Content-Type": "application/json",
          },
        });
      }
      setLoading(false);
      if (res.data) {
        setIsOpen(true);
        setModalData({
          ...modalData,
          title: !isEditing
            ? t("form.Section Added")
            : t("form.Section Updated"),
          bodyText: `${t("form.You have successfully")} ${
            !isEditing ? t("form.added") : t("form.updated")
          } ${t("form.Section")}.`,
          apiResStatus: true,
        });
      }
    } catch (error) {
      setLoading(false);
      let data;
      if (error && error.response) data = error.response.data;
      let action = !isEditing ? t("form.added") : t("form.updated");
      if (data && data.message) {
        setModalData({
          ...modalData,
          title: t("form.Something went wrong"),
          bodyText: data.message,
          apiResStatus: false,
        });
      } else {
        setModalData({
          ...modalData,
          title: t("form.Something went wrong"),
          bodyText: `Section was not ${action}. ${t("form.Please try again")}}`,
          apiResStatus: false,
        });
      }
    }
  };

  const saveOrEditTask = async (postData) => {
    try {
      let res = "";
      if (!isEditing) {
        res = await api.post(`/nda_report/diligence/task/create/`, postData, {
          headers: {
            "Content-Type": "application/json",
          },
        });
      } else {
        res = await api.post(`/nda_report/diligence/task/edit/`, postData, {
          headers: {
            "Content-Type": "application/json",
          },
        });
      }
      setLoading(false);
      if (res.data) {
        setIsOpen(true);
        setModalData({
          ...modalData,
          title: !isEditing ? t("form.Task Added") : t("form.Task Updated"),
          bodyText: `${t("form.You have successfully")} ${
            !isEditing ? t("form.added") : t("form.updated")
          } ${t("form.Task")}.`,
          apiResStatus: true,
        });
      }
    } catch (error) {
      setLoading(false);
      let data;
      if (error && error.response) data = error.response.data;
      let action = !isEditing ? t("form.added") : t("form.updated");
      if (data && data.message) {
        setModalData({
          ...modalData,
          title: t("form.Something went wrong"),
          bodyText: data.message,
          apiResStatus: false,
        });
      } else {
        setModalData({
          ...modalData,
          title: t("form.Something went wrong"),
          bodyText: `${t("form.Task was not")} ${action}. ${t(
            "form.Please try again"
          )}`,
          apiResStatus: false,
        });
      }
    }
  };

  const contextData = {
    currentForm,
    setCurrentForm,
    saveOrEditCompany,
    saveOrEditCompanylegacy,
    saveOrEditUserlegacy,
    saveOrEditReviewerlegacy,
    saveOrEditAdminlegacy,
    companyFormErrors,
    modalData,
    setModalData,
    setIsOpen,
    modalIsOpen,
    setModuleToEdit,
    editFormData,
    setEditFormData,
    isEditing,
    setIsEditing,
    setCompanyFormErrors,
    adminFormErrors,
    setAdminFormErrors,
    saveOrEditAdmin,
    saveOrEditUser,
    saveOrEditReviewer,
    setReviewerFormErrors,
    reviewerFormErrors,
    userFormErrors,
    setUserFormErrors,
    saveOrEditContract,
    saveOrEditContractlegacy,
    contractFormErrors,
    setContractFormErrors,
    loading,
    setLoading,
    handleMultipleContracts,
    handleMultipleContractslegacy,
    passwordFormErrors,
    setPasswordFormErrors,
    updatePassword,
    shouldLogout,
    setShouldLogout,
    shouldRedirect,
    checkReviewerEmailexists,
    showSubmitBtn,
    progressValue,
    handleZipUpload,
    handleZipUploadlegacy,
    userSignUp,

    saveOrEditContact,
    contactFormErrors,
    setContactFormErrors,

    saveOrEditGroup,
    groupFormErrors,
    setGroupFormErrors,

    planModalIsOpen,
    setPlanModelIsOpen,

    LegalDocumentFormErrors,
    ClauseLibaryFormErrors,
    saveOrEditLegalDocument,
    saveOrEditClause,

    saveOrEditPlan,
    saveOrEditSection,
    saveOrEditTask,
    getNotifications,
    getNotificationslegacy,

    saveOrEditDemoUser,
    demoUserFormErrors,
    setDemoUserFormErrors,

    office365SettingFormErrors,
    setOffice365SettingFormErrors,
    saveOrEditOffice365Setting,
  };

  return (
    <FormContext.Provider value={contextData}>{children}</FormContext.Provider>
  );
};
