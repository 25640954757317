import React, { useContext, useState } from "react";
import Modal from "react-modal";
import "./modal.css";
import AuthContext from "../../context/AuthContext";
import AppContext from "../../context/AppContext";
import FormContext from "../../context/FormContext";
import { validatePhone } from "../../utils/utils";
import { toast } from "react-toastify";
import useAxios from "../../hooks/useAxios";
import { resolve } from "../../api/resolve";
import { parse } from "papaparse";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";

const customStyles = {
  content: {
    top: "30%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    maxWidth: "400px",
    maxHeight: "auto",
    borderRadius: "11px",
    minWidth: "300px",
  },
  overlay: {
    backgroundColor: "rgba(64, 8, 53, 0.63)",
  },
};

Modal.setAppElement("#root");
const VerifyMobile = ({
  modalIsOpen,
  setIsOpen,
  setPlanModelIsOpen,
  getUserDetails,
}) => {
  const api = useAxios();
  const { t } = useTranslation();
  const { user, setUser, logoutUser } = useContext(AuthContext);
  const [userMobile, setUserMobile] = useState("");
  const [userOtp, setUserOtp] = useState("");
  const [mobileError, setMobileError] = useState("");
  const [otpError, setOtpError] = useState("");
  const [isShowOtp, setIsShowOtp] = useState(false);
  const [countryCode, setCountryCode] = useState("+91");
  const [countryListData, setCountryListData] = useState([]);
  const [remainingTime, setRemainingTime] = useState(0);

  const getOtp = async () => {
    setMobileError("");
    if (userMobile.trim().length === 0) {
      setMobileError(t("form.Please enter mobile number"));
    } else if (!validatePhone(userMobile)) {
      setMobileError(t("form.Please enter valid mobile number"));
    } else {
      const postData = {
        phone: countryCode + userMobile,
      };
      const res = await resolve(
        api.post(`user/otp/`, postData).then((res) => res.data)
      );

      if (res.data) {
        setIsShowOtp(true);
        toast.success(t("form.OTP sent successfully"));
        setRemainingTime(180);
      }
      if (res.error) {
        toast.error(t("common.Something went wrong"));
      }
    }
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      if (remainingTime > 0) {
        setRemainingTime(remainingTime - 1);
      }
    }, 1000);

    // Clear the timer when component unmounts or when remainingTime reaches 0
    return () => clearTimeout(timer);
  }, [remainingTime]);

  const handleMobileKeyPress = (userMobile) => {
    setMobileError("");
    if (!validatePhone(userMobile)) {
      setMobileError(t("form.Please enter valid mobile number"));
    }
  };

  const matchOtp = async () => {
    setMobileError("");
    if (userMobile.trim().length === 0) {
      setMobileError(t("form.Please enter mobile number"));
    } else if (!validatePhone(userMobile)) {
      setMobileError(t("form.Please enter valid mobile number"));
    }
    if (userOtp.trim().length === 0) {
      setMobileError(t("form.Please enter otp"));
    } else {
      const postData = {
        otp: parseInt(userOtp),
      };
      const res = await resolve(
        api.post(`user/match/otp/`, postData).then((res) => res.data)
      );

      if (res.data) {
        if (res.data.message == "mobileno verified sucessfully") {
          setUser({ ...user, phone_verified: true, phone: userMobile });
          localStorage.setItem(
            "authTokens",
            JSON.stringify(res.data.token_data)
          );
          setIsOpen(false);
          if (user.is_subscription_active === false) {
            setPlanModelIsOpen(true);
          } else {
            getUserDetails();
          }

          toast.success(t("form.Phone Number verified successfully"));
        } else if (res.data.message == "mobileno not verified") {
          setOtpError(t("form.Otp not matched"));
        }
      }
      if (res.error) {
        toast.error(t("common.Something went wrong"));
      }
    }
  };

  const getCountryListApi = async () => {
    const responceData = await resolve(
      api.get("/company/countries/list/").then((res) => res.data)
    );

    if (responceData["data"]) {
      setCountryListData(responceData["data"]);
    }
  };

  useEffect(() => {
    getCountryListApi();
  }, []);

  return (
    <div className="">
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={() => setIsOpen(true)}
        style={customStyles}
        onAfterClose={() => {}}
      >
        <div className=" modal-container">
          <h1 className="text-center text-primary font-bold text-lg">
            {t("form.Verify Mobile Number")}
          </h1>
          {isShowOtp ? (
            <>
              <div className="mt-5">
                <div className="flex">
                  <label className="flex flex-start mb-1">
                    {t("form.Mobile Number")}
                  </label>
                  <button
                    onClick={(e) => {
                      e.preventDefault();
                      setIsShowOtp(false);
                    }}
                    className="bg-primary hover:bg-secondary font-semibold text-white ml-5 mb-2 pl-2 pr-2 rounded-lg mr-5"
                  >
                    {t("form.Edit")}
                  </button>
                </div>
                <input
                  type="text"
                  disabled
                  value={`${countryCode} ${userMobile}`}
                  className="border-2 border-primary rounded-lg outline-none p-1 pl-2 w-full"
                />
              </div>

              <div className="mt-5">
                <label className="flex flex-start mb-1">
                  {t("form.Enter OTP")}
                </label>
                <input
                  type="text"
                  value={userOtp}
                  className="border-2 border-primary rounded-lg outline-none p-1 pl-2 w-full"
                  onChange={(e) => setUserOtp(e.target.value)}
                />
                <p className="flex flex-start error_msg">
                  {remainingTime > 0 &&
                    `Resend OTP available in ${Math.floor(
                      remainingTime / 60
                    )}:${(remainingTime % 60).toString().padStart(2, "0")}`}
                </p>
                <p className="flex flex-start error_msg">{otpError}</p>
              </div>

              <div className="flex justify-center mt-5">
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    matchOtp();
                  }}
                  className="bg-primary hover:bg-secondary font-semibold text-white p-1 pl-2 pr-2 rounded-lg mr-5"
                >
                  {t("form.Match OTP")}
                </button>
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    getOtp();
                  }}
                  disabled={remainingTime > 0}
                  className={`bg-primary hover:bg-secondary font-semibold text-white p-1 pl-2 pr-2 rounded-lg mr-5 ${
                    remainingTime > 0
                      ? "opacity-50 cursor-not-allowed bg-gray-400"
                      : ""
                  }`}
                >
                  {t("form.Resend OTP")}
                </button>
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    setIsOpen(false);
                    logoutUser();
                  }}
                  className="bg-[#606060] font-semibold text-white p-1 pl-2 pr-2 rounded-lg"
                >
                  {t("common.Cancel")}
                </button>
              </div>
            </>
          ) : (
            <>
              <div className="mt-5 flex flex-col justify-center">
                <label className="flex flex-start mb-1">
                  {t("form.Enter Mobile Number")}
                </label>

                <div className="flex">
                  <select
                    value={countryCode}
                    onChange={(e) => setCountryCode(e.target.value)}
                    className="border-2 border-primary rounded-lg outline-none p-1 pl-2 mr-2 w-1/5"
                  >
                    {countryListData.map((item, index) => {
                      return (
                        <option key={index} value={`+` + item.phonecode}>
                          {item.name + ` (+` + item.phonecode + `)`}
                        </option>
                      );
                    })}
                  </select>
                  <input
                    type="number"
                    value={userMobile}
                    className="border-2 border-primary rounded-lg outline-none p-1 pl-2 "
                    onChange={(e) => setUserMobile(e.target.value)}
                    onKeyUp={(e) => handleMobileKeyPress(e.target.value)}
                  />
                </div>
                <p className="flex flex-start error_msg">{mobileError}</p>
              </div>
              <div className="flex justify-center mt-5">
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    getOtp();
                  }}
                  className="bg-primary hover:bg-secondary font-semibold text-white p-1 pl-8 pr-8 rounded-lg mr-5"
                >
                  {t("form.Get OTP")}
                </button>
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    setIsOpen(false);
                    logoutUser();
                  }}
                  className="bg-[#606060] font-semibold text-white p-1 pl-8 pr-8 rounded-lg"
                >
                  {t("common.Cancel")}
                </button>
              </div>
            </>
          )}
        </div>
      </Modal>
    </div>
  );
};

export default VerifyMobile;
