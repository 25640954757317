import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { resolve } from "../../api/resolve";
import useAxios from "../../hooks/useAxios";
import { toast } from "react-toastify";
import { customAlphabet } from "nanoid";
import AuthContext from "../../context/AuthContext";
import LoadingScreen from "../loader/LoadingScreen";
import copy from "../../assets/icons/copy-clause.png";

const GenrateDocumentAnalysis = ({ documentData }) => {
  const usa_states = [
    { name: "Alabama" },
    { name: "Alaska" },
    { name: "Arizona" },
    { name: "Arkansas" },
    { name: "California" },
    { name: "Colorado" },
    { name: "Connecticut" },
    { name: "Delaware" },
    { name: "Florida" },
    { name: "Georgia" },
    { name: "Hawaii" },
    { name: "Idaho" },
    { name: "Illinois" },
    { name: "Indiana" },
    { name: "Iowa" },
    { name: "Kansas" },
    { name: "Kentucky" },
    { name: "Louisiana" },
    { name: "Maine" },
    { name: "Maryland" },
    { name: "Massachusetts" },
    { name: "Michigan" },
    { name: "Minnesota" },
    { name: "Mississippi" },
    { name: "Missouri" },
    { name: "Montana" },
    { name: "Nebraska" },
    { name: "Nevada" },
    { name: "New Hampshire" },
    { name: "New Jersey" },
    { name: "New Mexico" },
    { name: "New York" },
    { name: "North Carolina" },
    { name: "North Dakota" },
    { name: "Ohio" },
    { name: "Oklahoma" },
    { name: "Oregon" },
    { name: "Pennsylvania" },
    { name: "Rhode Island" },
    { name: "South Carolina" },
    { name: "South Dakota" },
    { name: "Tennessee" },
    { name: "Texas" },
    { name: "Utah" },
    { name: "Vermont" },
    { name: "Virginia" },
    { name: "Washington" },
    { name: "West Virginia" },
    { name: "Wisconsin" },
    { name: "Wyoming" },
  ];
  const api = useAxios();
  const { user } = useContext(AuthContext);
  const nanoid = customAlphabet("1234567890", 18);
  const [selectedCountry, setSelectedCountry] = useState("");
  const [sectionList, setSectionList] = useState([]);
  const [countryListData, setCountryListData] = useState([]);
  const [sectionTaskList, setSectionTaskList] = useState([]);
  const [playBookList, setplayBookList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [conversationHistory, setConversationHistory] = useState([]);

  useEffect(() => {
    getSectionList();
    getCountryListApi();
    getPlayBookList();
  }, []);

  useEffect(() => {
    const localHis = conversationHistory;
    // console.log("local", localHis);
  }, [conversationHistory]);

  const getPlayBookList = async () => {
    const res = await resolve(
      api
        .get(`contract/document/list/?type=playbook&limit=100`)
        .then((res) => res.data)
    );
    if (res.data) {
      setplayBookList(res.data.results);
    }
  };

  const htmlToPlainText = (html) => {
    const tempDiv = document.createElement("div");
    tempDiv.innerHTML = html;
    return tempDiv.textContent || tempDiv.innerText || "";
  };

  const handleCopyClick = (textToCopy) => {
    try {
      const textarea = document.createElement("textarea");
      textarea.value = textToCopy;
      document.body.appendChild(textarea);
      textarea.select();
      document.execCommand("copy");
      document.body.removeChild(textarea);
      toast.success("Copied to clipboard");
    } catch (error) {
      toast.error("Failed to copy to clipboard");
    }
  };

  const uploadQuoqoDeligence = async (postData) => {
    setLoading(true);
    try {
      let taskId = postData.selectedPerspective;
      const aiContent = htmlToPlainText(documentData);
      let taskInfo = sectionTaskList.find((task) => task.id == taskId);
      let dataInfo = {};
      if (taskInfo) {
        dataInfo[taskInfo["section_id"].section_name] = [taskInfo.task_name];
      }

      let formData = new FormData();
      // file.forEach((file) => {
      //   if (postData.aitools == "Playrite") {
      //     formData.append("file", file.id);
      //   }
      //   formData.append("legacy_doc_id", file.id);
      // });
      formData.append("playbookId", postData.playbookId);
      formData.append("sections", JSON.stringify(dataInfo));
      formData.append("content", aiContent);
      formData.append("taskdecpt", postData.taskDescription);
      formData.append("perspective", postData.perspective);
      formData.append("governing_law", postData.governing_law);
      formData.append("customprompt", postData.customePrompt);

      formData.append("super_prompt", postData.superPrompt);
      const uniqueId = nanoid();
      formData.append("userEmailAddress", user.user_email);
      formData.append("uniqueID", uniqueId);
      formData.append(
        "receiveReportWebhookURL",
        "https://www.example.com/receive_report_webhook/uygjhghbygjhvbh"
      );
      let main_result = { status: 400 };
      if (postData.aitools === "NDAReview") {
        try {
          // const uniqueId = nanoid();
          // formDatafornda.append("documentName", file.doc_name);
          // formDatafornda.append("userEmailAddress", user.user_email);
          // formDatafornda.append("uniqueID", uniqueId);
          // formDatafornda.append(
          //   "receiveReportWebhookURL",
          //   "https://www.example.com/receive_report_webhook/uygjhghbygjhvbh"
          // );
          // formDatafornda.append("document", base64Data);
          // file.forEach(async (file, index) => {
          //   try {
          //     let authTokens = localStorage.getItem("authTokens")
          //       ? JSON.parse(localStorage.getItem("authTokens"))
          //       : null;

          //     const apiResponse = await api.get(
          //       file.converted_file_url + "?" + highlightToken.sas_token,
          //       { responseType: "blob" }
          //     );

          //     const blob = apiResponse.data;
          //     const reader = new FileReader();

          //     reader.readAsDataURL(blob);

          //     reader.onload = function () {
          //       const formDatafornda = new FormData();
          //       const uniqueId = nanoid();
          //       const base64Data = reader.result.split("base64,")[1];
          //       formDatafornda.append("legacy_doc_id", file.id);
          //       formDatafornda.append("documentName", file.doc_name);
          //       formDatafornda.append("userEmailAddress", user.user_email);
          //       formDatafornda.append("uniqueID", uniqueId);
          //       formDatafornda.append(
          //         "receiveReportWebhookURL",
          //         "https://www.example.com/receive_report_webhook/uygjhghbygjhvbh"
          //       );
          //       formDatafornda.append("document", base64Data);

          const apiResponse = api
            .post(
              `${process.env.REACT_APP_BACKEND_BASE_URL}/nda_report/receive_nda_doc_webhook`,
              formData,
              {
                "Content-Type": "application/json",
              }
            )
            .then((res) => {
              if (res.status === 200) {
                main_result = res;
                toast.success(t("allModule.Document uploaded successfully"));
              }
            });
        } catch (error) {
          console.error("Error in outer try block:", error);
          toast.error("Error processing document");
        }
      } else if (postData.aitools == "ContractReview") {
        const res = await api.post(
          `${process.env.REACT_APP_BACKEND_BASE_URL}/nda_report/diligence_gendoc/upload/`,
          formData
        );
        main_result = res;
      } else if (postData.aitools == "TransactionReview") {
        const res = await api.post(
          `${process.env.REACT_APP_BACKEND_BASE_URL}/nda_report/transaction_gendoc/upload/`,
          formData
        );
        main_result = res;
      } else if (postData.aitools == "IPReview") {
        const res = await api.post(
          `${process.env.REACT_APP_BACKEND_BASE_URL}/nda_report/ipreview_gendoc/upload/`,
          formData
        );
        main_result = res;
      } else if (postData.aitools == "SectoralReview") {
        const res = await api.post(
          `${process.env.REACT_APP_BACKEND_BASE_URL}/nda_report/Sectoralreview_gendoc/upload/`,
          formData
        );
        main_result = res;
      } else if (postData.aitools == "Playrite") {
        const res = await api.post(
          `${process.env.REACT_APP_BACKEND_BASE_URL}/nda_report/playrite/deviation/uploadlegacy/`,
          formData
        );
        main_result = res;
      } else {
        let res = { status: 400 };
      }
      // if "NDAReview""ContractReview""TransactionReview""IPReview">"SectoralReview""Playrite"

      if (
        main_result.status == 200 ||
        main_result.message == "file uploaded successfully"
      ) {
        const recommendationsArray = Object.values(main_result.data);
        const userMessage = {
          user: postData.taskDescription,
          assistant: recommendationsArray,
        };
        setConversationHistory((prevHistory) => [...prevHistory, userMessage]);
        setFormData(initialFormData);
      }
    } catch (error) {
      if (error?.response?.data?.message) {
        toast.error(error.response.data.message);
      } else {
        console.log("error", error.message);
        toast.error(
          t("allModule.Something went wrong, please try again later.")
        );
      }
    }
    setLoading(false);
  };

  const getSectionTaskList = async (sectionId) => {
    setSectionTaskList([]);
    const res = await resolve(
      api
        .get(
          `nda_report/diligence/task/list/?section_id=${sectionId}&ordering=description&sortingOrder=asc`
        )
        .then((res) => res.data)
    );
    if (res.data) {
      const { data } = res;
      const { results, count } = data;
      setSectionTaskList(results);
    } else {
      setSectionTaskList([]);
    }
  };

  const handleGoverningLawChange = (e) => {
    const selectedValue = e.target.value;
    setFormData({ ...formData, governing_law: selectedValue });

    const isCountry = countryListData.some(
      (country) => country.name === selectedValue
    );

    if (isCountry) {
      setSelectedCountry(selectedValue);
    } else {
      const sk = "United States of America";
      setSelectedCountry(sk);
    }
  };

  const getCountryListApi = async () => {
    const responceData = await resolve(
      api.get("/company/countries/list/").then((res) => res.data)
    );
    if (responceData["data"]) {
      setCountryListData(responceData["data"]);
    }
  };

  const { t } = useTranslation();
  const initialFormData = {
    aitools: "",
    selectedPerspective: "",
    taskDescription: "",
    perspective: "",
    governing_law: "",
    sectionId: "",
    customePrompt: "",
    playbookId: "",
  };
  const [formData, setFormData] = useState(initialFormData);
  const [formDataErr, setFormDataErr] = useState(initialFormData);

  const handleUploadQuoqoDeligence = () => {
    for (const key in formDataErr) {
      if (formDataErr.hasOwnProperty(key)) {
        formDataErr[key] = "";
      }
    }

    if (formData.aitools == "Playrite" && formData.playbookId == "") {
      setFormDataErr({
        ...formDataErr,
        playbookId: t("allModule.Please select Playbook"),
      });
    } else if (formData.aitools != "NDAReview" && formData.sectionId == "") {
      setFormDataErr({
        ...formDataErr,
        sectionId: t("constant.Please select section"),
      });
    } else if (
      formData.aitools != "NDAReview" &&
      formData.selectedPerspective == ""
    ) {
      setFormDataErr({
        ...formDataErr,
        selectedPerspective: t("constant.Please select task"),
      });
    } else {
      uploadQuoqoDeligence(formData);
    }
  };

  const getSectionList = async (clearText) => {
    let seccat = "";
    if (clearText == "NDAReview") {
      seccat = "";
    } else if (clearText == "ContractReview") {
      seccat = "diligence";
    } else if (clearText == "TransactionReview") {
      seccat = "Transaction";
    } else if (clearText == "IPReview") {
      seccat = "IP";
    } else if (clearText == "SectoralReview") {
      seccat = "SectoralReview";
    } else if (clearText == "Playrite") {
      seccat = "Playbook";
    }
    const res = await resolve(
      api
        .get(
          `nda_report/diligence/section/list/?SecCat=${seccat}&ordering=section_name&sortingOrder=asc`
        )
        .then((res) => res.data)
    );
    if (res.data) {
      const { data } = res;
      const { results, count } = data;
      setSectionList(results);
    }
  };

  const handleAIToolsChange = (e) => {
    const selectedValue = e.target.value;
    setFormData({ ...formData, aitools: selectedValue });
    getSectionList(selectedValue);
  };

  return (
    <>
      {loading ? (
        <LoadingScreen />
      ) : (
        <div className="window-container">
          <>
            <div className="text-left ">
              <div className="mt-1 flex flex-col justify-center">
                <label className="text-primary font-normal text-md">
                  <strong> {t("allModule.AI Reviews")}:</strong>
                </label>
                <div className="flex flex-col justify-center">
                  <select
                    id="aitools"
                    name="aitools"
                    value={formData.aitools}
                    onChange={handleAIToolsChange}
                    className="w-full border-2 border-primary rounded-lg outline-none"
                  >
                    <option value="">
                      {t("allModule.Select Type of Review")}
                    </option>
                    <option value="ContractReview">
                      {t("constant.Review a Contract")}
                    </option>
                    <option value="TransactionReview">
                      {t("constant.Review a Transaction Document")}
                    </option>
                    <option value="IPReview">{t("constant.IP Review")}</option>
                    <option value="SectoralReview">
                      {t("constant.Sectoral Review")}
                    </option>
                    <option value="Playrite">{t("allModule.Playrite")}</option>
                  </select>
                </div>
              </div>
              <hr
                style={{
                  border: "1px solid #ccc",
                  margin: "15px 0px 7px 0px ",
                }}
              />
              <div className="swapnil">
                {formData.aitools &&
                  formData.aitools != "NDAReview" &&
                  formData.aitools != "" && (
                    <>
                      {formData.aitools &&
                        formData.aitools != "Playrite" &&
                        formData.aitools != "" && (
                          <>
                            <div className="flex flex-col justify-center">
                              <label className="text-primary font-normal text-md">
                                {t("constant.Perspective")}:
                              </label>
                              <input
                                type="text"
                                value={formData.reviewReason}
                                onChange={(e) =>
                                  setFormData({
                                    ...formData,
                                    reviewReason: e.target.value,
                                    perspective: e.target.value,
                                  })
                                }
                                className="border-2 border-primary rounded-lg outline-none p-1 pl-2 "
                                placeholder={t(
                                  "constant.From whose perspective are you reviewing this document? (e.g. buyer or seller)"
                                )}
                              />
                              <p className="flex flex-start error_msg">
                                {formDataErr.reviewReason}
                              </p>
                            </div>
                            <div className="flex flex-col justify-center">
                              <label className="text-primary font-normal text-md">
                                {t("constant.Governing Law")}:
                              </label>
                              <select
                                value={formData.governing_law}
                                onChange={(e) => handleGoverningLawChange(e)}
                                className="border-2 border-primary rounded-lg outline-none p-1 pl-2 w-full"
                              >
                                <option value="">
                                  {t("common.Select")}{" "}
                                  {t("constant.Governing Law")}
                                </option>
                                {countryListData.map((item, index) => (
                                  <option key={index} value={item.name}>
                                    {item.name}
                                  </option>
                                ))}
                                {selectedCountry ===
                                  "United States of America" &&
                                  usa_states.map((item, index) => (
                                    <option key={index} value={item.name}>
                                      {item.name}
                                    </option>
                                  ))}
                              </select>
                              <p className="flex flex-start error_msg">
                                {formDataErr.governing_law}
                              </p>
                            </div>

                            {selectedCountry === "United States of America" && (
                              <div className="flex flex-col justify-center">
                                <label className="text-primary font-normal text-md">
                                  {t("constant.States")}:
                                </label>
                                <select
                                  value={formData.governing_law} // Use the same value for the state selection
                                  onChange={(e) => handleGoverningLawChange(e)} // Use the same handler for state selection
                                  className="border-2 border-primary rounded-lg outline-none p-1 pl-2 w-full"
                                >
                                  <option value="">
                                    {t("common.Select")} {t("constant.States")}
                                  </option>
                                  {usa_states.map((item, index) => (
                                    <option key={index} value={item.name}>
                                      {item.name}
                                    </option>
                                  ))}
                                </select>
                                <p className="flex flex-start error_msg">
                                  {formDataErr.governing_law}
                                </p>
                              </div>
                            )}
                          </>
                        )}

                      {formData.aitools &&
                        formData.aitools == "Playrite" &&
                        formData.aitools != "" && (
                          <div className="mt-5 flex flex-col justify-center">
                            <label className="text-primary font-normal text-md">
                              {t("allModule.Playbook")}:
                            </label>
                            <select
                              className="border-2 border-primary rounded-lg outline-none p-1 pl-2 w-full"
                              value={formData.playbookId}
                              onChange={(e) => {
                                setFormData({
                                  ...formData,
                                  playbookId: e.target.value,
                                });
                              }}
                            >
                              <option value={0}>
                                {t("allModule.Select Playbook")}
                              </option>
                              {playBookList.map((item, index) => (
                                <option key={index} value={item.id}>
                                  {item.name}
                                </option>
                              ))}
                            </select>
                            <p className="flex flex-start error_msg">
                              {formDataErr.playbookId}
                            </p>
                          </div>
                        )}

                      <div className="flex flex-col justify-center">
                        <label className="text-primary font-normal text-md">
                          {t("menu.Section")}:
                        </label>
                        <select
                          className="border-2 border-primary rounded-lg outline-none p-1 pl-2 w-full"
                          value={formData.sectionId}
                          onChange={(e) => {
                            setFormData({
                              ...formData,
                              sectionId: e.target.value,
                            });
                            getSectionTaskList(e.target.value);
                          }}
                        >
                          <option value={0}>
                            {t("constant.Select Section")}
                          </option>
                          {sectionList.map((item, index) => (
                            <option key={index} value={item.id}>
                              {item.section_name}
                            </option>
                          ))}
                        </select>
                        <p className="flex flex-start error_msg">
                          {formDataErr.sectionId}
                        </p>
                      </div>
                      <div className="flex flex-col justify-center">
                        <label className="text-primary font-normal text-md">
                          {t("menu.Task")}:
                        </label>
                        {/* style="background-image:url(male.png); */}
                        <select
                          value={formData.selectedPerspective}
                          onChange={(e) => {
                            const selectedId = e.target.value;
                            const selectedTask = sectionTaskList.find(
                              (task) => task.id == selectedId
                            );
                            if (selectedTask) {
                              setFormData({
                                ...formData,
                                selectedPerspective: selectedId,
                                taskDescription: selectedTask.description,
                                superPrompt: selectedTask.task_name, // Save task_name along with taskDescription
                              });
                            }
                          }}
                          className="border-2 border-primary rounded-lg outline-none p-1 pl-2 mr-2 display-tool-tip"
                        >
                          <option value="">{t("constant.Select Task")}</option>
                          {sectionTaskList.map((value, index) => {
                            return (
                              <option
                                title={value.tool_tip}
                                key={index}
                                value={value.id}
                              >
                                {value.description}
                              </option>
                            );
                          })}
                        </select>
                        <p className="flex flex-start error_msg">
                          {formDataErr.selectedPerspective}
                        </p>
                      </div>
                      {formData.aitools &&
                        formData.aitools != "Playrite" &&
                        formData.aitools != "" && (
                          <div className="flex flex-col justify-center">
                            <label className="text-primary font-normal text-md">
                              {t("constant.Custom Prompt")}:
                            </label>
                            <input
                              type="text"
                              value={formData.customePrompt}
                              onChange={(e) =>
                                setFormData({
                                  ...formData,
                                  customePrompt: e.target.value,
                                })
                              }
                              className="border-2 border-primary rounded-lg outline-none p-1 pl-2 "
                              placeholder={t("constant.Custom Prompt")}
                            />
                          </div>
                        )}
                      <div className="flex justify-center mt-5">
                        <button
                          className="bg-primary hover:bg-secondary font-semibold text-white p-1 pl-8 pr-8 rounded-lg mr-5"
                          onClick={(e) => {
                            e.stopPropagation();
                            handleUploadQuoqoDeligence();
                          }}
                        >
                          {t("modal.Review")}
                        </button>
                      </div>
                    </>
                  )}
              </div>
            </div>
            <div className="">
              {conversationHistory.map((entry, index) => (
                <div key={index} className="chat-message">
                  <div className="user-message">
                    <div className="message-content">
                      <strong>You:</strong> {entry.user}
                    </div>
                  </div>
                  {entry.assistant.map((response, idx) => (
                    <div key={idx} className="assistant-message">
                      <div
                        className="message-content"
                        style={{ position: "relative" }}
                      >
                        <strong>AI:</strong> {response}
                        <button
                          onClick={() => handleCopyClick(response)}
                          className="copy-button"
                          style={{
                            position: "absolute",
                            bottom: "5px",
                            right: "5px",
                          }}
                        >
                          <img
                            src={copy}
                            alt="Copy Icon"
                            className="copy-icon"
                            style={{
                              width: "30px",
                              height: "30px",
                            }}
                          />
                        </button>
                      </div>
                    </div>
                  ))}
                </div>
              ))}
            </div>
          </>
        </div>
      )}
    </>
  );
};

export default GenrateDocumentAnalysis;
