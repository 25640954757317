import React, { useContext, useEffect, useState, useRef } from "react";
import dayjs from "dayjs";
import AuthContext from "../../context/AuthContext";
import FormContext from "../../context/FormContext";
import AppModal from "../modals/Modal";
import EditIcon from "../../assets/icons/Edit.svg";
import AddCompanyModel from "../modals/AddCompanyModel";
import useAxios from "../../hooks/useAxios";
import { resolve } from "../../api/resolve";
import { autoCloseTimer, delay } from "../../constants/constants";
import { toast } from "react-toastify";
import CloseIcon from "../../assets/icons/Close.svg";
import LoadingScreen from "../loader/LoadingScreen";
import { Worker, Viewer } from "@react-pdf-viewer/core";
import AppContext from "../../context/AppContext";
import DownArrow from "../../assets/icons/down-arrow.svg";
import ReactTooltip from "react-tooltip";
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";
import eye_off from "../../assets/icons/eye_off.png";
import eye_on from "../../assets/icons/eye_on.png";
import { useTranslation, Trans } from "react-i18next";
import PurchaseLicence from "../modals/PurchaseLicence";
import PermissionContext from "../../context/PermissionContext";

const Account = () => {
  const { user } = useContext(AuthContext);
  const [companyModelOpen, setCompanyModelOpen] = useState(false);
  const [licenceModelOpen, setLicenceModelOpen] = useState(false);
  const [companyInfo, setCompanyInfo] = useState(null);
  const [loading, setLoading] = useState(false);
  const [userDetail, setUserDetail] = useState({
    phone: "",
    entity_title: "",
    designation: "",
    document_status: 0,
  });
  const [userPlanDetail, setUserPlanDetail] = useState({ name: "-" });
  const [billingActive, setBillingActive] = useState(false);
  const [documentList, setDocumentList] = useState([]);
  const [docUrl, setDocUrl] = useState("");
  const [docType, setDocType] = useState("");
  const {
    highlightToken,
    getSasToken,
    getHighlightSasToken,
    language,
    setLanguage,
    langObj,
  } = useContext(AppContext);

  const [isShowPasswordCurrect, setIsShowPasswordCurrect] = useState(false);
  const [isShowPasswordNew, setIsShowPasswordNew] = useState(false);
  const [isShowPasswordConfirm, setIsShowPasswordConfirm] = useState(false);

  const authFileRef = useRef();
  const taxFileRef = useRef();
  const myRefs = useRef([]);
  const { t, i18n } = useTranslation();
  const { permissionList } = useContext(PermissionContext);

  const [authorization, setAuthorization] = useState({
    authLetterType: "",
    authLetterTypeError: "",
    authLetterFile: "",
    authLetterFileError: "",
    taxDocumentType: "",
    taxDocumentTypeError: "",
    taxDocumentFile: "",
    taxDocumentFileError: "",
  });

  const api = useAxios();
  const {
    setIsOpen,
    modalIsOpen,
    modalData,
    passwordFormErrors,
    setPasswordFormErrors,
    updatePassword,
  } = useContext(FormContext);
  const [passwordDetails, setPasswordDetails] = useState({
    current: "",
    password: "",
    confirm: "",
    email: user.user_email,
  });

  const handleBulkDelete = async () => {
    const res = await resolve(
      api.post("/user/delete/bulk/files/").then((res) => res.data)
    );
    if (res.data) {
      toast.success(res.data.message);
    }
    else{
      toast.error(res.error.response.data.message);
    }
  };

  const handleEditCompany = async () => {
    const res = await resolve(
      api
        .get(`/company/detail/${companyInfo.id}`, {
          headers: {
            "content-type": "application/json",
          },
        })
        .then((res) => res.data)
    );

    if (res.data) {
      setCompanyInfo(res.data);
      setCompanyModelOpen(true);
    }

    if (res.error) {
      toast.error("Something went wrong, to get company info", {
        delay,
        autoClose: autoCloseTimer,
        onClose: () => setLoading(false),
      });
    }
  };

  const getplanDetail = async () => {
    const res = await resolve(
      api
        .get(`/user/plan/name/`, {
          headers: {
            "content-type": "application/json",
          },
        })
        .then((res) => res.data)
    );
    if (res.data) {
      setUserPlanDetail({ name: res.data["data"].name });
    }
  };

  const getUserDetails = async () => {
    if (user.user_type == "super_admin") {
      return false;
    }
    const res = await resolve(
      api
        .get(`/user/detail/`, {
          headers: {
            "content-type": "application/json",
          },
        })
        .then((res) => res.data)
    );

    if (res.data) {
      setUserDetail({
        ...userDetail,
        phone: res.data.phone,
        entity_title: res.data.entity_title,
        designation: res.data.designation,
        document_status: res.data.document_status,
      });
      setCompanyInfo(res.data.company_id);
      if (
        user.user_type == "admin" ||
        (user.user_type == "user" && res.data.created_by == null)
      ) {
        setBillingActive(true);
      }

      if (res.data.document_status === 3) {
        getAdminDetail();
      }
    }

    if (res.error) {
      toast.error("Something went wrong, to get user info", {
        delay,
        autoClose: autoCloseTimer,
        onClose: () => setLoading(false),
      });
    }
  };
  const handleFileChange = (file, from) => {
    if (file !== undefined) {
      const reader = new FileReader();
      reader.readAsText(file);
      reader.onload = async () => {
        let docType = file.name.split(".")[file.name.split(".").length - 1];

        if (
          docType === "pdf" ||
          docType === "jpg" ||
          docType === "jpeg" ||
          docType === "png"
        ) {
          if (from === "authorization") {
            setAuthorization({
              ...authorization,
              authLetterFile: file.name,
              authLetterFileError: "",
            });
          } else {
            setAuthorization({
              ...authorization,
              taxDocumentFile: file.name,
              taxDocumentFileError: "",
            });
          }
        } else {
          if (from === "authorization") {
            setAuthorization({
              ...authorization,
              authLetterFileError: "Only PDF, Jpg, jpeg or png file supported",
            });
          } else {
            setAuthorization({
              ...authorization,
              taxDocumentFileError: "Only PDF, Jpg, jpeg or png file supported",
            });
          }
        }
        setIsOpen(false);
      };
    }
  };

  const handleUploadDocument = async () => {
    if (authorization.authLetterType == "") {
      setAuthorization({
        ...authorization,
        authLetterTypeError: "Please select authorization letter",
      });
    } else if (authorization.authLetterFile == "") {
      setAuthorization({
        ...authorization,
        authLetterTypeError: "",
        authLetterFileError: "Please select file",
      });
    } else if (authorization.taxDocumentType == "") {
      setAuthorization({
        ...authorization,
        authLetterTypeError: "",
        authLetterFileError: "",
        taxDocumentTypeError: "Please select tax document type",
      });
    } else {
      let isValidForm = true;
      if (
        authorization.taxDocumentType != "Not Applicable" &&
        authorization.taxDocumentFile === ""
      ) {
        setAuthorization({
          ...authorization,
          authLetterTypeError: "",
          authLetterFileError: "",
          taxDocumentTypeError: "",
          taxDocumentFileError: "Please select file",
        });
        isValidForm = false;
      }

      if (isValidForm) {
        setLoading(true);
        setAuthorization({
          ...authorization,
          authLetterTypeError: "",
          authLetterFileError: "",
          taxDocumentTypeError: "",
          taxDocumentFileError: "",
        });

        const formData = new FormData();
        formData.append("authorization_type", authorization.authLetterType);
        formData.append("authorization_file", authFileRef.current?.files[0]);
        formData.append("tax_document_type ", authorization.taxDocumentType);
        formData.append("tax_document_file", taxFileRef.current?.files[0]);
        formData.append(
          "webhook_url",
          `${process.env.REACT_APP_BACKEND_BASE_URL}/user/webhook_signature_pad/`
        );

        const responseData = await resolve(
          api
            .post("/user/save-document/", formData, {
              headers: { "Content-Type": "application/json" },
            })
            .then((res) => res.data)
        );
        if (responseData.data) {
          toast.success("Document uploaded successfully", {
            delay,
            autoClose: autoCloseTimer,
            onClose: () => setLoading(false),
          });
        }

        if (responseData.error) {
          toast.error("Something went wrong.", {
            delay,
            autoClose: autoCloseTimer,
            onClose: () => setLoading(false),
          });
        }
        getUserDetails();
        setAuthorization({
          ...authorization,
          authLetterType: "",
          authLetterFile: "",
          taxDocumentType: "",
          taxDocumentFile: "",
          authLetterTypeError: "",
          authLetterFileError: "",
          taxDocumentTypeError: "",
          taxDocumentFileError: "",
        });
        setLoading(false);
      }
    }
  };

  const getAdminDetail = async () => {
    const res = await resolve(
      api.get(`/user/admin/perticular/${user?.user_id}`)
    );
    if (res.data) {
      let documents = res["data"].data["document_list"];
      let newDocumentList = documents.map((document) => {
        document.fileToUpload = "";
        document.FileName = "";
        document.FileNameError = "";
        return document;
      });
      setDocumentList(newDocumentList);
    }

    if (res.error) {
      toast.error("Something went wrong.", {
        autoClose: autoCloseTimer,
        hideProgressBar: true,
      });
    }
  };

  const handleFileChangeAfterReject = (file, document) => {
    if (file !== undefined) {
      const reader = new FileReader();
      reader.readAsText(file);
      reader.onload = async () => {
        let docType = file.name.split(".")[file.name.split(".").length - 1];

        if (
          docType === "pdf" ||
          docType === "jpg" ||
          docType === "jpeg" ||
          docType === "png"
        ) {
          const newState = documentList.map((obj) => {
            if (obj.id === document.id) {
              return {
                ...obj,
                fileToUpload: file,
                FileName: file.name,
                FileNameError: "",
              };
            }
            return obj;
          });
          setDocumentList(newState);
        } else {
          const newState = documentList.map((obj) => {
            if (obj.id === document.id) {
              return {
                ...obj,
                fileToUpload: "",
                FileName: "",
                FileNameError: "Only PDF, Jpg, jpeg or png file supported",
              };
            }
            return obj;
          });
          setDocumentList(newState);
        }
      };
    }
  };

  const handleUploadSingleDocument = async (document) => {
    if (document.FileName == "") {
      const newState = documentList.map((obj) => {
        if (obj.id === document.id) {
          return {
            ...obj,
            fileToUpload: "",
            FileName: "",
            FileNameError: "Please Select file",
          };
        }
        return obj;
      });
      setDocumentList(newState);
    } else {
      const formData = new FormData();
      formData.append("user_id", user?.user_id);
      formData.append("authorization_file_id", document.id);
      formData.append("authorization_file", document.fileToUpload);
      formData.append(
        "webhook_url",
        `${process.env.REACT_APP_BACKEND_BASE_URL}/user/webhook_signature_pad/`
      );

      const responseData = await resolve(
        api
          .post("/user/save-document/update/", formData, {
            headers: { "Content-Type": "application/json" },
          })
          .then((res) => res.data)
      );
      if (responseData.data) {
        toast.success("Document uploaded successfully", {
          delay,
          autoClose: autoCloseTimer,
          onClose: () => setLoading(false),
        });
      }

      if (responseData.error) {
        toast.error("Something went wrong.", {
          delay,
          autoClose: autoCloseTimer,
          onClose: () => setLoading(false),
        });
      }
      getUserDetails();
    }
  };

  const handleViewFile = async (document) => {
    let fileUrl = document["link_of_doc"];
    let docType = fileUrl.split(".")[fileUrl.split(".").length - 1];
    if (docType === "pdf") {
      setDocUrl(fileUrl);
      setDocType("pdf");
    } else {
      setDocUrl(fileUrl);
      setDocType("image");
    }
  };

  useEffect(() => {
    getSasToken();
    getHighlightSasToken();
    getUserDetails();
    getplanDetail();
    setPasswordFormErrors({ current: "", password: "", confirm: "" });
  }, []);

  const renderToolbar = (Toolbar) => (
    <Toolbar>
      {(slots) => {
        const {
          CurrentPageInput,
          GoToNextPage,
          GoToPreviousPage,
          NumberOfPages,

          CurrentScale,
          ZoomIn,
          ZoomOut,
        } = slots;

        return (
          <div className={`w-full`}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                width: "100%",
                justifyContent: "space-between",
              }}
            >
              <div className={`flex ml-8 items-center justify-center`}>
                <div className="w-10">
                  <CurrentPageInput />
                </div>
                <div className="mx-2">
                  /{" "}
                  <span>
                    <NumberOfPages />
                  </span>
                </div>
                <div className="flex flex-col ml-1">
                  <GoToPreviousPage>
                    {(props) => (
                      <>
                        <span
                          disabled={props.isDisabled}
                          onClick={props.onClick}
                          style={{
                            cursor: "pointer",
                            transform: "rotate(180deg)",
                            width: 15,
                            height: 15,
                          }}
                          className="ml-1"
                          data-tip="Previous Page"
                        >
                          <img src={DownArrow} alt="img" />
                        </span>
                        <ReactTooltip
                          backgroundColor="#400835"
                          textColor="#ffffff"
                        />
                      </>
                    )}
                  </GoToPreviousPage>
                  <GoToNextPage>
                    {(props) => (
                      <>
                        <span
                          disabled={props.isDisabled}
                          onClick={props.onClick}
                          style={{
                            cursor: "pointer",
                            marginTop: 2,
                            width: 15,
                            height: 15,
                          }}
                          className="ml-1"
                          data-tip="Next Page"
                        >
                          <img src={DownArrow} alt="img" />
                        </span>
                        <ReactTooltip
                          backgroundColor="#400835"
                          textColor="#ffffff"
                        />
                      </>
                    )}
                  </GoToNextPage>
                </div>
              </div>
              <div className="max-w-fit flex items-center">
                <ZoomIn />
                <span className="mx-1">{CurrentScale()}</span>
                <ZoomOut />
              </div>
            </div>
          </div>
        );
      }}
    </Toolbar>
  );
  /////////// pdf search fuctionality ////////////////////
  const defaultLayoutPluginInstance = defaultLayoutPlugin({
    renderToolbar,
    sidebarTabs: () => [],
  });

  return (
    <div className="text-primary text-base font-normal font-sans upload_doc_page">
      <div className="main_head">
        <h1 className="text-4xl font-bold">{t("modal.Account")}</h1>
      </div>
      <div className="flex">
        <div className="w-2/5">
          <div className="mb-3">
            <span className="text-base font-bold mr-2">
              {t("common.User Name")} :
            </span>
            <span className="text-lg font-medium text-[#79838B]">{`${user.name[0].toUpperCase()}${user.name.slice(
              1
            )}`}</span>
          </div>
          <div className="mb-3">
            <span className="text-base font-bold mr-2">
              {t("common.Email Address")} :
            </span>
            <span className="text-lg font-medium text-[#79838B]">
              {user.user_email}
            </span>
          </div>
          {user.user_type !== "super_admin" && (
            <div className="mb-3">
              <span className="text-base font-bold mr-2">
                {t("common.Phone Number")} :
              </span>
              <span className="text-lg font-medium text-[#79838B]">
                {userDetail.phone}
              </span>
            </div>
          )}

          {user.user_type !== "super_admin" && (
            <div className="mt-5">
              <h1 className="text-base font-bold mb-2">
                {t("allModule.Company Information")} :
              </h1>
              <div className="mb-3 flex">
                <span className="text-base font-bold mr-2">
                  {t("form.Company Name")} :
                </span>
                <span className="text-lg font-medium text-[#79838B]">
                  {companyInfo?.name}
                </span>
                {user.user_type === "admin" && (
                  <button
                    onClick={(e) => {
                      e.stopPropagation();
                      handleEditCompany();
                    }}
                    className="bg-secondary px-1 w-7 py-1 rounded-md flex justify-between text-white wh_spab ml-5"
                  >
                    <img src={EditIcon} width={20} />
                  </button>
                )}
              </div>
            </div>
          )}

          <div className="mt-5">
            <div className="mb-3">
              <span className="text-base font-bold mr-2">
                {t("modal.User Type")} :
              </span>
              <span className="text-lg font-medium text-[#79838B]">{`${user.user_type[0].toUpperCase()}${user.user_type.slice(
                1
              )}`}</span>
            </div>
            {user.user_type !== "super_admin" && (
              <div className="mb-3">
                <span className="text-base font-bold mr-2">
                  {t("modal.Plan Name")} :
                </span>
                <span className="text-lg font-medium text-[#79838B]">
                  {userPlanDetail.name}
                </span>
                {user.user_type === "admin" && (
                  <button
                    className="bg-secondary font-bold text-white w-full ml-4 p-1 rounded-lg w-2/6"
                    type="button"
                    onClick={(e) => {
                      e.stopPropagation();
                      setLicenceModelOpen(true);
                    }}
                  >
                    {t("allModule.Purchase Additional Licenses")}
                  </button>
                )}
              </div>
            )}

            <div className="mb-3">
              <span className="text-base font-bold mr-2">
                {t("adminDashboard.Version")} :
              </span>
              <span className="text-lg font-medium text-[#79838B]">
                {process.env.REACT_APP_VERSION}
              </span>
            </div>
          </div>

          <div className="mb-3">
            <span className="text-base font-bold mr-2">
              {t("common.Language")} :
            </span>
            <select
              className="border-2 border-primary rounded-lg outline-none p-0.5 pl-2"
              onChange={(e) => {
                i18n.changeLanguage(e.target.value);
                setLanguage(e.target.value);
              }}
              value={language}
            >
              {Object.keys(langObj).map((lng) => (
                <option key={lng} value={lng}>
                  {langObj[lng].nativeName}
                </option>
              ))}
            </select>
          </div>
          {billingActive &&
            permissionList["account_billing_management_on"] == true && (
              <a
                href="https://billing.stripe.com/p/login/test_cN20077Cnfl46GY4gg"
                target="_blank"
                className="bg-secondary px-1 w-3/5 py-1 rounded-md flex justify-center text-white wh_spab "
              >
                {t("adminDashboard.Access Billing Account")}
              </a>
            )}

          <div className="mt-5 w-3/5">
            <h1 className="text-base font-bold mb-2">
              {t("modal.Update your account password")}:
            </h1>
            <form
              onSubmit={(e) => {
                e.preventDefault();
                updatePassword(passwordDetails);
              }}
            >
              <div className="flex flex-col mb-3">
                <label>{t("modal.Enter current password")}</label>
                <div className="password-container">
                  <input
                    type={isShowPasswordCurrect ? "text" : "password"}
                    placeholder="*****"
                    className="border-2 border-primary rounded-lg outline-none p-0.5 pl-2 w-full"
                    value={passwordDetails.current}
                    onChange={(e) =>
                      setPasswordDetails({
                        ...passwordDetails,
                        current: e.target.value,
                      })
                    }
                  />
                  <img
                    src={isShowPasswordCurrect ? eye_on : eye_off}
                    onClick={() =>
                      setIsShowPasswordCurrect(!isShowPasswordCurrect)
                    }
                    className="eye-icon"
                  />
                </div>
                <p className="text-sm text-red-500 font-normal">
                  {passwordFormErrors.current}
                </p>
              </div>
              <div className="flex flex-col mb-3">
                <label>{t("modal.Enter new password")}</label>
                <div className="password-container">
                  <input
                    placeholder=""
                    className="border-2 border-primary rounded-lg outline-none p-0.5 pl-2 w-full"
                    value={passwordDetails.password}
                    onChange={(e) =>
                      setPasswordDetails({
                        ...passwordDetails,
                        password: e.target.value,
                      })
                    }
                    type={isShowPasswordNew ? "text" : "password"}
                  />
                  <img
                    src={isShowPasswordNew ? eye_on : eye_off}
                    onClick={() => setIsShowPasswordNew(!isShowPasswordNew)}
                    className="eye-icon"
                  />
                </div>
                <p className="text-sm text-red-500 font-normal">
                  {passwordFormErrors.password}
                </p>
              </div>
              <div className="flex flex-col mb-3">
                <label>{t("modal.Confirm new password")}</label>
                <div className="password-container">
                  <input
                    placeholder=""
                    className="border-2 border-primary rounded-lg outline-none p-0.5 pl-2 w-full"
                    value={passwordDetails.confirm}
                    onChange={(e) =>
                      setPasswordDetails({
                        ...passwordDetails,
                        confirm: e.target.value,
                      })
                    }
                    type={isShowPasswordConfirm ? "text" : "password"}
                  />
                  <img
                    src={isShowPasswordConfirm ? eye_on : eye_off}
                    onClick={() =>
                      setIsShowPasswordConfirm(!isShowPasswordConfirm)
                    }
                    className="eye-icon"
                  />
                </div>

                <p className="text-sm text-red-500 font-normal">
                  {passwordFormErrors.confirm}
                </p>
              </div>
              <button
                className="bg-primary font-semibold text-white w-full p-1 rounded-lg mt-3"
                type="submit"
              >
                {t("modal.Update Password")}
              </button>
            </form>
            {/* <button
              className="bg-primary mb-4 font-semibold text-white w-full p-1 rounded-lg mt-3"
              onClick={handleBulkDelete}
            >
              Swapnil Delete Bulk Documents
            </button> */}
          </div>
        </div>
        {user.user_type === "admin" && (
          <div className="w-2/6 set_account">
            <h1 className="text-base font-bold mb-2">
              {t("allModule.Company Authorization")}:
            </h1>
            {userDetail.document_status === 0 ? (
              loading ? (
                <LoadingScreen />
              ) : (
                <>
                  <div className="flex flex-col mb-3">
                    <label>{t("allModule.Authorization letter")}</label>
                    <select
                      value={authorization.authLetterType}
                      onChange={(e) => {
                        setAuthorization({
                          ...authorization,
                          authLetterType: e.target.value,
                        });
                      }}
                      className="border-2 border-primary rounded-lg outline-none p-0.5 pl-2"
                    >
                      <option value="">{t("common.Select")}</option>
                      <option value="Letter of Authorization">
                        {t("allModule.Letter of Authorization")}
                      </option>
                      <option value="Minutes">{t("allModule.Minutes")}</option>
                      <option value="Board Resolutions">
                        {t("allModule.Board Resolutions")}
                      </option>
                      <option value="Shareholder Resolutions">
                        {t("allModule.Shareholder Resolutions")}
                      </option>
                    </select>

                    <p className="text-sm text-red-500 font-normal">
                      {authorization.authLetterTypeError}
                    </p>
                  </div>
                  {authorization.authLetterType !== "" && (
                    <div className="flex flex-col mb-3">
                      {/* <label>{authorization.authLetterType}</label> */}
                      {authorization.authLetterFile && (
                        <div className="flex justify-between items-center my-3">
                          <p className={`text-sm font-semibold`}>
                            {t("allModule.Selected File")} :{" "}
                            {authorization.authLetterFile}
                          </p>
                          <img
                            src={CloseIcon}
                            width={16}
                            className="cursor-pointer"
                            onClick={() => {
                              setAuthorization({
                                ...authorization,
                                authLetterFile: "",
                                authLetterFileError: "",
                              });
                            }}
                          />
                        </div>
                      )}

                      <input
                        id="files"
                        style={{ display: "none" }}
                        type="file"
                        ref={authFileRef}
                        onChange={(e) => {
                          handleFileChange(e.target.files[0], "authorization");
                        }}
                        accept=".pdf, .jpg, .jpeg, .png"
                        multiple={false}
                      />

                      <button
                        onClick={() => authFileRef.current.click()}
                        className="bg-secondary font-bold text-white  p-1 px-2 rounded-lg contact-book-right-btn"
                      >
                        {t("allModule.Selected File")}
                      </button>

                      <p className="text-sm text-red-500 font-normal">
                        {authorization.authLetterFileError}
                      </p>
                    </div>
                  )}

                  <div className="flex flex-col mb-3">
                    <label>{t("allModule.Tax Document")}</label>
                    <select
                      value={authorization.taxDocumentType}
                      onChange={(e) => {
                        setAuthorization({
                          ...authorization,
                          taxDocumentType: e.target.value,
                        });
                      }}
                      className="border-2 border-primary rounded-lg outline-none p-0.5 pl-2"
                    >
                      <option value="">{t("common.Select")} </option>
                      {companyInfo?.country_id &&
                        companyInfo.country_id == "101" && (
                          <option value="GST Certificate">
                            {t("allModule.GST Certificate")}
                          </option>
                        )}

                      <option value="Tax Identification Certificate">
                        {t("allModule.Tax Identification Certificate")}
                      </option>
                      {companyInfo?.country_id &&
                        companyInfo.country_id != "101" && (
                          <option value="Not Applicable">
                            {t("allModule.Not Applicable")}
                          </option>
                        )}
                    </select>

                    <p className="text-sm text-red-500 font-normal">
                      {authorization.taxDocumentTypeError}
                    </p>
                  </div>
                  {authorization.taxDocumentType !== "" &&
                    authorization.taxDocumentType !== "Not Applicable" && (
                      <div className="flex flex-col mb-3">
                        {/* <label>{authorization.taxDocumentType}</label> */}
                        {authorization.taxDocumentFile && (
                          <div className="flex justify-between items-center my-3">
                            <p className={`text-sm font-semibold`}>
                              {t("allModule.Selected File")} :{" "}
                              {authorization.taxDocumentFile}
                            </p>
                            <img
                              src={CloseIcon}
                              width={16}
                              className="cursor-pointer"
                              onClick={() => {
                                setAuthorization({
                                  ...authorization,
                                  taxDocumentFile: "",
                                  taxDocumentFileError: "",
                                });
                              }}
                            />
                          </div>
                        )}
                        <input
                          id="files"
                          style={{ display: "none" }}
                          type="file"
                          ref={taxFileRef}
                          onChange={(e) => {
                            handleFileChange(e.target.files[0], "tax");
                          }}
                          accept=".pdf, .jpg, .jpeg, .png"
                          multiple={false}
                        />
                        <button
                          onClick={() => taxFileRef.current.click()}
                          className="bg-secondary font-bold text-white  p-1 px-2 rounded-lg contact-book-right-btn"
                        >
                          {t("allModule.Selected File")}
                        </button>

                        <p className="text-sm text-red-500 font-normal">
                          {authorization.taxDocumentFileError}
                        </p>
                      </div>
                    )}

                  <div className="flex flex-col mb-3">
                    <button
                      className="bg-primary font-semibold text-white w-full p-1 rounded-lg mt-3 "
                      onClick={(e) => {
                        handleUploadDocument();
                      }}
                    >
                      {t("allModule.Upload Document")}
                    </button>
                  </div>
                </>
              )
            ) : userDetail.document_status == 1 ? (
              <>
                <div className="flex flex-col mb-3">
                  <label style={{ color: "var(--orgg)" }}>
                    {t("allModule.Document is under review")}..
                  </label>
                </div>
              </>
            ) : userDetail.document_status == 2 ? (
              <>
                <div className="flex flex-col mb-3">
                  <label style={{ color: "green" }}>
                    {t("allModule.Document Approved")}..
                  </label>
                </div>
              </>
            ) : userDetail.document_status == 3 ? (
              <>
                {/* <div className='flex flex-col mb-3'>
                  <label style={{ color: 'rgb(239 68 68 / var(--tw-text-opacity))' }}>Document Rejected..</label>
                </div> */}

                {documentList?.map((document, index) => {
                  return (
                    <div className="auth_document_box" key={index}>
                      <div className="mb-3">
                        <span className="text-base font-bold mr-2">
                          {t("allModule.Document Type")} :
                        </span>
                        <span className="text-lg font-medium text-[#79838B]">
                          {document.type_of_document}
                        </span>
                      </div>
                      <div className="mb-3 flex">
                        <span className="text-base font-bold mr-2">
                          {t("form.Status")} :
                        </span>
                        <span className="text-lg font-medium text-[#79838B]">
                          {document["is_approved"] == 0 ? (
                            <span className="text-yellow-500">
                              {t("allModule.Need Approval")}
                            </span>
                          ) : document["is_approved"] == 1 ? (
                            <span className="text-green-500">
                              {t("allModule.Approved")}
                            </span>
                          ) : document["is_approved"] == 2 ? (
                            <span className="text-red-500">
                              {t("allModule.Rejected")}
                            </span>
                          ) : (
                            <></>
                          )}
                        </span>
                      </div>
                      {document["is_approved"] != 2 && (
                        <div className="mb-3 flex">
                          <button
                            onClick={(e) => handleViewFile(document)}
                            className="bg-secondary font-bold text-white  p-1 px-2 rounded-lg contact-book-right-btn mr-2"
                          >
                            {t("allModule.View Document")}
                          </button>
                        </div>
                      )}

                      {document["is_approved"] == 2 && (
                        <>
                          <div className="mb-3 flex">
                            <span className="text-base font-bold mr-2">
                              Reason :
                            </span>
                            <span className="text-lg font-medium text-[#79838B]">
                              {document["rejection_reason"]}
                            </span>
                          </div>
                          <div className="mb-3 flex">
                            <button
                              onClick={(e) => handleViewFile(document)}
                              className="bg-secondary font-bold text-white  p-1 px-2 rounded-lg contact-book-right-btn mr-2"
                            >
                              {t("allModule.View Document")}
                            </button>
                          </div>

                          <div className="flex flex-col mb-3">
                            {document.FileName && (
                              <div className="flex justify-between items-center my-3">
                                <p className={`text-sm font-semibold`}>
                                  {t("allModule.Selected File")} :{" "}
                                  {document.FileName}
                                </p>
                                <img
                                  src={CloseIcon}
                                  width={16}
                                  className="cursor-pointer"
                                  onClick={() => {
                                    const newState = documentList.map((obj) => {
                                      if (obj.id === document.id) {
                                        return {
                                          ...obj,
                                          fileToUpload: "",
                                          FileName: "",
                                          FileNameError: "",
                                        };
                                      }
                                      return obj;
                                    });
                                    setDocumentList(newState);
                                  }}
                                />
                              </div>
                            )}
                            <input
                              id="files"
                              style={{ display: "none" }}
                              type="file"
                              ref={(el) => (myRefs.current[index] = el)}
                              onChange={(e) => {
                                handleFileChangeAfterReject(
                                  e.target.files[0],
                                  document
                                );
                              }}
                              accept=".pdf, .jpg, .jpeg, .png"
                              multiple={false}
                            />
                            <button
                              onClick={() => myRefs.current[index].click()}
                              className="bg-secondary font-bold text-white  p-1 px-2 rounded-lg contact-book-right-btn"
                            >
                              {t("allModule.Selected File")}
                            </button>

                            <p className="text-sm text-red-500 font-normal">
                              {document.FileNameError}
                            </p>
                          </div>

                          <div className="flex flex-col mb-3">
                            <button
                              className="bg-primary font-semibold text-white w-full p-1 rounded-lg mt-3 "
                              onClick={(e) => {
                                handleUploadSingleDocument(document);
                              }}
                            >
                              {t("allModule.Upload Document")}
                            </button>
                          </div>
                        </>
                      )}
                    </div>
                  );
                })}
              </>
            ) : (
              <></>
            )}
          </div>
        )}

        <div className="w-2/6 set_account">
          {docType == "pdf" ? (
            <div className="box_u1 p-4">
              <div className="box_u2 account_pdf_view">
                <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.13.216/build/pdf.worker.min.js">
                  {loading ? (
                    <LoadingScreen />
                  ) : (
                    <>
                      {docUrl && (
                        <Viewer
                          fileUrl={`${docUrl}?${highlightToken.sas_token}`}
                          plugins={[defaultLayoutPluginInstance]}
                          onDocumentAskPassword={(e) => {}}
                          renderError={() => (
                            <div className="grid place-items-center h-full w-full">
                              <h1 className="font-semibold">
                                {t(
                                  "common.Contract indexing in progress. Check back later"
                                )}
                              </h1>
                            </div>
                          )}
                          renderLoader={() => <LoadingScreen />}
                        />
                      )}
                    </>
                  )}
                </Worker>
              </div>
            </div>
          ) : docType == "image" ? (
            <div className="box_u1 p-4">
              <div className="box_u2 account_pdf_view">
                <img src={`${docUrl}?${highlightToken.sas_token}`} alt="img" />
              </div>
            </div>
          ) : (
            <></>
          )}
        </div>
      </div>

      <AppModal
        title={modalData.title}
        bodyText={modalData.bodyText}
        setIsOpen={setIsOpen}
        modalIsOpen={modalIsOpen}
      />

      <AddCompanyModel
        companyModelOpen={companyModelOpen}
        setCompanyModelOpen={setCompanyModelOpen}
        companyInfo={companyInfo}
        setCompanyInfo={setCompanyInfo}
        userDetail={userDetail}
        getUserDetails={getUserDetails}
      />

      <PurchaseLicence
        setIsOpen={setLicenceModelOpen}
        modalIsOpen={licenceModelOpen}
      />
    </div>
  );
};

export default Account;
