import React, { useContext, useState } from 'react';
import Modal from 'react-modal';
import './modal.css';

import { toast } from 'react-toastify';
import useAxios from '../../hooks/useAxios';
import { resolve } from '../../api/resolve';
import { useTranslation } from 'react-i18next';

const customStyles = {
    content: {
        top: '30%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        maxWidth: '400px',
        maxHeight: 'auto',
        borderRadius: '11px',
        minWidth: '300px',
    },
    overlay: {
        backgroundColor: 'rgba(64, 8, 53, 0.63)',
    },
};

Modal.setAppElement('#root');
const WorkflowNameModel = ({
    isOpen,
    setIsOpen,
    selectedTemplate,
    setIframeUrl,
    setTempSaveConfirmation,
    setDocumentId,
}) => {
    const api = useAxios();
    const { t } = useTranslation();
    const [templateName, setTemplateName] = useState('');
    const [templateNameError, setTemplateNameError] = useState('');
   
    const validateFormAndSubmit = async () => {
        setTemplateNameError('');
        if (templateName === "") {
            setTemplateNameError(t('form.Please enter document name'));
        } else {
            const postData = {
                id: selectedTemplate['id'],
                name: templateName
            }
            const responceData = await resolve(
                api.post(`contract/legal/workflow/`, postData).then((res) => res.data)
            );
            
            if (responceData.data) {                
                setIsOpen(false);
                setTemplateName('');
                setTemplateNameError('');
                setIframeUrl(responceData['data'].data);
                // setDocumentId(responceData['data'].id);
                setDocumentId(55);
                setTempSaveConfirmation(true);
            }

            if (responceData.error) {
                setIframeUrl('');
                toast.error(t('common.Something went wrong'));
            }
        }
    };

    return (
        <div className=''>
            <Modal
                isOpen={isOpen}
                onRequestClose={() => setIsOpen(true)}
                style={customStyles}
                onAfterClose={() => {
                    setTemplateName('');
                    setTemplateNameError('');
                }}
            >

                <div className=' modal-container'>
                    <h1 className='text-center text-primary font-bold text-lg'>{t('form.Document Detail')}</h1>

                    <div className='mt-5'>
                        <div className='flex'>
                            <label className='flex flex-start mb-1'>{t('form.Document Name')}</label>
                        </div>
                        <input
                            type='text'
                            value={templateName}
                            className='border-2 border-primary rounded-lg outline-none p-1 pl-2 w-full '
                            onChange={(e) => setTemplateName(e.target.value)}
                        />
                        <p className='flex flex-start error_msg'>{templateNameError}</p>
                    </div>



                    <div className='flex justify-center mt-5'>
                        <button
                            onClick={(e) => {
                                e.preventDefault();
                                validateFormAndSubmit();
                            }}
                            className='bg-primary hover:bg-secondary font-semibold text-white p-1 pl-2 pr-2 rounded-lg mr-5'
                        >
                            {t('form.Create Document')}
                        </button>

                        <button
                            onClick={(e) => {
                                e.preventDefault();
                                setIsOpen(false);
                            }}
                            className='bg-[#606060] font-semibold text-white p-1 pl-2 pr-2 rounded-lg'
                        >
                            {t('common.Cancel')}
                        </button>

                    </div>



                </div>
            </Modal>
        </div>
    );
};

export default WorkflowNameModel;
