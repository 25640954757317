import React, { useContext, useEffect } from 'react';
import {
  useParams,
  useNavigate,
  useLocation,
  useResolvedPath,
} from 'react-router-dom';
import AppContext from '../../context/AppContext';
const RoutedSearchlegacy = ({ props }) => {
  const params = useParams();
  const href = useLocation();
  const navigate = useNavigate();
  const { setActiveLink, setRoutedSearchID } = useContext(AppContext);
  useEffect(() => {
    if (
      href?.pathname?.split('/')[href.pathname.split('/').length - 1] === 'help'
    ) {
      setActiveLink('Help');
    } else {
      if (params.id) {
        setRoutedSearchID(params.id);
        console.log("params.id",params.id)
      }
      setActiveLink('Searchlegacy');
    }
    navigate('/');
  });
  return <></>;
};

export default RoutedSearchlegacy;
