import React, { useState, useEffect } from "react";
import Creatable from "react-select/creatable";
import ReactTooltip from 'react-tooltip';
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import "./shareButton.css"; // Update the path accordingly
import axios from "axios";
import useAxios from "../../hooks/useAxios";
import share from "../../assets/icons/share.svg";
import group from "../../assets/icons/group.png";
import returnbutton from "../../assets/icons/returnbutton.svg";
import addbuttons from "../../assets/icons/addbuttons.svg";
import HelpIcon from "../../assets/icons/Help-sm.svg";
import CustomPhoneInput from "../forms/PhoneInput";
import { customStylesshare } from "../../constants/styles";
import { resolve } from "../../api/resolve";



const AddContactPopup = ({ docId, onClick, onShare, onDelete }) => {
  const [showSharePopup, setShowSharePopup] = useState(false);
  const [showError, setShowError] = useState(true);
  const [companyshowError, setcompanyShowError] = useState(true);
  const [contactshowError, setcontactshowError] = useState(true);
  const [defaultValues, setDefaultValues] = useState([]);
  const [isShowDesignation, setIsShowDesignation] = useState(false);
  const [deligateeListData, setDeligateeListData] = useState([]);
  const [RecipientDetail, setRecipientDetail] = useState([]);
  const [sharemethod, setsharemethod] = useState([]);
  
  const { t } = useTranslation();
  const [shareData, setShareData] = useState({

    description: "",
    email: "",
    name: "",
    phone: "",
  });
  const [Addcontactdata, setAddcontactdata] = useState({
    designation: "",
    email: "",
    name: "",
    phone: "",
    group_id: "",
    company_id: "",
    title_id: "",
    delegatee: false
  });
  const [Addcompanydata, setAddcompanydata] = useState({
    city: "",
    company_id: 0,
    country_id: "",
    designation: "",
    entity: "",
    entity_title: "",
    name: "",
    state: ""
  });
  const [receipientListData, setReceipientListData] = useState([]);
  const [isMultiUserSelect, setIsMultiUserSelect] = useState(true);
  const [shareSuccess, setShareSuccess] = useState(false); // State to track share success
  const [contactSearchBy, setContactSearchBy] = useState("contact_search");
  const baseUrl = process.env.REACT_APP_BACKEND_BASE_URL;

  // const { t } = useTranslation();

  const handleShareClick = () => {
    setShowSharePopup(true);
    document.body.style.overflow = "hidden"; // Prevent scrolling on the body when popup is open
  };
  const getGroupMemberList = async (groupInfo) => {
    // setSelectedRecipients({groupInfo})
    const responceData = await resolve(
      api
        .get(`/user/contact/group/member_list/${groupInfo.id}`)
        .then((res) => res.data)
    );
    if (responceData["data"]) {
      const listData = responceData.data;
      const filteredArr = [];
      if (listData.length > 0) {
        listData.forEach((contactObj) => {
          let contactInfo = contactObj.group_member_id;
          filteredArr.push({
            value: contactInfo.id + "",
            name: contactInfo.name,
            id: contactInfo.id,
            email: contactInfo.email,
            phone: contactInfo.phone,
            signingCapacity: "",
            typeOfSignature: "",
            errorMsg: "",
          });
        });
        setRecipientDetail(filteredArr);
      }
    }
  };


  const handleClosePopup = () => {
    setShowSharePopup(false);
    setShareSuccess(false);
    setShowError(true);
    setcompanyShowError(true);
    setcontactshowError(true);
    setAddcontactdata({
      designation: "",
      email: "",
      name: "",
      phone: "",
      group_id: "",
      company_id: "",
      title_id: "",
      delegatee: false
    });

    setShareData({
      description: "",
      email: "",
      name: "",
    });

    document.body.style.overflow = ""; // Enable scrolling on the body when popup is closed
  };
  const addcontactdetailstoshare = () => {
  };
  const api = useAxios();

  const handleShareSubmit = async () => {
    try {
      // Make API call to submit shareData
      
      shareData.docId = docId;
      // let name = shareData.name;
      let description = shareData.description;
      // let email = shareData.email;
      console.log("sharemethod",sharemethod)
      if (sharemethod=="contact_search"){}
      // contact_search
      // group_search

      // if (sharemethod=="contact_search" ||sharemethod=="group_search"){
        const emaillist = RecipientDetail.map(item => item.email);
        const namelist = RecipientDetail.map(item => item.name);
        const phonelist = RecipientDetail.map(item => item.phone);
        console.log("RecipientDetail",RecipientDetail);
        shareData.name = namelist;
        shareData.email = emaillist;
        shareData.phone = phonelist;
        // RecipientDetail.forEach((contactInfo) => {
        //   setemaillist(contactInfo.email);
        //   setnamelist(contactInfo.name);
        //   setphonelist(contactInfo.name);
        //   // setItems([...emaillist, contactInfo.email]);
        //   console.log("emailinfo",contactInfo.email)

        // });
        
        setShowError(namelist && emaillist && description)
        const res = (namelist && emaillist && description) && await api.post(`${baseUrl}/contract/ShareDocumentExt/`, shareData);
      

      if (res.data.message === "Document shared successfully.") {
        // If API call is successful, hide the popup and change button color
        toast.success(res.data.message);
        setShowSharePopup(false);
        setShareSuccess(true);
        setShareData({
          description: "",
          email: "",
          name: "",
        });
        // You can optionally perform additional actions here
      } else {
        toast.error("Failed to submit share data");
        // Handle error cases
        throw new Error("Failed to submit share data");

      }
    } catch (error) {
      // Handle errors
      console.error("Error submitting share data:", error.response.data);
      // Optionally display an error message to the user
    }
  };


  const contactfetchlisttodisplay = async () => {
    try {
      // Make API call to submit shareData

      const res = await api.get(`${baseUrl}/user/contact/list/`);



      // if (res.data.message==="Document shared successfully.") {
      //   // If API call is successful, hide the popup and change button color
      //   toast.success(res.data.message);
      //   setShowSharePopup(false);
      //   setShareSuccess(true);
      //   setShareData({
      //     description: "",
      //     email: "",
      //     name: "",
      //   });
      //   // You can optionally perform additional actions here
      // } else {
      //   toast.error("Failed to submit share data");
      //   // Handle error cases
      //   throw new Error("Failed to submit share data");

      // }
    } catch (error) {
      // Handle errors
      console.error("Error submitting share data:", error.response.data);
      // Optionally display an error message to the user
    }
  };
  const [contactList, setContactList] = useState([]);
  const [selectedRecipients, setSelectedRecipients] = useState([]);
  const [selectedOption, setSelectedOption] = useState('');
  const [selectedcompany, setselectedcompany] = useState('');
  const [companylist, setcompanylist] = useState([]);
  const [grouplist, setgrouplist] = useState([]);
  const [titlelist, settitlelist] = useState([]);
  const [selectedgroup, setselectedgroup] = useState('');
  const [selectedtitle, setselectedtitle] = useState('');
  const [countrylist, setcountrylist] = useState([]);
  const [StateListData, setStateListData] = useState([]);
  const [CityListData, setCityListData] = useState([]);
  const [EntityListData, setEntityListData] = useState([]);
  const [selectedcountry, setselectedcountry] = useState('');
  const [selectedstate, setselectedstate] = useState('');
  const [selectedcity, setselectedcity] = useState('');
  const [selectedentity, setselectedentity] = useState('');


  const fetchData = async () => {
    try {
      // Fetch data from API
      const response = await api.get(`${baseUrl}/user/contact/list/`);
      // Extract data from response
      const responseData = response.data;
      // Update state with fetched data
      setContactList(responseData.results);

      
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };
  useEffect(() => {
    getReceipientListApi();
    // if (activeDocInfo?.id) {
    //         getDocumentInfoApi(activeDocInfo.id);
    // }
  }, [contactSearchBy]);

  useEffect(() => {
    // Call fetchData function when component mounts
    fetchData();
    handleButtonClick();

    // Cleanup function (optional) if needed
    return () => {
      // Perform any cleanup here if necessary
    };
  }, []);
  const handleSelectChange = (event) => {
    setSelectedOption(event.target.value);
    // Do something with the selected option, if needed
  };
  const handleSelectCompany = (event) => {
    setselectedcompany(event.target.value);
    // Do something with the selected option, if needed
  };


  const handleChange = (e) => {

    const selectedName = e.target.value;
    const selectedObject = contactList.find(item => item.name === selectedName);
    setSelectedOption(e.target.value);


    const { name, value } = e.target;
    setShareData((prevState) => ({
      ...prevState,
      [name]: value,
    }));

  };

  const handleChangeforcontact = (e) => {
    // setSelectedOption(e.target.value);
    // const selectedName = e.target.value;
    // const selectedObject = contactList.find(item => item.name === selectedName);
    

    const { name, value } = e.target;
    setAddcontactdata((prevState) => ({
      ...prevState,
      [name]: value,
    }));

  };

  const handleChangeforaddcompany = (e) => {
    const selectedName = e.target.value;

    const { name, value } = e.target;
    if (name == "city") {
      setselectedcity(selectedName)
    }
    if (name == "entity") {
      setselectedentity(selectedName)
    }
    setAddcompanydata((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  const handleChangeforaddcountry = (e) => {
    const selectedName = e.target.value;

    const { name, value } = e.target;

    // const selectedObject = countrylist.find(item => item.id === selectedName);

    const handlecountryapicall = async () => {
      try {
        const res = await api.get(`${baseUrl}/company/state/list/${value}`);
        let countryId = 231;
        if(value==101 || value==230 || value==0){
          countryId = value
      }else{
          countryId = 231;
      }
        const resent = await api.get(`${baseUrl}/user/entities/list/${countryId}`);
        // user/entities/list/${name}
        setStateListData(res.data);
        // setCityListData(resent.data);
        setEntityListData(resent.data);
       
      } catch (error) {
        // Handle errors
        console.error("Error submitting share data:", error.response.data);
        // Optionally display an error message to the user
      }
    };
    handlecountryapicall();

    const dummylist = [selectedName];
    // Update selected option in state
    setselectedcountry(selectedName);

    setAddcompanydata((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  const handleChangeforaddcompanystate = (e) => {
    const selectedName = e.target.value;

    const { name, value } = e.target;

    // const selectedObject = countrylist.find(item => item.id === selectedName);
   
    const handlecountryapicall = async () => {
      try {
        const res = await api.get(`${baseUrl}/company/cities/list/${value}`);

        setCityListData(res.data);
      } catch (error) {
        // Handle errors
        console.error("Error submitting share data:", error.response.data);
        // Optionally display an error message to the user
      }
    };
    handlecountryapicall();
    setselectedstate(selectedName)
    setAddcompanydata((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };


  const handleChangeforgroup = (e) => {
    const selectedName = e.target.value;

    const selectedObject = grouplist.find(item => item.id === selectedName);


    const dummylist = [selectedName];
    // Update selected option in state
    setselectedgroup(selectedName);
    const { name, value } = e.target;
    setAddcontactdata((prevState) => ({
      ...prevState,
      [name]: dummylist,
    }));
  };

  const handleChangefortitle = (e) => {
    const selectedName = e.target.value;

    // const selectedObject = titlelist.find(item => item.id === selectedName);


    // Update selected option in state
    if (selectedName === "other") {
      setIsShowDesignation(true)
    }
    else{
      setIsShowDesignation(false)
    }
    setselectedtitle(selectedName);
    const { name, value } = e.target;
    setAddcontactdata((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleChangeforcompany = (e) => {
    const selectedName = e.target.value;

    const selectedObject = companylist.find(item => item.id === selectedName);
    const handletitleapicall = async () => {
      try {
        const res = await api.get(`${baseUrl}/company/title/list/${selectedName}`);
        settitlelist(res.data)
       
      } catch (error) {
        // Handle errors
        console.error("Error submitting share data:", error.response.data);
        // Optionally display an error message to the user
      }
    };
    handletitleapicall();
    // Update selected option in state
    setselectedcompany(selectedName);

    // Update shareData with selected name and its corresponding value
    // setAddcontactdata(prevState => ({
    //   ...prevState,
    //   [name]: selectedName,
    //   // Assuming you want to set both the name and email from selectedObject
    //   // company_id: selectedObject ? selectedObject.id : '',
    //   // email: selectedObject ? selectedObject.email : ''
    // }));
    const { name, value } = e.target;
    setAddcontactdata((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleChangeagain = (e) => {
    const selectedName = e.target.value;
    const selectedObject = contactList.find(item => item.id == selectedName);


    // Update selected option in state
    setSelectedOption(selectedName);

    // Update shareData with selected name and its corresponding value
    setShareData(prevState => ({
      ...prevState,
      // Assuming you want to set both the name and email from selectedObject
      name: selectedObject ? selectedObject.name : '',
      email: selectedObject ? selectedObject.email : '',
      phone: selectedObject ? selectedObject.phone : '',
    }));
  };
  const [showAdditionalFields, setShowAdditionalFields] = useState(true);

  const [showAdditionalFieldscompany, setShowAdditionalFieldscompany] = useState(false);
  const getReceipientListApi = async () => {
    setReceipientListData([]);
    

    if (contactSearchBy == "contact_search") {
      const responceData = await resolve(
        api.get(`/user/contact/list/`).then((res) => res.data)
      );
      if (responceData["data"]) {
        const listData = responceData.data.results;
        const filteredArr = [];
        const filteredArrDLT = [];
        if (listData.length > 0) {
          listData.forEach((contactInfo) => {
            filteredArr.push({
              value: contactInfo.id + "",
              label: contactInfo.name,
              id: contactInfo.id,
              email: contactInfo.email,
              phone: contactInfo.phone,
              name: contactInfo.name,
              signingCapacity: "",
              typeOfSignature: "",
            });
            if (contactInfo.delegatee) {
              filteredArrDLT.push({
                id: contactInfo.id,
                name: contactInfo.name,
              });
            }
          });
          setReceipientListData(filteredArr);
        }
        setDeligateeListData(filteredArrDLT);
      }
    } else {
      const responceData = await resolve(
        api.get(`/user/contact/group/list/`).then((res) => res.data)
      );
      if (responceData["data"]) {
        const listData = responceData.data.results;
        const filteredArr = [];
        if (listData.length > 0) {
          listData.forEach((groupInfo) => {
            filteredArr.push({
              value: groupInfo.id + "",
              label: groupInfo.group_name,
              id: groupInfo.id,
            });
          });
          setReceipientListData(filteredArr);
        }
      }
    }
  };
  const handleButtonreturn = () => {
    setShowAdditionalFields(false);
  };
  const handleButtonreturnagain = () => {
    setShowAdditionalFields(true);
    setShowAdditionalFieldscompany(false)
  };
  const handleButtonaddcompany = () => {
    setShowAdditionalFieldscompany(true);
    const handlecountryapicall = async () => {
      try {
        const res = await api.get(`${baseUrl}/company/countries/list/`);

        setcountrylist(res.data)
        setStateListData([]);
        setCityListData([]);
        setEntityListData([]);
      } catch (error) {
        // Handle errors
        console.error("Error submitting share data:", error.response.data);
        // Optionally display an error message to the user
      }
    };
    handlecountryapicall();

  };
  const handleButtonClick  = async () => {
    setShowAdditionalFields(true);
    const responsecompany = await api.get(`${baseUrl}/company/list/`);
      const responseDatacompany = responsecompany.data;
      setcompanylist(responseDatacompany)
      const responsegroup = await api.get(`${baseUrl}/user/contact/group/list/`);
      if (responsegroup["data"]) {
        const groupList = responsegroup["data"].results;
        const filteredArr = [];
        if (groupList.length > 0) {
          groupList.forEach((groupInfo) => {
            filteredArr.push({
              value: groupInfo.id + "",
              label: groupInfo.group_name,
              id: groupInfo.id,
            });
          });
          setgrouplist(filteredArr);
        }
      }
      // setgrouplist(responsegroup.data.results)
      
  };
  const handleAddContactSubmit = async () => {
    try {
      let designation = Addcontactdata.designation
      let email = Addcontactdata.email
      let name= Addcontactdata.name
      let phone= Addcontactdata.phone
      let group_id= Addcontactdata.group_id
      let company_id= Addcontactdata.company_id
      let title_id= Addcontactdata.title_id
      let delegatee= Addcontactdata.designation
      setcontactshowError(name&&phone&&email&&designation&&group_id)
      const res = await api.post(`${baseUrl}/user/contact/save/`, Addcontactdata);

      if (res.data.message === "Contact Saved Successfully") {
        // If API call is successful, hide the popup and change button color
        toast.success(res.data.message);
        setShowSharePopup(false);
        setselectedtitle("");
        setselectedcompany("");
        setAddcontactdata({
          designation: "",
          email: "",
          name: "",
          phone: "",
          group_id: "",
          company_id: "",
          title_id: "",
          delegatee: false
        });
        // You can optionally perform additional actions here
      } else {
        toast.error("Failed to Add contact");
        // Handle error cases
        throw new Error("Failed to Add Contact");

      }
    } catch (error) {
      // Handle errors
      if (error.response.data.messages) {
        toast.error(error.response.data.messages[0].message);
      } else {
        toast.error(error.response.data.message);
      }
      console.error("Error submitting share data:", error.response.data);
      // Optionally display an error message to the user
    }
    fetchData();
  };
  const handleAddCompanySubmit = async () => {
    try {
      let city= Addcompanydata.city;
      
      let country_id=Addcompanydata.country_id;
      
      let name=Addcompanydata.name;
      
      let state=Addcompanydata.state;
      
      let Entity = Addcompanydata.entity
      
      setcompanyShowError(city && country_id && name && state && Entity)
      
      const res = (city && country_id && name && state && Entity) && await api.post(`${baseUrl}/company/create/`, Addcompanydata);

      if (res.data.message === "company created Successfully") {
        // If API call is successful, hide the popup and change button color
        toast.success(res.data.message);
        setShowAdditionalFieldscompany(false)
        setShareSuccess(true);
        setselectedcountry("");
        setselectedstate("");
        setselectedcity("");
        setselectedentity("");
        setAddcompanydata({
          city: "",
          company_id: 0,
          country_id: "",
          designation: "",
          entity: "",
          entity_title: "",
          name: "",
          state: ""
        });
        // You can optionally perform additional actions here
      } else {
        toast.error("Failed to Add contact");
        // Handle error cases
        throw new Error("Failed to Add Contact");

      }
    } catch (error) {
      // Handle errors
      if (error.response.data.messages) {
        toast.error(error.response.data.messages[0].message);
      } else {
        toast.error(error.response.data.message);
      }
      console.error("Error submitting share data:", error.response.data);
      // Optionally display an error message to the user
    }
    handleButtonClick();
  };

  return (
    <>
      <button
        className="btn btn_theme btn-sm mr-1 wh_spab mb-2"
        
        data-tip={t("allModule.Add Contact")}
        onClick={handleShareClick}
      >
        {/* <img
          src={share}
          className="ml-0.5"
          width={15}
          alt="Share with External Person"
        /> */}
        Add Contact
      </button>

      {showSharePopup && (
        <div className="modal-overlay">
        <div className="popup-container-share">
          <div className="popup-header">
            
            {showAdditionalFieldscompany &&
              <button onClick={handleButtonreturnagain}>
                <img
                  src={returnbutton}
                  className="ml-0.5"
                  width={15}
                // alt="Share with External Person"
                /></button>
            }
            {/* {!showAdditionalFields && !showAdditionalFieldscompany &&
            <h1 className="text-center text-primary font-bold text-lg mr-15 " style={{ width: "100%" }} >{t('form.Share Externally')}</h1>
            } */}
            {showAdditionalFields && !showAdditionalFieldscompany &&
            <h1 className="text-center text-primary font-bold text-lg mr-15 " style={{ width: "100%" }} >{t('allModule.Add Contact')}</h1>
            }
            {showAdditionalFieldscompany && 
            <h1 className="text-center text-primary font-bold text-lg mr-15 " style={{ width: "100%" }} >{t('form.Add Company')}</h1>
            }

            {!showAdditionalFields && !showAdditionalFieldscompany &&
              <button
                className={`bg-[#FFA500] font-semibold text-white mr-1 p-1 pl-2 pr-2 rounded-lg`}
                onClick={handleButtonClick}
                data-tip={t('allModule.Add Contact')}
              >
                {/* <img
                  src={group}
                  className="ml-0.5"
                  width={20}
                  // alt="Share with External Person"
                /> */}
                {/* Share */}
                +
                <ReactTooltip backgroundColor='#400835' textColor='#ffffff' />
              </button>
            }
             
            {showAdditionalFields && !showAdditionalFieldscompany &&
              <button className="bg-[#606060] font-semibold text-white p-1 pl-2 pr-2 rounded-lg" onClick={handleClosePopup}>
                X
              </button>
            }
          </div>
          {!showAdditionalFields && !showAdditionalFieldscompany &&
            <div className="flex">
              <label htmlFor="name">
                <strong>{t('menu.Select Recipients')}:</strong>
              </label>
              
            </div>
          }
          {!showAdditionalFields && !showAdditionalFieldscompany &&
          <div className="flex flex-col mb-3 contract-type">
                    <div className="flex">
                      <div className="searh">
                        <Creatable
                          options={receipientListData}
                          placeholder={
                            contactSearchBy === "contact_search"
                              ? t("allModule.Search by name or email")
                              : t("allModule.Search by group name")
                          }
                          styles={customStylesshare}
                          isMulti={isMultiUserSelect}
                          isSearchable={true}
                          onChange={(arr) => {
                            if (contactSearchBy === "group_search") {
                              setSelectedRecipients([arr]);
                              getGroupMemberList(arr);
                            } else {
                              setSelectedRecipients([...arr]);
                              let tempArr = [];
                              arr.forEach((obj) =>
                                tempArr.push({
                                  id: obj.id,
                                  email: obj.email,
                                  phone: obj.phone,
                                  name: obj.name,
                                  signingCapacity: obj.signingCapacity,
                                  typeOfSignature: obj.typeOfSignature,
                                  errorMsg: "",
                                })
                              );
                              
                              setRecipientDetail(tempArr);
                              
                            }
                          }}
                          isClearable={false}
                          className="w-full text-primary rounded-lg"
                          value={selectedRecipients}
                        />
                      </div>
                      <div className="flex pl-1">
                        <select
                          value={contactSearchBy}
                          onChange={(e) => {
                            setSelectedRecipients([]);
                            setRecipientDetail([]);
                            if (e.target.value === "group_search") {
                              setIsMultiUserSelect(false);
                            } else {
                              setIsMultiUserSelect(true);
                            }
                            setContactSearchBy(e.target.value);
                            setsharemethod(e.target.value)
                            
                          }}
                          className={customStylesshare}
                        >
                          <option value="contact_search">
                            {t("allModule.Search by contact")}
                          </option>
                          <option value="group_search">
                            {t("allModule.Search by group")}
                          </option>
                        </select>
                      </div>
                    </div>
                    <p className="error_msg"></p>
                  </div>
}
          {!showAdditionalFields && !showAdditionalFieldscompany &&
            <div className="flex flex-col mb-3 contract-type">
              <div className="flex">
              <label htmlFor="description">
                <strong>{t('form.Description of Document')}:</strong><br></br>
                {/* <strong>in Detail:</strong> */}
              </label>
              </div>
              <textarea
                id="description"
                name="description"
                placeholder={t('form.Enter description')}
                value={shareData.description}
                onChange={handleChange}
                className="input-field-large input-field-multiline"
              />
            </div>
          }
          {showAdditionalFields && !showAdditionalFieldscompany && (
            <div>
              {/* Additional fields go here */}
              {/* For example: */}
              <div className="flex flex-col mb-3 contract-type">
              <div className="flex">
                <label htmlFor="name">{t('form.Company')}:</label>
                    {/* <>
                      <img
                        src={HelpIcon}
                        width={12}
                        className="ml-1 -mt-2 cursor-pointer"
                        data-tip={t('form.Select Company')}
                      />
                      <ReactTooltip backgroundColor="#400835" textColor="#ffffff" />
                    </> */}
                    </div>
                <div className="flex">
                <select
                  id="company_id"
                  name="company_id"
                  style={{ width: '400px'}}
                  value={selectedcompany} onChange={handleChangeforcompany}>
                  <option value="">{t('form.Select Company')}</option>
                  {companylist.map(item => (
                    <option key={item.id} value={item.id}>{item.name}</option>
                  ))}
                </select>
                <button className="btn " onClick={handleButtonaddcompany}>                  
                  <img
                    src={addbuttons}
                    className="ml-0.5"
                    width={15}
                    data-tip={t('form.Add Company')}
                  // alt="Share with External Person"
                  />
                  <ReactTooltip backgroundColor='#400835' textColor='#ffffff' />
                </button>
                </div>
              </div>
              <div className="flex flex-col mb-3 contract-type">
                <div className="flex">
                  <label className="mb-2">{t('form.Name')}:</label>
                  {/* <>
                    <img
                      src={HelpIcon}
                      width={12}
                      className="ml-1 -mt-2 cursor-pointer"
                      data-tip={t('form.Insert full name')}
                    />
                    <ReactTooltip backgroundColor="#400835" textColor="#ffffff" />
                  </> */}
                </div>
                <input
                  id="name"
                  name="name"
                  placeholder={t('form.Insert full name')}
                  value={Addcontactdata.name}
                  onChange={handleChangeforcontact}
                  className="input-field-small"
                />
              </div>
              <div className="flex flex-col mb-3">
                <div className="flex">
                  <label className="mb-2">{t('form.Email')}:</label>
                  {/* <>
                    <img
                      src={HelpIcon}
                      width={12}
                      className="ml-1 -mt-2 cursor-pointer"
                      data-tip={t('form.Insert an unique email address')}
                    />
                    <ReactTooltip backgroundColor="#400835" textColor="#ffffff" />
                  </> */}
                </div>
                <input
                  type="email"
                  id="email"
                  name="email"
                  placeholder={t('loginPage.Enter email address')}
                  value={Addcontactdata.email}
                  onChange={handleChangeforcontact}
                  className="input-field-small"
                />
              </div>
              
              <div className="flex flex-col mb-3 contract-type">
                  <div className="flex">
                    <label className="mb-2">{t('form.Phone')}:</label>
                    {/* <>
                      <img
                        src={HelpIcon}
                        width={12}
                        className="ml-1 -mt-2 cursor-pointer"
                        data-tip={t('common.Enter Phone Number')}
                      />
                      <ReactTooltip backgroundColor="#400835" textColor="#ffffff" />
                    </> */}
                  </div>

            <CustomPhoneInput
              value={Addcontactdata.phone}
              onChange={(phone) => {
                phone= "+" + phone
                setAddcontactdata({ ...Addcontactdata, phone })
                  
              }}
            />
            <p className="text-sm text-red-500 font-normal">
              
            </p>
              </div>
          <div className="flex flex-col mb-3 contract-type">
                <div className="flex">
                  <label className="mb-2">{t('allModule.Group')}:</label>
                  {/* <>
                    <img
                      src={HelpIcon}
                      width={12}
                      className="ml-1 -mt-2 cursor-pointer"
                      data-tip={t('form.Select Group')}
                    />
                    <ReactTooltip backgroundColor="#400835" textColor="#ffffff" />
                  </> */}
                </div>
                
                <Creatable
                  options={grouplist}
                  placeholder={t('form.Select Group')}
                  styles={customStylesshare}
                  isMulti={true}
                  isSearchable={true}
                  
                  onChange={(arr) => {
                    let formatedArr = [];
                    arr.forEach((obj) =>
                      formatedArr.push(parseInt(obj.value.trim()))
                      
                    );
                    
                    //
                    setAddcontactdata({
                          ...Addcontactdata,
                          group_id: formatedArr,
                        })
                    setDefaultValues([...arr]);
                  }}
                  isClearable={false}
              className="w-full text-primary rounded-lg"
              defaultValue={defaultValues}
                />
                
              </div>
              

                <div className="flex flex-col mb-3 contract-type">
                  <div className="flex">
                    <label className="mb-2">{t('form.Title')}:</label>
                    {/* <>
                      <img
                        src={HelpIcon}
                        width={12}
                        className="ml-1 -mt-2 cursor-pointer"
                        data-tip={t('form.Select Title')}
                      />
                      <ReactTooltip backgroundColor="#400835" textColor="#ffffff" />
                    </> */}
                  </div>
                <select
                  id="title_id"
                  name="title_id"
                  value={selectedtitle} onChange={handleChangefortitle}>
                  <option value="">{t('form.Select Title')}</option>
                  {titlelist.map(item => (
                    <option key={item.id} value={item.id}>{item.name}</option>
                  ))}
                  <option value="other">{t('form.Other')}</option>
                </select>
              </div>
              {isShowDesignation && (
            <div className="flex flex-col mb-3">
              <div className="flex">
                <label className="mb-2">{t('form.Enter Title')}:</label>
                {/* <>
                  <img
                    src={HelpIcon}
                    width={12}
                    className="ml-1 -mt-2 cursor-pointer"
                    data-tip={t('form.Enter Title')}
                  />
                  <ReactTooltip backgroundColor="#400835" textColor="#ffffff" />
                </> */}
              </div>
              <input
                placeholder={t('form.Enter Title')}
                className="border-2 border-primary rounded-lg outline-none p-1 pl-2"
                value={
                  Addcontactdata.designation
                }
                onChange={(e) => {
                  setAddcontactdata({
                        ...Addcontactdata,
                        designation: e.target.value,
                      })
                    
                }}
              />
              
            </div>
          )}
              <div className="buttons-container">
              {!contactshowError && <span className="error-text">All Fields are required</span>}&nbsp;
                <button className="btn btn_theme btn-sm mr-1 wh_spab submit-button" onClick={handleAddContactSubmit}>
                {t('allModule.Add Contact')}
                </button>
                {/* Add more fields as needed */}
              </div>
            </div>
          )}
          
          {showAdditionalFieldscompany && (
            <div>
              <div className="input-row">
                <label htmlFor="description" style={{ width: '500px'}}>
                  <strong>{t('form.Company Name')}:</strong><br></br>
                  {/* <strong>in Detail:</strong> */}
                </label>
                <input
                  id="name"
                  name="name"
                  placeholder={t('form.Insert Company Name')}
                  value={Addcompanydata.name}
                  onChange={handleChangeforaddcompany}
                  className="input-field-small"
                />
              </div>
              <div className="input-row">
                <label htmlFor="description" style={{ width: '500px'}}>
                  <strong>{t('form.Country')}:</strong><br></br>
                  {/* <strong>in Detail:</strong> */}
                </label>

                <select
                  id="country_id"
                  name="country_id"
                  value={selectedcountry} onChange={handleChangeforaddcountry}>
                  <option value="">{t('allModule.Select Country')}</option>
                  {countrylist.map(item => (
                    <option key={item.id} value={item.id}>{item.name}</option>
                  ))}
                </select>
              </div>
              
              <div className="input-row">
                <label htmlFor="description" style={{ width: '500px'}}>
                  <strong>{t('form.State')}:</strong><br></br>
                  {/* <strong>in Detail:</strong> */}
                </label>
                <select
                  id="state"
                  name="state"
                  value={selectedstate} onChange={handleChangeforaddcompanystate}>
                  <option value="">{t('form.Select state')}</option>
                  {StateListData.map(item => (
                    <option key={item.id} value={item.id}>{item.name}</option>
                  ))}
                </select>
              </div>
              <div className="input-row">
                <label htmlFor="description" style={{ width: '500px'}}>
                  <strong>{t('form.City')}:</strong><br></br>
                  {/* <strong>in Detail:</strong> */}
                </label>

                <select
                  id="city"
                  name="city"
                  value={selectedcity} onChange={handleChangeforaddcompany}>
                  <option value="">{t('form.Select city')}</option>
                  {CityListData.map(item => (
                    <option key={item.id} value={item.id}>{item.name}</option>
                  ))}
                </select>
              </div>
              <div className="input-row">
                <label htmlFor="description" style={{ width: '500px'}}>
                  <strong>{t('form.Entity')}:</strong><br></br>
                  {/* <strong>in Detail:</strong> */}
                </label>

                <select
                  id="entity"
                  name="entity"
                  value={selectedentity} onChange={handleChangeforaddcompany}>
                  <option value="">{t('form.Select entity')}</option>
                  {EntityListData.map(item => (
                    <option key={item.id} value={item.id}>{item.entity_name}</option>
                  ))}
                </select>
              </div>
              <div className="buttons-container">
              
                {!companyshowError && <span className="error-text">All Fields are required</span>}&nbsp;
                <button className="btn btn_theme btn-sm mr-1 wh_spab submit-button" onClick={handleAddCompanySubmit}>
                {t('form.Add Company')}
                </button>

              </div>
            </div>

          )}

          <div className="buttons-container">
            {!showError && <span className="error-text">All Fields are required</span>}&nbsp;
            {!showAdditionalFields && !showAdditionalFieldscompany &&
              <button className="btn btn_theme btn-sm mr-1 wh_spab submit-button" onClick={handleShareSubmit}>
                {t("allModule.Submit")}
              </button>
            }
          </div>
        </div>
        </div>
      )}
    </>
  );
};

export default AddContactPopup;
