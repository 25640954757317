import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import './modal.css';
import useAxios from '../../hooks/useAxios';
import { resolve } from '../../api/resolve';
import { autoCloseTimer, delay } from '../../constants/constants';
import { toast } from 'react-toastify';
import { useContext } from 'react';
import AuthContext from '../../context/AuthContext';
import { useTranslation } from 'react-i18next';

const customStyles = {
    content: {
        top: '40%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        minWidth: 300,
        maxHeight: 'auto',
        borderRadius: '11px',
    },
    overlay: {
        backgroundColor: 'rgba(64, 8, 53, 0.63)',
    },
};

Modal.setAppElement('#root');
const AddCompanyModel = ({
    companyModelOpen,
    setCompanyModelOpen,
    getCompanyListApi,
    companyInfo,
    setCompanyInfo,
    userDetail,
    getUserDetails,
    pageName,
    setCompanyAuthModelOpen,
    user,
}) => {
    
    const api = useAxios();
    const { t } = useTranslation();
    const [errMsg, setErrMsg] = useState('');
    const [countryListData, setCountryListData] = useState([]);
    const [stateListData, setStateListData] = useState([]);
    const [cityListData, setCityListData] = useState([]);
    const [entityListData, setEntityListData] = useState([]);
    const [entityTitleListData, setEntityTitleListData] = useState([]);
    const [isShowDesignation, setIsShowDesignation] = useState(false);

    const [companyObj, setCompanyObj] = useState({
        companyName: '',
        countryId: '',
        stateId: '',
        cityId: '',
        entityId: '',
        otherEntity: '',
        entityTitleId: '',
        designation: '',

    });
    const [companyFormErr, setCompanyFormErr] = useState({
        companyName: '',
        countryId: '',
        stateId: '',
        cityId: '',
        entityId: '',
        entityTitleId: '',
        designation: '',
    });
    const { logoutUser } = useContext(AuthContext);


    const addNewCompany = async () => {
        for (const key in companyFormErr) {
            if (companyFormErr.hasOwnProperty(key)) {
                companyFormErr[key] = "";
            }
        }
        setCompanyFormErr({ ...companyFormErr });
        if (companyObj.companyName === "") {
            setCompanyFormErr({ ...companyFormErr, companyName: t('form.Company Name is required.') });
        } else if (companyObj.countryId === "") {
            setCompanyFormErr({ ...companyFormErr, countryId: t('form.Country is required.') });
        } else if (companyObj.stateId === "") {
            setCompanyFormErr({ ...companyFormErr, stateId: t('form.State is required.') });
        } else if (companyObj.cityId === "") {
            setCompanyFormErr({ ...companyFormErr, cityId: t('form.City is required.') });
        } else if (companyObj.entityId === "") {
            setCompanyFormErr({ ...companyFormErr, entityId: t('form.Entity is required.') });
        }else if (companyObj.entityId == "other" && companyObj.otherEntity === "") {
            setCompanyFormErr({ ...companyFormErr, otherEntity: t('form.Entity is required.') });
        } else {
            let isValidForm = true;
            if (companyInfo?.id) {
                if (companyObj.entityTitleId === "") {
                    isValidForm = false;
                    setCompanyFormErr({ ...companyFormErr, entityTitleId: t('form.Title is required.') });                    
                }else if (companyObj.entityTitleId === 'other' && companyObj.designation === "") {
                    isValidForm = false;
                    setCompanyFormErr({ ...companyFormErr, designation: t('form.Title is required.') });
                }
            }



            if (isValidForm) {
                const postData = {
                    "name": companyObj.companyName,
                    "country_id": companyObj.countryId,
                    "state": companyObj.stateId,
                    "city": companyObj.cityId,
                    "entity": companyObj.entityId !== 'other' ? companyObj.entityId : companyObj.otherEntity,
                    "company_id": companyInfo?.id ? companyInfo?.id : 0,
                    "entity_title": companyObj.entityTitleId,
                    "designation": companyObj.designation,
                }
                const res = await resolve(
                    api
                        .post(companyInfo?.id ? `/company/update/ ` : `company/create/`,
                            JSON.stringify(postData),
                            {
                                headers: {
                                    'content-type': 'application/json',
                                },
                            }
                        )
                        .then((res) => res.data)
                );
                if (res.data) {
                    toast.success(`${t('form.Company')} ${companyInfo?.id ? t('form.updated') : t('form.added')} ${t('form.successfully')}!`, {
                        delay,
                        autoClose: autoCloseTimer,
                    });
                    if (companyInfo?.id) {
                        setCompanyInfo({ ...companyInfo, name: companyObj.companyName });
                    } else {
                        getCompanyListApi();
                    }

                    setTimeout(() => {
                        setCompanyModelOpen(false);
                    }, 1000);

                } else {
                    toast.error(t('common.Something went wrong'), {
                        delay,
                        autoClose: autoCloseTimer,
                    });
                }
            }
        }
    };

    const getCountryListApi = async () => {
        const responceData = await resolve(api.get('/company/countries/list/').then((res) => res.data));
        if (responceData['data']) {
            // console.log("responceData['data']", responceData['data'])
            setCountryListData(responceData['data']);
            setStateListData([]);
            setCityListData([]);
            setEntityListData([]);
        }
    };

    const getStateListApi = async (countryId) => {
        countryId = countryId ? countryId : 0;
        setCityListData([]);
        setEntityListData([]);

        const responceData = await resolve(api.get(`/company/state/list/${countryId}`).then((res) => res.data));
        if (responceData['data']) {
            setStateListData(responceData['data']);
        }
    };
    const getCityListApi = async (stateId) => {
        stateId = stateId ? stateId : 0;
        const responceData = await resolve(api.get(`/company/cities/list/${stateId}`).then((res) => res.data));
        if (responceData['data']) {
            setCityListData(responceData['data']);
        }
    };
    const getEntityListApi = async (countryId) => {
        countryId = countryId ? countryId : 0;
        if(countryId==101 || countryId==230 || countryId==0){
            countryId = countryId
        }else{
            countryId = 231;
        }
        const responceData = await resolve(api.get(`/user/entities/list/${countryId}`).then((res) => res.data));
        if (responceData['data']) {
            setEntityListData(responceData['data']);
        }
    };

    const getEntityTitleListApi = async (entityId) => {
        entityId = entityId ? entityId : 0;
        const responceData = await resolve(api.get(`/user/entities/title/list/${entityId}`).then((res) => res.data));
        if (responceData['data']) {
            setEntityTitleListData(responceData['data']);
        } else {
            setEntityTitleListData([]);
        }
    };

    const handleTitleChange = (title) => {
        
        if (title === 'other') {
            setCompanyObj({ ...companyObj, entityTitleId: title, designation: '' });
            setIsShowDesignation(true);
        } else {
            setCompanyObj({ ...companyObj, entityTitleId: title });
            setIsShowDesignation(false);
        }
    };

    

    useEffect( async () => {
        setIsShowDesignation(false);
        if (companyModelOpen && companyInfo?.id) {
            let countryId = companyInfo?.country_id_id?companyInfo?.country_id_id:companyInfo?.country_id;
            setCompanyObj({
                ...companyObj,
                companyName: companyInfo?.name,
                countryId: (countryId?countryId:"")+ "",
                stateId: companyInfo?.state?companyInfo.state:"",
                cityId: companyInfo?.city?companyInfo.city:"",
                entityId: companyInfo?.entity?companyInfo.entity>0?companyInfo.entity:"other":"",
                otherEntity: companyInfo?.entity?companyInfo.entity:"",
                entityTitleId: userDetail?.entity_title?userDetail.entity_title:"",
                designation: userDetail?.designation,
            });

            setIsShowDesignation(userDetail?.entity_title === 'other' ? true : false);
            await getCountryListApi();
            await getStateListApi(countryId?countryId:0);
            await getCityListApi(companyInfo.state?companyInfo.state:0);
            await getEntityListApi(countryId?countryId:0);
            await getEntityTitleListApi(companyInfo.entity?companyInfo.entity:0);
        }

    }, [companyModelOpen]);


    useEffect(() => {
        getCountryListApi();
    }, []);

    return (
        <div className=''>
            <Modal
                isOpen={companyModelOpen}
                onRequestClose={() => setCompanyModelOpen(false)}
                style={customStyles}
                contentLabel='Example Modal'
                onAfterClose={() => {
                    setCompanyObj({
                        companyName: '',
                        countryId: '',
                        stateId: '',
                        cityId: '',
                        entityId: '',
                        entityTitleId: '',
                        designation: '',
                    });
                    setCompanyFormErr({
                        companyName: '',
                        countryId: '',
                        stateId: '',
                        cityId: '',
                        entityId: '',
                        entityTitleId: '',
                        designation: '',
                    });

                    if(companyInfo?.id){
                        getUserDetails()
                    }

                    if(pageName === 'HomePage' && user?.user_type === 'admin'){
                        setCompanyAuthModelOpen(true);
                    } 
                }}
            >

                <div className='text-center modal-container p-10'>
                    <form
                        onSubmit={(e) => {
                            e.preventDefault();
                            addNewCompany();
                        }}
                    >
                        <h1 className='text-primary font-bold text-lg'>
                            {companyInfo?.id ? t('form.Edit') : t('form.Add')} {t('form.Company')}
                        </h1>
                        <div>
                            <label className='flex flex-start'>{t('form.Company Name')}</label>
                            <input
                                readOnly={companyInfo?.id ? true : false}
                                placeholder={t('form.Company Name')}
                                value={companyObj.companyName}
                                className='border-2 border-primary rounded-lg outline-none p-1 pl-2 w-full'
                                onChange={(e) => setCompanyObj({ ...companyObj, companyName: e.target.value })}
                            />
                            <p className='flex flex-start error_msg'>{companyFormErr.companyName}</p>
                        </div>

                        <div>
                            <label className='flex flex-start'>{t('form.Country')}</label>
                            <select
                                value={companyObj.countryId}
                                onChange={(e) => {
                                    setCompanyObj({ ...companyObj, countryId: e.target.value });
                                    getStateListApi(e.target.value);
                                    getEntityListApi(e.target.value);
                                }}
                                className='border-2 border-primary rounded-lg outline-none p-1 pl-2 w-full'>
                                <option value="">{t('form.Select country')}</option>
                                {countryListData.map((item, index) => {
                                    return (<option key={index} value={item.id}>{item.name}</option>)
                                })}
                            </select>
                            <p className='flex flex-start error_msg'>{companyFormErr.countryId}</p>
                        </div>

                        <div>
                            <label className='flex flex-start'>{t('form.State')}</label>
                           
                            <select
                                value={companyObj.stateId}
                                onChange={(e) => {
                                    setCompanyObj({ ...companyObj, stateId: e.target.value });
                                    getCityListApi(e.target.value);
                                }}
                                className='border-2 border-primary rounded-lg outline-none p-1 pl-2 w-full'>
                                <option value="">{t('form.Select state')}</option>
                                {stateListData.map((item, index) => {
                                    return (<option key={index} value={item.id}>{item.name}</option>)
                                })}
                            </select>
                            <p className='flex flex-start error_msg'>{companyFormErr.stateId}</p>
                        </div>

                        <div>
                            <label className='flex flex-start'>{t('form.City')}</label>
                            <select
                                value={companyObj.cityId}
                                onChange={(e) => {
                                    setCompanyObj({ ...companyObj, cityId: e.target.value });
                                }}
                                className='border-2 border-primary rounded-lg outline-none p-1 pl-2 w-full'>
                                <option value="">{t('form.Select city')}</option>
                                {cityListData.map((item, index) => {
                                    return (<option key={index} value={item.id}>{item.name}</option>)
                                })}
                            </select>
                            <p className='flex flex-start error_msg'>{companyFormErr.cityId}</p>
                        </div>

                        <div>
                            <label className='flex flex-start'>{t('form.Entity')}</label>
                            <select
                                value={companyObj.entityId}
                                onChange={(e) => {
                                    setCompanyObj({ ...companyObj, entityId: e.target.value });
                                    getEntityTitleListApi(e.target.value);
                                }}
                                className='border-2 border-primary rounded-lg outline-none p-1 pl-2 w-full'>
                                <option value="">{t('form.Select entity')}</option>
                                {entityListData.map((item, index) => {
                                    return (<option key={index} value={item.id}>{item.entity_name}</option>)
                                })}
                                <option value="other">{t('form.Other')}</option>
                            </select>
                            <p className='flex flex-start error_msg'>{companyFormErr.entityId}</p>
                        </div>

                        {companyObj.entityId === 'other' && (
                            <div>
                                <label className='flex flex-start'>{t('form.Enter Entity')}</label>
                                <input
                                    placeholder={t('form.Enter Entity')}
                                    value={companyObj.otherEntity}
                                    className='border-2 border-primary rounded-lg outline-none p-1 pl-2 w-full'
                                    onChange={(e) => setCompanyObj({ ...companyObj, otherEntity: e.target.value })}
                                />
                                <p className='flex flex-start error_msg'>{companyFormErr.otherEntity}</p>
                            </div>
                        )}

                        {companyInfo?.id &&
                            <>
                                <div>
                                    <label className='flex flex-start'>{t('form.Title')}</label>
                                    <select
                                        value={companyObj.entityTitleId ? companyObj.entityTitleId : ''}
                                        onChange={(e) => {
                                            // setCompanyObj({ ...companyObj, entityTitleId: e.target.value });
                                            handleTitleChange(e.target.value);
                                        }}
                                        className='border-2 border-primary rounded-lg outline-none p-1 pl-2 w-full'>
                                        <option value="">{t('form.Select title')}</option>
                                        {entityTitleListData.map((item, index) => {
                                            return (<option key={index} value={item.id}>{item.name}</option>)
                                        })}
                                        <option value="other">{t('form.Other')}</option>
                                    </select>
                                    <p className='flex flex-start error_msg'>{companyFormErr.entityTitleId}</p>
                                </div>
                                {isShowDesignation &&
                                    <div>
                                        <label className='flex flex-start'>{t('form.Enter Title')}</label>
                                        <input
                                            placeholder={t('form.Enter Title')}
                                            value={companyObj.designation}
                                            className='border-2 border-primary rounded-lg outline-none p-1 pl-2 w-full'
                                            onChange={(e) => setCompanyObj({ ...companyObj, designation: e.target.value })}
                                        />
                                        <p className='flex flex-start error_msg'>{companyFormErr.designation}</p>
                                    </div>
                                }

                            </>
                        }



                        <div className='flex justify-start mt-5'>
                            <button
                                type='submit'
                                className={`bg-primary font-semibold text-white p-1 px-5 mr-5 rounded-lg w-0.5/3`}
                            >
                                {t('common.Save')}
                            </button>
                            <button
                                className='bg-[#606060] font-semibold text-white p-1 px-5 rounded-lg'
                                onClick={() => {
                                    setCompanyModelOpen(false)
                                    if (pageName==="HomePage") {
                                        logoutUser()
                                    }
                                }}
                            >
                                {t('common.Cancel')}
                            </button>
                        </div>
                    </form>
                </div>

            </Modal>
        </div>
    );
};

export default AddCompanyModel;
