import { useContext } from "react";
import FormContext from "../../context/FormContext";
import PurchaseAadhar from "../modals/PurchaseAadhar";
import PermissionContext from "../../context/PermissionContext";
import wallet_w from '../../assets/icons/wallet_w.png';
import { useTranslation } from 'react-i18next';

const Wallet = () => {

    const {
        setIsOpen,
        modalIsOpen,
    } = useContext(FormContext);
    const { t } = useTranslation();
    const { permissionList } = useContext(PermissionContext);

    return (
        <div className='text-primary text-base font-normal font-sans mt_set upload_doc_page'>
            <div className="main_head">
                <h1 className='text-4xl font-bold text_c'>
                    {t('menu.Wallet')}
                </h1>
            </div>
            <div className=' flex w-full items-center h-full'>

                <div className='w-2/5 flex flex-col justify-evenly h-64 align-center h-64' style={{alignItems:'center'}}>
                    <p className="ml-4 ">{t('allModule.Purchase Aadhaar Signature to add aadhaar signee as Recipients')}</p>
                    <button
                        className='bg-secondary font-bold text-white w-full ml-4 p-1 px-2 rounded-lg w-2/5'
                        type='button'
                        onClick={(e) => {
                            e.stopPropagation();
                            setIsOpen(true)
                        }}
                    >
                        {t('allModule.Purchase Signatures')}
                    </button>

                </div >
                <div className='h-64 ml-12 flex items-center w-2/7' style={{ position: 'relative' }}>
                  <div className="bodder mb-4 " style={{
                    height: '300px',
                    width:'5px',
                    background:'rgb(64, 8, 53)',
                    borderRadius:'10px'

                  }}></div>
                    <div className='w-full plffff' >
                        <div className="box_admin" >
                            <img style={{ backgroundColor: 'rgb(64, 8, 53)' }} src={wallet_w} />
                            <h4><span>{permissionList.aadhar_quota} </span> {t('allModule.Total Digital Signature Balance')}</h4>
                        </div>
                        <div className="row">
                            <div className="col-md-9">
                                <div className='text-base font-bold mb-1'>{t('allModule.Add a notification when Digital Signature Balance falls below')} :</div>
                            </div>
                            <div className="col-md-3">
                                <div className='text-lg w-full font-medium text-[#79838B]'>
                                    <select className="border-2 border-primary rounded-lg outline-none p-0.5 pl-2 heg4 w-full">
                                        <option value="5">5 {t('allModule.Signatures')}</option>
                                        <option value="25">25 {t('allModule.Signatures')}</option>
                                        <option value="50">50 {t('allModule.Signatures')}</option>
                                        <option value="100">100 {t('allModule.Signatures')}</option>
                                    </select></div>
                            </div>
                        </div>
                    </div>

                </div>
            </div >
            <PurchaseAadhar
                setIsOpen={setIsOpen}
                modalIsOpen={modalIsOpen}

            />
        </div >

    )
};
export default Wallet;