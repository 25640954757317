import React, { useEffect, useState, useContext, useRef } from "react";
import useAxios from "../../hooks/useAxios";
import upload_documents from "../../assets/icons/upload_documents.png";
import ReviewModal from "./ReviewModal";
import AuthContext from "../../context/AuthContext";
import { customAlphabet } from "nanoid";
import AppContext from "../../context/AppContext";
import { toast } from "react-toastify";
import LoadingScreen from "../loader/LoadingScreen";
import { resolve } from "../../api/resolve";
import Download from "../../assets/icons/Download.svg";
import pending from "../../assets/icons/pending.png";
import { useTranslation } from "react-i18next";
import nda from "../../assets/icons/NDA_icon.png";

import { docStatus } from "../../constants/constants";
import FormContext from "../../context/FormContext";
import PermissionContext from "../../context/PermissionContext";
import ReactTooltip from "react-tooltip";

const nanoid = customAlphabet("1234567890", 18);
const ReviewDocument = () => {
  const api = useAxios();
  const { permissionList } = useContext(PermissionContext);
  const { t } = useTranslation();
  const { user } = useContext(AuthContext);
  const { getNotifications } = useContext(FormContext);
  const inputRef = useRef();
  const [file, setFile] = useState(null);
  const [base64Data, setBase64Data] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { setActiveLink, setShowDropdown } = useContext(AppContext);
  const [loading, setLoading] = useState(false);
  const [reviewDocList, setReviewDocList] = useState([]);
  const [reviewDocSearchText, setReviewDocSearchText] = useState("");
  const [reviewDocCurrentPage, setReviewDocCurrentPage] = useState(1);
  const [reviewDocPageLimit, setReviewDocPageLimit] = useState(5);
  const [reviewDocNextBtnLimit, setReviewDocNextBtnLimit] = useState(0);
  const [reviewDocCount, setReviewDocCount] = useState(0);
  const [errMsgReviewDoc, setErrMsgReviewDoc] = useState("");
  const [sortingOrderReviewDoc, setSortingOrderReviewDoc] = useState("desc");
  const [sortColumnReviewDoc, setSortColumnReviewDoc] = useState("id");
  const [isDragging, setIsDragging] = useState(false);

  const getBase64 = (file) => {
    var reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      setBase64Data(reader.result);
    };
    reader.onerror = function (error) {};
  };
  const sendNdaDoc = async () => {
    const uniqueId = nanoid();
    setLoading(true);
    try {
      const ndaObj = {
        documentName: file.name,
        userEmailAddress: user.user_email,
        uniqueID: uniqueId,
        receiveReportWebhookURL:
          "https://www.example.com/receive_report_webhook/uygjhghbygjhvbh",
        document: base64Data.split("base64,")[1],
      };
      const res = await api.post(
        `${process.env.REACT_APP_BACKEND_BASE_URL}/nda_report/receive_nda_doc_webhook`,
        ndaObj
      );
      if (res.status == 200) {
        setTimeout(() => {
          getNotifications();
          getReviewDocList();
        }, 2000);
        checkDocumentStatus(res.data.uniqueID);
        toast.success(t("allModule.Document uploaded successfully"));
      }
    } catch (error) {
      toast.error(error.response.data["status"].msg);
    }
    setLoading(false);
    setIsModalOpen(false);
  };

  const getReviewDocList = async (clearText) => {
    const res = await resolve(
      api
        .get(
          `contract/document/list/?type=0&search=${reviewDocSearchText}&ordering=${sortColumnReviewDoc}&sortingOrder=${sortingOrderReviewDoc}&page=${reviewDocCurrentPage}&limit=${reviewDocPageLimit}&review_doc=1`
        )
        .then((res) => res.data)
    );
    if (res.data) {
      const { data } = res;
      const { results, count } = data;
      const filterData = [];
      if (results.length > 0) {
        setReviewDocCount(count);
        results.forEach((result) => {
          const reviewDocObj = {
            id: result.id,
            name: result.name,
            type: result.type,
            uniqueId: result.tags,
            status: docStatus[result.status],
          };
          filterData.push(reviewDocObj);
        });
      } else {
        if (reviewDocCurrentPage === 1)
          setErrMsgReviewDoc(t("homePage.No document added yet"));
        else setErrMsgReviewDoc(t("homePage.No more document to view"));
      }
      setReviewDocList([...filterData]);
      setReviewDocNextBtnLimit(Math.ceil(data.count / reviewDocPageLimit));
    } else {
      setReviewDocNextBtnLimit(0);
    }
  };

  const downloadReport = async (dataInfo) => {
    let date = new Date();
    var year = date.getFullYear();
    var month = date.getMonth() + 1;
    var day = date.getDate();
    var hours = date.getHours();
    var minutes = date.getMinutes();
    var seconds = date.getSeconds();

    const currentDateTime =
      year +
      "_" +
      month +
      "_" +
      day +
      "_" +
      hours +
      "_" +
      minutes +
      "_" +
      seconds;

    await api
      .get(
        `${process.env.REACT_APP_BACKEND_BASE_URL}/nda_report/download/${dataInfo.uniqueId}`
      )
      .then((resp) => {
        let str = resp?.data?.report;
        // setPdfBase64(resp?.data?.report)
        let binaryData = window.atob(`${str}`);
        let array = [];
        for (let i = 0; i < binaryData.length; i++) {
          array.push(binaryData.charCodeAt(i));
        }
        let uint8Array = new Uint8Array(array);
        let blob = new Blob([uint8Array], { type: "application/pdf" });
        let url = URL.createObjectURL(blob);
        let link = document.createElement("a");
        link.href = url;
        let fileName = dataInfo?.name.split(".");
        if (fileName.length > 0) {
          link.download = `${fileName[0]}_Report_NDA_${currentDateTime}.pdf`;
        } else {
          link.download = resp?.data?.reportName;
        }
        document.body.appendChild(link);
        link.click();
      })
      .catch((error) => {
        toast.error(error.response.data["status"].msg);
      });
  };

  // const checkDocumentStatus = (documentId) => {
  //   const sse = new EventSource(
  //     `${process.env.REACT_APP_BACKEND_BASE_URL}/contract/docstatus_stream/${documentId}/nda/`
  //   );
  //   function getRealtimeData(data) {
  //     getReviewDocList();
  //     sse.close();
  //   }
  //   sse.onmessage = (e) => getRealtimeData(e.data);
  // };

  const checkDocumentStatus = async (documentId) => {
    const url = `contract/docstatus_stream/${documentId}/nda/`;
    const responceData = await resolve(api.get(url).then((res) => res.data));
    if (responceData["data"]) {
      if (responceData["data"]["count"] == 0) {
        getReviewDocList();
        setTimeout(() => {
          getReviewDocList();
          checkDocumentStatus(documentId);
        }, 5000);
      } else {
        getReviewDocList();
      }
    }
  };

  useEffect(() => {
    if (file) {
      setIsModalOpen(true);
      getBase64(file);
    }
  }, [file]);

  useEffect(() => {
    getReviewDocList();
  }, [
    reviewDocCurrentPage,
    reviewDocPageLimit,
    sortColumnReviewDoc,
    sortingOrderReviewDoc,
  ]);

  if (loading) {
    return <LoadingScreen />;
  }

  return (
    <>
      <div className="text-primary">
        <div className="main_head flex items-center">
          <img src={nda} width={120} height={120} />
          <h1 className="text-4xl font-bold" style={{ marginLeft: "10px" }}>
            {t("menu.Review a NDA")}
          </h1>
        </div>
        {permissionList["nda_review_on"] == false ? (
          <div className="flex justify-center items-center h-96 flex-column ">
            <h3 className="text-2xl font-bold text_c">
              {t("allModule.You dont have permission to access this page")}
            </h3>
          </div>
        ) : (
          <div className="w-full items-center generate_docuement">
            <div className="row">
              <div className="col-md-8">
                <div
                  onClick={(e) => {
                    e.preventDefault();
                    inputRef.current.click();
                  }}
                  className={`p-5 border-2 border-primary text-primary rounded-lg text-center mt-5 mb-10 ${
                    isDragging ? "bg-[#f0f0f0]" : ""
                  }`}
                >
                  <img
                    style={{ display: "inline" }}
                    src={upload_documents}
                    width={50}
                    height={50}
                  />
                  <h4 className="font-bold text-xl mt-3">
                    {t("allModule.Upload a NDA for AI Review")}
                  </h4>
                </div>
                <input
                  type="file"
                  accept=".pdf,.doc, .docx"
                  hidden
                  ref={inputRef}
                  onChange={(e) => setFile(e.target.files[0])}
                />

                <ul>
                  {/* <li>* {t('allModule.Please upload a file with no redlines and in PDF format.')}</li> */}
                  <li>
                    *{" "}
                    {t(
                      "allModule.An AI reviewed report will be generated and can be found in"
                    )}{" "}
                    <span
                      className="text-red-600 font-bold cursor-pointer"
                      onClick={(e) => {
                        setActiveLink("MyDocuments");
                        setShowDropdown(undefined);
                      }}
                    >
                      {t("allModule.My Documents")}
                    </span>
                    .
                  </li>
                  <li>
                    *{" "}
                    {t(
                      "allModule.This report is not meant to be or is a substitute for legal advice, and please consult a licensed professional."
                    )}
                  </li>
                </ul>
              </div>
              <div className="col-md-4">
                <h4 className="text-xl font-bold  mb-2 text_c ">
                  {t("allModule.NDA List")}
                </h4>
                <div className="seachbox mb-2">
                  <input
                    type="text"
                    value={reviewDocSearchText}
                    className="border-2 border-primary rounded-lg outline-none p-1 pl-2 w-2/3"
                    placeholder={t("allModule.Search")}
                    onChange={(e) => {
                      setReviewDocSearchText(e.target.value);
                    }}
                    onKeyUp={(e) => {
                      if (e.key === "Enter" || e.target.value == "") {
                        setReviewDocCurrentPage(1);
                        getReviewDocList(reviewDocSearchText);
                      }
                    }}
                  />
                  <button
                    className="btn pb-1 pt-0.5 pl-6 pr-6 text_c font-bold rounded-lg btn_theme3"
                    onClick={(e) => {
                      setReviewDocCurrentPage(1);
                      getReviewDocList(reviewDocSearchText);
                    }}
                  >
                    {t("allModule.Search")}
                  </button>
                </div>
                <div className="template_list_container pad_0">
                  {reviewDocList.length > 0 && (
                    <>
                      {reviewDocList.map((item, index) => {
                        return (
                          <div
                            key={index}
                            className="template_box"
                            style={{ position: "relative" }}
                          >
                            <div className="template_box_head">
                              <h1 className="text-2xl font-bold text_c">
                                {" "}
                                {item.name}{" "}
                              </h1>
                              {item.status == "Completed" ? (
                                <>
                                  <p>
                                    {t("form.Status")}: {item.status}
                                  </p>
                                </>
                              ) : (
                                <>
                                  <p className="flex">
                                    {t("form.Status")}:{" "}
                                    <img
                                      style={{
                                        marginTop: "5px",
                                        marginLeft: "10px",
                                        height: "20px",
                                      }}
                                      src={pending}
                                    />
                                  </p>
                                </>
                              )}
                            </div>
                            {item.status == "Completed" && (
                              <>
                                <div
                                  style={{
                                    position: "absolute",
                                    right: "5px",
                                    bottom: "5px",
                                    cursor: "pointer",
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    setTimeout(() => {
                                      downloadReport(item);
                                    }, 3000);
                                  }}
                                >
                                  <button
                                    className="btn btn_theme2 inline-flex items-center justify-center"
                                    data-tip={t("allModule.Download")}
                                  >
                                    {/* {t("allModule.Download")}{" "} */}
                                    <img src={Download} />
                                  </button>
                                </div>
                                <ReactTooltip
                                  backgroundColor="#400835"
                                  textColor="#ffffff"
                                />
                              </>
                            )}
                          </div>
                        );
                      })}
                      <div
                        style={{
                          flexBasis: "100%",
                          display: "flex",
                          justifyContent: "end",
                        }}
                      >
                        <div className="flex text-primary  border-t-2 border-b-2 border-primary rounded-lg">
                          <button
                            className={`btn-sm border-r-2 border-l-2 border-primary font-semibold rounded-tl-lg rounded-bl-lg ${
                              reviewDocCurrentPage <= 1 && "bg-inputBackground"
                            }`}
                            disabled={reviewDocCurrentPage <= 1}
                            onClick={(e) => {
                              if (reviewDocCurrentPage > 1) {
                                setReviewDocCurrentPage(
                                  reviewDocCurrentPage - 1
                                );
                              }
                            }}
                          >
                            {t("common.Prev")}
                          </button>
                          <span className="btn-sm font-semibold">
                            {reviewDocCurrentPage}
                          </span>
                          <button
                            className={`btn-sm border-l-2 border-r-2 border-primary font-semibold rounded-tr-lg rounded-br-lg ${
                              reviewDocCurrentPage >= reviewDocNextBtnLimit &&
                              "bg-inputBackground"
                            }`}
                            disabled={
                              reviewDocCurrentPage >= reviewDocNextBtnLimit
                            }
                            onClick={(e) => {
                              if (
                                reviewDocCurrentPage < reviewDocNextBtnLimit
                              ) {
                                setReviewDocCurrentPage(
                                  reviewDocCurrentPage + 1
                                );
                              }
                            }}
                          >
                            {t("common.Next")}
                          </button>
                        </div>
                      </div>
                    </>
                  )}

                  {reviewDocList.length == 0 && (
                    <div className="flex justify-center items-center h-96 flex-column w-full">
                      <h3 className="text-2xl font-bold text_c">
                        {t("allModule.No Data Found")}
                      </h3>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
        <ReviewModal
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
          file={file}
          sendNdaDoc={sendNdaDoc}
        />
      </div>
    </>
  );
};

export default ReviewDocument;
