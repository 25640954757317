// import "sceditor/minified/themes/default.min.css";
// import "sceditor/minified/sceditor.min.js";
// import "sceditor/minified/icons/monocons.js";
// import "sceditor/minified/formats/bbcode.js";
import { useLocation } from "react-router-dom";
import ReactTooltip from "react-tooltip";
import DocChatAI from "./docChatAI";
import ClauseLibaryChat from "./ClauseLibraryChat";
import dayjs from "dayjs";
import LegalConfirmModal from "../modals/LegalConfirmModal";
import React, { useEffect, useContext, useState, useRef } from "react";
import QChat from "./QChat";
import aiOrange from "../../assets/icons/ai_orange.svg";
import aiPurple from "../../assets/icons/ai_purple.svg";
// Adding changes regarding sceditor
// import $ from 'jquery';
// import 'sceditor';
// import 'sceditor/themes/default.min.css';
// Changes regarding SCEDITOR end

// import { CKEditor } from "@ckeditor/ckeditor5-react";
import { Editor } from "@tinymce/tinymce-react";
// import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

import axios from "axios";
import { resolve } from "../../api/resolve";
import useAxios from "../../hooks/useAxios";
import LoadingScreen from "../loader/LoadingScreen";
import { toast } from "react-toastify";
import AppContext from "../../context/AppContext";
import { getDefaultLocale } from "react-datepicker";
import PermissionContext from "../../context/PermissionContext";
import TemplateNameModel from "../modals/TemplateNameModel";
import { useTranslation } from "react-i18next";
import Modal from "react-modal";
import ClauseAI from "./clauseai";
import DeleteIcon from "../../assets/icons/Delete.svg";
import QbotIcon from "../../assets/icons/ai-icon.png";
import DefaultIcon from "../../assets/icons/defaulticon.png";
import DeleteModal from "../modals/DeleteModal";

const CreateTemplate = () => {
  const [documentData, setDocumentData] = useState("");
  const [templateList, setTemplateList] = useState([]);
  const [defaultTemplateList, setDefaultTemplateList] = useState([]);
  const editorRef = useRef(null);
  const [documentDataDefault, setDocumentDataDefault] = useState(() => {
    return localStorage.getItem("createTemplateContent") || "";
  });
  const [search, setSearch] = useState("");
  const [searchDefault, setSearchDefault] = useState("");
  const [templateName, setTemplateName] = useState("");
  const [templateNameDefault, setTemplateNameDefault] = useState("");
  const [templateRemarks, setTemplateRemarks] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const [errorMsgDefault, setErrorMsgDefault] = useState("");
  const [loading, setLoading] = useState(true);
  const [activeTab, setactiveTab] = useState("selectTemplate");
  // const [iframSrc, setIframeUrl] = useState("");
  const setIframeUrl = useState("");
  const [openTemplateNameModal, setOpenTemplateNameModal] = useState(false);
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isDeleteVersionModalOpen, setIsDeleteVersionModalOpen] =
    useState(false);
  const [deleteTemplateId, setDeleteTemplateId] = useState(null);
  const [deleteVersionId, setDeleteVersionId] = useState(null);
  const location = useLocation();
  const [apicalled, setApicalled] = useState(false);
  const [apiResponse, setApiResponse] = useState("");
  const [versionList, setVersionList] = useState([]);
  const [currentVersion, setCurrentVersion] = useState(0);
  const textareaRef = useRef();
  const [aiContent, setAiContent] = useState("");

  const [isChatVisible, setIsChatVisible] = useState(false);

  const toggleChat = () => {
    setIsChatVisible((prevState) => !prevState); // Toggle the chat visibility
  };

  const {
    permissionList,
    getComapnyInfoApi,
    isValidCompany,
    updatePermissionList,
  } = useContext(PermissionContext);

  const {
    activeLink,
    setActiveLink,
    setPrevActiveLink,
    setShowDropdown,
    setTempSaveConfirmation,
    templateConfirmationOpen,
    setTemplateConfirmationOpen,
  } = useContext(AppContext);

  const baseURL = process.env.REACT_APP_BACKEND_BASE_URL;
  const api = useAxios();
  const { t } = useTranslation();

  const getTemplateListApi = async () => {
    const type = "template";
    const responceData = await resolve(
      api
        .get(`${baseURL}/contract/template/list/?type=${type}`)
        .then((res) => res.data)
    );
    if (responceData["data"]) {
      setTemplateList(responceData["data"].data);
    }
  };

  // const updateContent = () => {
  //   const content = window.sceditor.instance(textareaRef.current).val();
  //   setAiContent(content);
  // };

  const getTemplateSearchListApi = async (search) => {
    const responceData = await resolve(
      api
        .get(`${baseURL}/contract/template/list/search/?search=${search}`)
        .then((res) => res.data)
    );
    if (responceData["data"] && responceData["data"].results) {
      setTemplateList(responceData["data"].results);
    }
  };

  const templateListFetch = async (id) => {
    setCurrentVersion(id);
    const baseURL = process.env.REACT_APP_BACKEND_BASE_URL;
    console.log("REACT_APP_BACKEND_BASE_URL", baseURL);
    const url = `${baseURL}/contract/template/versions/${id}/`;
    console.log("Final URL", url);

    try {
      const res = await api.get(url);
      if (res.data) {
        setVersionList(res.data);
        setTemplateNameDefault(res.data[0].title);
      }
      return res.data;
    } catch (error) {
      console.error("Error fetching template list:", error);
    }
  };

  const handleTemplateClick = async (item) => {
    // setDocumentData("");
    setDocumentData("");
    setLoading(true);
    const res = await resolve(
      api
        .get(`${baseURL}/contract/template/list/view/${item.id}`)
        .then((res) => {
          setApiResponse(res["data"] + "");
          setApicalled(false);
          setApicalled(true);

          if (res["data"]) {
            // setDocumentDataDefault(responceData["data"] + "");
            setLoading(false);
          }

          if (res["error"]) {
            toast.error(t("common.Something went wrong"));
            // setLoading(false);
          }
          return res.data;
        })
    );
  };

  const deleteTemplate = async (item) => {
    const responceData = await resolve(
      api
        .get(`${baseURL}/contract/template/list/delete/${deleteTemplateId}`)
        .then((res) => res.data)
    );
    if (responceData["data"]) {
      toast.success(t("allModule.Template deleted successfully"));
      getTemplateListApi();
      getDefaultTemplateListApi();
    }

    if (responceData["error"]) {
      toast.error(t("allModule.Delete template failed"));
    }
    setIsDeleteModalOpen(false);
  };

  const deleteVersion = async (item) => {
    const responceData = await resolve(
      api
        .get(`${baseURL}/contract/template/Version/delete/${deleteVersionId}`)
        .then((res) => res.data)
    );
    if (responceData["data"]) {
      toast.success(t("allModule.Template Version deleted successfully"));
      getTemplateListApi();
      getDefaultTemplateListApi();
    }

    if (responceData["error"]) {
      toast.error(t("allModule.Delete template failed"));
    }
    setIsDeleteVersionModalOpen(false);
    setVersionList([]);
  };

  const addTableStyles = async (content) => {
    let modifiedContent =
      '<div style="font-family: Times New Roman; font-size: 11px;">' +
      content +
      "</div>";
    modifiedContent = modifiedContent.replace(
      /<table>/g,
      '<table style="border-collapse: collapse; margin: 0 auto;">'
    );

    // Replace <td> tags with style
    modifiedContent = modifiedContent.replace(
      /<td>/g,
      '<td style="border: 1px solid #000; empty-cells: show; min-width: 40px;">'
    );

    modifiedContent = modifiedContent.replace(
      /<iframe.*?data-youtube-id="(.*?)".*?<\/iframe>/g,
      '<a href="https://www.youtube.com/watch?v=$1" target="_blank"><img width="70" height="50" src="https://img.youtube.com/vi/$1/maxresdefault.jpg" alt="Video Thumbnail"></a>'
    );

    // Define regular expression to match img src attribute
    const imgRegex = /<img\s+[^>]*src="([^"]*)"[^>]*>/g;

    const promises = [];

    // Replace img src attribute with Base64
    modifiedContent.replace(imgRegex, (match, src) => {
      if (src.startsWith("emoticons/")) {
        // Convert image to Base64
        promises.push(convertImageToBase64(src, 700, 350, "emoji"));
        // Replace src attribute with Base64 string
        return match;
      } else if (src.startsWith("https://")) {
        promises.push(convertImageToBase64(src, 400, 200, "https"));
        return match;
      } else {
        promises.push(`<img width="500px" height="300px" src="${src}" />`);
        return match;
      }
    });

    const data = await Promise.all(promises);
    return modifiedContent.replace(imgRegex, () => data.shift());
  };

  // Function to convert image to Base64
  const convertImageToBase64 = (src, maxWidth, maxHeight, type) => {
    return new Promise((resolve, reject) => {
      if (type == "emoji") {
        const url = window.location.host;
        const protocol = window.location.protocol;
        src = `${protocol}//${url}/${src}`;
      }

      let convertedImage;
      var xhr = new XMLHttpRequest();
      xhr.onload = function () {
        var reader = new FileReader();
        reader.onloadend = function () {
          let img = new Image();
          img.onload = function () {
            // Calculate aspect ratio
            let aspectRatio = img.width / img.height;

            // Calculate new dimensions
            let newWidth = img.width;
            let newHeight = img.height;

            if (newWidth > maxWidth) {
              newWidth = maxWidth;
              newHeight = newWidth / aspectRatio;
            }

            if (newHeight > maxHeight) {
              newHeight = maxHeight;
              newWidth = newHeight * aspectRatio;
            }

            // Create canvas for resizing
            let canvas = document.createElement("canvas");
            canvas.width = newWidth;
            canvas.height = newHeight;

            // Resize image
            let ctx = canvas.getContext("2d");
            ctx.drawImage(img, 0, 0, newWidth, newHeight);

            // Convert resized image to Base64
            let resizedBase64 = canvas.toDataURL("image/jpeg"); // You can change 'image/jpeg' to 'image/png' if needed
            convertedImage = `<img src="${resizedBase64}" width="${newWidth}" height="${newHeight}" />`;
            resolve(convertedImage);
          };
          img.src = reader.result;
        };
        reader.readAsDataURL(xhr.response);
      };
      xhr.open("GET", src);
      xhr.responseType = "blob";
      xhr.send();
    });
  };

  const handleSaveTemplate = async () => {
    const content = documentData;
    setErrorMsg("");
    // const sceditorInstance = window.sceditor.instance(textareaRef.current);
    // const content = sceditorInstance.val();
    if (templateName === "") {
      setErrorMsg(t("allModule.Please provide document name"));
    } else if (content === "") {
      setErrorMsg(
        t("allModule.Please provide document content or choose template")
      );
    } else {
      setLoading(true);
      // const modifiedContent = await addTableStyles(content);
      const postData = {
        templateName: templateName,
        // content: modifiedContent,
        content: content,
        createTemplate: false,
        createDocuement: true,
        type: "template",
        fileType: "pdf",
      };
      const res = await resolve(
        api
          .post(`${baseURL}/contract/template/converter/`, postData)
          .then((res) => {
            // setApicalled(false);
            // setApicalled(true);
            setLoading(false);
            if (res["data"]) {
              toast.success(t("allModule.Document created successfully"));
              setTemplateName("");
              setDocumentData("");
              // setApiResponse("");
              setDocumentData("");
              setTempSaveConfirmation(false);
              localStorage.removeItem("createTemplateContent");
              setActiveLink("MyDocuments");
              setShowDropdown("");
              updatePermissionList(res["data"].User_permission[0]);
            }
            return res.data;
          })
          .catch((error) => {
            setLoading(false);
            setApicalled(false);
            setApicalled(true);
            toast.error(t("allModule.Create template failed"));
          })
      );
    }
  };

  const getDefaultTemplateListApi = async () => {
    const type = "template";
    const responceData = await resolve(
      api
        .get(`${baseURL}/contract/template/list/?type=${type}`)
        .then((res) => res.data)
    );
    if (responceData["data"]) {
      setDefaultTemplateList(responceData["data"].data);
    }
  };

  const getDefaultTemplateSearchListApi = async (search) => {
    const responceData = await resolve(
      api
        .get(
          `${baseURL}/contract/template/list/search/?search=${searchDefault}`
        )
        .then((res) => res.data)
    );
    if (responceData["data"] && responceData["data"].results) {
      setDefaultTemplateList(responceData["data"].results);
    }
  };

  const handleDefaultTemplateClick = async (item) => {
    setDocumentDataDefault("");
    setLoading(true);
    const res = await resolve(
      api
        .get(`${baseURL}/contract/template/list/view/${item.id}`)
        .then((res) => {
          setApiResponse(res["data"] + "");
          setApicalled(false);
          setApicalled(true);

          if (res["data"]) {
            // setDocumentDataDefault(responceData["data"] + "");
            setLoading(false);
          }

          if (res["error"]) {
            toast.error(t("common.Something went wrong"));
            // setLoading(false);
          }
          return res.data;
        })
    );
  };

  const handleSaveTemplateDefault = async () => {
    // const sceditorInstance = window.sceditor.instance(textareaRef.current);
    // const content = sceditorInstance.val();
    const content = documentData;
    setErrorMsg("");
    if (templateNameDefault === "") {
      setErrorMsgDefault(t("allModule.Please provide template name"));
    } else if (templateRemarks === "") {
      setErrorMsgDefault(t("allModule.Please provide template Remarks"));
    } else if (content === "<p><br></p>") {
      setErrorMsgDefault(
        t("allModule.Please provide template content or choose template")
      );
    } else {
      setErrorMsgDefault("");
      // const modifiedContent = await addTableStyles(content);
      setLoading(true);
      const postData = {
        templateName: templateNameDefault,
        // content: modifiedContent,
        content: content,
        createTemplate: true,
        createDocuement: false,
        type: "template",
        fileType: "docx",
        remarks: templateRemarks,
      };
      const res = await resolve(
        api
          .post(`${baseURL}/contract/template/converter/`, postData)
          .then((res) => {
            setApicalled(false);
            setApicalled(true);
            // setApicalled(false);
            // setApiResponse(res["data"] + "");
            setLoading(false);
            if (res["data"]) {
              toast.success(t("allModule.Template created successfully"));
              setTemplateNameDefault("");
              setTemplateRemarks("");
              setDocumentDataDefault("");
              setApiResponse("");
              localStorage.removeItem("createTemplateContent");
              getTemplateListApi();
              getDefaultTemplateListApi();
              updatePermissionList(res["data"].User_permission[0]);
            }
            return res.data;
          })
          .catch((error) => {
            setApiResponse(content);
            setLoading(false);
            setApicalled(false);
            setApicalled(true);
            if (
              error.response &&
              error.response.data &&
              error.response.data.message
            ) {
              toast.error(error.response.data.message); // Displaying the custom error message
            }
          })
      );
    }
  };

  const handelTemplateversionDefault = async (item) => {
    const postData = {
      template_id: item.id,
    };
    const res = await api
      .post(`${baseURL}/contract/template/versions/default`, postData)
      .then((res) => {
        if (res.data) {
          setVersionList(res.data);
          toast.success(t("allModule.Template set as default successfully"));
        }
        return res.data;
      })
      .catch((error) => {
        toast.error(t("allModule.Set default template failed"));
      });
  };

  const handleVersionTemplateUpdate = async () => {
    // const sceditorInstance = window.sceditor.instance(textareaRef.current);
    // const content = sceditorInstance.val();
    const content = documentData;
    // sceditorInstance.val("");

    setErrorMsg("");
    if (templateNameDefault === "") {
      setErrorMsgDefault(t("allModule.Please provide template name"));
    } else if (templateRemarks === "") {
      setErrorMsgDefault(t("allModule.Please provide template Remarks"));
    } else if (content === "<p><br></p>") {
      setErrorMsgDefault(
        t("allModule.Please provide template content or choose template")
      );
    } else {
      setLoading(true);
      setErrorMsgDefault("");
      // const modifiedContent = await addTableStyles(content);
      const postData = {
        templateName: templateNameDefault,
        // content: modifiedContent,
        content: content,
        createTemplate: true,
        createDocuement: false,
        type: "template",
        fileType: "docx",
        template_id: currentVersion,
        remarks: templateRemarks,
      };
      const res = await resolve(
        api
          .post(`${baseURL}/contract/template/versions/`, postData)
          .then((res) => {
            setLoading(false);
            if (res["data"]) {
              toast.success(t("allModule.Template created successfully"));
              setTemplateNameDefault("");
              setTemplateRemarks("");
              setDocumentDataDefault("");
              setDocumentData("");
              setApiResponse("");
              // sceditorInstance.val("");
              setVersionList([]);
              setApicalled(false);
              setApicalled(true);
              localStorage.removeItem("createTemplateContent");
              getTemplateListApi();
              getDefaultTemplateListApi();
              updatePermissionList(res["data"].User_permission[0]);
            }
            return res.data;
          })
          .catch((error) => {
            setLoading(false);
            setApicalled(false);
            setApicalled(true);
            if (
              error.response &&
              error.response.data &&
              error.response.data.message
            ) {
              toast.error(error.response.data.message); // Displaying the custom error message
            }
          })
      );
    }
  };

  // const handelVersionView = async (item) => {
  //   const sceditorInstance = window.sceditor.instance(textareaRef.current);
  //   sceditorInstance.val(item.html_content);
  // };

  const handelVersionView = async (item) => {
    if (editorRef.current) {
      // Set the content of the TinyMCE editor
      editorRef.current.setContent(item.html_content);
    } else {
      console.error("Editor instance is not available.");
    }
  };

  useEffect(() => {
    getTemplateListApi();
    getDefaultTemplateListApi();
    getComapnyInfoApi();
  }, []);

  // useEffect(() => {
  //   setTempSaveConfirmation(true);
  //   setVersionList([]);
  //   setTemplateNameDefault("");
  //   setTemplateRemarks("");
  //   setCurrentVersion(0);
  //   const delay = setTimeout(() => {
  //     if (textareaRef.current) {
  //       window.sceditor.create(textareaRef.current, {
  //         format: "xhtml",
  //         icons: "monocons",
  //         style: `${process.env.PUBLIC_URL}/sceditor.css`,
  //         emoticonsEnabled: true,
  //       });
  //     }
  //   }, 1000);
  //   return () => clearTimeout(delay);
  // }, []);

  useEffect(() => {
    setTempSaveConfirmation(true);
    setVersionList([]);
    setTemplateNameDefault("");
    setTemplateRemarks("");
    setCurrentVersion(0);

    const delay = setTimeout(() => {
      if (editorRef.current) {
        editorRef.current.setContent(""); // Initialize with empty content or any default content
      }
    }, 1000);

    return () => clearTimeout(delay);
  }, []);

  // useEffect(() => {
  //   if (textareaRef.current) {
  //     window.sceditor.create(textareaRef.current, {
  //       format: "xhtml",
  //       icons: "monocons",
  //       style: `${process.env.PUBLIC_URL}/sceditor.css`,
  //     });
  //     const sceditorInstance = window.sceditor.instance(textareaRef.current);
  //     sceditorInstance.val("");
  //   }
  //   setVersionList([]);
  //   setTemplateNameDefault("");
  //   setTemplateRemarks("");
  //   setCurrentVersion(0);
  // }, [activeTab]);

  useEffect(() => {
    if (editorRef.current) {
      editorRef.current.setContent(""); // Clear the content
    }
    setVersionList([]);
    setTemplateNameDefault("");
    setTemplateRemarks("");
    setCurrentVersion(0);
  }, [activeTab]);

  // const handleApiCall = () => {
  //   if (textareaRef.current) {
  //     window.sceditor.create(textareaRef.current, {
  //       format: "xhtml",
  //       icons: "monocons",
  //       style: `${process.env.PUBLIC_URL}/sceditor.css`,
  //     });
  //     const sceditorInstance = window.sceditor.instance(textareaRef.current);
  //     apiResponse === ""
  //       ? sceditorInstance.val("")
  //       : sceditorInstance.val(apiResponse);
  //     setLoading(false);
  //   }
  // };

  const handleApiCall = () => {
    if (editorRef.current) {
      editorRef.current.setContent(apiResponse === "" ? "" : apiResponse); // Set content based on API response
      setLoading(false);
    }
  };

  useEffect(() => {
    setLoading(false);
    const timeout = setTimeout(() => {
      if (apicalled == true) {
        handleApiCall();
        setApicalled(false);
      }
    }, 500);

    return () => clearTimeout(timeout);
  }, [apicalled]);

  return (
    <>
      <div className="text-primary text-base font-normal font-sans mt_set">
        <div className="main_head">
          <h1 className="text-4xl font-bold text_c">
            {t("menu.Create from Template")}
          </h1>
        </div>

        {!isValidCompany ? (
          <div className="flex justify-center items-center h-96 flex-column ">
            <h3 className="text-2xl font-bold text_c">
              {t("allModule.Please update your company info.")}
            </h3>
          </div>
        ) : permissionList["custom_template_on"] == 1 ? (
          <div className="w-full items-center generate_docuement">
            <div className="row">
              <div
                className="col-md-12"
                style={{ display: "flex", marginBottom: "10px" }}
              >
                <button
                  style={{
                    border: "none",
                    marginRight: 10,
                    color: "gray",
                    background: "transparent",
                    fontWeight: 500,
                    fontSize: 16,
                    cursor: "pointer",
                    padding: "5px",
                    borderBottom:
                      activeTab == "selectTemplate"
                        ? "2px solid black"
                        : "none",
                  }}
                  onClick={() => {
                    setactiveTab("selectTemplate");
                  }}
                >
                  {t("menu.Create from Template")}
                </button>
                <button
                  style={{
                    marginRight: 10,
                    border: "none",
                    color: "gray",
                    background: "transparent",
                    fontWeight: 500,
                    fontSize: 16,
                    cursor: "pointer",
                    padding: "5px",
                    borderBottom:
                      activeTab == "createTemplate"
                        ? "2px solid black"
                        : "none",
                  }}
                  onClick={() => {
                    setactiveTab("createTemplate");
                  }}
                >
                  {t("allModule.Create a Template")}
                </button>
              </div>

              {activeTab == "selectTemplate" && (
                <>
                  {permissionList.custom_template_on === false ? (
                    <div className="flex justify-center items-center h-96 flex-column w-full">
                      <h3 className="text-2xl font-bold text_c">
                        {t(
                          "allmodule.You dont have permission to access this page"
                        )}
                      </h3>
                    </div>
                  ) : permissionList.custom_template_allotted_cnt > 0 &&
                    permissionList.custom_template_remaing_cnt === 0 ? (
                    <div className="flex justify-center items-center h-96 flex-column w-full">
                      <h3 className="text-2xl font-bold text_c">
                        {t(
                          "allModule.You have no more limit to create template."
                        )}
                      </h3>
                    </div>
                  ) : (
                    <>
                      <div className="col-md-9">
                        {loading ? (
                          <LoadingScreen />
                        ) : (
                          <>
                            <div className="bottom_area">
                              {/* <CKEditor
                                editor={ClassicEditor}
                                data={documentData}
                                onReady={(editor) => { }}
                                onChange={(event, editor) => {
                                  const data = editor.getData();
                                  setDocumentData(data);
                                }}  
                              />
                              <>
                                <form action="" method="post">
                                  <div>
                                    <textarea
                                      ref={textareaRef}
                                      id="example"
                                      style={{
                                        height: "70vh",
                                        width: "100%",
                                      }}
                                    >
                                      {documentData}
                                    </textarea>
                                  </div>
                                </form>
                              </>*/}

                              <Editor
                                tinymceScriptSrc={`${process.env.PUBLIC_URL}/tinymce/tinymce.min.js`}
                                //tinymceScriptSrc="https://cdn.tiny.cloud/1/zi2ytxohu4e3dhkgcqh3z4hlmorokuwbj5a31lz5s5xsj9ge/tinymce/6/tinymce.min.js"
                                value={documentData}
                                onInit={(evt, editor) =>
                                  (editorRef.current = editor)
                                }
                                init={{
                                  selector: "textarea#editor", // your selector for the TinyMCE editor
                                  license_key: "gpl", // Add this line to agree to the GPL license terms
                                  height: 500,
                                  menubar: false,
                                  branding: false,
                                  statusbar: false,
                                  browser_spellcheck: true,
                                  promotion: false,
                                  plugins: [
                                    // Core editing and premium features
                                    "anchor",
                                    "autolink",
                                    "charmap",
                                    "codesample",
                                    "emoticons",
                                    "image",
                                    "link",
                                    "lists",
                                    "media",
                                    "searchreplace",
                                    "table",
                                    "visualblocks",
                                    "wordcount",
                                    "checklist",
                                    "mediaembed",
                                    "casechange",
                                    "export",
                                    "formatpainter",
                                    "pageembed",
                                    "a11ychecker",
                                    "tinymcespellchecker",
                                    "permanentpen",
                                    "powerpaste",
                                    "advtable",
                                    "advcode",
                                    "editimage",
                                    "advtemplate",
                                    "ai",
                                    "mentions",
                                    "tinycomments",
                                    "tableofcontents",
                                    "footnotes",
                                    "mergetags",
                                    "autocorrect",
                                    "typography",
                                    "inlinecss",
                                    "markdown",
                                    // Additional plugins
                                    "advlist",
                                    "insertdatetime",
                                    "autosave",
                                    "code",
                                    "directionality",
                                    "fullscreen",
                                    "paste",
                                    "preview",
                                    "tinydrive",
                                    "quickbars",
                                    "spellchecker",
                                    "save",
                                  ],
                                  toolbar:
                                    "undo redo | aidialog aishortcuts | blocks fontsizeinput | bold italic underline strikethrough superscript subscript | forecolor backcolor formatpainter removeformat | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link image media table pageembed | lineheight outdent indent | charmap emoticons checklist | code fullscreen preview | save print | pagebreak anchor codesample footnotes mergetags | addtemplate inserttemplate | addcomment showcomments | ltr rtl casechange | spellcheckdialog a11ycheck | chatButton",
                                  content_style:
                                    "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
                                  font_formats:
                                    "Arial=arial,helvetica,sans-serif; Courier New=courier new,courier,monospace; Georgia=georgia,palatino,serif; Tahoma=tahoma,arial,helvetica,sans-serif; Times New Roman=times new roman,times,serif; Verdana=verdana,geneva,sans-serif",
                                  fontsize_formats:
                                    "8pt 10pt 12pt 14pt 18pt 24pt 36pt",
                                  tinycomments_mode: "embedded",
                                  tinycomments_author: "Author name",
                                  mergetags_list: [
                                    {
                                      value: "First.Name",
                                      title: "First Name",
                                    },
                                    { value: "Email", title: "Email" },
                                  ],
                                  ai_request: (request, respondWith) =>
                                    respondWith.string(() =>
                                      Promise.reject(
                                        "See docs to implement AI Assistant"
                                      )
                                    ),
                                  image_advtab: true,
                                  typography_rules: [
                                    "common/punctuation/quote",
                                    "en-US/dash/main",
                                    "common/nbsp/afterParagraphMark",
                                    "common/nbsp/afterSectionMark",
                                    "common/nbsp/afterShortWord",
                                    "common/nbsp/beforeShortLastNumber",
                                    "common/nbsp/beforeShortLastWord",
                                    "common/nbsp/dpi",
                                    "common/punctuation/apostrophe",
                                    "common/space/delBeforePunctuation",
                                    "common/space/afterComma",
                                    "common/space/afterColon",
                                    "common/space/afterExclamationMark",
                                    "common/space/afterQuestionMark",
                                    "common/space/afterSemicolon",
                                    "common/space/beforeBracket",
                                    "common/space/bracket",
                                    "common/space/delBeforeDot",
                                    "common/space/squareBracket",
                                    "common/number/mathSigns",
                                    "common/number/times",
                                    "common/number/fraction",
                                    "common/symbols/arrow",
                                    "common/symbols/cf",
                                    "common/symbols/copy",
                                    "common/punctuation/delDoublePunctuation",
                                    "common/punctuation/hellip",
                                  ],
                                  typography_ignore: ["code"],
                                  toolbar_mode: "wrap",
                                  contextmenu: "link image imagetools table", // Add table to the context menu
                                  table_advtab: true, // Enable the "Advanced" tab in the table properties dialog
                                  table_appearance_options: true, // Enable appearance options like width, height, etc.
                                  table_default_attributes: {
                                    border: "1", // Set default border for tables
                                  },
                                  table_default_styles: {
                                    width: "100%", // Set default width for tables
                                  },
                                  typography_ignore: ["code"],

                                  // setup: function (editor) {
                                  //   // Add the chat button to the toolbar
                                  //   editor.ui.registry.addButton("chatButton", {
                                  //     text: "Chat",
                                  //     onAction: function () {
                                  //       const selectedText =
                                  //         editor.selection.getContent({
                                  //           format: "text",
                                  //         });
                                  //       if (selectedText) {
                                  //         launchChatWithText(selectedText); // Call the function with the selected text
                                  //       } else {
                                  //         alert(
                                  //           "Please select text to start a chat."
                                  //         );
                                  //       }
                                  //     },
                                  //   });

                                  //   // Add Ctrl+/ keyboard shortcut for the chat functionality
                                  //   editor.addShortcut(
                                  //     "ctrl+/",
                                  //     "Chat with selected text",
                                  //     function () {
                                  //       const selectedText =
                                  //         editor.selection.getContent({
                                  //           format: "text",
                                  //         });
                                  //       if (selectedText) {
                                  //         launchChatWithText(selectedText); // Call the function with the selected text
                                  //       } else {
                                  //         alert(
                                  //           "Please select text to start a chat."
                                  //         );
                                  //       }
                                  //     }
                                  //   );

                                  //   // Manually handling Ctrl + /
                                  //   editor.on("keydown", function (e) {
                                  //     if (e.ctrlKey && e.key === "/") {
                                  //       e.preventDefault(); // Prevent the default action
                                  //       const selectedText =
                                  //         editor.selection.getContent({
                                  //           format: "text",
                                  //         });
                                  //       if (selectedText) {
                                  //         launchChatWithText(selectedText); // Call the function with the selected text
                                  //       } else {
                                  //         alert(
                                  //           "Please select text to start a chat."
                                  //         );
                                  //       }
                                  //     }
                                  //   });
                                  // },

                                  toolbar_mode: "wrap",
                                  contextmenu: "link image imagetools table", // Add table to the context menu
                                  table_advtab: true, // Enable the "Advanced" tab in the table properties dialog
                                  table_appearance_options: true, // Enable appearance options like width, height, etc.
                                  table_default_attributes: {
                                    border: "1", // Set default border for tables
                                  },
                                  table_default_styles: {
                                    width: "100%", // Set default width for tables
                                  },
                                }}
                                onEditorChange={(content) => {
                                  setDocumentData(content);
                                }}
                              />
                            </div>
                            <div className="flex justify-end">
                              <p className="error_msg">{errorMsg}</p>
                            </div>

                            <div className="mt-5 flex justify-end">
                              <input
                                type="text"
                                value={templateName}
                                onChange={(e) =>
                                  setTemplateName(e.target.value)
                                }
                                className="border-2 border-primary rounded-lg outline-none p-1 pl-2"
                                placeholder={t("allModule.Enter Document Name")}
                              />
                              <button
                                onClick={handleSaveTemplate}
                                className="btn pb-1 pt-0.5 pl-6 pr-6 text_c font-bold rounded-lg btn_theme3 ml-5"
                              >
                                {t("common.Save")}
                              </button>
                            </div>
                          </>
                        )}
                      </div>

                      <div className="col-md-3">
                        <h4 className="text-xl font-bold  mb-2 text_c ">
                          {t("allModule.Select Template")}
                        </h4>
                        <div className="seachbox mb-2">
                          <input
                            type="text"
                            className="border-2 border-primary rounded-lg outline-none p-1 pl-2 w-2/3"
                            placeholder={t("allModule.Search a Template")}
                            onChange={(e) => {
                              setSearch(e.target.value);
                            }}
                            onKeyUp={(e) => {
                              if (e.key === "Enter" || e.target.value === "") {
                                getTemplateSearchListApi(search);
                              }
                            }}
                            value={search}
                          />
                          <button
                            className="btn pb-1 pt-0.5 pl-6 pr-6 text_c font-bold rounded-lg btn_theme3"
                            onClick={(e) => getTemplateSearchListApi(search)}
                          >
                            {t("allModule.Search")}
                          </button>
                        </div>
                        <div className="template_list_container pad_0">
                          {loading == false &&
                            templateList.length > 0 &&
                            templateList.map((item, index) => {
                              return (
                                <>
                                  <ReactTooltip
                                    backgroundColor="#400835"
                                    textColor="#ffffff"
                                  />
                                  <div
                                    key={index}
                                    onClick={(e) => {
                                      handleTemplateClick(item);
                                    }}
                                    className="template_box"
                                  >
                                    <div className="template_box_head">
                                      <h1 className="text-2xl font-bold text_c">
                                        {" "}
                                        {item.title}{" "}
                                      </h1>
                                    </div>
                                    <div className="template_box_body">
                                      <strong>
                                        {t("allModule.Created on")}
                                      </strong>
                                      <span className="text-base font-normal text_c">
                                        {" "}
                                        {dayjs(item.created_at).format(
                                          "dddd, MMM D, YYYY HH:mm"
                                        )}
                                      </span>
                                    </div>
                                    <button
                                      className="btn btn_theme btn-sm mr-1 mt-8 wh_spab "
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        setDeleteTemplateId(item.id);
                                        setIsDeleteModalOpen(true);
                                      }}
                                      data-tip={t("constant.Delete document")}
                                    >
                                      <img
                                        src={DeleteIcon}
                                        className="ml-0.5"
                                        width={20}
                                        alt="Delete Document"
                                      />
                                    </button>
                                  </div>
                                </>
                              );
                            })}

                          {loading == false && templateList.length == 0 && (
                            <div className="flex justify-center items-center h-96 flex-column w-full">
                              <h3 className="text-2xl font-bold text_c">
                                {t("allModule.No Template Found")}
                              </h3>
                            </div>
                          )}
                        </div>
                      </div>
                    </>
                  )}
                </>
              )}

              {activeTab == "createTemplate" && (
                <>
                  {permissionList.custom_template_on === false ? (
                    <div className="flex justify-center items-center h-96 flex-column w-full">
                      <h3 className="text-2xl font-bold text_c">
                        {t(
                          "allModule.You dont have permission to access this page"
                        )}
                      </h3>
                    </div>
                  ) : permissionList.custom_template_allotted_cnt > 0 &&
                    permissionList.custom_template_remaing_cnt === 0 ? (
                    <div className="flex justify-center items-center h-96 flex-column w-full">
                      <h3 className="text-2xl font-bold text_c">
                        {t(
                          "allModule.You have no more limit to create template."
                        )}
                      </h3>
                    </div>
                  ) : (
                    <>
                      <div className="col-md-9">
                        {loading ? (
                          <LoadingScreen />
                        ) : (
                          <>
                            <div className="bottom_area">
                              {/* <CKEditor
                                editor={ClassicEditor}
                                data={documentDataDefault}
                                onReady={(editor) => { }}
                                onChange={(event, editor) => {
                                  const data = editor.getData();
                                  setDocumentDataDefault(data);
                                }}
                              /> 
                              <form action="" method="post">
                                <div>
                                  <textarea
                                    ref={textareaRef}
                                    id="example1"
                                    style={{
                                      height: "70vh",
                                      width: "100%",
                                    }}
                                  />
                                </div>
                              </form>*/}

                              <Editor
                                tinymceScriptSrc={`${process.env.PUBLIC_URL}/tinymce/tinymce.min.js`}
                                //tinymceScriptSrc="https://cdn.tiny.cloud/1/zi2ytxohu4e3dhkgcqh3z4hlmorokuwbj5a31lz5s5xsj9ge/tinymce/6/tinymce.min.js"
                                value={documentData}
                                onInit={(evt, editor) =>
                                  (editorRef.current = editor)
                                }
                                init={{
                                  selector: "textarea#editor", // your selector for the TinyMCE editor
                                  license_key: "gpl", // Add this line to agree to the GPL license terms
                                  height: 500,
                                  menubar: false,
                                  branding: false,
                                  statusbar: false,
                                  browser_spellcheck: true,
                                  promotion: false,
                                  plugins: [
                                    // Core editing and premium features
                                    "anchor",
                                    "autolink",
                                    "charmap",
                                    "codesample",
                                    "emoticons",
                                    "image",
                                    "link",
                                    "lists",
                                    "media",
                                    "searchreplace",
                                    "table",
                                    "visualblocks",
                                    "wordcount",
                                    "checklist",
                                    "mediaembed",
                                    "casechange",
                                    "export",
                                    "formatpainter",
                                    "pageembed",
                                    "a11ychecker",
                                    "tinymcespellchecker",
                                    "permanentpen",
                                    "powerpaste",
                                    "advtable",
                                    "advcode",
                                    "editimage",
                                    "advtemplate",
                                    "ai",
                                    "mentions",
                                    "tinycomments",
                                    "tableofcontents",
                                    "footnotes",
                                    "mergetags",
                                    "autocorrect",
                                    "typography",
                                    "inlinecss",
                                    "markdown",
                                    // Additional plugins
                                    "advlist",
                                    "insertdatetime",
                                    "autosave",
                                    "code",
                                    "directionality",
                                    "fullscreen",
                                    "paste",
                                    "preview",
                                    "tinydrive",
                                    "quickbars",
                                    "spellchecker",
                                    "save",
                                  ],
                                  toolbar:
                                    "undo redo | aidialog aishortcuts | blocks fontsizeinput | bold italic underline strikethrough superscript subscript | forecolor backcolor formatpainter removeformat | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link image media table pageembed | lineheight outdent indent | charmap emoticons checklist | code fullscreen preview | save print | pagebreak anchor codesample footnotes mergetags | addtemplate inserttemplate | addcomment showcomments | ltr rtl casechange | spellcheckdialog a11ycheck | chatButton",
                                  content_style:
                                    "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
                                  font_formats:
                                    "Arial=arial,helvetica,sans-serif; Courier New=courier new,courier,monospace; Georgia=georgia,palatino,serif; Tahoma=tahoma,arial,helvetica,sans-serif; Times New Roman=times new roman,times,serif; Verdana=verdana,geneva,sans-serif",
                                  fontsize_formats:
                                    "8pt 10pt 12pt 14pt 18pt 24pt 36pt",
                                  tinycomments_mode: "embedded",
                                  tinycomments_author: "Author name",
                                  mergetags_list: [
                                    {
                                      value: "First.Name",
                                      title: "First Name",
                                    },
                                    { value: "Email", title: "Email" },
                                  ],
                                  ai_request: (request, respondWith) =>
                                    respondWith.string(() =>
                                      Promise.reject(
                                        "See docs to implement AI Assistant"
                                      )
                                    ),
                                  image_advtab: true,
                                  typography_rules: [
                                    "common/punctuation/quote",
                                    "en-US/dash/main",
                                    "common/nbsp/afterParagraphMark",
                                    "common/nbsp/afterSectionMark",
                                    "common/nbsp/afterShortWord",
                                    "common/nbsp/beforeShortLastNumber",
                                    "common/nbsp/beforeShortLastWord",
                                    "common/nbsp/dpi",
                                    "common/punctuation/apostrophe",
                                    "common/space/delBeforePunctuation",
                                    "common/space/afterComma",
                                    "common/space/afterColon",
                                    "common/space/afterExclamationMark",
                                    "common/space/afterQuestionMark",
                                    "common/space/afterSemicolon",
                                    "common/space/beforeBracket",
                                    "common/space/bracket",
                                    "common/space/delBeforeDot",
                                    "common/space/squareBracket",
                                    "common/number/mathSigns",
                                    "common/number/times",
                                    "common/number/fraction",
                                    "common/symbols/arrow",
                                    "common/symbols/cf",
                                    "common/symbols/copy",
                                    "common/punctuation/delDoublePunctuation",
                                    "common/punctuation/hellip",
                                  ],
                                  typography_ignore: ["code"],
                                  toolbar_mode: "wrap",
                                  contextmenu: "link image imagetools table", // Add table to the context menu
                                  table_advtab: true, // Enable the "Advanced" tab in the table properties dialog
                                  table_appearance_options: true, // Enable appearance options like width, height, etc.
                                  table_default_attributes: {
                                    border: "1", // Set default border for tables
                                  },
                                  table_default_styles: {
                                    width: "100%", // Set default width for tables
                                  },
                                  typography_ignore: ["code"],

                                  // setup: function (editor) {
                                  //   // Add the chat button to the toolbar
                                  //   editor.ui.registry.addButton("chatButton", {
                                  //     text: "Chat",
                                  //     onAction: function () {
                                  //       const selectedText =
                                  //         editor.selection.getContent({
                                  //           format: "text",
                                  //         });
                                  //       if (selectedText) {
                                  //         launchChatWithText(selectedText); // Call the function with the selected text
                                  //       } else {
                                  //         alert(
                                  //           "Please select text to start a chat."
                                  //         );
                                  //       }
                                  //     },
                                  //   });

                                  //   // Add Ctrl+/ keyboard shortcut for the chat functionality
                                  //   editor.addShortcut(
                                  //     "ctrl+/",
                                  //     "Chat with selected text",
                                  //     function () {
                                  //       const selectedText =
                                  //         editor.selection.getContent({
                                  //           format: "text",
                                  //         });
                                  //       if (selectedText) {
                                  //         launchChatWithText(selectedText); // Call the function with the selected text
                                  //       } else {
                                  //         alert(
                                  //           "Please select text to start a chat."
                                  //         );
                                  //       }
                                  //     }
                                  //   );

                                  //   // Manually handling Ctrl + /
                                  //   editor.on("keydown", function (e) {
                                  //     if (e.ctrlKey && e.key === "/") {
                                  //       e.preventDefault(); // Prevent the default action
                                  //       const selectedText =
                                  //         editor.selection.getContent({
                                  //           format: "text",
                                  //         });
                                  //       if (selectedText) {
                                  //         launchChatWithText(selectedText); // Call the function with the selected text
                                  //       } else {
                                  //         alert(
                                  //           "Please select text to start a chat."
                                  //         );
                                  //       }
                                  //     }
                                  //   });
                                  // },

                                  toolbar_mode: "wrap",
                                  contextmenu: "link image imagetools table", // Add table to the context menu
                                  table_advtab: true, // Enable the "Advanced" tab in the table properties dialog
                                  table_appearance_options: true, // Enable appearance options like width, height, etc.
                                  table_default_attributes: {
                                    border: "1", // Set default border for tables
                                  },
                                  table_default_styles: {
                                    width: "100%", // Set default width for tables
                                  },
                                }}
                                onEditorChange={(content) => {
                                  setDocumentData(content);
                                }}
                              />
                            </div>
                            {/* <div */}

                            {/* > */}
                            {/* Icon that triggers the chat */}

                            {/* Conditionally render the QChat component */}

                            {/* </div> */}
                            <div className="flex justify-end">
                              <p className="error_msg">{errorMsgDefault}</p>
                            </div>

                            <div className="mt-5 flex justify-end">
                              <div className="flex items-center">
                                <div className="mt-5 mb-1 flex justify-end">
                                  {/* <div
                                    className="flex items-center mr-5"
                                    style={{ marginTop: "-22px" }}
                                  >
                                    <DocChatAI
                                      aiContent={aiContent}
                                      updateContent={() =>
                                        setAiContent(documentData)
                                      }
                                      className="btn pb-1 ml-1 mr-1 pt-0.5 pl-6 pr-6 text_c font-bold rounded-lg btn_theme3"
                                    />
                                  </div> */}
                                  {/* <div
                                    className="flex items-center mr-5"
                                    style={{ marginTop: "-22px" }}
                                  >
                                    <ClauseLibaryChat
                                      aiContent={aiContent}
                                      updateContent={() =>
                                        setAiContent(documentData)
                                      }
                                      className="btn pb-1 pt-0.5 pl-6 pr-6 text_c font-bold rounded-lg btn_theme3"
                                    />
                                  </div> */}
                                </div>
                                {versionList.length > 0 ? (
                                  <div>
                                    <input
                                      type="text"
                                      value={templateNameDefault}
                                      onChange={(e) =>
                                        setTemplateNameDefault(e.target.value)
                                      }
                                      className="border-2 border-primary rounded-lg outline-none p-1 pl-2"
                                      placeholder={t(
                                        "allModule.Save Template as"
                                      )}
                                      disabled
                                    />
                                    <input
                                      type="text"
                                      maxlength="100"
                                      value={templateRemarks}
                                      onChange={(e) =>
                                        setTemplateRemarks(e.target.value)
                                      }
                                      className="border-2 ml-2 border-primary rounded-lg outline-none p-1 pl-2"
                                      placeholder={t(
                                        "allModule.Remarks for this version"
                                      )}
                                    />
                                    <button
                                      onClick={(e) =>
                                        handleVersionTemplateUpdate()
                                      }
                                      className="btn pb-1 pt-0.5 pl-6 pr-6 text_c font-bold rounded-lg btn_theme3 ml-5"
                                    >
                                      {t("common.Update")}
                                    </button>
                                  </div>
                                ) : (
                                  <div className="mb-4 flex items-center">
                                    <input
                                      type="text"
                                      value={templateNameDefault}
                                      onChange={(e) =>
                                        setTemplateNameDefault(e.target.value)
                                      }
                                      className="border-2 border-primary rounded-lg outline-none p-1 pl-2"
                                      placeholder={t(
                                        "allModule.Save Template as"
                                      )}
                                    />
                                    <input
                                      type="text"
                                      maxLength="100"
                                      value={templateRemarks}
                                      onChange={(e) =>
                                        setTemplateRemarks(e.target.value)
                                      }
                                      className="border-2 ml-2 border-primary rounded-lg outline-none p-1 pl-2"
                                      placeholder={t(
                                        "allModule.Remarks for this version"
                                      )}
                                    />
                                    <button
                                      onClick={handleSaveTemplateDefault}
                                      className="btn pb-1 pt-0.5 pl-6 pr-6 text_c font-bold rounded-lg btn_theme3 ml-5"
                                    >
                                      {t("common.Save")}
                                    </button>
                                    <button
                                      onClick={toggleChat}
                                      className="btn pb-1 pt-0.5 pl-6 pr-6 text_c font-bold rounded-lg btn_theme3 ml-2 flex items-center justify-center"
                                      data-tip="AI Assistant and Document Chat"
                                    >
                                      <img
                                        src={
                                          isChatVisible ? aiOrange : aiPurple
                                        }
                                        alt="AI"
                                        className="tab-icon"
                                        style={{
                                          width: "40px",
                                          height: "24px",
                                        }}
                                      />
                                    </button>
                                  </div>
                                )}
                              </div>
                            </div>
                          </>
                        )}
                      </div>
                      <div className="col-md-3">
                        {isChatVisible ? (
                          <div
                            style={{
                              flex: "3",
                              display: "fixed",
                              height: "100%",
                              // padding: ,
                            }}
                          >
                            <QChat
                              documentData={documentData}
                              className="chat-window"
                            />
                          </div>
                        ) : versionList.length > 0 ? (
                          <div>
                            <h4 className="text-xl font-bold  mb-2 text_c ">
                              {t("allModule.Select Version")}
                            </h4>

                            <div className="template_list_container pad_0">
                              {loading == false &&
                                versionList.length > 0 &&
                                versionList.map((item, index) => {
                                  return (
                                    <div
                                      key={index}
                                      onClick={(e) => {
                                        handelVersionView(item);
                                      }}
                                      style={
                                        index === 0
                                          ? { border: "3px solid #310128" }
                                          : {}
                                      }
                                      className="template_box"
                                    >
                                      <div className="template_box_head">
                                        <h1 className="text-2xl font-bold text_c">
                                          {" "}
                                          {item.title} V_{item.version_no}{" "}
                                        </h1>
                                      </div>
                                      <div className="template_box_body">
                                        <strong>
                                          {t("allModule.Created on")}
                                        </strong>
                                        <span className="text-base font-normal text_c">
                                          {" "}
                                          {dayjs(
                                            item.version_created_at
                                          ).format("dddd, MMM D, YYYY HH:mm")}
                                        </span>
                                        <div>
                                          <strong>
                                            {t("allModule.Remarks")}
                                            {": "}
                                          </strong>

                                          <span className="text-base font-normal text_c">
                                            {item.remarks}
                                          </span>
                                        </div>
                                      </div>
                                      {versionList.length == 1 ? (
                                        <>
                                          <ReactTooltip
                                            backgroundColor="#400835"
                                            textColor="#ffffff"
                                          />
                                          <button
                                            className="btn btn_theme btn-sm mr-3 mt-10 wh_spab"
                                            onClick={(e) => {
                                              e.stopPropagation();
                                              handelTemplateversionDefault(
                                                item
                                              );
                                            }}
                                            disabled
                                            data-tip={t(
                                              "constant.Default Template"
                                            )}
                                          >
                                            <img
                                              src={DefaultIcon}
                                              className="ml-0.5"
                                              width={20}
                                              alt="Make Default Template"
                                            />
                                          </button>
                                          <button
                                            className="btn btn_theme btn-sm pl-4 ml-1 mr-3 mt-10 wh_spab "
                                            onClick={(e) => {
                                              e.stopPropagation();
                                              // setDeleteVersionId(item.id);
                                              // setIsDeleteVersionModalOpen(true);
                                            }}
                                            disabled
                                            data-tip={t(
                                              "constant.Default Template is not allowed to delete"
                                            )}
                                          >
                                            <img
                                              src={DeleteIcon}
                                              className="ml-0.5"
                                              width={20}
                                              alt="Delete Template Version"
                                            />
                                          </button>
                                        </>
                                      ) : (
                                        <>
                                          <ReactTooltip
                                            backgroundColor="#400835"
                                            textColor="#ffffff"
                                          />
                                          <button
                                            className="btn btn_theme btn-sm mr-3 mt-10 wh_spab"
                                            disabled={index === 0}
                                            onClick={(e) => {
                                              e.stopPropagation();
                                              handelTemplateversionDefault(
                                                item
                                              );
                                            }}
                                            data-tip={
                                              index === 0
                                                ? t("constant.Default Template")
                                                : t(
                                                    "constant.Make Default Template"
                                                  )
                                            }
                                          >
                                            <img
                                              src={DefaultIcon}
                                              className="ml-0.5"
                                              width={20}
                                              alt="Make Default Template"
                                            />
                                          </button>
                                          <button
                                            className="btn btn_theme btn-sm pl-4 ml-1 mr-3 mt-10 wh_spab "
                                            disabled={index === 0}
                                            onClick={(e) => {
                                              e.stopPropagation();
                                              setDeleteVersionId(item.id);
                                              setIsDeleteVersionModalOpen(true);
                                            }}
                                            data-tip={
                                              index === 0
                                                ? t(
                                                    "constant.Default Template is not allowed to delete"
                                                  )
                                                : t(
                                                    "constant.Delete Template Version"
                                                  )
                                            }
                                          >
                                            <img
                                              src={DeleteIcon}
                                              className="ml-0.5"
                                              width={20}
                                              alt="Delete Template Version"
                                            />
                                          </button>
                                        </>
                                      )}
                                    </div>
                                  );
                                })}

                              {loading == false &&
                                defaultTemplateList.length == 0 && (
                                  <div className="flex justify-center items-center h-96 flex-column w-full">
                                    <h3 className="text-2xl font-bold text_c">
                                      {t("allModule.No Template Found")}
                                    </h3>
                                  </div>
                                )}
                            </div>
                          </div>
                        ) : (
                          <div>
                            <h4 className="text-xl font-bold  mb-2 text_c ">
                              {t("allModule.Select Template")}
                            </h4>
                            <div className="seachbox mb-2">
                              <input
                                type="text"
                                className="border-2 border-primary rounded-lg outline-none p-1 pl-2 w-2/3"
                                placeholder={t("allModule.Search a Template")}
                                onChange={(e) => {
                                  setSearchDefault(e.target.value);
                                }}
                                onKeyUp={(e) => {
                                  if (
                                    e.key === "Enter" ||
                                    e.target.value === ""
                                  ) {
                                    getDefaultTemplateSearchListApi(search);
                                  }
                                }}
                                value={searchDefault}
                              />
                              <button
                                className="btn pb-1 pt-0.5 pl-6 pr-6 text_c font-bold rounded-lg btn_theme3"
                                onClick={(e) =>
                                  getDefaultTemplateSearchListApi(search)
                                }
                              >
                                {t("allModule.Search")}
                              </button>
                            </div>
                            <div className="template_list_container pad_0">
                              {loading == false &&
                                defaultTemplateList.length > 0 &&
                                defaultTemplateList.map((item, index) => {
                                  return (
                                    <>
                                      <ReactTooltip
                                        backgroundColor="#400835"
                                        textColor="#ffffff"
                                      />
                                      <div
                                        key={index}
                                        onClick={(e) => {
                                          handleDefaultTemplateClick(item);
                                          templateListFetch(item.id);
                                        }}
                                        className="template_box"
                                      >
                                        <div className="template_box_head">
                                          <h1 className="text-2xl font-bold text_c">
                                            {" "}
                                            {item.title}{" "}
                                          </h1>
                                        </div>
                                        <div className="template_box_body">
                                          <strong>
                                            {t("allModule.Created on")}
                                          </strong>
                                          <span className="text-base font-normal text_c">
                                            {" "}
                                            {dayjs(item.created_at).format(
                                              "dddd, MMM D, YYYY HH:mm"
                                            )}
                                          </span>
                                        </div>
                                        <button
                                          className="btn btn_theme btn-sm mr-1 mt-8 wh_spab "
                                          onClick={(e) => {
                                            e.stopPropagation();
                                            setDeleteTemplateId(item.id);
                                            setIsDeleteModalOpen(true);
                                          }}
                                          data-tip={t(
                                            "constant.Delete document"
                                          )}
                                        >
                                          <img
                                            src={DeleteIcon}
                                            className="ml-0.5"
                                            width={20}
                                            alt="Delete Document"
                                          />
                                        </button>
                                      </div>
                                    </>
                                  );
                                })}

                              {loading == false &&
                                defaultTemplateList.length == 0 && (
                                  <div className="flex justify-center items-center h-96 flex-column w-full">
                                    <h3 className="text-2xl font-bold text_c">
                                      {t("allModule.No Template Found")}
                                    </h3>
                                  </div>
                                )}
                            </div>
                          </div>
                        )}
                      </div>
                    </>
                  )}
                </>
              )}
            </div>
          </div>
        ) : (
          <div className="flex justify-center items-center h-96 flex-column ">
            <h3 className="text-2xl font-bold text_c">
              {t("allModule.You dont have permission to access this page")}
            </h3>
          </div>
        )}

        <ReactTooltip backgroundColor="#400835" textColor="#ffffff" />

        <DeleteModal
          title="Delete Template"
          bodyText="Are you sure you want to delete template?"
          isDeleteModalOpen={isDeleteModalOpen}
          setIsDeleteModalOpen={setIsDeleteModalOpen}
          deleteTemplate={deleteTemplate}
        />

        <DeleteModal
          title="Delete Template Version"
          bodyText="Are you sure you want to delete template version?"
          isDeleteModalOpen={isDeleteVersionModalOpen}
          setIsDeleteModalOpen={setIsDeleteVersionModalOpen}
          deleteVersion={deleteVersion}
        />

        <LegalConfirmModal
          title={t("allModule.Confirmation")}
          bodyText={t(
            "allModule.Please save your changes before moving out of this page!"
          )}
          isConfirmModalOpen={templateConfirmationOpen}
          setIsConfirmModalOpen={setTemplateConfirmationOpen}
          setTempSaveConfirmation={setTempSaveConfirmation}
          activeLink={activeLink}
          setPrevActiveLink={setPrevActiveLink}
          // deleteDocument={deleteDocument}
        />

        <TemplateNameModel
          isOpen={openTemplateNameModal}
          setIsOpen={setOpenTemplateNameModal}
          selectedTemplate={selectedTemplate}
          setIframeUrl={setIframeUrl}
          setType="legal template"
        />
      </div>
    </>
  );
};

export default CreateTemplate;
