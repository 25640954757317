import { createContext, useEffect, useState } from "react";
import useAxios from "../hooks/useAxios";
import { resolve } from "../api/resolve";
import { useNavigate } from "react-router-dom";
import { use } from "i18next";

const AppContext = createContext();

export default AppContext;

export const AppProvider = ({ children }) => {
  const api = useAxios();
  const navigate = useNavigate();
  const [activeLink, setActiveLink] = useState(
    localStorage.getItem("activeLink") || "Home"
  );
  const [activePrevLink, setPrevActiveLink] = useState("Home");
  const [showDropdown, setShowDropdown] = useState();
  const [companiesList, setCompaniesList] = useState([]);
  const [companiesListlegacy, setCompaniesListlegacy] = useState([]);
  const [contractsHeading, setContractsHeading] = useState("Search");
  const [isRepoList, setIsRepoList] = useState(false);
  const [repoName, setRepoName] = useState(null);
  const [repoID, setRepoID] = useState(null);
  const [sasToken, setSasToken] = useState({
    sas_token: "",
    expiry: "",
  });
  const [highlightToken, setHighlightToken] = useState({
    sas_token: "",
    expiry: "",
  });
  const [notificationCount, setNotificationCount] = useState(0);
  const [totalNotificationCount, setTotalNotificationCount] = useState(0);
  const [routedSearchID, setRoutedSearchID] = useState(null);
  const [contractTypes, setContractTypes] = useState([]);
  const [selectedContractType, setSelectedContractType] = useState(null);
  const [reviewSelection, setReviewSelection] = useState({
    file_url: "",
    doc_name: "",
    converted_file_url: "",
    id: "",
    contract_clause: "",
  });
  const [modelID, setModelID] = useState(null);
  let [language, setLanguage] = useState(() =>
    localStorage.getItem("i18nextLng")
      ? localStorage.getItem("i18nextLng")
      : "en"
  );
  const langObj = {
    en: { nativeName: "English" },
    fr: { nativeName: "Français" },
    ar: { nativeName: "العربية" },
    hi: { nativeName: "हिन्दी" },
    it: { nativeName: "Italiano" },
    de: { nativeName: "Deutsch" },
    es: { nativeName: "Español" },
    tr: { nativeName: "Türkçe" }
};
  const [adminId, setAdminId] = useState(() =>
    localStorage.getItem("adminId") ? localStorage.getItem("adminId") : 0
  );
  const [rowInfo, setRowInfo] = useState(() =>
    localStorage.getItem("rowInfo")
      ? JSON.parse(localStorage.getItem("rowInfo"))
      : {}
  );
  const [tempSaveConfirmation, setTempSaveConfirmation] = useState(false);
  const [templateConfirmationOpen, setTemplateConfirmationOpen] =
    useState(false);

  const [diligenceConfirmationOpen, setDiligenceConfirmationOpen] =
    useState(false);

  const getCompanies = async () => {
    const res = await resolve(api.get(`/company/all/`).then((res) => res.data));
    if (res.data) {
      const { results } = res.data;
      const filterData = [];
      if (results.length > 0) {
        results.forEach((result) => {
          filterData.push({
            value: result.name,
            label: result.name,
            id: result.id,
            company_name: result.name,
          });
        });
      }
      setCompaniesList([...filterData]);
    }
  };

  const getCompanieslegacy = async () => {
    const res = await resolve(
      api.get(`/legacy_company/all/`).then((res) => res.data)
    );
    if (res.data) {
      const { results } = res.data;
      const filterData = [];
      if (results.length > 0) {
        results.forEach((result) => {
          filterData.push({
            value: result.name,
            label: result.name,
            id: result.id,
            company_name: result.name,
          });
        });
      }
      setCompaniesListlegacy([...filterData]);
    }
  };

  const getSasToken = async () => {
    const res = await resolve(
      api.get(`/contract/get_sas_token/`).then((res) => res.data)
    );
    if (res.data) {
      const { sas_token, expiry } = res.data;
      setSasToken({ sas_token, expiry });
    }
  };
  const getHighlightSasToken = async () => {
    const res = await resolve(
      api.get(`/contract/get_sas_token_highlight/`).then((res) => res.data)
    );
    if (res.data) {
      const { sas_token, expiry } = res.data;
      setHighlightToken({ sas_token, expiry });
    }
  };

  const getNotificationsCount = async () => {
    const res = await resolve(
      api.get(`notification/update_is_seen/0`).then((res) => res.data)
    );
    const res2 = await resolve(
      api.get(`legacy_notification/update_is_seen/0`).then((res) => res.data)
    );
    if (res.data) {
      const { notif_count, total_notif_count } = res.data;

      if (res2.data) {
        const {
          notif_count: notif_count2,
          total_notif_count: total_notif_count2,
        } = res2.data;

        // Combine notification counts from both responses
        const combinedNotifCount =
          (!isNaN(notif_count) ? notif_count : 0) +
          (!isNaN(notif_count2) ? notif_count2 : 0);
        const combinedTotalNotifCount =
          (!isNaN(total_notif_count) ? total_notif_count : 0) +
          (!isNaN(total_notif_count2) ? total_notif_count2 : 0);

        // Set the combined counts
        setNotificationCount(combinedNotifCount);
        setTotalNotificationCount(combinedTotalNotifCount);
      } else {
        // If res2.data is not available, set notification counts from res.data
        setNotificationCount(notif_count || 0);
        setTotalNotificationCount(total_notif_count || 0);
      }
    }
  };

  const getNotificationsCountlegacy = async () => {
    const res = await resolve(
      api.get(`legacy_notification/update_is_seen/0`).then((res) => res.data)
    );
    if (res.data) {
      const { notif_count, total_notif_count } = res.data;
      setNotificationCount(notif_count);
      setTotalNotificationCount(total_notif_count);
    }
  };

  function clickOnLeftMenu() {
    localStorage.setItem("searchText", "");
  }

  useEffect(() => {
    localStorage.setItem("activePrevLink", activePrevLink);
    if (
      (activePrevLink == "CreateTemplateLibrary" &&
        tempSaveConfirmation === true) ||
      (activePrevLink == "Qflow" && tempSaveConfirmation === true) ||
      (activePrevLink == "CreateTemplate" && tempSaveConfirmation === true) ||
      (activePrevLink == "GenerateDocument" && tempSaveConfirmation === true)
    ) {
      if (activeLink !== "Qflow") {
        setTemplateConfirmationOpen(true);
      }
      if (activeLink !== "CreateTemplateLibrary") {
        setTemplateConfirmationOpen(true);
      }
      if (activeLink !== "CreateTemplate") {
        setTemplateConfirmationOpen(true);
      }
      if (activeLink !== "GenerateDocument") {
        setTemplateConfirmationOpen(true);
      }
    } else {
      localStorage.setItem("activeLink", activeLink);
      const pathname = window.location.pathname;
      const isNavigate =
        pathname.includes("/document-preview/") ||
        pathname.includes("/workflow/") ||
        pathname.includes("/aadhar-signature/") ||
        pathname.includes("/qr-signature/");
      if (!isNavigate) {
        if (activeLink == "Workflows") {
          navigate("/apply-signature/select-workflows");
        } else if (activeLink == "StampsAndSeals") {
          navigate("/stamps-seals");
        } else if (activeLink == "AutoMagic") {
          navigate("/stamps-seals/automagic");
        } else if (activeLink == "Presets") {
          navigate("/stamps-seals/presets");
        } else if (activeLink == "ManualMode") {
          navigate("/stamps-seals/manual-mode");
        } else if (activeLink == "AttachStampPaper") {
          navigate("/stamps-seals/attach-stamp-paper");
        } else if (activeLink == "EmailNotifications") {
          navigate("/stamps-seals/email-notifications");
        } else if (activeLink == "Searchlegacy") {
          navigate("/Searchlegacy");
        } else if (activeLink == "Searchnormal") {
          navigate("/Searchnormal");
        }
      }
      setPrevActiveLink(activeLink);
    }
  }, [activeLink]);

  useEffect(() => {
    getCompanies();
    getCompanieslegacy();
    getSasToken();
  }, []);

  const contextData = {
    activeLink,
    setActiveLink,
    activePrevLink,
    setPrevActiveLink,
    setTempSaveConfirmation,
    templateConfirmationOpen,
    setTemplateConfirmationOpen,

    diligenceConfirmationOpen,
    setDiligenceConfirmationOpen,

    companiesList,
    getCompanies,

    companiesListlegacy,
    getCompanieslegacy,
    repoName,
    setRepoName,
    repoID,
    setRepoID,
    contractsHeading,
    setContractsHeading,
    isRepoList,
    setIsRepoList,
    sasToken,
    setSasToken,
    getSasToken,
    notificationCount,
    getNotificationsCount,
    getNotificationsCountlegacy,
    setRoutedSearchID,
    routedSearchID,
    getHighlightSasToken,
    setHighlightToken,
    highlightToken,
    setNotificationCount,
    totalNotificationCount,
    contractTypes,
    setContractTypes,
    selectedContractType,
    setSelectedContractType,
    reviewSelection,
    setReviewSelection,
    modelID,
    setModelID,
    showDropdown,
    setShowDropdown,

    adminId,
    setAdminId,

    language,
    setLanguage,
    langObj,

    setRowInfo,
    rowInfo,
    clickOnLeftMenu,
  };

  return (
    <AppContext.Provider value={contextData}>{children}</AppContext.Provider>
  );
};
