import { useEffect, useContext } from "react";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import useAxios from "../../hooks/useAxios";
import { resolve } from "../../api/resolve";
import AuthContext from "../../context/AuthContext";
import jwt_decode from 'jwt-decode';
import PermissionContext from "../../context/PermissionContext";
import AppContext from "../../context/AppContext";
import { useTranslation } from 'react-i18next';

const PaymentSuccess = () => {
    const { plan_id, session_id } = useParams();
    const { setUser, user } = useContext(AuthContext);
    const { updatePermissionList } = useContext(PermissionContext);
    const { setActiveLink } = useContext(AppContext);    

    const api = useAxios();
    const { t } = useTranslation();
    const navigation = useNavigate();

    const paymentSuccess = async () => {
        const postData = {
            plan_id: plan_id,
            session: session_id
        }
        const responceData = await resolve(
            api.post('/user/payment/sucess/', postData).then((res) => res.data)
        );

        if (responceData.data) {
            if(responceData?.data?.message && responceData?.data?.message === 'payment sucess'){
                setTimeout(() => {
                    navigation('/dashboard');
                }, 2000);
    
                toast.success(t('form.Payment Success'));
            }else{
                const userData = jwt_decode(responceData['data'].data.access);
                setUser(userData);
                localStorage.setItem('authTokens', JSON.stringify(responceData['data'].data));
                updatePermissionList(responceData['data'].User_permission);
                setTimeout(() => {
                    navigation('/dashboard');
                }, 2000);
    
                toast.success(t('form.Payment Success'));
            }            
        }
       
        if (responceData.error?.response?.data?.message) {
            toast.error(responceData.error.response.data.message);
        }else if(responceData.error){
            toast.error(t('common.Something went wrong'));
        }
    }

    useEffect(() => {
        if (plan_id && session_id) {
            paymentSuccess();
            // toast.success('Payment Success');

        } else {
            toast.error(t('common.Something went wrong'));
        }


    }, [])

    return (
        <div className="flex justify-center items-center h-screen">
            <div className="text-center">
                <h1 className="text-2xl font-bold">{t('form.Thanks for your order')}!</h1>
                <p className="text-lg">{t('form.We appreciate your business')}!<br />
                    {t('form.If you have any questions, please email')} <a href="mailto:support@quoqo.com">support@quoqo.com</a>.</p>
                <button onClick={(e) => {
                    e.stopPropagation();
                    setActiveLink('Home')
                    navigation('/dashboard');
                }}
                    className="btn btn_theme btn-sm mt-5"
                >{t('form.Back to Quoqo Fusion')}</button>
            </div>
        </div>

    )
}

export default PaymentSuccess;