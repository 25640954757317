import React, { useState, useContext } from 'react';
import { useTable } from 'react-table';
import '../contracts/contracts.css';
import DownArrow from '../../assets/icons/down-arrow.svg';
import ReactTooltip from 'react-tooltip';
import FormContext from '../../context/FormContext';
import AppContext from '../../context/AppContext';
import AuthContext from '../../context/AuthContext';
import EditIcon from '../../assets/icons/Edit.svg';
import DeleteIcon from '../../assets/icons/Delete.svg';
import Duplicate from '../../assets/icons/Duplicate.svg';
import DeleteModal from '../modals/DeleteModal';
import { resolve } from '../../api/resolve';
import useAxios from '../../hooks/useAxios';
import { autoCloseTimer, delay } from '../../constants/constants';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export const NoDataComponent = ({ colLength }) => {
  const [rows] = useState(Array(4).fill(''));
  const [cols] = useState(Array(colLength).fill(''));
  return (
    <>
      {rows.map((data, index) => (
        <tr key={`group_ndf_row_${index}`}>
          {cols.map((data, index01) => (
            <td key={`group_ndf_td_${index01}`} className='px-5 py-2 font-medium text-smW'>--</td>
          ))}
        </tr>
      ))}
    </>
  );
};

const GroupTable = ({
  contractsData,
  contactTableColumns,
  prevPage,
  nextPage,
  sortingOrder,
  setSortingOrder,
  setSortColumn,
  showActionBtn,
  showPageLimit,
  getGroupList,
  groupCurrentPage,
  setGroupCurrentPage,
  groupNextBtnLimit,
  groupCount,
  pageLimit,
  setGroupPageLimit
}) => {
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({ columns: contactTableColumns, data: contractsData });
  const { setEditFormData, setIsEditing } = useContext(FormContext);
  const [currentRow, setCurrentRow] = useState(0);
  const [groupId, setGroupId] = useState(0);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const { setActiveLink, reviewSelection, setReviewSelection } =
    useContext(AppContext);
  const { user } = useContext(AuthContext);

  const api = useAxios();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const deleteGroup = async () => {
    const res = await resolve(
      api.post(`/user/contact/group/delete/${groupId}`, { IsDelete: true })
    );
    if (res.data) {
      getGroupList({ groupId: null, isPrimary: true });
    }
    setIsDeleteModalOpen(false);
  };

  const handleEditGroup = async (groupInfo) => {

    const responceData = await resolve(
      api.get(`/user/contact/group/member_list/${groupInfo.id}`)
    );

    if (responceData['data']) {
      let contactList = responceData['data'].data;
      let tempContactList = [];

      contactList.forEach((contactInfo, index) => {
        tempContactList.push(contactInfo.group_member_id)
      });
      groupInfo.contactList = tempContactList
      navigate('/contact-book/edit-group')
      setEditFormData(groupInfo);
      setIsEditing(true);
      setActiveLink('GroupForm');
    }
  }

  const duplicateGroup = async (groupId) => {
    const res = await resolve(
      api.get(`user/contact/duplicate/${groupId}`)
    );
    if (res.data) {
      toast.success(t('allModule.Duplicate group successfully!'), {
        delay,
        autoClose: autoCloseTimer,
      });
      getGroupList({ groupId: null, isPrimary: true });
    }

    if (res.error) {
      toast.error(res.error.response.data['message'], {
        delay,
        autoClose: autoCloseTimer,
      });
    }
  };

  return (
    <>
      {contractsData.length > 0 && (
        <div
          className={`mt-3 flex ${showPageLimit ? 'justify-between' : 'justify-end'
            }`}
        >
          {showPageLimit && (
            <div>
              {groupCount > 5 && (
                <>
                  <span>{t('constant.Show')}</span>
                  <select
                    className='border-2 border-primary outline-none mx-1 rounded-md cursor-pointer btn-sm'
                    onChange={(e) => {
                      setGroupCurrentPage(1)
                      setGroupPageLimit(e.target.value)
                  }}
                    value={pageLimit}
                  >
                    <option>5</option>

                    {groupCount >= 6 && <option>6</option>}
                    {groupCount >= 10 && <option>10</option>}
                    {groupCount >= 15 && <option>15</option>}
                    {groupCount >= 25 && <option>25</option>}
                  </select>
                  <span>{t('common.Results')}</span>
                </>
              )}
            </div>
          )}
          <div className='flex text-primary  border-t-2 border-b-2 border-primary rounded-lg'>
            <button
              className={`pl-5 pr-3 border-r-2 border-l-2 border-primary font-semibold rounded-tl-lg rounded-bl-lg ${groupCurrentPage <= 1 && 'bg-inputBackground'
                }`}
              disabled={groupCurrentPage <= 1}
              onClick={(e) => {
                if (groupCurrentPage > 1) {
                  setGroupCurrentPage(groupCurrentPage - 1)
                }
              }}
            >
              {t('common.Prev')}
            </button>
            <span className='px-2 font-semibold'>{groupCurrentPage}</span>
            <button
              className={`pr-5 pl-3 border-l-2 border-r-2 border-primary font-semibold rounded-tr-lg rounded-br-lg ${groupCurrentPage >= groupNextBtnLimit && 'bg-inputBackground'
                }`}
              disabled={groupCurrentPage >= groupNextBtnLimit}
              onClick={(e) => {
                if (groupCurrentPage < groupNextBtnLimit) {
                  setGroupCurrentPage(groupCurrentPage + 1)
                }
              }}
            >
              {t('common.Next')}
            </button>
          </div>
        </div>
      )}
      <div className='mt-2 flex flex-col table-container mb-5'>
        <div>
          <div className='table_desin'>
            
            <div style={{maxHeight:'60vh'}} className='shadow overflow-hidden border-2 border-primary  sm:rounded-lg overflow-y-auto'>
              <table {...getTableProps()} className='w-full '>
                <thead>
                  {headerGroups.map((headerGroup) => (
                    <tr
                      className={`cursor-pointer bg-[#F6C107]`}
                      {...headerGroup.getHeaderGroupProps()}>
                      {headerGroup.headers.map((column) => (
                        // Add the sorting props to control sorting. For this example
                        // we can add them into the header props

                        <th
                          scope='col'
                          className='text-left px-2 py-2'
                          {...column
                            .getHeaderProps
                            // column.getSortByToggleProps()
                            ()}
                        >
                          <div className='flex items-center'>
                          <span>{t('constant.' + column.render('Header'))}</span>
                            {/* Add a sort direction indicator */}
                            {sortingOrder === 'desc' ? (
                              <span
                                onClick={(e) => {
                                  setSortingOrder('asc');
                                  setSortColumn(column.id);
                                }}
                                style={{ cursor: 'pointer', marginTop: 2 }}
                                className='ml-1'
                              >
                                {/* &#11165; */}
                                <img src={DownArrow} />
                              </span>
                            ) : (
                              <span
                                onClick={(e) => {
                                  setSortingOrder('desc');
                                  setSortColumn(`-${column.id}`);
                                }}
                                style={{
                                  cursor: 'pointer',
                                  transform: 'rotate(180deg)',
                                }}
                                className='ml-1'
                              >
                                <img src={DownArrow} />
                              </span>
                            )}
                          </div>
                        </th>
                      ))}
                      {showActionBtn && (
                        <th scope='col' className='text-left px-4 pt-2'>
                          {t('common.Action')}
                        </th>
                      )}
                    </tr>
                  ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                  {contractsData.length < 1 && (
                    <NoDataComponent colLength={contactTableColumns.length} />
                  )}
                  {contractsData.length > 0 &&
                    rows.map((row, i) => {
                      // new
                      prepareRow(row);
                      return (
                        <tr

                          {...row.getRowProps()}
                          onClick={() => {
                            setCurrentRow(i);
                          }}

                        >
                          {row.cells.map((cell, row_cell_index) => {
                            return (

                              <td
                                {...cell.getCellProps()}
                                className={`px-2 py-2 font-medium text-sm contracts-col-data ${cell.value === 'In-Active' && 'text-red-500'
                                  }`}
                              >
                                <span
                                >
                                  {cell.render('Cell')}
                                </span>
                              </td>

                            );
                          })}
                          {showActionBtn && (
                            <td className='py-2 flex'>
                              <button className='bg-secondary px-1 w-20 py-1 rounded-md flex justify-between text-white'>
                                <img
                                  src={EditIcon}
                                  width={20}
                                  data-tip={t('allModule.Edit Group')}
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    handleEditGroup(row.original)
                                    // setEditFormData(row.original);
                                    // setIsEditing(true);
                                    // setActiveLink('GroupForm');
                                  }}
                                />

                                <img
                                  src={Duplicate}
                                  width={20}
                                  data-tip={t('allModule.Duplicate Group')}
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    setGroupId(row.original.id)
                                    duplicateGroup(row.original.id);
                                  }}
                                />

                                <img
                                  src={DeleteIcon}
                                  data-tip={t('allModule.Delete Group')}
                                  className='ml-0.5'
                                  width={20}
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    setGroupId(row.original.id)
                                    setIsDeleteModalOpen(true);

                                  }}
                                />
                                <ReactTooltip
                                  backgroundColor="#400835"
                                  textColor="#ffffff"
                                />
                              </button>
                            </td>
                          )}
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <DeleteModal
        title={t('allModule.Delete Group')}
        bodyText={t('allModule.This action cannot be undone. Are you sure you want to continue?')}
        isDeleteModalOpen={isDeleteModalOpen}
        setIsDeleteModalOpen={setIsDeleteModalOpen}
        deleteGroup={deleteGroup}
      />
    </>
  );
};

export default GroupTable;
