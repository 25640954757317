import { createContext, useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import axios from 'axios';
import jwt_decode from 'jwt-decode';
import { useNavigate } from 'react-router-dom';
import { autoCloseTimer, delay } from '../constants/constants';
import { useTranslation } from 'react-i18next';

const AuthContext = createContext();

export default AuthContext;

export const AuthProvider = ({ children }) => {
  let [authTokens, setAuthTokens] = useState(() =>
    localStorage.getItem('authTokens')
      ? JSON.parse(localStorage.getItem('authTokens'))
      : null
  );
  let [user, setUser] = useState(() =>
    localStorage.getItem('authTokens')
      ? jwt_decode(JSON.parse(localStorage.getItem('authTokens')).access)
      : null
  );

  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { t } = useTranslation();

  const loginUser = async (email, password) => {
    setLoading(true);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_LEGACY_BACKEND_BASE_URL}/api/token/`,
        JSON.stringify({
          email,
          password,
        }),
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );
      const data = await response.data;
      setAuthTokens(data);
      setUser(jwt_decode(data.access));
      localStorage.setItem('authTokens', JSON.stringify(data));
      setLoading(false);
      navigate('/dashboard');
    } catch (error) {
      let data;
      if (error && error.response) data = error.response.data;

      console.log('data?.detail',data?.detail);
      if (
        data?.detail === 'No active account found with the given credentials'
      ) {
        toast.error(t('loginPage.Invalid Credentials'), {
          delay,
          autoClose: autoCloseTimer,
          onClose: () => setLoading(false),
        });
      } else if (
        data?.detail === 'Subscription expired please contact system admin'
      ) {
        toast.error(t('loginPage.Contract Expired'), {
          delay,
          autoClose: autoCloseTimer,
          onClose: () => setLoading(false),
        });
      }else if (
        data?.detail === 'Access to our services is restricted due to an expired subscription. Contact support for assistance.'
      ) {
        toast.error(t('loginPage.Access to our services is restricted due to an expired subscription. Contact support for assistance.'), {
          delay,
          autoClose: autoCloseTimer,
          onClose: () => setLoading(false),
        });
      }else if (
        data?.code === 'ALREADY_LOGGED_IN'
      ) {
        toast.error(t('loginPage.Logout from other device and try again'), {
          delay,
          autoClose: autoCloseTimer,
          onClose: () => setLoading(false),
        });
      } else {
        toast.error(t('common.Something went wrong'), {
          delay,
          autoClose: autoCloseTimer,
          onClose: () => setLoading(false),
        });
      }
    }
  };

  const logoutUser = async () => {
    try {
      await axios.get(
        `${process.env.REACT_APP_LEGACY_BACKEND_BASE_URL}/user/logout/${user.user_id}`
      );
      setAuthTokens(null);
      setUser(null);
      localStorage.removeItem('authTokens');
      localStorage.removeItem('activeLink');
      navigate('/');
    } catch (error) {
      setAuthTokens(null);
      setUser(null);
      localStorage.removeItem('authTokens');
      localStorage.removeItem('activeLink');
      navigate('/');
    }
  };

  const sendPasswordResetLink = async (email) => {
    setLoading(true);
    try {
      await axios.post(
        `${process.env.REACT_APP_LEGACY_BACKEND_BASE_URL}/user/forgot_password/`,
        JSON.stringify({
          email,
        }),
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );
      toast.success(t('loginPage.Email Sent'), {
        delay,
        autoClose: autoCloseTimer,
        onClose: () => setLoading(false),
      });
    } catch (error) {
      let data;
      if (error && error.response) data = error.response.data;
      if (data?.error === 'Email does not exist') {
        toast.success( t('loginPage.Email Sent'), {
          delay,
          autoClose: autoCloseTimer,
          onClose: () => setLoading(false),
        });
      } else {
        toast.error(t('common.Something went wrong'), {
          delay,
          autoClose: autoCloseTimer,
          onClose: () => setLoading(false),
        });
      }
    }
  };

  const resetPassword = async (userObj) => {
    setLoading(true);
    try {
      await axios.post(
        `${process.env.REACT_APP_LEGACY_BACKEND_BASE_URL}/user/reset_password/`,
        JSON.stringify({
          ...userObj,
        }),
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );
      toast.success(
        <>
          <span>{t('common.Sucesss')}</span>
          <p>{t('loginPage.Redirecting to login')}...</p>
        </>,
        {
          delay,
          autoClose: autoCloseTimer,
          onClose: () => {
            setLoading(false);
            navigate('/');
          },
        }
      );
    } catch (error) {
      let data;
      if (error && error.response) data = error.response.data;
      if (data?.error === 'Token Expired') {
        toast.error(
          <>
            <span>{t('loginPage.Link Expired')}</span>
            <p>{t('loginPage.Redirecting to new link page')}</p>
          </>,
          {
            delay,
            autoClose: autoCloseTimer,
            onClose: () => {
              setLoading(false);
              navigate('/forgot-password');
            },
          }
        );
      } else {
        toast.error(t('common.Something went wrong'), {
          delay,
          autoClose: autoCloseTimer,
          onClose: () => setLoading(false),
        });
      }
    }
  };

  const contextData = {
    user,
    authTokens,
    setAuthTokens,
    setUser,
    loginUser,
    logoutUser,
    loading,
    sendPasswordResetLink,
    resetPassword,
  };

  useEffect(() => {
    if (authTokens) {
      setUser(jwt_decode(authTokens.access));
    }
  }, [authTokens]);

  return (
    <AuthContext.Provider value={contextData}>{children}</AuthContext.Provider>
  );
};
