import React, { useEffect, useContext, useState } from 'react';
import Switch from 'react-switch';
import dayjs from 'dayjs';
import './companyForm.css';
import FormContextlegacy from '../../contextlegacy/FormContextlegacy';
import FormContext from '../../context/FormContext';
import AppContext from '../../context/AppContext';
import AppContextlegacy from '../../contextlegacy/AppContextlegacy';
import AppModal from '../modallegacy/Modallegacy';
import DatePickerComponent from '../../shared/DatePickerComponent';
import CutomPhoneInput from './PhoneInputlegacy';
import { useTranslation } from 'react-i18next';

const CompanyFormlegacy = () => {
  const { t } = useTranslation();
  const {
    setCurrentForm,
    saveOrEditCompanylegacy,
    companyFormErrors,
    modalData,
    setIsOpen,
    modalIsOpen,
    isEditing,
    editFormData,
    setEditFormData,
    setIsEditing,
    setCompanyFormErrors,
    shouldRedirect,
  } = useContext(FormContext);
  const { setActiveLink } = useContext(AppContext);
  const [startDate, setStartDate] = useState(
    isEditing && editFormData?.renew_date && new Date(editFormData?.renew_date)
  );
  const [companyObj, setCompanyObj] = useState({
    name: '',
    phone: '',
    address: '',
    is_active: undefined,
    renew_date: '',
  });
  const [isActiveUser, setIsActiveUser] = useState(editFormData?.is_active);
  useEffect(() => {
    setCompanyFormErrors({
      ...companyFormErrors,
      name: '',
      phone: '',
      address: '',
      renew_date: '',
    });
    setCurrentForm('CompanyFormlegacy');
    return () => setCurrentForm('');
  }, []);
  useEffect(() => {
    return () => {
      if (isEditing) {
        setEditFormData('');
        setIsEditing(false);
      }
    };
  }, [isEditing]);
  useEffect(() => {
    if (isEditing) {
      setEditFormData({
        ...editFormData,
        is_active: isActiveUser,
        renew_date: dayjs(startDate).format('YYYY-MM-DD'),
      });
    } else {
      setCompanyObj({
        ...companyObj,
        renew_date: startDate ? dayjs(startDate).format('YYYY-MM-DD') : '',
      });
    }
  }, [isActiveUser, startDate]);
  return (
    <div className='text-primary text-base font-normal font-sans'>
      <h1 className='text-4xl font-bold'>
        {!isEditing ? t('form.Add New Company') : t('form.Edit Company Information')}
      </h1>
      <div className=' w-2/5 mt-10'>
        <form>
          <div className='flex flex-col mb-3'>
            <label>{t('form.Company Name')}</label>
            <input
              placeholder={t('form.Company Name')}
              className='border-2 border-primary rounded-lg outline-none p-1 pl-2'
              value={!isEditing ? companyObj.name : editFormData.name}
              onChange={(e) => {
                !isEditing
                  ? setCompanyObj({ ...companyObj, name: e.target.value })
                  : setEditFormData({
                      ...editFormData,
                      name: e.target.value,
                    });
              }}
            />
            <p className='text-sm text-red-500 font-normal'>
              {companyFormErrors.name}
            </p>
          </div>
          <div className='flex flex-col mb-3'>
            <label>{t('form.Phone')}</label>
            {/* <input
              placeholder='Phone Number'
              maxLength={10}
              className='border-2 border-primary rounded-lg outline-none p-1 pl-2'
              value={!isEditing ? companyObj.phone : editFormData.phone}
              onChange={(e) => {
                !isEditing
                  ? setCompanyObj({ ...companyObj, phone: e.target.value })
                  : setEditFormData({
                      ...editFormData,
                      phone: e.target.value,
                    });
              }}
            /> */}
            <CutomPhoneInput
              value={!isEditing ? companyObj.phone : editFormData.phone}
              onChange={(phone) => {
                !isEditing
                  ? setCompanyObj({ ...companyObj, phone })
                  : setEditFormData({
                      ...editFormData,
                      phone,
                    });
              }}
            />
            <p className='text-sm text-red-500 font-normal'>
              {companyFormErrors.phone}
            </p>
          </div>
          <div className='flex flex-col mb-3'>
            <label>{t('form.Address')}</label>
            <textarea
              placeholder={t('form.Address')}
              className='border-2 border-primary rounded-lg outline-none p-1 pl-2'
              value={!isEditing ? companyObj.address : editFormData.address}
              onChange={(e) => {
                !isEditing
                  ? setCompanyObj({ ...companyObj, address: e.target.value })
                  : setEditFormData({
                      ...editFormData,
                      address: e.target.value,
                    });
              }}
            />
            <p className='text-sm text-red-500 font-normal'>
              {companyFormErrors.address}
            </p>
          </div>
          <div className='flex flex-col mb-3 company-date-container'>
            <span>{t('form.Renewal Date')}</span>
            <DatePickerComponent
              startDate={startDate && startDate}
              setStartDate={setStartDate}
              placeholderText={t('form.Click to select renewal date')}
            />
            <p className='text-sm text-red-500 font-normal'>
              {companyFormErrors.renew_date}
            </p>
          </div>
          {isEditing && (
            <div className='flex justify-between items-center mb-3'>
              <span>
                {t('form.Status')} :{' '}
                <span
                  className={`${
                    isActiveUser ? 'text-primary' : 'text-red-500'
                  } font-medium`}
                >
                  {isActiveUser ? 'Active' : 'In-Active'}
                </span>
              </span>
              <Switch
                offColor='#E0720C'
                onColor='#400835'
                name='Active'
                checked={isActiveUser}
                height={20}
                boxShadow='none'
                onChange={() => setIsActiveUser(!isActiveUser)}
              />
            </div>
          )}

          {!isEditing ? (
            <button
              className='bg-primary font-bold text-white w-full p-1 rounded-lg'
              onClick={(e) => {
                e.preventDefault();
                saveOrEditCompanylegacy(companyObj);
              }}
            >
              {t('form.Save Company')}
            </button>
          ) : (
            <button
              className='bg-secondary font-bold text-white w-full p-1 rounded-lg'
              onClick={(e) => {
                e.preventDefault();
                saveOrEditCompanylegacy(editFormData);
              }}
            >
              {t('form.Update Company')}
            </button>
          )}
        </form>
      </div>
      <AppModal
        title={modalData.title}
        bodyText={modalData.bodyText}
        setIsOpen={setIsOpen}
        modalIsOpen={modalIsOpen}
        setActiveLink={setActiveLink}
        activeLink={shouldRedirect && 'Companies'}
      />
    </div>
  );
};

export default CompanyFormlegacy;
