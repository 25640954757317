import { useEffect, useRef, useState } from "react";
import { resolve } from "../../api/resolve";
import axios from "axios";
import { toast } from "react-toastify";
import LoadingScreen from "../loader/LoadingScreen";
import { useContext } from "react";
import DocumentContext from "../../context/DocumentContext";
import { useParams } from "react-router-dom";
import { use } from "i18next";
import { useTranslation } from 'react-i18next';

const AadharSignature = () => {
  const formRef = useRef(null);
  const [signingXml, setSigningXml] = useState(``);
  const [loading, setLoading] = useState(false);
  const [isSubmitForm, setIsSubmitForm] = useState(false);
  const [aadharSignatureInfo, setAadharSignatureInfo] = useState({
    location: "",
    reason: "",
  });
  const [aadharFormError, setAadharFormError] = useState({
    location: "",
    reason: "",
  });

  const baseURL = process.env.REACT_APP_BACKEND_BASE_URL;
  const { recptId } = useParams();
  const { t } = useTranslation();

  const getDocumentInfoApi = async (recptId) => {
    setLoading(true);
    const responceData = await resolve(
      axios
        .post(`${baseURL}/contract/document/email/sign/`, {
          recpt_id: parseInt(recptId),
          final: false,
        })
        .then((res) => res.data)
    );

    if (responceData["data"]) {
      if (
        responceData["data"].type_of_signature == "Aadhaar_Digital_Signature"
      ) {
        responceData["data"].AadhaarDigitalSignature[0].location = "";
        responceData["data"].AadhaarDigitalSignature[0].reason = "";
        setAadharSignatureInfo(responceData["data"].AadhaarDigitalSignature[0]);
      }
    }

    if (responceData["error"]) {
    }
    setLoading(false);
  };

  const getAuthToken = async () => {
    const responseData = await resolve(
      axios.get(`${baseURL}/user/microsoft/auth/token/`).then((res) => res.data)
    );

    return responseData;
  };

  const getSigningXml = async () => {
    const authTokenInfo = await getAuthToken();
    if (authTokenInfo["data"]) {
      const postData = {
        name: aadharSignatureInfo.name,
        location: aadharSignatureInfo.location,
        reason: aadharSignatureInfo.reason,
        authMode: aadharSignatureInfo.authMode,
        xCo_ordinates: aadharSignatureInfo.xCo_ordinate,
        yCo_ordinates: aadharSignatureInfo.yCo_ordinate,
        signHeight: aadharSignatureInfo.signHeight,
        signWidth: aadharSignatureInfo.signWidth,
        docId: aadharSignatureInfo.docId,
        stepId: aadharSignatureInfo.stepId,
        pageNumber: aadharSignatureInfo.pageNumber,
        pdfPassword: "",
        base64String: aadharSignatureInfo.base64String,
        pdfName: "temp.pdf",
      };

      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${authTokenInfo["data"].access_token}`,
      };
      const responceData = await resolve(
        axios
          .post(
            `${process.env.REACT_APP_AADHAR_SIGNATURE_URL}/esign/xml/`,
            postData,
            {
              headers: headers,
            }
          )
          .then((res) => res.data)
      );

      if (responceData["data"]) {
        setSigningXml(responceData["data"]);
        setIsSubmitForm(true);
      }

      if (responceData["error"]) {
        toast.error(t('form.Something went wrong with Aadhaar Signature'));
      }
    }
  };

  const handleSubmit = () => {
    const { location, reason } = aadharSignatureInfo;
    for (const key in aadharFormError) {
      if (aadharFormError.hasOwnProperty(key)) {
        aadharFormError[key] = "";
      }
    }
    if (location.trim().length < 1) {
      setAadharFormError({
        ...aadharFormError,
        location: t('form.Address is required.'),
      });
    } else if (reason.trim().length < 1) {
      setAadharFormError({
        ...aadharFormError,
        reason: t('form.Reason is required.'),
      });
    } else {
      getSigningXml();
      setAadharFormError({
        ...aadharFormError,
        location: "",
        reason: "",
      });
    }
  };

  useEffect(() => {
    if (isSubmitForm) {
        setLoading(true);
        formRef.current.submit()
    }
  }, [isSubmitForm]);


  useEffect(() => {
    getDocumentInfoApi(recptId);
  }, []);

  return (
    loading ? <LoadingScreen /> :
    <>
      <h1>{t('form.Aadhaar Authentication')}</h1>

      <label className="my-2 font-bold mr-2">{t('form.Address')}:</label>
      <input
        type="text"
        placeholder="Provide address as per aadhaar"
        className="border-2 border-primary rounded-md rounded-tl-lg rounded-bl-lg bg-inputBackground px-2 mr-2 w-full"
        value={aadharSignatureInfo.location}
        onChange={(e) => {
          setAadharSignatureInfo({
            ...aadharSignatureInfo,
            location: e.target.value,
          });
        }}
      />
      <p className="text-red-500 text-xs italic">{aadharFormError.location}</p>
      <label className="my-2 font-bold mr-2">{t('form.Reason')}:</label>
      <textarea
        onChange={(e) => {
          setAadharSignatureInfo({
            ...aadharSignatureInfo,
            reason: e.target.value,
          });
        }}
        className="border-2 border-primary rounded-lg outline-none p-0.5 pl-2  w-full"
        rows={4}
        placeholder="Reason for signing the document"
        value={aadharSignatureInfo.reason}
      ></textarea>
      <p className="text-red-500 text-xs italic">{aadharFormError.reason}</p>
      <p>
        <b>{t('form.NOTE')} : </b>{t('form.You will be redirected to NSDL Portal for OTP verification')}
      </p>
      <input
        style={{ cursor: "pointer" }}
        onClick={(e) => {
          e.preventDefault();
          handleSubmit();
        }}
        className="btn pb-1 pt-0.5 pl-6 pr-6 text_c font-bold rounded-lg btn_theme3"
        type="Submit"
        defaultValue="Submit"
      />

      <form
        ref={formRef}
        id="addharFormId"
        name="addharFormName"
        method="POST"
        encType="multipart/formdata"
        action="https://esign.egov-nsdl.com/nsdl-esp/authenticate/esign-doc/"
      >
        <input type="hidden" name="msg" value={signingXml} />
      </form>
    </>
  );
};

export default AadharSignature;
