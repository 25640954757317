import React, { useEffect, useContext, useState } from "react";
import "./recipient.css";
import right_arrow from "../../assets/icons/right-arrow.png";
import DocumentContext from "../../context/DocumentContext";
import useAxios from "../../hooks/useAxios";
import { resolve } from "../../api/resolve";
import AppContext from "../../context/AppContext";
import { Worker, Viewer } from "@react-pdf-viewer/core";
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
import LoadingScreen from "../loader/LoadingScreen";
import ReactTooltip from "react-tooltip";
import DownArrow from "../../assets/icons/down-arrow.svg";
import magic_wand from "../../assets/icons/magic-wand.png";
import copydd from "../../assets/icons/copy.png";
import contact2 from "../../assets/icons/contact2.png";
import attach from "../../assets/icons/attach-paperclip-symbol.png";
import printing from "../../assets/icons/printing.png";

import seal from "../../assets/icons/seal.png";
import signn from "../../assets/icons/signn.png";
import stamp from "../../assets/icons/stamp.png";
import { autoCloseTimer, delay } from "../../constants/constants";
import { toast } from "react-toastify";
import HelpIcon from "../../assets/icons/Help-sm.svg";
import PermissionContext from "../../context/PermissionContext";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const Presets = () => {
  const {
    documentListData,
    activeDocInfo,
    setActiveDocInfo,
    apiCurrentPage,
    setApiCurrentPage,
    getDocListWithStatusApi,
    apiDataLimit,
    nextBtnLimit,

    isAutoMagicApplied,
    isPresetsApplied,
    isManualApplied,
    isStampApplied,
    setIsAutoMagicApplied,
    setIsPresetsApplied,
    setIsManualApplied,
    setIsStampApplied,
    rightMenuClickFromOtherPage,
    setRightMenuClickFromOtherPage,

    docUrl,
    setDocUrl,
    documentChange,
    removePresetsApi,
  } = useContext(DocumentContext);
  const navigate = useNavigate();
  const {
    setActiveLink,
    getSasToken,
    highlightToken,
    getHighlightSasToken,
    setShowDropdown,
  } = useContext(AppContext);

  const [receipientListData, setReceipientListData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingApi, setLoadingApi] = useState(false);
  const [actionAll, setActionAll] = useState(false);
  const [contactId, setContactId] = useState(0);
  const [presetItem, setPresetItem] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const [isShowSignature, setIsShowSignature] = useState(true);
  const [handleFixed, setHandleFixed] = useState(false);
  const { permissionList } = useContext(PermissionContext);

  const api = useAxios();
  const { t } = useTranslation();
  const getDocumentInfoApi = async (documentId) => {
    const responceData = await resolve(
      api
        .get(`/contract/document/recipients/list/${documentId}`)
        .then((res) => res.data)
    );

    if (responceData["data"] && responceData["data"].length > 0) {
      setReceipientListData(responceData["data"]);
    }
  };

  const handleApplyPreset = async () => {
    let returnData = false;
    setErrorMsg("");
    if (contactId == 0) {
      setErrorMsg(t("allModule.Please select recipient."));
    } else if (presetItem == 0) {
      setErrorMsg(t("allModule.Please select preset."));
    } else {
      setLoadingApi(true);
      const postData = {
        document_id: activeDocInfo.id,
        recpt_contact_id: contactId,
        type_of_sign: presetItem,
        preset_on: true,
        action_all: actionAll,
      };
      const responceData = await resolve(
        api
          .post(`/contract/pdf/preset/update/`, postData)
          .then((res) => res.data)
      );

      if (responceData.data) {
        setLoadingApi(false);
        setDocUrl(responceData["data"].file_url["converted_file_url"]);
        setIsPresetsApplied(responceData["data"].file_url["preset_on"]);
        toast.success(t("allModule.Presets applied successfully!"), {
          delay,
          autoClose: autoCloseTimer,
          onClose: () => setLoadingApi(false),
        });
        getDocListWithStatusApi(apiCurrentPage, apiDataLimit, 6);
        returnData = true;
      }

      if (responceData.error) {
        setLoadingApi(false);
        toast.error(t("common.Something went wrong"), {
          delay,
          autoClose: autoCloseTimer,
          onClose: () => setLoadingApi(false),
        });
      }
    }
    return returnData;
  };

  const handleRightSectionResponsive = () => {
    setHandleFixed(!handleFixed);
  };

  const handleRightMenu = (menuName) => {
    if (menuName == "Presets") {
      if (isPresetsApplied == true) {
        removePresetsApi(setContactId, setPresetItem, setLoadingApi);
      }
    } else if (menuName == "AutoMagic") {
      setRightMenuClickFromOtherPage(true);
    } else if (menuName == "ManualMode") {
      setRightMenuClickFromOtherPage(true);
    } else if (menuName == "AttachStampPaper") {
      setRightMenuClickFromOtherPage(true);
    }

    setActiveLink(menuName);
    setShowDropdown("Recipients");
  };

  const handleContactChange = (e) => {
    let tempContactId = e.target.value;
    setContactId(tempContactId);
    setPresetItem("");
    if (tempContactId > 0) {
      let contactInfo = receipientListData.filter((item) => {
        if (item["user_contacts_id"] === null) {
          return item.id == tempContactId;
        } else {
          return item.id == tempContactId;
        }
      });
      if (
        contactInfo[0].typeOfSignature == "E-signature"
      ) {
        setIsShowSignature(true);
      } else {
        setIsShowSignature(false);
      }
    } else {
      setIsShowSignature(true);
    }
  };

  const handlePresetItemChange = (e) => {
    setPresetItem(e.target.value);
  };
  useEffect(() => {
    getDocListWithStatusApi(apiCurrentPage, apiDataLimit, 6);
  }, [apiCurrentPage, apiDataLimit]);

  useEffect(() => {
    if (activeDocInfo?.id) {
      getDocumentInfoApi(activeDocInfo.id);
      if (rightMenuClickFromOtherPage === true) {
        if (isPresetsApplied == true) {
          removePresetsApi(setContactId, setPresetItem, setLoadingApi);
        }
        setHandleFixed(false);
        setRightMenuClickFromOtherPage(false);
      }
    }
  }, [activeDocInfo]);

  useEffect(() => {
    getSasToken();
    getHighlightSasToken();
  }, []);

  const renderToolbar = (Toolbar) => (
    <Toolbar>
      {(slots) => {
        const {
          CurrentPageInput,
          GoToNextPage,
          GoToPreviousPage,
          NumberOfPages,
          ShowSearchPopover,
          Print,
          CurrentScale,
          ZoomIn,
          ZoomOut,
        } = slots;

        return (
          <div className={`w-full`}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                width: "100%",
                justifyContent: "space-between",
              }}
            >
              <div className={`flex ml-8 items-center justify-center`}>
                <div className="w-10">
                  <CurrentPageInput />
                </div>
                <div className="mx-2">
                  /{" "}
                  <span>
                    <NumberOfPages />
                  </span>
                </div>
                <div className="flex flex-col ml-1">
                  <GoToPreviousPage>
                    {(props) => (
                      <>
                        <span
                          disabled={props.isDisabled}
                          onClick={props.onClick}
                          style={{
                            cursor: "pointer",
                            transform: "rotate(180deg)",
                            width: 15,
                            height: 15,
                          }}
                          className="ml-1"
                          data-tip={t("allModule.Previous Page")}
                        >
                          <img src={DownArrow} />
                        </span>
                        <ReactTooltip
                          backgroundColor="#400835"
                          textColor="#ffffff"
                        />
                      </>
                    )}
                  </GoToPreviousPage>
                  <GoToNextPage>
                    {(props) => (
                      <>
                        <span
                          disabled={props.isDisabled}
                          onClick={props.onClick}
                          style={{
                            cursor: "pointer",
                            marginTop: 2,
                            width: 15,
                            height: 15,
                          }}
                          className="ml-1"
                          data-tip="Next Page"
                        >
                          <img src={DownArrow} />
                        </span>
                        <ReactTooltip
                          backgroundColor="#400835"
                          textColor="#ffffff"
                        />
                      </>
                    )}
                  </GoToNextPage>
                </div>
              </div>
              <div className="max-w-fit flex items-center">
                <ZoomIn />
                <span className="mx-1">{CurrentScale()}</span>
                <ZoomOut />
              </div>
            </div>
          </div>
        );
      }}
    </Toolbar>
  );
  /////////// pdf search fuctionality ////////////////////
  const defaultLayoutPluginInstance = defaultLayoutPlugin({
    renderToolbar,
    sidebarTabs: () => [],
  });

  return (
    <div className="text-primary text-base font-normal font-sans mt_set">
      <div className="main_head">
        <div className="flex">
          <h1 className="text-4xl font-bold text_c">
            {t("allModule.Presets")}
          </h1>
          <>
            <img
              src={HelpIcon}
              width={12}
              className="ml-1 -mt-2 cursor-pointer"
              data-tip={t(
                "allModule.Presets are a set of stamps and seals that you can apply to your documents."
              )}
            />
            <ReactTooltip backgroundColor="#400835" textColor="#ffffff" />
          </>
        </div>
      </div>

      {permissionList["preset_on"] == false ? (
        <div className="flex justify-center items-center h-96 flex-column ">
          <h3 className="text-2xl font-bold text_c">
            {t("allModule.You dont have permission to access this page")}
          </h3>
        </div>
      ) : documentListData.length === 0 ? (
        <div className="no_document_available_box">
          <p>{t("allModule.No Assigned Document Available.")}</p>
        </div>
      ) : (
        <>
          <div
            className={`row ${handleFixed && 0 ? "set_fix1" : ""}`}
            id="hd_show"
          >
            <div className="col-md-9">
              <div className="w-full doc_list_btn">
                <div className="flex flex-col mb-4">
                  <div className="btn_us3">
                    <button
                      className="btn pb-1 pt-0.5 pl-6 pr-6 text_c font-bold rounded-lg next-pre-btn "
                      onClick={() => {
                        if (apiCurrentPage > 1) {
                          setApiCurrentPage(apiCurrentPage - 1);
                        }
                      }}
                    >
                      <img src={right_arrow} className="right rotateIcon" />
                    </button>
                    {documentListData.map((item, index) => {
                      return (
                        <button
                          key={"doclist_" + index}
                          className={`set_w2 btn pb-1 pt-0.5 pl-6 pr-6 text_c font-semibold ${
                            item.id == activeDocInfo?.id ? "active" : ""
                          }`}
                          onClick={() => documentChange(item)}
                        >
                          {item.name}{" "}
                        </button>
                      );
                    })}
                    <button
                      onClick={() => {
                        if (apiCurrentPage < nextBtnLimit) {
                          setApiCurrentPage(apiCurrentPage + 1);
                        }
                      }}
                      className="btn pb-1 pt-0.5 pl-6 pr-6 text_c font-bold rounded-lg next-pre-btn float-right"
                    >
                      <img src={right_arrow} className="right" />
                    </button>
                  </div>
                </div>
              </div>
              <h4 className="text-xl font-bold  mb-2 text_c text-center">
                {" "}
                {activeDocInfo?.id ? activeDocInfo.name : ""}
              </h4>

              <div className="box_u1 p-4">
                <div className="box_u2 pdf_box">
                  <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.13.216/build/pdf.worker.min.js">
                    {loading ? (
                      <LoadingScreen />
                    ) : (
                      <>
                        {docUrl && (
                          <Viewer
                            fileUrl={`${docUrl}?${highlightToken.sas_token}`}
                            plugins={[
                              defaultLayoutPluginInstance,
                              // searchPluginInstance,
                            ]}
                            renderError={() => (
                              <div className="grid place-items-center h-full w-full">
                                <h1 className="font-semibold">
                                  {t(
                                    "common.Contract indexing in progress. Check back later"
                                  )}
                                </h1>
                              </div>
                            )}
                            renderLoader={() => <LoadingScreen />}
                          />
                        )}
                      </>
                    )}
                  </Worker>
                </div>
              </div>
            </div>
            <div className="col-md-3">
              <div className="tool rounded-md">
                {/* <div className="arrow_us"
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                handleRightSectionResponsive()
                                            }}
                                        >
                                            <a href="#" className="btn btn_theme3"><img src={right_arrow} alt="" /></a>
                                        </div> */}
                <ul>
                  <li
                    data-tip={t("menu.Automagic")}
                    style={{
                      backgroundColor: isAutoMagicApplied
                        ? "var(--orgg)"
                        : "transparent",
                    }}
                  >
                    <a
                      onClick={(e) => {
                        e.preventDefault();
                        navigate("/stamps-seals/automagic");
                        handleRightMenu("AutoMagic");
                      }}
                      href="#"
                    >
                      <img src={magic_wand} alt="" />
                    </a>
                  </li>

                  <li
                    data-tip={t("menu.Presets")}
                    className="active_left_menu"
                    style={{
                      backgroundColor: isPresetsApplied
                        ? "var(--orgg)"
                        : "transparent",
                    }}
                  >
                    <a
                      onClick={(e) => {
                        e.preventDefault();
                        navigate("/stamps-seals/presets");
                        handleRightMenu("Presets");
                      }}
                      href="#"
                    >
                      <img src={copydd} alt="" />
                    </a>
                  </li>

                  <li
                    data-tip={t("menu.Manual mode")}
                    style={{
                      backgroundColor: isManualApplied
                        ? "var(--orgg)"
                        : "transparent",
                    }}
                  >
                    <a
                      onClick={(e) => {
                        e.preventDefault();
                        navigate("/stamps-seals/manual-mode");
                        handleRightMenu("ManualMode");
                      }}
                      href="#"
                    >
                      <img src={contact2} alt="" />
                    </a>
                  </li>

                  <li
                    data-tip={t("menu.Attach stamp paper")}
                    style={{
                      backgroundColor: isStampApplied
                        ? "var(--orgg)"
                        : "transparent",
                    }}
                  >
                    <a
                      onClick={(e) => {
                        e.preventDefault();
                        navigate("/stamps-seals/attach-stamp-paper");
                        handleRightMenu("AttachStampPaper");
                      }}
                      href="#"
                    >
                      <img src={attach} alt="" />
                    </a>
                  </li>

                  <li data-tip={t("menu.Email Notifications")}>
                    <a
                      onClick={(e) => {
                        e.preventDefault();
                        navigate("/stamps-seals/email-notifications");
                        handleRightMenu("EmailNotifications");
                      }}
                      href="#"
                    >
                      <img src={printing} alt="" />
                    </a>
                  </li>
                </ul>
                <div className="hd_2">
                  {loadingApi ? (
                    <LoadingScreen />
                  ) : (
                    <>
                      <div className="automagic_action_all_page mb-3">
                        {receipientListData.length <= 4 && (
                          <>
                            <input
                              type="checkbox"
                              id="all_page"
                              checked={actionAll}
                              onChange={(e) => {
                                setActionAll(e.target.checked);
                              }}
                            />
                            <label htmlFor="all_page">
                              {t("allModule.Presets apply to all pages")}
                            </label>
                          </>
                        )}
                      </div>

                      <select
                        value={contactId}
                        onChange={(e) => {
                          handleContactChange(e);
                        }}
                        className="border-2 border-primary rounded-lg outline-none p-1 pl-2 w-full mb-3"
                      >
                        <option value="0">
                          {t("allModule.Select Recipient")}
                        </option>

                        {receipientListData.map((item, index) => {
                          return item["user_contacts_id"] === null ? (
                            <option key={index} value={item["id"]}>
                              {item["user_id"].first_name}
                            </option>
                          ) : (
                            <option key={index} value={item["id"]}>
                              {item["user_contacts_id"].name}{" "}
                              {item.is_deligacy == true ? " (Delegatee)" : ""}
                            </option>
                          );
                        })}
                      </select>

                      <div className="presets_item_list">
                        {isShowSignature ? (
                          <>
                            <h4 className="mb-3 font-bold text_c text-xl">
                              {t("allModule.Select Preset")}
                            </h4>
                            {!isAutoMagicApplied && (
                              <>
                                <div className="radi_me2 border-2 border-primary p-4 mb-3">
                                  <div className=" check_b radioo">
                                    <input
                                      type="radio"
                                      value="SIGNATURE"
                                      checked={
                                        presetItem === "SIGNATURE"
                                          ? true
                                          : false
                                      }
                                      onChange={(e) => {
                                        handlePresetItemChange(e);
                                      }}
                                      name="presets_item"
                                      className="mr-2"
                                    />
                                    <span></span>
                                  </div>

                                  <div className="left-side-presets">
                                    <img
                                      className="plus_icon"
                                      src={signn}
                                      alt="img"
                                    />
                                    <p>
                                      {t(
                                        "allModule.Add initials at the bottom of last page"
                                      )}
                                    </p>
                                  </div>
                                </div>
                              </>
                            )}

                            <div className="radi_me2 border-2 border-primary p-4 mb-3">
                              <div className=" check_b radioo ">
                                <input
                                  type="radio"
                                  value="SEAL"
                                  checked={presetItem === "SEAL" ? true : false}
                                  onChange={(e) => {
                                    handlePresetItemChange(e);
                                  }}
                                  name="presets_item"
                                  className="mr-2"
                                />
                                <span></span>
                              </div>

                              <div className="left-side-presets">
                                <img
                                  className="plus_icon"
                                  src={seal}
                                  alt="img"
                                />
                                <p>
                                  {t(
                                    "allModule.Add initials and round seal at the bottom of last page"
                                  )}
                                </p>
                              </div>
                            </div>

                            <div className="radi_me2 border-2 border-primary p-4 mb-3">
                              <div className=" check_b radioo">
                                <input
                                  type="radio"
                                  checked={
                                    presetItem === "STAMPPAD" ? true : false
                                  }
                                  value="STAMPPAD"
                                  onChange={(e) => {
                                    handlePresetItemChange(e);
                                  }}
                                  name="presets_item"
                                  className="mr-2"
                                />
                                <span></span>
                              </div>

                              <div className="left-side-presets">
                                <img className="plus_icon" src={stamp} />
                                <p>
                                  {t(
                                    "allModule.Add authorised signatory seal and e-signature on last page"
                                  )}
                                </p>
                              </div>
                            </div>

                            <div className="w-full mt-5">
                              <p className="error_msg">{errorMsg}</p>
                              <input
                                type="button"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  handleApplyPreset();
                                }}
                                className="btn pb-1 pt-0.5 pl-6 pr-6 text_c font-bold rounded-lg btn_theme3 apply_presets_btn"
                                value={t(
                                  "allModule.Apply preset(s) to document"
                                )}
                              />
                            </div>

                            <div className="document_content">
                              <h1 className="font-bold text-center text-2xl mb-5">
                                <img
                                  className="signee-icon"
                                  src={copydd}
                                  alt=""
                                />{" "}
                                {t("allModule.Presets")}
                              </h1>
                              <p>
                                {t(
                                  "allModule.Presets feature allows users to apply Signatures / Seals / Stamps on all pages or on the last page."
                                )}{" "}
                                ​​
                              </p>
                              <p>
                                {t(
                                  "allModule.Users can click on the Presets icon"
                                )}{" "}
                                <img
                                  className="signee-icon"
                                  src={copydd}
                                  alt=""
                                />{" "}
                                {t(
                                  "allModule.to apply Presets and click on it again to remove them."
                                )}
                                ​​​
                              </p>
                              <p>
                                {t(
                                  "allModule.Dont forget to click on Apply preset(s) to document button to apply the feature before clicking on next."
                                )}
                              </p>
                            </div>
                          </>
                        ) : (
                          <>
                            <h4>
                              {t(
                                "allModule.This feature is not available for Stakeholder/ Aadhaar Signatories"
                              )}
                            </h4>
                          </>
                        )}
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="set_btn2 mt-4">
            <button
              onClick={(e) => {
                e.preventDefault();
                setActiveLink("AutoMagic");
                setShowDropdown("Recipients");
                setApiCurrentPage(1);
              }}
              className="btn pb-1 pt-0.5 pl-6 pr-6 text_c font-bold rounded-lg btn_theme3"
            >
              {t("allModule.Previous")}
            </button>
            <button
              onClick={async (e) => {
                if (contactId > 0 && presetItem != "") {
                  let res = await handleApplyPreset();
                  if (res) {
                    handleRightMenu("ManualMode");
                    setApiCurrentPage(1);
                  }
                } else {
                  handleRightMenu("ManualMode");
                  setApiCurrentPage(1);
                }
              }}
              className="btn pb-1 pt-0.5 pl-6 pr-6 text_c font-bold rounded-lg btn_theme3"
            >
              {t("common.Next")}
            </button>
          </div>
        </>
      )}
    </div>
  );
};

export default Presets;
