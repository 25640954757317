import React, { useEffect, useContext } from 'react';
// import Navbar from '../navbar/Navbar';
import { useNavigate } from 'react-router-dom';
import AppContext from '../../context/AppContext';
import { useTranslation } from 'react-i18next';

const NotFoundComponent = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { setActiveLink } = useContext(AppContext);
  useEffect(() => {
    setActiveLink('NotFound');
    navigate('/dashboard');
  }, []);
  return (
    <div className='grid place-items-center h-full text-primary text-center'>
      <div className='-ml-10'>
        <h1 className='text-4xl font-bold'>{t('form.Page Not Found')}</h1>
        <h1
          style={{
            color: 'transparent',
            fontSize: '6rem',
            fontWeight: 'bolder',
            WebkitTextStrokeWidth: '2px',
            WebkitTextStrokeColor: '#400835',
          }}
        >
          ( 404 )
        </h1>
      </div>
    </div>
  );
};

export default NotFoundComponent;
