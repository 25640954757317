import React, { useContext, useState } from 'react';
import Modal from 'react-modal';
import './modal.css';
import AuthContext from '../../context/AuthContext';
import AppContext from '../../context/AppContext';
import FormContext from '../../context/FormContext';
import { validatePhone } from '../../utils/utils';
import { toast } from 'react-toastify';
import useAxios from '../../hooks/useAxios';
import { resolve } from '../../api/resolve';
import { parse } from 'papaparse';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

const customStyles = {
    content: {
        top: '40%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        maxWidth: '600px',
        maxHeight: 'auto',
        borderRadius: '11px',
        minWidth: '500px',
    },
    overlay: {
        backgroundColor: 'rgba(64, 8, 53, 0.63)',
    },
};

Modal.setAppElement('#root');
const Pay = ({
    modalIsOpen,
    setIsOpen,
    setPlanModelIsOpen
}) => {
    const api = useAxios();
    const { t } = useTranslation();
    const { user, setUser, logoutUser } = useContext(AuthContext);
    const [paid_amount, setPaid_amount] = useState('');
    const [date_of_payment, setDate_of_payment] = useState('');
    const [mode_of_payment, setMode_of_payment] = useState('');
    const [cheque_no, setcheque_no] = useState('');
    const [otp, setotp] = useState('');

    const [bank_name, setBank_name] = useState('');
    const [bank_nameError, setBank_nameError] = useState('');
    const [Transaction_no, setTransaction_no] = useState('');
    const [Transaction_noError, setTransaction_noError] = useState('');
    const [isShowOtp, setIsShowOtp] = useState(false);
    const [countryCode, setCountryCode] = useState('+91');
    const [countryListData, setCountryListData] = useState([]);

    const PayAmount = async () => {
        setBank_nameError('');
        setTransaction_noError('');
        if (paid_amount.trim().length === 0 || !validatePhone(paid_amount)) {
            setBank_nameError(t('form.Please enter amount'));
        } else if (!validatePhone(Transaction_no)) {
            setTransaction_noError(t('form.Please enter Transaction No'));
        } else {
            const postData = {
                date_of_payment: date_of_payment,
                paid_amount: paid_amount,
                mode_of_payment: mode_of_payment,
                cheque_no: cheque_no,
                bank_name: bank_name,
                Transaction_no: Transaction_no,

            };
            const res = await resolve(api.post(`user/update/corprate/transaction/1/`, postData).then((res) => res.data));

            if (res.data) {
                setIsShowOtp(true);
                toast.success(t('form.Amount pay successfully'));
            }
            if (res.error) {
                toast.error(t('common.Something went wrong'));
            }
        }
    };


    const getCountryListApi = async () => {
        const responceData = await resolve(api.get('/company/countries/list/').then((res) => res.data));

        if (responceData['data']) {
            setCountryListData(responceData['data']);
        }
    };

    useEffect(() => {
        getCountryListApi();
    }, []);

    return (
        <div className=''>
            <Modal
                isOpen={modalIsOpen}
                onRequestClose={() => setIsOpen(true)}
                style={customStyles}
                onAfterClose={() => {
                }}
            >

                <div className=' modal-container'>
                    <h1 className='text-center text-primary font-bold text-lg'>{t('form.Pay Bill')}</h1>

                    <>

                        <div className='mt-5 flex flex-col justify-center'>
                            <label className='flex flex-start mb-1'>{t('form.Transaction No')}</label>
                            <div className='flex'>

                                <input type='text' value={`${Transaction_no}`}
                                    className='border-2 border-primary rounded-lg outline-none p-1 pl-2 '
                                    onChange={(e) => setTransaction_no(e.target.value)}
                                />
                            </div>
                            <p className='flex flex-start error_msg'>{Transaction_noError}</p>
                        </div>
                        <div className='mt-5 flex flex-col justify-center'>
                            <label className='flex flex-start mb-1'>{t('form.Payment Mode')}</label>
                            <div className='flex'>

                                <select
                                    className="border-2 border-primary rounded-lg outline-none p-1 pl-2"
                                    value=''
                                >
                                    <option value="">{t('form.Select Mode')}</option>
                                    <option value="online">{t('form.Online')}</option>
                                    <option value="cod">{t('form.COD')}</option>
                                </select>
                            </div>
                            {/* <p className='flex flex-start error_msg'>{BankNameError}</p> */}
                        </div>


                        <div className='mt-3 flex flex-col justify-center'>
                            <label className='flex flex-start mb-1'>{t('form.Bank Name')}</label>
                            <div className='flex'>

                                <input type='text' value={`${bank_name}`}
                                    className='border-2 border-primary rounded-lg outline-none p-1 pl-2 '
                                    onChange={(e) => setBank_name(e.target.value)}
                                />
                            </div>
                            <p className='flex flex-start error_msg'>{bank_nameError}</p>
                        </div>
                        <div className='mt-5 flex flex-col justify-center'>
                            <label className='flex flex-start mb-1'>{t('form.Cheque No')}</label>
                            <div className='flex'>

                                <input type='text' value=""
                                    className='border-2 border-primary rounded-lg outline-none p-1 pl-2 '
                                // onChange={(e) => setChequeNo(e.target.value)}
                                />
                            </div>
                            {/* <p className='flex flex-start error_msg'>{ChequeNoError}</p> */}
                        </div>
                        <div className='mt-5 flex flex-col justify-center'>
                            <label className='flex flex-start mb-1'>{t('form.Amount')}</label>
                            <div className='flex'>

                                <input type='text' value=""
                                    className='border-2 border-primary rounded-lg outline-none p-1 pl-2 '
                                // onChange={(e) => setAmount(e.target.value)}
                                />
                            </div>
                            {/* <p className='flex flex-start error_msg'>{AmountError}</p> */}
                        </div>
                        
                        <div className='flex justify-center mt-5'>
                            <button
                                onClick={(e) => {
                                    e.preventDefault();
                                    PayAmount();
                                }}
                                className='bg-primary hover:bg-secondary font-semibold text-white p-1 pl-8 pr-8 rounded-lg mr-5'
                            >
                                {t('form.Pay')}
                            </button>
                            <button
                                onClick={(e) => {
                                    e.preventDefault();
                                    setIsOpen(false);
                                }}
                                className='bg-[#606060] font-semibold text-white p-1 pl-8 pr-8 rounded-lg'
                            >
                                {t('common.Cancel')}
                            </button>

                        </div>
                    </>


                </div>
            </Modal>
        </div>
    );
};

export default Pay;
