import React, { useState } from 'react';
import { useTable } from 'react-table';
import '../contracts/contracts.css';
import DownArrow from '../../assets/icons/down-arrow.svg';
import ReactTooltip from 'react-tooltip';

import { resolve } from '../../api/resolve';
import useAxios from '../../hooks/useAxios';
import { autoCloseTimer, delay } from '../../constants/constants';
import { toast } from 'react-toastify';
import RejectDocModal from '../modals/RejectDocModal';
import eye_on from "../../assets/icons/eye_on.png";
import { useTranslation } from 'react-i18next';
import EyeOnIconWhite from "../../assets/icons/eye_on_white.png";
import statusIcon from "../../assets/icons/status.png"
import SigneeInfoModal from "../modals/SigneeInfoModal";


export const NoDataComponent = ({ colLength }) => {
    const [rows] = useState(Array(4).fill(''));
    const [cols] = useState(Array(colLength).fill(''));
    return (
        <>
            {rows.map((data, index) => (
                <tr key={`group_ndf_row_${index}`}>
                    {cols.map((data, index01) => (
                        <td key={`group_ndf_td_${index01}`} className='px-5 py-2 font-medium text-smW'>--</td>
                    ))}
                </tr>
            ))}
        </>
    );
};

const RejectedDocTable = ({
    tableData,
    tableColumns,
    currentPage,
    setCurrentPage,
    nextBtnLimit,
    itemCount,
    pageLimit,
    setPageLimit,
    getListData,

    sortingOrder,
    setSortingOrder,
    setSortColumn,
    showActionBtn,
    showPageLimit,
    setPreviewDocument,
    setPreviewDocumentData,

}) => {
    const [recipientList, setRecipientList] = useState([]);
    const [signeeInfoModelOpen, setSigneeInfoModelOpen] = useState(false);
    const handleCheckStatus = async (docId) => {
        setDocId(docId);
        const res = await resolve(
          api.get(`/contract/workflow/sign/status/${docId}`)
        );
        if (res.data) {
            // console.log(res.data);
            setRecipientList(res.data["data"].data);
            // console.log("recipientList", recipientList);
        }
    
        if (res.error) {
          setRecipientList([]);
        }
        setSigneeInfoModelOpen(true);
      };

    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
        useTable({ columns: tableColumns, data: tableData });

    const [docId, setDocId] = useState(0);
    const [rejectDocModelOpen, setRejectDocModelOpen] = useState(false);

    const api = useAxios();
    const { t } = useTranslation();

    const rejectDocument = async () => {
        const res = await resolve(
            api.post(`/user/reject/document/${docId}`)
        );
        if (res.data) {
            setRejectDocModelOpen(false);
            toast.success('Document Rejected Successfully', {
                autoClose: autoCloseTimer,
                hideProgressBar: true,
            });
            await delay(1000);
            getListData();
        }

        if (res.error) {
            toast.error("Something went wrong.", {
                autoClose: autoCloseTimer,
                hideProgressBar: true,
            });
        }
        setRejectDocModelOpen(false);
    };


    return (
        <>

            <div className='mt-2 flex flex-col table-container mb-5'>
                <div>
                    <div className='table_desin'>
                        <div style={{ maxHeight: '60vh' }} className='shadow overflow-hidden border-2 border-primary  sm:rounded-lg overflow-y-auto'>
                            <table {...getTableProps()} className='w-full '>
                                <thead>
                                    {headerGroups.map((headerGroup) => (
                                        <tr
                                            className={`cursor-pointer bg-[#F6C107]`}
                                            {...headerGroup.getHeaderGroupProps()}>
                                            {headerGroup.headers.map((column) => (
                                                // Add the sorting props to control sorting. For this example
                                                // we can add them into the header props

                                                <th
                                                    scope='col'
                                                    className='text-left px-2 py-2'
                                                    {...column
                                                        .getHeaderProps
                                                        // column.getSortByToggleProps()
                                                        ()}
                                                >
                                                    <div className='flex items-center'>
                                                        <span>{t('constant.' + column.render('Header'))}</span>

                                                        {column.isSorted !== undefined &&
                                                            (column.isSorted ? (
                                                                <span
                                                                    onClick={(e) => {
                                                                        setSortingOrder('asc');
                                                                        setSortColumn(column.id);
                                                                        column.isSorted = false;
                                                                    }}
                                                                    style={{ cursor: 'pointer', marginTop: 2 }}
                                                                    className='ml-1'
                                                                >
                                                                    <img src={DownArrow} />
                                                                </span>
                                                            ) : (
                                                                <span
                                                                    onClick={(e) => {
                                                                        setSortingOrder('desc');
                                                                        setSortColumn(`${column.id}`);
                                                                        column.isSorted = true;
                                                                    }}
                                                                    style={{
                                                                        cursor: 'pointer',
                                                                        transform: 'rotate(180deg)',
                                                                    }}
                                                                    className='ml-1'
                                                                >
                                                                    <img src={DownArrow} />
                                                                </span>
                                                            ))
                                                        }
                                                    </div>
                                                </th>
                                            ))}
                                            {showActionBtn && (
                                                <th scope='col' className='text-left px-4 pt-2'>
                                                    {t('constant.Action')}
                                                </th>
                                            )}
                                        </tr>
                                    ))}
                                </thead>
                                <tbody {...getTableBodyProps()}>
                                    {tableData.length < 1 && (
                                        <NoDataComponent colLength={tableColumns.length} />
                                    )}
                                    {tableData.length > 0 &&
                                        rows.map((row, i) => {
                                            // new
                                            prepareRow(row);
                                            return (
                                                <tr
                                                    {...row.getRowProps()}
                                                >
                                                    {row.cells.map((cell, row_cell_index) => {
                                                        return (

                                                            <td
                                                                {...cell.getCellProps()}
                                                                className={`px-2 py-2 font-medium text-sm contracts-col-data ${cell.value === 'In-Active' && 'text-red-500'
                                                                    }`}
                                                            >
                                                                <span
                                                                    title={cell.column.Header === "Document Name" ? cell.value : ""}
                                                                    className={`${cell.column.Header === "Document Name"
                                                                        ? "text_fixed cursor-pointer"
                                                                        : ""
                                                                        }`}>
                                                                    {cell.render("Cell")}
                                                                </span>

                                                            </td>

                                                        );
                                                    })}
                                                    {showActionBtn && (
                                                        <td className='py-2 flex'>
                                                            <button
                                                                onClick={(e) => {
                                                                    e.stopPropagation();
                                                                    setPreviewDocument(true);
                                                                    setPreviewDocumentData(row.original);
                                                                }}
                                                                className="btn btn_theme btn-sm mr-1 wh_spab w-10"
                                                                data-tip={t("constant.View")}
                                                            >
                                                                {/* {t("constant.View")} */}
                                                                <img
                                                                    src={EyeOnIconWhite}
                                                                    className="ml-0.5"
                                                                    width={20}
                                                                />
                                                            </button>
                                                            <button
                                                                onClick={(e) => {
                                                                    e.stopPropagation();
                                                                    handleCheckStatus(row.original.id);
                                                                }}
                                                                className="btn btn_theme btn-sm wh_spab w-10"
                                                                data-tip={t('allModule.Check status')}
                                                                >
                                                                {/* {t('allModule.Check status')} */}
                                                                <img
                                                                        src={statusIcon}
                                                                        className="ml-0.5"
                                                                        width={20}
                                                                    />
                                                                </button>
                                                            {/* <button className='btn btn_theme btn-sm mr-1 wh_spab'>Affix My Signature</button>
                                                            <button
                                                                onClick={(e) => {
                                                                    e.stopPropagation();
                                                                    setDocId(row.original.id);
                                                                    setRejectDocModelOpen(true);

                                                                }}
                                                                className='btn btn_theme btn-sm wh_spab'>Reject document</button> */}
                                                        </td>
                                                    )}
                                                    <ReactTooltip backgroundColor='#400835' textColor='#ffffff' />
                                                </tr>
                                            );
                                        })}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                {tableData.length > 0 && (
                    <div
                        className={`mt-3 flex ${showPageLimit ? 'justify-between' : 'justify-end'
                            }`}
                    >
                        {showPageLimit && (
                            <div>
                                {itemCount > 5 && (
                                    <>
                                        <span>{t('constant.Show')}</span>
                                        <select
                                            className='border-2 border-primary outline-none mx-1 rounded-md cursor-pointer btn-sm'
                                            onChange={(e) => {
                                                setCurrentPage(1)
                                                setPageLimit(e.target.value)
                                            }}
                                            value={pageLimit}
                                        >
                                            <option>5</option>
                                            <option>10</option>
                                            <option>15</option>
                                            <option>25</option>``
                                        </select>
                                        <span>{t('common.Results')}</span>
                                    </>
                                )}
                            </div>
                        )}
                        <div className='flex text-primary  border-t-2 border-b-2 border-primary rounded-lg'>
                            <button
                                className={`btn-sm border-r-2 border-l-2 border-primary font-semibold rounded-tl-lg rounded-bl-lg ${currentPage <= 1 && 'bg-inputBackground'
                                    }`}
                                disabled={currentPage <= 1}
                                onClick={(e) => {
                                    if (currentPage > 1) {
                                        setCurrentPage(currentPage - 1)
                                    }
                                }}
                            >
                                {t('common.Prev')}
                            </button>
                            <span className='btn-sm font-semibold'>{currentPage}</span>
                            <button
                                className={`btn-sm border-l-2 border-r-2 border-primary font-semibold rounded-tr-lg rounded-br-lg ${currentPage >= nextBtnLimit && 'bg-inputBackground'
                                    }`}
                                disabled={currentPage >= nextBtnLimit}
                                onClick={(e) => {
                                    if (currentPage < nextBtnLimit) {
                                        setCurrentPage(currentPage + 1)
                                    }
                                }}
                            >
                                {t('common.Next')}
                            </button>
                        </div>
                    </div>
                )}
            </div>
            <RejectDocModal
                rejectDocModelOpen={rejectDocModelOpen}
                setRejectDocModelOpen={setRejectDocModelOpen}
                rejectDocument={rejectDocument}
            />
            <SigneeInfoModal
                signeeInfoModelOpen={signeeInfoModelOpen}
                setSigneeInfoModelOpen={setSigneeInfoModelOpen}
                recipientList={recipientList}
                sendEmail=""
                sendSms=""
                screenName="RejectedScreen"
            />
        </>
    );
};

export default RejectedDocTable;
