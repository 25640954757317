import React, { useState } from 'react';
import './helpModule.css';
import { Helmet } from 'react-helmet';
import CallIcon from '../../assets/icons/Call.svg';
import RequestIcon from '../../assets/icons/Request.svg';
import HelpModuleModal from '../modals/HelpModuleModal';
import { useTranslation } from 'react-i18next';

const HelpModule = () => {
  const [modalIsOpen, setIsOpen] = useState(false);
  const [isSupportRequest, setIsSupportRequest] = useState(false);
  const { t } = useTranslation();
  return (
    <div className='text-primary text-base font-normal font-sans help_page'>
      <div className="main_head">
        <h1 className='text-4xl font-bold'>{t('menu.Help')}</h1>
      </div>

      <div className='flex  w-1/2 justify-between '>
        <div
          className='flex items-center cursor-pointer'
          onClick={() => {
            setIsOpen(true);
            setIsSupportRequest(true);
          }}
        >
          <div className='border-primary help-module-item'>
            <img src={RequestIcon} width={40} />
          </div>
          <div className='ml-3'>
            <h2 className='text-base font-bold'>
              {t('allModule.Send us a')} <br /> {t('allModule.support request')}
            </h2>
          </div>
        </div>
        <div
          className='flex items-center cursor-pointer'
          onClick={() => {
            setIsOpen(true);
          }}
        >
          <div className='border-primary help-module-item'>
            <img src={CallIcon} width={50} />
          </div>
          <div className='ml-3'>
            <h2 className='text-base font-bold'>{t('allModule.Schedule a call')}</h2>
            <p className='text-sm'>
              ( {t('allModule.Available Mon-Fri during')} <br /> {t('allModule.normal business hours')} )
            </p>
          </div>
        </div>
      </div>
      <HelpModuleModal
        modalIsOpen={modalIsOpen}
        setIsOpen={setIsOpen}
        isSupportRequest={isSupportRequest}
        setIsSupportRequest={setIsSupportRequest}
      />
      
    </div>
  );
};

export default HelpModule;
