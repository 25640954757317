import React, { useContext, useEffect, useState } from "react";
import Modal from "react-modal";
import HelpIcon from "../../assets/icons/Help-sm.svg";
import Select from "react-select";
import { useTranslation } from "react-i18next";
import { resolve } from "../../api/resolve";
import useAxios from "../../hooks/useAxios";
import { toast } from "react-toastify";

const customStyles = {
  content: {
    top: "45%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    maxWidth: "600px",
    // minHeight: "400px",
    borderRadius: "4px",
    minWidth: "300px",
    maxHeight: "600px",
    overflowX: "hidden",
    border: "2px solid #310128",
  },
  overlay: {
    backgroundColor: "rgba(64, 8, 53, 0.63)",
  },
  // menuPortal: base => ({ ...base, zIndex: 9999 })
};

Modal.setAppElement("#root");
const PlayriteDeligenceLegacy = ({
  isModalOpen,
  setIsModalOpen,
  file,
  uploadQuoqoDeligence,
  sectionTaskList,
  countryListData,

  playBookList,
  getSectionTaskList,
  inputRef,
  setSectionTaskList,
}) => {
  const usa_states = [
    { name: "Alabama" },
    { name: "Alaska" },
    { name: "Arizona" },
    { name: "Arkansas" },
    { name: "California" },
    { name: "Colorado" },
    { name: "Connecticut" },
    { name: "Delaware" },
    { name: "Florida" },
    { name: "Georgia" },
    { name: "Hawaii" },
    { name: "Idaho" },
    { name: "Illinois" },
    { name: "Indiana" },
    { name: "Iowa" },
    { name: "Kansas" },
    { name: "Kentucky" },
    { name: "Louisiana" },
    { name: "Maine" },
    { name: "Maryland" },
    { name: "Massachusetts" },
    { name: "Michigan" },
    { name: "Minnesota" },
    { name: "Mississippi" },
    { name: "Missouri" },
    { name: "Montana" },
    { name: "Nebraska" },
    { name: "Nevada" },
    { name: "New Hampshire" },
    { name: "New Jersey" },
    { name: "New Mexico" },
    { name: "New York" },
    { name: "North Carolina" },
    { name: "North Dakota" },
    { name: "Ohio" },
    { name: "Oklahoma" },
    { name: "Oregon" },
    { name: "Pennsylvania" },
    { name: "Rhode Island" },
    { name: "South Carolina" },
    { name: "South Dakota" },
    { name: "Tennessee" },
    { name: "Texas" },
    { name: "Utah" },
    { name: "Vermont" },
    { name: "Virginia" },
    { name: "Washington" },
    { name: "West Virginia" },
    { name: "Wisconsin" },
    { name: "Wyoming" },
  ];
  const api = useAxios();
  const [selectedCountry, setSelectedCountry] = useState("");
  const [sectionList, setSectionList] = useState([]);
  const handleGoverningLawChange = (e) => {
    const selectedValue = e.target.value;
    setFormData({ ...formData, governing_law: selectedValue }); // Update governing_law with selected value

    // Determine if the selected value is a country or a state
    const isCountry = countryListData.some(
      (country) => country.name === selectedValue
    );
    // console.log("isCountry", isCountry);
    // console.log('selectedValue', country);
    if (isCountry) {
      setSelectedCountry(selectedValue);
    } else {
      const sk = "United States of America";
      setSelectedCountry(sk);
    }
  };

  const cancelModel = () => {
    setSelectedCountry("");
    setIsModalOpen(false);
  };

  const { t } = useTranslation();
  const [formData, setFormData] = useState({
    aitools: "",
    selectedPerspective: "",
    taskDescription: "",
    perspective: "",
    governing_law: "",
    sectionId: "",
    customePrompt: "",
    playbookId: "",
  });
  const [formDataErr, setFormDataErr] = useState({
    aitools: "",
    selectedPerspective: "",
    perspective: "",
    taskDescription: "",
    sectionId: "",
    playbookId: "",
  });

  const handleUploadQuoqoDeligence = () => {
    for (const key in formDataErr) {
      if (formDataErr.hasOwnProperty(key)) {
        formDataErr[key] = "";
      }
    }

    if (formData.aitools == "Playrite" && formData.playbookId == "") {
      setFormDataErr({
        ...formDataErr,
        playbookId: t("allModule.Please select Playbook"),
      });
    } else if (formData.aitools != "NDAReview" && formData.sectionId == "") {
      setFormDataErr({
        ...formDataErr,
        sectionId: t("constant.Please select section"),
      });
    } else if (
      formData.aitools != "NDAReview" &&
      formData.selectedPerspective == ""
    ) {
      setFormDataErr({
        ...formDataErr,
        selectedPerspective: t("constant.Please select task"),
      });
    } else {
      uploadQuoqoDeligence(formData, file);
    }
  };

  const getSectionList = async (clearText) => {
    let seccat = "";
    if (clearText == "NDAReview") {
      seccat = "";
    } else if (clearText == "ContractReview") {
      seccat = "diligence";
    } else if (clearText == "TransactionReview") {
      seccat = "Transaction";
    } else if (clearText == "IPReview") {
      seccat = "IP";
    } else if (clearText == "SectoralReview") {
      seccat = "SectoralReview";
    } else if (clearText == "Playrite") {
      seccat = "Playbook";
    }
    const res = await resolve(
      api
        .get(
          `nda_report/diligence/section/list/?SecCat=${seccat}&ordering=section_name&sortingOrder=asc`
        )
        .then((res) => res.data)
    );
    if (res.data) {
      console.log("Inside get section list", res.data);
      const { data } = res;
      const { results, count } = data;
      setSectionList(results);
    }
  };

  const handleAIToolsChange = (e) => {
    const selectedValue = e.target.value;
    setFormData({ ...formData, aitools: selectedValue });
    console.log("selected value", selectedValue);
    getSectionList(selectedValue); // Call getsectionlist method with the selected value
  };

  return (
    <div className="">
      <Modal
        isOpen={isModalOpen}
        onRequestClose={() => {}}
        style={customStyles}
        contentLabel="Example Modal"
        onAfterClose={() => {
          setSectionTaskList([]);
          if (inputRef.current?.value) {
            inputRef.current.value = "";
          }
          setFormData({
            selectedPerspective: "",
            taskDescription: "",
            perspective: "",
            governing_law: "",
            sectionId: "",
            customePrompt: "",
            playbookId: "",
          });
          setFormDataErr({
            selectedPerspective: "",
            taskDescription: "",
            perspective: "",
            governing_law: "",
            sectionId: "",
            customePrompt: "",
            playbookId: "",
          });
        }}
      >
        <button
          className="absolute top-0 right-0 text-[#606060] font-semibold p-1 pl-2 pr-2 mr-2 rounded-lg h-10"
          onClick={cancelModel}
        >
          {t("X")}
        </button>

        <div className="text-left modal-container">
          {file.map((doc, index) => (
            <p key={index} className="text-primary font-normal text-md mt-2">
              {t("form.File Name")} {index + 1}: {doc && doc.doc_name}
            </p>
          ))}

          {/* <p className="text-primary font-normal text-md mt-2">
            {t("form.File Name")}: {file && file.name}
          </p> */}
          <div className="mt-5 flex flex-col justify-center">
            <label className="text-primary font-normal text-md">
              {t("allModule.AI Reviews")}:
            </label>
            <div className="flex">
              <select
                id="aitools"
                style={{ width: "300px" }}
                name="aitools"
                value={formData.aitools}
                onChange={(e) => {
                  handleAIToolsChange(e);
                }}
                className="input-field-small"
              >
                <option value="">{t("allModule.Select Type of Review")}</option>
                {/* <option value="NDAReview">{t("constant.Review NDA")}</option> */}
                <option value="ContractReview">
                  {t("constant.Review a Contract")}
                </option>
                <option value="TransactionReview">
                  {t("constant.Review a Transaction Document")}
                </option>
                <option value="IPReview">{t("constant.IP Review")}</option>
                <option value="SectoralReview">
                  {t("constant.Sectoral Review")}
                </option>
                <option value="Playrite">{t("allModule.Playrite")}</option>
              </select>
            </div>
          </div>
          {formData.aitools &&
            formData.aitools != "NDAReview" &&
            formData.aitools != "" && (
              <>
                {formData.aitools &&
                  formData.aitools != "Playrite" &&
                  formData.aitools != "" && (
                    <>
                      <div className="mt-5 flex flex-col justify-center">
                        <label className="text-primary font-normal text-md">
                          {t("constant.Perspective")}:
                        </label>
                        <input
                          type="text"
                          value={formData.reviewReason}
                          onChange={(e) =>
                            setFormData({
                              ...formData,
                              reviewReason: e.target.value,
                              perspective: e.target.value,
                            })
                          }
                          className="border-2 border-primary rounded-lg outline-none p-1 pl-2 "
                          placeholder={t(
                            "constant.From whose perspective are you reviewing this document? (e.g. buyer or seller)"
                          )}
                        />
                        <p className="flex flex-start error_msg">
                          {formDataErr.reviewReason}
                        </p>
                      </div>
                      <div className="flex flex-col justify-center">
                        <label className="text-primary font-normal text-md">
                          {t("constant.Governing Law")}:
                        </label>
                        <select
                          value={formData.governing_law}
                          onChange={(e) => handleGoverningLawChange(e)}
                          className="border-2 border-primary rounded-lg outline-none p-1 pl-2 w-full"
                        >
                          <option value="">
                            {t("common.Select")} {t("constant.Governing Law")}
                          </option>
                          {countryListData.map((item, index) => (
                            <option key={index} value={item.name}>
                              {item.name}
                            </option>
                          ))}
                          {selectedCountry === "United States of America" &&
                            usa_states.map((item, index) => (
                              <option key={index} value={item.name}>
                                {item.name}
                              </option>
                            ))}
                        </select>
                        <p className="flex flex-start error_msg">
                          {formDataErr.governing_law}
                        </p>
                      </div>

                      {selectedCountry === "United States of America" && (
                        <div className="flex flex-col justify-center">
                          <label className="text-primary font-normal text-md">
                            {t("constant.States")}:
                          </label>
                          <select
                            value={formData.governing_law} // Use the same value for the state selection
                            onChange={(e) => handleGoverningLawChange(e)} // Use the same handler for state selection
                            className="border-2 border-primary rounded-lg outline-none p-1 pl-2 w-full"
                          >
                            <option value="">
                              {t("common.Select")} {t("constant.States")}
                            </option>
                            {usa_states.map((item, index) => (
                              <option key={index} value={item.name}>
                                {item.name}
                              </option>
                            ))}
                          </select>
                          <p className="flex flex-start error_msg">
                            {formDataErr.governing_law}
                          </p>
                        </div>
                      )}
                    </>
                  )}

                {formData.aitools &&
                  formData.aitools == "Playrite" &&
                  formData.aitools != "" && (
                    <div className="mt-5 flex flex-col justify-center">
                      <label className="text-primary font-normal text-md">
                        {t("allModule.Playbook")}:
                      </label>
                      <select
                        className="border-2 border-primary rounded-lg outline-none p-1 pl-2 w-full"
                        value={formData.playbookId}
                        onChange={(e) => {
                          setFormData({
                            ...formData,
                            playbookId: e.target.value,
                          });
                        }}
                      >
                        <option value={0}>
                          {t("allModule.Select Playbook")}
                        </option>
                        {playBookList.map((item, index) => (
                          <option key={index} value={item.id}>
                            {item.name}
                          </option>
                        ))}
                      </select>
                      <p className="flex flex-start error_msg">
                        {formDataErr.playbookId}
                      </p>
                    </div>
                  )}

                <div className="flex flex-col justify-center">
                  <label className="text-primary font-normal text-md">
                    {t("menu.Section")}:
                  </label>
                  <select
                    className="border-2 border-primary rounded-lg outline-none p-1 pl-2 w-full"
                    value={formData.sectionId}
                    onChange={(e) => {
                      setFormData({ ...formData, sectionId: e.target.value });
                      getSectionTaskList(e.target.value);
                    }}
                  >
                    <option value={0}>{t("constant.Select Section")}</option>
                    {sectionList.map((item, index) => (
                      <option key={index} value={item.id}>
                        {item.section_name}
                      </option>
                    ))}
                  </select>
                  <p className="flex flex-start error_msg">
                    {formDataErr.sectionId}
                  </p>
                </div>
                <div className="flex flex-col justify-center">
                  <label className="text-primary font-normal text-md">
                    {t("menu.Task")}:
                  </label>
                  {/* style="background-image:url(male.png); */}
                  <select
                    value={formData.selectedPerspective}
                    onChange={(e) => {
                      const selectedId = e.target.value;
                      const selectedTask = sectionTaskList.find(
                        (task) => task.id == selectedId
                      );
                      if (selectedTask) {
                        setFormData({
                          ...formData,
                          selectedPerspective: selectedId,
                          taskDescription: selectedTask.description,
                          superPrompt: selectedTask.task_name, // Save task_name along with taskDescription
                        });
                      }
                    }}
                    className="border-2 border-primary rounded-lg outline-none p-1 pl-2 mr-2 display-tool-tip"
                  >
                    <option value="">{t("constant.Select Task")}</option>
                    {sectionTaskList.map((value, index) => {
                      return (
                        <option
                          title={value.tool_tip}
                          key={index}
                          value={value.id}
                        >
                          {value.description}
                        </option>
                      );
                    })}
                  </select>
                  <p className="flex flex-start error_msg">
                    {formDataErr.selectedPerspective}
                  </p>
                </div>
                {formData.aitools &&
                  formData.aitools != "Playrite" &&
                  formData.aitools != "" && (
                    <div className="flex flex-col justify-center">
                      <label className="text-primary font-normal text-md">
                        {t("constant.Custom Prompt")}:
                      </label>
                      <input
                        type="text"
                        value={formData.customePrompt}
                        onChange={(e) =>
                          setFormData({
                            ...formData,
                            customePrompt: e.target.value,
                          })
                        }
                        className="border-2 border-primary rounded-lg outline-none p-1 pl-2 "
                        placeholder={t("constant.Custom Prompt")}
                      />
                    </div>
                  )}
              </>
            )}
          <div className="flex justify-center mt-5">
            <button
              className="bg-primary hover:bg-secondary font-semibold text-white p-1 pl-8 pr-8 rounded-lg mr-5"
              onClick={(e) => {
                e.stopPropagation();
                handleUploadQuoqoDeligence();
              }}
            >
              {t("allModule.Upload")}
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default PlayriteDeligenceLegacy;
