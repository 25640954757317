import React, { useEffect, useContext, useState } from 'react';
import document from '../../assets/icons/document.png';
import template from '../../assets/icons/template.png';
import template_library from '../../assets/icons/template_library.png';
import upload_documents from '../../assets/icons/upload_documents.png';
import AppContext from '../../context/AppContext';
import PermissionContext from '../../context/PermissionContext';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const CreateDocument = () => {

    const {
        setActiveLink,
        setShowDropdown,

    } = useContext(AppContext);

    const { permissionList, setPermissionList } = useContext(PermissionContext);
    const navigate = useNavigate();
    const { t } = useTranslation();

    return (<>

        <div className='text-primary text-base font-normal font-sans mt_set'>
            <div className="main_head">
                <h1 className='text-4xl font-bold text_c '>
                    {t('menu.Create Documents')}
                </h1>
            </div>

            <div className="Create_Doc1">
                <div className="row">
                    
                    <div className="col-md-3">
                        <div
                            onClick={(e) => {
                                e.preventDefault();
                                navigate('/generate-document');
                                setActiveLink('GenerateDocument');
                                setShowDropdown('CreateDocument')
                            }}
                            className="p-5 border-2 border-primary text-primary rounded-lg text-center mt-5">
                            <img src={document} />
                            <h4 className="font-bold text-xl mt-3">{t('menu.Generate Document')}</h4>
                        </div>
                    </div>

                
                    <div className="col-md-3">
                        <div
                            onClick={(e) => {
                                e.preventDefault();
                                navigate('/create-template');
                                setActiveLink('CreateTemplate');
                                setShowDropdown('CreateDocument')
                            }}
                            className="p-5 border-2 border-primary text-primary rounded-lg text-center mt-5">
                            <img src={template} />
                            <h4 className="font-bold text-xl mt-3">{t('menu.Create from Template')}</h4>
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div
                            onClick={(e) => {
                                e.preventDefault();
                                navigate('/template-library');
                                setActiveLink('CreateTemplateLibrary');
                                setShowDropdown('CreateDocument')
                            }}
                            className="p-5 border-2 border-primary text-primary rounded-lg text-center mt-5">
                            <img src={template_library} />
                            <h4 className="font-bold text-xl mt-3">{t('menu.Select from Template Library')}</h4>
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div
                            onClick={(e) => {
                                e.preventDefault();
                                navigate('/upload-O365-documents');
                                setActiveLink('ContractO365Form');
                                setShowDropdown('CreateDocument')
                            }}
                            className="p-5 border-2 border-primary text-primary rounded-lg text-center mt-5">
                            <img src={upload_documents} />
                            <h4 className="font-bold text-xl mt-3">{t('constant.Upload Document for Collaboration')}</h4>
                        </div>
                    </div>

                    
                </div>
            </div>
        </div>
    </>)
}

export default CreateDocument;