import React, { useContext, useState } from "react";
import "./user_profile.css";
import AuthContext from "../../context/AuthContext";
import PermissionInfoModal from "../modals/PermissionInfoModal";
import PermissionContext from "../../context/PermissionContext";
import Logout from "../../assets/icons/Logout.svg";
import { useTranslation } from "react-i18next";
import AppModal from "../modals/Modal";

const UserRighSection = () => {
  const { t } = useTranslation();
  const { user } = useContext(AuthContext);
  const { permissionList } = useContext(PermissionContext);
  const [permissionInfoModelOpen, setPermissionInfoModelOpen] = useState(false);
  const [modalIsOpen, setIsOpen] = useState(false);

  const name = user.name.split(" ");
  let shortName = "";
  if (name[1]) {
    shortName = name[0].charAt(0) + name[1].charAt(0);
  } else {
    shortName = name[0].charAt(0) + name[0].charAt(1);
  }

  const toTitleCase = (str) => {
    return str.replace(/(?:^|\s)\w/g, function (match) {
      return match.toUpperCase();
    });
  };

  const handleProfileClick = () => {
    if (user.user_type !== "super_admin") {
      setPermissionInfoModelOpen(true);
    }
  };

  return (
    <>
      <div
        onClick={(e) => {
          e.preventDefault();
          handleProfileClick();
        }}
        className="user_right_section"
      >
        <span className="fist_last">{shortName.toUpperCase()}</span>
        {user.user_type === "super_admin" ? (
          <p id="user_name">SAdmin</p>
        ) : (
          <p id="user_name">{toTitleCase(user.user_type)}</p>
        )}
      </div>

      <div className="logout_btn">
        <button
          onClick={() => setIsOpen(true)}
          className="btn btn_theme2 btn-sm flex items-center justify-center"
        >
          <img src={Logout} />
          <span>{t("menu.Logout")}</span>
        </button>
      </div>

      <PermissionInfoModal
        permissionInfoModelOpen={permissionInfoModelOpen}
        setPermissionInfoModelOpen={setPermissionInfoModelOpen}
        permissionList={permissionList}
        user={user}
      />

      <AppModal
        setIsOpen={setIsOpen}
        modalIsOpen={modalIsOpen}
        isLogin={true}
        title={t("menu.Do you want to Log out?")}
      />
    </>
  );
};

export default UserRighSection;
