import { createContext, useEffect, useState } from "react";
import useAxios from "../hooks/useAxios";
import { resolve } from "../api/resolve";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { PublicClientApplication } from "@azure/msal-browser";

const DocumentContext = createContext();

export default DocumentContext;

export const DocumentProvider = ({ children }) => {
  // document related states
  const [documentListData, setDocumentListData] = useState([]);
  const [activeDocInfo, setActiveDocInfo] = useState({});
  const [apiCurrentPage, setApiCurrentPage] = useState(1);
  const [apiDataLimit, setApiDataLimit] = useState(3);
  const [nextBtnLimit, setNextBtnLimit] = useState(0);
  const [recipientDetail, setRecipientDetail] = useState([]);

  const [apiCurrentPageRecpt, setApiCurrentPageRecpt] = useState(1);
  const [apiDataLimitRecpt, setApiDataLimitRecpt] = useState(3);
  const [nextBtnLimitRecpt, setNextBtnLimitRecpt] = useState(0);

  const [activeDocInfoMyDoc, setActiveDocInfoMyDoc] = useState({});
  const [isAutoMagicApplied, setIsAutoMagicApplied] = useState(false);
  const [isPresetsApplied, setIsPresetsApplied] = useState(false);
  const [isStampApplied, setIsStampApplied] = useState(false);
  const [isManualApplied, setIsManualApplied] = useState(false);
  const [rightMenuClickFromOtherPage, setRightMenuClickFromOtherPage] =
    useState(false);
  const [docUrl, setDocUrl] = useState("");
  const [recipientCount, setRecipientCount] = useState(0);
  const [forSignatureList, setForSignatureList] = useState([]);
  const [activeDocStatus, setActiveDocStatus] = useState(0);
  const [isShowDocPreview, setIsShowDocPreview] = useState(false);
  const [editDocumentIn365, setEditDocumentIn365] = useState(null);
  const [msalInstance, setMsalInstance] = useState(null);
  
  const api = useAxios();
  const { t } = useTranslation();
  const navigate = useNavigate();

  

  const getDocumentListApi = async (page = 0, limit = 5, docStatus = "") => {
    let status = "";
    if (docStatus !== "") {
      status = `&docStatus=${docStatus}`;
    }
    const responceData = await resolve(
      api
        .get(`/contract/document/list/?page=${page}&limit=${limit}${status}`)
        .then((res) => res.data)
    );
    if (responceData["data"]) {
      const documentList = responceData.data.results;
      if (documentList.length > 0) {
        documentList.map((item) => {
          item["isShowDoc"] = false;
        });
        setDocumentListData(documentList);

        if (!activeDocInfo?.id) {
          setActiveDocInfo(documentList[0]);
        } else {
          let selectedDoc = documentList.filter((item) => {
            return item.id === activeDocInfo.id;
          });
          if (selectedDoc.length === 0) {
            setActiveDocInfo({});
          } else {
            setActiveDocInfo(selectedDoc[0]);
          }
        }
      } else {
        setDocumentListData([]);
      }

      setNextBtnLimitRecpt(Math.ceil(responceData.data.count / apiDataLimit));
    }
  };

  const getDocListWithStatusApi = async (
    page = 0,
    limit = 5,
    status = 2,
    tabName = ""
  ) => {
    const responceData = await resolve(
      api
        .get(
          `/contract/document/list/status/?status=${status}&page=${page}&limit=${limit}`
        )
        .then((res) => res.data)
    );
    if (responceData["data"]) {
      const documentList = responceData.data.results;
      setDocumentListData(documentList);

      if (!activeDocInfo?.id && documentList.length > 0) {
        setActiveDocInfo(documentList[0]);
        setIsAutoMagicApplied(documentList[0].automagic_on);
        setIsPresetsApplied(documentList[0].preset_on);
        setIsStampApplied(documentList[0].stamp_on);
        setIsManualApplied(documentList[0].manual_on);
        setRecipientCount(documentList[0].recipient_count);
      } else if (documentList.length > 0) {
        let selectedDoc = documentList.filter((item) => {
          return item.id === activeDocInfo.id;
        });
        if (selectedDoc.length > 0) {
          if (tabName === "workflow") {
            setDocUrl(selectedDoc[0].original_file_pdf_url);
          } else {
            setDocUrl(selectedDoc[0].converted_file_url);
          }

          setIsAutoMagicApplied(selectedDoc[0].automagic_on);
          setIsPresetsApplied(selectedDoc[0].preset_on);
          setIsStampApplied(selectedDoc[0].stamp_on);
          setIsManualApplied(selectedDoc[0].manual_on);
          setRecipientCount(selectedDoc[0].recipient_count);
        }
      }

      if (!activeDocInfo?.id) {
        setActiveDocInfo(documentList[0]);
      } else {
        let selectedDoc = documentList.filter((item) => {
          return item.id === activeDocInfo.id;
        });
        if (selectedDoc.length === 0) {
          setActiveDocInfo(documentList[0]);
        } else {
          setActiveDocInfo(selectedDoc[0]);
        }
      }
      setNextBtnLimit(Math.ceil(responceData.data.count / apiDataLimit));
    }
  };

  const paginate = (array, page_size, page_number) => {
    // human-readable page numbers usually start with 1, so we reduce 1 in the first argument
    return array.slice((page_number - 1) * page_size, page_number * page_size);
  };
  const getSelectedDocumentListApi = async (page = 0, limit = 5) => {
    if (forSignatureList) {
      const documentList = paginate(forSignatureList, limit, page);

      if (documentList.length > 0) {
        documentList.map((item) => {
          item["isShowDoc"] = false;
        });
        setDocumentListData(documentList);
        setActiveDocInfo(documentList[0]);
      } else {
        setDocumentListData([]);
      }
      setNextBtnLimitRecpt(Math.ceil(forSignatureList.length / apiDataLimit));
    }
  };

  const submitDocumentStep1Api = async (
    recipientDetail,
    setActiveLink,
    defaultRecipientInfo,
    activeDocInfoStatus
  ) => {
    const postData = {
      docId: activeDocInfo.id,
      recipientDetail: recipientDetail,
      userInfo: {
        id: defaultRecipientInfo.id,
        signingCapacity: defaultRecipientInfo.signingCapacity,
        typeOfSignature: defaultRecipientInfo.typeOfSignature,
      },
    };
    try {
      const responceData = await api.post(
        `/contract/document/recipients/create/`,
        JSON.stringify(postData),
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (responceData["data"]) {
        toast.success(t("form.Recipient added successfully"));
        navigate("/apply-signature/select-workflows");
        setActiveLink("Workflows");
        setActiveDocStatus(activeDocInfoStatus);
        setApiCurrentPage(1);
      }

      if (responceData["error"]) {
        toast.error(t("form.Something went wrong"));
      }
    } catch (error) {
      toast.error(error.response.data.message);
    }
  };

  const addSelectedDocRecipientApi = async (
    recipientDetail,
    setActiveLink,
    defaultRecipientInfo
  ) => {
    if (forSignatureList.length > 0) {
      let cnt = 0;
      let errMsg = "";
      let temp = [...forSignatureList];
      await Promise.all(
        forSignatureList.map(async (item) => {
          let postData = {
            docId: item.id,
            recipientDetail: recipientDetail,
            userInfo: {
              id: defaultRecipientInfo.id,
              signingCapacity: defaultRecipientInfo.signingCapacity,
              typeOfSignature: defaultRecipientInfo.typeOfSignature,
            },
          };
          try {
            const responceData = await api.post(
              `/contract/document/recipients/create/`,
              JSON.stringify(postData),
              {
                headers: {
                  "Content-Type": "application/json",
                },
              }
            );

            if (responceData["data"]) {
              cnt++;
              let index = temp.findIndex((x) => x.id == item.id);

              if (index !== -1) {
                temp.splice(index, 1);
              }
            }
          } catch (error) {
            errMsg = error.response.data.message;
          }
        })
      );
      setForSignatureList(temp);
      if (cnt == forSignatureList.length) {
        toast.success(t("form.Recipient added successfully"));
        setActiveLink("Workflows");
      } else {
        toast.error(
          errMsg ? errMsg : "Something went wrong. Please try again later"
        );
      }
    } else {
      toast.error(t("form.No Document selected"));
    }
  };

  const removePresetsApi = async (
    setContactId,
    setPresetItem,
    setLoadingApi
  ) => {
    const postData = {
      document_id: activeDocInfo.id,
      preset_on: false,
    };
    setLoadingApi(true);
    const responceData = await resolve(
      api
        .post(`/contract/pdf/preset/update/`, JSON.stringify(postData), {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then((res) => res.data)
    );

    if (responceData["data"]) {
      setDocUrl(responceData["data"].file_url["converted_file_url"]);
      // getDocListWithStatusApi(apiCurrentPage, apiDataLimit);
      setIsPresetsApplied(false);
      setContactId("");
      setPresetItem("");
      toast.success(t("form.Preset removed successfully"));
    }

    if (responceData["error"]) {
      toast.success(t("form.Preset removed failed"));
    }

    setLoadingApi(false);
  };

  const removeManualApi = async (setContactId, contactId, setLoadingApi) => {
    const postData = {
      documentId: activeDocInfo.id,
      manual_on: false,
      contactId: "None",
      userId: "None",
      itemListData: "None",
    };

    setLoadingApi(true);
    const responceData = await resolve(
      api
        .post(`/contract/pdf/manual/update/`, JSON.stringify(postData), {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then((res) => res.data)
    );

    if (responceData["data"]) {
      setDocUrl(responceData["data"].file_url["converted_file_url"]);
      setIsManualApplied(false);
      setContactId("");
      toast.success(t("form.Manual removed successfully"));
    }

    if (responceData["error"]) {
      toast.success(t("form.Manual removed failed"));
    }
    setLoadingApi(false);
  };

  const removeStampApi = async () => {
    const postData = {
      doc_id: activeDocInfo.id,
      s_update: false,
    };
    const responceData = await resolve(
      api
        .post(`/contract/stamp/update/`, JSON.stringify(postData), {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then((res) => res.data)
    );
    if (responceData["data"]) {
      toast.success(t("form.Stamp removed successfully"));
      setIsManualApplied(responceData["data"].file_url["stamp_on"]);
      setDocUrl(responceData["data"].file_url["converted_file_url"]);
      getDocListWithStatusApi(apiCurrentPage, apiDataLimit, 6);
    }

    if (responceData["error"]) {
      toast.error(t("form.Stamp removed failed"));
    }
  };

  const documentChange = (item) => {
    setIsAutoMagicApplied(item.automagic_on);
    setIsPresetsApplied(item.preset_on);
    setIsStampApplied(item.stamp_on);
    setIsManualApplied(item.manual_on);
    setDocUrl(item.converted_file_url);
    setActiveDocInfo(item);
    setRecipientCount(item.recipient_count);
  };

  const contextData = {
    recipientCount,
    documentChange,
    // document related states
    documentListData,
    setDocumentListData,
    activeDocInfo,
    setActiveDocInfo,
    apiCurrentPage,
    setApiCurrentPage,
    apiDataLimit,
    setApiDataLimit,
    nextBtnLimit,
    recipientDetail,
    setRecipientDetail,

    apiCurrentPageRecpt,
    setApiCurrentPageRecpt,
    apiDataLimitRecpt,
    nextBtnLimitRecpt,

    // document related functions
    getDocumentListApi,
    submitDocumentStep1Api,
    activeDocStatus,
    getDocListWithStatusApi,
    isAutoMagicApplied,
    setIsAutoMagicApplied,
    isPresetsApplied,
    setIsPresetsApplied,
    removePresetsApi,

    isStampApplied,
    setIsStampApplied,

    isManualApplied,
    setIsManualApplied,
    removeManualApi,
    removeStampApi,

    // autoMagicClickFromOtherPage,
    // setAutoMagicClickFromOtherPage,

    rightMenuClickFromOtherPage,
    setRightMenuClickFromOtherPage,

    // my document related states
    activeDocInfoMyDoc,
    setActiveDocInfoMyDoc,

    docUrl,
    setDocUrl,

    forSignatureList,
    setForSignatureList,
    getSelectedDocumentListApi,
    addSelectedDocRecipientApi,

    setRecipientCount,

    isShowDocPreview,
    setIsShowDocPreview,

    msalInstance,
    setEditDocumentIn365,
    editDocumentIn365,
   
  };


  return (
    <DocumentContext.Provider value={contextData}>
      {children}
    </DocumentContext.Provider>
  );
};
