import React, { useEffect, useContext, useState } from "react";
import "./recipient.css";
import right_arrow from "../../assets/icons/right-arrow.png";
import DocumentContext from "../../context/DocumentContext";
import useAxios from "../../hooks/useAxios";
import { resolve } from "../../api/resolve";
import AppContext from "../../context/AppContext";
import { Worker, Viewer } from "@react-pdf-viewer/core";
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
import LoadingScreen from "../loader/LoadingScreen";
import ReactTooltip from "react-tooltip";
import DownArrow from "../../assets/icons/down-arrow.svg";

import magic_wand from "../../assets/icons/magic-wand.png";
import copydd from "../../assets/icons/copy.png";
import contact2 from "../../assets/icons/contact2.png";
import attach from "../../assets/icons/attach-paperclip-symbol.png";
import printing from "../../assets/icons/printing.png";
import HelpIcon from "../../assets/icons/Help-sm.svg";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import PermissionContext from "../../context/PermissionContext";

const StampsAndSeals = () => {
  const {
    documentListData,
    activeDocInfo,
    setActiveDocInfo,
    apiCurrentPage,
    setApiCurrentPage,
    getDocListWithStatusApi,
    apiDataLimit,
    nextBtnLimit,

    isAutoMagicApplied,
    isPresetsApplied,
    isManualApplied,
    isStampApplied,
    setIsAutoMagicApplied,
    setIsPresetsApplied,
    setIsManualApplied,
    setIsStampApplied,

    setRightMenuClickFromOtherPage,

    docUrl,
    setDocUrl,
    documentChange,
  } = useContext(DocumentContext);
  const { permissionList } = useContext(PermissionContext);

  const {
    setActiveLink,
    getSasToken,
    highlightToken,
    getHighlightSasToken,
    setShowDropdown,
  } = useContext(AppContext);

  const [loading, setLoading] = useState(false);
  const [handleFixed, setHandleFixed] = useState(true);
  const navigate = useNavigate();
  const api = useAxios();
  const { t } = useTranslation();

  const handleRightMenu = (menuName) => {
    if (menuName == "AutoMagic") {
      setRightMenuClickFromOtherPage(true);
    } else if (menuName == "Presets") {
      setRightMenuClickFromOtherPage(true);
    } else if (menuName == "ManualMode") {
      setRightMenuClickFromOtherPage(true);
    } else if (menuName == "AttachStampPaper") {
      setRightMenuClickFromOtherPage(true);
    }

    setActiveLink(menuName);
    setShowDropdown("StampsAndSeals");
  };

  const handleRightSectionResponsive = () => {
    setHandleFixed(!handleFixed);
  };

  useEffect(() => {
    getDocListWithStatusApi(apiCurrentPage, apiDataLimit, 6);
  }, [apiCurrentPage, apiDataLimit]);

  useEffect(() => {
    if (activeDocInfo?.id) {
      setDocUrl(activeDocInfo.converted_file_url);
    }
  }, [activeDocInfo]);

  /* useEffect(() => {

        if (documentListData.length > 0) {
            setDocUrl(documentListData[0].converted_file_url)
        }
    }, [documentListData]) */

  useEffect(() => {
    getSasToken();
    getHighlightSasToken();
  }, []);

  const renderToolbar = (Toolbar) => (
    <Toolbar>
      {(slots) => {
        const {
          CurrentPageInput,
          GoToNextPage,
          GoToPreviousPage,
          NumberOfPages,
          ShowSearchPopover,
          Print,
          CurrentScale,
          ZoomIn,
          ZoomOut,
        } = slots;

        return (
          <div className={`w-full`}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                width: "100%",
                justifyContent: "space-between",
              }}
            >
              <div className={`flex ml-8 items-center justify-center`}>
                <div className="w-10">
                  <CurrentPageInput />
                </div>
                <div className="mx-2">
                  /{" "}
                  <span>
                    <NumberOfPages />
                  </span>
                </div>
                <div className="flex flex-col ml-1">
                  <GoToPreviousPage>
                    {(props) => (
                      <>
                        <span
                          disabled={props.isDisabled}
                          onClick={props.onClick}
                          style={{
                            cursor: "pointer",
                            transform: "rotate(180deg)",
                            width: 15,
                            height: 15,
                          }}
                          className="ml-1"
                          data-tip={t("allModule.Previous Page")}
                        >
                          <img src={DownArrow} />
                        </span>
                        <ReactTooltip
                          backgroundColor="#400835"
                          textColor="#ffffff"
                        />
                      </>
                    )}
                  </GoToPreviousPage>
                  <GoToNextPage>
                    {(props) => (
                      <>
                        <span
                          disabled={props.isDisabled}
                          onClick={props.onClick}
                          style={{
                            cursor: "pointer",
                            marginTop: 2,
                            width: 15,
                            height: 15,
                          }}
                          className="ml-1"
                          data-tip="Next Page"
                        >
                          <img src={DownArrow} />
                        </span>
                        <ReactTooltip
                          backgroundColor="#400835"
                          textColor="#ffffff"
                        />
                      </>
                    )}
                  </GoToNextPage>
                </div>
              </div>
              <div className="max-w-fit flex items-center">
                <ZoomIn />
                <span className="mx-1">{CurrentScale()}</span>
                <ZoomOut />
              </div>
            </div>
          </div>
        );
      }}
    </Toolbar>
  );
  /////////// pdf search fuctionality ////////////////////
  const defaultLayoutPluginInstance = defaultLayoutPlugin({
    renderToolbar,
    sidebarTabs: () => [],
  });

  return (
    <div className="text-primary text-base font-normal font-sans mt_set">
      <div className="main_head">
        <div className="flex">
          <h1 className="text-4xl font-bold text_c">
            {t("allModule.Stamps & Seals")}
          </h1>
          <>
            <img
              src={HelpIcon}
              width={12}
              className="ml-1 -mt-2 cursor-pointer"
              data-tip={t("allModule.Stamps & Seals")}
            />
            <ReactTooltip backgroundColor="#400835" textColor="#ffffff" />
          </>
        </div>
      </div>

      {permissionList["seal_and_stamp_on"] == false ? (
        <div className="flex justify-center items-center h-96 flex-column ">
          <h3 className="text-2xl font-bold text_c">
            {t("allModule.You dont have permission to access this page")}
          </h3>
        </div>
      ) : documentListData.length > 0 ? (
        <>
          <div
            className={`row ${handleFixed && 0 ? "set_fix1" : ""}`}
            id="hd_show"
          >
            <div className="col-md-9">
              <div className="w-full doc_list_btn">
                <div className="flex flex-col mb-4">
                  <div className="btn_us3">
                    <button
                      className="btn pb-1 pt-0.5 pl-6 pr-6 text_c font-bold rounded-lg next-pre-btn "
                      onClick={() => {
                        if (apiCurrentPage > 1) {
                          setApiCurrentPage(apiCurrentPage - 1);
                        }
                      }}
                    >
                      <img src={right_arrow} className="right rotateIcon" />
                    </button>
                    {documentListData.map((item, index) => {
                      return (
                        <button
                          key={"doclist_" + index}
                          className={`set_w2 btn pb-1 pt-0.5 pl-6 pr-6 text_c font-semibold ${
                            item.id == activeDocInfo?.id ? "active" : ""
                          }`}
                          onClick={() => documentChange(item)}
                        >
                          {item.name}{" "}
                        </button>
                      );
                    })}
                    <button
                      onClick={() => {
                        if (apiCurrentPage < nextBtnLimit) {
                          setApiCurrentPage(apiCurrentPage + 1);
                        }
                      }}
                      className="btn pb-1 pt-0.5 pl-6 pr-6 text_c font-bold rounded-lg next-pre-btn float-right"
                    >
                      <img src={right_arrow} className="right" alt="img" />
                    </button>
                  </div>
                </div>
              </div>
              <h4 className="text-xl font-bold  mb-2 text_c text-center">
                {" "}
                {activeDocInfo?.id ? activeDocInfo.name : ""}
              </h4>

              <div className="box_u1 p-4">
                <div className="box_u2 pdf_box">
                  <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.13.216/build/pdf.worker.min.js">
                    {loading ? (
                      <LoadingScreen />
                    ) : (
                      <>
                        {docUrl && (
                          <Viewer
                            fileUrl={`${docUrl}?${highlightToken.sas_token}`}
                            plugins={[
                              defaultLayoutPluginInstance,
                              // searchPluginInstance,
                            ]}
                            renderError={() => (
                              <div className="grid place-items-center h-full w-full">
                                <h1 className="font-semibold">
                                  {t(
                                    "common.Contract indexing in progress. Check back later"
                                  )}
                                </h1>
                              </div>
                            )}
                            renderLoader={() => <LoadingScreen />}
                          />
                        )}
                      </>
                    )}
                  </Worker>
                </div>
              </div>
            </div>
            <div className="col-md-3">
              <div className="tool rounded-md">
                <ul>
                  <li
                    data-tip={t("menu.Automagic")}
                    style={{
                      backgroundColor: isAutoMagicApplied
                        ? "var(--orgg)"
                        : "transparent",
                    }}
                  >
                    <a
                      onClick={(e) => {
                        e.preventDefault();
                        navigate("/stamps-seals/automagic");
                        handleRightMenu("AutoMagic");
                      }}
                      href="#"
                    >
                      <img src={magic_wand} alt="" />
                    </a>
                  </li>

                  <li
                    data-tip={t("menu.Presets")}
                    style={{
                      backgroundColor: isPresetsApplied
                        ? "var(--orgg)"
                        : "transparent",
                    }}
                  >
                    <a
                      onClick={(e) => {
                        e.preventDefault();
                        navigate("/stamps-seals/presets");
                        handleRightMenu("Presets");
                      }}
                      href="#"
                    >
                      <img src={copydd} alt="" />
                    </a>
                  </li>

                  <li
                    data-tip={t("menu.Manual mode")}
                    style={{
                      backgroundColor: isManualApplied
                        ? "var(--orgg)"
                        : "transparent",
                    }}
                  >
                    <a
                      onClick={(e) => {
                        e.preventDefault();
                        navigate("/stamps-seals/manual-mode");
                        handleRightMenu("ManualMode");
                      }}
                      href="#"
                    >
                      <img src={contact2} alt="" />
                    </a>
                  </li>

                  <li
                    data-tip={t("menu.Attach stamp paper")}
                    style={{
                      backgroundColor: isStampApplied
                        ? "var(--orgg)"
                        : "transparent",
                    }}
                  >
                    <a
                      onClick={(e) => {
                        e.preventDefault();
                        navigate("/stamps-seals/attach-stamp-paper");
                        handleRightMenu("AttachStampPaper");
                      }}
                      href="#"
                    >
                      <img src={attach} alt="" />
                    </a>
                  </li>

                  <li data-tip={t("menu.Email Notifications")}>
                    <a
                      onClick={(e) => {
                        e.preventDefault();
                        navigate("/stamps-seals/email-notifications");
                        handleRightMenu("EmailNotifications");
                      }}
                      href="#"
                    >
                      <img src={printing} alt="" />
                    </a>
                  </li>
                </ul>
                <div className="hd_2 document_content">
                  <p>
                    {t(
                      "allModule.Applying signatures, seals and stamps can be done using different methods:"
                    )}
                  </p>

                  <p>
                    <img className="signee-icon" src={magic_wand} alt="" />
                    <b>{t("allModule.Automagic")}:</b>{" "}
                    {t(
                      "allModule.Automagic feature helps users to apply signatures on all pages at a time."
                    )}
                  </p>

                  <p>
                    <img className="signee-icon" src={copydd} alt="" />
                    <b>{t("allModule.Presets")}:</b>{" "}
                    {t(
                      "allModule.Presets feature allows users to apply signatures/seals/stamps as per requirement on all pages or on the last page."
                    )}
                  </p>

                  <p>
                    <img className="signee-icon" src={contact2} alt="" />
                    <b>{t("allModule.Manual")}:</b>{" "}
                    {t(
                      "allModule.Users can use Manual mode to select, paste and drag signatures / seals / photographs and to any location on the pdf."
                    )}
                  </p>

                  <p>
                    <img className="signee-icon" src={attach} alt="" />
                    <b>{t("allModule.Attach Stamp Paper")}:</b>
                    {t(
                      "allModule.The user can also upload e-stamp paper in image / pdf format using the Attach Stamp Paper feature."
                    )}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="set_btn2 mt-4">
            <button
              onClick={(e) => {
                e.preventDefault();
                setActiveLink("Workflows");
                setShowDropdown("Recipients");
                setApiCurrentPage(1);
              }}
              className="btn pb-1 pt-0.5 pl-6 pr-6 text_c font-bold rounded-lg btn_theme3"
            >
              Previous
            </button>
            <button
              onClick={(e) => {
                e.preventDefault();
                setActiveLink("AutoMagic");
                setShowDropdown("Recipients");
                setApiCurrentPage(1);
              }}
              className="btn pb-1 pt-0.5 pl-6 pr-6 text_c font-bold rounded-lg btn_theme3"
            >
              Next
            </button>
          </div>
        </>
      ) : (
        <div className="no_document_available_box">
          <p>{t("allModule.No Assigned Document Available.")}</p>
        </div>
      )}
    </div>
  );
};

export default StampsAndSeals;
