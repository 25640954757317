import React, { useEffect, useState, useContext } from "react";
import Search from "../../assets/icons/Search.svg";
import CloseIcon from "../../assets/icons/Close.svg";
import { clausetablecolumns } from "../../constants/constants";
import useAxios from "../../hooks/useAxios";
import { resolve } from "../../api/resolve";
import AddButton from "../../shared/AddButton";
import ClauseLibaryTable from "./ClauseLibaryTable";
import { parseISO, format } from "date-fns";

const ClauseLibaryList = () => {
  const api = useAxios();
  // pending doc related states
  const [ClauseLibaryList, setLegalWorkflowList] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [pageLimit, setPageLimit] = useState(5);
  const [nextBtnLimit, setNextBtnLimit] = useState(0);
  const [count, setUserCount] = useState(0);
  const [errMsg, setErrMsg] = useState("");
  const [showClearIcon, setShowClearIcon] = useState(false);
  const [sortingOrder, setSortingOrder] = useState("desc");
  const [sortColumn, setSortColumn] = useState("clause_name");

  const getLegalWorkflowList = async (clearText) => {
    const res = await resolve(
      api
        .get(
          `contract/list/clause/?ordering=${sortColumn}&sortingOrder=${sortingOrder}&search=${
            clearText ? "" : searchText
          }&page=${currentPage}&limit=${pageLimit}`
        )
        .then((res) => res.data)
    );
    if (res.data) {
      const { data } = res;
      const { results, count } = data;

      if (currentPage > 1 && results.length == 0) {
        setCurrentPage(currentPage - 1);
        return false;
      }

      const filterData = [];
      if (results.length > 0) {
        setUserCount(count);
        results.forEach((result) => {
          const tempObj = {
            id: result.id,
            clause_name: result.clause_name,
            clause_text: result.clause_text,
            company_id: result.company_id,
          };
          filterData.push(tempObj);
        });
      } else {
        if (currentPage === 1) setErrMsg("No data added yet");
        else setErrMsg("No more data to view");
      }
      setLegalWorkflowList([...filterData]);
      setNextBtnLimit(Math.ceil(count / pageLimit));
    } else {
      setNextBtnLimit(0);
    }
  };

  useEffect(() => {
    getLegalWorkflowList();
  }, [sortColumn, sortingOrder, currentPage, pageLimit]);

  return (
    <>
      <div className="text-primary">
        <div className="main_head">
          <h1 className="text-4xl font-bold">Clause Library</h1>
        </div>

        <div className="tab_main">
          <div className={`tab_us show`}>
            <div className="row justify-between">
              <div className="col-md-5 flex_contact">
                <form>
                  <div className="flex mt-3  w-full">
                    <div className="flex justify-between items-center border-2 border-primary rounded-md rounded-tl-lg rounded-bl-lg bg-inputBackground px-2 mr-2 w-full ">
                      <input
                        placeholder="Search.. "
                        className="px-1 py-1 focus:outline-none bg-transparent w-11/12"
                        value={searchText}
                        onChange={(e) => {
                          setSearchText(e.target.value);
                          setShowClearIcon(true);
                          if (!e.target.value.trim()) {
                            setShowClearIcon(false);
                          }
                        }}
                      />
                      {showClearIcon && (
                        <img
                          src={CloseIcon}
                          width={14}
                          className="cursor-pointer"
                          onClick={() => {
                            setSearchText("");
                            getLegalWorkflowList(true);
                            setShowClearIcon(false);
                          }}
                        />
                      )}
                    </div>
                    <button
                      type="submit"
                      className="flex btn btn_theme2 w_125 items-center justify-between"
                      onClick={(e) => {
                        e.preventDefault();
                        setCurrentPage(1);
                        getLegalWorkflowList();
                      }}
                    >
                      <img src={Search} style={{ width: "1rem" }} />
                      <span className="font-semibold ml-1">Search</span>
                    </button>
                  </div>
                </form>
              </div>
              <div className="col-md-5">
                <div className="flex justify-end mt-3">
                  <AddButton text="Add" currentForm="ClauseLibaryForm" />
                </div>
              </div>
            </div>
            <div className="mt-3 rounded-lg">
              {ClauseLibaryList.length < 1 && (
                <p className="text-red-500 text-sm font-medium">{errMsg}</p>
              )}
            </div>
            <ClauseLibaryTable
              tableColumns={clausetablecolumns}
              getListData={getLegalWorkflowList}
              tableData={ClauseLibaryList}
              showActionBtn
              nextBtnLimit={nextBtnLimit}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              itemCount={count}
              setPageLimit={setPageLimit}
              showPageLimit={true}
              sortingOrder={sortingOrder}
              setSortingOrder={setSortingOrder}
              setSortColumn={setSortColumn}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default ClauseLibaryList;
