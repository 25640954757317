import React, { useEffect, useContext, useState, useRef } from "react";
import ReactTooltip from "react-tooltip";
import LoadingScreen from "../loader/LoadingScreen";
import "../forms/companyForm.css";
import FormContext from "../../context/FormContext";
import AppModal from "../modals/Modal";
import "react-tagsinput/react-tagsinput.css";
import HelpIcon from "../../assets/icons/Help-sm.svg";
import UsersFile from "../../assets/Users.csv";
import PermissionContext from "../../context/PermissionContext";
import Papa from "papaparse";
import useAxios from "../../hooks/useAxios";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import eye_off from "../../assets/icons/eye_off.png";
import eye_on from "../../assets/icons/eye_on.png";

import "../../shared/ToggleSwitch.css";
import { resolve } from "../../api/resolve";
import AdminUserList from "./AdminUserList";

const DemoUserForm = () => {
  const api = useAxios();
  const navigate = useNavigate();
  const [isShowPassword, setIsShowPassword] = useState(false);
  const [planList, setPlanList] = useState([]);
  const [permissionList, setPermissionList] = useState({});
  const user = { user_type: "admin" };

  const {
    demoUserFormErrors,
    modalData,
    setIsOpen,
    modalIsOpen,
    isEditing,
    editFormData,
    setEditFormData,
    saveOrEditDemoUser,
    loading,
  } = useContext(FormContext);

  const { updatePermissionList } = useContext(PermissionContext);
  const [isAlreadyDemoUser, setIsAlreadyDemoUser] = useState(true);

  const [userObj, setUserObj] = useState({
    name: "",
    designation: "",
    email: "",
    companyName: "",
    planId: "",
    password: "",
    isDemoUser: true,
    isActiveUser: true,
    days: "",
    isGenericTemplatesRequire: false,
  });

  const getPlanListList = async (clearText) => {
    const res = await resolve(
      api.get(`user/plan/list/`).then((res) => res.data)
    );

    if (res.data) {
      const { data } = res;
      const { results, count } = data;
      const filterData = [];
      if (results.length > 0) {
        results.forEach((result) => {
          if (
            result != null &&
            result["plan_id"] != null &&
            result["plan_id"].is_corporate_plan
          ) {
            const planListObj = {
              id: result["plan_id"].id,
              name: result["plan_id"].name,
            };
            filterData.push(planListObj);
          }
        });
      }

      setPlanList([...filterData]);
    } else {
      setPlanList([]);
    }
  };

  const handleCheckQuota = async (userId) => {
    const res = await resolve(
      api.get(`/contract/User_Quota/${userId}`).then((res) => res.data)
    );

    if (res.data) {
      setPermissionList(res.data);
    }

    if (res.error) {
      toast.error("Something went wrong");
    }
  };

  useEffect(() => {
    getPlanListList();
    if (isEditing) {
      setIsAlreadyDemoUser(editFormData.isDemoUser);
      handleCheckQuota(editFormData.id);
    }
  }, []);

  if (loading) {
    return <LoadingScreen />;
  }

  return (
    <div className="text-primary text-base font-normal font-sans upload_doc_page">
      <div className="main_head">
        <h1 className="text-4xl font-bold">
          {!isEditing ? "Add Corporate User" : "Edit Corporate User"}
        </h1>
      </div>

      <div className=" flex w-full items-center h-full ">
        <form className="w-2/5">
          <div className="flex flex-col mb-3 contract-type">
            <div className="flex">
              <label className="mb-2">Name</label>
              <>
                <img
                  src={HelpIcon}
                  width={12}
                  className="ml-1 -mt-2 cursor-pointer"
                  data-tip="Insert full name"
                />
                <ReactTooltip backgroundColor="#400835" textColor="#ffffff" />
              </>
            </div>
            <input
              placeholder="Insert full name"
              className="border-2 border-primary rounded-lg outline-none p-1 pl-2"
              value={!isEditing ? userObj.name : editFormData.name}
              onChange={(e) => {
                !isEditing
                  ? setUserObj({ ...userObj, name: e.target.value })
                  : setEditFormData({
                      ...editFormData,
                      name: e.target.value,
                    });
              }}
            />
            <p className="text-sm text-red-500 font-normal">
              {demoUserFormErrors.name}
            </p>
          </div>

          <div className="flex flex-col mb-3">
            <div className="flex">
              <label className="mb-2">Designation</label>
              <>
                <img
                  src={HelpIcon}
                  width={12}
                  className="ml-1 -mt-2 cursor-pointer"
                  data-tip="Designation"
                />
                <ReactTooltip backgroundColor="#400835" textColor="#ffffff" />
              </>
            </div>
            <input
              placeholder="Designation"
              className="border-2 border-primary rounded-lg outline-none p-1 pl-2"
              value={
                !isEditing ? userObj.designation : editFormData.designation
              }
              onChange={(e) => {
                !isEditing
                  ? setUserObj({ ...userObj, designation: e.target.value })
                  : setEditFormData({
                      ...editFormData,
                      designation: e.target.value,
                    });
              }}
            />
            <p className="text-sm text-red-500 font-normal">
              {demoUserFormErrors.designation}
            </p>
          </div>

          <div className="flex flex-col mb-3">
            <div className="flex">
              <label className="mb-2">Email</label>
              <>
                <img
                  src={HelpIcon}
                  width={12}
                  className="ml-1 -mt-2 cursor-pointer"
                  data-tip="Insert an unique email address"
                />
                <ReactTooltip backgroundColor="#400835" textColor="#ffffff" />
              </>
            </div>
            <input
              placeholder="Insert an unique email address"
              className="border-2 border-primary rounded-lg outline-none p-1 pl-2"
              value={!isEditing ? userObj.email : editFormData.email}
              onChange={(e) => {
                !isEditing
                  ? setUserObj({ ...userObj, email: e.target.value })
                  : setEditFormData({
                      ...editFormData,
                      email: e.target.value,
                    });
              }}
            />
            <p className="text-sm text-red-500 font-normal">
              {demoUserFormErrors.email}
            </p>
          </div>

          <div className="flex flex-col mb-3">
            <div className="flex">
              <label className="mb-2">Company Name</label>
              <>
                <img
                  src={HelpIcon}
                  width={12}
                  className="ml-1 -mt-2 cursor-pointer"
                  data-tip="Insert company name"
                />
                <ReactTooltip backgroundColor="#400835" textColor="#ffffff" />
              </>
            </div>
            <input
              placeholder="Insert company name"
              className="border-2 border-primary rounded-lg outline-none p-1 pl-2"
              value={
                !isEditing ? userObj.companyName : editFormData.companyName
              }
              onChange={(e) => {
                !isEditing
                  ? setUserObj({ ...userObj, companyName: e.target.value })
                  : setEditFormData({
                      ...editFormData,
                      companyName: e.target.value,
                    });
              }}
            />
            <p className="text-sm text-red-500 font-normal">
              {demoUserFormErrors.companyName}
            </p>
          </div>
          {!isEditing && (
            <div className="flex flex-col mb-3">
              <div className="flex">
                <label className="mb-2">Password</label>
                <>
                  <img
                    src={HelpIcon}
                    width={12}
                    className="ml-1 -mt-2 cursor-pointer"
                    data-tip="Insert password"
                  />
                  <ReactTooltip backgroundColor="#400835" textColor="#ffffff" />
                </>
              </div>
              <div className="password-container">
                <input
                  placeholder="*****"
                  type={isShowPassword ? "text" : "password"}
                  className="border-2 border-primary rounded-lg outline-none p-1 pl-2 w-full"
                  value={!isEditing ? userObj.password : editFormData.password}
                  onChange={(e) => {
                    !isEditing
                      ? setUserObj({ ...userObj, password: e.target.value })
                      : setEditFormData({
                          ...editFormData,
                          password: e.target.value,
                        });
                  }}
                />
                <img
                  src={isShowPassword ? eye_on : eye_off}
                  onClick={() => setIsShowPassword(!isShowPassword)}
                  className="eye-icon"
                />
              </div>
              <p className="text-sm text-red-500 font-normal">
                {demoUserFormErrors.password}
              </p>
            </div>
          )}

          <div className="flex flex-col mb-3">
            <div className="flex">
              <label className="mb-2">Select Plan</label>
              <>
                <img
                  src={HelpIcon}
                  width={12}
                  className="ml-1 -mt-2 cursor-pointer"
                  data-tip="Select Plan"
                />
                <ReactTooltip backgroundColor="#400835" textColor="#ffffff" />
              </>
            </div>

            <select
              className="border-2 border-primary rounded-lg outline-none p-1 pl-2"
              value={!isEditing ? userObj.planId : editFormData.planId}
              onChange={(e) => {
                !isEditing
                  ? setUserObj({ ...userObj, planId: e.target.value })
                  : setEditFormData({
                      ...editFormData,
                      planId: e.target.value,
                    });
              }}
              // disabled={isEditing}
            >
              <option value="">Select Plan</option>
              {planList.map((plan) => (
                <option key={plan.id} value={plan.id}>
                  {plan.name}
                </option>
              ))}
            </select>
            <p className="text-sm text-red-500 font-normal">
              {demoUserFormErrors.planId}
            </p>
          </div>

          {userObj.isDemoUser && (
            <>
              {isAlreadyDemoUser && (
                <div className="flex flex-col mb-3">
                  <div className="flex">
                    <label className="mb-2">Duration</label>
                    <>
                      <img
                        src={HelpIcon}
                        width={12}
                        className="ml-1 -mt-2 cursor-pointer"
                        data-tip="Select Duration"
                      />
                      <ReactTooltip
                        backgroundColor="#400835"
                        textColor="#ffffff"
                      />
                    </>
                  </div>

                  <select
                    className="border-2 border-primary rounded-lg outline-none p-1 pl-2"
                    value={!isEditing ? userObj.days : editFormData.days}
                    onChange={(e) => {
                      if (!isEditing) {
                        setUserObj({ ...userObj, days: e.target.value });
                      } else {
                        setEditFormData({
                          ...editFormData,
                          days: e.target.value,
                        });
                      }
                    }}
                    disabled={isEditing}
                  >
                    <option value="">Select Duration</option>
                    <option value="7">7 Days</option>
                    <option value="15">15 Days</option>
                    <option value="30">30 Days</option>
                  </select>
                  <p className="text-sm text-red-500 font-normal">
                    {demoUserFormErrors.days}
                  </p>
                </div>
              )}
            </>
          )}

          <div className="flex flex-col mb-3">
            <div className="flex">
              <label className="mb-2">Is Demo User</label>
              <>
                <img
                  src={HelpIcon}
                  width={12}
                  className="ml-1 -mt-2 cursor-pointer"
                  data-tip="Is Demo User"
                />
                <ReactTooltip backgroundColor="#400835" textColor="#ffffff" />
              </>
            </div>

            <div className="flex">
              <div className="toggle-switch">
                <input
                  type="checkbox"
                  checked={
                    !isEditing ? userObj.isDemoUser : editFormData.isDemoUser
                  }
                  onChange={(e) => {
                    if (!isEditing) {
                      setUserObj({ ...userObj, isDemoUser: e.target.checked });
                    } else {
                      setUserObj({ ...userObj, isDemoUser: e.target.checked });
                      setEditFormData({
                        ...editFormData,
                        isDemoUser: e.target.checked,
                      });
                    }
                  }}
                  className="checkbox"
                  name={"isDemoUser"}
                  id={"isDemoUser"}
                />
                <label className="label" htmlFor={"isDemoUser"}>
                  <span className="inner" />
                  <span className="switch" />
                </label>
              </div>
            </div>
          </div>
          <div className="flex flex-col mb-3">
            <div className="flex">
              <label className="mb-2">Are Generic Templates Require</label>
              <>
                <img
                  src={HelpIcon}
                  width={12}
                  className="ml-1 -mt-2 cursor-pointer"
                  data-tip="Is Generic Templates Require"
                />
                <ReactTooltip backgroundColor="#400835" textColor="#ffffff" />
              </>
            </div>

            <div className="flex">
              <div className="toggle-switch">
                <input
                  type="checkbox"
                  checked={
                    !isEditing ? userObj.isGenericTemplatesRequire : editFormData.isGenericTemplatesRequire
                  }
                  onChange={(e) => {
                    if (!isEditing) {
                      setUserObj({ ...userObj, isGenericTemplatesRequire: e.target.checked });
                    } else {
                      setUserObj({ ...userObj, isGenericTemplatesRequire: e.target.checked });
                      setEditFormData({
                        ...editFormData,
                        isGenericTemplatesRequire: e.target.checked,
                      });
                    }
                  }}
                  className="checkbox"
                  name={"isGenericTemplatesRequire"}
                  id={"isGenericTemplatesRequire"}
                />
                <label className="label" htmlFor={"isGenericTemplatesRequire"}>
                  <span className="inner" />
                  <span className="switch" />
                </label>
              </div>
            </div>
          </div>
          
          {isEditing && (
            <div className="flex flex-col mb-3">
              <div className="flex">
                <label className="mb-2">Is Active User</label>
                <>
                  <img
                    src={HelpIcon}
                    width={12}
                    className="ml-1 -mt-2 cursor-pointer"
                    data-tip="Is Active User"
                  />
                  <ReactTooltip backgroundColor="#400835" textColor="#ffffff" />
                </>
              </div>

              <div className="flex">
                <div className="toggle-switch">
                  <input
                    type="checkbox"
                    checked={
                      !isEditing
                        ? userObj.is_active == "Yes"
                        : editFormData.is_active == "Yes"
                    }
                    onChange={(e) => {
                      const isChecked = e.target.checked;
                      const isActiveUser = isChecked ? "Yes" : "No";

                      if (!isEditing) {
                        setUserObj({
                          ...userObj,
                          is_active_user: isActiveUser,
                        });
                      } else {
                        // console.log("check", userObj);
                        setUserObj({
                          ...userObj,
                          is_active_user: isActiveUser,
                        });
                        setEditFormData({
                          ...editFormData,
                          is_active: isActiveUser,
                        });
                      }
                    }}
                    className="checkbox"
                    name={"isActiveUser"}
                    id={"isActiveUser"}
                  />
                  <label className="label" htmlFor={"isActiveUser"}>
                    <span className="inner" />
                    <span className="switch" />
                  </label>
                </div>
              </div>
            </div>
          )}

          {!isEditing ? (
            <>
              <button
                className="bg-primary font-bold text-white w-full p-1 rounded-lg mt-3"
                onClick={(e) => {
                  e.preventDefault();
                  saveOrEditDemoUser(userObj, updatePermissionList);
                }}
              >
                Add User
              </button>
              <p className="text-sm text-red-500 font-normal">
                {demoUserFormErrors.document}
              </p>
            </>
          ) : (
            <button
              className="bg-secondary font-bold text-white w-full p-1 rounded-lg"
              onClick={(e) => {
                e.preventDefault();
                saveOrEditDemoUser(editFormData, updatePermissionList);
              }}
            >
              Update User
            </button>
          )}
        </form>
        {isEditing && (
          <div className="w-3/5">
            <div
              className="text-center modal-container"
              style={{
                border: "1px solid black",
                marginLeft: "10%",
                borderRadius: "5px",
              }}
            >
              <h1 className="text-primary font-bold text-lg mb-2">
                Subscription Benefits and Balance
              </h1>
              <div className="mt-2 flex flex-col table-container mb-5 permission-quota-box">
                <table className="table-auto">
                  <thead>
                    <tr>
                      <th className="px-4 py-2">Items​</th>
                      <th className="px-4 py-2">Allotted Quota</th>
                      <th className="px-4 py-2">Balance</th>
                    </tr>
                  </thead>
                  <tbody>
                    {user.user_type == "admin" && (
                      <tr>
                        <td className="border px-4 py-2">Number of Users</td>
                        <td className="border px-4 py-2">
                          {permissionList.user_allotted_cnt == -1
                            ? "Unlimited"
                            : permissionList.user_allotted_cnt}
                        </td>
                        <td className="border px-4 py-2">
                          {permissionList.user_allotted_cnt == -1
                            ? "Unlimited"
                            : permissionList.user_remaing_cnt}
                        </td>
                      </tr>
                    )}

                    <tr>
                      <td className="border px-4 py-2">Document Uploads</td>
                      <td className="border px-4 py-2">
                        {permissionList.document_allotted_cnt == -1
                          ? "Unlimited"
                          : permissionList.document_allotted_cnt}
                      </td>
                      <td className="border px-4 py-2">
                        {permissionList.document_allotted_cnt == -1
                          ? "Unlimited"
                          : permissionList.document_remaing_cnt}
                      </td>
                    </tr>

                    <tr>
                      <td className="border px-4 py-2">Custom Templates</td>
                      <td className="border px-4 py-2">
                        {permissionList.custom_template_allotted_cnt == -1
                          ? "Unlimited"
                          : permissionList.custom_template_allotted_cnt}
                      </td>
                      <td className="border px-4 py-2">
                        {permissionList.custom_template_allotted_cnt == -1
                          ? "Unlimited"
                          : permissionList.custom_template_remaing_cnt}
                      </td>
                    </tr>

                    <tr>
                      <td className="border px-4 py-2">Custom Forms</td>
                      <td className="border px-4 py-2">
                        {permissionList.legal_template_allotted_cnt == -1
                          ? "Unlimited"
                          : permissionList.legal_template_allotted_cnt}
                      </td>
                      <td className="border px-4 py-2">
                        {permissionList.legal_template_allotted_cnt == -1
                          ? "Unlimited"
                          : permissionList.legal_template_remaing_cnt}
                      </td>
                    </tr>

                    <tr>
                      <td className="border px-4 py-2">
                        Generate documents using AI Editor​
                      </td>
                      <td className="border px-4 py-2">
                        {permissionList.ai_template_allotted_cnt == -1
                          ? "Unlimited"
                          : permissionList.ai_template_allotted_cnt}
                      </td>
                      <td className="border px-4 py-2">
                        {permissionList.ai_template_allotted_cnt == -1
                          ? "Unlimited"
                          : permissionList.ai_template_remaing_cnt}
                      </td>
                    </tr>

                    <tr>
                      <td className="border px-4 py-2">E-Signatures</td>
                      <td className="border px-4 py-2">
                        {permissionList.e_signature_allotted_cnt == -1
                          ? "Unlimited"
                          : permissionList.e_signature_allotted_cnt}
                      </td>
                      <td className="border px-4 py-2">
                        {permissionList.e_signature_allotted_cnt == -1
                          ? "Unlimited"
                          : permissionList.e_signature_remaing_cnt}
                      </td>
                    </tr>

                    <tr>
                      <td className="border px-4 py-2">Aadhaar Signatures</td>
                      <td className="border px-4 py-2">Pay as you use​</td>
                      <td className="border px-4 py-2">
                        {permissionList.aadhar_quota}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        )}
      </div>
      {isEditing && (
        <div className="flex flex-col w-full">
          <AdminUserList from="DemoUserForm" />
        </div>
      )}

      <AppModal
        title={modalData.title}
        bodyText={modalData.bodyText}
        setIsOpen={setIsOpen}
        modalIsOpen={modalIsOpen}
        modelAction="DemoUserForm"
        apiResStatus={modalData.apiResStatus}
      />
    </div>
  );
};

export default DemoUserForm;
