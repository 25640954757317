import React, { useEffect, useRef, useState } from "react";
import { QRCodeSVG } from "qrcode.react";
import SignatureCanvas from "react-signature-canvas";
import { v4 as uuidv4 } from "uuid";
import useAxios from "../../hooks/useAxios";
import axios from "axios";
import { typeOfSign } from "../../constants/constants";
import clearddd from "../../assets/icons/clear.png";
import downloadd1 from "../../assets/icons/downloadd1.png";
import mobile1 from "../../assets/icons/mobile1.png";
import editt from "../../assets/icons/editt.png";
import { useTranslation } from 'react-i18next';

import "./modal.css";
const id = uuidv4();
const Signature = ({
  setIsSignModalOpen,
  canvasType,
  setSrc,
  recptId,
  setCompanyName,
  companyName,
}) => {
  const [formData, setFormData] = useState(null);
  const api = useAxios();
  const { t } = useTranslation();
  const [padState, setPadState] = useState("draw");
  const signRef = useRef();
  const inputRef = useRef();
  const [showQr, setShowQr] = useState(false);
  const [penColor, setPenColor] = useState("black");
  const [image, setImage] = useState();

  useEffect(() => {
    
    if (showQr) {
      const sse = new EventSource(
        `${process.env.REACT_APP_BACKEND_BASE_URL}/contract/stream/${id}/`
      );
      function getRealtimeData(data) {
   
        if (data.data) {
          setSrc(`data:/${data.data}`);
          sse.close();
          setIsSignModalOpen(false);
        }
      }
      sse.onmessage = (e) => getRealtimeData(e);
      sse.onerror = () => {
       
        // error log here
        sse.close();
      };
      return () => {
        sse.close();
        // setSrc('');
      };
    }
  }, [showQr]);

  return (
    <div>
      {canvasType == "signature" || canvasType == "stamp" ? (
        <div className="haaderd">
          <h4>
            <img
              src={editt}
              alt=""
              style={{
                width: "18px",
              }}
            />
            {t('form.Signature Pad')}
          </h4>
          <span className="closd3">
            <button
              onClick={(e) => {
                e.preventDefault();
                setIsSignModalOpen(false);
              }}
              className="btn btn_theme"
            >
              X
            </button>
          </span>
        </div>
      ) : null}

      {canvasType == "signature" || canvasType == "stamp" ? (
        <div
          className={
            padState == "qrcode" ? "signature_qr_pad_box" : "signature_pad_box"
          }
        >
          {padState === "draw" || padState === "upload" ? (
            <>
              {/* <p>Please provide your signature here</p> */}
              <div className="seg_des_us">
                <div className="signa_box">
                  {padState === "draw" && (
                    <div
                      className="dekd"
                      style={{
                        display: "flex",
                        justifyContent: "flex-end",
                      }}
                    >
                      <button
                        style={{
                          marginRight: "5px",
                          background: "black",
                          border: "none",
                          width: 20,
                          height: 20,
                          borderRadius: "50%",
                          border: "1px solid gray",
                          cursor: "pointer",
                          color: "white",
                          fontWeight: "bolder",
                        }}
                        onClick={() => {
                          if (penColor !== "black") {
                            signRef.current.clear();
                          }
                          setPenColor("black");
                        }}
                      >
                        {penColor === "black" && <span>&#10003;</span>}
                      </button>
                      <button
                        style={{
                          background: "blue",
                          border: "none",
                          width: 20,
                          height: 20,
                          borderRadius: "50%",
                          border: "1px solid gray",
                          cursor: "pointer",
                          color: "white",
                          fontWeight: "bolder",
                        }}
                        onClick={() => {
                          if (penColor !== "blue") {
                            signRef.current.clear();
                          }
                          setPenColor("blue");
                        }}
                      >
                        {penColor === "blue" && <span>&#10003;</span>}
                      </button>
                    </div>
                  )}
                  {padState === "draw" ? (
                    <>
                      <SignatureCanvas
                        ref={signRef}
                        penColor={penColor}
                        canvasProps={{
                          width: 500,
                          height: 200,
                          className: "sigCanvas",
                        }}
                        onEnd={() => {
                          const url = signRef.current
                            .getTrimmedCanvas()
                            .toDataURL("image/png");
                          fetch(url).then((res) => {
                            res.blob().then(function (blob) {
                              var fd = new FormData();
                              fd.append("image", blob, "filename");
                              setFormData(blob);
                              let f = new FileReader();
                              f.readAsDataURL(blob);
                              f.onload = () => setImage(f.result);
                            });
                          });
                        }}
                      />
                    </>
                  ) : (
                    <div className="img-preview">
                      <div className="closd3">
                        <button
                          onClick={() => {
                            setImage("");
                            inputRef.current.value = "";
                            setPadState("draw");
                          }}
                          className="btn btn_theme"
                        >
                          X
                        </button>
                      </div>
                      {image && (
                        <img
                          src={image}
                          width={200}
                          height={150}
                          style={{ display: "inline-block" }}
                        />
                      )}
                    </div>
                  )}
                </div>
                {padState === "draw" && (
                  <p className="mb-3 use_li">
                    <button
                      className="btn btn_theme btn-sm "
                      onClick={() => {
                        signRef.current.clear();
                      }}
                    >
                      <img
                        src={clearddd}
                        alt=""
                        style={{
                          width: "18px",
                        }}
                      />
                    </button>
                    {t('form.Sign Above')}
                  </p>
                )}
                <div className="row justify-center mb_us">
                  <div className="col">
                    <button
                      className="btn btn_theme2"
                      onClick={() => {
                        inputRef.current.click();
                      }}
                    >
                      <img
                        className="mr-2"
                        src={downloadd1}
                        alt=""
                        style={{
                          width: "18px",
                          display: "inline-block",
                        }}
                      />
                      {t('form.Load Signature From File')}
                    </button>
                    <input
                      ref={inputRef}
                      hidden
                      type="file"
                      accept=".png, .jpeg"
                      onChange={(e) => {
                        if (e.target.files.length > 0) {
                          setImage("");
                          setPadState("upload");
                          setFormData(e.target.files[0]);
                          let f = new FileReader();
                          f.readAsDataURL(e.target.files[0]);
                          f.onload = () => setImage(f.result);
                        } else {
                          setImage("");
                          setPadState("draw");
                        }
                      }}
                    />
                  </div>
                  <div className="col">
                    <button
                      onClick={() => {
                        setImage("");
                        setPadState("qrcode");
                        setShowQr(true);
                      }}
                      className="btn btn_theme2"
                    >
                      <img
                        className="mr-2"
                        src={mobile1}
                        alt=""
                        style={{
                          width: "18px",
                          display: "inline-block",
                        }}
                      />
                      {t('form.Use Mobile Device')}
                    </button>
                  </div>
                </div>
                <div className="document_content">
                    <p>
                      {t('form.By clicking on Save Signature, I agree that the chosen signature/initials will be a valid electronic representation of my hand-written signature/initials for all purposes when it is used on documents, including legally binding contracts.')}
                    </p>
                </div>
                
              </div>

              <div className="footer_bg">
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    setIsSignModalOpen(false);
                  }}
                  className="btn btn_theme4"
                >
                  {t('common.Close')}
                </button>
                {padState !== "qrcode" && (
                  <>
                    
                    <button
                      className="btn btn_theme"
                      onClick={() => {
                        setSrc(image);
                        setIsSignModalOpen(false);
                        // setSrc('');
                        const data = new FormData();
                        data.append("image", formData);
                        data.append("document_recipients_id", recptId);
                        data.append("qrcode_id", id);
                        data.append("type_of_sign", typeOfSign.SIGNATURE);

                        data.append(
                          "webhook_url",
                          `${process.env.REACT_APP_BACKEND_BASE_URL}/contract/webhook_signature_pad/`
                        );
                        data.append("is_mobile", "false");
                        axios
                          .post(
                            `${process.env.REACT_APP_BACKEND_BASE_URL}/contract/save-image/`,
                            data,
                            {
                              headers: {
                                "Content-Type": "multipart/form-data",
                              },
                            }
                          )
                          .then((res) => {
                            setSrc("");
                          })
                          .catch((err) => {});
                      }}
                    >
                      {t('form.Save Signature')}
                    </button>
                  </>
                )}
              </div>
            </>
          ) : (
            <div className="qr_coded1">
              <div className="row">
                <div className="col-md-8">
               
                  <QRCodeSVG
                    style={{ width: "60%", height: "50%" }}
                    value={`${process.env.REACT_APP_FRONT_END_BASE_URL}/qr-signature/${recptId}/${id}`}
                  />
                </div>
                <div className="col-md-4">
                  <p>
                    {t('form.Scan the QR with your mobile device to sign the document')}
                  </p>
                </div>
              </div>
            </div>
          )}
        </div>
      ) : null}
    </div>
  );
};

export default Signature;
