import React from 'react';
import Modal from 'react-modal';
import './modal.css';
import { useState } from 'react';
import { resolve } from '../../api/resolve';
import useAxios from '../../hooks/useAxios';
import { toast } from 'react-toastify';
import { useEffect } from 'react';
import { autoCloseTimer, delay } from '../../constants/constants';
import { useTranslation } from 'react-i18next';

const customStyles = {
  content: {
    top: '30%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    maxWidth: '400px',
    maxHeight: '300px',
    borderRadius: '11px',
  },
  overlay: {
    backgroundColor: 'rgba(64, 8, 53, 0.63)',
  },
};

Modal.setAppElement('#root');
const RejectKycDocModal = ({
  rejectDocModelOpen,
  setRejectDocModelOpen,
  docId,
  userId,
  getAdminDetail,
}) => {

  const [reason, setReason] = useState('');
  const [reasonErr, setReasonErr] = useState('');

  const api = useAxios();
  const { t } = useTranslation();

  const rejectAuthDocument = async () => {
    setReasonErr('');
    if (reason.trim().length === 0) {
      setReasonErr(t('form.Please enter reason'));
    } else {
      const postData = {
        user_id: userId,
        doc_id: docId,
        is_approved: false,
        rejection_reason: reason,
      }
      const res = await resolve(api.post(`/user/admin/AcceptReject/`, postData));
      if (res.data) {
        setReason('');
        setReasonErr('');
        setRejectDocModelOpen(false);
        toast.success(t('form.Document rejected successfully'), {
          autoClose: autoCloseTimer,
          hideProgressBar: true,
        });
        getAdminDetail();
      }
      if (res.error) {
        toast.error(t('form.Something went wrong'), {
          autoClose: autoCloseTimer,
          hideProgressBar: true,
        });
      }
    }
  };



  return (
    <div className=''>
      <Modal
        isOpen={rejectDocModelOpen}
        onRequestClose={() => setRejectDocModelOpen(false)}
        style={customStyles}
        contentLabel='Example Modal'
        onAfterClose={() => { }}
      >

        <div className='text-center modal-container'>
          <h1 className='text-primary font-bold text-lg'>{t('form.Reject Document')}</h1>
          <div className='mt-5 flex flex-col justify-center'>
            <label className='flex flex-start mb-1'>{t('form.Reason')}:</label>

            <textarea
              className='border-2 border-primary rounded-lg outline-none p-1 pl-2 '
              onChange={(e) => setReason(e.target.value)}
              value={reason}
            ></textarea>

            <p className='flex flex-start error_msg'>{reasonErr}</p>
          </div>

          <div className='flex justify-center mt-5'>
            <button
              className='bg-primary hover:bg-secondary font-semibold text-white p-1 pl-8 pr-8 rounded-lg mr-5'
              onClick={(e) => {
                e.stopPropagation();
                rejectAuthDocument();
              }}
            >
              {t('form.Confirm')}
            </button>
            <button
              className='bg-[#606060] font-semibold text-white p-1 pl-8 pr-8 rounded-lg'
              onClick={(e) => {
                e.stopPropagation();
                setRejectDocModelOpen(false);
              }}
            >
              {t('common.Cancel')}
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default RejectKycDocModal;
