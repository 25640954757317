import React, { useEffect, useState, useContext } from 'react';
import dayjs from 'dayjs';
import CommonModule from './CommonComponentlegacy';
import useAxios from '../../hooks/useAxios';
import { resolve } from '../../api/resolve';
import AuthContext from '../../context/AuthContext';
import { licencingTableColumns } from '../../constants/constants';

const formattedColumns = licencingTableColumns.filter(
  (obj) => obj.Header !== 'Company'
);
const Licencinglegacy = () => {
  const api = useAxios();
  const { user } = useContext(AuthContext);
  const [ssoSetting, setSSOSetting] = useState([]);
  const [nextPage, setIsNextPage] = useState(null);
  const [prevPage, setIsPrevPage] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [errMsg, setErrMsg] = useState('');
  const [pageLoading, setPageLoading] = useState(true);
  const [sortingOrder, setSortingOrder] = useState('desc');
  const [sortColumn, setSortColumn] = useState('created_at');

  const getLicencingInfo = async () => {
    const res = await resolve(
      api
        .get(`/user/licence/key/?page=${currentPage}`)
        .then((res) => res.data)
    );
    
    if (res.data) {
      // const { results, next, previous } = res.data.data;
      const results = res.data.results;
      console.log('results', results);
      const next = 0;
      const previous = 0;
      const filterData = [];
      if (results.length > 0) {
        results.forEach((result) => {
          const ssObj = {
            licence_key: result.licence_key,
            id: result.id,
          };
          filterData.push(ssObj);
        });
      } else {
        setErrMsg('No key added yet');
      }
      setSSOSetting([...filterData]);
      setIsNextPage(next);
      setIsPrevPage(previous);
    }
    setPageLoading(false);
  };

  const searchUser = async (searchText, companyID) => {
    getLicencingInfo();
  };
  useEffect(() => {
    getLicencingInfo();
  }, [currentPage, sortColumn]);
  return (
    <CommonModule
      heading='Licensing'
      inputPlaceholder='search...'
      addBtnText='Add Licensing'
      errMsg={errMsg}
      moduleData={ssoSetting}
      moduleTableColumns={
        user.user_type === 'super_admin' ? licencingTableColumns : formattedColumns
      }
      currentPage={currentPage}
      setCurrentPage={setCurrentPage}
      isCompany={true}
      nextPage={nextPage}
      prevPage={prevPage}
      getSearchData={searchUser}
      activeLinkTitle='LicencingForm'
      pageLoading={pageLoading}
      sortingOrder={sortingOrder}
      setSortingOrder={setSortingOrder}
      setSortColumn={setSortColumn}
      getModuleData={getLicencingInfo}
    />
  );
};

export default Licencinglegacy;
