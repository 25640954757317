import React, { useContext, useEffect, useState } from "react";
import Modal from "react-modal";
import HelpIcon from "../../assets/icons/Help-sm.svg";
import Select from "react-select";
import { useTranslation } from "react-i18next";

const customStyles = {
  content: {
    top: "40%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    maxWidth: "450px",
    minHeight: "400px",
    borderRadius: "11px",
    minWidth: "300px",
  },
  overlay: {
    backgroundColor: "rgba(64, 8, 53, 0.63)",
  },
  // menuPortal: base => ({ ...base, zIndex: 9999 })
};

Modal.setAppElement("#root");
const PlayriteDiligenceModal = ({
  isModalOpen,
  setIsModalOpen,
  file,
  uploadQuoqoDeligence,
  sectionTaskList,
  countryListData,
  sectionList,
  playBookList,
  getSectionTaskList,
  inputRef,
  setSectionTaskList,
}) => {
  const usa_states = [
    { name: "Alabama" },
    { name: "Alaska" },
    { name: "Arizona" },
    { name: "Arkansas" },
    { name: "California" },
    { name: "Colorado" },
    { name: "Connecticut" },
    { name: "Delaware" },
    { name: "Florida" },
    { name: "Georgia" },
    { name: "Hawaii" },
    { name: "Idaho" },
    { name: "Illinois" },
    { name: "Indiana" },
    { name: "Iowa" },
    { name: "Kansas" },
    { name: "Kentucky" },
    { name: "Louisiana" },
    { name: "Maine" },
    { name: "Maryland" },
    { name: "Massachusetts" },
    { name: "Michigan" },
    { name: "Minnesota" },
    { name: "Mississippi" },
    { name: "Missouri" },
    { name: "Montana" },
    { name: "Nebraska" },
    { name: "Nevada" },
    { name: "New Hampshire" },
    { name: "New Jersey" },
    { name: "New Mexico" },
    { name: "New York" },
    { name: "North Carolina" },
    { name: "North Dakota" },
    { name: "Ohio" },
    { name: "Oklahoma" },
    { name: "Oregon" },
    { name: "Pennsylvania" },
    { name: "Rhode Island" },
    { name: "South Carolina" },
    { name: "South Dakota" },
    { name: "Tennessee" },
    { name: "Texas" },
    { name: "Utah" },
    { name: "Vermont" },
    { name: "Virginia" },
    { name: "Washington" },
    { name: "West Virginia" },
    { name: "Wisconsin" },
    { name: "Wyoming" },
  ];

  const [selectedCountry, setSelectedCountry] = useState("");

  const handleGoverningLawChange = (e) => {
    const selectedValue = e.target.value;
    setFormData({ ...formData, governing_law: selectedValue }); // Update governing_law with selected value

    // Determine if the selected value is a country or a state
    const isCountry = countryListData.some(
      (country) => country.name === selectedValue
    );
    if (isCountry) {
      setSelectedCountry(selectedValue);
    } else {
      const sk = "United States of America";
      setSelectedCountry(sk);
    }
  };

  const cancelModel = () => {
    setSelectedCountry("");
    setIsModalOpen(false);
  };

  const { t } = useTranslation();
  const [formData, setFormData] = useState({
    selectedPerspective: "",
    taskDescription: "",
    sectionId: "",
    playbookId: "",
  });
  const [formDataErr, setFormDataErr] = useState({
    selectedPerspective: "",
    taskDescription: "",
    sectionId: "",
    playbookId: "",
  });

  const handleUploadQuoqoDeligence = () => {
    for (const key in formDataErr) {
      if (formDataErr.hasOwnProperty(key)) {
        formDataErr[key] = "";
      }
    }

    if (formData.playbookId == "") {
      setFormDataErr({
        ...formDataErr,
        playbookId: t("allModule.Please select Playbook"),
      });
    } else if (formData.sectionId == "") {
      setFormDataErr({
        ...formDataErr,
        sectionId: t("constant.Please select section"),
      });
    } else if (formData.playbookId == "") {
      setFormDataErr({
        ...formDataErr,
        playbookId: t("allModule.Please select Playbook"),
      });
    } else if (formData.selectedPerspective == "") {
      setFormDataErr({
        ...formDataErr,
        selectedPerspective: t("constant.Please select task"),
      });
    } else {
      uploadQuoqoDeligence(formData, file);
    }
  };

  return (
    <div className="">
      <Modal
        isOpen={isModalOpen}
        onRequestClose={() => {}}
        style={customStyles}
        contentLabel="Example Modal"
        onAfterClose={() => {
          setSectionTaskList([]);
          if (inputRef.current?.value) {
            inputRef.current.value = "";
          }
          setFormData({
            selectedPerspective: "",
            taskDescription: "",
            sectionId: "",
            playbookId: "",
          });
          setFormDataErr({
            selectedPerspective: "",
            taskDescription: "",
            sectionId: "",
            playbookId: "",
          });
        }}
      >
        <div className="text-left modal-container">
          <p className="text-primary font-normal text-md mt-2">
            {t("form.File Name")}:{" "}
            {file.length > 0 &&
              file.map((file, index) => <li key={index}>{file.name}</li>)}
          </p>
          <div className="mt-5 flex flex-col justify-center">
            <label className="text-primary font-normal text-md">
              {t("allModule.Playbook")}:
            </label>
            <select
              className="border-2 border-primary rounded-lg outline-none p-1 pl-2 w-full"
              value={formData.playbookId}
              onChange={(e) => {
                setFormData({ ...formData, playbookId: e.target.value });
              }}
            >
              <option value={0}>{t("allModule.Select Playbook")}</option>
              {playBookList.map((item, index) => (
                <option key={index} value={item.id}>
                  {item.name}
                </option>
              ))}
            </select>
            <p className="flex flex-start error_msg">
              {formDataErr.playbookId}
            </p>
          </div>

          <div className="flex flex-col justify-center">
            <label className="text-primary font-normal text-md">
              {t("menu.Section")}:
            </label>
            <select
              className="border-2 border-primary rounded-lg outline-none p-1 pl-2 w-full"
              value={formData.sectionId}
              onChange={(e) => {
                setFormData({ ...formData, sectionId: e.target.value });
                getSectionTaskList(e.target.value);
              }}
            >
              <option value={0}>{t("constant.Select Section")}</option>
              {sectionList.map((item, index) => (
                <option key={index} value={item.id}>
                  {item.section_name}
                </option>
              ))}
            </select>
            <p className="flex flex-start error_msg">{formDataErr.sectionId}</p>
          </div>
          <div className="flex flex-col justify-center">
            <label className="text-primary font-normal text-md">
              {t("menu.Task")}:
            </label>
            {/* style="background-image:url(male.png); */}
            <select
              value={formData.selectedPerspective}
              onChange={(e) => {
                const selectedId = e.target.value;
                const selectedTask = sectionTaskList.find(
                  (task) => task.id == selectedId
                );
                if (selectedTask) {
                  setFormData({
                    ...formData,
                    selectedPerspective: selectedId,
                    taskDescription: selectedTask.description,
                    superPrompt: selectedTask.task_name, // Save task_name along with taskDescription
                  });
                }
              }}
              className="border-2 border-primary rounded-lg outline-none p-1 pl-2 mr-2 display-tool-tip"
            >
              <option value="">{t("constant.Select Task")}</option>
              {sectionTaskList.map((value, index) => {
                return (
                  <option title={value.tool_tip} key={index} value={value.id}>
                    {value.description}
                  </option>
                );
              })}
            </select>
            <p className="flex flex-start error_msg">
              {formDataErr.selectedPerspective}
            </p>
          </div>

          {/* <div className="flex flex-col justify-center">
            <label className="text-primary font-normal text-md">
              {t("constant.Custom Prompt")}:
            </label>
            <input
              type="text"
              value={formData.customePrompt}
              onChange={(e) =>
                setFormData({ ...formData, customePrompt: e.target.value })
              }
              className="border-2 border-primary rounded-lg outline-none p-1 pl-2 "
              placeholder={t("constant.Custom Prompt")}
            />
          </div> */}
          <div className="flex justify-center mt-5">
            <button
              className="bg-primary hover:bg-secondary font-semibold text-white p-1 pl-8 pr-8 rounded-lg mr-5"
              onClick={(e) => {
                e.stopPropagation();
                handleUploadQuoqoDeligence();
              }}
            >
              {t("allModule.Upload")}
            </button>
            <button
              className="bg-[#606060] font-semibold text-white p-1 pl-8 pr-8 rounded-lg"
              onClick={() => cancelModel()}
            >
              {t("common.Cancel")}
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default PlayriteDiligenceModal;
