import React, { useEffect, useContext, useState } from "react";
import "./recipient.css";
import right_arrow from "../../assets/icons/right-arrow.png";
import DocumentContext from "../../context/DocumentContext";
import useAxios from "../../hooks/useAxios";
import { resolve } from "../../api/resolve";
import AppContext from "../../context/AppContext";
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
import magic_wand from "../../assets/icons/magic-wand.png";
import copydd from "../../assets/icons/copy.png";
import contact2 from "../../assets/icons/contact2.png";
import attach from "../../assets/icons/attach-paperclip-symbol.png";
import printing from "../../assets/icons/printing.png";
import contact_d from "../../assets/icons/contact.png";
import right_arrow2 from "../../assets/icons/right-arrow2.png";
import AuthContext from "../../context/AuthContext";
import { autoCloseTimer, delay } from "../../constants/constants";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import HelpIcon from "../../assets/icons/Help-sm.svg";
import ReactTooltip from "react-tooltip";
import LoadingScreen from "../loader/LoadingScreen";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import { useTranslation } from "react-i18next";
import PermissionContext from "../../context/PermissionContext";

const EmailNotifications = () => {
  const {
    documentListData,
    activeDocInfo,
    setActiveDocInfo,
    apiCurrentPage,
    setApiCurrentPage,
    getDocListWithStatusApi,
    apiDataLimit,
    nextBtnLimit,
    recipientDetail,
    setRecipientDetail,

    isAutoMagicApplied,
    isPresetsApplied,
    isManualApplied,
    isStampApplied,
    setIsAutoMagicApplied,
    setIsPresetsApplied,
    setIsManualApplied,
    setIsStampApplied,
    setRightMenuClickFromOtherPage,

    documentChange,
    setRecipientCount,
  } = useContext(DocumentContext);

  const {
    setActiveLink,
    getSasToken,
    highlightToken,
    getHighlightSasToken,
    setShowDropdown,
  } = useContext(AppContext);
  const { user } = useContext(AuthContext);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { permissionList } = useContext(PermissionContext);
  const [docUrl, setDocUrl] = useState("");
  const [loading, setLoading] = useState(false);
  const [sendEmailFormData, setSendEmailFormData] = useState({
    fromEmail: user.user_email,
    note: "",
  });
  const [handleFixed, setHandleFixed] = useState(true);

  const [sendEmailFormErr, setSendEmailFormErr] = useState({
    fromEmail: "",
    note: "",
  });
  const [isShowEmailBox, setIsShowEmailBox] = useState(false);

  const api = useAxios();
  const getDocumentInfoApi = async (documentId) => {
    setRecipientDetail([]);
    const responceData = await resolve(
      api
        .get(`/contract/document/recipients/list/${documentId}`)
        .then((res) => res.data)
    );

    if (responceData["data"] && responceData["data"].length > 0) {
      const tempRecipientDetail = [];
      let isDeligatee = false;
      responceData["data"].forEach((recipientInfo) => {
        if (recipientInfo.is_deligacy) {
          isDeligatee = true;
        }
        if (recipientInfo.user_contacts_id === null) {
          tempRecipientDetail.push({
            id: recipientInfo.user_id.id,
            name: recipientInfo.user_id.first_name,
            email: recipientInfo.user_id.email,
            phone: recipientInfo.user_id.phone,
            signingCapacity: recipientInfo.signingCapacity,
            typeOfSignature: recipientInfo.typeOfSignature,
            recptId: recipientInfo.id,
            is_deligacy: recipientInfo.is_deligacy,
          });
        } else {
          tempRecipientDetail.push({
            id: recipientInfo.user_contacts_id.id,
            name: recipientInfo.user_contacts_id.name,
            email: recipientInfo.user_contacts_id.email,
            phone: recipientInfo.user_contacts_id.phone,
            signingCapacity: recipientInfo.signingCapacity,
            typeOfSignature: recipientInfo.typeOfSignature,
            recptId: recipientInfo.id,
            is_deligacy: recipientInfo.is_deligacy,
          });
        }
      });
      if (isDeligatee || tempRecipientDetail.length > 1) {
        setIsShowEmailBox(true);
      } else {
        setIsShowEmailBox(false);
      }
      setRecipientDetail(tempRecipientDetail);
    }
  };
  const handleRightSectionResponsive = () => {
    setHandleFixed(!handleFixed);
  };
  const handleRightMenu = (menuName) => {
    if (menuName == "AutoMagic") {
      setRightMenuClickFromOtherPage(true);
    } else if (menuName == "Presets") {
      setRightMenuClickFromOtherPage(true);
    } else if (menuName == "ManualMode") {
      setRightMenuClickFromOtherPage(true);
    } else if (menuName == "AttachStampPaper") {
      setRightMenuClickFromOtherPage(true);
    }
    setActiveLink(menuName);
    setShowDropdown("StampsAndSeals");
  };

  const sendEmail = async () => {
    if (isShowEmailBox === false) {
      setLoading(true);
      let postData = {
        document_id: activeDocInfo.id,
        user_email: sendEmailFormData.fromEmail,
        note: sendEmailFormData.note,
        AffixMySign: true,
      };
      const responceData = await resolve(
        api
          .post(`/contract/document/email/send/`, JSON.stringify(postData), {
            headers: {
              "Content-Type": "application/json",
            },
          })
          .then((res) => res.data)
      );
      if (responceData["data"]) {
        // navigate(`/workflow/${recipientDetail[0].recptId}`);
        navigate(`/workflow/${responceData["data"].token}`);
      }
    } else {
      setSendEmailFormErr({ ...sendEmailFormErr, fromEmail: "", note: "" });
      if (sendEmailFormData.fromEmail == "") {
        setSendEmailFormErr({
          ...sendEmailFormErr,
          fromEmail: t("allModule.From email is required"),
        });
      } else if (sendEmailFormData.note == "") {
        setSendEmailFormErr({
          ...sendEmailFormErr,
          note: t("allModule.Note is required"),
        });
      } else {
        setLoading(true);
        let postData = {
          document_id: activeDocInfo.id,
          user_email: sendEmailFormData.fromEmail,
          note: sendEmailFormData.note,
          AffixMySign: false,
        };
        const responceData = await resolve(
          api
            .post(`/contract/document/email/send/`, JSON.stringify(postData), {
              headers: {
                "Content-Type": "application/json",
              },
            })
            .then((res) => res.data)
        );
        if (responceData["data"]) {
          setLoading(false);
          toast.success(t("allModule.Email sent successfully!"), {
            delay,
            autoClose: autoCloseTimer,
            onClose: () => setLoading(false),
          });
          setTimeout(() => {
            setActiveLink("Home");
            setShowDropdown("");
          }, 1000);
        }

        if (responceData["error"]) {
          setLoading(false);
          toast.error(t("allModule.Something went wrong."), {
            delay,
            autoClose: autoCloseTimer,
            onClose: () => setLoading(false),
          });
        }
      }
    }
  };

  useEffect(() => {
    getDocListWithStatusApi(apiCurrentPage, apiDataLimit, 6);
  }, [apiCurrentPage, apiDataLimit]);

  useEffect(() => {
    if (activeDocInfo?.id) {
      getDocumentInfoApi(activeDocInfo.id);
    }
  }, [activeDocInfo]);

  useEffect(() => {
    getSasToken();
    getHighlightSasToken();
  }, []);

  return (
    <div className="text-primary text-base font-normal font-sans mt_set">
      <div className="main_head">
        <div className="flex">
          <h1 className="text-4xl font-bold text_c">
            {t("allModule.Correspondence")}
          </h1>
          <>
            <img
              src={HelpIcon}
              width={12}
              className="ml-1 -mt-2 cursor-pointer"
              data-tip={t(
                "allModule.Email Notifications are sent to the recipients of the document. The recipients will receive an email with a link to the document. The email will also contain the note you enter below."
              )}
            />
            <ReactTooltip backgroundColor="#400835" textColor="#ffffff" />
          </>
        </div>
      </div>

      {permissionList["email_notify_on"] == false ? (
        <div className="flex justify-center items-center h-96 flex-column ">
          <h3 className="text-2xl font-bold text_c">
            {t("allModule.You dont have permission to access this page")}
          </h3>
        </div>
      ) : documentListData.length > 0 && loading == false ? (
        <>
          <div
            className={`row ${handleFixed && 0 ? "set_fix1" : ""}`}
            id="hd_show"
          >
            <div className="col-md-9">
              <div className="w-full doc_list_btn">
                <div className="flex flex-col mb-4">
                  <div className="btn_us3">
                    <button
                      className="btn pb-1 pt-0.5 pl-6 pr-6 text_c font-bold rounded-lg next-pre-btn "
                      onClick={() => {
                        if (apiCurrentPage > 1) {
                          setApiCurrentPage(apiCurrentPage - 1);
                        }
                      }}
                    >
                      <img src={right_arrow} className="right rotateIcon" />
                    </button>
                    {documentListData.map((item, index) => {
                      return (
                        <button
                          key={"doclist_" + index}
                          className={`set_w2 btn pb-1 pt-0.5 pl-6 pr-6 text_c font-semibold ${
                            item.id == activeDocInfo?.id ? "active" : ""
                          }`}
                          onClick={() => documentChange(item)}
                        >
                          {item.name}{" "}
                        </button>
                      );
                    })}
                    <button
                      onClick={() => {
                        if (apiCurrentPage < nextBtnLimit) {
                          setApiCurrentPage(apiCurrentPage + 1);
                        }
                      }}
                      className="btn pb-1 pt-0.5 pl-6 pr-6 text_c font-bold rounded-lg next-pre-btn float-right"
                    >
                      <img src={right_arrow} className="right" />
                    </button>
                  </div>
                </div>
              </div>
              <h4 className="text-xl font-bold  mb-2 text_c text-center">
                {" "}
                {activeDocInfo?.id ? activeDocInfo.name : ""}
              </h4>

              {isShowEmailBox === true ? (
                <>
                  <h4 className="text-xl font-bold  mb-2 text_c">
                    {" "}
                    <img src={contact_d} className="mr-1 w_d1" alt="" />{" "}
                    {t("allModule.Recipients")}
                  </h4>

                  <div className="box_u1 p-4">
                    <div className="pdf_box2">
                      {recipientDetail.map((recipient, index) => {
                        return (
                          <div
                            key={"recipient_" + index}
                            className="set_checkk border-2 border-primary p-3 rounded-lg mb-3"
                          >
                            <div className="haed_1">
                              <div className="flex">
                                <img
                                  className="ridh2 mr-1"
                                  src={right_arrow2}
                                />
                                <h4 className="text-md font-bold">
                                  {recipient.name}{" "}
                                  {recipient.is_deligacy ? "(Delegatee)" : ""}{" "}
                                  {recipient.email}{" "}
                                </h4>
                                <span className="ml-5">
                                  [{t("allModule.Mobile No")}. {recipient.phone}
                                  ]
                                </span>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                      <div className="flex">
                        <label style={{ marginRight: "10px" }}>
                          {t("allModule.Note")}:{" "}
                        </label>
                        <p className="error_msg">{sendEmailFormErr.note}</p>
                      </div>

                      <CKEditor
                        editor={ClassicEditor}
                        data={sendEmailFormData.note}
                        onReady={(editor) => {}}
                        onChange={(event, editor) => {
                          const data = editor.getData();
                          setSendEmailFormData({
                            ...sendEmailFormData,
                            note: data,
                          });
                        }}
                        config={{
                          placeholder: t("allModule.Message here...."),
                        }}
                      />
                    </div>
                  </div>
                </>
              ) : (
                <div className="p-4 ">
                  <div className="mt-5 flex justify-center">
                    <button
                      onClick={(e) => {
                        e.preventDefault();
                        setActiveLink("AttachStampPaper");
                        setShowDropdown("Recipients");
                        setApiCurrentPage(1);
                      }}
                      className="btn pb-1 pt-0.5 pl-6 pr-6 text_c font-bold rounded-lg btn_theme3 mr-5"
                    >
                      {t("allModule.Previous")}
                    </button>
                    <button
                      onClick={(e) => {
                        sendEmail();
                      }}
                      className="btn pb-1 pt-0.5 pl-6 pr-6 text_c font-bold rounded-lg btn_theme3"
                    >
                      {t("menu.Affix My Signature")}
                    </button>
                  </div>
                </div>
              )}
            </div>
            <div className="col-md-3">
              <div className="tool rounded-md">
                <ul>
                  <li
                    data-tip={t("menu.Automagic")}
                    style={{
                      backgroundColor: isAutoMagicApplied
                        ? "var(--orgg)"
                        : "transparent",
                    }}
                  >
                    <a
                      onClick={(e) => {
                        e.preventDefault();
                        navigate("/stamps-seals/automagic");
                        handleRightMenu("AutoMagic");
                      }}
                      href="#"
                    >
                      <img src={magic_wand} alt="" />
                    </a>
                  </li>

                  <li
                    data-tip={t("menu.Presets")}
                    style={{
                      backgroundColor: isPresetsApplied
                        ? "var(--orgg)"
                        : "transparent",
                    }}
                  >
                    <a
                      onClick={(e) => {
                        e.preventDefault();
                        navigate("/stamps-seals/presets");
                        handleRightMenu("Presets");
                      }}
                      href="#"
                    >
                      <img src={copydd} alt="" />
                    </a>
                  </li>

                  <li
                    data-tip={t("menu.Manual mode")}
                    style={{
                      backgroundColor: isManualApplied
                        ? "var(--orgg)"
                        : "transparent",
                    }}
                  >
                    <a
                      onClick={(e) => {
                        e.preventDefault();
                        navigate("/stamps-seals/manual-mode");
                        handleRightMenu("ManualMode");
                      }}
                      href="#"
                    >
                      <img src={contact2} alt="" />
                    </a>
                  </li>

                  <li
                    data-tip={t("menu.Attach stamp paper")}
                    style={{
                      backgroundColor: isStampApplied
                        ? "var(--orgg)"
                        : "transparent",
                    }}
                  >
                    <a
                      onClick={(e) => {
                        e.preventDefault();
                        navigate("/stamps-seals/attach-stamp-paper");
                        handleRightMenu("AttachStampPaper");
                      }}
                      href="#"
                    >
                      <img src={attach} alt="" />
                    </a>
                  </li>

                  <li
                    data-tip={t("menu.Email Notifications")}
                    className="active_left_menu"
                  >
                    <a
                      onClick={(e) => {
                        e.preventDefault();
                        navigate("/stamps-seals/email-notifications");
                        handleRightMenu("EmailNotifications");
                      }}
                      href="#"
                    >
                      <img src={printing} alt="" />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          {isShowEmailBox === true && (
            <div className="set_btn2 mt-4">
              <button
                onClick={(e) => {
                  e.preventDefault();
                  setActiveLink("AttachStampPaper");
                  setShowDropdown("Recipients");
                }}
                className="btn pb-1 pt-0.5 pl-6 pr-6 text_c font-bold rounded-lg btn_theme3"
              >
                {t("allModule.Previous")}
              </button>
              <button
                onClick={(e) => {
                  sendEmail();
                }}
                className="btn pb-1 pt-0.5 pl-6 pr-6 text_c font-bold rounded-lg btn_theme3"
              >
                {t("allModule.Submit")}
              </button>
            </div>
          )}
        </>
      ) : loading ? (
        <LoadingScreen />
      ) : (
        <div className="no_document_available_box">
          <p>{t("allModule.No Assigned Document Available.")}</p>
        </div>
      )}
    </div>
  );
};

export default EmailNotifications;
