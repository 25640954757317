import React, { useState, useContext } from "react";
import Modal from "react-modal";
import "./modal.css";
import useAxios from "../../hooks/useAxios";
import { resolve } from "../../api/resolve";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import AppContext from "../../context/AppContext";
import LoadingScreen from "../loader/LoadingScreen";

const format = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;

const customStyles = {
  content: {
    top: "40%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    minWidth: 300,
    maxWidth:400,
    maxHeight: "220px",
    borderRadius: "11px",
  },
  overlay: {
    backgroundColor: "rgba(64, 8, 53, 0.63)",
  },
};

Modal.setAppElement("#root");
const AddNewRepolegacy = ({
  modalIsOpen,
  setIsOpen,
  getRepos,
  setRepoID,
  isUpdating,
  setIsUpdating,
  parentid,
  parentRepoName,
  handleSubFolderClick,
}) => {
  const api = useAxios();
  const { t } = useTranslation();
  const [errMsg, setErrMsg] = useState("");
  const [repoName, setRepoName] = useState("");
  const [validationError, setValidationError] = useState("");
  const { getNotificationsCountlegacy } = useContext(AppContext);
  const [PageLoading, setPageLoading] = useState(false);

  const addNewRepo = async (repoName) => {
    if (repoName.trim().length > 0) {
      if (format.test(repoName)) {
        setValidationError(t("modal.Special Characters are not allowed"));
      } else if (
        repoName.toLowerCase() === "default" ||
        repoName.toLowerCase() === "shared repo"
      ) {
        setValidationError(t("fileBrowser.Repository name already exists"));
      } else {
        // setPageLoading(true);
        const res = await resolve(
          api
            .post(
              `/legacy_contract/view_create_repo/`,
              JSON.stringify({ repo_name: repoName, parent_repo_id: parentid }),
              {
                headers: {
                  "content-type": "application/json",
                },
              }
            )
            .then((res) => res.data)
        );
        if (res.data) {
          toast.success(t("fileBrowser.Repository added successfully"));
          if (parentid) {
            const formData = new FormData();
            formData.append("repo_name", repoName);
            formData.append("repo_ids", res.data.id);
            formData.append("parent_repo_id", parentid);
            const res2 = await resolve(
              api
                .post(`/legacy_contract/RepoParentListLevel/`, formData, {
                  headers: {
                    "content-type": "application/json",
                  },
                })
                .then((res2) => res2.data)
            );
          }
          setIsOpen(false);
          setRepoName("");
          getRepos();
          if (parentid) {
            const repo = {
              id: parentid,
              repo_name: parentRepoName,
            };
            handleSubFolderClick(repo);
          }
        } else {
          setIsOpen(false);
          toast.error(t("modal.Repository was not added. Try again"));
        }
        setTimeout(() => {
          getNotificationsCountlegacy();
        }, 2000);
        setPageLoading(false);
      }
    }
  };
  if (PageLoading) {
    return <LoadingScreen />;
  }
  return (
    <div className="">
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={() => setIsOpen(false)}
        style={customStyles}
        contentLabel="Example Modal"
        onAfterClose={() => {
          setErrMsg("");
          setIsUpdating(false);
        }}
      >
        {errMsg ? (
          <>
            <h1 className="text-primary font-semibold text-lg">{errMsg}</h1>
            <div className="flex justify-center mt-3">
              <button
                type="button"
                className="bg-primary font-semibold text-white p-1 px-5 rounded-lg w-2/3"
                onClick={() => setIsOpen(false)}
              >
                {t("modal.Accept")}
              </button>
            </div>
          </>
        ) : (
          <div className="text-center modal-container">
            <form
              onSubmit={(e) => {
                e.preventDefault();
                addNewRepo(repoName);
              }}
            >
              <h1 className="text-primary font-bold text-lg">
                {isUpdating
                  ? t("modal.Rename Repository")
                  : t("modal.Add New Repository")}
              </h1>
              <input
                placeholder={t("modal.Repository Name")}
                className="border-2 border-primary rounded-lg outline-none p-1 pl-2 w-full my-2"
                onChange={(e) => setRepoName(e.target.value)}
              />
              <p className="text-red-500 mb-2">{validationError}</p>
              <div className="flex justify-center">
                <button
                  type="submit"
                  className={`${
                    isUpdating ? "bg-secondary" : "bg-primary"
                  } font-semibold text-white p-1 px-5 rounded-lg w-2/3`}
                >
                  {isUpdating ? t("modal.Update") : t("modal.Save")}
                </button>
              </div>
            </form>
          </div>
        )}
      </Modal>
    </div>
  );
};

export default AddNewRepolegacy;
