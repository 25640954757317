import React, { useEffect, useState, useContext } from 'react';
import dayjs from 'dayjs';
import CommonModule from './CommonComponentlegacy';
import useAxios from '../../hooks/useAxios';
import { resolve } from '../../api/resolve';
import AuthContext from '../../context/AuthContext';
import { ssoSetingTableColumns } from '../../constants/constants';

const formattedColumns = ssoSetingTableColumns.filter(
  (obj) => obj.Header !== 'Company'
);
const SSOSettinglegacy = () => {
  const api = useAxios();
  const { user } = useContext(AuthContext);
  const [ssoSetting, setSSOSetting] = useState([]);
  const [nextPage, setIsNextPage] = useState(null);
  const [prevPage, setIsPrevPage] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [errMsg, setErrMsg] = useState('');
  const [pageLoading, setPageLoading] = useState(true);
  const [sortingOrder, setSortingOrder] = useState('desc');
  const [sortColumn, setSortColumn] = useState('created_at');

  const getSSOSetting = async () => {
    const res = await resolve(
      api
        .get(`/user/sso/detail/?ordering=${sortColumn}&page=${currentPage}`)
        .then((res) => res.data)
    );
    console.log('res', res.data.data);
    if (res.data) {
      // const { results, next, previous } = res.data.data;
      const results = res.data['data'];
      const next = 0;
      const previous = 0;
      const filterData = [];
      if (results.length > 0) {
        results.forEach((result) => {
          const ssObj = {
            entityId: result.entityId,
            singleSignOnService: result.singleSignOnService,
            singleLogoutService: result.singleLogoutService,
            sso_cretificate: result.sso_cretificate,
            id: result.id,
          };
          filterData.push(ssObj);
        });
      } else {
        setErrMsg('No seting added yet');
      }
      setSSOSetting([...filterData]);
      setIsNextPage(next);
      setIsPrevPage(previous);
    }
    setPageLoading(false);
  };

  const searchUser = async (searchText, companyID) => {
    getSSOSetting();
  };
  useEffect(() => {
    getSSOSetting();
  }, [currentPage, sortColumn]);
  return (
    <CommonModule
      heading='SSO Setting'
      inputPlaceholder='Enter user name here'
      addBtnText='Add SSO Setting'
      errMsg={errMsg}
      moduleData={ssoSetting}
      moduleTableColumns={
        user.user_type === 'super_admin' ? ssoSetingTableColumns : formattedColumns
      }
      currentPage={currentPage}
      setCurrentPage={setCurrentPage}
      isCompany={true}
      nextPage={nextPage}
      prevPage={prevPage}
      getSearchData={searchUser}
      activeLinkTitle='SSOSettingFormEdit'
      pageLoading={pageLoading}
      sortingOrder={sortingOrder}
      setSortingOrder={setSortingOrder}
      setSortColumn={setSortColumn}
      getModuleData={getSSOSetting}
    />
  );
};

export default SSOSettinglegacy;
