import React, { useContext } from "react";
import Modal from "react-modal";
import dayjs from "dayjs";
import "./modal.css";
import CloseIcon from "../../assets/icons/Close.svg";
import ContractsListTable from "../notifications/ContractsListTable";
import { notificationListColumns } from "../../constants/constants";
import { useTranslation } from "react-i18next";

const customStyles = {
  content: {
    position: "absolute",
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    transform: "translate(-50%, -50%)",
    width: "600px",
    maxHeight: "500px",
    borderRadius: "11px",
    padding: "30px !important",
    backgroundColor: "white",
  },
  overlay: {
    backgroundColor: "rgba(64, 8, 53, 0.63)",
  },
};

Modal.setAppElement("#root");
const NotificationModal = ({
  modalIsOpen,
  setIsOpen,
  notifications,
  selectedNotification,
}) => {
  const { t } = useTranslation();
  return (
    <div className="">
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={() => setIsOpen(false)}
        style={customStyles}
        contentLabel="Example Modal"
        className="p-4 "
      >
        <div className="important-padding">
          {selectedNotification.length > 0 && (
            <h1 className="text-primary font-bold text-base text-center">
              {/* {selectedNotification[0].type !== 'notify_indexing_done'
              ? `Following Contracts are getting expired on ${dayjs(
                  selectedNotification[0].renewal_date
                ).format('MMMM D, YYYY')}`
              : 'Following Contracts are ready to view'} */}
              {selectedNotification[0].type === "notify_indexing_done"
                ? t("modal.Following Contracts are ready to view")
                : selectedNotification[0].type === "notify_renewal_date_absent"
                ? t("modal.Following contract/s does not have renewal date set")
                : `${t(
                    "modal.Following Contracts are getting expired on"
                  )} ${dayjs(selectedNotification[0].renewal_date).format(
                    "MMMM D, YYYY"
                  )}`}
            </h1>
          )}
          <ContractsListTable
            contractsData={selectedNotification}
            contractTableColumns={notificationListColumns}
          />
        </div>
      </Modal>
    </div>
  );
};

export default NotificationModal;
