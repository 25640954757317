import React, { useState } from 'react';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';

const CutomPhoneInput = ({ value, onChange }) => {
  return (
    <div>
      <PhoneInput
        country={'in'}
        value={value}
        onChange={(e) => onChange(e)}
        placeholder='Phone Number'
        enableAreaCodes
        enableSearch
        containerStyle={{
          border: '1px solid #400835',
          borderRadius: 10,
          paddingLeft: 5,
        }}
        inputStyle={{ border: 'none' }}
        buttonStyle={{ border: 'none', background: 'transparent' }}
      />
    </div>
  );
};

export default CutomPhoneInput;
