import React, { useEffect, useState, useContext } from "react";
import dayjs from "dayjs";
import AppContext from "../../context/AppContext";
import AuthContext from "../../context/AuthContext";
import { resolve } from "../../api/resolve";
import useAxios from "../../hooks/useAxios";
import Search from "../../assets/icons/Search.svg";
import RepositoryIcon from "../../assets/icons/Repository.svg";
import icbaselinefolder from "../../assets/icons/icbaselinefolder-1.svg";
import foldericonopened from "../../assets/icons/folderIconopened.svg";
import parentfolder from "../../assets/icons/parentfolder.svg";
import fileIcon from "../../assets/icons/fileIcon.svg";
import folderIcon from "../../assets/icons/folderIcon.svg";
import plusIcon from "../../assets/icons/plusIcon.svg";
import moveFolder from "../../assets/icons/movefolder.svg";
import shareIcon from "../../assets/icons/share.svg";
import EyeOnIconOrange from "../../assets/icons/eye_on_orange.png";
import DownloadIcon from "../../assets/icons/Download_orange.svg";
import LoadingScreen from "../loader/LoadingScreen";
import AddNewRepo from "../modallegacy/AddNewRepolegacy";
import Moverepolegacy from "../modallegacy/Moverepolegacy";
import ShareRepoLegacy from "../modallegacy/ShareRepoLegacy";
import Group from "../../assets/icons/group.png";
import Pencil from "../../assets/icons/pencil.png";
import DeleteModal from "../modallegacy/DeleteModallegacy";
import { useTranslation } from "react-i18next";
import ReactTooltip from "react-tooltip";
import DocumentPreviewModal from "../modals/DocumentPreviewModal";
import DeleteIcon from "../../assets/icons/Delete.svg";
import { toast } from "react-toastify";

const RepoItem = ({
  repo,
  setRepoName,
  setContractsHeading,
  setActiveLink,
  setRepoID,
  repoID,
  isDeleteModalOpen,
  setIsDeleteModalOpen,
  setIsModalOpen,
  setIsUpdating,
  struct,
  onChildValue,
  onChilddocValue,
  setfirstlevelparent,
  firstlevelparent,
  findstructurepath,
  setallparenttext,
  setisInsideSharedRepo,
}) => {
  const api = useAxios();
  const { t } = useTranslation();
  const [isHovered, setIsHovered] = useState(false);
  const [PageLoading, setPageLoading] = useState(false);
  const { user } = useContext(AuthContext);
  const [reposinParentPanel, setreposinParentPanel] = useState([]);
  const [reposinParent, setreposinParent] = useState([]);

  const [setErrMsg, setsetErrMsg] = useState([]);
  const [repocontract, setrepocontract] = useState([]);
  const [selectedContract, setselectedContract] = useState([]);
  const [SelectedContract, setSelectedContract] = useState([]);
  const [showDrawer, setShowDrawer] = useState(false);
  const [clickagain, setclickagain] = useState(false);
  const changeclickstate = async () => {
    if (clickagain == true) {
      setclickagain(false);
    }
  };
  const getRepoDocs = async (repoID) => {
    const passval = { repo_id: repoID };
    const res = await resolve(
      api
        .get(`legacy_contract/get_contract_repos_share/${repoID}`)
        .then((res) => res.data)
    );
    if (res.data) {
      const results = res.data;
      const filterData = [];
      if (results.length > 0) {
        // setContractCount(count);
        results.forEach((result) => {
          if (user.user_type === "user" && result.uploaded_by === user.user_id)
            setShowDrawer(true);
          const contractObj = {
            name: result.name || result.doc_name,
            created_at: dayjs(result.created_at).format("DD MMMM YYYY"),
            tags: result.tags,
            type: result.type,
            updated_at: dayjs(result.updated_at).format("D MMMM YYYY"),
            renewal_date: result.renewal_date
              ? dayjs(result.renewal_date).format("DD MMMM YYYY")
              : t("adminDashboard.Not Added"),
            company_id: result.company_id,
            id: result.id,
            converted_file_url: result.converted_file_url,
            file_url: result.file_url,
            doc_name: result.doc_name,
            reminder_days: result.remainder_days,
            reminder_date: result.remainder_date,
            uploaded_by: result.uploaded_by,
          };
          filterData.push(contractObj);
        });
      } else {
        // setErrMsg(t("adminDashboard.No contracts added yet"));
      }
      setrepocontract([]);
      setrepocontract([...filterData]);
      setSelectedContract({
        ...selectedContract,
        file_url: filterData.length > 0 && filterData[0].file_url,
        doc_name: filterData.length > 0 && filterData[0].doc_name,
        converted_file_url:
          filterData.length > 0 && filterData[0].converted_file_url,
        id: filterData.length > 0 && filterData[0].id,
        contract_clause: "",
      });
      onChilddocValue([...filterData]);
    }
    // setPageLoading(false);
  };
  const getparentReposPanel = async (repoID) => {
    const passval = { repo_id: repoID };
    const res = await resolve(
      api
        .post(`legacy_contract/getRepoparent/`, passval)
        .then((res) => res.data)
    );
    if (res.data) {
      const filterData = [];
      if (res.data && res.data.length > 0) {
        res.data.forEach((result) => {
          const contractObj = {
            repo_name: result.repo_name,
            id: result.id,
          };
          filterData.push(contractObj);
        });
      }
      setreposinParentPanel([]);
      setreposinParent([]);

      setreposinParentPanel([...filterData]);
      onChildValue([]);
      onChildValue([...filterData]);
    }
    // setPageLoading(false);
  };

  const repoaction = async (e) => {
    if (repo.repo_name == "Shared Repo") {
      setContractsHeading("Contracts");
      setRepoName(
        repo.repo_name.charAt(0).toUpperCase() + repo.repo_name.slice(1)
      );
      setRepoID(repo.id);
      setallparenttext([]);
      findstructurepath(repo.id);
      setisInsideSharedRepo(true);
      getsharedRepos();
      // getRepoDocs(repo.id);
      repoID = repo.id;
      setrepocontract([]);
      onChilddocValue([]);
      changeclickstate();
    } else {
      setContractsHeading("Contracts");
      setRepoName(
        repo.repo_name.charAt(0).toUpperCase() + repo.repo_name.slice(1)
      );
      setRepoID(repo.id);
      setallparenttext([]);
      setisInsideSharedRepo(false);
      findstructurepath(repo.id);
      getparentReposPanel(repo.id);
      getRepoDocs(repo.id);
      repoID = repo.id;
      changeclickstate();
    }
  };

  const getsharedRepos = async () => {
    const passval = { repo_id: "" };
    const res = await resolve(
      api
        .post(`legacy_contract/ShareRepoReceived/`, passval)
        .then((res) => res.data)
    );
    if (res.data) {
      const filterData = [];
      if (res.data && res.data.length > 0) {
        res.data.forEach((result) => {
          const contractObj = {
            repo_name: result.repo_name,
            id: result.id,
          };
          filterData.push(contractObj);
        });
      }
      setreposinParent([]);
      setreposinParent([...filterData]);
      onChildValue([]);
      onChildValue([...filterData]);
    }
    // setPageLoading(false);
  };

  return (
    <div
      className="w-fit"
      onClick={() => {
        setfirstlevelparent(repo.repo_name);
        repoaction();
      }}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <div className="parentfolder flex">
        {firstlevelparent == repo.repo_name && (
          <img
            src={foldericonopened}
            className="cursor-pointer mr-1 shrink-0 min-h-[24px]"
          />
        )}
        {firstlevelparent != repo.repo_name && (
          <img
            src={icbaselinefolder}
            className="cursor-pointer mr-1 shrink-0 min-h-[24px]"
          />
        )}

        <b className="font-bold cursor-pointer">
          {`${repo.repo_name[0].toUpperCase()}${repo.repo_name.slice(1)} `}
        </b>
      </div>

      {/* {(firstlevelparent==repo.repo_name)&&reposinParentPanel.map((repo) => (
        <div
          className="self-stretch rounded-8xs bg-snow-100 flex flex-row items-start justify-start pt-[11px] px-[13px] pb-[7px] box-border gap-[5px] max-w-full z-[2]"
          onClick={(e) => {
            e.stopPropagation();
            
          }}
        >
          <div className="h-[42px] w-[637px] relative rounded-8xs bg-snow-100 hidden max-w-full" />
          <img
            className="h-6 w-6 relative overflow-hidden shrink-0 min-h-[24px] z-[1]"
            alt=""
            src={icbaselinefolder}
          />
          <b className="font-bold cursor-pointer">
            {`${repo.repo_name[0].toUpperCase()}${repo.repo_name.slice(1)} `}
          </b>
        </div>
      ))} */}
    </div>
  );
};

const BrowseDocuments = () => {
  const api = useAxios();
  const { t } = useTranslation();
  const {
    repoName,
    setRepoName,
    setContractsHeading,
    setIsRepoList,
    setActiveLink,
    setRepoID,
    repoID,
  } = useContext(AppContext);
  const { user } = useContext(AuthContext);
  const [searchText, setSearchText] = useState("");
  const [errMsg, setErrMsg] = useState("");
  const [struct, setstruct] = useState();
  const [repos, setRepos] = useState([]);
  const [unassignedDocSearchText, setUnassignedDocSearchText] = useState("");
  const [reposinParent, setreposinParent] = useState([]);
  const [reposinParentPanel, setreposinParentPanel] = useState([]);
  const [docsinParent, setdocsinParent] = useState([]);
  const [pageLoading, setPageLoading] = useState(true);
  const [selectedfolders, setselectedfolders] = useState([]);
  const [selectedfiles, setselectedfiles] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalmoveIsOpen, setmodalmoveIsOpen] = useState(false);
  const [modalmultimoveIsOpen, setmodalmultimoveIsOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isDeletemultiModalOpen, setisDeletemultiModalOpen] = useState(false);
  const [originalRepos, setOriginalRepos] = useState([]);
  const [originalDocs, setOriginalDocs] = useState([]);
  // const [setRepoID, setIsDeleteModalOpen] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const [ischeckbox, setischeckbox] = useState(false);
  const [tocheckrepo, settocheckrepo] = useState([]);
  const [allparenttext, setallparenttext] = useState([]);
  const [tocheckfiles, settocheckfiles] = useState([]);
  const [previewDocument, setPreviewDocument] = useState(false);
  const [previewDocumentData, setPreviewDocumentData] = useState({});
  const [firstlevelparent, setfirstlevelparent] = useState("Home");
  const [modalshareIsOpen, setmodalshareIsOpen] = useState(false);
  const [modalmultishareIsOpen, setmodalmultishareIsOpen] = useState(false);
  const [repoidret, setrepoidret] = useState([]);
  const [parentrepoidret, setparentrepoidret] = useState([]);
  const { getNotificationsCountlegacy } = useContext(AppContext);
  const [maxWidth, setMaxWidth] = useState("40ch");
  const [isInsideSharedRepo, setisInsideSharedRepo] = useState(false);
  const [allSharedRepo, setallSharedRepo] = useState([]);

  const handleRepoSelection = (repo) => {
    const repoId = repo.id;

    if (selectedfolders.includes(repoId)) {
      setselectedfolders(selectedfolders.filter((id) => id !== repoId));
      settocheckrepo(selectedfolders.filter((id) => id !== repoId));
    } else {
      setselectedfolders([...selectedfolders, repoId]);
      settocheckrepo([...selectedfolders, repoId]);
    }
  };

  // Function to handle document selection
  const handleDocSelection = (doc) => {
    const docID = doc.id;

    setselectedfiles((prevSelectedFiles) => {
      if (prevSelectedFiles.includes(docID)) {
        settocheckfiles(prevSelectedFiles.filter((id) => id !== docID));
        return prevSelectedFiles.filter((id) => id !== docID);
      } else {
        settocheckfiles([...prevSelectedFiles, docID]);
        return [...prevSelectedFiles, docID];
      }
    });
  };

  const handleChildValue = (value) => {
    setreposinParentPanel([]);
    setreposinParent(value);
    setOriginalRepos(value);
    setselectedfiles([]);
    setselectedfolders([]);
  };
  const handleChilddocValue = (value) => {
    setdocsinParent([]);
    setOriginalDocs([]);
    setOriginalDocs(value);
    setdocsinParent(value);
    // setselectedfiles([]);
    // setselectedfolders([]);
  };
  const getRepos = async () => {
    const passval = { repo_id: "" };
    setselectedfiles([]);
    setselectedfolders([]);
    const res = await resolve(
      api.get(`legacy_contract/get_all_repos/`, passval).then((res) => res.data)
    );
    if (res.data) {
      const filterData = [];
      if (res.data && res.data.length > 0) {
        res.data.forEach((result) => {
          const contractObj = {
            repo_name: result.repo_name,
            id: result.id,
          };
          filterData.push(contractObj);
        });
      } else {
        setErrMsg(t("fileBrowser.No repositories added."));
      }
      setRepos([...filterData]);
    }
    setPageLoading(false);
  };

  const getparentidbackward = async () => {
    if (repoID) {
      if (firstlevelparent.toLowerCase() == "shared repo".toLowerCase()) {
        const passval = { repo_id: "" };
        const res2 = await resolve(
          api
            .post(`legacy_contract/ShareRepoReceived/`, passval)
            .then((res2) => res2.data)
        );
        const filterData = [];
        const repoiddata = [];
        if (res2.data && res2.data.length > 0) {
          res2.data.forEach((result) => {
            const contractObj = {
              repo_name: result.repo_name,
              id: result.id,
            };
            filterData.push(contractObj);
            repoiddata.push(result.id);
          });
          setRepoName("Shared Repo");
        }
        setOriginalDocs([]);
        setdocsinParent([]);
        setreposinParent([...filterData]);
        setOriginalRepos([...filterData]);
        setallSharedRepo([]);
        setallSharedRepo([...repoiddata]);
      }
    }
  };

  const handleSubFolderClick = async (repo) => {
    settocheckrepo([]);
    settocheckfiles([]);
    if (repo) {
      setRepoName(
        repo.repo_name.charAt(0).toUpperCase() + repo.repo_name.slice(1)
      );
      const passval = { repo_id: repo.id };

      setRepoID(repo.id);
      findstructurepath(repo.id);
      const res = await resolve(
        api
          .post(`legacy_contract/getRepoparent/`, passval)
          .then((res) => res.data)
      );
      getSubFolderDoc(repo.id);
      if (res.data) {
        const filterData = [];
        if (res.data && res.data.length > 0) {
          res.data.forEach((result) => {
            const contractObj = {
              repo_name: result.repo_name,
              id: result.id,
            };
            filterData.push(contractObj);
          });
        }
        setselectedfiles([]);
        setselectedfolders([]);
        setreposinParent([]);
        setOriginalRepos([]);

        setreposinParent([...filterData]);
        setOriginalRepos([...filterData]);
      }
    }
  };
  const getSubFolderDoc = async (repoID) => {
    const passval = { repo_id: repoID };
    const res = await resolve(
      api
        .get(`legacy_contract/get_contract_repos_share/${repoID}`)
        .then((res) => res.data)
    );
    if (res.data) {
      const results = res.data;
      const filterData = [];
      if (results.length > 0) {
        // setContractCount(count);
        results.forEach((result) => {
          const contractObj = {
            name: result.name || result.doc_name,
            created_at: dayjs(result.created_at).format("DD MMMM YYYY"),
            tags: result.tags,
            type: result.type,
            updated_at: dayjs(result.updated_at).format("D MMMM YYYY"),
            renewal_date: result.renewal_date
              ? dayjs(result.renewal_date).format("DD MMMM YYYY")
              : t("adminDashboard.Not Added"),
            company_id: result.company_id,
            id: result.id,
            converted_file_url: result.converted_file_url,
            file_url: result.file_url,
            doc_name: result.doc_name,
            reminder_days: result.remainder_days,
            reminder_date: result.remainder_date,
            uploaded_by: result.uploaded_by,
          };
          filterData.push(contractObj);
        });
      } else {
        setdocsinParent([]);
        // setErrMsg(t("adminDashboard.No contracts added yet"));
      }
      setdocsinParent([]);
      setOriginalDocs([]);
      setselectedfiles([]);
      setselectedfolders([]);
      setOriginalDocs([...filterData]);
      setdocsinParent([...filterData]);
    } else {
      setOriginalDocs([]);
      setdocsinParent([]);
    }
    // setPageLoading(false);
  };

  const searchRepoByName = async (searchText) => {
    const filterRepo = [];
    const filterDoc = [];
    originalRepos.forEach((repo) => {
      if (repo.repo_name.toLowerCase().includes(searchText.toLowerCase())) {
        filterRepo.push(repo);
      }
    });
    originalDocs.forEach((repo) => {
      if (repo.doc_name.toLowerCase().includes(searchText.toLowerCase())) {
        filterDoc.push(repo);
      }
    });
    setreposinParent([]);
    setselectedfiles([]);
    setselectedfolders([]);
    setreposinParent([...filterRepo]);
    setdocsinParent([]);
    setdocsinParent([...filterDoc]);
  };
  const homepage = (e) => {
    setRepoID(null);
    setRepoName(null);
    setRepos([]);
    handleChildValue([]);
    handleChilddocValue([]);
    getRepos();
    setisInsideSharedRepo(false);
    setfirstlevelparent("Home");
  };

  const findstructurepath = async (repoID) => {
    const passval = { repo_id: repoID };
    const res = await resolve(
      api
        .post(`legacy_contract/RepoAllParentLevel/`, passval)
        .then((res) => res.data)
    );
    if (res.data) {
      const results = res.data;
      const filterData = [];
      if (results.length > 0) {
        // setContractCount(count);
        results.forEach((result) => {
          filterData.push(result);
        });
        setallparenttext([]);
        setallparenttext([...filterData]);
      } else {
        // setErrMsg(t("adminDashboard.No contracts added yet"));
      }
    }
  };
  const handleDocDownload = async (doc) => {
    const url = `${process.env.REACT_APP_BACKEND_BASE_URL}/legacy_contract/download_contract/?file_url=${doc.file_url}&id=${doc.id}`;
    const link = document.createElement("a");
    link.href = url;
    link.download = doc.doc_name;
    link.target = "_blank";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  const deleteRepository = async () => {
    setPageLoading(true);
    const formData = new FormData();
    formData.append("repo_ids", selectedfolders);
    formData.append("file_ids", selectedfiles);
    const res = await resolve(
      api.post(`legacy_contract/delete_repo/`, formData).then((res) => res.data)
    );

    if (res.data) {
      if (res.data.success) {
        const repo = {
          id: repoID,
          repo_name: repoName,
        };
        if (selectedfolders[0] != repoID) {
          handleSubFolderClick(repo);
          getSubFolderDoc(repoID);
        } else {
          homepage();
        }
        toast.success(t("fileBrowser.Repository deleted successfully"));
      } else if (res.data.code === "notAuthorized") {
        let msg =
          t("fileBrowser.You are not authorized to delete") +
          " " +
          res.data.repo_name;
        toast.error(msg);
      } else {
        toast.error(
          t("fileBrowser.Failed to delete repository. Please try again")
        );
      }
    }
    if (res.error) {
      if (res.error.response.data) {
        toast.error(res.error.response.data.msg);
      } else {
        toast.error(t("fileBrowser.Failed to delete repository. Please try again"));
      }
    }
    setTimeout(() => {
      getNotificationsCountlegacy();
    }, 2000);

    getRepos();
    settocheckrepo([]);
    settocheckfiles([]);
    setIsDeleteModalOpen(false);
    setPageLoading(false);
  };
  const updateaftershare = async () => {
    settocheckfiles([]);
    settocheckrepo([]);
  };

  useEffect(() => {
    setRepoID(null);
    getRepos();
    setselectedfiles([]);
    setselectedfolders([]);
  }, []);

  useEffect(() => {
    const newSelectedFilesfolder = [];
    newSelectedFilesfolder.push(...selectedfiles, ...selectedfolders);
  }, [selectedfolders, selectedfiles]);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 1000) {
        setMaxWidth("20ch");
      } else {
        setMaxWidth("40ch");
      }
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  if (pageLoading) {
    return <LoadingScreen />;
  }

  const startIndex = allparenttext.findIndex((item) =>
    allSharedRepo.includes(item.id)
  );

  // Slice the array to start from the found index, or use the full array if not found
  const itemsToRender =
    startIndex !== -1 ? allparenttext.slice(startIndex) : [];

  return (
    <>
      <div className="h-full flex flex-col">
        <div className="w-full pt-1">
          <h1 className="text-4xl font-bold">
            {t("fileBrowser.Browse Documents")}
          </h1>
        </div>
        <div className="flex flex-grow pt-8" style={{ height: "90%" }}>
          <nav className="parentfolderpanel p-4">
            <h1
              className="text-3xl font-bold cursor-pointer"
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                homepage();
                setallparenttext([]);
              }}
            >
              {t("fileBrowser.Home")}
            </h1>
            <ul className="flex flex-col space-y-4 parentFolderView">
              {repos.map((repo) => (
                <RepoItem
                  repo={repo}
                  key={repo.repo_name}
                  setActiveLink={setActiveLink}
                  setRepoID={setRepoID}
                  setContractsHeading={setContractsHeading}
                  setRepoName={setRepoName}
                  setIsDeleteModalOpen={setIsDeleteModalOpen}
                  setIsModalOpen={setIsModalOpen}
                  setIsUpdating={setIsUpdating}
                  struct={struct}
                  onChildValue={handleChildValue}
                  onChilddocValue={handleChilddocValue}
                  setfirstlevelparent={setfirstlevelparent}
                  firstlevelparent={firstlevelparent}
                  findstructurepath={findstructurepath}
                  setallparenttext={setallparenttext}
                  setisInsideSharedRepo={setisInsideSharedRepo}
                />
              ))}
            </ul>
          </nav>
          <div className="w-full text-primary pl-4 " style={{ width: "70%" }}>
            <div className="rounded-lg p-0 subfolderpanel relative">
              <div className=" grid grid-cols-12 gap-4 w-full justify-between">
                {/* Left-aligned div */}
                <div className="col-span-6 rounded-8xs bg-snow-100 flex items-start justify-start pt-2 px-3 pb-1 space-x-2">
                  <h1 className="text-3xl font-bold p-2">
                    {repoName
                      ? repoName
                      : t("fileBrowser.Please select folder")}
                  </h1>
                </div>

                {/* Right-aligned div */}
                {firstlevelparent.toLowerCase() !==
                  "shared repo".toLowerCase() &&
                  tocheckrepo.length === 0 &&
                  tocheckfiles.length === 0 &&
                  firstlevelparent.toLowerCase() !== "default".toLowerCase() &&
                  (firstlevelparent.toLowerCase() !== "home".toLowerCase() ||
                    user.user_type === "admin" ||
                    user.user_type === "superadmin") && (
                    <div className="col-span-6 flex items-center justify-end pr-4 space-x-2 p-1">
                      <button
                        className="flex btn btn_theme btn-sm"
                        data-tip={t("fileBrowser.Add Repository")}
                        data-for="addrepoTooltip"
                        onClick={() => {
                          setIsModalOpen(true);
                        }}
                      >
                        <img className="h-4 w-4 " alt="" src={folderIcon} />
                      </button>
                      <ReactTooltip
                        id="addrepoTooltip"
                        backgroundColor="#400835"
                        textColor="#ffffff"
                      />
                      {repoID && (
                        <>
                          <button
                            className="flex btn btn_theme btn-sm"
                            data-tip={t("fileBrowser.Add Contract")}
                            data-for="addContractTooltip"
                            onClick={() => {
                              setallparenttext([]);
                              setContractsHeading("Contracts");
                              setRepoName(
                                repoName.charAt(0).toUpperCase() +
                                  repoName.slice(1)
                              );
                              setRepoID(repoID);
                              setActiveLink(
                                repoName.toLowerCase() !== "default"
                                  ? "ContractFormlegacy"
                                  : ""
                              );
                            }}
                          >
                            <img className="h-4 w-4 " alt="" src={plusIcon} />
                          </button>

                          <button
                            className="flex btn btn_theme btn-sm mt-1 mb-1"
                            data-tip={t("fileBrowser.Move")}
                            data-for="moveTooltip"
                            onClick={() => {
                              setallparenttext([]);
                              setselectedfolders([repoID]);
                              setmodalmoveIsOpen(true);
                            }}
                          >
                            <img
                              className="h-4 w-4 relative"
                              alt=""
                              src={moveFolder}
                            />
                          </button>

                          <button
                            className="flex btn btn_theme btn-sm mt-1 mb-1"
                            data-tip={t("fileBrowser.Delete")}
                            data-for="deleteTooltip"
                            onClick={() => {
                              setallparenttext([]);
                              setselectedfolders([repoID]);
                              setIsDeleteModalOpen(true);
                            }}
                          >
                            <img
                              className="h-4 w-4 relative"
                              alt=""
                              src={DeleteIcon}
                            />
                          </button>

                          <button
                            className="flex btn btn_theme btn-sm"
                            data-tip={t("fileBrowser.Share")}
                            data-for="shareTooltip"
                            onClick={() => {
                              setselectedfolders([repoID]);
                              setmodalshareIsOpen(true);
                            }}
                          >
                            <img
                              className="h-4 w-4 relative"
                              alt=""
                              src={shareIcon}
                            />
                          </button>

                          <ReactTooltip
                            id="addContractTooltip"
                            backgroundColor="#400835"
                            textColor="#ffffff"
                          />
                          <ReactTooltip
                            id="moveTooltip"
                            backgroundColor="#400835"
                            textColor="#ffffff"
                          />
                          <ReactTooltip
                            id="deleteTooltip"
                            backgroundColor="#400835"
                            textColor="#ffffff"
                          />
                          <ReactTooltip
                            id="shareTooltip"
                            backgroundColor="#400835"
                            textColor="#ffffff"
                          />
                        </>
                      )}
                    </div>
                  )}
              </div>

              <div style={{ borderTop: "2px solid #310128" }}></div>
              <div className="pl-4 cursor-pointer">
                <span
                  onClick={() => {
                    homepage();
                    setallparenttext([]);
                  }}
                  onMouseEnter={(e) => {
                    e.target.style.color = "red";
                  }}
                  onMouseLeave={(e) => {
                    e.target.style.color = "black";
                  }}
                >
                  Home
                </span>
                {isInsideSharedRepo && (
                  <span
                    onClick={() => {
                      setfirstlevelparent("shared repo");
                      getparentidbackward();
                      setallparenttext([]);
                    }}
                    onMouseEnter={(e) => {
                      e.target.style.color = "red";
                    }}
                    onMouseLeave={(e) => {
                      e.target.style.color = "black";
                    }}
                  >
                    {" "}
                    {" > "}Shared Repo
                  </span>
                )}
                {isInsideSharedRepo &&
                  itemsToRender.map((Parentrepoval) => (
                    <>
                      {" > "}
                      <span
                        onClick={() => {
                          handleSubFolderClick(Parentrepoval);
                          setallparenttext([]);
                        }}
                        onMouseEnter={(e) => {
                          e.target.style.color = "red";
                        }}
                        onMouseLeave={(e) => {
                          e.target.style.color = "black";
                        }}
                      >
                        {Parentrepoval.repo_name}
                      </span>
                    </>
                  ))}
                {!isInsideSharedRepo &&
                  allparenttext.map((Parentrepoval) => (
                    <>
                      {" > "}
                      <span
                        onClick={() => {
                          handleSubFolderClick(Parentrepoval);
                          setallparenttext([]);
                        }}
                        onMouseEnter={(e) => {
                          e.target.style.color = "red";
                        }}
                        onMouseLeave={(e) => {
                          e.target.style.color = "black";
                        }}
                      >
                        {Parentrepoval.repo_name}
                      </span>
                    </>
                  ))}
                {repoName &&
                  repoName.toLowerCase() !== "shared repo".toLowerCase() &&
                  " >"}{" "}
                {repoName &&
                  repoName.toLowerCase() !== "shared repo".toLowerCase() &&
                  repoName}
              </div>
              <div
                className="flex w-full"
                style={{ width: "100%", height: "calc(100% - 4rem)" }}
              >
                {/* {(reposinParent.length > 0 || docsinParent.length > 0) && (
                  <p className="text-red-500 text-sm font-medium">{`${errMsg} ${
                    user.user_type === "user"
                      ? t("fileBrowser.Please contact admin for the access.")
                      : ""
                  }`}</p>
                )} */}

                {
                  <div
                    className="flex flex-col w-100"
                    style={{ width: "100%" }}
                  >
                    <div className="mt-5 mb-5 grid grid-cols-12 gap-4 w-full justify-between">
                      {/* Left-aligned div */}

                      <div className="col-span-6 rounded-8xs bg-snow-100 flex items-start justify-start pt-2 px-3 pb-1 space-x-2">
                        {/* <img
                          data-tip={t("fileBrowser.Back")}
                          className="h-6 w-6 relative cursor-pointer overflow-hidden shrink-0 min-h-[24px]"
                          onClick={() => {
                            getparentidbackward();
                          }}
                          alt=""
                          src={icbaselinefolder}
                        />
                        <b className="font-bold">....</b> */}
                      </div>

                      {/* Right-aligned div */}
                      <div className="col-span-6 flex items-center justify-end pr-2 space-x-2">
                        <div className="absolute flex justify-end items-center p-2">
                          <div className="px-1  w-100 searchBox heg_in flex items-center border-2 border-primary rounded-md rounded-tl-lg rounded-bl-lg bg-inputBackground m-2">
                            <input
                              placeholder={t("allModule.Search")}
                              className="px-1 py-1 focus:outline-none bg-transparent w-11/12"
                              value={unassignedDocSearchText}
                              onChange={(e) => {
                                setUnassignedDocSearchText(e.target.value);
                                searchRepoByName(e.target.value);
                              }}
                            />
                          </div>
                          <button
                            type="submit"
                            className="flex btn btn_theme2 items-center justify-between"
                            onClick={(e) => {
                              e.preventDefault();
                              searchRepoByName(unassignedDocSearchText);
                            }}
                          >
                            <img
                              src={Search}
                              style={{ width: "1rem" }}
                              alt="Search Icon"
                            />
                            <span className="font-semibold ml-1">
                              {t("allModule.Search")}
                            </span>
                          </button>
                        </div>
                      </div>
                    </div>

                    {(reposinParent.length > 0 || docsinParent.length > 0) && (
                      <>
                        <div className="folderStructure">
                          <div className="grid grid-cols-1 pt-2 pl-2 pr-2 w-full">
                            {reposinParent.map((repo) => (
                              <div className="folderonly">
                                {firstlevelparent.toLowerCase() !==
                                  "shared repo".toLowerCase() && (
                                  <input
                                    type="checkbox"
                                    className="mr-2 mt-1.5"
                                    onChange={() => handleRepoSelection(repo)}
                                  />
                                )}
                                <div
                                  className="flex"
                                  key={repo.repo_name}
                                  onClick={() => {
                                    handleSubFolderClick(repo);
                                    setallparenttext([]);
                                    findstructurepath(repo.id);
                                  }}
                                >
                                  <img
                                    className="h-6 w-6 relative overflow-hidden shrink-0 min-h-[24px]-0 z-[1]"
                                    alt=""
                                    src={icbaselinefolder}
                                  />
                                  <b
                                    data-tip={`${repo.repo_name[0].toUpperCase()}${repo.repo_name.slice(
                                      1
                                    )}`}
                                    className={`font-bold pl-2 cursor-pointer truncate`}
                                    style={{ maxWidth }}
                                  >
                                    {`${repo.repo_name[0].toUpperCase()}${repo.repo_name.slice(
                                      1
                                    )} `}
                                  </b>
                                </div>
                              </div>
                            ))}
                          </div>

                          <div className="grid grid-cols-1 pl-2 pr-2 w-full">
                            {docsinParent.map((doc) => (
                              <div
                                className="flex items-center justify-between folderonly"
                                key={doc.doc_name}
                              >
                                <div className="flex items-center">
                                  {firstlevelparent.toLowerCase() !==
                                    "shared repo".toLowerCase() && (
                                    <input
                                      type="checkbox"
                                      className="mr-2"
                                      onChange={() => handleDocSelection(doc)}
                                    />
                                  )}
                                  <img
                                    className="h-6 w-5 ml-1 relative min-h-[24px] z-[1]"
                                    alt=""
                                    src={fileIcon}
                                  />
                                  <b
                                    data-tip={`${doc.doc_name[0].toUpperCase()}${doc.doc_name.slice(
                                      1
                                    )}`}
                                    className={`font-bold pl-2 cursor-pointer truncate`}
                                    style={{ maxWidth }}
                                  >
                                    {`${doc.doc_name[0].toUpperCase()}${doc.doc_name.slice(
                                      1
                                    )}`}
                                  </b>
                                  <ReactTooltip
                                    backgroundColor="#400835"
                                    textColor="#ffffff"
                                  />
                                </div>
                                <div className="flex items-center ml-auto">
                                  <button
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      setPreviewDocument(true);
                                      setPreviewDocumentData(doc);
                                    }}
                                    className="btn btn-sm wh_spab"
                                    data-tip={t("constant.View")}
                                  >
                                    <img
                                      src={EyeOnIconOrange}
                                      className="ml-0.5 w-5"
                                      width={20}
                                      alt="View Document"
                                    />
                                  </button>
                                  <button
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      handleDocDownload(doc);
                                    }}
                                    className="btn btn-sm wh_spab"
                                    data-tip={t("constant.Download document")}
                                  >
                                    <img
                                      src={DownloadIcon}
                                      className="ml-0.5 w-5"
                                      width={20}
                                      alt="Download Document"
                                    />
                                  </button>
                                </div>
                              </div>
                            ))}
                          </div>

                          {(tocheckrepo.length > 0 ||
                            tocheckfiles.length > 0) &&
                            firstlevelparent.toLowerCase() !==
                              "shared repo".toLowerCase() &&
                            (firstlevelparent.toLowerCase() !==
                              "home".toLowerCase() ||
                              user.user_type === "admin" ||
                              user.user_type === "superadmin") && (
                              <div className="browse-buttons-container">
                                <button
                                  className="flex btn btn_theme btn-sm"
                                  style={{
                                    strokeWidth: "2px", // Adjust stroke width if needed
                                    stroke: "#FFF", // Adjust stroke color if needed
                                  }}
                                  data-tip={t("fileBrowser.Move")}
                                  onClick={() => {
                                    setmodalmoveIsOpen(true);
                                    setmodalmultimoveIsOpen(true);
                                  }}
                                >
                                  <img
                                    className="h-4 w-4 relative"
                                    alt=""
                                    src={moveFolder}
                                  />
                                </button>

                                <button
                                  className="flex btn btn_theme btn-sm"
                                  data-tip={t("fileBrowser.Delete")}
                                  onClick={() => {
                                    setIsDeleteModalOpen(true);
                                    setisDeletemultiModalOpen(true);
                                  }}
                                >
                                  <img
                                    className="h-4 w-4 relative"
                                    alt=""
                                    src={DeleteIcon}
                                  />
                                </button>
                                {tocheckrepo.length > 0 &&
                                  tocheckfiles == 0 && (
                                    <button
                                      className="flex btn btn_theme btn-sm"
                                      data-tip={t("fileBrowser.Share")}
                                      onClick={() => {
                                        setmodalshareIsOpen(true);
                                        setmodalmultishareIsOpen(true);
                                      }}
                                    >
                                      <img
                                        className="h-4 w-4 relative"
                                        alt=""
                                        src={shareIcon}
                                      />
                                    </button>
                                  )}
                                <ReactTooltip
                                  backgroundColor="#400835"
                                  textColor="#ffffff"
                                />
                              </div>
                            )}
                        </div>
                      </>
                    )}

                    {reposinParent.length < 1 && docsinParent.length < 1 && (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          paddingTop: "20%",
                        }}
                      >
                        {firstlevelparent.toLowerCase() !==
                          "default".toLowerCase() &&
                          firstlevelparent.toLowerCase() !=
                            "shared repo".toLowerCase() && (
                            <span className="pl-2 pr-2">
                              {t(
                                "fileBrowser.Choose or create a folder or add a new contract..."
                              )}
                            </span>
                          )}
                      </div>
                    )}
                  </div>
                }
              </div>
            </div>
          </div>
        </div>

        <AddNewRepo
          setIsOpen={setIsModalOpen}
          modalIsOpen={isModalOpen}
          getRepos={getRepos}
          setRepoID={setRepoID}
          isUpdating={isUpdating}
          setIsUpdating={setIsUpdating}
          parentid={repoID}
          parentRepoName={repoName}
          handleSubFolderClick={handleSubFolderClick}
        />

        <DeleteModal
          title={t("fileBrowser.Delete Repository")}
          bodyText={t(
            "common.This action cannot be undone. Are you sure you want to continue?"
          )}
          isDeleteModalOpen={isDeleteModalOpen}
          setIsDeleteModalOpen={setIsDeleteModalOpen}
          deleteRepository={deleteRepository}
          isDeletemultiModalOpen={isDeletemultiModalOpen}
          selectedfolders={selectedfolders}
          selectedfiles={selectedfiles}
        />

        <Moverepolegacy
          setmodalmoveIsOpen={setmodalmoveIsOpen}
          modalmoveIsOpen={modalmoveIsOpen} // Assuming isModalOpen is defined somewhere
          isUpdating={isUpdating}
          setIsUpdating={setIsUpdating}
          parentid={repoID}
          repoName={repoName}
          modalmultimoveIsOpen={modalmultimoveIsOpen}
          selectedfolders={selectedfolders}
          selectedfiles={selectedfiles}
          handleSubFolderClick={handleSubFolderClick}
          getSubFolderDoc={getSubFolderDoc}
        />

        <ShareRepoLegacy
          setmodalshareIsOpen={setmodalshareIsOpen}
          modalshareIsOpen={modalshareIsOpen} // Assuming isModalOpen is defined somewhere
          isUpdating={isUpdating}
          shareNewRepo={true}
          setIsUpdating={setIsUpdating}
          parentid={repoID}
          repoName={repoName}
          modalmultishareIsOpen={modalmultishareIsOpen}
          selectedfolders={selectedfolders}
          selectedfiles={selectedfiles}
          alreadyselecteduser={""}
          updateaftershare={updateaftershare}
        />

        <DocumentPreviewModal
          isOpen={previewDocument}
          setIsOpen={setPreviewDocument}
          previewDocumentData={previewDocumentData}
        />
      </div>
    </>
  );
};

export default BrowseDocuments;
