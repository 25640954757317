import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { toast } from 'react-toastify';
import { resolve } from '../../api/resolve';
import axios from 'axios'
import LoadingScreen from "../loader/LoadingScreen";
import { autoCloseTimer, delay } from '../../constants/constants';
import { useNavigate } from 'react-router-dom';

const VerifyEmail = () => {
    const { token } = useParams();
    const [loading, setLoading] = useState(false);
    const [msg, setMsg] = useState('');
    const [tokenMsg, setTokenMsg] = useState('');
    const baseURL = process.env.REACT_APP_BACKEND_BASE_URL;
    const navigate = useNavigate();

    // calling api to verify email
    useEffect(async () => {

        if (token !== null || token !== undefined) {
            setLoading(true);
            const responceData = await resolve(
                axios.get(`${baseURL}/user/verify_link/?token=${token}`,).then((res) => res.data)
            );
            if (responceData['data']) {
                setLoading(false);
                toast.success('Email verified successfully', {
                    delay,
                    autoClose: autoCloseTimer,
                  });
                setMsg("Email verified successfully");
                navigate('/');
            }

            if (responceData['error']) {
                setLoading(false);
                setMsg("Email verification failed");
                toast.error("Email verification failed",{
                    delay,
                    autoClose: autoCloseTimer,
                });
                setTokenMsg("please try again with valid token");
                navigate('/');
            }
        } else {
            toast.error('Invalid token');
        }

    }, []);
    return (
        <div className="flex justify-center items-center h-screen">
            {loading ? (
                <LoadingScreen />
            ) : (
                <div className="text-center">
                    <h1 className="text-2xl font-bold">{msg}</h1> 
                    <p className="text-lg">{tokenMsg}</p>                   
                </div >
            )}

        </div >
    );
}

export default VerifyEmail;