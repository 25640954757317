import React, { useEffect, useContext, useState } from 'react';
import Switch from 'react-switch';
import Select from 'react-select';
import ReactTooltip from 'react-tooltip';
import Creatable from 'react-select/creatable';
import FormContext from '../../context/FormContext';
import AppContext from '../../context/AppContext';
import AuthContext from '../../context/AuthContext';
import AppModal from '../modallegacy/Modallegacy';
import { resolve } from '../../api/resolve';
import useAxios from '../../hooks/useAxios';
import HelpIcon from '../../assets/icons/Help-sm.svg';
import CustomPhoneInput from './PhoneInputlegacy';
import { useTranslation } from 'react-i18next';
const customStyles = {
  indicatorSeparator: () => false,
  control: (provided, state) => {
    return {
      ...provided,
      outline: 'none',
      boxShadow: 'none',
      border: '1px solid #400835',
      borderRadius: '10px',
      cursor: 'pointer',
      '&:hover': {
        border: '1px solid #400835',
        boxShadow: 'none',
      },
    };
  },
  dropdownIndicator: (provided) => {
    return {
      ...provided,
      color: 'transparent',
      '&:hover': { color: 'transparent' },
    };
  },
  placeholder: (provided) => {
    return {
      ...provided,
      // color: '#400835',
    };
  },
  menuList: (provided) => ({ ...provided, height: 200 }),
  input: (provided) => {
    return {
      ...provided,
      color: '#400835',
    };
  },
  option: (provided, state) => ({
    ...provided,
    color: state.isSelected ? '#fff' : '#400835',
    fontSize: 16,
    fontWeight: '400',
    background: state.isSelected ? '#400835' : undefined,
    cursor: 'pointer',
    '&:hover': {
      background: 'rgba(64, 8, 53, 0.63)',
      color: '#fff',
      fontWeight: 'bold',
    },
  }),
  singleValue: (provided) => {
    return {
      ...provided,
      color: '#400835',
    };
  },
};

const SSOSettingFormlegacy = () => {
  const { t } = useTranslation()
  const {
    setCurrentForm,
    saveOrEditSSOSeting,
    modalData,
    setIsOpen,
    modalIsOpen,
    isEditing,
    editFormData,
    setEditFormData,
    setIsEditing,
    ssoFormErrors,
    setSSOFormErrors,
    shouldRedirect,
  } = useContext(FormContext);
  const api = useAxios();
  const { setActiveLink, companiesList } = useContext(AppContext);
  const { user } = useContext(AuthContext);
  const [companyID, setCompanyID] = useState(null);
  const [repos, setRepos] = useState([]);
  const [userRepos, setUserRepos] = useState([]);
  const [loading, setLoading] = useState(false);
  const [ssoObj, setssoObj] = useState({
    entityId: '',
    singleSignOnService: '',
    singleLogoutService: '',
    sso_cretificate: '',
  });
  const [isActiveUser, setIsActiveUser] = useState(
    editFormData?.is_active_user
  );

  const getRepos = async () => {
    const res = await resolve(
      api.get(`contract/get_all_repos/`).then((res) => res.data)
    );
    if (res.data) {
      const filterData = [];
      if (res.data && res.data.length > 0) {
        res.data.forEach((result) => {
          if (result.repo_name.toLowerCase() !== 'default') {
            const contractObj = {
              value: result.repo_name,
              label: result.repo_name,
              id: result.id,
            };
            filterData.push(contractObj);
          }
        });
      }
      setRepos([...repos, ...filterData]);
    }
  };
  const getUserRepos = async (id) => {
    setLoading(true);
    const res = await resolve(
      api.get(`/contract/get_user_repos/${id}`).then((res) => res.data)
    );
    if (res.data) {
      const filterData = [];
      if (res.data && res.data.length > 0) {
        res.data.forEach((result) => {
          if (!result.is_public) {
            const contractObj = {
              value: result.repo_name,
              label: result.repo_name,
              id: result.id,
            };
            filterData.push(contractObj);
          }
        });
      }
      setUserRepos([...userRepos, ...filterData]);
    }
    setLoading(false);
  };
  useEffect(() => {
    getRepos();
    if (isEditing) {
      getUserRepos(editFormData.id);
    }
    setSSOFormErrors({
      ...ssoFormErrors,
      name: '',
      company_id: '',
      email: '',
      confirm_email: '',
      phone: '',
      position: '',
    });
    setCurrentForm('SSOSettingForm');
    return () => setCurrentForm('');
  }, []);
  useEffect(() => {
    return () => {
      if (isEditing) {
        setEditFormData('');
        setIsEditing(false);
      }
    };
  }, [isEditing]);
  useEffect(() => {
    if (isEditing) {
      setEditFormData({
        ...editFormData,
        is_active_user: isActiveUser,
        is_reviewer: ssoObj.is_reviewer,
      });
    }
  }, [isActiveUser, ssoObj.is_reviewer]);
  return (
    <div className='text-primary text-base font-normal font-sans'>
      <h1 className='text-4xl font-bold'>
        {!isEditing ? 'Add SSO Setting' : 'Edit SSO Setting'}
      </h1>
      <div className=' w-2/5 mt-10'>
        <form>
          <div className='flex flex-col mb-3'>
            <label>{t('form.Entity Id')}</label>
            <input
              placeholder={`Insert ${t('form.Entity Id')}`}
              className='border-2 border-primary rounded-lg outline-none p-1 pl-2'
              value={!isEditing ? ssoObj.entityId : editFormData.entityId}
              onChange={(e) => {
                !isEditing
                  ? setssoObj({ ...ssoObj, entityId: e.target.value })
                  : setEditFormData({
                      ...editFormData,
                      entityId: e.target.value,
                    });
              }}
            />
            <p className='text-sm text-red-500 font-normal'>
              {ssoFormErrors.entityId}
            </p>
          </div>    
          <div className='flex flex-col mb-3'>
            <label>{t('form.Single Sign On Service')}</label>
            <input
              placeholder={`Insert ${t('form.Single Sign On Service')}`}
              className='border-2 border-primary rounded-lg outline-none p-1 pl-2'
              value={!isEditing ? ssoObj.singleSignOnService : editFormData.singleSignOnService}
              onChange={(e) => {
                !isEditing
                  ? setssoObj({ ...ssoObj, singleSignOnService: e.target.value })
                  : setEditFormData({
                      ...editFormData,
                      singleSignOnService: e.target.value,
                    });
              }}
            />
            <p className='text-sm text-red-500 font-normal'>
              {ssoFormErrors.singleSignOnService}
            </p>
          </div>    

          <div className='flex flex-col mb-3'>
            <label>{t('form.Single Logout Service')}</label>
            <input
              placeholder={`Insert ${t('form.Single Logout Service')}`}
              className='border-2 border-primary rounded-lg outline-none p-1 pl-2'
              value={!isEditing ? ssoObj.singleLogoutService : editFormData.singleLogoutService}
              onChange={(e) => {
                !isEditing
                  ? setssoObj({ ...ssoObj, singleLogoutService: e.target.value })
                  : setEditFormData({
                      ...editFormData,
                      singleLogoutService: e.target.value,
                    });
              }}
            />
            <p className='text-sm text-red-500 font-normal'>
              {ssoFormErrors.singleLogoutService}
            </p>
          </div> 
            
          <div className='flex flex-col mb-3'>
            <label>{t('form.SSO Certificate')}</label>
            <input
              placeholder={`Insert ${t('form.SSO Certificate')}`}
              className='border-2 border-primary rounded-lg outline-none p-1 pl-2'
              value={!isEditing ? ssoObj.sso_cretificate : editFormData.sso_cretificate}
              onChange={(e) => {
                !isEditing
                  ? setssoObj({ ...ssoObj, sso_cretificate: e.target.value })
                  : setEditFormData({
                      ...editFormData,
                      sso_cretificate: e.target.value,
                    });
              }}
            />
            <p className='text-sm text-red-500 font-normal'>
              {ssoFormErrors.sso_cretificate}
            </p>
          </div> 
      
          {!isEditing ? (
            <button
              className='bg-primary font-bold text-white w-full p-1 rounded-lg'
              onClick={(e) => {
                e.preventDefault();
                saveOrEditSSOSeting(ssoObj);
              }}
            >
               {t('modal.Save')}
            </button>
          ) : (
            <button
              className='bg-secondary font-bold text-white w-full p-1 rounded-lg'
              onClick={(e) => {
                e.preventDefault();
                saveOrEditSSOSeting(editFormData);
              }}
            >
              {t('modal.Update')}
            </button>
          )}
        </form>
      </div>
      <AppModal
        title={modalData.title}
        bodyText={modalData.bodyText}
        setIsOpen={setIsOpen}
        modalIsOpen={modalIsOpen}
        setActiveLink={setActiveLink}
        activeLink={shouldRedirect && 'SSOSetting'}
      />
    </div>
  );
};

export default SSOSettingFormlegacy;
