import React, { useContext, useState } from 'react';
import Modal from 'react-modal';
import './modal.css';
import AuthContext from '../../context/AuthContext';
import { useTranslation } from 'react-i18next';

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    maxWidth: '70%',
    maxHeight: '90vh',
    borderRadius: '11px',
    minWidth: '300px',
  },
  overlay: {
    backgroundColor: 'rgba(64, 8, 53, 0.63)',
  },
};

Modal.setAppElement('#root');
const ViewPlan = ({
  planModalIsOpen,
  setPlanModelIsOpen,
}) => {

  const { user, logoutUser } = useContext(AuthContext);
  const [activeTable, setActiveTable] = useState('individualPlan');
  const { t } = useTranslation();

  const handleMyDocumentList = (tableName) => {
    setActiveTable(tableName);
  };

  return (
    <div className=''>
      <Modal
        isOpen={planModalIsOpen}
        onRequestClose={() => setPlanModelIsOpen(true)}
        style={customStyles}
        onAfterClose={() => {

        }}
      >

        <div className=' modal-container'>
          <div className='flex justify-between'>
            <h1 className='text-center text-primary font-bold text-lg mb-2 w-4/5'>{t('form.Subscribe Plan')}</h1>
            <button
              onClick={(e) => {
                e.preventDefault();
                setPlanModelIsOpen(false);
                logoutUser();
              }}
              className='bg-[#606060] font-semibold text-white p-1 pl-2 pr-2 rounded-lg w-1/8'
            >
              X
            </button>
          </div>
          <hr style={{ height: '2px' }} />
          <div className="nav_tab mb-4">
            <ul className="ul_set">
              <li className={activeTable === 'individualPlan' ? 'active' : ''}
                onClick={(e) => {
                  e.stopPropagation();
                  handleMyDocumentList('individualPlan')
                }}
              ><a href="#">{t('form.Individual Plan')} </a></li>

              <li className={activeTable === 'business' ? 'active' : ''}
                onClick={(e) => {
                  e.stopPropagation();
                  handleMyDocumentList('business')
                }} ><a href="#">{t('form.Business Plan')}</a></li>

            </ul>
          </div>

          <div className="tab_main">
            <div className={`tab_us ${activeTable === 'individualPlan' ? 'show' : ''}`}>
            <div className='price_sec'>
            <div className='row'>
                <div className='col-md-4'>
                <stripe-pricing-table
                    pricing-table-id={process.env.REACT_APP_BASIC_PLAN_TABLE_ID}
                    publishable-key={process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY}
                    customer-email={user.user_email}
                    client-reference-id={user.user_id}
                  >
                  </stripe-pricing-table>                   
                  </div>
                  <div className='col-md-4'>
                  <stripe-pricing-table
                    pricing-table-id={process.env.REACT_APP_PERSONAL_PLAN_TABLE_ID}
                    publishable-key={process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY}
                    customer-email={user.user_email}
                    client-reference-id={user.user_id}
                  >
                  </stripe-pricing-table>
                  </div>
                  <div className='col-md-4'>
                  </div>
                  </div>
              </div>
              
              
            </div>

            <div className={`tab_us ${activeTable === 'business' ? 'show' : ''}`}>
              <div className='price_sec'>
              <div className='row'>
                <div className='col-md-4'>
                  <stripe-pricing-table                    
                    pricing-table-id={process.env.REACT_APP_ESSENTIAL_PLAN_TABLE_ID}
                    publishable-key={process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY}                   
                    customer-email={user.user_email}
                    client-reference-id={user.user_id}
                  >
                  </stripe-pricing-table>
                </div>
                <div className='col-md-4'>
                  <stripe-pricing-table
                    pricing-table-id={process.env.REACT_APP_ADVANCED_PLAN_TABLE_ID}
                    publishable-key={process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY}
                    customer-email={user.user_email}
                    client-reference-id={user.user_id}
                  >
                  </stripe-pricing-table>
                </div>
                <div className='col-md-4'>
                  <stripe-pricing-table
                    pricing-table-id={process.env.REACT_APP_PRO_PLAN_TABLE_ID}
                    publishable-key={process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY}
                    customer-email={user.user_email}
                    client-reference-id={user.user_id}
                  >
                  </stripe-pricing-table>
                </div>
              </div>
              </div>
              
            </div>
          </div>
        </div>
      </Modal >
    </div >
  );
};

export default ViewPlan;
