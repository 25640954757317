import React, { useEffect, useContext, useState } from "react";
import "./recipient.css";
import right_arrow from "../../assets/icons/right-arrow.png";
import DocumentContext from "../../context/DocumentContext";
import useAxios from "../../hooks/useAxios";
import { resolve } from "../../api/resolve";
import AppContext from "../../context/AppContext";
import { Worker, Viewer } from "@react-pdf-viewer/core";
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
import LoadingScreen from "../loader/LoadingScreen";
import ReactTooltip from "react-tooltip";
import DownArrow from "../../assets/icons/down-arrow.svg";
import HelpIcon from "../../assets/icons/Help-sm.svg";
import { autoCloseTimer, delay } from "../../constants/constants";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import eye_off from "../../assets/icons/eye_off.png";
import eye_on from "../../assets/icons/eye_on.png";
import FormContext from "../../context/FormContext";
import PermissionContext from "../../context/PermissionContext";
import AppModal from "../modals/Modal";

const Workflow = () => {
  const {
    documentListData,
    activeDocInfo,
    setActiveDocInfo,
    apiCurrentPage,
    setApiCurrentPage,
    getDocListWithStatusApi,
    apiDataLimit,
    nextBtnLimit,
    nextBtnLimitRecpt,
    setDocumentListData,
    activeDocStatus,
    isShowDocPreview,
    setIsShowDocPreview,
    getDocumentListApi,
    apiDataLimitRecpt,
  } = useContext(DocumentContext);

  const {
    setActiveLink,
    getSasToken,
    highlightToken,
    getHighlightSasToken,
    setShowDropdown,
  } = useContext(AppContext);
  const { t } = useTranslation();
  const { permissionList } = useContext(PermissionContext);
  const [receipientOrder, setReceipientOrder] = useState("one_after_other");
  const [receipientListData, setReceipientListData] = useState([]);
  const [selectedReceipient, setSelectedReceipient] = useState([]);
  const [docUrl, setDocUrl] = useState("");
  const [receipientOrderErr, setReceipientOrderErr] = useState("");
  const [loading] = useState(false);
  const [loadingApi, setLoadingApi] = useState(false);
  const [deleteDocumentId, setDeleteDocumentId] = useState(false);
  const { setIsOpen, modalIsOpen, modalData } = useContext(FormContext);
  const api = useAxios();
  const navigate = useNavigate();
  const [approverList, setapproverList] = useState([]);
  const [reviewerList, setreviewerList] = useState([]);
  const [othersignList, setothersignList] = useState([]);
  const getDocumentInfoApi = async (documentId) => {
    setReceipientListData([]);
    setSelectedReceipient([]);
    const responceData = await resolve(
      api
        .get(`/contract/document/recipients/list/${documentId}`)
        .then((res) => res.data)
    );

    if (responceData["data"] && responceData["data"].length > 0) {
      const listData = responceData.data;
      const tempReceipientListData = [];
      const tempSelectedReceipient = [];
      let orderSeq = false;

      listData.forEach((recipientInfo, index) => {
        if (recipientInfo.user_contacts_id === null) {
          tempReceipientListData.push({
            id: recipientInfo.user_id.id,
            name: recipientInfo.user_id.first_name,
            is_deligacy: recipientInfo.is_deligacy,
            sequence: recipientInfo.sequence,
            defaultValue: recipientInfo.sequence - 1 + "-" + (index + 1),
            // defaultValue: recipientInfo.user_id.id + "-" + (index + 1),
            type: "user",
            signingType: recipientInfo.typeOfSignature,
          });
          tempSelectedReceipient.push(recipientInfo.sequence);
        } else {
          tempReceipientListData.push({
            id: recipientInfo.user_contacts_id.id,
            name: recipientInfo.user_contacts_id.name,
            is_deligacy: recipientInfo.is_deligacy,
            sequence: recipientInfo.sequence,
            defaultValue: recipientInfo.sequence - 1 + "-" + (index + 1),
            // defaultValue: recipientInfo.user_contacts_id.id + "-" + (index + 1),
            type: "contact",
            signingType: recipientInfo.typeOfSignature,
          });
          tempSelectedReceipient.push(recipientInfo.sequence);
        }

        if (recipientInfo.sequence > 0) {
          orderSeq = true;
        }
      });
      if (orderSeq === false) {
        setReceipientOrder("all_at_same_time");
      } else {
        setReceipientOrder("one_after_other");
      }
      setReceipientListData(tempReceipientListData);
      setSelectedReceipient(tempSelectedReceipient);
    }
  };

  useEffect(() => {
    const approverList = receipientListData.filter(
      (user) => user.signingType == "Approver"
    );
    const reviewerList = receipientListData.filter(
      (user) => user.signingType == "Reviewer"
    );
    const othersignList = receipientListData.filter(
      (user) =>
        user.signingType !== "Approver" && user.signingType !== "Reviewer"
    );
    setapproverList(approverList);
    setreviewerList(reviewerList);
    setothersignList(othersignList);
  }, [receipientListData]);

  const deleteDocument = async (documentId) => {
    setDeleteDocumentId(documentId);
    setIsOpen(true);
  };

  const deleteDocumentConfimed = async () => {
    setIsOpen(false);
    const responceData = await resolve(
      api
        .get(`/contract/document/unassigned/${deleteDocumentId}`)
        .then((res) => res.data)
    );
    if (responceData["data"]) {
      toast.success("Document removed successfully");
      getDocumentListApi(apiCurrentPage, apiDataLimitRecpt, "2,6");
      setActiveDocInfo({});
    }

    if (responceData["error"]) {
      toast.error("Document not removed");
    }
  };
  const findDuplicates = (arr) => {
    let sorted_arr = arr.slice().sort(); // You can define the comparing function here.
    // JS by default uses a crappy string compare.
    // (we use slice to clone the array so the
    // original array won't be modified)
    let results = [];
    for (let i = 0; i < sorted_arr.length - 1; i++) {
      if (sorted_arr[i + 1] === sorted_arr[i]) {
        results.push(sorted_arr[i]);
      }
    }
    return results;
  };

  const submitDocumentStep2 = () => {
    setReceipientOrderErr("");
    if (receipientOrder === "all_at_same_time") {
      receipientListData.map((receipientInfo, index) => {
        receipientInfo["user_contacts_id"] = receipientInfo["id"];
        receipientInfo["sequence"] = 0;
        return 1;
      });
      updateRecipientOrder(receipientListData);
    } else {
      let combinedList = [...reviewerList, ...approverList, ...othersignList];
      combinedList.map((receipientInfo, index) => {
        receipientInfo["user_contacts_id"] = receipientInfo["id"];
        receipientInfo["sequence"] =
          selectedReceipient.indexOf(receipientInfo["id"]) + 1;
        return 1;
      });

      let duplicateOrderArr = findDuplicates(selectedReceipient);
      let isSelectionDone = 1;
      combinedList.map((receipientInfo, index) => {
        if (receipientInfo["sequence"] === 0) {
          isSelectionDone = 0;
        }
        return 1;
      });

      if (isSelectionDone) {
        if (duplicateOrderArr.length === 0) {
          updateRecipientOrder(combinedList);
        } else {
          setReceipientOrderErr(
            t("allModule.User selection should be unique.")
          );
        }
      } else {
        setReceipientOrderErr(
          t("allModule.Please Select all unique recipients.")
        );
      }
    }
  };

  const updateRecipientOrder = async (receipientList) => {
    let tempReceipientListData = [];
    let defaultRecipientInfo = {
      user_contacts_id: 0,
      sequence: 0,
    };

    receipientList.map((receipientInfo, index) => {
      if (receipientInfo["type"] === "user") {
        defaultRecipientInfo = {
          user_contacts_id: receipientInfo["user_contacts_id"],
          sequence: receipientInfo["sequence"],
        };
      } else {
        tempReceipientListData.push({
          user_contacts_id: receipientInfo["user_contacts_id"],
          sequence: receipientInfo["sequence"],
        });
      }
    });

    let postData = {
      document_id: activeDocInfo.id,
      recipientDetail: tempReceipientListData,
      userInfo: defaultRecipientInfo,
    };
    setLoadingApi(true);
    const responceData = await resolve(
      api
        .post(
          `/contract/document/recipients/sequence/`,
          JSON.stringify(postData),
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => res.data)
    );
    if (responceData["data"]) {
      toast.success(t("allModule.Recipient order updated successfully."), {
        delay,
        autoClose: autoCloseTimer,
        onClose: () => setLoadingApi(false),
      });
      setLoadingApi(false);
      const allApproversReviewers = receipientListData.every(
        (item) =>
          item.signingType === "Approver" || item.signingType === "Reviewer"
      );
      if (allApproversReviewers) {
        navigate("/stamps-seals/email-notifications");
        setActiveLink("EmailNotifications");
      } else {
        navigate("/stamps-seals");
        setActiveLink("StampsAndSeals");
      }
      setShowDropdown("Recipients");
      setApiCurrentPage(1);
    }

    if (responceData["error"]) {
      toast.error(t("common.Something went wrong"), {
        delay,
        autoClose: autoCloseTimer,
        onClose: () => setLoadingApi(false),
      });
      setLoadingApi(false);
    }
  };

  const documentChange = (item) => {
    setDocUrl("item.original_file_pdf_url");
    setIsShowDocPreview(false);
    setActiveDocInfo(item);
  };

  const receipientOrderHandle = (e) => {
    let receipientOrderValue = e.target.value;
    let receipientOrderArr = receipientOrderValue.split("-");

    const recipientIndex = document.getElementsByClassName("customDropdownNew");
    const recipientArray = Array.prototype.indexOf.call(
      recipientIndex,
      e.target.parentElement
    );

    // const tempSelectedReceipient = selectedReceipient.map((item) => item);
    selectedReceipient[recipientArray] = parseInt(receipientOrderArr[0]);
    setSelectedReceipient(selectedReceipient);
  };

  useEffect(() => {
    getDocListWithStatusApi(apiCurrentPage, apiDataLimit, "2,6", "workflow");
  }, [apiCurrentPage, apiDataLimit]);

  useEffect(() => {
    if (activeDocInfo?.id) {
      setDocUrl(activeDocInfo.original_file_pdf_url);
      getDocumentInfoApi(activeDocInfo.id);
    }
  }, [activeDocInfo]);

  useEffect(() => {
    if (
      receipientOrder == "one_after_other" &&
      receipientListData.length == 1
    ) {
      let receipientOrderValue = "0-1";
      let receipientOrderArr = receipientOrderValue.split("-");

      selectedReceipient[receipientOrderArr[1] - 1] =
        parseInt(receipientOrderArr[0]) + 1;
      // setSelectedReceipient([selectedReceipient]);
      setSelectedReceipient([...selectedReceipient]);
    }
  }, [receipientOrder]);

  // useEffect(() => {
  //   if (documentListData.length > 0) {
  //     setDocUrl(documentListData[0].original_file_pdf_url);
  //   }
  // }, [documentListData]);

  useEffect(() => {
    getSasToken();
    getHighlightSasToken();
  }, []);

  const renderToolbar = (Toolbar) => (
    <Toolbar>
      {(slots) => {
        const {
          CurrentPageInput,
          GoToNextPage,
          GoToPreviousPage,
          NumberOfPages,

          CurrentScale,
          ZoomIn,
          ZoomOut,
        } = slots;

        return (
          <div className={`w-full`}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                width: "100%",
                justifyContent: "space-between",
              }}
            >
              <div className={`flex ml-8 items-center justify-center`}>
                <div className="w-10">
                  <CurrentPageInput />
                </div>
                <div className="mx-2">
                  /{" "}
                  <span>
                    <NumberOfPages />
                  </span>
                </div>
                <div className="flex flex-col ml-1">
                  <GoToPreviousPage>
                    {(props) => (
                      <>
                        <span
                          disabled={props.isDisabled}
                          onClick={props.onClick}
                          style={{
                            cursor: "pointer",
                            transform: "rotate(180deg)",
                            width: 15,
                            height: 15,
                          }}
                          className="ml-1"
                          data-tip="Previous Page"
                        >
                          <img src={DownArrow} alt="img" />
                        </span>
                        <ReactTooltip
                          backgroundColor="#400835"
                          textColor="#ffffff"
                        />
                      </>
                    )}
                  </GoToPreviousPage>
                  <GoToNextPage>
                    {(props) => (
                      <>
                        <span
                          disabled={props.isDisabled}
                          onClick={props.onClick}
                          style={{
                            cursor: "pointer",
                            marginTop: 2,
                            width: 15,
                            height: 15,
                          }}
                          className="ml-1"
                          data-tip="Next Page"
                        >
                          <img src={DownArrow} alt="img" />
                        </span>
                        <ReactTooltip
                          backgroundColor="#400835"
                          textColor="#ffffff"
                        />
                      </>
                    )}
                  </GoToNextPage>
                </div>
              </div>
              <div className="max-w-fit flex items-center">
                <ZoomIn />
                <span className="mx-1">{CurrentScale()}</span>
                <ZoomOut />
              </div>
            </div>
          </div>
        );
      }}
    </Toolbar>
  );
  /////////// pdf search fuctionality ////////////////////
  const defaultLayoutPluginInstance = defaultLayoutPlugin({
    renderToolbar,
    sidebarTabs: () => [],
  });

  return (
    <div className="text-primary text-base font-normal font-sans mt_set">
      <div className="main_head">
        <div className="flex">
          <h1 className="text-4xl font-bold text_c">
            {t("menu.Set Signing Order")}
          </h1>
          <>
            <img
              src={HelpIcon}
              width={12}
              className="ml-1 -mt-2 cursor-pointer"
              data-tip={t(
                "allModule.workflows help to set signing order of the recipients."
              )}
            />
            <ReactTooltip backgroundColor="#400835" textColor="#ffffff" />
          </>
        </div>
      </div>

      {permissionList["workflow_on"] == false ? (
        <div className="flex justify-center items-center h-96 flex-column ">
          <h3 className="text-2xl font-bold text_c">
            {t("allModule.You dont have permission to access this page")}
          </h3>
        </div>
      ) : documentListData.length > 0 && loadingApi == false ? (
        <>
          <div className="row ">
            <div className="col-md-12">
              <div className="w-full doc_list_btn">
                <div className="flex flex-col mb-4">
                  <div className="btn_us3">
                    <button
                      className="btn pb-1 pt-0.5 pl-6 pr-6 text_c font-bold rounded-lg next-pre-btn "
                      onClick={() => {
                        if (apiCurrentPage > 1) {
                          setApiCurrentPage(apiCurrentPage - 1);
                        }
                      }}
                    >
                      <img src={right_arrow} className="right rotateIcon" />
                    </button>

                    {documentListData.map((item, index) => {
                      return (
                        <React.Fragment key={index}>
                          <button
                            style={{ position: "relative" }}
                            className={`set_w2 btn pb-1 pt-0.5 pl-6 pr-6 text_c font-semibold ${
                              item.id == activeDocInfo?.id ? "active" : ""
                            }`}
                            onClick={() => documentChange(item)}
                          >
                            {item.name}

                            <img
                              onClick={(e) => {
                                e.stopPropagation();
                                let isShowDoc = false;
                                const newState = documentListData.map((obj) => {
                                  if (obj.id === item.id) {
                                    isShowDoc = !item.isShowDoc;
                                    return {
                                      ...obj,
                                      isShowDoc: !item.isShowDoc,
                                    };
                                  } else {
                                    return { ...obj, isShowDoc: false };
                                  }
                                });
                                setDocumentListData(newState);
                                if (isShowDoc) {
                                  setIsShowDocPreview(isShowDoc);
                                }
                                if (!item.isShowDoc) {
                                  setDocUrl(item.original_file_pdf_url);
                                } else {
                                  setDocUrl("");
                                }
                              }}
                              src={!item.isShowDoc ? eye_off : eye_on}
                              className="eye-icon-document"
                            />

                            {item.id !== activeDocInfo?.id && (
                              <span
                                onClick={(e) => {
                                  e.stopPropagation();
                                  deleteDocument(item.id);
                                }}
                                className="recipient-cross-icon"
                              >
                                X
                              </span>
                            )}
                          </button>
                        </React.Fragment>
                      );
                    })}
                    <button
                      onClick={() => {
                        if (apiCurrentPage < nextBtnLimitRecpt) {
                          setApiCurrentPage(apiCurrentPage + 1);
                        }
                      }}
                      className="btn pb-1 pt-0.5 pl-6 pr-6 text_c font-bold rounded-lg next-pre-btn float-right"
                    >
                      <img src={right_arrow} className="right" />
                    </button>
                  </div>
                </div>
              </div>
              <h4 className="text-xl font-bold  mb-2 text_c text-center">
                {" "}
                {activeDocInfo?.id ? activeDocInfo.name : ""}
              </h4>
              <div className="box_u1 p-4">
                {docUrl && isShowDocPreview && (
                  <div className="box_u2 sele_scroll">
                    <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.13.216/build/pdf.worker.min.js">
                      {loading ? (
                        <LoadingScreen />
                      ) : (
                        <>
                          {docUrl && (
                            <Viewer
                              fileUrl={`${docUrl}?${highlightToken.sas_token}`}
                              plugins={[
                                defaultLayoutPluginInstance,
                                // searchPluginInstance,
                              ]}
                              onDocumentAskPassword={(e) => {}}
                              renderError={() => (
                                <div className="grid place-items-center h-full w-full">
                                  <h1 className="font-semibold">
                                    {t(
                                      "common.Contract indexing in progress. Check back later"
                                    )}
                                  </h1>
                                </div>
                              )}
                              renderLoader={() => <LoadingScreen />}
                            />
                          )}
                        </>
                      )}
                    </Worker>
                  </div>
                )}
                {activeDocInfo?.id ? (
                  <div className="box_u3">
                    <h4 className="text-xl font-bold mt-5 mb-2 text_c">
                      {t("allModule.Select Review-Approval/Signing Order")}
                    </h4>
                    <label
                      htmlFor="one_after_other"
                      className="font-semibold mb-1"
                    >
                      <input
                        type="radio"
                        id="one_after_other"
                        name="uss"
                        checked={
                          receipientOrder === "one_after_other" ? true : false
                        }
                        onChange={(e) => {
                          setReceipientOrder("one_after_other");
                        }}
                      />{" "}
                      {t("allModule.One after the other")}{" "}
                    </label>
                    <br />
                    <label
                      htmlFor="all_at_same_time"
                      className="font-semibold mb-1"
                    >
                      <input
                        type="radio"
                        id="all_at_same_time"
                        name="uss"
                        checked={
                          receipientOrder === "all_at_same_time" ? true : false
                        }
                        onChange={(e) => {
                          setReceipientOrder("all_at_same_time");
                          receipientListData.map((recipient, index) => {
                            recipient.defaultValue = "0";
                            return 1;
                          });
                          setReceipientListData([...receipientListData]);
                        }}
                      />{" "}
                      {t("allModule.All at the same time")}
                    </label>
                  </div>
                ) : (
                  <div className="no_document_available_box">
                    <p>{t("allModule.Please select document")}.</p>
                  </div>
                )}
                <div className="box_u4 arrow_set mt-4 ">
                  <p className="error_msg">{receipientOrderErr}</p>
                  {receipientOrder === "one_after_other" ? (
                    <div className="me_grid ">
                      {reviewerList.map((recipient, index) => {
                        return (
                          <div
                            key={"recipient_list" + index}
                            className={
                              receipientOrder === "all_at_same_time"
                                ? "both-direction customDropdownNew"
                                : " customDropdownNew"
                            }
                          >
                            <select
                              onChange={(e) => {
                                receipientOrderHandle(e);
                              }}
                              disabled={
                                receipientOrder === "all_at_same_time"
                                  ? true
                                  : false
                              }
                              defaultValue={
                                reviewerList.length > 1
                                  ? recipient.defaultValue
                                  : "0-1"
                              }
                              // defaultValue={recipient.defaultValue}
                              className="border-2 border-primary rounded-lg outline-none p-0.5 pl-2 heg4 w-full"
                            >
                              <option value="0">
                                {t("allModule.Select Reviewer")}
                              </option>
                              {reviewerList.map((recipientLevel02, index02) => {
                                return (
                                  <option
                                    value={`${recipientLevel02.id}-${
                                      index + 1
                                    }`}
                                    key={
                                      "recipient_list" +
                                      index +
                                      "_inner_" +
                                      index02
                                    }
                                  >
                                    {recipientLevel02.name}{" "}
                                    {recipientLevel02.is_deligacy == true
                                      ? " (Delegatee)"
                                      : ""}
                                  </option>
                                );
                              })}
                            </select>
                          </div>
                        );
                      })}
                      {approverList.map((recipient, index) => {
                        return (
                          <div
                            key={"recipient_list" + index}
                            className={
                              receipientOrder === "all_at_same_time"
                                ? "both-direction customDropdownNew"
                                : "customDropdownNew"
                            }
                          >
                            <select
                              onChange={(e) => {
                                receipientOrderHandle(e);
                              }}
                              disabled={
                                receipientOrder === "all_at_same_time"
                                  ? true
                                  : false
                              }
                              defaultValue={
                                approverList.length > 1
                                  ? recipient.defaultValue
                                  : "0-1"
                              }
                              // defaultValue={recipient.defaultValue}
                              className="border-2 border-primary rounded-lg outline-none p-0.5 pl-2 heg4 w-full"
                            >
                              <option value="0">
                                {t("allModule.Select Approver")}
                              </option>
                              {approverList.map((recipientLevel02, index02) => {
                                return (
                                  <option
                                    value={`${recipientLevel02.id}-${
                                      index + 1
                                    }`}
                                    key={
                                      "recipient_list" +
                                      index +
                                      "_inner_" +
                                      index02
                                    }
                                  >
                                    {recipientLevel02.name}{" "}
                                    {recipientLevel02.is_deligacy == true
                                      ? " (Delegatee)"
                                      : ""}
                                  </option>
                                );
                              })}
                            </select>
                          </div>
                        );
                      })}
                      {othersignList.map((recipient, index) => {
                        return (
                          <div
                            key={"recipient_list" + index}
                            className={
                              receipientOrder === "all_at_same_time"
                                ? "both-direction customDropdownNew"
                                : "customDropdownNew"
                            }
                          >
                            <select
                              onChange={(e) => {
                                receipientOrderHandle(e);
                              }}
                              disabled={
                                receipientOrder === "all_at_same_time"
                                  ? true
                                  : false
                              }
                              defaultValue={
                                othersignList.length > 1
                                  ? recipient.defaultValue
                                  : "0-1"
                              }
                              // defaultValue={recipient.defaultValue}
                              className="border-2 border-primary rounded-lg outline-none p-0.5 pl-2 heg4 w-full"
                            >
                              <option value="0">
                                {t("allModule.Select Signer")}
                              </option>
                              {othersignList.map(
                                (recipientLevel02, index02) => {
                                  return (
                                    <option
                                      value={`${recipientLevel02.id}-${
                                        index + 1
                                      }`}
                                      key={
                                        "recipient_list" +
                                        index +
                                        "_inner_" +
                                        index02
                                      }
                                    >
                                      {recipientLevel02.name}{" "}
                                      {recipientLevel02.is_deligacy == true
                                        ? " (Delegatee)"
                                        : ""}
                                    </option>
                                  );
                                }
                              )}
                            </select>
                          </div>
                        );
                      })}
                    </div>
                  ) : (
                    <div className="me_grid ">
                      {receipientListData.map((recipient, index) => {
                        return (
                          <div key={recipient.id} className={"both-direction"}>
                            <select
                              disabled={true}
                              defaultValue={recipient.id}
                              className="border-2 border-primary rounded-lg outline-none p-0.5 pl-2 heg4 w-full"
                            >
                              <option
                                value={`${recipient.id}`}
                                key={recipient.id}
                              >
                                {recipient.name}{" "}
                                {recipient.is_deligacy == true
                                  ? " (Delegatee)"
                                  : ""}
                              </option>
                            </select>
                          </div>
                        );
                      })}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="set_btn2 mt-4">
            <button
              onClick={(e) => {
                e.preventDefault();
                navigate("/apply-signature/select-recipients");
                setActiveLink("Recipients");
                setApiCurrentPage(1);
              }}
              className="btn pb-1 pt-0.5 pl-6 pr-6 text_c font-bold rounded-lg btn_theme3"
            >
              {t("allModule.Previous")}
            </button>
            <button
              onClick={(e) => {
                e.stopPropagation();
                submitDocumentStep2();
              }}
              className="btn pb-1 pt-0.5 pl-6 pr-6 text_c font-bold rounded-lg btn_theme3"
            >
              {t("common.Next")}
            </button>
          </div>
          <AppModal
            title={t(
              "allModule.Are you sure you want to remove the document from this page?"
            )}
            bodyText={t(
              "allModule.Note: This will not delete the document permanently"
            )}
            setIsOpen={setIsOpen}
            modalIsOpen={modalIsOpen}
            modelAction={`RecipientDelete`}
            deleteDocumentConfimed={deleteDocumentConfimed}
          />
        </>
      ) : loadingApi ? (
        <LoadingScreen />
      ) : (
        <div className="no_document_available_box">
          <p>{t("allModule.No Assigned Document Available.")}</p>
        </div>
      )}
    </div>
  );
};

export default Workflow;
