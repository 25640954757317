import React, { useState, useContext } from 'react';
import { Navigate } from 'react-router-dom';
import { validateEmail } from '../../utils/utils';
import AuthContext from '../../context/AuthContext';
import ForgotPasswordUI from './ForgotPasswordUI';
import LoadingScreen from '../loader/LoadingScreen';
import { useTranslation } from 'react-i18next';

const ForgotPassword = ({ title }) => {
  const { t } = useTranslation();
  const { sendPasswordResetLink, user } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const [formErrors, setFormErrors] = useState({ email: '' });
  if (user) {
    return <Navigate to='/dashboard' />;
  }
  const onSubmit = (email) => {
    if (!validateEmail(email)) {
      setFormErrors({ ...formErrors, email: t('loginPage.Please enter a valid email!') });
    } else {
      sendPasswordResetLink(email, setLoading);
    }
  };
  return (
    loading ? (
      <LoadingScreen />
    ) : (
      <ForgotPasswordUI
        title={title}
        formErrors={formErrors}
        setFormErrors={setFormErrors}
        onSubmit={onSubmit}
      />)


  );
};


export default ForgotPassword;
