import { createContext, useState, useEffect } from "react";
import { toast } from "react-toastify";
import axios from "axios";
import jwt_decode from "jwt-decode";
import { useNavigate } from "react-router-dom";
import { autoCloseTimer, delay } from "../constants/constants";
import { useTranslation } from "react-i18next";

const AuthContext = createContext();

export default AuthContext;

export const AuthProvider = ({ children }) => {
  let [authTokens, setAuthTokens] = useState(() =>
    localStorage.getItem("authTokens")
      ? JSON.parse(localStorage.getItem("authTokens"))
      : null
  );

  let [user, setUser] = useState(() =>
    localStorage.getItem("authTokens")
      ? jwt_decode(JSON.parse(localStorage.getItem("authTokens")).access)
      : null
  );

  const [loading, setLoading] = useState(false);
  const [apiStatus, setApiStatus] = useState(false);
  const [msalInstance, onMsalInstanceChange] = useState();
  const navigate = useNavigate();
  const { t } = useTranslation();


  // Theme management
  const [theme, setTheme] = useState(() =>
    localStorage.getItem("theme") || "light"
  );

  useEffect(() => {
    document.documentElement.setAttribute('data-theme', theme);
    localStorage.setItem("theme", theme);
  }, [theme]);

  const toggleTheme = () => {
    const newTheme = theme === "light" ? "dark" : "light";
    setTheme(newTheme);
  };

  const userSignUp = async (
    userSignUpObj,
    setSignUpStep,
    setLoading,
    setUserSignUpObj
  ) => {
    setLoading(true);
    try {
      var postData = {
        first_name: userSignUpObj.userName,
        email: userSignUpObj.userEmailId,
        password: xorEncryptDecrypt(userSignUpObj.userPassword, process.env.REACT_APP_LOGIN_PASSWORD_KEY),
      };
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_BASE_URL}/user/register/`,
        JSON.stringify(postData),
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const data = await response.data;
      setLoading(false);
      setSignUpStep("step3");
    } catch (error) {
      setLoading(false);
      let data;
      if (error && error.response) data = error.response.data;

      if (data.email) {
        toast.error(data.email[0], {
          delay,
          autoClose: autoCloseTimer,
          onClose: () => setLoading(false),
        });
      } else {
        toast.error(t("common.Something went wrong"), {
          delay,
          autoClose: autoCloseTimer,
          onClose: () => setLoading(false),
        });
      }
    }
  };

  const resentVerificationEmailApi = async (
    userSignUpObj,
    setSignUpStep,
    setLoading
  ) => {
    setLoading(true);
    try {
      var postData = { email: userSignUpObj.userEmailId };
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_BASE_URL}/user/registeruser_email/`,
        JSON.stringify(postData),
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const data = await response.data;
      setLoading(false);
      setSignUpStep("step5");
    } catch (error) {
      setLoading(false);
      let data;
      if (error && error.response) data = error.response.data;
      if (data.error) {
        toast.error(data.error, {
          delay,
          autoClose: autoCloseTimer,
          onClose: () => setLoading(false),
        });
      } else {
        toast.error(t("common.Something went wrong"), {
          delay,
          autoClose: autoCloseTimer,
          onClose: () => setLoading(false),
        });
      }
    }
  };

  function xorEncryptDecrypt(input, key) {
    let output = "";
    for (let i = 0; i < input.length; i++) {
      output += String.fromCharCode(
        input.charCodeAt(i) ^ key.charCodeAt(i % key.length)
      );
    }
    return btoa(output);
  }

  const loginUser = async (email, password, updatePermissionList) => {
    setLoading(true);
    try {
      const key = `${process.env.REACT_APP_LOGIN_PASSWORD_KEY}`;
      if (!key){
        console.log("Key not found")
      }
      const resultString = xorEncryptDecrypt(password, key);
      password = resultString;
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_BASE_URL}/api/token/`,
        JSON.stringify({
          email,
          password,
        }),
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const data = await response.data;

      setAuthTokens(data["data"]);
      const userData = jwt_decode(data["data"].access);

      setUser(userData);
      localStorage.setItem("authTokens", JSON.stringify(data["data"]));
      setLoading(false);

      // update user menu permission
      updatePermissionList(data["User_permission"]);

      navigate("/dashboard");
    } catch (error) {
      let data;
      if (error && error.response) data = error.response.data;

      if (
        data?.detail === "No active account found with the given credentials"
      ) {
        toast.error(t("form.Invalid Credentials"), {
          delay,
          autoClose: autoCloseTimer,
          onClose: () => setLoading(false),
        });
      } else if (
        data?.detail === "Subscription expired please contact system admin"
      ) {
        toast.error(t("form.Contract Expired"), {
          delay,
          autoClose: autoCloseTimer,
          onClose: () => setLoading(false),
        });
      } else if (data?.code === "ALREADY_LOGGED_IN") {
        toast.error(t("form.Logout from other device and try again"), {
          delay,
          autoClose: autoCloseTimer,
          onClose: () => setLoading(false),
        });
      } else if (data?.code === "INVALID_EMAIL") {
        toast.error(t("form.Please verify email and try again"), {
          delay,
          autoClose: autoCloseTimer,
          onClose: () => setLoading(false),
        });
      } else if (data?.code === "Account_Deactivate") {
        toast.error(
          t(
            "form.Your account has been deactivated. Please contact your Admin."
          ),
          {
            delay,
            autoClose: autoCloseTimer,
            onClose: () => setLoading(false),
          }
        );
      } else if (data?.code === "INVALID_CREDENTAILS") {
        toast.error(
          `${t("form.Invalid Credentials")}, ${data?.msg} ${t(
            "form.attempts remaining"
          )}`,
          {
            delay,
            autoClose: autoCloseTimer,
            onClose: () => setLoading(false),
          }
        );
      } else if (data?.code === "ACCOUNT_LOCKED") {
        toast.error(
          t(
            "form.Account Locked/Unavailable. Please reset password to unlock or sign up"
          ),
          {
            delay,
            autoClose: autoCloseTimer,
            onClose: () => setLoading(false),
          }
        );
      } else {
        toast.error(t("common.Something went wrong"), {
          delay,
          autoClose: autoCloseTimer,
          onClose: () => setLoading(false),
        });
      }
    }
  };

  const googleLoginUser = async (
    postData,
    setLoading,
    updatePermissionList
  ) => {
    setLoading(true);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_BASE_URL}/user/google/auth/`,
        JSON.stringify(postData),
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const data = await response.data;

      setAuthTokens(data["data"]);
      setUser(jwt_decode(data["data"].access));
      localStorage.setItem("authTokens", JSON.stringify(data["data"]));

      // update user menu permission
      updatePermissionList(data["User_permission"]);

      setLoading(false);
      navigate("/dashboard");
    } catch (error) {
      let data;
      if (error && error.response) data = error.response.data;
      if (
        data?.detail === "No active account found with the given credentials"
      ) {
        toast.error(t("form.Invalid Credentials"), {
          delay,
          autoClose: autoCloseTimer,
          onClose: () => setLoading(false),
        });
      } else if (
        data?.detail === "Subscription expired please contact system admin"
      ) {
        toast.error(t("form.Contract Expired"), {
          delay,
          autoClose: autoCloseTimer,
          onClose: () => setLoading(false),
        });
      } else if (data?.code === "ALREADY_LOGGED_IN") {
        toast.error(t("form.Logout from other device and try again"), {
          delay,
          autoClose: autoCloseTimer,
          onClose: () => setLoading(false),
        });
      } else {
        toast.error(t("common.Something went wrong"), {
          delay,
          autoClose: autoCloseTimer,
          onClose: () => setLoading(false),
        });
      }
    }
  };

  const logoutUser = async () => {
    try {
      await axios.get(
        `${process.env.REACT_APP_BACKEND_BASE_URL}/user/logout/${user.user_id}`
      );

      setAuthTokens(null);
      setUser(null);
      localStorage.removeItem("authTokens");
      localStorage.removeItem("userPermissions");
      localStorage.removeItem("createTemplateContent");
      localStorage.removeItem("generateDocumentContent");
      localStorage.removeItem("purchaseAadharSession");
      localStorage.removeItem("generateTemplateContent");
      localStorage.removeItem("activeLink");
      navigate("/");
    } catch (error) {}
  };

  const sendPasswordResetLink = async (email, setLoading) => {
    setLoading(true);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_BASE_URL}/user/forgot_password/`,
        JSON.stringify({
          email,
        }),
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      toast.success(
        t(
          "Email Sent. If your email matches our records, you will receive it shortly"
        ),
        {
          delay,
          autoClose: autoCloseTimer,
          onClose: () => setLoading(false),
        }
      );
      setApiStatus(true);
      setLoading(false);
    } catch (error) {
      setApiStatus(false);
      let data;
      if (error && error.response) data = error.response.data;
      if (data?.error === "Email does not exist") {
        toast.success(
          t(
            "Email Sent. If your email matches our records, you will receive it shortly"
          ),
          {
            delay,
            autoClose: autoCloseTimer,
            onClose: () => setLoading(false),
          }
        );
      } else {
        toast.error(t("common.Something went wrong"), {
          delay,
          autoClose: autoCloseTimer,
          onClose: () => setLoading(false),
        });
      }

      setLoading(false);
    }
  };

  const resetPassword = async (userObj) => {
    setLoading(true);
    const key = process.env.REACT_APP_LOGIN_PASSWORD_KEY;
    userObj.email = xorEncryptDecrypt(userObj.email, key);
    userObj.password = xorEncryptDecrypt(userObj.password, key);
    try {
      await axios.post(
        `${process.env.REACT_APP_BACKEND_BASE_URL}/user/reset_password/`,
        JSON.stringify({
          ...userObj,
        }),
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      toast.success(
        <>
          <span>{t("form.Sucesss")}</span>
          <p>{t("form.Redirecting to login")}...</p>
        </>,
        {
          delay,
          autoClose: autoCloseTimer,
          onClose: () => {
            setLoading(false);
            navigate("/");
          },
        }
      );
    } catch (error) {
      let data;
      if (error && error.response) data = error.response.data;
      if (data?.error === "Token Expired") {
        toast.error(
          <>
            <span>{t("form.Link Expired")}</span>
            <p>{t("form.Redirecting to new link page")}</p>
          </>,
          {
            delay,
            autoClose: autoCloseTimer,
            onClose: () => {
              setLoading(false);
              navigate("/forgot-password");
            },
          }
        );
      } else {
        toast.error(t("common.Something went wrong"), {
          delay,
          autoClose: autoCloseTimer,
          onClose: () => setLoading(false),
        });
      }
    }
  };

  useEffect(() => {
    const storageListener = (e) => {
      if (e.key === "authTokens" && e.newValue === null) {
        logoutUser();
      }
    };

    window.addEventListener("storage", storageListener);

    return () => {
      window.removeEventListener("storage", storageListener);
    };
  }, []);

  const contextData = {
    user,
    authTokens,
    setAuthTokens,
    setUser,
    loginUser,
    googleLoginUser,
    logoutUser,
    loading,
    sendPasswordResetLink,
    resetPassword,
    apiStatus,
    setApiStatus,
    userSignUp,
    resentVerificationEmailApi,
    msalInstance,
    onMsalInstanceChange,
    theme,
    toggleTheme
  };

  useEffect(() => {
    if (authTokens) {
      setUser(jwt_decode(authTokens.access));
    }
  }, [authTokens]);

  return (
    <AuthContext.Provider value={contextData}>{children}</AuthContext.Provider>
  );
};
