import React, { useContext, useState, useEffect } from "react";
import Modal from "react-modal";
import "./modal.css";
import LoadingScreen from "../loader/LoadingScreen";
import { parseISO, format } from "date-fns";
import { useTranslation } from "react-i18next";
import DownloadIcon from "../../assets/icons/downloadd1.png";
import ReactTooltip from "react-tooltip";
import useAxios from "../../hooks/useAxios";
import { toast } from "react-toastify";
import { autoCloseTimer } from "../../constants/constants";
import EyeOnIconWhite from "../../assets/icons/eye_on_white.png";
import DocumentPreviewModal from "../modals/DocumentPreviewModal";

const customStyles = {
  content: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    minWidth: "1200px",
    height: 560,
    overflowY: "auto",
    borderRadius: "5px",
    border: "2px solid #310128",
  },
  overlay: {
    backgroundColor: "rgba(64, 8, 53, 0.63)",
  },
};

Modal.setAppElement("#root");
const ReportListModal = ({
  isReportsModalOpen,
  setIsReportsModalOpen,
  reportLists,
  docName,
}) => {
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const baseUrl = process.env.REACT_APP_BACKEND_BASE_URL;
  const api = useAxios();
  const [previewDocument, setPreviewDocument] = useState(false);
  const [previewDocumentData, setPreviewDocumentData] = useState({});

  function truncateText(text, maxLength) {
    if (text == null) {
      return "N/A";
    }
    if (text.length <= maxLength) {
      return text;
    }
    return text.substring(0, maxLength) + "...";
  }

  function getFirstKey(jsonData) {
    try {
      if (jsonData == null) {
        return "N/A";
      }
      const parsedData = JSON.parse(jsonData);
      const keys = Object.keys(parsedData);
      return keys.length > 0 ? keys[0] : "";
    } catch (error) {
      console.error("Invalid JSON data:", error);
      return "";
    }
  }

  const downlaodDocument = async (docId) => {
    const apiUrl = `${baseUrl}/contract/download/document/${docId}`;
    let authTokens = localStorage.getItem("authTokens")
      ? JSON.parse(localStorage.getItem("authTokens"))
      : null;
    const headers = {
      Authorization: `Bearer ${authTokens?.access}`,
    };
    const res = await api.get(apiUrl, {
      headers,
      "Content-Type": "application/json",
    });
    if (res.data) {
      authTokens = localStorage.getItem("authTokens")
        ? JSON.parse(localStorage.getItem("authTokens"))
        : null;
      const apiUrl = `${baseUrl}/contract/download/document/${docId}?token=${authTokens?.access}`;
      window.open(apiUrl);
    }

    if (res.error) {
      if (res["error"]?.response?.data?.message) {
        toast.error(res["error"].response["data"].message, {
          autoClose: autoCloseTimer,
          hideProgressBar: true,
        });
      } else {
        toast.error(t("common.Something went wrong"), {
          autoClose: autoCloseTimer,
          hideProgressBar: true,
        });
      }
    }
  };

  // useEffect(() => {
  //   console.log("reportLists in popup", reportLists);
  // }, [isReportsModalOpen]);

  return (
    <>
      <div className="">
        <Modal
          isOpen={isReportsModalOpen}
          onRequestClose={() => setIsReportsModalOpen(false)}
          style={customStyles}
          contentLabel="Example Modal"
          onAfterClose={() => {}}
        >
          <div className="relative text-center modal-container">
            <h1 className="text-primary font-bold text-lg mb-2">
              {t("allModule.List of AI Review Reports for")} {docName}
            </h1>
            <button
              className="absolute top-0 right-0 text-[#606060] font-semibold p-1 pl-2 pr-2 mr-2 rounded-lg h-10"
              onClick={() => setIsReportsModalOpen(false)}
            >
              {t("X")}
            </button>

            <hr />
            {loading == false ? (
              <>
                <div className="mt-2 table_desin">
                  <div className="shadow overflow-hidden border-2 border-primary sm:rounded-lg overflow-y-auto">
                    <div>
                      {reportLists.length === 0 ? (
                        <p>No Documents available to show</p>
                      ) : (
                        <table className="w-full">
                          <thead>
                            <tr>
                              <th className="px-4 py-2">
                                {t("form.Document Name")}
                              </th>
                              <th className="px-4 py-2">
                                {t("constant.Type")}
                              </th>
                              <th className="px-4 py-2">{t("form.Section")}</th>
                              <th className="px-4 py-2">{t("form.Task")}</th>
                              <th className="px-4 py-2">
                                {t("allModule.Created on")}
                              </th>
                              <th className="px-4 py-2">
                                {t("constant.Action")}
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {reportLists.map((report, index) => (
                              <tr key={index}>
                                <td className="border px-4 py-2">
                                  <span
                                    data-tip={report.name}
                                    className="cursor-pointer"
                                  >
                                    {truncateText(report.name, 30)}
                                  </span>
                                </td>
                                <td className="border px-4 py-2">
                                  {report.type}
                                </td>
                                <td className="border px-4 py-2">
                                  <span
                                    data-tip={getFirstKey(report.json_data)}
                                    className="cursor-pointer"
                                  >
                                    {truncateText(
                                      getFirstKey(report.json_data),
                                      30
                                    )}
                                  </span>
                                </td>
                                <td className="border px-4 py-2">
                                  <span
                                    data-tip={report.taskdecpt}
                                    className="cursor-pointer"
                                  >
                                    {truncateText(report.taskdecpt, 30)}
                                  </span>
                                </td>
                                <td className="border px-4 py-2">
                                  {format(
                                    parseISO(report.created_at),
                                    "ccc, dd MMM yyyy, HH:mm"
                                  )}
                                </td>
                                <td className="border px-4 py-2">
                                  <button
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      setPreviewDocument(true);
                                      setPreviewDocumentData(report);
                                    }}
                                    className="btn btn_theme btn-sm mr-1 wh_spab w-10"
                                    data-tip={t("constant.View")}
                                  >
                                    <img
                                      src={EyeOnIconWhite}
                                      className="ml-0.5"
                                      width={20}
                                      alt="View Document"
                                    />
                                  </button>
                                  <button
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      downlaodDocument(report.id);
                                    }}
                                    className="btn btn_theme btn-sm wh_spab w-10"
                                    data-tip={t("constant.Download document")}
                                  >
                                    <img
                                      src={DownloadIcon}
                                      className=""
                                      width={20}
                                      alt="Download Document"
                                    />
                                  </button>
                                </td>
                                <ReactTooltip
                                  backgroundColor="#400835"
                                  textColor="#ffffff"
                                  className="max-w-lg break-words"
                                />
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      )}
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <div className="flex justify-center items-center h-96">
                <LoadingScreen />
              </div>
            )}
          </div>
        </Modal>
      </div>
      <ReactTooltip backgroundColor="#400835" textColor="#ffffff" />
      <DocumentPreviewModal
        isOpen={previewDocument}
        setIsOpen={setPreviewDocument}
        previewDocumentData={previewDocumentData}
      />
    </>
  );
};

export default ReportListModal;
