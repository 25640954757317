import React, { useState, useContext } from "react";
import { useTable } from "react-table";
import "../contracts/contracts.css";
import DownArrow from "../../assets/icons/down-arrow.svg";
import ReactTooltip from "react-tooltip";
import FormContext from "../../context/FormContext";
import AppContext from "../../context/AppContext";
import AuthContext from "../../context/AuthContext";
import EditIcon from "../../assets/icons/Edit.svg";
import DeleteIcon from "../../assets/icons/Delete.svg";
import DeleteModal from "../modals/DeleteModal";
import { resolve } from "../../api/resolve";
import useAxios from "../../hooks/useAxios";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

export const NoDataComponent = ({ colLength }) => {
  const [rows] = useState(Array(4).fill(""));
  const [cols] = useState(Array(colLength).fill(""));

  return (
    <>
      {rows.map((data01, index01) => (
        <tr key={"row_" + index01}>
          {cols.map((data02, index2) => (
            <td
              key={"column_" + index2}
              className="px-5 py-2 font-medium text-smW"
            >
              --
            </td>
          ))}
        </tr>
      ))}
    </>
  );
};

const ContactTable = ({
  contactsData,
  contactTableColumns,
  prevPage,
  nextPage,
  currentPage,
  setCurrentPage,
  selectedContract,
  setSelectedContract,
  sortingOrder,
  setSortingOrder,
  setSortColumn,
  pageLimit,
  setPageLimit,
  contactCount,
  setContactCount,
  showActionBtn,
  showPageLimit,
  getContacts,

  nextBtnLimit,
}) => {
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({ columns: contactTableColumns, data: contactsData });
  const { setEditFormData, setIsEditing } = useContext(FormContext);
  const [currentRow, setCurrentRow] = useState(0);
  const [contactId, setContactId] = useState(0);
  const { setActiveLink } = useContext(AppContext);
  const { user } = useContext(AuthContext);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const api = useAxios();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const deleteContact = async () => {
    const res = await resolve(
      api.post(`/user/contact/user/delete/${contactId}`, { IsDelete: true })
    );
    if (res.data) {
      // setTextSearched(false);
      getContacts({ contactId: null, isPrimary: true });
    }
    setIsDeleteModalOpen(false);
  };

  const handleEditContact = async (contactInfo) => {
    const responceData = await resolve(
      api.get(`/user/contact/group/group_list/${contactInfo.id}`)
    );
    if (responceData["data"]) {
      let groupList = responceData["data"].data;
      let tempGroupList = [];
      groupList.forEach((groupInfo, index) => {
        tempGroupList.push(groupInfo.group_id);
      });
      contactInfo.groupList = tempGroupList;
      navigate("/contact-book/edit-contact");
      setEditFormData(contactInfo);
      setIsEditing(true);
      setActiveLink("ContactBookForm");
    }
  };

  return (
    <>
      <div className="mt-2 flex flex-col table-container mb-5">
        <div>
          <div className="table_desin">
            <div
              style={{ maxHeight: "60vh" }}
              className="shadow overflow-hidden border-2 border-primary  sm:rounded-lg  overflow-y-auto"
            >
              <table {...getTableProps()} className="w-full ">
                <thead>
                  {headerGroups.map((headerGroup) => (
                    <tr
                      className={`cursor-pointer bg-[#F6C107]`}
                      {...headerGroup.getHeaderGroupProps()}
                    >
                      {headerGroup.headers.map((column) => (
                        // Add the sorting props to control sorting. For this example
                        // we can add them into the header props

                        <th
                          scope="col"
                          className="text-left px-2 py-2"
                          {...column
                            .getHeaderProps
                            // column.getSortByToggleProps()
                            ()}
                        >
                          <div className="flex items-center">
                            <span>
                              {t("constant." + column.render("Header"))}
                            </span>
                            {/* Add a sort direction indicator */}
                            {column.render("Header") === "Contact Name" && (
                              <>
                                {sortingOrder === "desc" ? (
                                  <span
                                    onClick={(e) => {
                                      setSortingOrder("asc");
                                      setSortColumn(column.id);
                                    }}
                                    style={{ cursor: "pointer", marginTop: 2 }}
                                    className="ml-1"
                                  >
                                    {/* &#11165; */}
                                    <img src={DownArrow} />
                                  </span>
                                ) : (
                                  <span
                                    onClick={(e) => {
                                      setSortingOrder("desc");
                                      setSortColumn(`-${column.id}`);
                                    }}
                                    style={{
                                      cursor: "pointer",
                                      transform: "rotate(180deg)",
                                    }}
                                    className="ml-1"
                                  >
                                    <img src={DownArrow} />
                                  </span>
                                )}
                              </>
                            )}
                          </div>
                        </th>
                      ))}
                      {showActionBtn && (
                        <th scope="col" className="text-left px-4 pt-2">
                          {t("constant.Action")}
                        </th>
                      )}
                    </tr>
                  ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                  {contactsData.length < 1 && (
                    <NoDataComponent colLength={contactTableColumns.length} />
                  )}
                  {contactsData.length > 0 &&
                    rows.map((row, i) => {
                      // new
                      prepareRow(row);
                      return (
                        <tr
                          {...row.getRowProps()}
                          onClick={() => {
                            setCurrentRow(i);
                          }}
                        >
                          {row.cells.map((cell) => {
                            return (
                              <td
                                {...cell.getCellProps()}
                                className={`px-2 py-2 font-medium text-sm contracts-col-data ${
                                  cell.value === "In-Active" && "text-red-500"
                                }`}
                              >
                                <span>{cell.render("Cell")}</span>
                              </td>
                            );
                          })}
                          {showActionBtn && (
                            <td className="py-2 flex">
                              <button className="bg-secondary px-1 w-14 py-1 rounded-md flex justify-between text-white wh_spab">
                                <img
                                  src={EditIcon}
                                  width={20}
                                  data-tip={t('allModule.Edit Contact')}
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    handleEditContact(row.original);
                                  }}
                                />
                                <img
                                  src={DeleteIcon}
                                  className="ml-0.5"
                                  width={20}
                                  data-tip={t('allModule.Delete Contact')}
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    setContactId(row.original.id);
                                    setIsDeleteModalOpen(true);
                                  }}
                                />
                                <ReactTooltip
                                  backgroundColor="#400835"
                                  textColor="#ffffff"
                                />
                              </button>
                            </td>
                          )}
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        {contactsData.length > 0 && (
          <div
            className={`mt-3 flex ${
              showPageLimit ? "justify-between" : "justify-end"
            }`}
          >
            {showPageLimit && (
              <div>
                {contactCount > 5 && (
                  <>
                    <span>{t("constant.Show")}</span>
                    <select
                      className="border-2 border-primary outline-none mx-1 rounded-md cursor-pointer btn-sm"
                      onChange={(e) => setPageLimit(e.target.value)}
                      value={pageLimit}
                    >
                      <option selected>5</option>
                      {contactCount >= 10 && <option>10</option>}
                      {contactCount >= 15 && <option>15</option>}
                      {contactCount >= 25 && <option>25</option>}
                    </select>
                    <span>{t("common.Results")}</span>
                  </>
                )}
              </div>
            )}
            <div className="flex text-primary  border-t-2 border-b-2 border-primary rounded-lg">
              <button
                className={`btn-sm border-r-2 border-l-2 border-primary font-semibold rounded-tl-lg rounded-bl-lg ${
                  currentPage <= 1 && "bg-inputBackground"
                }`}
                disabled={currentPage <= 1}
                onClick={(e) => {
                  if (currentPage > 1) {
                    setCurrentPage(currentPage - 1);
                  }
                }}
              >
                {t("common.Prev")}
              </button>
              <span className="btn-sm font-semibold">{currentPage}</span>
              <button
                className={`btn-sm border-l-2 border-r-2 border-primary font-semibold rounded-tr-lg rounded-br-lg ${
                  currentPage >= nextBtnLimit && "bg-inputBackground"
                }`}
                disabled={currentPage >= nextBtnLimit}
                onClick={(e) => {
                  if (currentPage < nextBtnLimit) {
                    setCurrentPage(currentPage + 1);
                  }
                }}
              >
                {t("common.Next")}
              </button>
            </div>
          </div>
        )}
      </div>

      <DeleteModal
        title="Delete Contact"
        bodyText="This action cannot be undone. Are you sure you want to continue?"
        isDeleteModalOpen={isDeleteModalOpen}
        setIsDeleteModalOpen={setIsDeleteModalOpen}
        deleteContact={deleteContact}
      />
    </>
  );
};

export default ContactTable;
