import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import "./chatai.css";
import useAxios from "../../hooks/useAxios";
import LoadingScreen from "../loader/LoadingScreen";
import { toast } from "react-toastify";
import ReactTooltip from "react-tooltip";
import { useTranslation } from "react-i18next";

const DocChatAI = React.forwardRef((props, ref) => {
  const { documentData, textareaRef } = props;
  const [searchText, setSearchText] = useState("");
  const [conversationHistory, setConversationHistory] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const { t } = useTranslation();
  const baseURL = process.env.REACT_APP_BACKEND_BASE_URL;

  const chatContainerRef = useRef(null);
  const [shouldScrollToBottom, setShouldScrollToBottom] = useState(false);

  useEffect(() => {
    if (shouldScrollToBottom && chatContainerRef.current) {
      chatContainerRef.current.scrollTop =
        chatContainerRef.current.scrollHeight;
      setShouldScrollToBottom(false);
    }
  }, [conversationHistory, shouldScrollToBottom]);

  function htmlToPlainText(html) {
    const tempDiv = document.createElement("div");
    tempDiv.innerHTML = html;
    return tempDiv.textContent || tempDiv.innerText || "";
  }

  const handleSearch = async () => {
    try {
      setLoading(true);
      const payload = {
        searchText,
        aiContent: htmlToPlainText(documentData),
        conversationHistory,
      };

      const response = await axios.post(
        `${baseURL}/contract/generatedocchat/`,
        payload
      );

      if (response.status === 200) {
        const recommendations = response.data;
        const recommendationsArray = Object.values(recommendations);

        setConversationHistory((prevHistory) => {
          const updatedHistory = [...prevHistory];
          updatedHistory[updatedHistory.length - 1] = {
            ...updatedHistory[updatedHistory.length - 1],
            assistant: recommendationsArray,
          };
          return updatedHistory;
        });
      } else {
        throw new Error("Failed to fetch recommendations");
      }
    } catch (error) {
      console.error("Error fetching recommendations:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleSearchChange = (event) => {
    setSearchText(event.target.value);
  };

  const handleEnterPress = (e) => {
    if (e.key === "Enter") {
      addMessageToHistory();
      handleSearch();
    }
  };

  const addMessageToHistory = () => {
    const userMessage = { user: searchText, assistant: [] };
    setConversationHistory((prevHistory) => [...prevHistory, userMessage]);
    setSearchText("");
    setShouldScrollToBottom(true);
  };

  const handleClearChat = () => {
    setConversationHistory([]); // Clear the chat history
  };

  const handleCopyClick = (textToCopy) => {
    try {
      const textarea = document.createElement("textarea");
      textarea.value = textToCopy;
      document.body.appendChild(textarea);
      textarea.select();
      document.execCommand("copy");
      document.body.removeChild(textarea);
      toast.success("Copied to clipboard");
    } catch (error) {
      toast.error("Failed to copy to clipboard");
    }
  };

  return (
    <div className="chat-container">
      <div className="chat-header">
        <>
          {conversationHistory.length > 0 && !isLoading && (
            <button
              onClick={handleClearChat}
              className="clear-button"
              data-tip="clear chat history"
            >
              {t("Clear")}
            </button>
          )}
          <ReactTooltip backgroundColor="#400835" textColor="#ffffff" />
        </>
      </div>
      <div className="chat-history" ref={chatContainerRef}>
        {conversationHistory.length === 0 ? (
          <div className="no-history-message">
            <h2>{t("No conversation history yet.")}</h2>
          </div>
        ) : (
          conversationHistory.map((entry, index) => (
            <div key={index} className="chat-message">
              <div className="user-message">
                <div className="message-content">
                  <strong>You:</strong> {entry.user}
                </div>
              </div>
              {entry.assistant.map((response, idx) => (
                <div key={idx} className="assistant-message">
                  <div className="message-content">
                    <strong>AI:</strong> {response}
                    <button
                      onClick={() => handleCopyClick(response)}
                      className="copy-button"
                    >
                      Copy
                    </button>
                  </div>
                </div>
              ))}
            </div>
          ))
        )}
        {isLoading && <LoadingScreen />}
      </div>

      <div className="chat-input">
        <input
          type="text"
          placeholder={t("Ask a question ...")}
          value={searchText}
          onChange={handleSearchChange}
          onKeyPress={handleEnterPress}
          className="chat-text-input"
        />
        <button
          onClick={() => {
            addMessageToHistory();
            handleSearch();
          }}
          className="chat-send-button"
        >
          {t("Send")}
        </button>
      </div>
    </div>
  );
});

export default DocChatAI;
