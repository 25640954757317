import React, { useEffect, useState } from "react";
import Select from "react-select";
import Modal from "react-modal";
import "./modal.css";
import useAxios from "../../hooks/useAxios";
import { resolve } from "../../api/resolve";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

const customStyles = {
  content: {
    top: "40%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    minWidth: 400,
    maxWidth: 400,
    maxHeight: "700px",
    overflow: "hidden",
    borderRadius: "11px",
  },
  overlay: {
    backgroundColor: "rgba(64, 8, 53, 0.63)",
  },
};

Modal.setAppElement("#root");

const ShareRepoLegacy = ({
  modalshareIsOpen,
  setmodalshareIsOpen,
  isUpdating,
  setIsUpdating,
  parentid,
  repoName,
  alreadyselecteduser,
  modalmultishareIsOpen,
  selectedfolders,
  selectedfiles,
  getSharedDocList,
  shareNewRepo,
  updateaftershare,
}) => {
  const api = useAxios();
  const { t } = useTranslation();
  const [errMsg, setErrMsg] = useState("");
  const [reposval, setReposval] = useState([]);
  const [validationError, setValidationError] = useState("");
  const [selectedRepos, setSelectedRepos] = useState([
    { repo_id: "", children: [] },
  ]);
  const [shareData, setShareData] = useState({
    kindOfLegalAssistance: "",
    selectedProvider: "",
    description: "",
    contactDetails: "",
    email: "",
    phoneNumber: "",
    expectedDate: "",
    name: "",
  });
  const [siteuserlist, setsiteuserlist] = useState([]);
  const [selecteduser, setselecteduser] = useState("");
  const [selectedUsers, setSelectedUsers] = useState([]);

  const handleUserChange = (selectedOptions) => {
    setSelectedUsers(selectedOptions);
  };

  const options = siteuserlist.map((item) => ({
    value: item.id,
    label: item.name,
    email: item.email,
  }));

  const getRepos = async () => {
    const res = await resolve(
      api.get(`user/FetchSiteUser/`).then((res) => res.data)
    );
    if (res.data) {
      setsiteuserlist(res.data.data);
      // const filterData = res.data.map((result) => ({
      //   repo_name: result.repo_name,
      //   id: result.id,
      // }));
      // setsiteuserlist(res.data);
    }
  };

  const handleChangeforuser = async (e) => {
    const { name, value } = e.target;
    const selectedName = e.target.value;
    setselecteduser(selectedName);
  };

  const handlesharesubmit = async (e) => {
    const formData = new FormData();
    let parentRepoId = "";
    for (let i = selectedRepos.length - 1; i >= 0; i--) {
      if (selectedRepos[i].repo_id !== "") {
        parentRepoId = selectedRepos[i].repo_id;
        break;
      }
    }

    let arr = selectedUsers.map((user) => user.value);
    if (alreadyselecteduser) {
      formData.append("shared_with", alreadyselecteduser);
    } else {
      formData.append("shared_with", arr);
    }
    formData.append("repo_ids", selectedfolders);
    formData.append("expiry_date", shareData.expectedDate);
    try {
      const res2 = await resolve(
        api
          .post(`legacy_contract/ShareRepoCreate/`, formData, {
            headers: {
              "content-type": "application/json",
            },
          })
          .then((res) => res.data)
      );
      if (res2.data.message) {
        toast.success(
          t("fileBrowser.Selected Repository/ files share successfully")
        );
        setmodalshareIsOpen(false);
        setSelectedUsers([]);
        getSharedDocList();
        updateaftershare();
      } else {
        toast.error(
          t("fileBrowser.Failed to share repository. Please try again")
        );
      }
      getSharedDocList();
      setSelectedUsers([]);
      setmodalshareIsOpen(false);
      setIsUpdating(false);
      updateaftershare();
    } catch (error) {}
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setShareData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const formatOptionLabel = ({ label, email }) => (
    <div title={email}>{label}</div>
  );

  useEffect(() => {
    if (shareNewRepo) {
      getRepos();
    }
  }, [shareNewRepo]);

  function getCurrentDate() {
    const today = new Date();
    const year = today.getFullYear();
    let month = today.getMonth() + 1;
    let day = today.getDate();

    // Add leading zero if month or day is less than 10
    if (month < 10) {
      month = `0${month}`;
    }
    if (day < 10) {
      day = `0${day}`;
    }

    return `${year}-${month}-${day}`;
  }

  return (
    <div className="">
      <Modal
        isOpen={modalshareIsOpen}
        onRequestClose={() => setmodalshareIsOpen(false)}
        style={customStyles}
        contentLabel="Example Modal"
        onAfterClose={() => {
          setErrMsg("");
          setIsUpdating(false);
        }}
      >
        {errMsg ? (
          <>
            <h1 className="text-primary font-semibold text-lg">{errMsg}</h1>
            <div className="flex justify-center mt-3">
              <button
                type="button"
                className="bg-primary font-semibold text-white p-1 px-5 rounded-lg w-2/3"
                onClick={() => setmodalshareIsOpen(false)}
              >
                {t("fileBrowser.Share")}
              </button>
            </div>
          </>
        ) : (
          <div className="text-center modal-container">
            <form
              onSubmit={(e) => {
                e.preventDefault();
                handlesharesubmit();
                // changeparentRepo(repoName);
              }}
            >
              <div className="popup-header pb-2 flex justify-between items-center">
                <h1 className="text-primary font-bold text-lg mx-auto">
                  {isUpdating
                    ? t("fileBrowser.Update shared Repository")
                    : t("fileBrowser.Share Repository")}
                </h1>
                <button
                  className="bg-[#606060] font-semibold text-white p-1 pl-2 pr-2 rounded-lg"
                  onClick={() => setmodalshareIsOpen(false)}
                >
                  X
                </button>
              </div>

              {!isUpdating && (
                <div className="flex flex-col mb-3">
                  <div className="flex">
                    <label className="mb-3">
                      {t("fileBrowser.Select Recepients")}:
                    </label>
                  </div>
                  <div className="flex w-full">
                    <Select
                      id="company_id"
                      name="selectRecepients"
                      options={options}
                      isMulti
                      closeMenuOnSelect={false}
                      onChange={handleUserChange}
                      value={selectedUsers}
                      placeholder={t("fileBrowser.Select Recepients")}
                      formatOptionLabel={formatOptionLabel}
                      styles={{
                        control: (provided) => ({
                          ...provided,
                          width: 380,
                          maxHeight: 100,
                          overflowY: "auto",
                          textAlign: "left",
                          border: "1px solid #ccc",
                        }),
                        menu: (provided) => ({
                          ...provided,
                          textAlign: "left",
                        }),
                      }}
                    />
                  </div>
                </div>
              )}
              <br />
              <div className="flex flex-col mb-3">
                <div className="flex">
                  <label className="mb-3">
                    {t("fileBrowser.Expiry Date")}:
                  </label>
                </div>
                <div className="flex w-full">
                  <input
                    type="date"
                    id="expectedDate"
                    name="expectedDate"
                    style={{
                      width: "100%",
                      borderRadius: "5px",
                      border: "1px solid #ccc",
                      padding: "5px",
                    }}
                    value={shareData.expectedDate}
                    onChange={handleChange}
                    min={getCurrentDate()}
                  />
                </div>
              </div>

              <p className="text-red-500 mb-2">{validationError}</p>
              <div className="flex mb-3 justify-center pt-5">
                <button
                  type="submit"
                  className={`${
                    isUpdating ? "bg-secondary" : "bg-primary"
                  } font-semibold text-white p-1 px-5 rounded-lg w-2/3`}
                >
                  {isUpdating ? t("modal.Update") : t("fileBrowser.Share")}
                </button>
              </div>
            </form>
          </div>
        )}
      </Modal>
    </div>
  );
};

export default ShareRepoLegacy;
