import React, { useEffect, useContext, useState } from 'react';
import Switch from 'react-switch';
import Select from 'react-select';
import { customStyles } from '../../constants/styles';
import FormContext from '../../context/FormContext';
import AppContext from '../../context/AppContext';
import AppModal from '../modallegacy/Modallegacy';
import CustomPhoneInput from './PhoneInputlegacy';
import { useTranslation } from 'react-i18next';

const AdminFormlegacy = () => {
  const { t } = useTranslation();
  const {
    setCurrentForm,
    saveOrEditAdminlegacy,
    modalData,
    setIsOpen,
    modalIsOpen,
    isEditing,
    editFormData,
    setEditFormData,
    setIsEditing,
    adminFormErrors,
    setAdminFormErrors,
    shouldRedirect,
  } = useContext(FormContext);
  const { setActiveLink, companiesListlegacy } = useContext(AppContext);
  const [companyID, setCompanyID] = useState(null);
  const [adminObj, setAdminObj] = useState({
    first_name: '',
    company_id: companyID,
    email: '',
    confirm_email: '',
    phone: '',
    user_type: 'admin',
    company_name: '',
    is_reviewer: true,
  });
  const [isActiveUser, setIsActiveUser] = useState(
    editFormData?.is_active_user
  );
  useEffect(() => {
    setAdminFormErrors({
      ...adminFormErrors,
      name: '',
      company_id: '',
      email: '',
      confirm_email: '',
      phone: '',
    });
    setCurrentForm('AdminFormlegacy');
    return () => setCurrentForm('');
  }, []);
  useEffect(() => {
    return () => {
      if (isEditing) {
        setEditFormData('');
        setIsEditing(false);
      }
    };
  }, [isEditing]);
  useEffect(() => {
    if (isEditing) {
      setEditFormData({
        ...editFormData,
        is_active_user: isActiveUser,
      });
    }
  }, [isActiveUser]);
  return (
    <div className='text-primary text-base font-normal font-sans'>
      <h1 className='text-4xl font-bold'>
        {!isEditing ? t('form.Add Admin') : t('form.Edit Admin')}
      </h1>
      <div className=' w-2/5 mt-10'>
        <form>
          <div className='flex flex-col mb-3'>
            <label>{t('form.Name')}</label>
            <input
              placeholder={t('form.Insert user full name')}
              className='border-2 border-primary rounded-lg outline-none p-1 pl-2'
              value={!isEditing ? adminObj.first_name : editFormData.first_name}
              onChange={(e) => {
                !isEditing
                  ? setAdminObj({ ...adminObj, first_name: e.target.value })
                  : setEditFormData({
                      ...editFormData,
                      first_name: e.target.value,
                    });
              }}
            />
            <p className='text-sm text-red-500 font-normal'>
              {adminFormErrors.name}
            </p>
          </div>
          {!isEditing && (
            <div className='mb-3'>
              <label>{t('form.Company')}</label>
              <Select
                options={companiesListlegacy}
                placeholder={t('form.Select Company name')}
                styles={customStyles}
                isSearchable={true}
                onChange={(company) => {
                  setAdminObj({
                    ...adminObj,
                    company_id: company.id,
                    company_name: company.company_name,
                  });
                  isEditing &&
                    setEditFormData({
                      ...editFormData,
                      company_id: company.id,
                      company_name: company.company_name,
                    });
                }}
                className='w-full text-primary rounded-lg'
                defaultInputValue={isEditing ? editFormData.company_name : ''}
              />
              <p className='text-sm text-red-500 font-normal'>
                {adminFormErrors.company_id}
              </p>
            </div>
          )}
          <div className='flex flex-col mb-3'>
            <label>{t('form.Email')}</label>
            <input
              placeholder={t('form.Insert an unique user email')}
              type='email'
              className='border-2 border-primary rounded-lg outline-none p-1 pl-2'
              value={!isEditing ? adminObj.email : editFormData.email}
              onChange={(e) => {
                !isEditing
                  ? setAdminObj({ ...adminObj, email: e.target.value })
                  : setEditFormData({
                      ...editFormData,
                      email: e.target.value,
                    });
              }}
            />
            <p className='text-sm text-red-500 font-normal'>
              {adminFormErrors.email}
            </p>
          </div>
          <div className='flex flex-col mb-3'>
            <label>{t('form.Confirm Email')}</label>
            <input
              placeholder={t('form.Confirm user email')}
              type='email'
              className='border-2 border-primary rounded-lg outline-none p-1 pl-2'
              value={
                !isEditing ? adminObj.confirm_email : editFormData.confirm_email
              }
              onChange={(e) => {
                !isEditing
                  ? setAdminObj({ ...adminObj, confirm_email: e.target.value })
                  : setEditFormData({
                      ...editFormData,
                      confirm_email: e.target.value,
                    });
              }}
              onPasteCapture={(e) => e.preventDefault()}
            />
            <p className='text-sm text-red-500 font-normal'>
              {adminFormErrors.confirm_email}
            </p>
          </div>
          <div className='flex flex-col mb-3'>
            <label>{t('form.Phone')}</label>
            {/* <input
              placeholder='Phone Number'
              maxLength={10}
              className='border-2 border-primary rounded-lg outline-none p-1 pl-2'
              value={!isEditing ? adminObj.phone : editFormData.phone}
              onChange={(e) => {
                !isEditing
                  ? setAdminObj({ ...adminObj, phone: e.target.value })
                  : setEditFormData({
                      ...editFormData,
                      phone: e.target.value,
                    });
              }}
            /> */}
            <CustomPhoneInput
              value={!isEditing ? adminObj.phone : editFormData.phone}
              onChange={(phone) => {
                !isEditing
                  ? setAdminObj({ ...adminObj, phone })
                  : setEditFormData({
                      ...editFormData,
                      phone,
                    });
              }}
            />
            <p className='text-sm text-red-500 font-normal'>
              {adminFormErrors.phone}
            </p>
          </div>
          {isEditing && (
            <div className='flex justify-between items-center mb-3'>
              <span>
                {t('form.Status')} :{' '}
                <span
                  className={`${
                    isActiveUser ? 'text-primary' : 'text-red-500'
                  } font-medium`}
                >
                  {isActiveUser ? 'Active' : 'In-Active'}
                </span>
              </span>
              <Switch
                offColor='#E0720C'
                onColor='#400835'
                name='Active'
                checked={isActiveUser}
                height={20}
                boxShadow='none'
                onChange={() => setIsActiveUser(!isActiveUser)}
              />
            </div>
          )}

          {!isEditing ? (
            <button
              className='bg-primary font-bold text-white w-full p-1 rounded-lg'
              onClick={(e) => {
                e.preventDefault();
                saveOrEditAdminlegacy(adminObj);
              }}
            >
              {t('form.Save Admin')}
            </button>
          ) : (
            <button
              className='bg-secondary font-bold text-white w-full p-1 rounded-lg'
              onClick={(e) => {
                e.preventDefault();
                saveOrEditAdminlegacy(editFormData);
              }}
            >
              {t('form.Update Admin')}
            </button>
          )}
        </form>
      </div>
      <AppModal
        title={modalData.title}
        bodyText={modalData.bodyText}
        setIsOpen={setIsOpen}
        modalIsOpen={modalIsOpen}
        setActiveLink={setActiveLink}
        activeLink={shouldRedirect && 'Adminslegacy'}
      />
    </div>
  );
};

export default AdminFormlegacy;
