import { useNavigate } from 'react-router-dom';
import appLogo from '../../assets/app-logo.svg';
import AppContext from '../../context/AppContext';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
const ThankYouForSigning = () => {
    const { setActiveLink } = useContext(AppContext);
    const navigate = useNavigate()
    const { t } = useTranslation();
    return (

        <div className="thank-you-for-signing" >
            <img src={appLogo} width={200} alt="Thank you" />
            <h1>{t('form.Thank you')}</h1>
            <p>{t('form.Your action has been successfully completed.')}</p>
            <button onClick={(e) => {
                e.stopPropagation();
                setActiveLink('Home')
                navigate('/dashboard');
            }}
                className="btn btn_theme btn-sm mt-5"
            >{t('form.Back to Quoqo Fusion')}</button>
        </div>

    )
}

export default ThankYouForSigning;