import React, { useEffect, useState, useContext } from "react";
import Select from "react-select";
import dayjs from "dayjs";
import AppContext from "../../context/AppContext";
import LoadingScreen from "../loader/LoadingScreen";
import Search from "../../assets/icons/Search.svg";
import CloseIcon from "../../assets/icons/Close.svg";
import { dropDownStyles } from "../../constants/styles";
import ContactTable from "./ContactTable";
import GroupTable from "./GroupTable";
import {
  contactTableColumns,
  groupTableColumns,
} from "../../constants/constants";
import useAxios from "../../hooks/useAxios";
import { resolve } from "../../api/resolve";
import AddButton from "../../shared/AddButton";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import PermissionContext from "../../context/PermissionContext";

const ContactBook = () => {
  const api = useAxios();
  const [loading, setLoading] = useState(true);
  const [errMsg, setErrMsg] = useState("");
  const [errMsgGroup, setErrMsgGroup] = useState("");
  const [contactSearchText, setContactSearchText] = useState("");
  const [groupSearchText, setGroupSearchText] = useState("");

  const [nextPage, setIsNextPage] = useState(null);
  const [prevPage, setIsPrevPage] = useState(null);
  const [pageLimit, setPageLimit] = useState(15);
  const [groupPageLimit, setGroupPageLimit] = useState(15);

  const [contactCount, setContactCount] = useState(0);
  const [groupCount, setGroupCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [groupCurrentPage, setGroupCurrentPage] = useState(1);
  const [sortingOrder, setSortingOrder] = useState("asc");
  const [sortColumn, setSortColumn] = useState("-created_at");
  const [contacts, setContacts] = useState([]);
  const [groupList, setGroupList] = useState([]);
  const [pageLoading, setPageLoading] = useState(true);
  const [showContactClearIcon, setContactShowClearIcon] = useState(false);
  const [showGroupClearIcon, setGroupShowClearIcon] = useState(false);

  const [nextBtnLimit, setNextBtnLimit] = useState(0);
  const [groupNextBtnLimit, setGroupNextBtnLimit] = useState(0);
  const [activeTable, setActiveTable] = useState("contact");

  const navigate = useNavigate();
  const { t } = useTranslation();
  const { permissionList } = useContext(PermissionContext);

  const getContacts = async (clearText) => {
    const res = await resolve(
      api
        .get(
          `user/contact/list/?search=${
            clearText ? "" : contactSearchText
          }&page=${currentPage}&limit=${pageLimit}&order=${sortColumn}&sort=${sortingOrder}`
        )
        .then((res) => res.data)
    );
    if (res.data) {
      const { data } = res;
      const { results, count } = data;
      const filterData = [];
      if (results.length > 0) {
        setContactCount(count);
        results.forEach((result) => {
          const contactObj = {
            id: result.id,
            name: result.name,
            designation: result.designation,
            delegatee: result.delegatee,
            phone: result.phone,
            email: result.email,
            confirmEmail: result.email,
            groupList: result.groupList,
            companyId: result.company_id_id + "",
            titleId: result.title_id_id + "",
          };
          filterData.push(contactObj);
        });
      } else {
        if (currentPage === 1) setErrMsg(t("allModule.No contact added yet"));
        else setErrMsg(t("allModule.No more contacts to view"));
      }
      setContacts([...filterData]);
      setNextBtnLimit(Math.ceil(data.count / pageLimit));
    } else {
      setNextBtnLimit(0);
    }
    setPageLoading(false);
  };

  const getGroupList = async (clearText) => {
    const res = await resolve(
      api
        .get(
          `user/contact/group/list/?search=${
            clearText ? "" : groupSearchText
          }&page=${groupCurrentPage}&limit=${groupPageLimit}&ordering=${sortColumn}&sortingOrder=${sortingOrder}`
        )
        .then((res) => res.data)
    );
    if (res.data) {
      const { data } = res;
      const { results, count } = data;

      const filterData = [];
      if (results.length > 0) {
        setGroupCount(count);
        results.forEach((result) => {
          const groupObj = {
            id: result.id,
            group_name: result.group_name,
          };
          filterData.push(groupObj);
        });
      } else {
        if (currentPage === 1)
          setErrMsgGroup(t("allModule.No group added yet"));
        else setErrMsgGroup(t("allModule.No more groups to view"));
      }
      setGroupList([...filterData]);
      setGroupNextBtnLimit(Math.ceil(data.count / groupPageLimit));
    } else {
      setGroupNextBtnLimit(0);
    }
    setPageLoading(false);
  };

  const handleActiveList = (tableName) => {
    setActiveTable(tableName);
  };

  useEffect(() => {
    setLoading(false);
    return () => {
      setContacts([]);
      setGroupList([]);
    };
  }, []);

  useEffect(() => {
    if (activeTable == "group") {
      getGroupList();
    } else {
      getContacts();
    }
  }, [sortColumn, pageLimit, currentPage]);

  useEffect(() => {
    getGroupList();
  }, [groupCurrentPage, groupPageLimit]);

  if (loading) {
    return <LoadingScreen />;
  }
  return (
    <>
      <div className="text-primary">
        <div className="main_head">
          <h1 className="text-4xl font-bold">{t("allModule.Contacts")}</h1>
        </div>
        {permissionList["contact_book_on"] == false ? (
          <div className="flex justify-center items-center h-96 flex-column ">
            <h3 className="text-2xl font-bold text_c">
              {t("allModule.You dont have permission to access this page")}
            </h3>
          </div>
        ) : (
          <>
            <div className="nav_tab mb-4">
              <ul className="ul_set">
                <li
                  onClick={(e) => {
                    e.stopPropagation();
                    navigate("/contact-book/contact");
                    handleActiveList("contact");
                  }}
                  className={activeTable === "contact" ? "active" : ""}
                >
                  <a href="#">{t("allModule.Contacts")} </a>
                </li>
                <li
                  onClick={(e) => {
                    e.stopPropagation();
                    navigate("/contact-book/group");
                    handleActiveList("group");
                  }}
                  className={activeTable === "group" ? "active" : ""}
                >
                  <a href="#">{t("allModule.Group")} </a>
                </li>
              </ul>
            </div>
            <div className="tab_main">
              <div
                className={`tab_us ${activeTable === "contact" ? "show" : ""}`}
              >
                <h1 className="text-xl font-bold">
                  {t("allModule.Contacts")}{" "}
                </h1>
                <div className="row justify-between">
                  <div className="col-md-5 flex_contact">
                    <form>
                      <div className="flex mt-3  w-full">
                        <div className="flex justify-between items-center border-2 border-primary rounded-md rounded-tl-lg rounded-bl-lg bg-inputBackground px-2 mr-2 w-full ">
                          <input
                            placeholder={t("allModule.Search for contacts")}
                            className="px-1 py-1 focus:outline-none bg-transparent w-11/12"
                            value={contactSearchText}
                            onChange={(e) => {
                              setContactSearchText(e.target.value);
                              setContactShowClearIcon(true);
                              if (!e.target.value.trim()) {
                                setContactShowClearIcon(false);
                              }
                            }}
                          />
                          {showContactClearIcon && (
                            <img
                              src={CloseIcon}
                              width={14}
                              className="cursor-pointer"
                              onClick={() => {
                                setContactSearchText("");
                                getContacts(true);
                                setContactShowClearIcon(false);
                              }}
                            />
                          )}
                        </div>
                        <button
                          type="submit"
                          className="flex btn btn_theme2 w_125 items-center justify-between"
                          onClick={(e) => {
                            e.preventDefault();
                            setCurrentPage(1);
                            getContacts();
                          }}
                        >
                          <img src={Search} style={{ width: "1rem" }} />
                          <span className="font-semibold ml-1">
                            {t("allModule.Search")}
                          </span>
                        </button>
                      </div>
                    </form>
                  </div>
                  <div className="col-md-5">
                    <div className="flex justify-end mt-3">
                      <AddButton
                        text={t("allModule.Add New Contact")}
                        currentForm="ContactBookForm"
                      />
                    </div>
                  </div>
                </div>

                <div className="mt-3 rounded-lg">
                  {contacts.length < 1 && (
                    <p className="text-red-500 text-sm font-medium">{errMsg}</p>
                  )}
                </div>
                <ContactTable
                  contactTableColumns={contactTableColumns}
                  contactsData={contacts}
                  nextPage={nextPage}
                  prevPage={prevPage}
                  currentPage={currentPage}
                  setCurrentPage={setCurrentPage}
                  sortingOrder={sortingOrder}
                  setSortingOrder={setSortingOrder}
                  setSortColumn={setSortColumn}
                  pageLimit={pageLimit}
                  setPageLimit={setPageLimit}
                  contactCount={contactCount}
                  setContactCount={setContactCount}
                  showActionBtn
                  showPageLimit={true}
                  getContacts={getContacts}
                  nextBtnLimit={nextBtnLimit}
                />
              </div>
              <div
                className={`tab_us ${activeTable === "group" ? "show" : ""}`}
              >
                <div className="text-primary">
                  <h1 className="text-xl font-bold">{t("allModule.Group")}</h1>
                  <div className="row justify-between">
                    <div className="col-md-5 flex_contact">
                      <form>
                        <div className="flex w-full mt-3">
                          <div className="flex justify-between items-center border-2 border-primary rounded-md rounded-tl-lg rounded-bl-lg bg-inputBackground px-2 mr-2 w-full ">
                            <input
                              placeholder={t("allModule.Search for Groups")}
                              className="px-1 py-1 focus:outline-none bg-transparent w-11/12"
                              value={groupSearchText}
                              onChange={(e) => {
                                setGroupSearchText(e.target.value);
                                setGroupShowClearIcon(true);
                                if (!e.target.value.trim()) {
                                  setGroupShowClearIcon(false);
                                }
                              }}
                            />
                            {showGroupClearIcon && (
                              <img
                                src={CloseIcon}
                                width={14}
                                className="cursor-pointer"
                                onClick={() => {
                                  setGroupSearchText("");
                                  getGroupList(true);
                                  setGroupShowClearIcon(false);
                                }}
                              />
                            )}
                          </div>
                          <button
                            type="submit"
                            className="flex btn btn_theme2 items-center justify-between w_125 "
                            onClick={(e) => {
                              e.preventDefault();
                              getGroupList();
                            }}
                          >
                            <img src={Search} style={{ width: "1rem" }} />
                            <span className="font-semibold ml-1">
                              {t("allModule.Search")}
                            </span>
                          </button>
                        </div>
                      </form>
                    </div>
                    <div className="col-md-5">
                      <div className="flex justify-end mt-3">
                        <AddButton
                          text={t("allModule.Add New Group")}
                          currentForm="GroupForm"
                        />
                      </div>
                    </div>
                  </div>

                  <div className="mt-3 rounded-lg">
                    {contacts.length < 1 && (
                      <p className="text-red-500 text-sm font-medium">
                        {errMsgGroup}
                      </p>
                    )}
                  </div>

                  <GroupTable
                    contactTableColumns={groupTableColumns}
                    contractsData={groupList}
                    nextPage={nextPage}
                    prevPage={prevPage}
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                    sortingOrder={sortingOrder}
                    setSortingOrder={setSortingOrder}
                    setSortColumn={setSortColumn}
                    pageLimit={pageLimit}
                    setPageLimit={setPageLimit}
                    contactCount={contactCount}
                    setContactCount={setContactCount}
                    showActionBtn
                    showPageLimit={true}
                    setPageLoading={setPageLoading}
                    getGroupList={getGroupList}
                    groupNextBtnLimit={groupNextBtnLimit}
                    groupCurrentPage={groupCurrentPage}
                    setGroupCurrentPage={setGroupCurrentPage}
                    groupCount={groupCount}
                    setGroupPageLimit={setGroupPageLimit}
                  />
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default ContactBook;
