import React, { useState } from "react";
import { useTable } from "react-table";
import "../contracts/contracts.css";
import DownArrow from "../../assets/icons/down-arrow.svg";
import ReactTooltip from "react-tooltip";

import { resolve } from "../../api/resolve";
import useAxios from "../../hooks/useAxios";
import { autoCloseTimer, delay } from "../../constants/constants";
import { toast } from "react-toastify";
import eye_on from "../../assets/icons/eye_on.png";
import { useTranslation } from "react-i18next";
import EyeOnIconWhite from "../../assets/icons/eye_on_white.png";
import DownoadIcon from "../../assets/icons/downloadd1.png";

export const NoDataComponent = ({ colLength }) => {
  const [rows] = useState(Array(4).fill(""));
  const [cols] = useState(Array(colLength).fill(""));
  return (
    <>
      {rows.map((data, index) => (
        <tr key={`group_ndf_row_${index}`}>
          {cols.map((data, index01) => (
            <td
              key={`group_ndf_td_${index01}`}
              className="px-5 py-2 font-medium text-smW"
            >
              --
            </td>
          ))}
        </tr>
      ))}
    </>
  );
};

const CompletedDocTable = ({
  tableData,
  tableColumns,
  currentPage,
  setCurrentPage,
  nextBtnLimit,
  itemCount,
  pageLimit,
  setPageLimit,
  getListData,

  sortingOrder,
  setSortingOrder,
  setSortColumn,
  showActionBtn,
  showPageLimit,
  setLoading,
  setPreviewDocument,
  setPreviewDocumentData,
}) => {
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({ columns: tableColumns, data: tableData });

  const [docId, setDocId] = useState(0);

  const api = useAxios();
  const { t } = useTranslation();
  const baseUrl = process.env.REACT_APP_BACKEND_BASE_URL;

  const downlaodDocument = async (docId) => {
    let authTokens = localStorage.getItem("authTokens")
      ? JSON.parse(localStorage.getItem("authTokens"))
      : null;
    const headers = {
      Authorization: `Bearer ${authTokens?.access}`,
    };
    const apiUrl = `${baseUrl}/contract/download/document/${docId}`;
    const res = await api.get(apiUrl, {
      headers,
      "Content-Type": "application/json",
    });
    if (res.data) {
      authTokens = localStorage.getItem("authTokens")
        ? JSON.parse(localStorage.getItem("authTokens"))
        : null;
      const apiUrl = `${baseUrl}/contract/download/document/${docId}?token=${authTokens?.access}`;
      window.open(apiUrl);
    }

    if (res.error) {
      if (res["error"]?.response?.data?.message) {
        toast.error(res["error"].response["data"].message, {
          autoClose: autoCloseTimer,
          hideProgressBar: true,
        });
      } else {
        toast.error(t("common.Something went wrong"), {
          autoClose: autoCloseTimer,
          hideProgressBar: true,
        });
      }
    }
  };

  const handleSendToLegacy = async (docInfo) => {
    setLoading(true);
    const res = await resolve(
      api.get(`/contract/send/tolegacy/${docInfo["id"]}`)
    );
    console.log(res.data);
    if (res.data) {
      if (res.data["data"].status["success"] === true) {
        getListData();
        toast.success("Document sent to Document storage", {
          autoClose: autoCloseTimer,
          hideProgressBar: true,
        });
      } else {
        toast.error(res.data["data"].status["msg"], {
          autoClose: autoCloseTimer,
          hideProgressBar: true,
        });
      }
    }

    if (res.error) {
      toast.error("Something went wrong.", {
        autoClose: autoCloseTimer,
        hideProgressBar: true,
      });
    }
    setLoading(false);
  };

  return (
    <>
      <div className="mt-2 flex flex-col table-container mb-5">
        <div>
          <div className="table_desin">
            <div
              style={{ maxHeight: "60vh" }}
              className="shadow overflow-hidden border-2 border-primary  sm:rounded-lg overflow-y-auto"
            >
              <table {...getTableProps()} className="w-full ">
                <thead>
                  {headerGroups.map((headerGroup) => (
                    <tr
                      className={`cursor-pointer bg-[#F6C107]`}
                      {...headerGroup.getHeaderGroupProps()}
                    >
                      {headerGroup.headers.map((column) => (
                        // Add the sorting props to control sorting. For this example
                        // we can add them into the header props

                        <th
                          scope="col"
                          className="text-left px-2 py-2"
                          {...column
                            .getHeaderProps
                            // column.getSortByToggleProps()
                            ()}
                        >
                          <div className="flex items-center">
                            <span>
                              {t("constant." + column.render("Header"))}
                            </span>

                            {column.isSorted !== undefined &&
                              (column.isSorted ? (
                                <span
                                  onClick={(e) => {
                                    setSortingOrder("asc");
                                    setSortColumn(column.id);
                                    column.isSorted = false;
                                  }}
                                  style={{ cursor: "pointer", marginTop: 2 }}
                                  className="ml-1"
                                >
                                  <img src={DownArrow} />
                                </span>
                              ) : (
                                <span
                                  onClick={(e) => {
                                    setSortingOrder("desc");
                                    setSortColumn(`${column.id}`);
                                    column.isSorted = true;
                                  }}
                                  style={{
                                    cursor: "pointer",
                                    transform: "rotate(180deg)",
                                  }}
                                  className="ml-1"
                                >
                                  <img src={DownArrow} />
                                </span>
                              ))}
                          </div>
                        </th>
                      ))}
                      {showActionBtn && (
                        <th
                          scope="col"
                          className="text-left px-4 pt-2"
                          style={{}}
                        >
                          Action
                        </th>
                      )}
                    </tr>
                  ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                  {tableData.length < 1 && (
                    <NoDataComponent colLength={tableColumns.length} />
                  )}
                  {tableData.length > 0 &&
                    rows.map((row, i) => {
                      // new
                      prepareRow(row);
                      return (
                        <tr {...row.getRowProps()}>
                          {row.cells.map((cell, row_cell_index) => {
                            return (
                              <td
                                {...cell.getCellProps()}
                                className={`px-2 py-2 font-medium text-sm contracts-col-data ${
                                  cell.value === "In-Active" && "text-red-500"
                                }`}
                                style={
                                  cell.column.Header === "Contract Name"
                                    ? {
                                        width: 220,
                                        overflow: "hidden",
                                        display: "block",
                                        textOverflow: "ellipsis",
                                      }
                                    : null
                                }
                              >
                                <span
                                  title={
                                    cell.column.Header === "Document Name"
                                      ? cell.value
                                      : ""
                                  }
                                  className={`${
                                    cell.column.Header === "Document Name"
                                      ? "text_fixed cursor-pointer"
                                      : ""
                                  }`}
                                >
                                  {cell.render("Cell")}
                                </span>
                              </td>
                            );
                          })}
                          {showActionBtn && (
                            <td className="py-2 flex">
                              <button
                                onClick={(e) => {
                                  e.stopPropagation();
                                  setPreviewDocument(true);
                                  setPreviewDocumentData(row.original);
                                }}
                                className="btn btn_theme btn-sm mr-1 wh_spab w-10"
                                data-tip={t("constant.View")}
                              >
                                {/* View */}
                                <img
                                  src={EyeOnIconWhite}
                                  className="ml-0.5"
                                  width={20}
                                />
                              </button>
                              <button
                                onClick={(e) => {
                                  e.stopPropagation();
                                  setDocId(row.original.id);
                                  downlaodDocument(row.original.id);
                                }}
                                className="btn btn_theme btn-sm mr-1 wh_spab w-10"
                                data-tip={t("constant.Download document")}
                              >
                                {/* {t('constant.Download document')} */}
                                <img
                                  src={DownoadIcon}
                                  className="ml-0.5"
                                  width={20}
                                />
                              </button>

                              {row.original.md5sum && (
                                <button
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    const md5sum = row.original.md5sum;
                                    navigator.clipboard.writeText(md5sum);
                                  }}
                                  className="btn btn_theme btn-sm  wh_spab"
                                  data-tip={row.original.md5sum}
                                >
                                  {t("constant.CheckSum")}
                                </button>
                              )}

                              {row.original.is_send_to_legacy === false ? (
                                <button
                                  onClick={(e) => {
                                    handleSendToLegacy(row.original);
                                  }}
                                  className="btn btn_theme btn-sm  wh_spab"
                                >
                                  {t("constant.Send to Document Storage")}
                                </button>
                              ) : (
                                <button
                                  className="btn btn_theme btn-sm  wh_spab"
                                  style={{ backgroundColor: "gray" }}
                                >
                                  {t("allModule.Sent to Document Storage")}
                                </button>
                              )}
                            </td>
                          )}

                          <ReactTooltip
                            backgroundColor="#400835"
                            textColor="#ffffff"
                          />
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        {tableData.length > 0 && (
          <div
            className={`mt-3 flex ${
              showPageLimit ? "justify-between" : "justify-end"
            }`}
          >
            {showPageLimit && (
              <div>
                {itemCount > 5 && (
                  <>
                    <span>{t("constant.Show")}</span>
                    <select
                      className="border-2 border-primary outline-none mx-1 rounded-md cursor-pointer btn-sm"
                      onChange={(e) => {
                        setCurrentPage(1);
                        setPageLimit(e.target.value);
                      }}
                      value={pageLimit}
                    >
                      <option>5</option>
                      <option>10</option>
                      <option>15</option>
                      <option>25</option>
                    </select>
                    <span>{t("common.Results")}</span>
                  </>
                )}
              </div>
            )}
            <div className="flex text-primary  border-t-2 border-b-2 border-primary rounded-lg">
              <button
                className={`btn-sm border-r-2 border-l-2 border-primary font-semibold rounded-tl-lg rounded-bl-lg ${
                  currentPage <= 1 && "bg-inputBackground"
                }`}
                disabled={currentPage <= 1}
                onClick={(e) => {
                  if (currentPage > 1) {
                    setCurrentPage(currentPage - 1);
                  }
                }}
              >
                {t("common.Prev")}
              </button>
              <span className="btn-sm font-semibold">{currentPage}</span>
              <button
                className={`btn-sm border-l-2 border-r-2 border-primary font-semibold rounded-tr-lg rounded-br-lg ${
                  currentPage >= nextBtnLimit && "bg-inputBackground"
                }`}
                disabled={currentPage >= nextBtnLimit}
                onClick={(e) => {
                  if (currentPage < nextBtnLimit) {
                    setCurrentPage(currentPage + 1);
                  }
                }}
              >
                {t("common.Next")}
              </button>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default CompletedDocTable;
