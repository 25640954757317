import React, { useState, useContext, useEffect } from 'react';
import useAxios from '../../hooks/useAxios';
import { resolve } from '../../api/resolve';
import HelpIcon from '../../assets/icons/Help-sm.svg';
import ReactTooltip from 'react-tooltip';

import { adminPlanTableColumns } from '../../constants/constants';
import CloseIcon from '../../assets/icons/Close.svg';
import Search from '../../assets/icons/Search.svg';
import PlanListTable from './PlanListTable';
import AddButton from '../../shared/AddButton';


const PlanList = () => {

    const api = useAxios();
    // pending doc related states
    const [planList, setPlanList] = useState([]);
    const [planSearchText, setPlanListSearchText] = useState('');
    const [planCurrentPage, setPlanListCurrentPage] = useState(1);
    const [planPageLimit, setPlanListPageLimit] = useState(10);
    const [planNextBtnLimit, setPlanListNextBtnLimit] = useState(0);
    const [planCount, setPlanListCount] = useState(0);
    const [errMsgPlanList, setErrMsgPlanList] = useState('');
    const [showPlanListClearIcon, setShowPlanListClearIcon] = useState(false);
    const [sortingOrder, setSortingOrder] = useState('desc');
    const [sortColumn, setSortColumn] = useState('id');

    const getPlanListList = async (clearText) => {

        const res = await resolve(
            api
                .get(
                    `user/plan/list/?search=${clearText ? '' : planSearchText}&page=${planCurrentPage}&limit=${planPageLimit}`
                    // `user/plan/list/`
                )
                .then((res) => res.data)
        );
        if (res.data) {
            const { data } = res;
            const { results, count } = data;
            const filterData = [];
            if (results.length > 0) {
                setPlanListCount(count);
                results.forEach((result) => {
                    const planListObj = {
                        id: result['plan_id'].id,
                        name: result['plan_id'].name,
                        plan_type: result['plan_id'].is_corporate_plan?'Corporate':'Normal',
                        company: result['plan_id'].available_for_company ? 'Yes' : 'No',
                        user: result['plan_id'].available_for_user ? 'Yes' : 'No',
                        planInfo: result,
                    };
                    filterData.push(planListObj);
                });
            } else {
                if (planCurrentPage === 1) setErrMsgPlanList('No plan added yet');
                else setErrMsgPlanList('No more plan to view');
            }
            setPlanList([...filterData]);
            setPlanListNextBtnLimit(Math.ceil(data.count / planPageLimit))
        } else {
            setPlanListNextBtnLimit(0)
        }
    };

    useEffect(() => {
        getPlanListList();
    }, [sortColumn, sortingOrder, planCurrentPage, planPageLimit]);

    return (
        <>
            <div className='text-primary'>
                <div className="main_head">
                    <h1 className='text-4xl font-bold'>Subcription Plan</h1>
                </div>

                <div className="tab_main">
                    <div className={`tab_us show`}>
                        <h1 className='text-xl font-bold'>Plan List</h1>
                        <div className="row justify-between">
                            <div className="col-md-5">
                                <form>
                                    <div className='flex mt-3  w-full'>

                                        <div className='flex justify-between items-center border-2 border-primary rounded-md rounded-tl-lg rounded-bl-lg bg-inputBackground px-2 mr-2 w-full '>
                                            <input
                                                placeholder='Search for Plan'
                                                className='px-1 py-1 focus:outline-none bg-transparent w-11/12'
                                                value={planSearchText}
                                                onChange={(e) => {
                                                    setPlanListSearchText(e.target.value);
                                                    setShowPlanListClearIcon(true);
                                                    if (!e.target.value.trim()) {
                                                        setShowPlanListClearIcon(false);
                                                    }
                                                }}
                                            />
                                            {showPlanListClearIcon && (
                                                <img
                                                    src={CloseIcon}
                                                    width={14}
                                                    className='cursor-pointer'
                                                    onClick={() => {
                                                        setPlanListCurrentPage(1);
                                                        setPlanListSearchText('');
                                                        getPlanListList(true);
                                                        setShowPlanListClearIcon(false);
                                                    }}
                                                />
                                            )}
                                        </div>
                                        <button
                                            type='submit'
                                            className='flex btn btn_theme2 w_125 items-center justify-between'
                                            onClick={(e) => {
                                                e.preventDefault();
                                                setPlanListCurrentPage(1);
                                                getPlanListList();
                                            }}
                                        >
                                            <img src={Search} style={{ width: '1rem' }} />
                                            <span className='font-semibold ml-1'>Search</span>
                                        </button>
                                    </div>
                                </form>
                            </div>
                            <div className="col-md-5">
                                <div className='flex justify-end mt-3'>
                                    <div className='mr-5'>
                                        <AddButton text="Add New Plan" currentForm="PlanForm" />
                                    </div>
                                    
                                    <AddButton text="Add Corporate Plan" currentForm="PlanCorporateForm" />
                                </div>
                            </div>
                        </div>


                        <div className='mt-3 rounded-lg'>
                            {planList.length < 1 && (
                                <p className='text-red-500 text-sm font-medium'>{errMsgPlanList}</p>
                            )}
                        </div>
                        <PlanListTable
                            tableColumns={adminPlanTableColumns}
                            getListData={getPlanListList}
                            tableData={planList}
                            showActionBtn
                            nextBtnLimit={planNextBtnLimit}
                            currentPage={planCurrentPage}
                            setCurrentPage={setPlanListCurrentPage}
                            itemCount={planCount}
                            setPageLimit={setPlanListPageLimit}
                            showPageLimit={true}
                            sortingOrder={sortingOrder}
                            setSortingOrder={setSortingOrder}
                            setSortColumn={setSortColumn}
                        />
                    </div>

                </div>


            </div>


        </>


    )
}
export default PlanList;