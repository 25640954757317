import React, { useEffect, useContext, useState } from 'react';
import Switch from 'react-switch';
import Select from 'react-select';
import ReactTooltip from 'react-tooltip';
import Creatable from 'react-select/creatable';
import FormContext from '../../context/FormContext';
import AppContext from '../../context/AppContext';
import AuthContext from '../../context/AuthContext';
import AppModal from '../modallegacy/Modallegacy';
import { resolve } from '../../api/resolve';
import useAxios from '../../hooks/useAxios';
import HelpIcon from '../../assets/icons/Help-sm.svg';
import CustomPhoneInput from './PhoneInputlegacy';
import { useTranslation } from 'react-i18next';
const LicencingFormlegacy = () => {
  const { t } = useTranslation()
  const {
    setCurrentForm,
    saveOrEditLicencing,
    modalData,
    setIsOpen,
    modalIsOpen,
    isEditing,
    editFormData,
    setEditFormData,
    setIsEditing,
    licenceFormErrors,
    shouldRedirect,
  } = useContext(FormContext);

  const api = useAxios();
  const { setActiveLink } = useContext(AppContext);
  const [loading, setLoading] = useState(false);
  const [addObj, setAddObj] = useState({
    licence_key: '',
  });
    
 
  useEffect(() => {
    return () => {
      if (isEditing) {
        setEditFormData('');
        setIsEditing(false);
      }
    };
  }, [isEditing]);
 
  return (
    <div className='text-primary text-base font-normal font-sans'>
      <h1 className='text-4xl font-bold'>
        {!isEditing ? 'Add Licensing' : 'Edit Licensing'}
      </h1>
      <div className=' w-2/5 mt-10'>
        <form>
          <div className='flex flex-col mb-3'>
            <label>{t('form.Licensing key')}</label>
            <input
              placeholder={`Insert ${t('form.Licensing key')}`}
              className='border-2 border-primary rounded-lg outline-none p-1 pl-2'
              value={!isEditing ? addObj.licence_key : editFormData.licence_key}
              onChange={(e) => {
                !isEditing
                  ? setAddObj({ ...addObj, licence_key: e.target.value })
                  : setEditFormData({
                      ...editFormData,
                      licence_key: e.target.value,
                    });
              }}
            />
            <p className='text-sm text-red-500 font-normal'>
              {licenceFormErrors.licence_key}
            </p>
          </div>    
           
      
          {!isEditing ? (
            <button
              className='bg-primary font-bold text-white w-full p-1 rounded-lg'
              onClick={(e) => {
                e.preventDefault();
                saveOrEditLicencing(addObj);
              }}
            >
               {t('modal.Save')}
            </button>
          ) : (
            <button
              className='bg-secondary font-bold text-white w-full p-1 rounded-lg'
              onClick={(e) => {
                e.preventDefault();
                saveOrEditLicencing(editFormData);
              }}
            >
              {t('modal.Update')}
            </button>
          )}
        </form>
      </div>
      <AppModal
        title={modalData.title}
        bodyText={modalData.bodyText}
        setIsOpen={setIsOpen}
        modalIsOpen={modalIsOpen}
        setActiveLink={setActiveLink}
        activeLink={shouldRedirect && 'Licencing'}
      />
    </div>
  );
};

export default LicencingFormlegacy;
