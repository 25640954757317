import React from "react";
import Modal from "react-modal";
import dayjs from "dayjs";
import "./modal.css";
import CloseIcon from "../../assets/icons/Close.svg";
import ContractsListTable from "../notifications/ContractsListTable";
import { notificationListColumns } from "../../constants/constants";
import { useTranslation } from "react-i18next";

const customStyles = {
  content: {
    top: "40%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "600px",
    maxHeight: "500px",
    borderRadius: "11px",
  },
  overlay: {
    backgroundColor: "rgba(64, 8, 53, 0.63)",
  },
};

Modal.setAppElement("#root");
const NotificationModal = ({
  modalIsOpen,
  setIsOpen,
  notifications,
  notiftype,
  selectedNotification,
}) => {
  const { t } = useTranslation();
  return (
    <div className="">
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={() => setIsOpen(false)}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <div className="important-padding">
          {selectedNotification.length > 0 && (
            <h1 className="text-primary font-bold text-base text-center">
              {/* {selectedNotification[0].type !== 'notify_indexing_done'
              ? `Following Contracts are getting expired on ${dayjs(
                  selectedNotification[0].renewal_date
                ).format('MMMM D, YYYY')}`
              : 'Following Contracts are ready to view'} */}
              {selectedNotification[0].type === "notify_indexing_done"
                ? t("modal.Following Contracts are ready to view")
                : selectedNotification[0].type === "notify_renewal_date_absent"
                ? t("modal.Following Contracts are ready to view")
                : `${t("modal.Following Contracts are ready to view")}`}
            </h1>
          )}
          <ContractsListTable
            contractsData={selectedNotification}
            contractTableColumns={notificationListColumns}
            notiftype={notiftype}
          />
        </div>
      </Modal>
    </div>
  );
};

export default NotificationModal;
