import React, { useContext, useEffect, useRef, useState } from "react";
import axios from "axios";
import Modal from "react-modal";
import md5 from "md5";
import "./AddContractModal.css";
import GooglePicker from "react-google-picker";
import Computer from "../../assets/icons/Computer.svg";
import Googledrive from "../../assets/icons/Googledrive.svg";
import ZipIcon from "../../assets/icons/zip.png";
import OneDrive from "../../assets/icons/OneDrive.svg";
import CloseIcon from "../../assets/icons/Close.svg";
import FormContext from "../../context/FormContext";
import { ReactOneDriveFilePicker } from "react-onedrive-filepicker";
import { resolve } from "../../api/resolve";
import useAxios from "../../hooks/useAxios";
import { useTranslation } from "react-i18next";
import PermissionContext from "../../context/PermissionContext";

const customStyles = {
  content: {
    top: "50%",
    padding: "15px",
    left: "50%",
    right: "auto",
    bottom: "auto",
    transform: "translate(-50%, -50%)",
    borderRadius: "11px",
  },
  overlay: {
    backgroundColor: "rgba(64, 8, 53, 0.63)",
  },
};

Modal.setAppElement("#root");
const AddContractModal = ({
  modalIsOpen,
  setIsOpen,
  contractObj,
  setContractObj,
  isMultipleContracts,
  setIsMultipleContracts,
  multipleContractsObj,
  setMultipleContractsObj,
  repoName,
  repoID,
  activeLink,
}) => {
  const {
    setContractFormErrors,
    contractFormErrors,
    handleMultipleContracts,
    handleZipUpload,
  } = useContext(FormContext);
  const { t } = useTranslation();
  const { updatePermissionList } = useContext(PermissionContext);
  const fileRef = useRef();
  const zipRef = useRef();
  const api = useAxios();
  const [googleAccessToken, setGoogleAccessToken] = useState(null);
  const [btnDisabled, setIsBtnDisabled] = useState(false);
  const [fileExists, setFileExists] = useState(false);
  const [checkSumValues, setCheckSumValues] = useState([]);
  const [checkForDuplicates, setCheckForDuplicates] = useState(false);
  const [isZip, setIsZip] = useState(false);
  const handleFileChange = (file) => {
    const reader = new FileReader();
    reader.readAsText(file);
    reader.onload = async () => {
      // const hash = md5(reader.result);
      const hash = "";
      const res = await resolve(
        api
          .post(
            `/contract/validate_checksum/`,
            JSON.stringify({ checksum_array: [hash] }),
            {
              headers: {
                "Content-Type": "application/json",
              },
            }
          )
          .then((res) => res.data)
      );
      if (!res.data?.success_array[0]) {
        setContractFormErrors({
          ...contractFormErrors,
          checkSumExists: true,
        });
      }
      let docType = file.name.split(".")[file.name.split(".").length - 1];
      if (
        (activeLink !== "ContractO365Form" && docType === "pdf") ||
        docType === "doc" ||
        docType === "docx"
      ) {
        setContractObj({
          ...contractObj,
          doc_name: file.name,
          document: file,
          checkSumValue: hash,
        });
      } else {
        let errorMessage =
          activeLink === "ContractO365Form"
            ? t("form.Only DOC, DOCX files supported")
            : t("form.Only PDF, DOC, Excel or PPT file supported");
        setContractFormErrors({
          ...contractFormErrors,
          document: errorMessage,
        });
      }
      setIsOpen(false);
    };
  };

  const readFileAsText = (file, arr) => {
    return new Promise(function (resolve, reject) {
      let fr = new FileReader();
      fr.readAsText(file);
      fr.onload = function () {
        resolve(md5(fr.result));
      };
    });
  };

  const handleMultipleFileChange = (fileList) => {
    const checksum_array = [];
    setFileExists(false);
    if (fileList.length > 0) {
      const formattedFiles = [...fileList];
      const doctypes = [
        "pdf",
        "doc",
        "docx",
        "ppt",
        "pptx",
        "xlsx",
        "xls",
        "csv",
      ];
      let hasErrors = false;
      formattedFiles.forEach((file) => {
        let docType = file.name.split(".")[file.name.split(".").length - 1];
        if (!doctypes.includes(docType)) {
          hasErrors = true;
          setContractFormErrors({
            ...contractFormErrors,
            multi_document: t(
              "form.Only PDF, DOC, Excel or PPT file supported"
            ),
          });
          setIsOpen(false);
          return;
        }
        setCheckForDuplicates(true);
        checksum_array.push(readFileAsText(file));
      });
      Promise.all(checksum_array).then(async (values) => {
        setCheckSumValues([...values]);
        const res = await resolve(
          api
            .post(
              `/contract/validate_checksum/`,
              JSON.stringify({ checksum_array: [...values] }),
              {
                headers: {
                  "Content-Type": "application/json",
                },
              }
            )
            .then((res) => res.data)
        );
        if (res.data) {
          res.data.success_array.forEach((item, id) => {
            if (!item) {
              setFileExists(true);
              formattedFiles[id].exists = true;
            } else {
              formattedFiles[id].exists = false;
            }
          });
        }
        if (!hasErrors) {
          setMultipleContractsObj(formattedFiles);
        }
        setCheckForDuplicates(false);
      });
    }
  };
  const getFileFromDrive = async (
    driveName,
    id,
    name,
    mimeType,
    downloadUrl
  ) => {
    setContractFormErrors({
      ...contractFormErrors,
      document: "",
    });
    try {
      const res =
        driveName === "Google"
          ? await axios.get(
              `https://www.googleapis.com/drive/v2/files/${id}?alt=media`,
              {
                headers: {
                  Authorization: `Bearer ${googleAccessToken}`,
                },
                responseType: "blob",
              }
            )
          : await axios.get(downloadUrl, {
              responseType: "blob",
            });
      let blob = new Blob([res.data], { type: mimeType });
      const reader = new FileReader();
      reader.readAsText(blob);
      reader.onload = async () => {
        const hash = md5(reader.result);
        const res = await resolve(
          api
            .post(
              `/contract/validate_checksum/`,
              JSON.stringify({ checksum_array: [hash] }),
              {
                headers: {
                  "Content-Type": "application/json",
                },
              }
            )
            .then((res) => res.data)
        );
        if (!res.data.success_array[0]) {
          setContractFormErrors({
            ...contractFormErrors,
            checkSumExists: true,
          });
        }
        setContractObj({
          ...contractObj,
          doc_name: name,
          document: blob,
          checkSumValue: hash,
        });
      };
    } catch (error) {
      setContractFormErrors({
        ...contractFormErrors,
        document: t("form.Unable to select file. Try again"),
      });
    }
    setIsOpen(false);
  };
  const getMultipleFilesFromDrive = async (driveName, docs) => {
    const checksum_array = [];
    setContractFormErrors({
      ...contractFormErrors,
      drive_errors: "",
    });
    let downloadedFiles = [];
    setIsBtnDisabled(true);
    try {
      for (const doc of docs) {
        const res =
          driveName === "Google"
            ? await axios.get(
                `https://www.googleapis.com/drive/v2/files/${doc.id}?alt=media`,
                {
                  headers: {
                    Authorization: `Bearer ${googleAccessToken}`,
                  },
                  responseType: "blob",
                }
              )
            : await axios.get(doc["@microsoft.graph.downloadUrl"], {
                responseType: "blob",
              });
        let blob = new Blob([res.data], {
          type: doc.name.split(".")[doc.name.split(".").length - 1],
        });
        checksum_array.push(readFileAsText(blob));
        downloadedFiles.push({
          name: doc.name,
          repo_name: repoName,
          repo_id: repoID,
          document: blob,
        });
      }
      if (downloadedFiles.length === docs.length) {
        Promise.all(checksum_array).then(async (values) => {
          setCheckSumValues([...values]);
          const res = await resolve(
            api
              .post(
                `/contract/validate_checksum/`,
                JSON.stringify({ checksum_array: [...values] }),
                {
                  headers: {
                    "Content-Type": "application/json",
                  },
                }
              )
              .then((res) => res.data)
          );
          if (res.data) {
            res.data.success_array.forEach((item, id) => {
              if (!item) {
                setFileExists(true);
                downloadedFiles[id].exists = true;
              } else {
                downloadedFiles[id].exists = false;
              }
            });
          }
          setIsBtnDisabled(false);
          setMultipleContractsObj([...downloadedFiles]);
        });
      }
    } catch (error) {
      setIsBtnDisabled(false);
      setContractFormErrors({
        ...contractFormErrors,
        drive_errors: t("form.Unable to select file. Try again"),
      });
    }
  };

  // useEffect(() => {
  //   return () => setIsZip(false);
  // });
  return (
    <div className="">
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={() => setIsOpen(false)}
        style={customStyles}
        onAfterClose={() =>
          isMultipleContracts && setIsMultipleContracts(false)
        }
      >
        <h1 className="text-center font-bold text-primary text-xl mb-3 pt-4">
          {isMultipleContracts
            ? t("form.Upload Contracts")
            : t("form.Upload Contract")}
        </h1>
        {checkForDuplicates ? (
          <h2 className="text-center font-semibold">
            {t("form.Please wait...")}
          </h2>
        ) : (
          <div className="flex justify-evenly pb-5 fix_icon_us">
            <div className="flex items-center cursor-pointer">
              <div className="picker-option">
                <img src={Computer} onClick={() => fileRef.current.click()} />
              </div>
              <div>
                <label
                  htmlFor="files"
                  className="ml-3 font-semibold cursor-pointer"
                >
                  {t("form.Computer")}
                </label>
                <input
                  id="files"
                  style={{ display: "none" }}
                  type="file"
                  ref={fileRef}
                  onChange={(e) => {
                    setIsZip(false);
                    if (!isMultipleContracts) {
                      handleFileChange(e.target.files[0]);
                    } else {
                      handleMultipleFileChange(e.target.files);
                    }
                  }}
                  accept={
                    activeLink === "ContractO365Form"
                      ? ".doc, .docx, .ppt, .pptx, .xlsx, .xls, .csv"
                      : ".doc, .docx, .ppt, .pptx, .pdf, .xlsx, .xls, .csv"
                  }
                  multiple={isMultipleContracts}
                />
              </div>
            </div>
            {/* disable zip upload as of now */}
            {isMultipleContracts && (
              <div className="flex items-center cursor-pointer">
                <div className="picker-option">
                  <img
                    src={ZipIcon}
                    onClick={() => zipRef.current.click()}
                    width={50}
                  />
                </div>
                <div>
                  <label
                    htmlFor="files"
                    className="ml-3 font-semibold cursor-pointer"
                    onClick={(e) => {
                      e.preventDefault();
                      zipRef.current.click();
                    }}
                  >
                    {t("form.Zip")}
                  </label>
                  <input
                    id="files"
                    style={{ display: "none" }}
                    type="file"
                    ref={zipRef}
                    onChange={(e) => {
                      setIsZip(true);
                      setMultipleContractsObj([e.target.files[0]]);
                    }}
                    accept=".zip"
                    multiple={false}
                  />
                </div>
              </div>
            )}

            <GooglePicker
              clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
              developerKey={process.env.REACT_APP_GOOGLE_API_KEY}
              scope={[
                "https://www.googleapis.com/auth/drive.file",
                "https://www.googleapis.com/auth/drive",
                "https://www.googleapis.com/auth/drive.file",
                "https://www.googleapis.com/auth/drive.metadata",
              ]}
              onChange={(data) => {
                setIsZip(false);
                if (!isMultipleContracts) {
                  getFileFromDrive(
                    "Google",
                    data.docs[0].id,
                    data.docs[0].name,
                    data.docs[0].name.split(".")[
                      data.docs[0].name.split(".").length - 1
                    ]
                  );
                } else {
                  getMultipleFilesFromDrive("Google", data.docs);
                }
              }}
              onAuthenticate={(token) =>
                setGoogleAccessToken(!googleAccessToken && token)
              }
              onAuthFailed={(data) => {}}
              multiselect={isMultipleContracts}
              navHidden={true}
              authImmediate={false}
              mimeTypes={[
                "application/pdf",
                "application/msword",
                "application/vnd.ms-powerpoint",
                "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
                "application/vnd.openxmlformats-officedocument.presentationml.presentation",
                "application/vnd.oasis.opendocument.presentation",
                "application/vnd.ms-powerpoint.presentation.macroEnabled.12",
              ]}
              viewId={"DOCS"}
            >
              <div className="flex items-center cursor-pointer">
                <div className="picker-option">
                  <img src={Googledrive} />
                </div>
                <div>
                  <span className="ml-3 font-semibold cursor-pointer">
                    {t("form.Google Drive")}
                  </span>
                </div>
              </div>
            </GooglePicker>
            <ReactOneDriveFilePicker
              clientID={process.env.REACT_APP_ONEDRIVE_CLIENT_ID}
              action="share"
              multiSelect={isMultipleContracts}
              onSuccess={(result) => {
                setIsZip(false);
                if (!isMultipleContracts) {
                  getFileFromDrive(
                    "OneDrive",
                    result.value[0].id,
                    result.value[0].name,
                    result.value[0].file.mimeType,
                    result.value[0]["@microsoft.graph.downloadUrl"]
                  );
                } else {
                  getMultipleFilesFromDrive("OneDrive", result.value);
                }
              }}
              onCancel={(result) => {}}
            >
              <div className="flex items-center cursor-pointer">
                <div className="picker-option">
                  <img src={OneDrive} />
                </div>
                <p className="ml-3 font-semibold">{t("form.OneDrive")}</p>
              </div>
            </ReactOneDriveFilePicker>
          </div>
        )}
        {multipleContractsObj.length > 0 && (
          <div style={{ maxHeight: "300px", overflow: "auto" }}>
            <h2 className="text-center  font-bold my-1">
              {t("form.Selected Files")} :
            </h2>
            <h2 className="text-center text-red-500 font-semibold my-1">
              {fileExists && "One or more files already exist"}
            </h2>
            <table className="mx-auto w-4/5">
              <thead>
                <tr className="flex justify-between px-3">
                  <th>{t("form.File Name")}</th>
                  <th>{t("form.Action")}</th>
                </tr>
              </thead>
              <tbody>
                {multipleContractsObj.map((contractObj, idx) => (
                  <tr
                    className="flex justify-between px-3 my-1"
                    key={contractObj.name}
                  >
                    <td className={contractObj.exists && "text-red-500"}>
                      {contractObj.name}
                    </td>
                    <td className="grid place-items-center mr-3">
                      {" "}
                      <img
                        src={CloseIcon}
                        width={16}
                        className="cursor-pointer"
                        onClick={() => {
                          const filteredDocs = multipleContractsObj.filter(
                            (item, id) => id !== idx
                          );
                          const filteredCheckSum = checkSumValues.filter(
                            (item, id) => id !== idx
                          );
                          setMultipleContractsObj([...filteredDocs]);
                          setCheckSumValues([...filteredCheckSum]);
                        }}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
        {isMultipleContracts && (
          <>
            <button
              className={`bg-primary font-bold text-white mx-auto block w-2/3 p-1 rounded-lg mt-4 mb-5 ${
                btnDisabled && "bg-gray-500"
              }`}
              onClick={(e) => {
                e.preventDefault();
                if (multipleContractsObj.length > 0 && !isZip) {
                  handleMultipleContracts(
                    multipleContractsObj,
                    repoName,
                    repoID,
                    checkSumValues,
                    updatePermissionList
                  );
                } else {
                  handleZipUpload(
                    multipleContractsObj,
                    repoName,
                    repoID,
                    updatePermissionList
                  );
                }
              }}
              disabled={btnDisabled}
            >
              {btnDisabled ? "Loading..." : "Save Documents"}
            </button>
            <p className="text-l text-red-500 font-normal ml-5 mt-3 text-center">
              {contractFormErrors.drive_errors}
            </p>
          </>
        )}
      </Modal>
    </div>
  );
};

export default AddContractModal;
