import appLogo from '../../assets/app-logo.svg';
import React, { useContext, useEffect, useState } from 'react';
import AuthContext from '../../context/AuthContext';
import { toast } from 'react-toastify';
import useAxios from '../../hooks/useAxios';
import { resolve } from '../../api/resolve';
import LoadingScreen from '../loader/LoadingScreen';
import { useNavigate } from 'react-router-dom';
import AppContext from '../../context/AppContext';
import { useTranslation } from 'react-i18next';

const AadharFailed = () => {
    const api = useAxios();
    const { user } = useContext(AuthContext)
    const [loading, setLoading] = useState(false);
    const { setActiveLink } = useContext(AppContext);
    const navigate = useNavigate()
    const { t } = useTranslation();

    const aadharFailedApi = async () => {
        setLoading(true)
        const postData = {
            session_id: localStorage.getItem('purchaseAadharSession'),
            user_id: user['user_id']
        };
        const res = await resolve(api.post(`user/aadhar/payment/intent/`, postData).then((res) => res.data));

        if (res.data) {
            toast.error(t('form.Payment Failed'))
        }
        if (res.error) {
            toast.error(t('common.Something went wrong'));
        }
        setLoading(false)
    };

    useEffect(() => {
        // aadharFailedApi();
    }, [])
    return (
        <>

            {loading ? (
                <LoadingScreen />
            ) : (
                <div className="thank-you-for-signing" >
                    <img src={appLogo} width={200} alt="Thank you" />
                    <h1>Payment Failed</h1>
                    <p>Your action has been failed.</p>
                    <button onClick={(e) => {
                        e.stopPropagation();
                        setActiveLink('Wallet')
                        navigate('/dashboard');
                    }}
                        className="btn btn_theme btn-sm mt-5"
                    >{t('form.Back to Quoqo Fusion')}</button>
                </div>
            )}


        </>



    )
}

export default AadharFailed;