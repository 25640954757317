import React, { useState, useContext } from 'react';
import { useTable } from 'react-table';
import '../contracts/contracts.css';
import DownArrow from '../../assets/icons/down-arrow.svg';
import ReactTooltip from 'react-tooltip';
import { useTranslation } from 'react-i18next';
import PermissionContext from '../../context/PermissionContext';
import EmailIconWhite from "../../assets/icons/email.png";
import smsIconWhite from "../../assets/icons/sms.png";
export const NoDataComponent = ({ colLength }) => {
  const [rows] = useState(Array(4).fill(''));
  const [cols] = useState(Array(colLength).fill(''));

  return (
    <>
      {rows.map((data01, index01) => (
        <tr key={'row_' + index01}>
          {cols.map((data02, index2) => (
            <td key={'column_' + index2} className='px-5 py-2 font-medium text-smW'>--</td>
          ))}
        </tr>
      ))}
    </>
  );
};

const DocumentTable = ({
  documentListData,
  documentTableColumns,
  prevPage,
  nextPage,
  currentPage,
  setCurrentPage,
  sortingOrder,
  setSortingOrder,
  setSortColumn,
  setPageLimit,
  contactCount,
  showActionBtn,
  showPageLimit,
  getDocumentList,
  nextBtnLimit,
  sendEmail,
  sendSms
}) => {

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({ columns: documentTableColumns, data: documentListData });
  const [currentRow, setCurrentRow] = useState(0);
  const { t } = useTranslation();
  const { permissionList } = useContext(PermissionContext);
  return (
    <>
      <div className='mt-2 flex flex-col table-container mb-5'>
        <div>
          <div className='table_desin'>

            <div className='shadow overflow-hidden border-2 border-primary  sm:rounded-lg max-h-96 overflow-y-auto'>

              <table {...getTableProps()} className='w-full '>
                <thead>
                  {headerGroups.map((headerGroup) => (
                    <tr
                      className={`cursor-pointer bg-[#F6C107]`}
                      {...headerGroup.getHeaderGroupProps()}>

                      {headerGroup.headers.map((column) => (
                        <th
                          scope='col'
                          className='text-left px-2 py-2'
                          {...column
                            .getHeaderProps
                            ()}
                        >
                          <div className='flex items-center'>
                            <span>{t('constant.' + column.render('Header'))}</span>

                            {column.isSorted !== undefined &&
                              (column.isSorted ? (
                                <span
                                  onClick={(e) => {
                                    setSortingOrder('asc');
                                    setSortColumn(column.id);
                                    column.isSorted = false;
                                  }}
                                  style={{ cursor: 'pointer', marginTop: 2 }}
                                  className='ml-1'
                                >
                                  <img src={DownArrow} />
                                </span>
                              ) : (
                                <span
                                  onClick={(e) => {
                                    setSortingOrder('desc');
                                    setSortColumn(`${column.id}`);
                                    column.isSorted = true;
                                  }}
                                  style={{
                                    cursor: 'pointer',
                                    transform: 'rotate(180deg)',
                                  }}
                                  className='ml-1'
                                >
                                  <img src={DownArrow} />
                                </span>
                              ))
                            }
                          </div>
                        </th>
                      ))}
                      {showActionBtn && (
                        <th scope='col' className='text-left px-4 pt-2'>
                          {t('constant.Send Reminder')}
                        </th>
                      )}
                    </tr>
                  ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                  {documentListData.length < 1 && (
                    <NoDataComponent colLength={documentTableColumns.length} />
                  )}
                  {documentListData.length > 0 &&

                    rows.map((row, i) => {
                      // new
                      prepareRow(row);
                      return (
                        <tr
                          {...row.getRowProps()}
                          onClick={() => {
                            setCurrentRow(i);
                          }}

                        >
                          {row.cells.map((cell, tdIndex) => {
                            return (
                              <React.Fragment key={i + '-' + tdIndex}>
                                <td
                                  {...cell.getCellProps()}
                                  className={`px-2 py-2 font-medium text-sm contracts-col-data ${cell.value === 'In-Active' && 'text-red-500'
                                    }`}
                                >
                                  <span className={`${cell.column.Header === 'Document Name' ? 'text_fixed' : ''}`}

                                  >
                                    {cell.render('Cell')}
                                  </span>

                                </td>
                              </React.Fragment>
                            );
                          })}

                          {showActionBtn && (
                            <td className='py-2 px-2 flex' >
                              {row.original.status === 'InProcess' && permissionList['sms_email_reminder_on'] == true ? (
                                <>
                                  <button
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      sendSms(row.original.id)
                                    }}
                                    className='btn btn_theme btn-sm wh_spab w-10'
                                    data-tip={t('allModule.Notify via messaging')}
                                  >
                                    <img
                                      src={smsIconWhite}
                                      className="ml-0.5"
                                      width={20}
                                    />
                                    {/* {t('constant.SMS')} */}
                                  </button>
                                  <button
                                    data-tip={t('allModule.Notify via Email')}
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      sendEmail(row.original.id)
                                    }}
                                    className='btn btn_theme btn-sm wh_spab w-10'

                                  >
                                    <img
                                      src={EmailIconWhite}
                                      className="ml-0.5"
                                      width={20}
                                    />
                                    {/* {t('constant.Email')} */}
                                  </button>
                                </>
                              ) : (
                                <>-</>
                              )}
                            </td>
                          )}
                          <ReactTooltip backgroundColor='#400835' textColor='#ffffff' />
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        {documentListData.length > 0 && (
          <div
            className={`mt-3 flex ${showPageLimit ? 'justify-between' : 'justify-end'
              }`}
          >
            {showPageLimit && (
              <div>
                {contactCount > 5 && (
                  <>
                    <span>{t('constant.Show')}</span>
                    <select
                      className='border-2 border-primary outline-none mx-1 rounded-md cursor-pointer btn-sm'
                      onChange={(e) => setPageLimit(e.target.value)}
                    >
                      <option>5</option>
                      {contactCount >= 10 && <option>10</option>}
                      {contactCount >= 15 && <option>15</option>}
                      {contactCount >= 25 && <option>25</option>}
                    </select>
                    <span>{t('common.Results')}</span>
                  </>
                )}
              </div>
            )}
            <div className='flex text-primary  border-t-2 border-b-2 border-primary rounded-lg'>
              <button
                className={`btn-sm border-r-2 border-l-2 border-primary font-semibold rounded-tl-lg rounded-bl-lg ${currentPage <= 1 && 'bg-inputBackground'
                  }`}
                disabled={currentPage <= 1}
                onClick={(e) => {
                  if (currentPage > 1) {
                    setCurrentPage(currentPage - 1)
                  }
                }}
              >
                {t('common.Prev')}
              </button>
              <span className='btn-sm font-semibold'>{currentPage}</span>
              <button
                className={`btn-sm border-l-2 border-r-2 border-primary font-semibold rounded-tr-lg rounded-br-lg ${currentPage >= nextBtnLimit && 'bg-inputBackground'
                  }`}
                disabled={currentPage >= nextBtnLimit}
                onClick={(e) => {
                  if (currentPage < nextBtnLimit) {
                    setCurrentPage(currentPage + 1)
                  }
                }}
              >
                {t('common.Next')}
              </button>
            </div>
          </div>
        )}
      </div>


    </>
  );
};

export default DocumentTable;
