import { createContext, useState, useEffect, useContext } from 'react';
import { toast } from 'react-toastify';
import axios from 'axios';
import HugeUploader from 'huge-uploader';
import { resolve } from '../api/resolve';
import AppContextlegacy from './AppContextlegacy';
import AuthContextlegacy from './AuthContextlegacy';
import AppContext from '../context/AppContext';
import AuthContext from '../context/AuthContext';
import { validateEmail, validatePassword, validatePhone } from '../utils/utils';
import useAxios from '../hooks/useAxios';
import {getCookie} from '../utils/utils'
import { useTranslation } from 'react-i18next';

const FormContextlegacy = createContext();

export default FormContextlegacy;

export const FormProvider = ({ children }) => {
  const api = useAxios();
  const { t } = useTranslation();
  const { getCompanies, setNotificationCount, totalNotificationCount } =
    useContext(AppContext);
  const { user, authTokens } = useContext(AuthContext);
  const [modalIsOpen, setIsOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [modalData, setModalData] = useState({ title: '', bodyText: '' });
  const [currentForm, setCurrentForm] = useState('');
  const [companyFormErrors, setCompanyFormErrors] = useState({
    name: '',
    phone: '',
    address: '',
    renew_date: '',
  });
  const [adminFormErrors, setAdminFormErrors] = useState({
    name: '',
    company_id: '',
    email: '',
    confirm_email: '',
    phone: '',
  });
  const [userFormErrors, setUserFormErrors] = useState({
    name: '',
    company_id: '',
    email: '',
    confirm_email: '',
    phone: '',
    position: '',
  });
  const [ssoFormErrors, setSSOFormErrors] = useState({
    entityId: '',
    singleSignOnService: '',
    singleLogoutService: '',
    sso_cretificate: '',
    id: '',
  });
  const [reviewerFormErrors, setReviewerFormErrors] = useState({
    name: '',
    company_id: '',
    email: '',
    confirm_email: '',
    phone: '',
  });
  const [contractFormErrors, setContractFormErrors] = useState({
    name: '',
    type: '',
    renewal_date: '',
    document: '',
    multi_document: '',
    drive_errors: '',
    checkSumExists: false,
  });
  const [passwordFormErrors, setPasswordFormErrors] = useState({
    current: '',
    password: '',
    confirm: '',
  });

  const [licenceFormErrors, setLicenceFormErrors] = useState({
    licence_key: '',
  });
  const [moduleToEdit, setModuleToEdit] = useState('');
  const [editFormData, setEditFormData] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [shouldLogout, setShouldLogout] = useState(false);
  const [shouldRedirect, setShouldRedirect] = useState(true);
  const [showSubmitBtn, setShowSubmitBtn] = useState(true);
  const [progressValue, setProgressValue] = useState(1);

  const saveOrEditCompany = async (companyObj) => {
    const { name, phone, address, renew_date } = !isEditing
      ? companyObj
      : editFormData;
    if (name.trim().length < 1)
      setCompanyFormErrors({
        ...companyFormErrors,
        name: t('form.Company Name is required.'),
      });
    else if (phone.trim().length < 10)
      setCompanyFormErrors({
        ...companyFormErrors,
        phone: t('form.Phone Number is required.'),
      });
    else if (address.trim().length < 10)
      setCompanyFormErrors({
        ...companyFormErrors,
        address: t('form.Address is required.'),
      });
    else if (renew_date.trim().length < 1 || renew_date === 'Invalid Date')
      setCompanyFormErrors({
        ...companyFormErrors,
        renew_date: t('form.Renewal Date is required.'),
      });
    else {
      const res = !isEditing
        ? await resolve(
            api
              .post('/legacy_company/view_create/', JSON.stringify(companyObj), {
                headers: {
                  'Content-Type': 'application/json',
                },
              })
              .then((res) => res.data)
          )
        : await resolve(
            api
              .patch(
                `/legacy_company/retrieve_update/${editFormData.id}`,
                JSON.stringify(editFormData),
                {
                  headers: {
                    'Content-Type': 'application/json',
                  },
                }
              )
              .then((res) => res.data)
          );
      if (res.data) {
        setShouldRedirect(true);
        setIsOpen(true);
        setModalData({
          ...modalData,
          title: !isEditing ? t('form.Company Added') : t('form.Company Updated'),
          bodyText: `${t('form.You have successfully')} ${
            !isEditing ? t('form.added') : t('form.updated')
          } ${t('form.company')}.`,
        });
        getCompanies();
      }
      if (res.error) {
        setShouldRedirect(false);
        setIsOpen(true);
        setModalData({
          ...modalData,
          title: t('form.Something Went wrong'),
          bodyText: t('form.Company was not added. Please try again'),
        });
      }
    }
  };
  const saveOrEditAdmin = async (adminObj) => {
    const { first_name, phone, email, confirm_email, company_id } = !isEditing
      ? adminObj
      : editFormData;
    if (first_name.trim().length < 1)
      setAdminFormErrors({
        ...adminFormErrors,
        name: t('form.Admin Name is required.'),
      });
    else if (!company_id)
      setAdminFormErrors({
        ...adminFormErrors,
        company_id: t('form.Please select a company.'),
      });
    else if (!validateEmail(email))
      setAdminFormErrors({
        ...adminFormErrors,
        email: t('form.Please Enter a valid email'),
      });
    else if (email !== confirm_email)
      setAdminFormErrors({
        ...adminFormErrors,
        confirm_email: t('form.Email does not match'),
      });
    else if (phone.trim().length < 10)
      setAdminFormErrors({
        ...adminFormErrors,
        phone: t('form.Please enter a valid number'),
      });
    else {
      const res = !isEditing
        ? await resolve(
            api
              .post('/legacy_user/view_create/', JSON.stringify(adminObj), {
                headers: {
                  'Content-Type': 'application/json',
                },
              })
              .then((res) => res.data)
          )
        : await resolve(
            api
              .patch(
                `/legacy_user/retrieve_update/${editFormData.id}`,
                JSON.stringify(editFormData),
                {
                  headers: {
                    'Content-Type': 'application/json',
                  },
                }
              )
              .then((res) => res.data)
          );
      if (res.data) {
        setShouldRedirect(true);
        setIsOpen(true);
        setModalData({
          ...modalData,
          title: !isEditing ? t('form.Admin Added') : t('form.Admin Updated'),
          bodyText: `${t('form.You have successfully')} ${
            !isEditing ? t('form.added') : t('form.updated')
          } ${t('form.admin')}.`,
        });
      }
      if (res.error) {
        setShouldRedirect(false);
        console.log('res.error', res.error?.response?.data);
        const emailExists = res.error?.response?.data.email;
        const status_code = res.error?.response?.data.status_code;
        setIsOpen(true);
        if (emailExists?.length > 0) {
          setModalData({
            ...modalData,
            title: t('form.Email already exists'),
            bodyText: t('form.Admin email already exists'),
          });
        }else if (status_code?.length > 0 && status_code === "Your subscription was expired") {
          setModalData({
            ...modalData,
            title: t('form.Subscription Expired'),
            bodyText: t('form.Your subscription was expired'),
          });
        }else if (status_code?.length > 0 && status_code === "maximum_allowed_user_reached") {
          setModalData({
            ...modalData,
            title: t('form.Limit Expired'),
            bodyText: t('form.You have reached the maximum number of allowed users.'),
          });
        } else {
          setModalData({
            ...modalData,
            title: t('form.Something Went wrong'),
            bodyText: t('form.Admin was not added. Please try again'),
          });
        }
      }
    }
  };
  
  const saveOrEditUser = async (userObj) => {
    const {
      first_name,
      phone,
      email,
      confirm_email,
      position,
      company_id,
      repo_array,
      is_reviewer,
    } = !isEditing ? userObj : editFormData;
    if (first_name.trim().length < 1)
      setUserFormErrors({
        ...userFormErrors,
        name: t('form.User Name is required.'),
      });
    else if (user.user_type === 'super_admin' && !company_id)
      setUserFormErrors({
        ...userFormErrors,
        company_id: t('form.Please select a company.'),
      });
    else if (position.trim().length < 1)
      setUserFormErrors({
        ...userFormErrors,
        position: t('form.User position is required.'),
      });
    else if (!validateEmail(email))
      setUserFormErrors({
        ...userFormErrors,
        email: t('form.Please Enter a valid email'),
      });
    else if (email !== confirm_email)
      setUserFormErrors({
        ...userFormErrors,
        confirm_email: t('form.Email does not match'),
      });
    else if (phone.trim().length < 10)
      setUserFormErrors({
        ...userFormErrors,
        phone: t('form.Please enter a valid number'),
      });
    else {
      const res = !isEditing
        ? await resolve(
            api
              .post('/legacy_user/view_create/', JSON.stringify(userObj), {
                headers: {
                  'Content-Type': 'application/json',
                },
              })
              .then((res) => res.data)
          )
        : await resolve(
            api
              .patch(
                `/legacy_user/retrieve_update/${editFormData.id}`,
                JSON.stringify(editFormData),
                {
                  headers: {
                    'Content-Type': 'application/json',
                  },
                }
              )
              .then((res) => res.data)
          );
      if (res.data) {
        setShouldRedirect(true);
        setIsOpen(true);
        setModalData({
          ...modalData,
          title: !isEditing ? t('form.User Added') : t('form.User Updated'),
          bodyText: `${t('form.You have successfully')} ${
            !isEditing ? t('form.added') : t('form.updated')
          } ${t('form.user')}.`,
        });
      }
      if (res.error) {
        setShouldRedirect(false);
        const emailExists = res.error?.response?.data.email;
        const status_code = res.error?.response?.data.status_code;
        setIsOpen(true);
        if (emailExists?.length > 0) {
          setModalData({
            ...modalData,
            title: t('form.Email already exists'),
            bodyText: t('form.User email already exists'),
          });
        }else if (status_code?.length > 0 && status_code === "Your subscription was expired") {
          setModalData({
            ...modalData,
            title: t('form.Subscription Expired'),
            bodyText: t('form.Your subscription was expired'),
          });
        }else if (status_code?.length > 0 && status_code === "maximum_allowed_user_reached") {
          setModalData({
            ...modalData,
            title: t('form.Limit Expired'),
            bodyText: t('form.You have reached the maximum number of allowed users.'),
          });
        } else {
          setModalData({
            ...modalData,
            title: t('form.Something Went wrong'),
            bodyText: t('form.User was not added. Please try again'),
          });
        }
      }
    }
  };
  const saveOrEditSSOSeting   = async (ssoObj) => {
   
   
    const {
      entityId,
      id,
      singleLogoutService,
      singleSignOnService,
      sso_cretificate,
    } = !isEditing ? ssoObj : editFormData;

    for (const key in ssoFormErrors) {
      if (ssoFormErrors.hasOwnProperty(key)) {
        ssoFormErrors[key] = "";
      }
    }
    if (entityId.trim().length < 1)
      setSSOFormErrors({
        ...ssoFormErrors,
        entityId: 'entityId is required.',
      });    
    else if (singleSignOnService.trim().length < 1)
      setSSOFormErrors({
        ...ssoFormErrors,
        singleSignOnService: 'Single Sign On Service is required.',
      });
    else if (singleLogoutService.trim().length < 1)
      setSSOFormErrors({
        ...ssoFormErrors,
        singleLogoutService: 'Single Logout Service is required.',
      });
    else if (sso_cretificate.trim().length < 1)
      setSSOFormErrors({
        ...ssoFormErrors,
        sso_cretificate: 'Certificate is required.',
      });
    else {

      setSSOFormErrors({
        ...ssoFormErrors,
        entityId: '',
        singleSignOnService: '',
        singleLogoutService: '',
        sso_cretificate: '',       
      })
      console.log("ssoObj....",ssoObj);     

      const res = !isEditing
        ? await resolve(
            api
              .post('/legacy_user/sso/detail/', JSON.stringify(ssoObj), {
                headers: {
                  'Content-Type': 'application/json',
                },
              })
              .then((res) => res.data)
          )
        : await resolve(
            api
              .post(
                `/legacy_user/sso/detail/`,
                JSON.stringify(editFormData),
                {
                  headers: {
                    'Content-Type': 'application/json',
                  },
                }
              )
              .then((res) => res.data)
          );
      if (res.data) {
        setShouldRedirect(true);
        setIsOpen(true);
        setModalData({
          ...modalData,
          title: !isEditing ? 'SSO Setting Added' : 'SSO Setting Updated',
          bodyText: `You have successfully ${
            !isEditing ? 'added' : 'updated'
          } SSO Setting.`,
        });
      }
      if (res.error) {
        setShouldRedirect(false);        
        const exception = res.error?.response?.data.exception;       
        setIsOpen(true);
        if (exception?.length > 0) {
          setModalData({
            ...modalData,
            title: 'Something Went wrong',
            bodyText: exception,
          });
        } else {
          setModalData({
            ...modalData,
            title: 'Something Went wrong',
            bodyText: 'SSO Setting was not added. Please try again',
          });
        }
      }
    }
  };
  const saveOrEditReviewer = async (reviewerObj) => {
    const { first_name, email, confirm_email, company_array } = !isEditing
      ? reviewerObj
      : editFormData;
    if (first_name.trim().length < 1)
      setReviewerFormErrors({
        ...reviewerFormErrors,
        name: t('form.Reviewer Name is required.'),
      });
    else if (company_array && company_array.length < 1)
      setReviewerFormErrors({
        ...reviewerFormErrors,
        company_id: t('form.Please select a company.'),
      });
    else if (!validateEmail(email))
      setReviewerFormErrors({
        ...reviewerFormErrors,
        email: t('form.Please Enter a valid email'),
      });
    else if (email !== confirm_email)
      setReviewerFormErrors({
        ...reviewerFormErrors,
        confirm_email: t('form.Email does not match'),
      });
    else {
      const res = !isEditing
        ? await resolve(
            api
              .post(
                '/legacy_user/reviewer/view_create/',
                JSON.stringify(reviewerObj),
                {
                  headers: {
                    'Content-Type': 'application/json',
                  },
                }
              )
              .then((res) => res.data)
          )
        : await resolve(
            api
              .patch(
                `/legacy_user/reviewer/retrieve_update/${editFormData.id}`,
                JSON.stringify(editFormData),
                {
                  headers: {
                    'Content-Type': 'application/json',
                  },
                }
              )
              .then((res) => res.data)
          );
      if (res.data) {
        setShouldRedirect(true);
        setIsOpen(true);
        setModalData({
          ...modalData,
          title: !isEditing ? t('form.Reviewer Added') : t('form.Reviewer Updated'),
          bodyText: `${t('form.You have successfully')} ${
            !isEditing ? t('form.added') : t('form.updated')
          } ${t('form.Reviewer')}.`,
        });
      }
      if (res.error) {
        setShouldRedirect(false);
        const emailExists = res.error?.response?.data.email;
        setIsOpen(true);
        if (emailExists?.length > 0) {
          setModalData({
            ...modalData,
            title: t('form.Email already exists'),
            bodyText: t('form.Reviewer email already exists'),
          });
        } else {
          setModalData({
            ...modalData,
            title: t('form.Something Went wrong'),
            bodyText: t('form.Reviewer was not added. Please try again'),
          });
        }
      }
    }
  };
  const checkReviewerEmailexists = async (email) => {
    setShowSubmitBtn(true);
    if (email.trim().length > 0) {
      const res = await resolve(
        api.get(`/legacy_user/reviewer/check_email/${email}`).then((res) => res.data)
      );
      if (res.error) {
        setIsOpen(true);
        setShowSubmitBtn(false);
        setModalData({
          ...modalData,
          title: t('form.Something Went wrong'),
          bodyText: res.error.response.data.msg,
        });
      }
    }
  };

  const getNotifications = () => {
    const sse = new EventSource(
      `${process.env.REACT_APP_BACKEND_BASE_URL}/notification/stream/${user.user_id}/${totalNotificationCount}`
    );
    function getRealtimeData(data) {
      setNotificationCount(data);
      sse.close();
    }
    sse.onmessage = (e) => getRealtimeData(e.data);
  };
  const saveOrEditContract = async (contractObj) => {
    let {
      name,
      type,
      tags,
      doc_name,
      repo_name,
      renewal_date,
      document,
      repoID,
      checkSumExists,
      checkSumValue,
    } = !isEditing ? contractObj : editFormData;
    tags = !isEditing ? tags.join(',') : tags;
    if (name.trim().length < 1)
      setContractFormErrors({
        ...contractFormErrors,
        name: t('form.Contract Name is required.'),
      });
    else if (type.trim().length < 1)
      setContractFormErrors({
        ...contractFormErrors,
        type: t('form.Please select a type.'),
      });
    // else if (renewal_date.trim().length < 1 || renewal_date === 'Invalid Date')
    //   setContractFormErrors({
    //     ...contractFormErrors,
    //     renewal_date: 'Renewal Date is required.',
    //   });
    else if (!isEditing && !document)
      setContractFormErrors({
        ...contractFormErrors,
        document: t('form.Please select a document.'),
      });
    else if (contractFormErrors.checkSumExists) {
      setContractFormErrors({
        ...contractFormErrors,
        checkSumExists: true,
      });
    } else {
      let formData;
      if (!isEditing) {
        formData = new FormData();
        formData.append('name', name);
        formData.append('type', type);
        formData.append('tags', tags);
        formData.append('doc_name', doc_name);
        formData.append('repo_name', repo_name);
        formData.append('repo', repoID);
        formData.append('renewal_date', renewal_date ? renewal_date : '');
        formData.append('document', document);
        formData.append('md5sum', [checkSumValue]);
      } else {
        let date;
        if (
          (renewal_date && renewal_date === '1970-01-01') ||
          (renewal_date && renewal_date === 'Invalid Date')
        ) {
          date = '';
        } else {
          date = renewal_date;
        }
        formData = new FormData();
        formData.append('name', name);
        formData.append('type', type);
        formData.append('tags', tags);
        formData.append('renewal_date', date);
      }
      setLoading(true);
      const res = !isEditing
        ? await resolve(
            api
              .post('/legacy_contract/view_create/', formData, {
                headers: {
                  'Content-Type': 'application/json',
                },
                onUploadProgress: (e) => {
                  setProgressValue(Math.round((e.loaded / e.total) * 100));
                },
              })
              .then((res) => res.data)
          )
        : await resolve(
            api
              .patch(`/legacy_contract/retrieve_update/${editFormData.id}`, formData, {
                headers: {
                  'Content-Type': 'application/json',
                },
              })
              .then((res) => res.data)
          );
      setLoading(false);
      if (res.data) {
        // getNotifications();
        console.log("675 line")
        setIsOpen(true);
        setModalData({
          ...modalData,
          title: !isEditing ? t('form.Contract Added') : t('form.Contract Updated'),
          bodyText: `${
            !isEditing
              ? t('form.You will receive notification once contract is ready to view')
              : t('form.Contract updated successfully')
          }`,
        });
      }
      if (res.error) {
        setIsOpen(true);
        setModalData({
          ...modalData,
          title: t('form.Something Went wrong'),
          bodyText: t('form.Contract was not added. Please try again'),
        });
      }
      setContractFormErrors({
        name: '',
        type: '',
        renewal_date: '',
        document: '',
        multi_document: '',
        drive_errors: '',
      });
    }
  };

  const handleMultipleContracts = async (
    fileList,
    repoName,
    repoID,
    checkSumValues
  ) => {
    const hasErrors = false;
    if (fileList.length > 0) {
      if (fileList.length > 100) {
        setIsOpen(true);
        setModalData({
          ...modalData,
          title: t('form.Maximum limit reached'),
          bodyText: t('form.Upload a maximum of 100 files'),
        });
        return;
      }
      let formData = new FormData();
      fileList.forEach((file, id) => {
        if (file.exists) {
          hasErrors = true;
        }
        formData.append('doc_name', file.name);
        formData.append('repo_name', repoName);
        formData.append('repo_id', repoID);
        formData.append('document', file.document || file);
        formData.append('md5sum', checkSumValues[id]);
      });
      if (!hasErrors) {
        setLoading(true);
        const res = await resolve(
          api
            .post('/legacy_contract/add_multiple_contract/', formData, {
              headers: {
                'Content-Type': 'application/json',
              },
              onUploadProgress: (e) => {
                setProgressValue(Math.round((e.loaded / e.total) * 100));
              },
            })
            .then((res) => res.data)
        );
        setLoading(false);
        if (res.data) {
          // getNotifications();
          setIsOpen(true);
          setModalData({
            ...modalData,
            title: !isEditing ? t('form.Contracts Added') : t('form.Contracts Updated'),
            bodyText: `${
              !isEditing
                ? t('form.You will receive notification once contracts are ready to view')
                : t('form.Contracts updated successfully')
            }`,
          });
        }

        if (res.error) {
          setIsOpen(true);
          setModalData({
            ...modalData,
            title: t('form.Something Went wrong'),
            bodyText: t('form.Contracts were not added. Please try again'),
          });
        }
      }
    }
  };
  const handleZipUpload = async (fileList, repoName) => {
    const csrftoken = getCookie('csrftoken');
    if (fileList.length > 0) {
      const uploader = new HugeUploader({
        endpoint: `${process.env.REACT_APP_BACKEND_BASE_URL}/legacy_contract/bulk_upload_contracts/${repoName}`,
        file: fileList[0],
        chunkSize: 10,
        headers: {
          Authorization: `Bearer ${authTokens.access}`,
          'X-CSRFToken': csrftoken,
        },
      });

      setLoading(true);
      uploader.on('error', (err) => {
        setLoading(false);
        setIsOpen(true);
        setModalData({
          ...modalData,
          title: t('form.Something Went wrong'),
          bodyText: t('form.Contracts were not added. Please try again'),
        });
        console.error('Something bad happened', err.detail);
      });

      uploader.on('progress', (progress) => {
        setProgressValue(progress.detail);
        console.log(`The upload is at ${progress.detail}%`);
      });

      uploader.on('finish', () => {
        // getNotifications();
        setLoading(false);
        setIsOpen(true);

        setModalData({
          ...modalData,

          title: !isEditing ? t('form.Contracts Added') : t('form.Contracts Updated'),

          bodyText: `${
            !isEditing
              ? t('form.You will receive notification once contracts are ready to view')
              : t('form.Contracts updated successfully')
          }`,
        });
      });
      // let formData = new FormData();
      // fileList.forEach((file, id) => {
      //   formData.append('zip_file', file);
      //   formData.append('repo_name', repoName);
      // });
      // setLoading(true);
      // const res = await resolve(
      //   api
      //     .post('/contract/bulk_upload_contracts/', formData, {
      //       onUploadProgress: (e) => {
      //         setProgressValue(Math.round((e.loaded / e.total) * 100));
      //       },
      //     })
      //     .then((res) => res.data)
      // );
      // setLoading(false);
      // if (res.data) {
      //   setIsOpen(true);
      //   setModalData({
      //     ...modalData,
      //     title: !isEditing ? 'Contracts Added' : 'Contracts Updated',
      //     bodyText: `${
      //       !isEditing
      //         ? 'You will receive notification once contracts are ready to view'
      //         : 'Contracts updated successfully'
      //     }`,
      //   });
      // }
      // if (res.error) {
      //   setIsOpen(true);
      //   setModalData({
      //     ...modalData,
      //     title: 'Something Went wrong',
      //     bodyText: 'Contracts were not added. Please try again',
      //   });
      // }
    }
  };

  const updatePassword = async (passwordObj) => {
    setPasswordFormErrors({ current: '', password: '', confirm: '' });
    const { current, password, confirm, email } = passwordObj;
    if (current.trim().length < 1) {
      setPasswordFormErrors({
        ...passwordFormErrors,
        current: t('form.Please enter your current password.'),
      });
    } else if (!validatePassword(password)) {
      setPasswordFormErrors({
        ...passwordFormErrors,
        password: t('form.Password should contain : 1 Alphabet, 1 Number, 1 Special character, Minimum 8 characters'),
      });
    } else if (confirm !== password) {
      setPasswordFormErrors({
        ...passwordFormErrors,
        confirm: t('form.Password and confirm password are not same.'),
      });
    } else {
      const payload = {
        email,
        old_pass: current,
        new_pass: password,
      };
      const res = await resolve(
        api
          .post('/legacy_user/update_password/', payload, {
            headers: {
              'Content-Type': 'application/json',
            },
          })
          .then((res) => res.data)
      );
      if (res.data) {
        setShouldLogout(true);
        setIsOpen(true);
        setModalData({
          title: t('form.Password updated successfully.'),
          bodyText: t('form.Please login again to continue'),
        });
      } else {
        if (res.error.response.data.code === 'old_password_incorrect') {
          setIsOpen(true);
          setModalData({
            title: t('form.Current password is incorrect.'),
            bodyText: t('form.Please try again'),
          });
        } else {
          setIsOpen(true);
          setModalData({
            title: t('form.Something went wrong'),
            bodyText: t('form.Please try again'),
          });
        }
      }
      setPasswordFormErrors({ current: '', password: '', confirm: '' });
    }
  };

  const saveOrEditLicencing = async (licencingObj) => {
    const { licence_key} = !isEditing? licencingObj: editFormData;
    if (licence_key.trim().length < 1){
      setLicenceFormErrors({
        ...licenceFormErrors,
        licence_key: t('form.licence_key is required.'),
      });
    }else{

      const res = !isEditing
        ? await resolve(
            api
              .post('/legacy_user/licence/key/', JSON.stringify(licencingObj), {
                headers: {
                  'Content-Type': 'application/json',
                },
              })
              .then((res) => res.data)
          )
        : await resolve(
            api
              .post(
                `/legacy_user/licence/key/`,
                JSON.stringify(editFormData),
                {
                  headers: {
                    'Content-Type': 'application/json',
                  },
                }
              )
              .then((res) => res.data)
          );
      if (res.data) {
        setShouldRedirect(true);
        setIsOpen(true);
        setModalData({
          ...modalData,
          title: !isEditing ? 'Licensing Added' : 'Licensing Updated',
          bodyText: `You have successfully ${
            !isEditing ? 'added' : 'updated'
          } Licensing.`,
        });
      }
      if (res.error) {
        setShouldRedirect(false);        
        const exception = res.error?.response?.data.exception;       
        setIsOpen(true);
        if (exception?.length > 0) {
          setModalData({
            ...modalData,
            title: 'Something Went wrong',
            bodyText: exception,
          });
        } else {
          setModalData({
            ...modalData,
            title: 'Something Went wrong',
            bodyText: 'Licensing was not added. Please try again',
          });
        }
      }

      setLicenceFormErrors({
        ...licenceFormErrors,
        licence_key: '',
      })
    }
    


  };

  const contextData = {
    currentForm,
    setCurrentForm,
    saveOrEditCompany,
    companyFormErrors,
    modalData,
    setIsOpen,
    modalIsOpen,
    setModuleToEdit,
    editFormData,
    setEditFormData,
    isEditing,
    setIsEditing,
    setCompanyFormErrors,
    adminFormErrors,
    setAdminFormErrors,
    saveOrEditAdmin,
    saveOrEditUser,
    saveOrEditReviewer,
    setReviewerFormErrors,
    reviewerFormErrors,
    userFormErrors,
    setUserFormErrors,
    saveOrEditContract,
    contractFormErrors,
    setContractFormErrors,
    loading,
    setLoading,
    handleMultipleContracts,
    passwordFormErrors,
    setPasswordFormErrors,
    updatePassword,
    shouldLogout,
    setShouldLogout,
    shouldRedirect,
    checkReviewerEmailexists,
    showSubmitBtn,
    progressValue,
    handleZipUpload,

    saveOrEditSSOSeting,
    ssoFormErrors,
    setSSOFormErrors,

    licenceFormErrors,
    setLicenceFormErrors,
    saveOrEditLicencing,
  };

  return (
    <FormContextlegacy.Provider value={contextData}>{children}</FormContextlegacy.Provider>
  );
};
