import React, { useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import ForgotPasswordForm from './ForgotPasswordForm';
import { appName } from '../../constants/constants';
import './forgotpassword.css';
import Footer from '../footer/Footer';
import appLogo from '../../assets/fusion-logo.png';
import { useTranslation } from 'react-i18next';
import AppContext from '../../context/AppContext';

const ForgotPasswordUI = ({ title, formErrors, setFormErrors, onSubmit }) => {
  const { language, setLanguage, langObj } = useContext(AppContext);
  const { t, i18n } = useTranslation();
  useEffect(() => {
    document.title = `${appName} | ${title}`;
  });
  return (
    <>
      <div className='flex w-full h-screen forgot-password-container'>
        <div className='w-1/3 bg-[#F5F2FA] forgot-password-form-container'>
          <ForgotPasswordForm formErrors={formErrors} setFormErrors={setFormErrors} onSubmit={onSubmit} />
        </div>
        <div className='w-2/3  login-content-container text-primary '>
          <div className='set_btn'>
          <img
            src={appLogo}
            alt='app-logo'
          />
          <div>
          {/* <button className='btn btn_theme3 font-bold' >
          Signup for Qsign
            </button> */}
         {/*  <button className='btn btn_theme font-bold'>
              <a
                href='https://launch.quoqo.com/meetings/chetan12/quoqo-platform-demo-60-minutes'
                target='_blank'
              >
                Request a Demo
              </a>
            </button> */}
            </div>
            </div>

            <div className='login-language-dropdown mt-5'>
                <span className='text-base font-bold mr-2'>{t('common.Language')} :</span>
                <select
                    className='border-2 border-primary rounded-lg outline-none p-0.5 pl-2'
                    onChange={(e) => {
                    i18n.changeLanguage(e.target.value)
                    setLanguage(e.target.value)
                    }}
                    value={language}
                >
                    {Object.keys(langObj).map((lng) => (
                    <option key={lng} value={lng}>{langObj[lng].nativeName}</option>
                    ))}
                </select>
            </div>
         
            <p className='font-normal mt-8'>
                        {t('loginPage.Electronic and Digital Signature solutions and Photo KYC solutions for global enterprises')}.
                    </p>
                    <h1 className='mt-3 text-2xl font-bold'>
                        {t('loginPage.Move away from wet-signatures to the digital age')}
                    </h1>
                    <p className='mt-2 text-medium'>
                        {t('loginPage.QSign enables an organization to move from wet ink to electronic or digital signatures effortlessly')}.
                    </p>

                    <h1 className='mt-3 text-2xl font-bold'>
                        {t('loginPage.Perform Photo KYC effortlessly')}
                    </h1>
                    <p className='mt-2 text-medium'>
                        {t('loginPage.No need for tiresome manual form filling -- use our photo KYC feature to comply with regulatory procedures online, even via a mobile-phone')}.
                    </p>

                    <h1 className='mt-3 text-2xl font-bold'>
                        {t('loginPage.Built-in powerful Workflows Manager')}
                    </h1>
                    <p className='mt-2 text-medium'>
                        {t('loginPage.Use workflows to obtain internal approvals, or external signatures, or send emails or upload and set obligations in Quoqo Legacy or .... the list goes on!')}.
                    </p>

                    <h1 className='mt-3 text-2xl font-bold'>
                        {t('loginPage.Made for Enterprise')}
                    </h1>
                    <p className='mt-2 text-medium'>
                        {t('loginPage.AD to RBAC, we have you covered, completely with widely used enterprise features and integrations for a fuss-free experience. All on secure-cloud with SOC-2 controls in place!')}.
                    </p>
          
        </div>
      </div>
      <Footer />
    </>
  );
};

export default ForgotPasswordUI;
