import React, { useEffect, useRef, useState } from 'react';
import 'sceditor/minified/themes/default.min.css';
import 'sceditor/minified/sceditor.min.js';
import 'sceditor/minified/icons/monocons.js';
import 'sceditor/minified/formats/bbcode.js';

const Test = () => {
    const textareaRef = useRef(null);
    const [documentData, setDocumentData] = useState("");

    useEffect(() => {
        if (textareaRef.current) {
            window.sceditor.create(textareaRef.current, {
                format: 'xhtml',
                icons: 'monocons',
                style: 'minified/themes/content/default.min.css'
            });
        }
    }, []);

    const takeText = () => {
        if (textareaRef.current) {
            const sceditorInstance = window.sceditor.instance(textareaRef.current);
            const content = sceditorInstance.val();
            // console.log("Textarea content:", content);
            setDocumentData(content);
            // console.log(documentData);
        }
    }

    return (
        <><form action="" method="post">
            <div>
                <textarea
                    ref={textareaRef}
                    id="example"
                    style={{ height: '300px', width: '600px' }}
                />
            </div>
        </form>
            <button onClick={takeText}>Abc</button></>
    );
}

export default Test;