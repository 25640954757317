import React, { useState, useEffect, useContext } from 'react';
import dayjs from 'dayjs';
import NotificationsTablelegacy from './NotificationsTablelegacy';
import { notificationTableColumns } from '../../constants/constants';
import useAxios from '../../hooks/useAxios';
import { resolve } from '../../api/resolve';
import NotificationModal from '../modallegacy/NotificationModallegacy';
import AppContext from '../../context/AppContext';
import LoadingScreen from '../loader/LoadingScreen';
import { useTranslation } from 'react-i18next';

const Notificationslegacy = () => {
  const api = useAxios();
  const { t } = useTranslation();
  const { getNotificationsCountlegacy } = useContext(AppContext);
  const [errMsg, setErrMsg] = useState('');
  const [nextPage, setIsNextPage] = useState(null);
  const [prevPage, setIsPrevPage] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [sortingOrder, setSortingOrder] = useState('desc');
  const [sortColumn, setSortColumn] = useState('expiry_at');
  const [notificationslegacy, setNotificationslegacy] = useState([]);
  const [pageLoading, setPageLoading] = useState(true);
  const [modalIsOpen, setIsOpen] = useState(false);
  const [selectedNotificationlegacy, setSelectedNotificationlegacy] = useState([]);
  const getNotificationslegacy = async () => {
    const res = await resolve(
      api
        .get(
          `legacy_notification/upcoming_notifications/?ordering=${sortColumn}&page=${currentPage}&sortingOrder=${sortingOrder}`
        )
        .then((res) => res.data)
    );
    if (res.data) {
      const { results, next, previous } = res.data;
      let filterData = [];
      if (results.length > 0) {
        results.forEach((result) => {
          const contractObj = {
            id: result.id,
            title: result.msg,
            date: dayjs(result.for_date).format('DD MMMM YYYY'),
            read: result.is_seen ? 'Seen' : 'Not Seen',
            contract_details: result.contract_details,
            type: result.type,
          };
          filterData.push(contractObj);
        });
      } else {
        setErrMsg(t('modal.No notifications found'));
      }
      filterData.forEach((item) => {
        if (item.contract_details) {
          item.contract_details.replaceAll('\\', '');
          const parsedObj = JSON.parse(item.contract_details);
          item.contract_details = parsedObj;
        }
      });
      setNotificationslegacy([...filterData]);
      setIsNextPage(next);
      setIsPrevPage(previous);
    }
    setPageLoading(false);
  };

  const updateNotificationSeenlegacy = async (id) => {
    const res = await resolve(
      api.get(`legacy_notification/update_is_seen/${id}`).then((res) => res.data)
    );
    if (res.data) {
      getNotificationsCountlegacy();
      getNotificationslegacy();
    }
  };
  const updateNotificationClearedlegacy = async (id) => {
    const res = await resolve(
      api.get(`legacy_notification/update_is_cleared/${id}`).then((res) => res.data)
    );
    if (res.data) {
      getNotificationsCountlegacy();
      getNotificationslegacy();
    }
  };

  const clearAllNotificationslegacy = async () => {
    setPageLoading(true);
    const res = await resolve(
      api.get(`legacy_notification/clear_all/`).then((res) => res.data)
    );
    if (res.data) {
      getNotificationsCountlegacy();
      getNotificationslegacy();
    }
    setPageLoading(false);
  };
  useEffect(() => {
    getNotificationsCountlegacy();
    getNotificationslegacy();
  }, [currentPage, sortColumn]);
  return (
    <>
      {pageLoading ? (
        <LoadingScreen />
      ) : (
        <div className='text-primary'>
          <h1 className='text-4xl font-bold'>{t('modal.Upcoming Notifications')}</h1>
          {notificationslegacy.length < 1 && (
            <p className='text-red-500 text-sm font-medium my-5'>{errMsg}</p>
          )}
          <NotificationsTablelegacy
            contractsData={notificationslegacy}
            contractTableColumns={notificationTableColumns}
            prevPage={prevPage}
            nextPage={nextPage}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            sortingOrder={sortingOrder}
            setSortingOrder={setSortingOrder}
            setSortColumn={setSortColumn}
            setIsOpen={setIsOpen}
            updateNotificationSeen={updateNotificationSeenlegacy}
            updateNotificationCleared={updateNotificationClearedlegacy}
            setSelectedNotification={setSelectedNotificationlegacy}
            clearAllNotifications={clearAllNotificationslegacy}
          />
          <NotificationModal
            modalIsOpen={modalIsOpen}
            setIsOpen={setIsOpen}
            notifications={notificationslegacy}
            selectedNotification={selectedNotificationlegacy}
          />
        </div>
      )}
    </>
  );
};

export default Notificationslegacy;
