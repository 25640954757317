import React, { useState } from 'react';
import AIIcon from '../../assets/icons/ai-icon.png'; // Update the path accordingly

const ChatbotButton = () => {
  const [showChatbot, setShowChatbot] = useState(false);

  const toggleChatbot = () => {
    setShowChatbot(!showChatbot);
  };

  return (
    <div style={{ textAlign: 'center', marginTop: '20vh' }}>
      <button onClick={toggleChatbot} style={{ cursor: 'pointer' }}>
        <img
          src={AIIcon}
          alt="AI Icon"
          className="w-36"
          style={{ display: 'block', margin: 'auto' }}
        />
      </button>
      <div style={{ marginTop: '20px', fontSize: '16px' }}>
        Click here to make your legal experience quick
      </div>
      {showChatbot && (
        <div style={{ height: '80vh', marginTop: '50px' }}>
          <iframe
            title="Chatbot"
            src="https://azchat-webapp-zkfxedppslmoe-staging.azurewebsites.net/"
            allowFullScreen
            width="100%"
            height="100%"
            frameBorder="0"
            style={{ border: 'none' }} // Remove border if not needed
          ></iframe>
        </div>
      )}
    </div>
  );
};

export default ChatbotButton;
