import React, { useEffect, useContext, useState, useRef } from "react";
import ReactTooltip from "react-tooltip";
import LoadingScreen from "../loader/LoadingScreen";
import "../forms/companyForm.css";
import FormContext from "../../context/FormContext";
import AppModal from "../modals/Modal";
import "react-tagsinput/react-tagsinput.css";
import HelpIcon from "../../assets/icons/Help-sm.svg";
import LegalWorkflowFile from "../../assets/LegalWorkflow.csv";

import Papa from "papaparse";
import useAxios from "../../hooks/useAxios";
import { useNavigate } from "react-router-dom";
import { use } from "i18next";

const ClauseLibaryForm = () => {
  const api = useAxios();
  const navigate = useNavigate();

  const {
    setCurrentForm,
    ClauseLibaryFormErrors,
    modalData,
    setIsOpen,
    modalIsOpen,
    isEditing,
    editFormData,
    setEditFormData,
    setIsEditing,
    saveOrEditClause,
    loading,
    progressValue,
  } = useContext(FormContext);

  const [inputs, setInputs] = useState([""]);
  const [companyList, setCompanyList] = useState([]);

  useEffect(() => {
    const list = companyList;
  }, [companyList]);

  const fetchCompany = async () => {
    try {
      const res = await api.get(
        `${process.env.REACT_APP_BACKEND_BASE_URL}/company/all/`
      );
      if (res.data) {
        setCompanyList(res.data.results);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const initializeInputs = (data) => {
    if (Array.isArray(data)) {
      return data;
    } else if (typeof data === "string") {
      try {
        const parsedData = JSON.parse(data.replace(/'/g, '"'));
        return Array.isArray(parsedData) ? parsedData : [parsedData];
      } catch (error) {
        console.error("Failed to parse clause_text", error);
        return [data];
      }
    } else {
      return [""];
    }
  };

  useEffect(() => {
    fetchCompany();
    if (isEditing) {
      setInputs(initializeInputs(editFormData?.clause_text));
    } else {
      setInputs([""]);
      setClauseLibaryForm({
        id: "",
        clause_name: "",
        clause_text: "",
        company_id: "",
      });
    }
  }, []);

  const handleAddInput = () => {
    setInputs([...inputs, ""]); // Add a new empty input
  };

  const handleInputChange = (index, value) => {
    const newInputs = [...inputs];
    newInputs[index] = value;
    setInputs(newInputs);
  };

  const [ClauseLibaryForm, setClauseLibaryForm] = useState({
    id: "",
    clause_name: "",
    clause_text: "",
    company_id: "",
  });
  const fileInput = useRef();
  const [responceMsg, setResponceMsg] = useState({
    msg: "",
    toast: "",
  });
  const [open, setOpen] = useState(false);
  const [value, setValue] = useState(null);
  const [error, setError] = useState(null);

  const handleFileChange = async (e) => {
    const { files } = e.target;
    setValue(files[0]);
    Papa.parse(files[0], {
      header: true,
      skipEmptyLines: true,
      complete: async function (results) {},
    });
  };

  const deleteFile = (e) => {
    fileInput.current.value = "";
    setValue(null);
  };

  const bulkUploadDocument = () => {
    let formData = new FormData();
    formData.append("file", value);

    api
      .post(
        `${process.env.REACT_APP_BACKEND_BASE_URL}/user/qflow/template/bulk_upload/`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      )
      .then((resp) => {
        setResponceMsg({
          msg: resp.data.message,
          toast: "success",
        });
        setOpen(true);
        setTimeout(() => {
          // setActiveLink('ContactBook');
        }, 2000);
      })
      .catch((err) => {
        err.response.data.messages
          ? setResponceMsg({
              msg: err.response.data.messages[0].message,
              toast: "error",
            })
          : setResponceMsg({
              msg: err.response.data.message,
              toast: "error",
            });
        setOpen(true);
        if (!err.response.data.message) {
          setTimeout(() => {
            navigate("/login");
          }, 2000);
        }
      });
  };

  if (loading) {
    return <LoadingScreen />;
  }

  return (
    <div className="text-primary text-base font-normal font-sans upload_doc_page">
      <div className="main_head">
        <h1 className="text-4xl font-bold">
          {!isEditing ? "Add Clause" : "Edit Clause"}
        </h1>
      </div>

      <div className=" flex w-full items-center h-full ">
        <form className="w-2/5">
          <div className="flex flex-col mb-3">
            <div className="flex">
              <label className="mb-2">Clause Name</label>
              <>
                <img
                  src={HelpIcon}
                  width={12}
                  className="ml-1 -mt-2 cursor-pointer"
                  data-tip="Insert the name of the clause"
                />
                <ReactTooltip backgroundColor="#400835" textColor="#ffffff" />
              </>
            </div>
            <input
              placeholder="Clause name"
              className="border-2 border-primary rounded-lg outline-none p-1 pl-2"
              value={
                isEditing
                  ? editFormData?.clause_name
                  : ClauseLibaryForm?.clause_name
              }
              onChange={(e) => {
                const newValue = e.target.value;
                setClauseLibaryForm({
                  ...ClauseLibaryForm,
                  clause_name: newValue,
                });
                setEditFormData({ ...editFormData, clause_name: newValue });
              }}
            />
            <p className="text-sm text-red-500 font-normal">
              {ClauseLibaryFormErrors?.clause_name}
            </p>
          </div>
          <div className="flex flex-col mb-3">
            <div className="flex mb-2">
              <label className="mb-2">Clause Description</label>
              <>
                <img
                  src={HelpIcon}
                  width={12}
                  className="ml-1 -mt-2 cursor-pointer"
                  data-tip="Insert Clause Description"
                />
                <ReactTooltip backgroundColor="#400835" textColor="#ffffff" />
              </>
            </div>
            <div className="flex flex-col">
              {inputs.map((input, index) => (
                <div key={index} className="flex items-center mb-2">
                  <input
                    placeholder="Insert Clause Description"
                    className="border-2 border-primary rounded-lg outline-none p-1 pl-2"
                    value={input}
                    style={{ width: "100%" }}
                    onChange={(e) => handleInputChange(index, e.target.value)}
                  />
                </div>
              ))}
              <div className="flex items-center">
                <button
                  type="button"
                  onClick={handleAddInput}
                  className="bg-secondary font-bold text-white w-10 p-1 rounded-lg"
                  data-tip="Add new clause description"
                >
                  +
                </button>
              </div>
              <p className="text-sm text-red-500 font-normal">
                {ClauseLibaryFormErrors?.clause_text}
              </p>
            </div>
          </div>

          <div className="flex flex-col mb-3">
            <div className="flex">
              <label className="mb-2">Company</label>
              <>
                <img
                  src={HelpIcon}
                  width={12}
                  className="ml-1 -mt-2 cursor-pointer"
                  data-tip="Insert Company"
                />
                <ReactTooltip backgroundColor="#400835" textColor="#ffffff" />
              </>
            </div>
            <input
              placeholder="Company Id"
              className="border-2 border-primary rounded-lg outline-none p-1 pl-2"
              value={
                isEditing
                  ? editFormData?.company_id
                  : ClauseLibaryForm?.company_id
              }
              onChange={(e) => {
                const newValue = e.target.value;
                setClauseLibaryForm({
                  ...ClauseLibaryForm,
                  company_id: newValue,
                });
                setEditFormData({
                  ...editFormData,
                  company_id: newValue,
                });
              }}
            />
            {/* <select
              className="border-2 border-primary rounded-lg outline-none p-1 pl-2"
              value={
                !isEditing
                  ? LegalDocumentForm.company_id
                  : editFormData.company_id
              }
              onChange={(e) => {
                const selectedCompanyId = e.target.value;
                !isEditing
                  ? setLegalDocumentForm({
                      ...LegalDocumentForm,
                      company_id: selectedCompanyId,
                    })
                  : setEditFormData({
                      ...editFormData,
                      company_id: selectedCompanyId,
                    });
              }}
            >
              <option value="">Select Company</option>
              {companyList.map((company) => (
                <option key={company.id} value={company.id}>
                  {company.name}
                </option>
              ))}
            </select> */}
            <p className="text-sm text-red-500 font-normal">
              {ClauseLibaryFormErrors?.company_id}
            </p>
          </div>

          {!isEditing ? (
            <>
              <button
                className="bg-primary font-bold text-white w-full p-1 rounded-lg mt-3"
                onClick={(e) => {
                  e.preventDefault();
                  const filteredInputs = inputs.filter(
                    (input) => input.trim() !== ""
                  );
                  const updatedClauseLibaryForm = {
                    ...ClauseLibaryForm,
                    clause_text: filteredInputs,
                  };
                  saveOrEditClause(updatedClauseLibaryForm);
                }}
              >
                Add Clause
              </button>
              <p className="text-sm text-red-500 font-normal">
                {ClauseLibaryFormErrors?.document}
              </p>
            </>
          ) : (
            <button
              className="bg-primary font-bold text-white w-full p-1 rounded-lg mt-3"
              onClick={(e) => {
                e.preventDefault();
                const filteredInputs = inputs.filter(
                  (input) => input.trim() !== ""
                );
                const updatedEditFormData = {
                  ...editFormData,
                  clause_text: filteredInputs,
                };
                setEditFormData(updatedEditFormData);
                saveOrEditClause(updatedEditFormData);
              }}
            >
              Update Clause
            </button>
          )}
        </form>

        {/* {!isEditing && (
          <>
            <div className=" ml-12  w-2/5 flex items-center">
              <div
                className="bodder mb-4"
                style={{
                  height: "300px",
                  width: 5,
                  background: "#400835",
                  borderRadius: 10,
                }}
              ></div>
              <div className="w-full flex contact-book-right-btn">
                <a
                  className="bg-secondary font-bold text-white p-1 px-2 rounded-lg mb-3"
                  href={LegalWorkflowFile}
                >
                  Download Template
                </a>

                <p className="text-sm text-red-500 font-normal ml-5 mt-3">
                  {ClauseLibaryFormErrors?.multi_document}
                </p>

                <button
                  className="bg-secondary font-bold text-white ml-2 p-1 px-2 rounded-lg contact-book-right-btn mb-3"
                  onClick={() => fileInput.current.click()}
                >
                  Bulk Upload Document
                </button>
                <input
                  id="files"
                  style={{ display: "none" }}
                  type="file"
                  ref={fileInput}
                  onChange={handleFileChange}
                  accept=".csv"
                />
                <p className="text-sm text-red-500 font-normal ml-5 mt-3">
                  {ClauseLibaryFormErrors?.multi_document}
                </p>
              </div>
            </div>

            <div className=" ml-5  w-2/5">
              {open && <div>{responceMsg.msg}</div>}

              {value !== null && error == null && (
                <>
                  {value?.name}

                  <button
                    onClick={deleteFile}
                    className="btn btn_theme2 btn-sm ml-3 mb-3"
                  >
                    delete
                  </button>
                  <button
                    className="btn btn_theme btn-sm ml-3 mb-3"
                    onClick={bulkUploadDocument}
                  >
                    Save Document
                  </button>
                </>
              )}
            </div>
          </>
        )} */}
      </div>

      <AppModal
        title={modalData.title}
        bodyText={modalData.bodyText}
        setIsOpen={setIsOpen}
        modalIsOpen={modalIsOpen}
        modelAction="ClauseLibaryForm"
        apiResStatus={modalData.apiResStatus}
      />
    </div>
  );
};

export default ClauseLibaryForm;
