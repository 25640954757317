import React, { useEffect, useContext, useState } from 'react';
import Switch from 'react-switch';
import Select from 'react-select';
import ReactTooltip from 'react-tooltip';
import { customStyles } from '../../constants/styles';
import FormContext from '../../context/FormContext';
import AppContext from '../../context/AppContext';
import AppModal from '../modallegacy/Modallegacy';
import { resolve } from '../../api/resolve';
import useAxios from '../../hooks/useAxios';
import HelpIcon from '../../assets/icons/Help-sm.svg';
import { useTranslation } from 'react-i18next';

const ReviewerFormlegacy = () => {
  const api = useAxios();
  const { t } = useTranslation();
  const {
    setCurrentForm,
    saveOrEditReviewerlegacy,
    modalData,
    setIsOpen,
    modalIsOpen,
    isEditing,
    editFormData,
    setEditFormData,
    setIsEditing,
    reviewerFormErrors,
    setReviewerFormErrors,
    shouldRedirect,
    showSubmitBtn,
    checkReviewerEmailexists,
  } = useContext(FormContext);
  const { setActiveLink, companiesList } = useContext(AppContext);
  const [showCompanies, setShowCompanies] = useState(true);
  const [selectOptions, setSelectOptions] = useState([]);
  const [userCompanies, setUserCompanies] = useState([]);
  const [loading, setLoading] = useState(false);
  const [reviewerObj, setReviewerObj] = useState({
    first_name: '',
    company_array: selectOptions,
    email: '',
    confirm_email: '',
  });
  const [isActiveUser, setIsActiveUser] = useState(
    editFormData?.is_active_user
  );
  const getUserCompanies = async (id) => {
    setLoading(true);
    const res = await resolve(
      api.get(`/reviewer/get_assigned_companies/${id}`).then((res) => res.data)
    );
    if (res.data) {
      const filterData = [];
      if (res.data && res.data.company_list.length > 0) {
        res.data.company_list.forEach((result) => {
          const contractObj = {
            value: result.name,
            label: result.name,
            id: result.id,
          };
          filterData.push(contractObj);
        });
      }
      setUserCompanies([...filterData]);
    }
    setLoading(false);
  };
  useEffect(() => {
    if (isEditing) {
      getUserCompanies(editFormData.id);
    }
    setReviewerFormErrors({
      ...reviewerFormErrors,
      name: '',
      company_id: '',
      email: '',
      confirm_email: '',
      phone: '',
    });
    setCurrentForm('ReviewerForm');
    return () => setCurrentForm('');
  }, []);
  useEffect(() => {
    return () => {
      if (isEditing) {
        setEditFormData('');
        setIsEditing(false);
      }
    };
  }, [isEditing]);
  useEffect(() => {
    if (isEditing) {
      setEditFormData({
        ...editFormData,
        is_active_user: isActiveUser,
      });
    }
  }, [isActiveUser]);
  return (
    <div className='text-primary text-base font-normal font-sans'>
      <h1 className='text-4xl font-bold'>
        {!isEditing ? t('form.Add Reviewer') : t('form.Edit Reviewer')}
      </h1>
      <div className=' w-2/5 mt-10'>
        <form>
          <div className='flex flex-col mb-3'>
            <label>{t('form.Name')}</label>
            <input
              placeholder={t('form.Insert user full name')}
              className='border-2 border-primary rounded-lg outline-none p-1 pl-2'
              value={
                !isEditing ? reviewerObj.first_name : editFormData.first_name
              }
              onChange={(e) => {
                !isEditing
                  ? setReviewerObj({
                      ...reviewerObj,
                      first_name: e.target.value,
                    })
                  : setEditFormData({
                      ...editFormData,
                      first_name: e.target.value,
                    });
              }}
            />
            <p className='text-sm text-red-500 font-normal'>
              {reviewerFormErrors.name}
            </p>
          </div>
          {!loading && (
            <div className='mb-3'>
              <div className='flex'>
                <label className='mb-1'>{t('form.Select Companies')}</label>
                <>
                  <img
                    src={HelpIcon}
                    width={12}
                    className='ml-1 -mt-1 cursor-pointer'
                    data-tip={t('form.Reviewers can access only selected companies contracts')}
                  />
                  <ReactTooltip backgroundColor='#400835' textColor='#ffffff' />
                </>
              </div>
              <Select
                options={
                  showCompanies
                    ? [{ value: 'All', label: 'All' }, ...companiesList]
                    : [{ value: 'All', label: 'All' }]
                }
                value={!isEditing ? selectOptions : userCompanies}
                isMulti
                placeholder='Select Company name'
                styles={customStyles}
                isSearchable={true}
                onChange={(companyArr) => {
                  setShowCompanies(true);
                  const checkIfAllExists = companyArr.filter(
                    (items) => items.value === 'All'
                  );
                  if (checkIfAllExists.length > 0) {
                    setShowCompanies(false);
                    if (isEditing) {
                      setUserCompanies([{ value: 'All', label: 'All' }]);
                      setEditFormData({
                        ...editFormData,
                        company_array: ['all'],
                      });
                    } else {
                      setSelectOptions([{ value: 'All', label: 'All' }]);
                      setReviewerObj({
                        ...reviewerObj,
                        company_array: ['all'],
                      });
                    }
                  } else {
                    if (isEditing) {
                      setUserCompanies(companyArr);
                      setEditFormData({
                        ...editFormData,
                        company_array: companyArr.map((item) => item.id),
                      });
                    } else {
                      setSelectOptions(companyArr);
                      setReviewerObj({
                        ...reviewerObj,
                        company_array: companyArr.map((item) => item.id),
                      });
                    }
                  }
                }}
                className='w-full text-primary rounded-lg'
              />
              <p className='text-sm text-red-500 font-normal'>
                {reviewerFormErrors.company_id}
              </p>
            </div>
          )}
          <div className='flex flex-col mb-3'>
            <label>{t('form.Email')}</label>
            <input
              placeholder={t('form.Insert an unique user email')}
              type='email'
              className='border-2 border-primary rounded-lg outline-none p-1 pl-2'
              value={!isEditing ? reviewerObj.email : editFormData.email}
              onChange={(e) => {
                !isEditing
                  ? setReviewerObj({ ...reviewerObj, email: e.target.value })
                  : setEditFormData({
                      ...editFormData,
                      email: e.target.value,
                    });
              }}
              onBlur={(e) => checkReviewerEmailexists(e.target.value)}
            />
            <p className='text-sm text-red-500 font-normal'>
              {reviewerFormErrors.email}
            </p>
          </div>
          <div className='flex flex-col mb-3'>
            <label>{t('form.Confirm Email')}</label>
            <input
              placeholder={t('form.Confirm user email')}
              type='email'
              className='border-2 border-primary rounded-lg outline-none p-1 pl-2'
              value={
                !isEditing
                  ? reviewerObj.confirm_email
                  : editFormData.confirm_email
              }
              onChange={(e) => {
                !isEditing
                  ? setReviewerObj({
                      ...reviewerObj,
                      confirm_email: e.target.value,
                    })
                  : setEditFormData({
                      ...editFormData,
                      confirm_email: e.target.value,
                    });
              }}
              onPasteCapture={(e) => e.preventDefault()}
            />
            <p className='text-sm text-red-500 font-normal'>
              {reviewerFormErrors.confirm_email}
            </p>
          </div>
          {isEditing && (
            <div className='flex justify-between items-center mb-3'>
              <span>
                {t('form.Status')} :{' '}
                <span
                  className={`${
                    isActiveUser ? 'text-primary' : 'text-red-500'
                  } font-medium`}
                >
                  {isActiveUser ? 'Active' : 'In-Active'}
                </span>
              </span>
              <Switch
                offColor='#E0720C'
                onColor='#400835'
                name='Active'
                checked={isActiveUser}
                height={20}
                boxShadow='none'
                onChange={() => setIsActiveUser(!isActiveUser)}
              />
            </div>
          )}

          {!isEditing ? (
            <button
              className={`bg-primary font-bold text-white w-full p-1 rounded-lg ${
                !showSubmitBtn && 'bg-gray-600'
              }`}
              onClick={(e) => {
                e.preventDefault();
                saveOrEditReviewerlegacy(reviewerObj);
              }}
              disabled={!showSubmitBtn}
            >
              {t('form.Save Reviewer')}
            </button>
          ) : (
            <button
              className={`bg-secondary font-bold text-white w-full p-1 rounded-lg ${
                !showSubmitBtn && 'bg-gray-600'
              }`}
              onClick={(e) => {
                e.preventDefault();
                saveOrEditReviewerlegacy(editFormData);
              }}
              disabled={!showSubmitBtn}
            >
              {t('form.Update Reviewer')}
            </button>
          )}
        </form>
      </div>
      <AppModal
        title={modalData.title}
        bodyText={modalData.bodyText}
        setIsOpen={setIsOpen}
        modalIsOpen={modalIsOpen}
        setActiveLink={setActiveLink}
        activeLink={showSubmitBtn && shouldRedirect && 'Reviewers'}
      />
    </div>
  );
};

export default ReviewerFormlegacy;
