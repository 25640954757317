import React, { useContext, useEffect, useState } from 'react';
import './notifications.css';
import AppContext from '../../context/AppContext';
import Bell from '../../assets/icons/Bell.svg';
import { Link, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const BellComponent = () => {
  const { setActiveLink, notificationCount, getNotificationsCount, setShowDropdown } =
    useContext(AppContext);
  useEffect(() => {
    getNotificationsCount();
  }, [notificationCount]);
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <div className='bell-notifications-container'>
      <button
        className='flex btn btn_theme btn-sm'
        onClick={() => {
          navigate('/account/notification')
          setActiveLink('Notifications')
          
          // setShowDropdown('')
        }}
      >
        <span>{t('menu.Notifications')}</span>
        <span className='ml-2 relative'>
          <img src={Bell} alt='Bell-icon' className="Bell_icoon" />
          {notificationCount > 0 && (
            <span
              className='not_bar'
              style={{

              }}
            >
              {notificationCount}
            </span>
          )}
        </span>
      </button>
    </div>
  );
};

export default BellComponent;
