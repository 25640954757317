import React, { useEffect, useState, useContext } from 'react';
import Search from '../../assets/icons/Search.svg';
import CloseIcon from '../../assets/icons/Close.svg';
import { adminListTableColumns } from '../../constants/constants';
import useAxios from '../../hooks/useAxios';
import { resolve } from '../../api/resolve';
import AddButton from '../../shared/AddButton';
import AdminListTable from './AdminListTable';
import { parseISO, format } from 'date-fns'

const AdminList = () => {

    const api = useAxios();
    // pending doc related states
    const [userList, setUserList] = useState([]);
    const [userSearchText, setUserSearchText] = useState('');
    const [userCurrentPage, setUserCurrentPage] = useState(1);
    const [userPageLimit, setUserPageLimit] = useState(5);
    const [userNextBtnLimit, setUserNextBtnLimit] = useState(0);
    const [userCount, setUserCount] = useState(0);
    const [errMsgUser, setErrMsgUser] = useState('');
    const [showUserClearIcon, setShowUserClearIcon] = useState(false);
    const [sortingOrder, setSortingOrder] = useState('desc');
    const [sortColumn, setSortColumn] = useState('id');



    const getAdminList = async (clearText) => {
        const res = await resolve(api.get(`user/admin/list/?ordering=${sortColumn}&sortingOrder=${sortingOrder}&search=${clearText ? '' : userSearchText}&page=${userCurrentPage}&limit=${userPageLimit}`).then((res) => res.data));
        if (res.data) {
            const { data } = res;
            const { results, count } = data;          
            const filterData = [];
            if (results.length > 0) {
                setUserCount(count);
                results.forEach((result) => {
                    const userObj = {
                        id: result.id,
                        first_name: result.first_name,
                        email: result.email,
                        phone: result.phone,
                        document_status: result.document_status == 0 ? 'Not uploaded yet' :
                            result.document_status == 1 ? 'Review' :
                                result.document_status == 2 ? 'Accepted' : 'Rejected',
                        
                        plan_name: result.plan_name,
                        paid_amount: result.paid_amount,
                        valid_till: format(parseISO(result.valid_till), 'ccc, dd MMM yyyy, HH:mm'),
                    };
                    filterData.push(userObj);
                });
            } else {
                if (userCurrentPage === 1) setErrMsgUser('No admin added yet');
                else setErrMsgUser('No more admin to view');
            }
            setUserList([...filterData]);
            setUserNextBtnLimit(Math.ceil(count / userPageLimit))
        } else {
            setUserNextBtnLimit(0)
        }
    };

    useEffect(() => {
        getAdminList();
    }, [sortColumn, sortingOrder, userCurrentPage, userPageLimit])

    return (
        <>
            <div className='text-primary'>
                <div className="main_head">
                    <h1 className='text-4xl font-bold'>List of Admins</h1>
                </div>

                <div className="tab_main">
                    <div className={`tab_us show`}>
                        <div className="row justify-between">
                            <div className="col-md-5 flex_contact">
                                <form>
                                    <div className='flex mt-3  w-full'>

                                        <div className='flex justify-between items-center border-2 border-primary rounded-md rounded-tl-lg rounded-bl-lg bg-inputBackground px-2 mr-2 w-full '>
                                            <input
                                                placeholder='Search for admin'
                                                className='px-1 py-1 focus:outline-none bg-transparent w-11/12'
                                                value={userSearchText}
                                                onChange={(e) => {
                                                    setUserSearchText(e.target.value);
                                                    setShowUserClearIcon(true);
                                                    if (!e.target.value.trim()) {
                                                        setShowUserClearIcon(false);
                                                    }
                                                }}
                                            />
                                            {showUserClearIcon && (
                                                <img
                                                    src={CloseIcon}
                                                    width={14}
                                                    className='cursor-pointer'
                                                    onClick={() => {
                                                        setUserSearchText('');
                                                        getAdminList(true);
                                                        setShowUserClearIcon(false);
                                                    }}
                                                />
                                            )}
                                        </div>
                                        <button
                                            type='submit'
                                            className='flex btn btn_theme2 w_125 items-center justify-between'
                                            onClick={(e) => {
                                                e.preventDefault();
                                                setUserCurrentPage(1)
                                                getAdminList();
                                            }}
                                        >
                                            <img src={Search} style={{ width: '1rem' }} />
                                            <span className='font-semibold ml-1'>Search</span>
                                        </button>
                                    </div>
                                </form>
                            </div>

                        </div>
                        <div className='mt-3 rounded-lg'>
                            {userList.length < 1 && (
                                <p className='text-red-500 text-sm font-medium'>{errMsgUser}</p>
                            )}
                        </div>
                        <AdminListTable
                            tableColumns={adminListTableColumns}
                            getListData={getAdminList}
                            tableData={userList}
                            showActionBtn
                            nextBtnLimit={userNextBtnLimit}
                            currentPage={userCurrentPage}
                            setCurrentPage={setUserCurrentPage}
                            itemCount={userCount}
                            setPageLimit={setUserPageLimit}
                            showPageLimit={true}
                            sortingOrder={sortingOrder}
                            setSortingOrder={setSortingOrder}
                            setSortColumn={setSortColumn}
                        />


                    </div>

                </div>
            </div>
        </>
    )
}

export default AdminList