import React, { useEffect, useContext, useState, useRef } from 'react';
import ReactTooltip from 'react-tooltip';
import LoadingScreen from '../loader/LoadingScreen';
import '../forms/companyForm.css';
import FormContext from '../../context/FormContext';
import AppModal from '../modals/Modal';
import 'react-tagsinput/react-tagsinput.css';
import HelpIcon from '../../assets/icons/Help-sm.svg';
import UsersFile from '../../assets/Users.csv'
import PermissionContext from '../../context/PermissionContext';
import Papa from 'papaparse';
import useAxios from '../../hooks/useAxios';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import AppContext from '../../context/AppContext';

const AdminUserForm = () => {
    const api = useAxios();
    const navigate = useNavigate();

    const {
        userFormErrors,
        modalData,
        setIsOpen,
        modalIsOpen,
        isEditing,
        editFormData,
        setEditFormData,
        saveOrEditUser,
        loading,
        contactFormErrors,

    } = useContext(FormContext);

    const { updatePermissionList } = useContext(PermissionContext);
    const { activeLink } = useContext(AppContext);  
    const { t } = useTranslation();
    const [userObj, setUserObj] = useState({
        name: '',
        designation: '',
        email: '',
    });
    const fileInput = useRef();
    const [responceMsg, setResponceMsg] = useState({
        msg: '',
        toast: '',
    });
    const [open, setOpen] = useState(false);
    const [value, setValue] = useState(null);
    const [error, setError] = useState(null);
    const [errorContactList, setErrorContactList] = useState([]);

    const handleFileChange = async (e) => {
        const { files } = e.target;
        if (files && files.length > 0) {
            setError(null);
            setValue(files[0]);
            // setErrorContactList([]);
            Papa.parse(files[0], {
                header: true,
                skipEmptyLines: true,
                complete: async function (results) {

                },

            });
        }

    };

    const deleteFile = (e) => {
        fileInput.current.value = '';
        setValue(null);
    };

    const bulkUploadUsers = () => {

        let formData = new FormData();
        formData.append("file", value);

        api
            .post(
                `${process.env.REACT_APP_BACKEND_BASE_URL}/user/user/bulk_upload/`,
                formData,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                }
            )
            .then((resp) => {

                if(resp?.data?.message){
                    toast.success(resp?.data?.message);
                }else{
                    toast.error(t('common.Something went wrong'));
                }                

                // updatePermissionList(resp['data']['user_permission'][0]);
                if (resp.data.records && resp.data.records.length > 0) {
                    setErrorContactList(resp.data.records);
                  }                
                setValue(null);
                setError(null);
                fileInput.current.value = '';
            })
            .catch((err) => {
                setValue(null);
                setError(null);
                fileInput.current.value = '';

                if(err.response.data.messages){
                    toast.error(err.response.data.messages[0].message);
                }else{
                    toast.error(err.response.data.message);
                }
                setOpen(true);
                if (!err.response.data.message) {
                    setTimeout(() => {
                        navigate('/login');
                    }, 2000);
                }
            });
    };

    if (loading) {
        return <LoadingScreen />;
    }

    return (
        <div className='text-primary text-base font-normal font-sans upload_doc_page'>
            <div className="main_head">
                <h1 className='text-4xl font-bold'>
                    {!isEditing ? t('form.Add User') : t('form.Edit User')}
                </h1>
            </div>

            <div className=' flex w-full items-center h-full '>
                <form className='w-2/5'>
                    <div className='flex flex-col mb-3 contract-type'>
                        <div className='flex'>
                            <label className='mb-2'>{t('form.Name')}</label>
                            <>
                                <img
                                    src={HelpIcon}
                                    width={12}
                                    className='ml-1 -mt-2 cursor-pointer'
                                    data-tip={t('form.Insert full name')}
                                />
                                <ReactTooltip backgroundColor='#400835' textColor='#ffffff' />
                            </>
                        </div>
                        <input
                            placeholder={t('form.Insert full name')}
                            className='border-2 border-primary rounded-lg outline-none p-1 pl-2'
                            value={!isEditing ? userObj.name : editFormData.name}
                            onChange={(e) => {
                                !isEditing
                                    ? setUserObj({ ...userObj, name: e.target.value })
                                    : setEditFormData({
                                        ...editFormData,
                                        name: e.target.value,
                                    });
                            }}
                        />
                        <p className='text-sm text-red-500 font-normal'>
                            {userFormErrors.name}
                        </p>
                    </div>

                    <div className='flex flex-col mb-3'>
                        <div className='flex'>
                            <label className='mb-2'>{t('form.Designation')}</label>
                            <>
                                <img
                                    src={HelpIcon}
                                    width={12}
                                    className='ml-1 -mt-2 cursor-pointer'
                                    data-tip={t('form.Designation')}
                                />
                                <ReactTooltip backgroundColor='#400835' textColor='#ffffff' />
                            </>
                        </div>
                        <input
                            placeholder={t('form.Designation')}
                            className='border-2 border-primary rounded-lg outline-none p-1 pl-2'
                            value={!isEditing ? userObj.designation : editFormData.designation}
                            onChange={(e) => {
                                !isEditing
                                    ? setUserObj({ ...userObj, designation: e.target.value })
                                    : setEditFormData({
                                        ...editFormData,
                                        designation: e.target.value,
                                    });
                            }}
                        />
                        <p className='text-sm text-red-500 font-normal'>
                            {userFormErrors.designation}
                        </p>
                    </div>

                    <div className='flex flex-col mb-3'>
                        <div className='flex'>
                            <label className='mb-2'>{t('form.Email')}</label>
                            <>
                                <img
                                    src={HelpIcon}
                                    width={12}
                                    className='ml-1 -mt-2 cursor-pointer'
                                    data-tip={t('form.Insert an unique email address')}
                                />
                                <ReactTooltip backgroundColor='#400835' textColor='#ffffff' />
                            </>
                        </div>
                        <input
                            placeholder={t('form.Insert an unique email address')}
                            className='border-2 border-primary rounded-lg outline-none p-1 pl-2'
                            value={!isEditing ? userObj.email : editFormData.email}
                            onChange={(e) => {
                                !isEditing
                                    ? setUserObj({ ...userObj, email: e.target.value })
                                    : setEditFormData({
                                        ...editFormData,
                                        email: e.target.value,
                                    });
                            }}
                        />
                        <p className='text-sm text-red-500 font-normal'>
                            {userFormErrors.email}
                        </p>
                    </div>

                    {!isEditing ? (
                        <>
                            <button
                                className='bg-primary font-bold text-white w-full p-1 rounded-lg mt-3'
                                onClick={(e) => {
                                    e.preventDefault();
                                    saveOrEditUser(userObj, updatePermissionList);
                                }}
                            >
                                {t('form.Add User')}
                            </button>
                            <p className='text-sm text-red-500 font-normal'>
                                {userFormErrors.document}
                            </p>
                        </>
                    ) : (
                        <button
                            className='bg-secondary font-bold text-white w-full p-1 rounded-lg'
                            onClick={(e) => {
                                e.preventDefault();
                                saveOrEditUser(editFormData, updatePermissionList);
                            }}
                        >
                            {t('form.Update User')}
                        </button>
                    )}
                </form>

                {!isEditing && (
                    <>
                        <div className=' ml-12  w-2/5 flex items-center wrap_uys'>
                            <div
                                className='bodder mb-4 pos_uu1'
                                style={{
                                    height: '300px',
                                    width: 5,
                                    background: '#400835',
                                    borderRadius: 10,
                                }}
                            ></div>
                            <div className='w-full flex pd_md1d' >
                                <a className='bg-secondary font-bold text-white p-1 px-2 rounded-lg mb-3' href={UsersFile} download='user_sample.csv' >Download Template</a>

                                <p className='text-sm text-red-500 font-normal ml-5 mt-3'>
                                    {contactFormErrors.multi_document}
                                </p>

                                <button
                                    className='bg-secondary font-bold text-white ml-2 p-1 px-2 rounded-lg contact-book-right-btn mb-3'
                                    onClick={() => fileInput.current.click()}
                                >
                                    {t('form.Bulk Upload Users')}
                                </button>
                                <input
                                    id='files'
                                    style={{ display: 'none' }}
                                    type='file'
                                    ref={fileInput}
                                    onChange={handleFileChange}
                                    accept='.csv'
                                />
                                <p className='text-sm text-red-500 font-normal ml-5 mt-3'>
                                    {contactFormErrors.multi_document}
                                </p>
                            </div>
                            {errorContactList && errorContactList.length > 0 && (
                                <div className="pd_md1d mt-5" style={{ width: "100%" }}>
                                    <div className="table_desin mb-4">
                                        <div className="">
                                            <div className="flex justify-between">
                                                <p className="pl-2">{t('form.User Failed to Save')}</p>
                                            </div>
                                            <table className="w-full">
                                                <thead>
                                                    <tr>
                                                        <th>{t('form.Name')}</th>
                                                        <th>{t('form.Email')}</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {errorContactList.map((item, index) => {
                                                        return (
                                                            <tr key={index}>
                                                                <td>{item.name}</td>
                                                                <td>{item.email}</td>
                                                            </tr>
                                                        );
                                                    })}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            )}

                            {/* {errorContactList && errorContactList.length > 0 && (

                                <div className='pd_md1d mt-5' style={{
                                    width: '100%'
                                }}>
                                    <div className="table_desin mb-4">

                                        <div className="">
                                            <div className='flex justify-between'>
                                                <p className='pl-2'>{t('form.User Failed to Save')}</p>

                                            </div>
                                            <table className="w-full">
                                                <thead>
                                                    <tr>
                                                        <th>{t('form.Name')}</th>
                                                        <th>{t('form.Email')}</th>                                                        
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {errorContactList.map((item, index) => {
                                                        return (
                                                            <tr key={index}>                                                                
                                                                <td>{item.name}</td>
                                                                <td>{item.email}</td>
                                                            </tr>
                                                        )
                                                    })}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            )} */}
                        </div>

                        <div className=' ml-5  w-2/5'>                          
                            {value !== null && error == null && (
                                <>
                                    <div>{responceMsg.msg}</div>
                                    {value?.name}

                                    <button
                                        onClick={deleteFile}
                                        className='btn btn_theme2 btn-sm ml-3 mb-3'>X</button>
                                    <button
                                        className='btn btn_theme btn-sm ml-3 mb-3'
                                        onClick={bulkUploadUsers}
                                    >
                                        {t('form.Save Users')}
                                    </button>
                                </>
                            )}
                        </div>
                    </>

                )}
            </div>

            <AppModal
                title={modalData.title}
                bodyText={modalData.bodyText}
                setIsOpen={setIsOpen}
                modalIsOpen={modalIsOpen}
                modelAction={activeLink}
                apiResStatus={modalData.apiResStatus}
            />
        </div>
    )
}

export default AdminUserForm
