import React, { useContext, useState, useEffect } from "react";
import Modal from "react-modal";
import "./modal.css";
import AuthContext from "../../context/AuthContext";
import { toast } from "react-toastify";
import useAxios from "../../hooks/useAxios";
import { resolve } from "../../api/resolve";
import { useTranslation } from "react-i18next";
import LoadingScreen from "../loader/LoadingScreen";
import PermissionContext from "../../context/PermissionContext";
import { use } from "i18next";


const customStyles = {
  content: {
    top: "30%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    maxWidth: "400px",
    maxHeight: "auto",
    borderRadius: "11px",
    minWidth: "300px",
  },
  overlay: {
    backgroundColor: "rgba(64, 8, 53, 0.63)",
  },
};

Modal.setAppElement("#root");
const PurchaseLicence = ({ modalIsOpen, setIsOpen }) => {
  const api = useAxios();
  const { t } = useTranslation();
  const [signCnt, setSignCnt] = useState("");
  const [signCntError, setSignCntError] = useState("");
  const [loading, setLoading] = useState(false);
  const { user } = useContext(AuthContext);
  const { updatePermissionList } = useContext(PermissionContext);

  const purchaseAadhar = async () => {
    setSignCntError("");
    if (signCnt == "") {
      setSignCntError(t("form.Please enter licences you want to purchase."));
    } else {
      const postData = {
        quantity: signCnt,
      };
      setLoading(true);
      const res = await resolve(
        api.post(`user/purchase/qty/`, postData).then((res) => res.data)
      );
      if (res.data) {
        setIsOpen(false)
        updatePermissionList(res.data['UserQuota']);
        toast.success(t("form.Licence purchase successfully"));
      }
            
      if (res.error && res.error.response.data.message) {
        toast.error(res.error.response.data.message);
      }else if (res.error && res.error.response.data.message) {
        toast.error(t("common.Something went wrong"));
      }
      setSignCnt("");
      setSignCntError("")
      setLoading(false);
    }
  };


  return (
    <div className="">
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={() => setIsOpen(true)}
        style={customStyles}
        onAfterClose={() => {}}
      >
        <div className=" modal-container">
          <h1 className="text-center text-primary font-bold text-lg">
            {t("allModule.Purchase Licence")}
          </h1>

          {loading ? (
            <LoadingScreen />
          ) : (
            <>
              <div className="mt-5">
                <label className="flex flex-start mb-2">
                  {t("form.How many additional licenses do you wish to purchase?")}
                </label>

                <input
                  type="number"
                  className="border-2 border-primary rounded-lg outline-none p-1 pl-2 w-full"
                  onChange={(e) => setSignCnt(e.target.value)}
                  value={signCnt}
                />

                <p className="flex flex-start error_msg">{signCntError}</p>
              </div>
              <div className="flex justify-center mt-5">
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    purchaseAadhar();
                  }}
                  className="bg-primary hover:bg-secondary font-semibold text-white p-1 pl-8 pr-8 rounded-lg mr-5"
                >
                  {t("form.Make Payment")}
                </button>
                <button
                  className="bg-[#606060] font-semibold text-white p-1 pl-8 pr-8 rounded-lg"
                  onClick={() => setIsOpen(false)}
                >
                  {t("common.Cancel")}
                </button>
              </div>
            </>
          )}
        </div>
      </Modal>
    </div>
  );
};

export default PurchaseLicence;
