import React, { useState, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import useAxios from "../../hooks/useAxios";
import { resolve } from "../../api/resolve";
import HelpIcon from "../../assets/icons/Help-sm.svg";
import ReactTooltip from "react-tooltip";
import "./my_document.css";
import {
  pendingDocTableColumns,
  workflowDocTableColumns,
  completedDocTableColumns,
  rejectedDocTableColumns,
  otherDocTableColumns,
  unassignedDocTableColumns,
  unassignedDocOfAdminUserTableColumns,
  reviewDocTableColumns,
  deletedDocTableColumns,
  sharedDocTableColumns,
  docStatus,
  sharestatus,
} from "../../constants/constants";
import { parseISO, format } from "date-fns";
import Search from "../../assets/icons/Search.svg";
import CloseIcon from "../../assets/icons/Close.svg";
import PendingDocTable from "./PendingDocTable";
import WorkflowDocTable from "./WorkflowDocTable";
import CompletedDocTable from "./CompletedDocTable";
import RejectedDocTable from "./RejectedDocTable";

import OtherDocTable from "./OtherDocTable";
import UnassignedDocTable from "./UnassignedDocTable";
import DocumentContext from "../../context/DocumentContext";
import ReviewDocTable from "./ReviewDocTable";
import LoadingScreen from "../loader/LoadingScreen";
import AppContext from "../../context/AppContext";
import DocumentPreviewModal from "../modals/DocumentPreviewModal";
import DeletedDocTable from "./DeletedDocTable";
import SharedDocument from "./SharedDocument";
import { useTranslation } from "react-i18next";
import { use } from "i18next";
import PermissionContext from "../../context/PermissionContext";
import { useLocation } from "react-router-dom";

const MyDocuments = () => {
  const location = useLocation();
  const api = useAxios();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { permissionList } = useContext(PermissionContext);

  // pending doc related states
  const [pendingDocList, setPendingDocList] = useState([]);
  const [pendingDocSearchText, setPendingDocSearchText] = useState("");
  const [pendingDocCurrentPage, setPendingDocCurrentPage] = useState(1);
  const [pendingDocPageLimit, setPendingDocPageLimit] = useState(15);
  const [pendingDocNextBtnLimit, setPendingDocNextBtnLimit] = useState(0);
  const [pendingDocCount, setPendingDocCount] = useState(0);
  const [errMsgPendingDoc, setErrMsgPendingDoc] = useState("");
  const [showPendingDocClearIcon, setShowPendingDocClearIcon] = useState(false);
  const [sortingOrderPendingDoc, setSortingOrderPendingDoc] = useState("desc");
  const [sortColumnPendingDoc, setSortColumnPendingDoc] = useState("id");

  // workflow doc related states
  const [workflowDocList, setWorkflowDocList] = useState([]);
  const [workflowDocSearchText, setWorkflowDocSearchText] = useState("");
  const [workflowDocCurrentPage, setWorkflowDocCurrentPage] = useState(1);
  const [workflowDocPageLimit, setWorkflowDocPageLimit] = useState(15);
  const [workflowDocNextBtnLimit, setWorkflowDocNextBtnLimit] = useState(0);
  const [workflowDocCount, setWorkflowDocCount] = useState(0);
  const [errMsgWorkflowDoc, setErrMsgWorkflowDoc] = useState("");
  const [showWorkflowDocClearIcon, setShowWorkflowDocClearIcon] =
    useState(false);
  const [sortingOrderWorkflowDoc, setSortingOrderWorkflowDoc] =
    useState("desc");
  const [sortColumnWorkflowDoc, setSortColumnWorkflowDoc] = useState("id");

  // Completed doc related states
  const [completedDocList, setCompletedDocList] = useState([]);
  const [completedDocSearchText, setCompletedDocSearchText] = useState("");
  const [completedDocCurrentPage, setCompletedDocCurrentPage] = useState(1);
  const [completedDocPageLimit, setCompletedDocPageLimit] = useState(15);
  const [completedDocNextBtnLimit, setCompletedDocNextBtnLimit] = useState(0);
  const [completedDocCount, setCompletedDocCount] = useState(0);
  const [errMsgCompletedDoc, setErrMsgCompletedDoc] = useState("");
  const [showCompletedDocClearIcon, setShowCompletedDocClearIcon] =
    useState(false);
  const [sortingOrderCompletedDoc, setSortingOrderCompletedDoc] =
    useState("desc");
  const [sortColumnCompletedDoc, setSortColumnCompletedDoc] = useState("id");

  // Rejected doc related states
  const [rejectedDocList, setRejectedDocList] = useState([]);
  const [rejectedDocSearchText, setRejectedDocSearchText] = useState("");
  const [rejectedDocCurrentPage, setRejectedDocCurrentPage] = useState(1);
  const [rejectedDocPageLimit, setRejectedDocPageLimit] = useState(15);
  const [rejectedDocNextBtnLimit, setRejectedDocNextBtnLimit] = useState(0);
  const [rejectedDocCount, setRejectedDocCount] = useState(0);
  const [errMsgRejectedDoc, setErrMsgRejectedDoc] = useState("");
  const [showRejectedDocClearIcon, setShowRejectedDocClearIcon] =
    useState(false);
  const [activeTable, setActiveTable] = useState("unassigned");
  const [sortingOrderRejectedDoc, setSortingOrderRejectedDoc] =
    useState("desc");
  const [sortColumnRejectedDoc, setSortColumnRejectedDoc] = useState("id");

  // Other Signed doc related states
  const [otherDocList, setOtherDocList] = useState([]);
  const [otherDocSearchText, setOtherDocSearchText] = useState("");
  const [otherDocCurrentPage, setOtherDocCurrentPage] = useState(1);
  const [otherDocPageLimit, setOtherDocPageLimit] = useState(15);
  const [otherDocNextBtnLimit, setOtherDocNextBtnLimit] = useState(0);
  const [otherDocCount, setOtherDocCount] = useState(0);
  const [errMsgOtherDoc, setErrMsgOtherDoc] = useState("");
  const [showOtherDocClearIcon, setShowOtherDocClearIcon] = useState(false);
  const [sortingOrderOtherDoc, setSortingOrderOtherDoc] = useState("desc");
  const [sortColumnOtherDoc, setSortColumnOtherDoc] = useState("id");

  // unaasinged Signed doc related states
  const [unassignedDocList, setUnassignedDocList] = useState([]);
  const [unassignedDocSearchText, setUnassignedDocSearchText] = useState("");
  const [unassignedDocCurrentPage, setUnassignedDocCurrentPage] = useState(1);
  const [unassignedDocPageLimit, setUnassignedDocPageLimit] = useState(15);
  const [unassignedDocNextBtnLimit, setUnassignedDocNextBtnLimit] = useState(0);
  const [unassignedDocCount, setUnassignedDocCount] = useState(0);
  const [errMsgUnassignedDoc, setErrMsgUnassignedDoc] = useState("");
  const [showUnassignedDocClearIcon, setShowUnassignedDocClearIcon] =
    useState(false);
  const [sortingOrderUnassignedDoc, setSortingOrderUnassignedDoc] =
    useState("desc");
  const [sortColumnUnassignedDoc, setSortColumnUnassignedDoc] = useState("id");

  // review doc related states
  const [reviewDocList, setReviewDocList] = useState([]);
  const [reviewDocSearchText, setReviewDocSearchText] = useState("");
  const [reviewDocCurrentPage, setReviewDocCurrentPage] = useState(1);
  const [reviewDocPageLimit, setReviewDocPageLimit] = useState(15);
  const [reviewDocNextBtnLimit, setReviewDocNextBtnLimit] = useState(0);
  const [reviewDocCount, setReviewDocCount] = useState(0);
  const [errMsgReviewDoc, setErrMsgReviewDoc] = useState("");
  const [showReviewDocClearIcon, setShowReviewDocClearIcon] = useState(false);
  const [sortingOrderReviewDoc, setSortingOrderReviewDoc] = useState("desc");
  const [sortColumnReviewDoc, setSortColumnReviewDoc] = useState("id");
  const [sharedDocPageLimit, setsharedDocPageLimit] = useState(15);

  // Deleted doc related states
  const [deletedDocList, setDeletedDocList] = useState([]);
  const [deletedDocSearchText, setDeletedDocSearchText] = useState("");
  const [deletedDocCurrentPage, setDeletedDocCurrentPage] = useState(1);
  const [deletedDocPageLimit, setDeletedDocPageLimit] = useState(15);
  const [deletedDocNextBtnLimit, setDeletedDocNextBtnLimit] = useState(0);
  const [deletedDocCount, setDeletedDocCount] = useState(0);
  const [sharedDocList, setsharedDocList] = useState([]);
  const [sharedDocCount, setsharedDocCount] = useState(0);
  const [sharedDocSearchText, setsharedDocSearchText] = useState("");
  const [SortingOrderSharedDoc, setSortingOrderSharedDoc] = useState("desc");

  const [errMsgDeletedDoc, setErrMsgDeletedDoc] = useState("");
  const [showDeletedDocClearIcon, setShowDeletedDocClearIcon] = useState(false);
  const [sortingOrderDeletedDoc, setSortingOrderDeletedDoc] = useState("desc");
  const [sortColumnDeletedDoc, setSortColumnDeletedDoc] = useState("id");

  const [loading, setLoading] = useState(false);
  const [previewDocument, setPreviewDocument] = useState(false);
  const [previewDocumentData, setPreviewDocumentData] = useState({});

  const { setActiveDocInfoMyDoc, forSignatureList, setForSignatureList } =
    useContext(DocumentContext);

  const { activePrevLink, adminId, rowInfo } = useContext(AppContext);

  const handleMyDocumentList = (tableName) => {
    setActiveTable(tableName);
  };
  const handleBatchList = (tableName) => {
    setActiveTable(tableName);
  };

  useEffect(() => {
    console.log("location.pathname", location.pathname);
    if (location.pathname === "/my-documents/set-up-signatures") {
      if (activeTable !== "unassigned") {
        handleMyDocumentList("unassigned");
        getUnassignedDocList();
      }
    } else if (location.pathname === "/my-documents/set-up-workflows") {
      if (activeTable !== "workflow") {
        handleMyDocumentList("workflow");
        getWorkflowDocList();
      }
    } else if (location.pathname === "/my-documents/pending-for-signatures") {
      if (activeTable !== "pending") {
        handleMyDocumentList("pending");
        getPendingDocList();
      }
    } else if (location.pathname === "/my-documents/completed-documents") {
      if (activeTable !== "completed") {
        handleMyDocumentList("completed");
        getCompletedDocList();
      }
    } else if (location.pathname === "/my-documents/rejected-documents") {
      if (activeTable !== "rejected") {
        handleMyDocumentList("rejected");
        getRejectedDocList();
      }
    } else if (location.pathname === "/my-documents/other-signed-documents") {
      if (activeTable !== "otherSigned") {
        handleMyDocumentList("otherSigned");
        getOtherDocList();
      }
    } else if (location.pathname === "/my-documents/review-documents") {
      if (activeTable !== "reviewDocument") {
        handleMyDocumentList("reviewDocument");
        getReviewDocList();
      }
    } else if (location.pathname === "/my-documents/deleted-documents") {
      if (activeTable !== "deleted") {
        handleMyDocumentList("deleted");
        getDeletedDocList();
      }
    } else if (location.pathname === "/my-documents/shared-documents") {
      if (activeTable !== "shared") {
        handleMyDocumentList("shared");
        getSharedDocList();
      }
    }
  }, [location.pathname]);

  const getPendingDocList = async (clearText) => {
    const res = await resolve(
      api
        .get(
          activePrevLink === "DocumentStatus" ||
            activePrevLink === "MyDocuments"
            ? `contract/document/list/?ordering=${sortColumnPendingDoc}&sortingOrder=${sortingOrderPendingDoc}&search=${
                clearText ? "" : pendingDocSearchText
              }&page=${pendingDocCurrentPage}&limit=${pendingDocPageLimit}&docStatus=3`
            : `contract/document/?UserId=${adminId}&ordering=${sortColumnPendingDoc}&sortingOrder=${sortingOrderPendingDoc}&search=${
                clearText ? "" : pendingDocSearchText
              }&page=${pendingDocCurrentPage}&limit=${pendingDocPageLimit}&docStatus=3`
        )
        .then((res) => res.data)
    );
    if (res.data) {
      const { data } = res;
      const { results, count } = data;
      const filterData = [];
      if (results.length > 0) {
        setPendingDocCount(count);
        results.forEach((result) => {
          const pendingDocObj = {
            id: result.id,
            name: result.name,
            status: docStatus[result.status],
            updated_at: format(
              parseISO(result.updated_at),
              "ccc, dd MMM yyyy, HH:mm"
            ),
            created_by: result.uploaded_by["first_name"],
            converted_file_url: result.converted_file_url,
          };
          filterData.push(pendingDocObj);
        });
      } else {
        if (pendingDocCurrentPage === 1)
          setErrMsgPendingDoc(t("homePage.No document added yet"));
        else setErrMsgPendingDoc(t("homePage.No more document to view"));
      }
      setPendingDocList([...filterData]);
      setPendingDocNextBtnLimit(Math.ceil(data.count / pendingDocPageLimit));
    } else {
      setPendingDocNextBtnLimit(0);
    }
  };

  const getWorkflowDocList = async (clearText) => {
    const res = await resolve(
      api
        .get(
          activePrevLink === "DocumentStatus" ||
            activePrevLink === "MyDocuments"
            ? `contract/document/list/?ordering=${sortColumnWorkflowDoc}&sortingOrder=${sortingOrderWorkflowDoc}&search=${
                clearText ? "" : workflowDocSearchText
              }&page=${workflowDocCurrentPage}&limit=${workflowDocPageLimit}&docStatus=2,6`
            : `contract/document/?UserId=${adminId}&ordering=${sortColumnWorkflowDoc}&sortingOrder=${sortingOrderWorkflowDoc}&search=${
                clearText ? "" : workflowDocSearchText
              }&page=${workflowDocCurrentPage}&limit=${workflowDocPageLimit}&docStatus=2,6`
        )
        .then((res) => res.data)
    );
    if (res.data) {
      const { data } = res;
      const { results, count } = data;
      const filterData = [];
      if (results.length > 0) {
        setWorkflowDocCount(count);
        results.forEach((result) => {
          const pendingDocObj = {
            id: result.id,
            name: result.name,
            status: docStatus[result.status],
            updated_at: format(
              parseISO(result.updated_at),
              "ccc, dd MMM yyyy, HH:mm"
            ),
            created_by: result.uploaded_by["first_name"],
            converted_file_url: result.converted_file_url,
          };
          filterData.push(pendingDocObj);
        });
      } else {
        if (workflowDocCurrentPage === 1)
          setErrMsgWorkflowDoc(t("homePage.No document added yet"));
        else setErrMsgWorkflowDoc(t("homePage.No more document to view"));
      }
      setWorkflowDocList([...filterData]);
      setWorkflowDocNextBtnLimit(Math.ceil(data.count / workflowDocPageLimit));
    } else {
      setWorkflowDocNextBtnLimit(0);
    }
  };

  const getCompletedDocList = async (clearText) => {
    const res = await resolve(
      api
        .get(
          activePrevLink === "DocumentStatus" ||
            activePrevLink === "MyDocuments"
            ? `contract/document/list/?ordering=${sortColumnCompletedDoc}&sortingOrder=${sortingOrderCompletedDoc}&search=${
                clearText ? "" : completedDocSearchText
              }&page=${completedDocCurrentPage}&limit=${completedDocPageLimit}&docStatus=4`
            : `contract/document/?UserId=${adminId}&ordering=${sortColumnCompletedDoc}&sortingOrder=${sortingOrderCompletedDoc}&search=${
                clearText ? "" : completedDocSearchText
              }&page=${completedDocCurrentPage}&limit=${completedDocPageLimit}&docStatus=4`
        )
        .then((res) => res.data)
    );
    if (res.data) {
      const { data } = res;
      const { results, count } = data;
      const filterData = [];
      if (results.length > 0) {
        setCompletedDocCount(count);
        results.forEach((result) => {
          const docObj = {
            id: result.id,
            name: result.name,
            is_send_to_legacy: result.is_send_to_legacy,
            status: docStatus[result.status],
            updated_at: format(
              parseISO(result.updated_at),
              "ccc, dd MMM yyyy, HH:mm"
            ),
            created_by: result.uploaded_by["first_name"],
            converted_file_url: result.converted_file_url,
            md5sum: result.md5sum,
          };
          filterData.push(docObj);
        });
      } else {
        if (completedDocCurrentPage === 1)
          setErrMsgCompletedDoc(t("homePage.No document added yet"));
        else setErrMsgCompletedDoc(t("homePage.No more document to view"));
      }
      setCompletedDocList([...filterData]);
      setCompletedDocNextBtnLimit(
        Math.ceil(data.count / completedDocPageLimit)
      );
    } else {
      setCompletedDocNextBtnLimit(0);
    }
  };

  const getRejectedDocList = async (clearText) => {
    const res = await resolve(
      api
        .get(
          activePrevLink === "DocumentStatus" ||
            activePrevLink === "MyDocuments"
            ? `contract/document/list/?ordering=${sortColumnRejectedDoc}&sortingOrder=${sortingOrderRejectedDoc}&search=${
                clearText ? "" : rejectedDocSearchText
              }&page=${rejectedDocCurrentPage}&limit=${rejectedDocPageLimit}&docStatus=5`
            : `contract/document/?UserId=${adminId}&ordering=${sortColumnRejectedDoc}&sortingOrder=${sortingOrderRejectedDoc}&search=${
                clearText ? "" : rejectedDocSearchText
              }&page=${rejectedDocCurrentPage}&limit=${rejectedDocPageLimit}&docStatus=5`
        )
        .then((res) => res.data)
    );
    if (res.data) {
      const { data } = res;
      const { results, count } = data;
      const filterData = [];
      if (results.length > 0) {
        setRejectedDocCount(count);
        results.forEach((result) => {
          const docObj = {
            id: result.id,
            name: result.name,
            status: docStatus[result.status],
            updated_at: format(
              parseISO(result.updated_at),
              "ccc, dd MMM yyyy, HH:mm"
            ),
            created_by: result.uploaded_by["first_name"],
            converted_file_url: result.converted_file_url,
          };
          filterData.push(docObj);
        });
      } else {
        if (rejectedDocCurrentPage === 1)
          setErrMsgRejectedDoc(t("homePage.No document added yet"));
        else setErrMsgRejectedDoc(t("homePage.No more document to view"));
      }
      setRejectedDocList([...filterData]);
      setRejectedDocNextBtnLimit(Math.ceil(data.count / rejectedDocPageLimit));
    } else {
      setRejectedDocNextBtnLimit(0);
    }
  };

  const getOtherDocList = async (clearText) => {
    const res = await resolve(
      api
        .get(
          activePrevLink === "DocumentStatus" ||
            activePrevLink === "MyDocuments"
            ? `contract/document/OtherSign/?ordering=${sortColumnOtherDoc}&sortingOrder=${sortingOrderOtherDoc}&search=${
                clearText ? "" : otherDocSearchText
              }&page=${otherDocCurrentPage}&limit=${otherDocPageLimit}&docStatus=4`
            : `contract/document/OtherSign/?UserId=${adminId}&ordering=${sortColumnOtherDoc}&sortingOrder=${sortingOrderOtherDoc}&search=${
                clearText ? "" : otherDocSearchText
              }&page=${otherDocCurrentPage}&limit=${otherDocPageLimit}&docStatus=4`
        )
        .then((res) => res.data)
    );
    if (res.data) {
      const { data } = res;
      const { results, count } = data;
      const filterData = [];
      if (results.length > 0) {
        setOtherDocCount(count);
        results.forEach((resultData) => {
          let result = resultData.document_id;
          const docObj = {
            id: result.id,
            name: result.name,
            status: docStatus[result.status],
            office365_status: result.office365_status,
            updated_at: format(
              parseISO(result.updated_at),
              "ccc, dd MMM yyyy, HH:mm"
            ),
            created_by: result.uploaded_by["first_name"],
            converted_file_url: result.converted_file_url,
          };
          filterData.push(docObj);
        });
      } else {
        if (otherDocCurrentPage === 1)
          setErrMsgOtherDoc(t("homePage.No document added yet"));
        else setErrMsgOtherDoc(t("homePage.No more document to view"));
      }
      setOtherDocList([...filterData]);
      setOtherDocNextBtnLimit(Math.ceil(data.count / otherDocPageLimit));
    } else {
      setOtherDocNextBtnLimit(0);
    }
  };

  const getUnassignedDocList = async (clearText) => {
    const res = await resolve(
      api
        .get(
          activePrevLink === "DocumentStatus" ||
            activePrevLink === "MyDocuments"
            ? `contract/document/list/?ordering=${sortColumnUnassignedDoc}&sortingOrder=${sortingOrderUnassignedDoc}&search=${
                clearText ? "" : unassignedDocSearchText
              }&page=${unassignedDocCurrentPage}&limit=${unassignedDocPageLimit}&docStatus=1,10,11`
            : `contract/document/?UserId=${adminId}&ordering=${sortColumnUnassignedDoc}&sortingOrder=${sortingOrderUnassignedDoc}&search=${
                clearText ? "" : unassignedDocSearchText
              }&page=${unassignedDocCurrentPage}&limit=${unassignedDocPageLimit}&docStatus=1,10,11`
        )
        .then((res) => res.data)
    );
    if (res.data) {
      const { data } = res;
      const { results, count } = data;
      const filterData = [];
      if (results.length > 0) {
        setUnassignedDocCount(count);
        results.forEach((result) => {
          let doc_name = result.doc_name;
          var ext = doc_name.substr(doc_name.lastIndexOf(".") + 1);

          const unassignedDocObj = {
            id: result.id,
            name: result.name,
            original_file_pdf_url: result.original_file_pdf_url,
            file_url: result.file_url,
            ext: ext,
            office365_status: result.office365_status,
            status: docStatus[result.status],
            updated_at: format(
              parseISO(result.updated_at),
              "ccc, dd MMM yyyy, HH:mm"
            ),
            created_by: result.uploaded_by["first_name"],
            converted_file_url: result.converted_file_url,
            DocFinalized: result.DocFinalized,
          };
          filterData.push(unassignedDocObj);
        });
      } else {
        if (unassignedDocCurrentPage === 1)
          setErrMsgUnassignedDoc(t("homePage.No document added yet"));
        else setErrMsgUnassignedDoc(t("homePage.No more document to view"));
      }
      setUnassignedDocList([...filterData]);
      setUnassignedDocNextBtnLimit(
        Math.ceil(data.count / unassignedDocPageLimit)
      );
    } else {
      setUnassignedDocNextBtnLimit(0);
    }
  };

  const getReviewDocList = async (clearText) => {
    const res = await resolve(
      api
        .get(
          activePrevLink === "DocumentStatus" ||
            activePrevLink === "MyDocuments"
            ? `contract/document/list/?search=${
                clearText ? "" : reviewDocSearchText
              }&ordering=${sortColumnReviewDoc}&sortingOrder=${sortingOrderReviewDoc}&page=${reviewDocCurrentPage}&limit=${reviewDocPageLimit}&review_doc=1`
            : `contract/document/?UserId=${adminId}&search=${
                clearText ? "" : reviewDocSearchText
              }&ordering=${sortColumnReviewDoc}&sortingOrder=${sortingOrderReviewDoc}&page=${reviewDocCurrentPage}&limit=${reviewDocPageLimit}&review_doc=1`
        )
        .then((res) => res.data)
    );
    if (res.data) {
      const { data } = res;
      const { results, count } = data;
      const filterData = [];
      if (results.length > 0) {
        setReviewDocCount(count);
        results.forEach((result) => {
          // let typeDescription;
          // switch (result.type) {
          //   case "Q-DILIGENCE":
          //     typeDescription = "Q-Diligence";
          //     break;
          //   case "Q-IPReview":
          //     typeDescription = "IP Review";
          //     break;
          //   case "Q-SectoralReview":
          //     typeDescription = "Sectoral Review";
          //     break;
          //   case "Q-PlayriteDeviation":
          //     typeDescription = "Q-PlayriteDeviation";
          //     break;
          //   case "Q-Transaction":
          //     typeDescription = "Q-Transaction";
          //     break;
          //   default:
          //     // Handle other cases if needed
          //     typeDescription = "Q-NDA";
          //     break;
          // }
          const reviewDocObj = {
            id: result.id,
            name: result.name,
            type: result.type,
            uniqueId: result.tags,
            converted_file_url: result.converted_file_url,
            file_url: result.file_url,
          };
          filterData.push(reviewDocObj);
        });
      } else {
        if (reviewDocCurrentPage === 1)
          setErrMsgReviewDoc(t("homePage.No document added yet"));
        else setErrMsgReviewDoc(t("homePage.No more document to view"));
      }
      setReviewDocList([...filterData]);
      setReviewDocNextBtnLimit(Math.ceil(data.count / reviewDocPageLimit));
    } else {
      setReviewDocNextBtnLimit(0);
    }
  };

  const getDeletedDocList = async (clearText) => {
    const res = await resolve(
      api
        .get(
          `contract/doc/cancle/list/?ordering=${sortColumnDeletedDoc}&sortingOrder=${sortingOrderDeletedDoc}&search=${
            clearText ? "" : deletedDocSearchText
          }&page=${deletedDocCurrentPage}&limit=${deletedDocPageLimit}&docStatus=5`
        )
        .then((res) => res.data)
    );
    if (res.data) {
      const { data } = res;
      const { results, count } = data;
      const filterData = [];
      if (results.length > 0) {
        setDeletedDocCount(count);
        results.forEach((result) => {
          const docObj = {
            id: result.id,
            name: result.name,
            status: docStatus[result.status],
            updated_at: format(
              parseISO(result.updated_at),
              "ccc, dd MMM yyyy, HH:mm"
            ),
            created_by: result.uploaded_by["first_name"],
            converted_file_url: result.converted_file_url,
          };
          filterData.push(docObj);
        });
      } else {
        if (deletedDocCurrentPage === 1)
          setErrMsgDeletedDoc(t("homePage.No document added yet"));
        else setErrMsgDeletedDoc(t("homePage.No more document to view"));
      }
      setDeletedDocList([...filterData]);
      setDeletedDocNextBtnLimit(Math.ceil(data.count / deletedDocPageLimit));
    } else {
      setDeletedDocNextBtnLimit(0);
    }
  };

  const getSharedDocList = async (clearText) => {
    const res = await resolve(
      api
        .get(
          `contract/sharedoc/user/view/?ordering=${sortColumnDeletedDoc}&sortingOrder=${sortingOrderDeletedDoc}&page=${deletedDocCurrentPage}&limit=${deletedDocPageLimit}&search=${
            clearText ? "" : sharedDocSearchText
          }`
        )
        .then((res) => res.data)
    );
    // console.log("values",res.data)
    if (res.data) {
      const { data } = res;
      const { count, results } = data;
      const filterData = [];
      if (results.length > 0) {
        setsharedDocCount(count);
        results.forEach((result) => {
          const docObj = {
            id: result?.doc_id_id,
            act_id: result?.id,
            name: result?.doc_name,
            type: sharestatus[result?.status],
            updated_at: format(
              parseISO(result?.updated_at),
              "ccc, dd MMM yyyy, HH:mm"
            ),
            created_by: result?.receiver_name,
            contactnumberlist: result?.contactnumberlist,
            emaillist: result?.emaillist,
            kindofassistance: result?.kindofassistance,
            description: result?.description,
            converted_file_url: result?.converted_file_url,
          };
          filterData.push(docObj);
        });
        // console.log("filter data",filterData)
      } else {
        setErrMsgDeletedDoc(t("homePage.No more document to view"));
      }
      setsharedDocList([...filterData]);
      setDeletedDocNextBtnLimit(Math.ceil(data.count / deletedDocPageLimit));
    } else {
      setDeletedDocNextBtnLimit(0);
    }
  };

  const getBatchList = async () => {};
  useEffect(() => {
    getPendingDocList();
  }, [
    sortColumnPendingDoc,
    sortingOrderPendingDoc,
    pendingDocCurrentPage,
    pendingDocPageLimit,
  ]);

  useEffect(() => {
    getWorkflowDocList();
  }, [
    sortColumnWorkflowDoc,
    sortingOrderWorkflowDoc,
    workflowDocCurrentPage,
    workflowDocPageLimit,
  ]);

  useEffect(() => {
    getCompletedDocList();
  }, [
    sortColumnCompletedDoc,
    sortingOrderCompletedDoc,
    completedDocCurrentPage,
    completedDocPageLimit,
  ]);

  useEffect(() => {
    getRejectedDocList();
  }, [
    sortColumnRejectedDoc,
    sortingOrderRejectedDoc,
    rejectedDocCurrentPage,
    rejectedDocPageLimit,
  ]);

  useEffect(() => {
    getOtherDocList();
  }, [
    sortColumnOtherDoc,
    sortingOrderOtherDoc,
    otherDocCurrentPage,
    otherDocPageLimit,
  ]);

  useEffect(() => {
    getUnassignedDocList();
  }, [
    sortColumnUnassignedDoc,
    sortingOrderUnassignedDoc,
    unassignedDocCurrentPage,
    unassignedDocPageLimit,
  ]);
  useEffect(() => {
    // getBatchList()
    setForSignatureList([]);
  }, []);
  useEffect(() => {
    getReviewDocList();
  }, [
    sortColumnReviewDoc,
    sortingOrderReviewDoc,
    reviewDocCurrentPage,
    reviewDocPageLimit,
  ]);

  useEffect(() => {
    getDeletedDocList();
  }, [
    sortColumnDeletedDoc,
    sortingOrderDeletedDoc,
    deletedDocCurrentPage,
    deletedDocPageLimit,
  ]);

  useEffect(() => {
    getSharedDocList();
  }, [
    sortColumnDeletedDoc,
    sortingOrderDeletedDoc,
    deletedDocCurrentPage,
    deletedDocPageLimit,
  ]);

  if (loading) {
    return <LoadingScreen />;
  }
  return (
    <div className="text-primary text-base font-normal font-sans mt_set">
      <div className="main_head">
        <div className="flex">
          <h1 className="text-4xl font-bold text_c ">
            {activePrevLink === "DocumentStatus" ||
            activePrevLink === "MyDocuments"
              ? t("homePage.My")
              : t("homePage.User")}{" "}
            {t("homePage.Documents")}{" "}
            {activePrevLink === "DocumentStatus" ||
            activePrevLink === "MyDocuments"
              ? ""
              : "(" + rowInfo.first_name + ")"}
          </h1>
          <>
            <img
              src={HelpIcon}
              width={12}
              className="ml-1 -mt-2 cursor-pointer"
              data-tip={`${
                activePrevLink === "DocumentStatus" ||
                activePrevLink === "MyDocuments"
                  ? "My"
                  : "User"
              } Documents`}
            />
            <ReactTooltip backgroundColor="#400835" textColor="#ffffff" />
          </>
        </div>
      </div>
      {/* need to remove  */}
      {(activePrevLink === "DocumentStatus" ||
        activePrevLink === "MyDocuments") && (
        <div className="nav_tab mb-4">
          <ul className="ul_set">
            <li
              className={activeTable === "unassigned" ? "active" : ""}
              onClick={(e) => {
                e.stopPropagation();
                navigate("/my-documents/set-up-signatures");
              }}
            >
              <a href="#">{t("allModule.Manage Documents")}</a>
            </li>
            {/* <li
              className={activeTable === "batchList" ? "active" : ""}
              onClick={(e) => {
                e.stopPropagation();
                handleBatchList("batchList");
                navigate("/my-documents/batch-list");
                if (activeTable !== "batchList") {
                  getBatchList();
                }
              }}
            >
              <a href="#">{t("allModule.Batch List")}</a>
            </li> */}

            <li
              className={activeTable === "workflow" ? "active" : ""}
              onClick={(e) => {
                e.stopPropagation();
                navigate("/my-documents/set-up-workflows");
              }}
            >
              <a href="#">{t("allModule.Processing Queue")}</a>
            </li>

            <li
              className={activeTable === "pending" ? "active" : ""}
              onClick={(e) => {
                e.stopPropagation();
                navigate("/my-documents/pending-for-signatures");
                // handleMyDocumentList("pending");
                // if (activeTable !== "pending") {
                //   getPendingDocList();
                // }
              }}
            >
              <a href="#">{t("allModule.Pending for Signatures")}</a>
            </li>
            <li
              className={activeTable === "completed" ? "active" : ""}
              onClick={(e) => {
                e.stopPropagation();
                navigate("/my-documents/completed-documents");
                // handleMyDocumentList("completed");
                // if (activeTable !== "completed") {
                //   getCompletedDocList();
                // }
              }}
            >
              <a href="#">{t("allModule.Completed Documents")} </a>
            </li>
            <li
              className={activeTable === "rejected" ? "active" : ""}
              onClick={(e) => {
                e.stopPropagation();
                navigate("/my-documents/rejected-documents");
              }}
            >
              <a href="#">{t("allModule.Rejected Documents")} </a>
            </li>

            <li
              className={activeTable === "otherSigned" ? "active" : ""}
              onClick={(e) => {
                e.stopPropagation();
                navigate("/my-documents/other-signed-documents");
              }}
            >
              <a href="#">{t("allModule.Other Signed Documents")} </a>
            </li>

            <li
              className={activeTable === "reviewDocument" ? "active" : ""}
              onClick={(e) => {
                e.stopPropagation();
                navigate("/my-documents/review-documents");
              }}
            >
              <a href="#">{t("allModule.Reviewed Documents")}</a>
            </li>

            <li
              className={activeTable === "deleted" ? "active" : ""}
              onClick={(e) => {
                e.stopPropagation();
                navigate("/my-documents/deleted-documents");
              }}
            >
              <a href="#">{t("allModule.Deleted Documents")} </a>
            </li>
            <li
              className={activeTable === "shared" ? "active" : ""}
              onClick={(e) => {
                e.stopPropagation();
                navigate("/my-documents/shared-documents");
              }}
            >
              <a href="#">{t("allModule.Shared Documents")} </a>
            </li>
          </ul>
        </div>
      )}

      <div className="tab_main">
        <div className={`tab_us ${activeTable === "unassigned" ? "show" : ""}`}>
          <div className="my_document_listing mb-7">
            <div className="">
              <div className="row justify-between">
                <div className="col-md-5">
                  <h2 className="text-xl font-semibold">
                    {t("allModule.Manage Documents")}
                    <>
                      <img
                        src={HelpIcon}
                        width={12}
                        className=" cursor-pointer di_mes"
                        data-tip={t(
                          "allModule.List of Documents pending Signature setup"
                        )}
                      />
                      <ReactTooltip
                        backgroundColor="#400835"
                        textColor="#ffffff"
                      />
                    </>
                  </h2>
                </div>
                {permissionList["document_search_on"] == true && (
                  <div className="col-md-5">
                    <div className="flex justify-center">
                      <div className="heg_in flex justify-between items-center border-2 border-primary rounded-md rounded-tl-lg rounded-bl-lg bg-inputBackground px-2 w-full mr-2">
                        <input
                          placeholder={t("allModule.Search for Document")}
                          className="px-1 py-1 focus:outline-none bg-transparent w-11/12"
                          value={unassignedDocSearchText}
                          onChange={(e) => {
                            setUnassignedDocSearchText(e.target.value);
                            setShowUnassignedDocClearIcon(true);
                            if (!e.target.value.trim()) {
                              setShowUnassignedDocClearIcon(false);
                            }
                          }}
                        />
                        {showUnassignedDocClearIcon && (
                          <img
                            src={CloseIcon}
                            width={14}
                            className="cursor-pointer"
                            onClick={() => {
                              setUnassignedDocCurrentPage(1);
                              setUnassignedDocSearchText("");
                              getUnassignedDocList(true);
                              setShowUnassignedDocClearIcon(false);
                            }}
                          />
                        )}
                      </div>
                      <button
                        type="submit"
                        className="flex btn btn_theme2 items-center justify-between w_125"
                        onClick={(e) => {
                          e.preventDefault();
                          setUnassignedDocCurrentPage(1);
                          getUnassignedDocList();
                        }}
                      >
                        <img src={Search} style={{ width: "1rem" }} />
                        <span className="font-semibold ml-1">
                          {t("allModule.Search")}
                        </span>
                      </button>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="mt-3 rounded-lg">
              {unassignedDocList.length < 1 && (
                <p className="text-red-500 text-sm font-medium">
                  {errMsgUnassignedDoc}
                </p>
              )}
            </div>
            <UnassignedDocTable
              tableColumns={
                activePrevLink === "DocumentStatus" ||
                activePrevLink === "MyDocuments"
                  ? unassignedDocTableColumns
                  : unassignedDocOfAdminUserTableColumns
              }
              getListData={getUnassignedDocList}
              getRejectedDocList={getRejectedDocList}
              tableData={unassignedDocList}
              nextBtnLimit={unassignedDocNextBtnLimit}
              currentPage={unassignedDocCurrentPage}
              setCurrentPage={setUnassignedDocCurrentPage}
              itemCount={unassignedDocCount}
              pageLimit={unassignedDocPageLimit}
              setPageLimit={setUnassignedDocPageLimit}
              showPageLimit={true}
              setActiveDocInfoMyDoc={setActiveDocInfoMyDoc}
              setSortingOrder={setSortingOrderUnassignedDoc}
              setSortColumn={setSortColumnUnassignedDoc}
              forSignatureList={forSignatureList}
              setForSignatureList={setForSignatureList}
              setPreviewDocument={setPreviewDocument}
              setPreviewDocumentData={setPreviewDocumentData}
              showActionBtn
            />
          </div>
        </div>
        <div className={`tab_us ${activeTable === "workflow" ? "show" : ""}`}>
          <div className="my_document_listing mb-7">
            <div className="">
              <div className="row justify-between">
                <div className="col-md-5">
                  <h2 className="text-xl font-semibold">
                    {t("allModule.Processing Queue")}
                    <>
                      <img
                        src={HelpIcon}
                        width={12}
                        className="cursor-pointer di_mes"
                        data-tip={t(
                          "allModule.List of Documents pending Workflow setup"
                        )}
                      />
                      <ReactTooltip
                        backgroundColor="#400835"
                        textColor="#ffffff"
                      />
                    </>
                  </h2>
                </div>

                {permissionList["document_search_on"] == true && (
                  <div className="col-md-5">
                    <div className="flex justify-center">
                      <div className="heg_in flex justify-between items-center border-2 border-primary rounded-md rounded-tl-lg rounded-bl-lg bg-inputBackground px-2 w-full mr-2">
                        <input
                          placeholder={t("allModule.Search for Document")}
                          className="px-1 py-1 focus:outline-none bg-transparent w-11/12"
                          value={workflowDocSearchText}
                          onChange={(e) => {
                            setWorkflowDocSearchText(e.target.value);
                            setShowWorkflowDocClearIcon(true);
                            if (!e.target.value.trim()) {
                              setShowWorkflowDocClearIcon(false);
                            }
                          }}
                        />
                        {showWorkflowDocClearIcon && (
                          <img
                            src={CloseIcon}
                            width={14}
                            className="cursor-pointer"
                            onClick={() => {
                              setWorkflowDocCurrentPage(1);
                              setWorkflowDocSearchText("");
                              getWorkflowDocList(true);
                              setShowWorkflowDocClearIcon(false);
                            }}
                          />
                        )}
                      </div>
                      <button
                        type="submit"
                        className="flex btn btn_theme2 items-center justify-between w_125"
                        onClick={(e) => {
                          e.preventDefault();
                          setWorkflowDocCurrentPage(1);
                          getWorkflowDocList();
                        }}
                      >
                        <img src={Search} style={{ width: "1rem" }} />
                        <span className="font-semibold ml-1">
                          {t("allModule.Search")}
                        </span>
                      </button>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="mt-3 rounded-lg">
              {workflowDocList.length < 1 && (
                <p className="text-red-500 text-sm font-medium">
                  {errMsgWorkflowDoc}
                </p>
              )}
            </div>
            <WorkflowDocTable
              tableColumns={workflowDocTableColumns}
              getListData={getWorkflowDocList}
              tableData={workflowDocList}
              nextBtnLimit={workflowDocNextBtnLimit}
              currentPage={workflowDocCurrentPage}
              setCurrentPage={setWorkflowDocCurrentPage}
              itemCount={workflowDocCount}
              pageLimit={workflowDocPageLimit}
              setPageLimit={setWorkflowDocPageLimit}
              showPageLimit={true}
              setSortingOrder={setSortingOrderWorkflowDoc}
              setSortColumn={setSortColumnWorkflowDoc}
              setPreviewDocument={setPreviewDocument}
              setPreviewDocumentData={setPreviewDocumentData}
              showActionBtn
            />
          </div>
        </div>
        <div className={`tab_us ${activeTable === "pending" ? "show" : ""}`}>
          <div className="my_document_listing mb-7">
            <div className="">
              <div className="row justify-between">
                <div className="col-md-5">
                  <h2 className="text-xl font-semibold">
                    {t("allModule.Pending for Signatures")}
                    <>
                      <img
                        src={HelpIcon}
                        width={12}
                        className=" cursor-pointer di_mes"
                        data-tip={t(
                          "allModule.List of Documents pending for Signature"
                        )}
                      />
                      <ReactTooltip
                        backgroundColor="#400835"
                        textColor="#ffffff"
                      />
                    </>
                  </h2>
                </div>
                {permissionList["document_search_on"] == true && (
                  <div className="col-md-5">
                    <div className="flex justify-center">
                      <div className="heg_in flex justify-between items-center border-2 border-primary rounded-md rounded-tl-lg rounded-bl-lg bg-inputBackground px-2 w-full mr-2">
                        <input
                          placeholder={t("allModule.Search for Document")}
                          className="px-1 py-1 focus:outline-none bg-transparent w-11/12"
                          value={pendingDocSearchText}
                          onChange={(e) => {
                            setPendingDocSearchText(e.target.value);
                            setShowPendingDocClearIcon(true);
                            if (!e.target.value.trim()) {
                              setShowPendingDocClearIcon(false);
                            }
                          }}
                        />
                        {showPendingDocClearIcon && (
                          <img
                            src={CloseIcon}
                            width={14}
                            className="cursor-pointer"
                            onClick={() => {
                              setPendingDocCurrentPage(1);
                              setPendingDocSearchText("");
                              getPendingDocList(true);
                              setShowPendingDocClearIcon(false);
                            }}
                          />
                        )}
                      </div>
                      <button
                        type="submit"
                        className="flex btn btn_theme2 items-center justify-between w_125"
                        onClick={(e) => {
                          e.preventDefault();
                          setPendingDocCurrentPage(1);
                          getPendingDocList();
                        }}
                      >
                        <img src={Search} style={{ width: "1rem" }} />
                        <span className="font-semibold ml-1">
                          {t("allModule.Search")}
                        </span>
                      </button>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="mt-3 rounded-lg">
              {pendingDocList.length < 1 && (
                <p className="text-red-500 text-sm font-medium">
                  {errMsgPendingDoc}
                </p>
              )}
            </div>
            <PendingDocTable
              tableColumns={pendingDocTableColumns}
              getListData={getPendingDocList}
              getRejectedDocList={getRejectedDocList}
              tableData={pendingDocList}
              showActionBtn
              nextBtnLimit={pendingDocNextBtnLimit}
              currentPage={pendingDocCurrentPage}
              setCurrentPage={setPendingDocCurrentPage}
              itemCount={pendingDocCount}
              pageLimit={pendingDocPageLimit}
              setPageLimit={setPendingDocPageLimit}
              showPageLimit={true}
              setActiveDocInfoMyDoc={setActiveDocInfoMyDoc}
              setSortingOrder={setSortingOrderPendingDoc}
              setSortColumn={setSortColumnPendingDoc}
              setPreviewDocument={setPreviewDocument}
              setPreviewDocumentData={setPreviewDocumentData}
            />
          </div>
        </div>
        <div className={`tab_us ${activeTable === "completed" ? "show" : ""}`}>
          <div className="my_document_listing mb-7">
            <div className="">
              <div className="row justify-between">
                <div className="col-md-5">
                  <h2 className="text-xl font-semibold">
                    {t("allModule.Completed Documents")}
                    <>
                      <img
                        src={HelpIcon}
                        width={12}
                        className="cursor-pointer di_mes"
                        data-tip={t("allModule.List of Executed Documents")}
                      />
                      <ReactTooltip
                        backgroundColor="#400835"
                        textColor="#ffffff"
                      />
                    </>
                  </h2>
                </div>
                {permissionList["document_search_on"] == true && (
                  <div className="col-md-5">
                    <div className="flex justify-center">
                      <div className="heg_in flex justify-between items-center border-2 border-primary rounded-md rounded-tl-lg rounded-bl-lg bg-inputBackground px-2 w-full mr-2">
                        <input
                          placeholder={t("allModule.Search for Document")}
                          className="px-1 py-1 focus:outline-none bg-transparent w-11/12"
                          value={completedDocSearchText}
                          onChange={(e) => {
                            setCompletedDocSearchText(e.target.value);
                            setShowCompletedDocClearIcon(true);
                            if (!e.target.value.trim()) {
                              setShowCompletedDocClearIcon(false);
                            }
                          }}
                        />
                        {showCompletedDocClearIcon && (
                          <img
                            src={CloseIcon}
                            width={14}
                            className="cursor-pointer"
                            onClick={() => {
                              setCompletedDocCurrentPage(1);
                              setCompletedDocSearchText("");
                              getCompletedDocList(true);
                              setShowCompletedDocClearIcon(false);
                            }}
                          />
                        )}
                      </div>
                      <button
                        type="submit"
                        className="flex btn btn_theme2 items-center justify-between w_125"
                        onClick={(e) => {
                          e.preventDefault();
                          setCompletedDocCurrentPage(1);
                          getCompletedDocList();
                        }}
                      >
                        <img src={Search} style={{ width: "1rem" }} />
                        <span className="font-semibold ml-1">
                          {t("allModule.Search")}
                        </span>
                      </button>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="mt-3 rounded-lg">
              {completedDocList.length < 1 && (
                <p className="text-red-500 text-sm font-medium">
                  {errMsgPendingDoc}
                </p>
              )}
            </div>

            <CompletedDocTable
              tableColumns={completedDocTableColumns}
              getListData={getCompletedDocList}
              tableData={completedDocList}
              showActionBtn
              nextBtnLimit={completedDocNextBtnLimit}
              currentPage={completedDocCurrentPage}
              setCurrentPage={setCompletedDocCurrentPage}
              itemCount={completedDocCount}
              pageLimit={completedDocPageLimit}
              setPageLimit={setCompletedDocPageLimit}
              showPageLimit={true}
              setSortingOrder={setSortingOrderCompletedDoc}
              setSortColumn={setSortColumnCompletedDoc}
              setLoading={setLoading}
              setPreviewDocument={setPreviewDocument}
              setPreviewDocumentData={setPreviewDocumentData}
            />
          </div>
        </div>
        <div className={`tab_us ${activeTable === "rejected" ? "show" : ""}`}>
          <div className="my_document_listing mb-7">
            <div className="">
              <div className="row justify-between">
                <div className="col-md-5">
                  <h2 className="text-xl font-semibold">
                    {t("allModule.Rejected Documents")}
                    <>
                      <img
                        src={HelpIcon}
                        width={12}
                        className="cursor-pointer di_mes"
                        data-tip={t("allModule.List of Rejected Documents")}
                      />
                      <ReactTooltip
                        backgroundColor="#400835"
                        textColor="#ffffff"
                      />
                    </>
                  </h2>
                </div>
                {permissionList["document_search_on"] == true && (
                  <div className="col-md-5">
                    <div className="flex justify-center">
                      <div className="heg_in flex justify-between items-center border-2 border-primary rounded-md rounded-tl-lg rounded-bl-lg bg-inputBackground px-2 w-full mr-2">
                        <input
                          placeholder={t("allModule.Search for Document")}
                          className="px-1 py-1 focus:outline-none bg-transparent w-11/12"
                          value={rejectedDocSearchText}
                          onChange={(e) => {
                            setRejectedDocSearchText(e.target.value);
                            setShowRejectedDocClearIcon(true);
                            if (!e.target.value.trim()) {
                              setShowRejectedDocClearIcon(false);
                            }
                          }}
                        />
                        {showRejectedDocClearIcon && (
                          <img
                            src={CloseIcon}
                            width={14}
                            className="cursor-pointer"
                            onClick={() => {
                              setRejectedDocCurrentPage(1);
                              setRejectedDocSearchText("");
                              getRejectedDocList(true);
                              setShowRejectedDocClearIcon(false);
                            }}
                          />
                        )}
                      </div>
                      <button
                        type="submit"
                        className="flex btn btn_theme2 items-center justify-between w_125"
                        onClick={(e) => {
                          e.preventDefault();
                          setRejectedDocCurrentPage(1);
                          getRejectedDocList();
                        }}
                      >
                        <img src={Search} style={{ width: "1rem" }} />
                        <span className="font-semibold ml-1">
                          {t("allModule.Search")}
                        </span>
                      </button>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="mt-3 rounded-lg">
              {rejectedDocList.length < 1 && (
                <p className="text-red-500 text-sm font-medium">
                  {errMsgRejectedDoc}
                </p>
              )}
            </div>
            <RejectedDocTable
              tableColumns={rejectedDocTableColumns}
              getListData={getRejectedDocList}
              tableData={rejectedDocList}
              nextBtnLimit={rejectedDocNextBtnLimit}
              currentPage={rejectedDocCurrentPage}
              setCurrentPage={setRejectedDocCurrentPage}
              itemCount={rejectedDocCount}
              pageLimit={rejectedDocPageLimit}
              setPageLimit={setRejectedDocPageLimit}
              showPageLimit={true}
              setSortingOrder={setSortingOrderRejectedDoc}
              setSortColumn={setSortColumnRejectedDoc}
              setPreviewDocument={setPreviewDocument}
              setPreviewDocumentData={setPreviewDocumentData}
              showActionBtn
            />
          </div>
        </div>
        <div
          className={`tab_us ${activeTable === "otherSigned" ? "show" : ""}`}
        >
          <div className="my_document_listing mb-7">
            <div className="">
              <div className="row justify-between">
                <div className="col-md-5">
                  <h2 className="text-xl font-semibold">
                    {t("allModule.Other Signed Documents")}
                    <>
                      <img
                        src={HelpIcon}
                        width={12}
                        className="cursor-pointer di_mes"
                        data-tip={t(
                          "allModule.List of Completed Documents set up by Others"
                        )}
                      />
                      <ReactTooltip
                        backgroundColor="#400835"
                        textColor="#ffffff"
                      />
                    </>
                  </h2>
                </div>
                {permissionList["document_search_on"] == true && (
                  <div className="col-md-5">
                    <div className="flex justify-center">
                      <div className="heg_in flex justify-between items-center border-2 border-primary rounded-md rounded-tl-lg rounded-bl-lg bg-inputBackground px-2 w-full mr-2">
                        <input
                          placeholder={t("allModule.Search for Document")}
                          className="px-1 py-1 focus:outline-none bg-transparent w-11/12"
                          value={otherDocSearchText}
                          onChange={(e) => {
                            setOtherDocSearchText(e.target.value);
                            setShowOtherDocClearIcon(true);
                            if (!e.target.value.trim()) {
                              setShowOtherDocClearIcon(false);
                            }
                          }}
                        />
                        {showOtherDocClearIcon && (
                          <img
                            src={CloseIcon}
                            width={14}
                            className="cursor-pointer"
                            onClick={() => {
                              setOtherDocCurrentPage(1);
                              setOtherDocSearchText("");
                              getOtherDocList(true);
                              setShowOtherDocClearIcon(false);
                            }}
                          />
                        )}
                      </div>
                      <button
                        type="submit"
                        className="flex btn btn_theme2 items-center justify-between w_125"
                        onClick={(e) => {
                          e.preventDefault();
                          setOtherDocCurrentPage(1);
                          getOtherDocList();
                        }}
                      >
                        <img src={Search} style={{ width: "1rem" }} />
                        <span className="font-semibold ml-1">
                          {t("allModule.Search")}
                        </span>
                      </button>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="mt-3 rounded-lg">
              {otherDocList.length < 1 && (
                <p className="text-red-500 text-sm font-medium">
                  {errMsgOtherDoc}
                </p>
              )}
            </div>
            <OtherDocTable
              tableColumns={otherDocTableColumns}
              getListData={getOtherDocList}
              tableData={otherDocList}
              showActionBtn
              nextBtnLimit={otherDocNextBtnLimit}
              currentPage={otherDocCurrentPage}
              setCurrentPage={setOtherDocCurrentPage}
              itemCount={otherDocCount}
              pageLimit={otherDocPageLimit}
              setPageLimit={setOtherDocPageLimit}
              showPageLimit={true}
              setSortingOrder={setSortingOrderOtherDoc}
              setSortColumn={setSortColumnOtherDoc}
              setPreviewDocument={setPreviewDocument}
              setPreviewDocumentData={setPreviewDocumentData}
            />
          </div>
        </div>
        <div
          className={`tab_us ${activeTable === "reviewDocument" ? "show" : ""}`}
        >
          <div className="my_document_listing mb-7">
            <div className="">
              <div className="row justify-between">
                <div className="col-md-5">
                  <h2 className="text-xl font-semibold">
                    {t("allModule.Reviewed Documents")}
                    <>
                      <img
                        src={HelpIcon}
                        width={12}
                        className="cursor-pointer di_mes"
                        data-tip={t(
                          "allModule.List of Reports after Document Review"
                        )}
                      />
                      <ReactTooltip
                        backgroundColor="#400835"
                        textColor="#ffffff"
                      />
                    </>
                  </h2>
                </div>
                {permissionList["document_search_on"] == true && (
                  <div className="col-md-5">
                    <div className="flex justify-center">
                      <div className="heg_in flex justify-between items-center border-2 border-primary rounded-md rounded-tl-lg rounded-bl-lg bg-inputBackground px-2 w-full mr-2">
                        <input
                          placeholder={t("allModule.Search for Document")}
                          className="px-1 py-1 focus:outline-none bg-transparent w-11/12"
                          value={reviewDocSearchText}
                          onChange={(e) => {
                            setReviewDocSearchText(e.target.value);
                            setShowReviewDocClearIcon(true);
                            if (!e.target.value.trim()) {
                              setShowReviewDocClearIcon(false);
                            }
                          }}
                        />
                        {showReviewDocClearIcon && (
                          <img
                            src={CloseIcon}
                            width={14}
                            className="cursor-pointer"
                            onClick={() => {
                              setReviewDocCurrentPage(1);
                              setReviewDocSearchText("");
                              getReviewDocList(true);
                              setShowReviewDocClearIcon(false);
                            }}
                          />
                        )}
                      </div>
                      <button
                        type="submit"
                        className="flex btn btn_theme2 items-center justify-between w_125"
                        onClick={(e) => {
                          e.preventDefault();
                          setReviewDocCurrentPage(1);
                          getReviewDocList();
                        }}
                      >
                        <img src={Search} style={{ width: "1rem" }} />
                        <span className="font-semibold ml-1">
                          {t("allModule.Search")}
                        </span>
                      </button>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="mt-3 rounded-lg">
              {reviewDocList.length < 1 && (
                <p className="text-red-500 text-sm font-medium">
                  {errMsgReviewDoc}
                </p>
              )}
            </div>
            <ReviewDocTable
              tableColumns={reviewDocTableColumns}
              getListData={getReviewDocList}
              tableData={reviewDocList}
              showActionBtn
              nextBtnLimit={reviewDocNextBtnLimit}
              currentPage={reviewDocCurrentPage}
              setCurrentPage={setReviewDocCurrentPage}
              itemCount={reviewDocCount}
              pageLimit={reviewDocPageLimit}
              setPageLimit={setReviewDocPageLimit}
              showPageLimit={true}
              setSortingOrder={setSortingOrderReviewDoc}
              setSortColumn={setSortColumnReviewDoc}
              setPreviewDocument={setPreviewDocument}
              setPreviewDocumentData={setPreviewDocumentData}
            />
          </div>
        </div>
        <div className={`tab_us ${activeTable === "deleted" ? "show" : ""}`}>
          <div className="my_document_listing mb-7">
            <div className="">
              <div className="row justify-between">
                <div className="col-md-5">
                  <h2 className="text-xl font-semibold">
                    {t("allModule.Deleted Documents")}
                    <>
                      <img
                        src={HelpIcon}
                        width={12}
                        className="cursor-pointer di_mes"
                        data-tip={t("allModule.List of Deleted Documents")}
                      />
                      <ReactTooltip
                        backgroundColor="#400835"
                        textColor="#ffffff"
                      />
                    </>
                  </h2>
                </div>
                {permissionList["document_search_on"] == true && (
                  <div className="col-md-5">
                    <div className="flex justify-center">
                      <div className="heg_in flex justify-between items-center border-2 border-primary rounded-md rounded-tl-lg rounded-bl-lg bg-inputBackground px-2 w-full mr-2">
                        <input
                          placeholder={t("allModule.Search for Document")}
                          className="px-1 py-1 focus:outline-none bg-transparent w-11/12"
                          value={deletedDocSearchText}
                          onChange={(e) => {
                            setDeletedDocSearchText(e.target.value);
                            setShowDeletedDocClearIcon(true);
                            if (!e.target.value.trim()) {
                              setShowDeletedDocClearIcon(false);
                            }
                          }}
                        />
                        {showDeletedDocClearIcon && (
                          <img
                            src={CloseIcon}
                            width={14}
                            className="cursor-pointer"
                            onClick={() => {
                              setDeletedDocCurrentPage(1);
                              setDeletedDocSearchText("");
                              getDeletedDocList(true);
                              setShowDeletedDocClearIcon(false);
                            }}
                          />
                        )}
                      </div>
                      <button
                        type="submit"
                        className="flex btn btn_theme2 items-center justify-between w_125"
                        onClick={(e) => {
                          e.preventDefault();
                          setDeletedDocCurrentPage(1);
                          getDeletedDocList();
                        }}
                      >
                        <img src={Search} style={{ width: "1rem" }} />
                        <span className="font-semibold ml-1">
                          {t("allModule.Search")}
                        </span>
                      </button>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="mt-3 rounded-lg">
              {deletedDocList.length < 1 && (
                <p className="text-red-500 text-sm font-medium">
                  {errMsgDeletedDoc}
                </p>
              )}
            </div>
            <DeletedDocTable
              tableColumns={deletedDocTableColumns}
              getListData={getDeletedDocList}
              tableData={deletedDocList}
              nextBtnLimit={deletedDocNextBtnLimit}
              currentPage={deletedDocCurrentPage}
              setCurrentPage={setDeletedDocCurrentPage}
              itemCount={deletedDocCount}
              pageLimit={deletedDocPageLimit}
              setPageLimit={setDeletedDocPageLimit}
              showPageLimit={true}
              setSortingOrder={setSortingOrderDeletedDoc}
              setSortColumn={setSortColumnDeletedDoc}
              setPreviewDocument={setPreviewDocument}
              setPreviewDocumentData={setPreviewDocumentData}
              showActionBtn
            />
          </div>
        </div>
        <div className={`tab_us ${activeTable === "shared" ? "show" : ""}`}>
          <div className="my_document_listing mb-7">
            <div className="">
              <div className="row justify-between">
                <div className="col-md-5">
                  <h2 className="text-xl font-semibold">
                    {t("allModule.Shared Documents")}
                    <>
                      <img
                        src={HelpIcon}
                        width={12}
                        className="cursor-pointer di_mes"
                        data-tip={t("allModule.List of Shared Documents")}
                      />
                      <ReactTooltip
                        backgroundColor="#400835"
                        textColor="#ffffff"
                      />
                    </>
                  </h2>
                </div>
                {permissionList["document_search_on"] == true && (
                  <div className="col-md-5">
                    <div className="flex justify-center">
                      <div className="heg_in flex justify-between items-center border-2 border-primary rounded-md rounded-tl-lg rounded-bl-lg bg-inputBackground px-2 w-full mr-2">
                        <input
                          placeholder={t("allModule.Search for Document")}
                          className="px-1 py-1 focus:outline-none bg-transparent w-11/12"
                          value={sharedDocSearchText}
                          onChange={(e) => {
                            setsharedDocSearchText(e.target.value);
                            setShowDeletedDocClearIcon(true);
                            if (!e.target.value.trim()) {
                              setShowDeletedDocClearIcon(false);
                            }
                          }}
                        />
                        {showDeletedDocClearIcon && (
                          <img
                            src={CloseIcon}
                            width={14}
                            className="cursor-pointer"
                            onClick={() => {
                              setDeletedDocCurrentPage(1);
                              setsharedDocSearchText("");
                              getSharedDocList(true);
                              setShowDeletedDocClearIcon(false);
                            }}
                          />
                        )}
                      </div>
                      <button
                        type="submit"
                        className="flex btn btn_theme2 items-center justify-between w_125"
                        onClick={(e) => {
                          e.preventDefault();
                          setDeletedDocCurrentPage(1);
                          getSharedDocList();
                        }}
                      >
                        <img src={Search} style={{ width: "1rem" }} />
                        <span className="font-semibold ml-1">
                          {t("allModule.Search")}
                        </span>
                      </button>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="mt-3 rounded-lg">
              {sharedDocList.length < 1 && (
                <p className="text-red-500 text-sm font-medium">
                  {errMsgDeletedDoc}
                </p>
              )}
            </div>
            <SharedDocument
              tableColumns={sharedDocTableColumns}
              getListData={getSharedDocList}
              tableData={sharedDocList}
              nextBtnLimit={deletedDocNextBtnLimit}
              currentPage={deletedDocCurrentPage}
              setCurrentPage={setDeletedDocCurrentPage}
              itemCount={deletedDocCount}
              pageLimit={deletedDocPageLimit}
              setPageLimit={setDeletedDocPageLimit}
              showPageLimit={true}
              setSortingOrder={setSortingOrderDeletedDoc}
              setSortColumn={setSortColumnDeletedDoc}
              setPreviewDocument={setPreviewDocument}
              setPreviewDocumentData={setPreviewDocumentData}
              showActionBtn
            />
          </div>
        </div>
      </div>

      <DocumentPreviewModal
        isOpen={previewDocument}
        setIsOpen={setPreviewDocument}
        previewDocumentData={previewDocumentData}
      />
    </div>
  );
};
export default MyDocuments;
