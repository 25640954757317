import React from 'react';
import Settings from '../../assets/icons/Settings.svg';
import UserImg from '../../assets/icons/User.svg';
import subscriptionImg from '../../assets/icons/plan.svg';
import aadhaar_quota from '../../assets/icons/aadhar.svg';
import lawfirm2 from '../../assets/icons/lawfirm2.png';
import legel_document from '../../assets/icons/legal.svg';
import LogsIcon from '../../assets/icons/log.png';
import Marketplace from "../../assets/icons/marketPlace.png";
import { useTranslation } from 'react-i18next';
// for legacy changes starts:-
import Company from '../../assets/icons/Company.svg';

import review_document from  "../../assets/icons/review_document.png";
const SuperAdmin = ({ activeLink, setActiveLink, activePrevLink, setShowDropdown, showDropdown, clickOnLeftMenu, currentForm }) => {
  const { t } = useTranslation();
  const localPrevActiveLink = localStorage.getItem('activePrevLink')?localStorage.getItem('activePrevLink'):'';

  return (
    <>
      <li
        onClick={(event) => {
          event.stopPropagation();
          setActiveLink('AdminList')
        }}
        className={activePrevLink === 'AdminList'  || activePrevLink === 'AdminUserList' ? 'active-link' : undefined}
      >
        <a>
          <img src={UserImg} />
          <span>{t('menu.List of Admins')}</span>

        </a>
      </li>
      <li
        onClick={(event) => {
          event.stopPropagation();
          setActiveLink('MainUserList')
        }}
        className={activePrevLink === 'MainUserList'  ? 'active-link' : undefined}
      >
        <a>
          <img src={UserImg} />
          <span>{t('menu.List of Users')}</span>

        </a>
      </li>
      {/* <li
        onClick={(event) => {
          event.stopPropagation();
          setActiveLink('Companieslegacy')
        }}
        className={activePrevLink === 'Companieslegacy'  ? 'active-link' : undefined}
      >
        <a>
          <img src={Company} />
          <span>{t('menu.Company')}</span>

        </a>
      </li> */}
      {/* <li
        onClick={(event) => {
          event.stopPropagation();
          setActiveLink('Adminslegacy')
        }}
        className={activePrevLink === 'Adminslegacy'  ? 'active-link' : undefined}
      >
        <a>
          <img src={Company} />
          <span>{t('menu.Adminslegacy')}</span>

        </a>
      </li> */}
      {/* <li
        onClick={() => setActiveLink('Reviewerslegacy')}
        
        className={activeLink === 'Reviewerslegacy'|| currentForm === 'ReviewerFormlegacy' ? 'active-link' : undefined}
      >
        <a>
        <img src={UserImg} />
        <span>{t('menu.Reviewers')}</span>
        </a>
      </li> */}
      {/* <li
        onClick={() => setActiveLink('Userslegacy')}
        className={activeLink === 'Userslegacy' ? 'active-link' : undefined}
      >
        <a>
        <img src={UserImg} />
        <span>{t('menu.Users-Legacy')}</span>
        </a>
      </li> */}


      <li
        onClick={(event) => {
          event.stopPropagation();
          setActiveLink('DemoUserList')
        }}
        className={activePrevLink === 'DemoUserList' || activePrevLink === 'DemoUserForm'  ? 'active-link' : undefined}
      >
        <a>
          <img src={UserImg} />
          <span>{t('menu.List of Corporate Users')}</span>
        </a>
      </li>
      
      <li
        onClick={(event) => {
          event.stopPropagation();
          setActiveLink('SuperAdminPlanList')
        }}
        className={activePrevLink === 'SuperAdminPlanList' ? 'active-link' : undefined}
      >
        <a>
          <img src={subscriptionImg} />

          <span>{t('menu.Subscription Plan')}</span>

        </a>
      </li>
      <li
        onClick={(event) => {
          event.stopPropagation();
          setActiveLink('AadharQuotaList')
        }}
        className={activePrevLink === 'AadharQuotaList' ? 'active-link' : undefined}
      >
        <a>
          <img src={aadhaar_quota} />
          <span>{t('menu.Aadhaar Quota')}</span>
        </a>
      </li>
      <li
        onClick={(event) => {
          event.stopPropagation();
          setActiveLink('AdminMarketPlace')
        }}
        className={activePrevLink === 'AdminMarketPlace' ? 'active-link' : undefined}
      >
        <a>
          <img src={Marketplace} />
          <span>{t('menu.Admin Marketplace')}</span>
        </a>
      </li>
      <li
        onClick={(event) => {
          event.stopPropagation();
          setActiveLink('AadharPurchaseList')
        }}
        className={activePrevLink === 'AadharPurchaseList' ? 'active-link' : undefined}
      >
        <a>
          <img src={aadhaar_quota} />
          <span>{t('menu.Aadhaar Purchase List')}</span>
        </a>
      </li>

      <li
        onClick={(event) => {
          event.stopPropagation();
          setActiveLink('LegalDocumentList')
        }}
        className={activePrevLink === 'LegalDocumentList' || activePrevLink === 'LegalDocumentForm' ? 'active-link' : undefined}
      >
        <a>
          <img src={legel_document} />
          <span>{t('menu.Legal Document List')}</span>

        </a>
      </li>
      <li
        onClick={(event) => {
          event.stopPropagation();
          setActiveLink('ClauseLibaryList')
        }}
        className={activePrevLink === 'ClauseLibaryList' || activePrevLink === 'LegalWorkflowForm' ? 'active-link' : undefined}
      >
        <a>
          <img src={legel_document} />
          <span>{t('Clause Libary')}</span>

        </a>
      </li>

      <li
        onClick={(event) => {
          event.stopPropagation();
          setActiveLink('ReviewSection')
          setShowDropdown(showDropdown === "ReviewSection" ? undefined : "ReviewSection")
        }}
      >
        <a><img src={review_document} />
          <span>{t('menu.Review Task')}</span>

          <span className='Arrow' style={{ transform: showDropdown === 'ReviewSection' ? 'rotate(0deg)' : 'rotate(270deg)' }} ><svg className="h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
            <path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd" />
          </svg></span>
        </a>
        {showDropdown == 'ReviewSection' && (
          <ul className='sub_menu show img_us'>
            <li
              onClick={(event) => {
                event.stopPropagation();
                setActiveLink('ReviewSection')
                clickOnLeftMenu();
              }}
              className={activePrevLink === 'ReviewSection' ? 'active-link' : undefined}
            >
              <a >
                <img src={review_document} />
                <span>{t('menu.Section')}</span>
              </a>
            </li>

            <li
              onClick={(event) => {
                event.stopPropagation();
                clickOnLeftMenu();
                setActiveLink('ReviewSectionTask')
              }}
              className={
                activePrevLink === 'ReviewSectionTask'
                  ? 'active-link'
                  : undefined
              }
            >
              <a> <img src={review_document} />
                <span>{t('menu.Task')}</span></a>
            </li>
          </ul>
        )}
      </li>
      
      <li
        onClick={(event) => {
          event.stopPropagation();
          setActiveLink('Account')
        }}
        className={activePrevLink === 'Account' ? 'active-link' : undefined}
      >
        <a>
          <img src={Settings} />
          <span>{t('menu.Account')}</span>

        </a>
      </li>

      <li
        onClick={(event) => {
          event.stopPropagation();
          setActiveLink('Logs')
        }}
        className={activePrevLink === 'Logs' ? 'active-link' : undefined}
      >
        <a>
          <img src={LogsIcon} />
          <span>{t('menu.Logs')}</span>

        </a>
      </li>
    </>
  );
};

export default SuperAdmin;
