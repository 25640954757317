import React from 'react';
import Modal from 'react-modal';
import './modal.css';
import Signature from './SignatureCanvas';
import PhotoModel from './PhotoModel';
const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        maxWidth: '600px',
        // maxHeight: '380px',
        borderRadius: '11px',
    },
    overlay: {
        backgroundColor: 'rgba(64, 8, 53, 0.63)',
    },
};


const SignatureModal = ({
    isSignModalOpen,
    setIsSignModalOpen,
    canvasType,
    setCanvasType,
    setSrc,
    setCompanyName,
    companyName,
    imgSrc,
    setImgSrc,
    setStream,
    stream,
    showWebcam,
    setShowWebcam,
    videoRef,
    recptId,
}) => {


    return (
        <div className=''>
            <Modal
                isOpen={isSignModalOpen}
                onRequestClose={() => setIsSignModalOpen(false)}
                style={customStyles}
                contentLabel='Example Modal'
                onAfterClose={() => { }}
            >
                <div className='text-center modal-container'>
                    {canvasType === 'photo' ?
                        <PhotoModel
                            setSrc={setSrc}
                            imgSrc={imgSrc}
                            setImgSrc={setImgSrc}
                            setIsSignModalOpen={setIsSignModalOpen}
                            stream={stream} 
                            setStream={setStream}
                            canvasType={canvasType}
                            showWebcam={showWebcam}
                            setShowWebcam={setShowWebcam}
                            videoRef={videoRef}
                            recptId={recptId}
                        />
                        :
                        <Signature
                            setIsSignModalOpen={setIsSignModalOpen}
                            canvasType={canvasType}
                            setSrc={setSrc}
                            setCompanyName={setCompanyName}
                            companyName={companyName}
                            recptId={recptId}
                        />
                    }

                </div>
            </Modal>
        </div>
    );
};

export default SignatureModal;
