import React, { useContext, useState } from "react";
import AppContext from "../context/AppContext";
import Group from "../assets/icons/group.png";
import PermissionContext from "../context/PermissionContext";
import FormContext from "../context/FormContext";
import AppModal from "../components/modals/Modal";
import { resolve } from "../api/resolve";
import useAxios from "../hooks/useAxios";
import AadharQuotaModel from "../components/modals/AadharQuotaModel";
import { useTranslation } from "react-i18next";

const AddButton = ({ text, currentForm, getAadharQuotaList, adminId }) => {
  const { setActiveLink } = useContext(AppContext);
  const { permissionList } = useContext(PermissionContext);

  const { modalData, setIsOpen, modalIsOpen, setModalData, setIsEditing } =
    useContext(FormContext);
  const [openAadharQuotaModal, setOpenAadharQuotaModal] = useState(false);

  const api = useAxios();
  const { t } = useTranslation();
  const checkDocumentVerified = async () => {
    const res = await resolve(
      api
        .get(`/user/detail/?AdminId=${adminId}`, {
          headers: {
            "content-type": "application/json",
          },
        })
        .then((res) => res.data)
    );

    if (res.data) {
      if (res.data.document_status === 2) {
        return true;
      }
    }
    return false;
  };

  const handleCheckQuota = async (userId) => {
    const res = await resolve(
      api.get(`/contract/User_Quota/${userId}`).then((res) => res.data)
    );
    
    if (res.data) {
      return res.data;
    }

    if (res.error) {
      return {
        user_allotted_cnt: 5,
        user_remaing_cnt: 0,
      };
    }
  };

  const onBtnClick = async () => {
    console.log("form name", currentForm)
    switch (currentForm) {
      case "Companies":
        return setActiveLink("CompanyForm");
      case "Admins":
        return setActiveLink("AdminForm");
      case "Users":
        return setActiveLink("UserForm");
      case "Reviewers":
        return setActiveLink("ReviewerForm");
      case "Contracts":
        return setActiveLink("ContractForm");
      case "ContactBookForm":
        return setActiveLink("ContactBookForm");
      case "GroupForm":
        return setActiveLink("GroupForm");
      case "PlanForm":
        setIsEditing(false);
        return setActiveLink("PlanForm");
      case "PlanCorporateForm":
        setIsEditing(false);
        return setActiveLink("PlanCorporateForm");

      case "DemoUserForm":
        setIsEditing(false);
        return setActiveLink("DemoUserForm");
      case "Office365SettingForm":
        setIsEditing(false);
        return setActiveLink("Office365SettingForm");

      case "AdminUserForm":
        if (
          permissionList["user_allotted_cnt"] == -1 ||
          (permissionList["user_allotted_cnt"] > 0 &&
            permissionList["user_remaing_cnt"] > 0)
        ) {
          checkDocumentVerified().then((res) => {
            if (res) {
              return setActiveLink("AdminUserForm");
            } else {
              setIsOpen(true);
              setModalData({
                ...modalData,
                title: t("form.Permission Denied"),
                bodyText: t(
                  "form.Please upload Company documents in the Account section for verification"
                ),
                apiResStatus: true,
              });
            }
          });
        } else {
          setIsOpen(true);
          setModalData({
            ...modalData,
            title: t("form.Permission Denied"),
            bodyText: t("form.You dont have permission to add more user."),
            apiResStatus: true,
          });
        }
        return 1;
      case "AdminUserFormBySA":
        let permissionListInfo = await handleCheckQuota(adminId);
        
        if (
          permissionListInfo["user_allotted_cnt"] == -1 ||
          (permissionListInfo["user_allotted_cnt"] > 0 &&
          permissionListInfo["user_remaing_cnt"] > 0)
        ) {
          checkDocumentVerified().then((res) => {
            if (res) {
              setIsEditing(false);
              return setActiveLink("AdminUserFormBySA");
            } else {
              setIsOpen(true);
              setModalData({
                ...modalData,
                title: t("form.Permission Denied"),
                bodyText: t(
                  "form.Please upload Company documents in the Account section for verification"
                ),
                apiResStatus: true,
              });
            }
          });
        } else {
          setIsOpen(true);
          setModalData({
            ...modalData,
            title: t("form.Permission Denied"),
            bodyText: t("form.You dont have permission to add more user."),
            apiResStatus: true,
          });
        }
        return 1;
      case "AadharQuotaForm":
        setOpenAadharQuotaModal(true);
        return 1;

      case "LegalDocumentForm":
        setIsEditing(false);
        return setActiveLink("LegalDocumentForm");

      case "MarketPlaceForm":
        setIsEditing(false);
        return setActiveLink("MarketPlaceForm");
      case "ClauseLibaryForm":
        setIsEditing(false);
        return setActiveLink("ClauseLibaryForm");

      case "SectionForm":
        setIsEditing(false);
        return setActiveLink("SectionForm");

      case "SectionTaskForm":
        setIsEditing(false);
        return setActiveLink("SectionTaskForm");

      case "Userslegacy":
        return setActiveLink("UserFormlegacy");
      case "Adminslegacy":
        return setActiveLink("AdminFormlegacy");
      case "Reviewerslegacy":
        return setActiveLink("ReviewerFormlegacy");
      case "Companieslegacy":
        return setActiveLink("CompanyFormlegacy");
      default:
          return 1;
    }
  };

  return (
    <>
      <button className="btn btn_theme2" onClick={() => onBtnClick()}>
        <span className="flex items-center">
          <img src={Group} className="img_sm2" alt="img" />
          <span className="font-semibold ml-2">{text}</span>
        </span>
      </button>

      <AppModal
        title={modalData.title}
        bodyText={modalData.bodyText}
        setIsOpen={setIsOpen}
        modalIsOpen={modalIsOpen}
        modelAction="AdminUserForm"
        apiResStatus={modalData.apiResStatus}
      />

      <AadharQuotaModel
        isOpen={openAadharQuotaModal}
        setIsOpen={setOpenAadharQuotaModal}
        getAadharQuotaList={getAadharQuotaList}
      />
    </>
  );
};

export default AddButton;
