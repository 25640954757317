import React, { useContext, useEffect, useState } from "react";
import contactBookIcon from "../../assets/icons/ContactBook.svg";
import uploadDocIcon from "../../assets/icons/UploadDoc.svg";
import transactionsIcon from "../../assets/icons/transactions.svg";
import myDocIcon from "../../assets/icons/myDoc.png";
import attach_w from "../../assets/icons/attach_w.png";
import automagic_w from "../../assets/icons/automagic_w.png";
import manual_w from "../../assets/icons/manual_w.png";
import presets_w from "../../assets/icons/presets_w.png";
import printing_w from "../../assets/icons/printing_w.png";
import document_w from "../../assets/icons/document_w.png";
import electronic_s from "../../assets/icons/electronic-signature.png";
// import notification_w from "../../assets/icons/notification_w.png";
// import docsearch from "../../assets/icons/document_search.png";
// import recipient_w from "../../assets/icons/recipient_w.png";
import recipient_w from "../../assets/icons/recipients.svg";
import chatai from "../../assets/icons/chat_with_doc.png";
import qbot from "../../assets/icons/chat.png";
import Marketplace from "../../assets/icons/marketPlace.png";
import stamp_w from "../../assets/icons/stamp.svg";
import template_w from "../../assets/icons/template_w.png";
import upload_do_w from "../../assets/icons/upload_do_w.png";
import wallet_w from "../../assets/icons/wallet_w.png";
import workflow_w from "../../assets/icons/setSigning.svg";
import Help from "../../assets/icons/Help.svg";
import Settings from "../../assets/icons/Settings.svg";
import UserImg from "../../assets/icons/User.svg";
import template_library from "../../assets/icons/temple_library_w.svg";
import DocumentContext from "../../context/DocumentContext";
import review_document from "../../assets/icons/review_document.png";
import Diligence from "../../assets/icons/Diligence.png";
import NDA from "../../assets/icons/NDA.png";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { use } from "i18next";

import { resolve } from "../../api/resolve";
import useAxios from "../../hooks/useAxios";
// import task from "../../assets/icons/task_icon.png";
import manageDocIcon from "../../assets/icons/manageDocuments.png";
// import workflowDocIcon from "../../assets/icons/workflow_w.png";
import workflowDocIcon from "../../assets/icons/setupworkflows.svg";
// import rejectDocIcon from "../../assets/icons/rejectedDocumnet.png";
import rejectDocIcon from "../../assets/icons/rejecteddocuments.svg";
import reviewDocIcon from "../../assets/icons/reviewDocuments.png";
import deleteDocIcon from "../../assets/icons/deleteDocument.png";
import pendingDocIcon from "../../assets/icons/pendingSign.png";
import completedDocIcon from "../../assets/icons/completedDocument.png";
// import addfilesrd from "../../assets/icons/addfilesrd.png";
// import addfilesrd1 from "../../assets/icons/Addcontract1.svg";
// import Addcontract1 from "../../assets/icons/Addcontract1.svg";
import ipReviewIcon from "../../assets/icons/ip.png";
import shareDocument from "../../assets/icons/shareDocument.svg";
import browseDocument from "../../assets/icons/browseDocument21.svg";
import share from "../../assets/icons/share.svg";
import { useLocation } from "react-router-dom";
import Search from "../../assets/icons/Search.svg";
// import Home from "../../assets/icons/Home.svg";
// import AddContract from "../../assets/icons/Add-Contract.svg";

const AdminUser = ({
  activeLink,
  setActiveLink,
  showDropdown,
  setShowDropdown,
  user,
  recipientCount,
  activePrevLink,
}) => {
  const location = useLocation();

  useEffect(() => {
    if (location.pathname === "/my-documents/set-up-signatures") {
      setActiveDocLink("ManageDocuments");
    } else if (location.pathname === "/my-documents/set-up-workflows") {
      setActiveDocLink("SetupWorkflows");
    } else if (location.pathname === "/my-documents/pending-for-signatures") {
      setActiveDocLink("PendingSignature");
    } else if (location.pathname === "/my-documents/completed-documents") {
      setActiveDocLink("CompletedDocuments");
    } else if (location.pathname === "/my-documents/rejected-documents") {
      setActiveDocLink("RejectedDocuments");
    } else if (location.pathname === "/my-documents/other-signed-documents") {
      setActiveDocLink("OtherSignedDocuments");
    } else if (location.pathname === "/my-documents/review-documents") {
      setActiveDocLink("ReviewDocuments");
    } else if (location.pathname === "/my-documents/deleted-documents") {
      setActiveDocLink("DeletedDocuments");
    } else if (
      location.pathname === "/dashboard" ||
      location.pathname === "/help" ||
      location.pathname === "/contact-book/contact" ||
      location.pathname === "/Qflow"
    ) {
      setActiveDocLink("");
      setShowDropdown(undefined); // Optionally close dropdown if it's open
    } else {
      setActiveDocLink("");
    }
  }, [location.pathname]);

  const navigate = useNavigate();
  const { forSignatureList } = useContext(DocumentContext);
  const { t } = useTranslation();
  const api = useAxios();
  const [userPlanDetail, setUserPlanDetail] = useState({ name: "-" });
  const [activeDocLink, setActiveDocLink] = useState("");

  const getplanDetail = async () => {
    const res = await resolve(
      api
        .get(`/user/plan/name/`, {
          headers: {
            "content-type": "application/json",
          },
        })
        .then((res) => res.data)
    );
    if (res.data) {
      setUserPlanDetail({ name: res.data["data"].name });
    }
  };

  useEffect(() => {
    getplanDetail();
  }, []);

  return (
    <>
      <li
        onClick={(event) => {
          event.stopPropagation();
          setActiveLink("MyDocuments");
          setActiveDocLink("MyDocuments");
          setShowDropdown(
            showDropdown === "MyDocuments" ? undefined : "MyDocuments"
          );
          // setShowDropdown(undefined);
          navigate("/my-documents/set-up-signatures");
        }}
        className={
          (activeDocLink == "MyDocuments" &&
            activePrevLink === "MyDocuments") ||
          activePrevLink == "EditDocument365"
            ? "active-link"
            : undefined
        }
      >
        <a>
          <img src={myDocIcon} />
          <span>{t("menu.My Documents")}</span>
          <span
            className="Arrow"
            style={{
              transform:
                showDropdown === "MyDocuments"
                  ? "rotate(0deg)"
                  : "rotate(270deg)",
            }}
          >
            <svg
              className="h-4 w-4"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fillRule="evenodd"
                d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                clipRule="evenodd"
              />
            </svg>
          </span>
        </a>

        {showDropdown == "MyDocuments" && (
          <ul className="sub_menu show img_us">
            <li
              onClick={(event) => {
                event.stopPropagation();
                // setShowDropdown(undefined); // Optionally close dropdown if it's open
                setActiveDocLink("ManageDocuments");
                navigate("/my-documents/set-up-signatures");
              }}
              className={
                activeDocLink === "ManageDocuments" ? "active-link" : undefined
              }
            >
              <a>
                <img src={manageDocIcon} />
                <span>{t("allModule.Manage Documents")}</span>
              </a>
            </li>

            <li
              onClick={(event) => {
                event.stopPropagation();
                setActiveDocLink("SetupWorkflows");
                navigate("/my-documents/set-up-workflows");
              }}
              className={
                activeDocLink === "SetupWorkflows" ? "active-link" : undefined
              }
            >
              <a>
                <img src={workflowDocIcon} />
                <span>{t("allModule.Processing Queue")}</span>
              </a>
            </li>

            <li
              onClick={(event) => {
                event.stopPropagation();
                setActiveDocLink("PendingSignature");
                navigate("/my-documents/pending-for-signatures");
              }}
              className={
                activeDocLink === "PendingSignature" ? "active-link" : undefined
              }
            >
              <a>
                <img src={pendingDocIcon} />
                <span>{t("allModule.Pending for Signatures")}</span>
              </a>
            </li>

            <li
              onClick={(event) => {
                event.stopPropagation();
                setActiveDocLink("CompletedDocuments");
                navigate("/my-documents/completed-documents");
              }}
              className={
                activeDocLink === "CompletedDocuments"
                  ? "active-link"
                  : undefined
              }
            >
              <a>
                <img src={completedDocIcon} />
                <span>{t("allModule.Completed Documents")}</span>
              </a>
            </li>

            <li
              onClick={(event) => {
                event.stopPropagation();
                setActiveDocLink("RejectedDocuments");
                navigate("/my-documents/rejected-documents");
              }}
              className={
                activeDocLink === "RejectedDocuments"
                  ? "active-link"
                  : undefined
              }
            >
              <a>
                <img src={rejectDocIcon} />
                <span>{t("allModule.Rejected Documents")}</span>
              </a>
            </li>

            <li
              onClick={(event) => {
                event.stopPropagation();
                setActiveDocLink("OtherSignedDocuments");
                navigate("/my-documents/other-signed-documents");
              }}
              className={
                activeDocLink === "OtherSignedDocuments"
                  ? "active-link"
                  : undefined
              }
            >
              <a>
                <img src={completedDocIcon} />
                <span>{t("allModule.Other Signed Documents")}</span>
              </a>
            </li>
            <li
              onClick={(event) => {
                event.stopPropagation();
                setActiveDocLink("ReviewDocuments");
                navigate("/my-documents/review-documents");
              }}
              className={
                activeDocLink === "ReviewDocuments" ? "active-link" : undefined
              }
            >
              <a>
                <img src={reviewDocIcon} />
                <span>{t("allModule.Reviewed Documents")}</span>
              </a>
            </li>
            <li
              onClick={(event) => {
                event.stopPropagation();
                setActiveDocLink("DeletedDocuments");
                navigate("/my-documents/deleted-documents");
              }}
              className={
                activeDocLink === "DeletedDocuments" ? "active-link" : undefined
              }
            >
              <a>
                <img src={deleteDocIcon} />
                <span>{t("allModule.Deleted Documents")}</span>
              </a>
            </li>
            <li
              onClick={(event) => {
                event.stopPropagation();
                setActiveDocLink("shared");
                navigate("/my-documents/shared-documents");
              }}
              className={activeDocLink === "shared" ? "active-link" : undefined}
            >
              <a>
                <img src={share} />
                <span>{t("allModule.Shared Documents")}</span>
              </a>
            </li>
          </ul>
        )}

        {showDropdown == "AffixMySignature" && (
          <ul className="sub_menu show img_us">
            <li
              onClick={(event) => {
                event.stopPropagation();
                setActiveLink("AffixMySignature");
                setShowDropdown("AffixMySignature");
              }}
              className={
                activePrevLink === "AffixMySignature"
                  ? "active-link"
                  : undefined
              }
            >
              <a>
                <img src={electronic_s} />
                <span>{t("menu.Affix My Signature")}</span>
              </a>
            </li>
          </ul>
        )}
      </li>

      <li
        onClick={(event) => {
          event.stopPropagation();
          setActiveLink("CreateDocument");
          navigate("/create-document");
          setShowDropdown(
            showDropdown === "CreateDocument" ? undefined : "CreateDocument"
          );
        }}
        className={
          activePrevLink === "CreateDocument" ? "active-link" : undefined
        }
      >
        <a>
          <img src={document_w} />
          <span>{t("menu.Create Documents")}</span>
          <span
            className="Arrow"
            style={{
              transform:
                showDropdown === "CreateDocument"
                  ? "rotate(0deg)"
                  : "rotate(270deg)",
            }}
          >
            <svg
              className="h-4 w-4"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fillRule="evenodd"
                d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                clipRule="evenodd"
              />
            </svg>
          </span>
        </a>

        {showDropdown == "CreateDocument" && (
          <ul className="sub_menu show img_us">
            <li
              onClick={(event) => {
                event.stopPropagation();
                navigate("/generate-document");
                setActiveLink("GenerateDocument");
              }}
              className={
                activePrevLink === "GenerateDocument"
                  ? "active-link"
                  : undefined
              }
            >
              <a>
                <img src={document_w} />
                <span>{t("menu.Generate Document")}</span>
              </a>
            </li>

            <li
              onClick={(event) => {
                event.stopPropagation();
                navigate("/create-template");
                setActiveLink("CreateTemplate");
              }}
              className={
                activePrevLink === "CreateTemplate" ? "active-link" : undefined
              }
            >
              <a>
                <img src={template_w} />
                <span>{t("menu.Create from Template")}</span>
              </a>
            </li>
            <li
              onClick={(event) => {
                event.stopPropagation();
                navigate("/template-library");
                setActiveLink("CreateTemplateLibrary");
              }}
              className={
                activePrevLink === "CreateTemplateLibrary"
                  ? "active-link"
                  : undefined
              }
            >
              <a>
                <img src={template_library} />
                <span>{t("menu.Select from Template Library")}</span>
              </a>
            </li>

            <li
              onClick={(event) => {
                event.stopPropagation();
                navigate("/upload-O365-documents");
                setActiveLink("ContractO365Form");
              }}
              className={
                activePrevLink === "ContractO365Form"
                  ? "active-link"
                  : undefined
              }
            >
              <a>
                <img src={upload_do_w} />
                <span>{t("constant.Upload Document for Collaboration")}</span>
              </a>
            </li>
          </ul>
        )}
      </li>

      {/* <li
        onClick={(event) => {
          event.stopPropagation();
          navigate("/Qflow");
          setActiveLink("Qflow");
        }}
        className={activePrevLink === "Qflow" ? "active-link" : undefined}
      >
        <a>
          <img src={template_library} />
          <span>{t("menu.Qflow")}</span>
        </a>
      </li> */}
      <li
        onClick={(event) => {
          event.stopPropagation();
          setActiveLink("ReviewDocument");
          navigate("/ai-review/nda");

          setShowDropdown(
            showDropdown === "ReviewDocument" ? undefined : "ReviewDocument"
          );
        }}
      >
        <a>
          <img src={review_document} />
          <span>{t("allModule.Review Documents")}</span>

          <span
            className="Arrow"
            style={{
              transform:
                showDropdown === "ReviewDocument" ||
                showDropdown == "QuoqoPlayrite"
                  ? "rotate(0deg)"
                  : "rotate(270deg)",
            }}
          >
            <svg
              className="h-4 w-4"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fillRule="evenodd"
                d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                clipRule="evenodd"
              />
            </svg>
          </span>
        </a>
        {(showDropdown == "ReviewDocument" ||
          showDropdown == "QuoqoPlayrite") && (
          <ul className="sub_menu show img_us">
            <li
              onClick={(event) => {
                event.stopPropagation();
                navigate("/ai-review/nda");
                setActiveLink("ReviewDocument");
              }}
              className={
                activePrevLink === "ReviewDocument" ? "active-link" : undefined
              }
            >
              <a>
                <img src={NDA} />
                <span>{t("constant.Review NDA")}</span>
              </a>
            </li>

            <li
              onClick={(event) => {
                event.stopPropagation();
                navigate("/ai-review/diligence");
                setActiveLink("QuoqoDiligence");
              }}
              className={
                activePrevLink === "QuoqoDiligence" ? "active-link" : undefined
              }
            >
              <a>
                {" "}
                <img src={Diligence} />
                <span>{t("constant.Review a Contract")}</span>
              </a>
            </li>

            <li
              onClick={(event) => {
                event.stopPropagation();
                navigate("/ai-review/transaction");
                setActiveLink("QuoqoTransaction");
              }}
              className={
                activePrevLink === "QuoqoTransaction"
                  ? "active-link"
                  : undefined
              }
            >
              <a>
                {" "}
                <img src={transactionsIcon} />
                <span>{t("constant.Review a Transaction Document")}</span>
              </a>
            </li>

            <li
              onClick={(event) => {
                event.stopPropagation();
                navigate("/ai-review/ip");
                setActiveLink("QuoqoIP");
              }}
              className={
                activePrevLink === "QuoqoIP" ? "active-link" : undefined
              }
            >
              <a>
                {" "}
                <img src={ipReviewIcon} />
                <span>{t("constant.IP Review")}</span>
              </a>
            </li>
            <li
              onClick={(event) => {
                event.stopPropagation();
                navigate("/ai-review/QuoqoSectoralReview");
                setActiveLink("QuoqoSectoralReview");
              }}
              className={
                activePrevLink === "QuoqoSectoralReview"
                  ? "active-link"
                  : undefined
              }
            >
              <a>
                {" "}
                <img src={reviewDocIcon} />
                <span>{t("constant.Sectoral Review")}</span>
              </a>
            </li>

            <li>
              <a
                href="https://qchat.q-bot.ai/"
                target="_blank"
                style={{ display: "block", textDecoration: "none" }}
              >
                <img src={chatai} alt="Q-DocChat" />
                {t("allModule.Q-DocChat")}
              </a>
            </li>
            <li
              onClick={(event) => {
                event.stopPropagation();
                setActiveLink("CreatePlaybook");
                navigate("/playrite/create-playbook");

                setShowDropdown(
                  showDropdown === "QuoqoPlayrite"
                    ? "ReviewDocument"
                    : "QuoqoPlayrite"
                );
              }}
            >
              <a>
                <img src={review_document} />
                <span>{t("allModule.Playrite")}</span>

                <span
                  // className="Arrow"
                  style={{
                    transform:
                      showDropdown === "QuoqoPlayrite"
                        ? "rotate(0deg)"
                        : "rotate(270deg)",
                    position: "absolute",
                    right: "13px",
                    top: "10px",
                  }}
                >
                  <svg
                    className="h-5 w-5"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fillRule="evenodd"
                      d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                      clipRule="evenodd"
                    />
                  </svg>
                </span>
              </a>
              {showDropdown == "QuoqoPlayrite" && (
                <ul className="sub_menu show img_us">
                  <li
                    onClick={(event) => {
                      event.stopPropagation();
                      navigate("/playrite/create-playbook");
                      setActiveLink("CreatePlaybook");
                    }}
                    className={
                      activePrevLink === "CreatePlaybook"
                        ? "active-link"
                        : undefined
                    }
                  >
                    <a>
                      <img src={NDA} />
                      <span>{t("constant.Create a Playbook")}</span>
                    </a>
                  </li>

                  <li
                    onClick={(event) => {
                      event.stopPropagation();
                      navigate("/playrite/view-playbook");
                      setActiveLink("ViewPlaybookList");
                    }}
                    className={
                      activePrevLink === "ViewPlaybookList"
                        ? "active-link"
                        : undefined
                    }
                  >
                    <a>
                      {" "}
                      <img src={Diligence} />
                      <span>{t("constant.View a Playbook")}</span>
                    </a>
                  </li>

                  <li
                    onClick={(event) => {
                      event.stopPropagation();
                      navigate("/playrite/deviation-analysis");
                      setActiveLink("DeviationAnalysis");
                    }}
                    className={
                      activePrevLink === "DeviationAnalysis"
                        ? "active-link"
                        : undefined
                    }
                  >
                    <a>
                      {" "}
                      <img src={transactionsIcon} />
                      <span>{t("constant.Playrite Tasks")}</span>
                    </a>
                  </li>
                </ul>
              )}
            </li>
          </ul>
        )}
      </li>

      <li
        onClick={(event) => {
          event.stopPropagation();
          setActiveLink("ContractForm");
          navigate("/upload-documents");
          setShowDropdown(
            showDropdown === "Recipients" ? undefined : "Recipients"
          );
        }}
      >
        <a>
          <img src={uploadDocIcon} />
          <span>{t("menu.Sign Documents")}</span>
          <span
            className="Arrow"
            style={{
              transform:
                showDropdown === "Recipients"
                  ? "rotate(0deg)"
                  : "rotate(270deg)",
            }}
          >
            <svg
              className="h-4 w-4"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fillRule="evenodd"
                d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                clipRule="evenodd"
              />
            </svg>
          </span>
        </a>

        {showDropdown == "Recipients" && (
          <ul className="sub_menu show img_us">
            <li
              onClick={(event) => {
                event.stopPropagation();
                navigate("/upload-documents");
                setActiveLink("ContractForm");
              }}
              className={
                activePrevLink === "ContractForm" ? "active-link" : undefined
              }
            >
              <a>
                <img src={upload_do_w} />
                <span>{t("menu.Upload Documents")}</span>
              </a>
            </li>
            <li
              onClick={(event) => {
                event.stopPropagation();
                if (forSignatureList.length > 0) {
                  setActiveLink("SelectedDocRecipients");
                } else {
                  setActiveLink("Recipients");
                }
                navigate("/apply-signature/select-recipients");
              }}
              className={
                activePrevLink === "Recipients" ||
                activePrevLink === "SelectedDocRecipients"
                  ? "active-link"
                  : undefined
              }
            >
              <a>
                <img src={recipient_w} />
                <span>{t("menu.Select Recipients")}</span>
              </a>
            </li>
            <li
              onClick={(event) => {
                event.stopPropagation();
                setActiveLink("Workflows");
                navigate("/apply-signature/select-workflows");
              }}
              className={
                activePrevLink === "Workflows" ? "active-link" : undefined
              }
            >
              <a>
                <img src={workflow_w} />
                <span>{t("menu.Set Signing Order")}</span>
              </a>
            </li>
            <li
              onClick={(event) => {
                event.stopPropagation();
                setActiveLink("StampsAndSeals");
                navigate("/stamps-seals");
              }}
              className={
                activePrevLink === "StampsAndSeals" ? "active-link" : undefined
              }
            >
              <a>
                <img src={stamp_w} />
                <span>{t("menu.Apply Stamps and Seals")}</span>
              </a>
            </li>
            <li
              onClick={(event) => {
                event.stopPropagation();
                navigate("/stamps-seals/automagic");
                setActiveLink("AutoMagic");
              }}
              className={
                activePrevLink === "AutoMagic" ? "active-link" : undefined
              }
            >
              <a>
                <img src={automagic_w} />
                <span>{t("menu.Automagic")}</span>
              </a>
            </li>
            <li
              onClick={(event) => {
                event.stopPropagation();
                navigate("/stamps-seals/presets");
                setActiveLink("Presets");
              }}
              className={
                activePrevLink === "Presets" ? "active-link" : undefined
              }
            >
              <a>
                <img src={presets_w} />
                <span>{t("menu.Presets")}</span>
              </a>
            </li>
            <li
              onClick={(event) => {
                event.stopPropagation();
                navigate("/stamps-seals/manual-mode");
                setActiveLink("ManualMode");
              }}
              className={
                activePrevLink === "ManualMode" ? "active-link" : undefined
              }
            >
              <a>
                <img src={manual_w} />
                <span>{t("menu.Manual mode")}</span>
              </a>
            </li>
            <li
              onClick={(event) => {
                event.stopPropagation();
                navigate("/stamps-seals/attach-stamp-paper");
                setActiveLink("AttachStampPaper");
              }}
              className={
                activePrevLink === "AttachStampPaper"
                  ? "active-link"
                  : undefined
              }
            >
              <a>
                <img src={attach_w} />
                <span>{t("menu.Attach stamp paper")}</span>
              </a>
            </li>
            <li
              onClick={(event) => {
                event.stopPropagation();
                navigate("/stamps-seals/email-notifications");
                setActiveLink("EmailNotifications");
              }}
              className={
                activePrevLink === "EmailNotifications"
                  ? "active-link"
                  : undefined
              }
            >
              <a>
                <img src={printing_w} />
                <span>
                  {recipientCount == 1
                    ? t("menu.Affix My Signature")
                    : t("menu.Email Notifications")}
                </span>
              </a>
            </li>
          </ul>
        )}
      </li>
      <li
        onClick={(event) => {
          event.stopPropagation();
          setActiveLink("Searchlegacy");
          navigate("/Searchlegacy");
          setShowDropdown(
            showDropdown === "homepagelegacy" ? undefined : "homepagelegacy"
          );
        }}
      >
        <a>
          <img src={review_document} />
          <span>{t("menu.Quoqo Legacy")}</span>

          <span
            className="Arrow"
            style={{
              transform:
                showDropdown === "homepagelegacy"
                  ? "rotate(0deg)"
                  : "rotate(270deg)",
            }}
          >
            <svg
              className="h-4 w-4"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fillRule="evenodd"
                d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                clipRule="evenodd"
              />
            </svg>
          </span>
        </a>
        {showDropdown == "homepagelegacy" && (
          <ul className="sub_menu show img_us">
            <li
              onClick={(event) => {
                event.stopPropagation();
                setActiveLink("Searchlegacy");
                navigate("/Searchlegacy");
                // setShowDropdown(
                //   showDropdown === "Searchlegacy" ? undefined : "ViewContractlegacy"
                // );
              }}
              // {() => setActiveLink('Searchlegacy')}
              className={
                activeLink === "Searchlegacy" ||
                activeLink === "ViewContractlegacy"
                  ? "active-link"
                  : undefined
              }
            >
              <a>
                <img src={Search} />
                <span>{t("allModule.Search")}</span>
              </a>
            </li>
            {/* <li
              onClick={(event) => {
                event.stopPropagation();
                setActiveLink("AddContractlegacy");
                navigate("/AddContractlegacy");
                // setShowDropdown(
                //   showDropdown === "AddContractlegacy" ? undefined : "ContractFormlegacy"
                // );
              }}
              // () => setActiveLink('AddContractlegacy')}
              className={
                activeLink === "AddContractlegacy" ||
                activeLink === "ContractFormlegacy"
                  ? "active-link"
                  : undefined
              }
            >
              <a>
                <img src={addfilesrd1} />
                <span>{t("modal.Add Contract")}</span>
              </a>
            </li> */}
            <li
              onClick={(event) => {
                event.stopPropagation();
                setActiveLink("BrowseDocuments");
                navigate("/BrowseDocuments");
                // setShowDropdown(
                //   showDropdown === "AddContractlegacy" ? undefined : "ContractFormlegacy"
                // );
              }}
              // () => setActiveLink('AddContractlegacy')}
              className={
                activeLink === "BrowseDocuments" ||
                activeLink === "BrowseDocuments"
                  ? "active-link"
                  : undefined
              }
            >
              <a>
                <img src={browseDocument} />
                <span>{t("fileBrowser.Browse Documents")}</span>
              </a>
            </li>
            <li
              onClick={(event) => {
                event.stopPropagation();
                setActiveLink("SharedRepolegacy");
                navigate("/SharedRepolegacy");
                // setShowDropdown(
                //   showDropdown === "AddContractlegacy" ? undefined : "ContractFormlegacy"
                // );
              }}
              // () => setActiveLink('AddContractlegacy')}
              className={
                activeLink === "SharedRepolegacy" ||
                activeLink === "SharedRepolegacy"
                  ? "active-link"
                  : undefined
              }
            >
              <a>
                <img src={shareDocument} />
                <span>{t("fileBrowser.Shared Documents")}</span>
              </a>
            </li>
            {/* <li
              onClick={(event) => 
              {
                event.stopPropagation();
                setActiveLink('Userslegacy');               
                
              }
              }
              className={activeLink === 'Userslegacy' ? 'active-link' : undefined}
            >
              <a>
              <img src={UserImg} />
              <span>{t('menu.Users-Legacy')}</span>
              </a>
            </li> */}

            {/* <li
                onClick={(event) => {
                  event.stopPropagation();
                  navigate("/ai-review/transaction");
                  setActiveLink("QuoqoTransaction");
                }}
                className={
                  activePrevLink === "QuoqoTransaction"
                    ? "active-link"
                    : undefined
                }
              >
                <a>
                  {" "}
                  <img src={transactionsIcon} />
                  <span>{t("constant.Review a Transaction Document")}</span>
                </a>
              </li> */}
          </ul>
        )}
      </li>

      {/* <li
        onClick={(event) => {
          event.stopPropagation();
          setActiveLink("StampsAndSeals");
          navigate("/stamps-seals");
          setShowDropdown(
            showDropdown === "StampsAndSeals" ? undefined : "StampsAndSeals"
          );
        }}
        className={
          activePrevLink === "StampsAndSeals" ? "active-link" : undefined
        }
      >
        <a>
          <img src={stamp_w} />
          <span>{t("menu.Apply Stamps and Seals")}</span>
          <span
            className="Arrow"
            style={{
              transform:
                showDropdown === "StampsAndSeals"
                  ? "rotate(0deg)"
                  : "rotate(270deg)",
            }}
          >
            <svg
              className="h-4 w-4"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fillRule="evenodd"
                d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                clipRule="evenodd"
              />
            </svg>
          </span>
        </a>
        {showDropdown == "StampsAndSeals" && (
          <ul className="sub_menu show img_us">
            <li
              onClick={(event) => {
                event.stopPropagation();
                navigate("/stamps-seals/automagic");
                setActiveLink("AutoMagic");
              }}
              className={
                activePrevLink === "AutoMagic" ? "active-link" : undefined
              }
            >
              <a>
                <img src={automagic_w} />
                <span>{t("menu.Automagic")}</span>
              </a>
            </li>

            <li
              onClick={(event) => {
                event.stopPropagation();
                navigate("/stamps-seals/presets");
                setActiveLink("Presets");
              }}
              className={
                activePrevLink === "Presets" ? "active-link" : undefined
              }
            >
              <a>
                <img src={presets_w} />
                <span>{t("menu.Presets")}</span>
              </a>
            </li>

            <li
              onClick={(event) => {
                event.stopPropagation();
                navigate("/stamps-seals/manual-mode");
                setActiveLink("ManualMode");
              }}
              className={
                activePrevLink === "ManualMode" ? "active-link" : undefined
              }
            >
              <a>
                <img src={manual_w} />
                <span>{t("menu.Manual mode")}</span>
              </a>
            </li>

            <li
              onClick={(event) => {
                event.stopPropagation();
                navigate("/stamps-seals/attach-stamp-paper");
                setActiveLink("AttachStampPaper");
              }}
              className={
                activePrevLink === "AttachStampPaper"
                  ? "active-link"
                  : undefined
              }
            >
              <a>
                <img src={attach_w} />
                <span>{t("menu.Attach stamp paper")}</span>
              </a>
            </li>
            <li
              onClick={(event) => {
                event.stopPropagation();
                navigate("/stamps-seals/email-notifications");
                setActiveLink("EmailNotifications");
              }}
              className={
                activePrevLink === "EmailNotifications"
                  ? "active-link"
                  : undefined
              }
            >
              <a>
                <img src={printing_w} />
                <span>
                  {recipientCount == 1
                    ? t("menu.Affix My Signature")
                    : t("menu.Email Notifications")}
                </span>
              </a>
            </li>
          </ul>
        )}
      </li> */}

      <>
        {/* <li>
          <a href="https://legacy.quoqo.app/" target="_blank">
            <img src={docsearch} />
            {t("allModule.Document Repository")}
          </a>
        </li> */}

        {/* <li
          onClick={(event) => {
            event.stopPropagation();
            setActiveLink("CreatePlaybook");
            navigate("/playrite/create-playbook");

            setShowDropdown(
              showDropdown === "QuoqoPlayrite" ? undefined : "QuoqoPlayrite"
            );
          }}
        >
          <a>
            <img src={review_document} />
            <span>{t("Quoqo Playrite")}</span>

            <span
              className="Arrow"
              style={{
                transform:
                  showDropdown === "QuoqoPlayrite"
                    ? "rotate(0deg)"
                    : "rotate(270deg)",
              }}
            >
              <svg
                className="h-4 w-4"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fillRule="evenodd"
                  d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                  clipRule="evenodd"
                />
              </svg>
            </span>
          </a>
          {showDropdown == "QuoqoPlayrite" && (
            <ul className="sub_menu show img_us">
              <li
                onClick={(event) => {
                  event.stopPropagation();
                  navigate("/playrite/create-playbook");
                  setActiveLink("CreatePlaybook");
                }}
                className={
                  activePrevLink === "CreatePlaybook"
                    ? "active-link"
                    : undefined
                }
              >
                <a>
                  <img src={NDA} />
                  <span>{t("Create a Playbook")}</span>
                </a>
              </li>

              <li
                onClick={(event) => {
                  event.stopPropagation();
                  navigate("/playrite/view-playbook");
                  setActiveLink("ViewPlaybookList");
                }}
                className={
                  activePrevLink === "ViewPlaybookList"
                    ? "active-link"
                    : undefined
                }
              >
                <a>
                  {" "}
                  <img src={Diligence} />
                  <span>{t("View a Playbook")}</span>
                </a>
              </li>

              <li
                onClick={(event) => {
                  event.stopPropagation();
                  navigate("/playrite/deviation-analysis");
                  setActiveLink("DeviationAnalysis");
                }}
                className={
                  activePrevLink === "QuoqoTransaction"
                    ? "active-link"
                    : undefined
                }
              >
                <a>
                  {" "}
                  <img src={transactionsIcon} />
                  <span>{t("allModule.Playrite Tasks")}</span>
                </a>
              </li>
            </ul>
          )}
        </li> */}

        <li
          onClick={(event) => {
            event.stopPropagation();
            navigate("/Bots");
            setActiveLink("Bots");
          }}
          className={activePrevLink === "Bots" ? "active-link" : undefined}
        >
          <a>
            {" "}
            <img src={qbot} />
            <span>{t("allModule.Legal Research")}</span>
          </a>
        </li>

        <li
          onClick={(event) => {
            event.stopPropagation();
            navigate("/Marketplace");
            setActiveLink("Marketplace");
          }}
          className={
            activePrevLink === "Marketplace" ? "active-link" : undefined
          }
        >
          <a>
            {" "}
            <img src={Marketplace} />
            <span>{t("menu.Service Providers")}</span>
          </a>
        </li>

        {/* <li
          onClick={(event) => {
            event.stopPropagation();
            navigate("/ai-review/nda");
            setActiveLink("ReviewDocument");
          }}
          className={
            activePrevLink === "ReviewDocument" ? "active-link" : undefined
          }
        >
          <a>
            <img src={NDA} />
            <span>{t("constant.NDA")}</span>
          </a>
        </li>

        <li
          onClick={(event) => {
            event.stopPropagation();
            navigate("/ai-review/diligence");
            setActiveLink("QuoqoDiligence");
          }}
          className={
            activePrevLink === "QuoqoDiligence" ? "active-link" : undefined
          }
        >
          <a>
            {" "}
            <img src={Diligence} />
            <span>{t("constant.Diligence")}</span>
          </a>
        </li>

        <li
          onClick={(event) => {
            event.stopPropagation();
            navigate("/ai-review/transaction");
            setActiveLink("QuoqoTransaction");
          }}
          className={
            activePrevLink === "QuoqoTransaction" ? "active-link" : undefined
          }
        >
          <a>
            {" "}
            <img src={transactionsIcon} />
            <span>{t("constant.Transactions")}</span>
          </a>
        </li> */}
      </>

    

      <li
        onClick={() => {
          navigate("/contact-book/contact");
          setActiveLink("ContactBook");
          setShowDropdown("");
        }}
        className={
          activePrevLink === "ContactBook" ||
          activePrevLink === "ContactBookForm"
            ? "active-link"
            : undefined
        }
      >
        <a>
          {" "}
          <img src={contactBookIcon} />
          <span>{t("menu.Contact Book")}</span>
        </a>
      </li>

      <li
        onClick={(event) => {
          event.stopPropagation();
          setActiveLink("Account");
          navigate("/account");
          setShowDropdown(showDropdown === "Account" ? undefined : "Account");
        }}
        className={activePrevLink === "Account" ? "active-link" : undefined}
      >
        <a>
          <img src={Settings} />
          <span>{t("menu.Account")}</span>

          <span
            className="Arrow"
            style={{
              transform:
                showDropdown === "Account" ? "rotate(0deg)" : "rotate(270deg)",
            }}
          >
            <svg
              className="h-4 w-4"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fillRule="evenodd"
                d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                clipRule="evenodd"
              />
            </svg>
          </span>
        </a>
        {showDropdown == "Account" && (
          <ul className="sub_menu show img_us">
            <li
              onClick={(event) => {
                event.stopPropagation();
                navigate("/account/wallet");
                setActiveLink("Wallet");
              }}
              className={
                activePrevLink === "Wallet" ? "active-link" : undefined
              }
            >
              <a>
                <img src={wallet_w} />
                <span>{t("menu.Wallet")}</span>
              </a>
            </li>
            {user.user_type === "admin" && (
              <li
                onClick={(event) => {
                  navigate("/account/add-users");
                  event.stopPropagation();
                  setActiveLink("AdminUser");
                }}
                className={
                  activePrevLink === "AdminUser" ||
                  activePrevLink === "AdminUserForm" ||
                  activePrevLink === "AdminUserDocument"
                    ? "active-link"
                    : undefined
                }
              >
                <a>
                  <img src={UserImg} />
                  <span>{t("menu.List of Users")}</span>
                </a>
              </li>
            )}

            {/*<li
              onClick={(event) => {
                event.stopPropagation();
                navigate("/account/notification");
                setActiveLink("Notifications");
              }}
              className={
                activePrevLink === "Notifications" ? "active-link" : undefined
              }
            >
              <a>
                {" "}
                <img src={notification_w} />
                <span>{t("menu.Notifications")}</span>
              </a>
            </li> 

            <li
              onClick={(event) => {
                event.stopPropagation();
                navigate("/account/TaskList");
                setActiveLink("TaskList");
              }}
              className={
                activePrevLink === "TaskList" ? "active-link" : undefined
              }
            >
              <a>
                {" "}
                <img src={task} />
                <span>{t("TaskManager")}</span>
              </a>
            </li> */}

            {user.user_type === "admin" && (
              <li
                onClick={(event) => {
                  event.stopPropagation();
                  navigate("/account/purchaseList");
                  setActiveLink("purchaseList");
                }}
                className={
                  activePrevLink === "purchaseList" ? "active-link" : undefined
                }
              >
                <a>
                  {" "}
                  <img src={myDocIcon} />
                  <span>{t("constant.Licence History")}</span>
                </a>
              </li>
            )}
            {user.user_type === "admin" && (
              <li
                onClick={(event) => {
                  event.stopPropagation();
                  navigate("/office-365-setting");
                  setActiveLink("Office365SettingList");
                }}
                className={
                  activePrevLink === "Office365SettingList" ||
                  activePrevLink === "Office365SettingForm"
                    ? "active-link"
                    : undefined
                }
              >
                <a>
                  <img src={Settings} />
                  <span>{t("constant.Office 365 Setting")}</span>
                </a>
              </li>
            )}
          </ul>
        )}
      </li>

      <li
        onClick={() => {
          setActiveLink("Help");
          navigate("/help");
        }}
        className={activePrevLink === "Help" ? "active-link" : undefined}
      >
        <a>
          <img src={Help} />
          <span>{t("menu.Help")}</span>
        </a>
      </li>
    </>
  );
};

export default AdminUser;
