import React, { useState, useEffect, useContext } from 'react';
import dayjs from 'dayjs';
import PurchaseListTable from './PurchaseListTable';
import { PurchaseListTableColumns } from '../../constants/constants';
import useAxios from '../../hooks/useAxios';
import { resolve } from '../../api/resolve';
import NotificationModal from '../modals/NotificationModal';
import AuthContext from "../../context/AuthContext";
import LoadingScreen from '../loader/LoadingScreen';
import { useTranslation } from 'react-i18next';
// import jwt from 'jsonwebtoken';

const PurchaseList = () => {
  const api = useAxios();
  const { t } = useTranslation();
  const [errMsg, setErrMsg] = useState('');
  const [nextPage, setIsNextPage] = useState(null);
  const [prevPage, setIsPrevPage] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [sortingOrder, setSortingOrder] = useState('desc');
  const [sortColumn, setSortColumn] = useState('id');
  const [PurchaseLists, setPurchaseList] = useState([]);
  const [pageLoading, setPageLoading] = useState(true);
  const [modalIsOpen, setIsOpen] = useState(false);
  const [selectedNotification, setSelectedNotification] = useState([]);
  const [purchasePageLimit, setPurchasePageLimit] = useState(5);
  const [purchaseNextBtnLimit, setPurchaseNextBtnLimit] = useState(0);
  const [purchaseCount, setPurchaseCount] = useState(0);
  const { user } = useContext(AuthContext);
  const getPurchaseList = async () => {
      var userId = user.user_id;
    const res = await resolve(
      api
        .get(
          `user/subscription/purchase/list/?subscriber_id=${userId}&sortingOrder=${sortingOrder}&page=${currentPage}`
        )
        .then((res) => res.data)
    );
    if (res.data) {
      const data = res.data;
      const { results, count } = data;
      // const { results, next, previous } = res.data;
      let filterData = [];
      if (results.length > 0) {
        setPurchaseCount(count);
        results.forEach((result) => {
          const contractObj = {
            id: result.id,
            plan_name: result.plan_id.name,
            original_plan_qty: result?.transaction_id?.original_plan_qty,
            plan_quantity: result?.transaction_id?.plan_quantity,
            plan_unit_price: result?.transaction_id?.plan_unit_price,
            total_paid_amount: result?.transaction_id?.total_paid_amount,
            date: dayjs(result.updated_at).format('DD MMMM YYYY'),
          };
          filterData.push(contractObj);
        });
      } else {
        setErrMsg(t('allModule.No Data Found'));
      }
      filterData.forEach((item) => {
        if (item.contract_details) {
          item.contract_details.replaceAll('\\', '');
          const parsedObj = JSON.parse(item.contract_details);
          item.contract_details = parsedObj;
        }
      });
      setPurchaseList([...filterData]);
      // setIsNextPage(next);
      // setIsPrevPage(previous);
      setPurchaseNextBtnLimit(Math.ceil(data.count / purchaseNextBtnLimit));
    }
    setPageLoading(false);
  };
  useEffect(() => {
    getPurchaseList();
  }, [sortColumn, sortingOrder, currentPage]);
  return (
    <>
      {pageLoading ? (
        <LoadingScreen />
      ) : (
        <div className='text-primary'>
          <div className="main_head">
          <h1 className='text-4xl font-bold'>{t('constant.Licence History')}</h1>
    </div>
         
          {PurchaseLists.length < 1 && (
            <p className='text-red-500 text-sm font-medium my-5'>{errMsg}</p>
          )}
          <PurchaseListTable
            contractsData={PurchaseLists}
            contractTableColumns={PurchaseListTableColumns}
            prevPage={prevPage}
            nextPage={nextPage}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            sortingOrder={sortingOrder}
            setSortingOrder={setSortingOrder}
            setSortColumn={setSortColumn}
            setIsOpen={setIsOpen}
            setSelectedNotification={setSelectedNotification}
          />
         
        </div>
      )}
    </>
  );
};

export default PurchaseList;
