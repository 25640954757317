import React, { useEffect, useState, useContext } from 'react';
import useAxios from '../../hooks/useAxios';
import { resolve } from '../../api/resolve';
import { autoCloseTimer, delay } from '../../constants/constants';
import { toast } from 'react-toastify';
import './admin.css';
import { Worker, Viewer } from '@react-pdf-viewer/core';
import LoadingScreen from '../loader/LoadingScreen';
import AppContext from '../../context/AppContext';
import DownArrow from '../../assets/icons/down-arrow.svg';
import ReactTooltip from 'react-tooltip';
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';
import RejectKycDocModal from '../modals/RejectKycDocModal';
import { parseISO, format } from 'date-fns'

const AdminDetail = ({ adminId }) => {

    const [documentList, setDocumentList] = useState([]);
    const [userDetail, setUserDetail] = useState(null);
    const [docUrl, setDocUrl] = useState('');
    const [loading] = useState(false);
    const [docType, setDocType] = useState('');
    const [rejectDocModelOpen, setRejectDocModelOpen] = useState(false);
    const [docId, setDocId] = useState(null);

    const api = useAxios();
    const { highlightToken, getSasToken, getHighlightSasToken } = useContext(AppContext);

    const getAdminDetail = async () => {
        const res = await resolve(api.get(`/user/admin/perticular/${adminId}`));
        if (res.data) {
            setUserDetail(res['data'].data['basic_detail'])
            setDocumentList(res['data'].data['document_list'])
        }

        if (res.error) {
            toast.error("Something went wrong.", {
                autoClose: autoCloseTimer,
                hideProgressBar: true,
            });
        }
    };

    const handleViewFile = async (document) => {
        let fileUrl = document['link_of_doc'];
        let docType = fileUrl.split('.')[fileUrl.split('.').length - 1];
        if (docType === 'pdf') {
            setDocUrl(fileUrl);
            setDocType('pdf');
        } else {
            setDocUrl(fileUrl);
            setDocType('image');
        }
    };

    const handleAccept = async (document) => {
        const postData = {
            user_id: userDetail['id'],
            doc_id: document['id'],
            is_approved: true,
            rejection_reason: "",
        }
        const res = await resolve(api.post(`/user/admin/AcceptReject/`, postData));
        if (res.data) {
            toast.success('Document accepted successfully', {
                autoClose: autoCloseTimer,
                hideProgressBar: true,
            });
            getAdminDetail();
        }
        if (res.error) {
            toast.error('Something went wrong', {
                autoClose: autoCloseTimer,
                hideProgressBar: true,
            });
        }
    };

    useEffect(() => {
        getSasToken();
        getHighlightSasToken();
        getAdminDetail();
    }, []);

    const renderToolbar = (Toolbar) => (
        <Toolbar>
            {(slots) => {
                const {
                    CurrentPageInput,
                    GoToNextPage,
                    GoToPreviousPage,
                    NumberOfPages,

                    CurrentScale,
                    ZoomIn,
                    ZoomOut,
                } = slots;

                return (
                    <div className={`w-full`}>
                        <div
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                width: '100%',
                                justifyContent: 'space-between',
                            }}
                        >
                            <div className={`flex ml-8 items-center justify-center`}>
                                <div className='w-10'>
                                    <CurrentPageInput />
                                </div>
                                <div className='mx-2'>
                                    /{' '}
                                    <span>
                                        <NumberOfPages />
                                    </span>
                                </div>
                                <div className='flex flex-col ml-1'>
                                    <GoToPreviousPage>
                                        {(props) => (
                                            <>
                                                <span
                                                    disabled={props.isDisabled}
                                                    onClick={props.onClick}
                                                    style={{
                                                        cursor: 'pointer',
                                                        transform: 'rotate(180deg)',
                                                        width: 15,
                                                        height: 15,
                                                    }}
                                                    className='ml-1'
                                                    data-tip='Previous Page'
                                                >
                                                    <img src={DownArrow} alt='img' />
                                                </span>
                                                <ReactTooltip
                                                    backgroundColor='#400835'
                                                    textColor='#ffffff'
                                                />
                                            </>
                                        )}
                                    </GoToPreviousPage>
                                    <GoToNextPage>
                                        {(props) => (
                                            <>
                                                <span
                                                    disabled={props.isDisabled}
                                                    onClick={props.onClick}
                                                    style={{
                                                        cursor: 'pointer',
                                                        marginTop: 2,
                                                        width: 15,
                                                        height: 15,
                                                    }}
                                                    className='ml-1'
                                                    data-tip='Next Page'
                                                >
                                                    <img src={DownArrow} alt='img' />
                                                </span>
                                                <ReactTooltip
                                                    backgroundColor='#400835'
                                                    textColor='#ffffff'
                                                />
                                            </>
                                        )}
                                    </GoToNextPage>
                                </div>
                            </div>
                            <div className='max-w-fit flex items-center'>
                                <ZoomIn />
                                <span className='mx-1'>{CurrentScale()}</span>
                                <ZoomOut />
                            </div>

                        </div>




                    </div>
                );
            }}
        </Toolbar>
    );
    /////////// pdf search fuctionality ////////////////////
    const defaultLayoutPluginInstance = defaultLayoutPlugin({
        renderToolbar,
        sidebarTabs: () => [],
    });
    return (
        <div className='text-primary text-base font-normal font-sans upload_doc_page'>
            <div className="main_head">
                <h1 className='text-4xl font-bold'>Admin Detail</h1>
            </div>
            <div className='flex'>
                <div className='w-2/5'>
                    <div className='mb-3'>
                        <span className='text-base font-bold mr-2'>User Name :</span>
                        <span className='text-lg font-medium text-[#79838B]'>{userDetail?.first_name}</span>

                    </div>
                    <div className='mb-3'>
                        <span className='text-base font-bold mr-2'>Email Address :</span>
                        <span className='text-lg font-medium text-[#79838B]'>
                            {userDetail?.email}
                        </span>
                    </div>
                    <div className='mb-3'>
                        <span className='text-base font-bold mr-2'>Phone Number :</span>
                        <span className='text-lg font-medium text-[#79838B]'>
                            {userDetail?.phone}
                        </span>
                    </div>

                    <div className='mt-5'>
                        <h1 className='text-base font-bold mb-2'>
                            Company Information :-
                        </h1>
                        <div className='mb-3 flex'>
                            <span className='text-base font-bold mr-2'>Company Name :</span>
                            <span className='text-lg font-medium text-[#79838B]'>
                                {userDetail?.company_id['name']}
                            </span>
                        </div>
                    </div>

                    <div className='mt-5'>
                        <h1 className='text-base font-bold mb-2'>
                            Plan Information :-
                        </h1>
                        <div className='mb-3 flex'>
                            <span className='text-base font-bold mr-2'>Plan Name :</span>
                            <span className='text-lg font-medium text-[#79838B]'>
                                {userDetail?.plan_name}
                            </span>
                        </div>
                        <div className='mb-3 flex'>
                            <span className='text-base font-bold mr-2'>Paid Amount :</span>
                            <span className='text-lg font-medium text-[#79838B]'>
                            ₹{userDetail?.paid_amount}
                            </span>
                        </div>
                        <div className='mb-3 flex'>
                            <span className='text-base font-bold mr-2'>Valid Till :</span>
                            <span className='text-lg font-medium text-[#79838B]'>
                                {userDetail?.valid_till?format(parseISO(userDetail.valid_till), 'ccc, dd MMM yyyy, HH:mm'):''}
                            </span>
                        </div>
                    </div>

                    <div className='mt-5'>
                        <h1 className='text-base font-bold mb-2'>
                            Document Information :
                        </h1>
                        {/* <div className='mb-3 flex'>
                            <span className='text-base font-bold mr-2'>Document Status :</span>
                            <span className='text-lg font-medium text-[#79838B]'>
                                {userDetail?.document_status == 0 ? (
                                    <span className='text-red-500'>Not yet uploaded</span>
                                ) : userDetail?.document_status == 1 ? (
                                    <span className='text-yellow-500'>Need Approval</span>
                                ) : userDetail?.document_status == 2 ? (
                                    <span className='text-green-500'>Approved</span>
                                ) : userDetail?.document_status == 3 ? (
                                    <span className='text-red-500'>Rejected</span>
                                ) : (
                                    <></>
                                )}
                            </span>
                        </div> */}
                        {
                            documentList.length > 0 ? (
                                documentList?.map((document, index) => {
                                    return (
                                        document['type_of_document'] !== 'Not Applicable' && (
                                            <div className='auth_document_box' key={index}>
                                                <div className='mb-3 flex'>
                                                    <span className='text-base font-bold mr-2'>Document Type :</span>
                                                    <span className='text-lg font-medium text-[#79838B]'>
                                                        {document['type_of_document']}
                                                    </span>
                                                </div>

                                                <div className='mb-3 flex'>
                                                    <span className='text-base font-bold mr-2'>Status :</span>
                                                    <span className='text-lg font-medium text-[#79838B]'>
                                                        {document['is_approved'] == 0 ? (
                                                            <span className='text-yellow-500'>Need Approval</span>
                                                        ) : document['is_approved'] == 1 ? (
                                                            <span className='text-green-500'>Approved</span>
                                                        ) : document['is_approved'] == 2 ? (
                                                            <span className='text-red-500'>Rejected</span>
                                                        ) : (<></>)}
                                                    </span>
                                                </div>
                                                <div className='mb-3 flex'>
                                                    <button
                                                        onClick={((e) => handleViewFile(document))}
                                                        className="bg-secondary font-bold text-white  p-1 px-2 rounded-lg contact-book-right-btn mr-2">View Document</button>
                                                    {document['is_approved'] == 0 && (
                                                        <>
                                                            <button
                                                                onClick={((e) => handleAccept(document))}
                                                                className="bg-secondary font-bold text-white  p-1 px-2 rounded-lg contact-book-right-btn mr-2">Accept</button>
                                                            <button
                                                                onClick={((e) => {
                                                                    setDocId(document['id'])
                                                                    setRejectDocModelOpen(true)
                                                                })}
                                                                className="bg-secondary font-bold text-white  p-1 px-2 rounded-lg contact-book-right-btn mr-2">Reject</button>

                                                        </>
                                                    )}

                                                </div>
                                            </div>
                                        )

                                    )
                                })
                            ) : (
                                <div className='mb-3 flex'>
                                    <span className='text-base font-bold mr-2'>Document Status :</span>
                                    <span className='text-lg font-medium text-[#79838B]'>
                                        {userDetail?.document_status == 0 ? (
                                            <span className='text-red-500'>Not yet uploaded</span>
                                        ) : userDetail?.document_status == 1 ? (
                                            <span className='text-yellow-500'>Need Approval</span>
                                        ) : userDetail?.document_status == 2 ? (
                                            <span className='text-green-500'>Approved</span>
                                        ) : userDetail?.document_status == 3 ? (
                                            <span className='text-red-500'>Rejected</span>
                                        ) : (
                                            <></>
                                        )}
                                    </span>
                                </div>
                            )
                        }

                    </div>
                </div>
                <div className='w-full'>
                    {docType == 'pdf' ? (
                        <div className="box_u1 p-4" >
                            <div className="box_u2 account_pdf_view"  >
                                <Worker workerUrl='https://unpkg.com/pdfjs-dist@2.13.216/build/pdf.worker.min.js'>
                                    {loading ? (
                                        <LoadingScreen />
                                    ) : (
                                        <>
                                            {docUrl && (
                                                <Viewer
                                                    fileUrl={`${docUrl}?${highlightToken.sas_token}`}
                                                    plugins={[
                                                        defaultLayoutPluginInstance,
                                                    ]}
                                                    onDocumentAskPassword={(e) => {

                                                    }}
                                                    renderError={() => (
                                                        <div className='grid place-items-center h-full w-full'>
                                                            <h1 className='font-semibold'>
                                                                Contract indexing in progress. Check back later
                                                            </h1>
                                                        </div>
                                                    )}
                                                    renderLoader={() => <LoadingScreen />}
                                                />
                                            )}
                                        </>
                                    )}
                                </Worker>
                            </div>
                        </div>
                    ) : docType == 'image' ? (
                        <div className="box_u1 p-4" >
                            <div className="box_u2 account_pdf_view"  >
                                <img src={`${docUrl}?${highlightToken.sas_token}`} alt="img" />
                            </div>
                        </div>
                    ) : (
                        <></>
                    )}

                </div>
            </div>

            <RejectKycDocModal
                rejectDocModelOpen={rejectDocModelOpen}
                setRejectDocModelOpen={setRejectDocModelOpen}    
                docId={docId}   
                userId={userDetail?.id}      
                getAdminDetail={getAdminDetail}  
            />
        </div>
    );
};

export default AdminDetail;
