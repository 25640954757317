import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';
import translationEN from './locales/en/translation.json';
import translationFR from './locales/fr/translation.json';

import translationAR from './locales/ar/translation.json';
import translationHI from './locales/hi/translation.json';
import translationIT from './locales/it/translation.json';
import translationDE from './locales/de/translation.json';
import translationES from './locales/es/translation.json';
import translationTR from './locales/tr/translation.json';

// the translations
const resources = {
    en: {
      translation: translationEN
    },
    fr: {
      translation: translationFR
    },
    ar: {
      translation: translationAR
    },
    hi: {
      translation: translationHI
    },
    it: {
      translation: translationIT
    },
    de: {
      translation: translationDE
    },
    es: {
      translation: translationES
    },
    tr: {
      translation: translationTR
    }
  };

i18n
    // .use(Backend)
    .use(LanguageDetector)
    .use(initReactI18next) 
    .init({
      resources,
      fallbackLng: "en", // use en if detected lng is not available  
      interpolation: {
        escapeValue: false // react already safes from xss
      }
    });

// i18n.changeLanguage('en');
export default i18n;