import React, { useEffect, useState, useContext } from 'react';
import Search from '../../assets/icons/Search.svg';
import CloseIcon from '../../assets/icons/Close.svg';
import { aadharQuotaTableColumns } from '../../constants/constants';
import useAxios from '../../hooks/useAxios';
import { resolve } from '../../api/resolve';
import AddButton from '../../shared/AddButton';
import AadharQuotaTable from './AadharQuotaTable';
import { parseISO, format } from 'date-fns'
import NotifyMeModel from '../modals/NotifyMeModel';
import { useTranslation } from 'react-i18next';

const AadharQuotaList = () => {

    const api = useAxios();
    const { t } = useTranslation();
    // pending doc related states
    const [aadharQuotaList, setAadharQuotaList] = useState([]);
    const [searchText, setSearchText] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [pageLimit, setPageLimit] = useState(5);
    const [nextBtnLimit, setNextBtnLimit] = useState(0);
    const [count, setUserCount] = useState(0);
    const [errMsg, setErrMsg] = useState('');
    const [showClearIcon, setShowClearIcon] = useState(false);
    const [openNotifymeModal, setOpenNotifymeModal] = useState(false);
    const [notifyMeData, setNotifyMeData] = useState({});
    const [sortingOrder, setSortingOrder] = useState('desc');
    const [sortColumn, setSortColumn] = useState('id');



    const getAadharQuotaList = async (clearText) => {
        const res = await resolve(api.get(`user/aadhar/superadmin/list/?ordering=${sortColumn}&sortingOrder=${sortingOrder}&search=${clearText ? '' : searchText}&page=${currentPage}&limit=${pageLimit}`).then((res) => res.data));
        if (res.data) {
            const { data } = res;
            const { results, count } = data;
            const filterData = [];
            if (results.length > 0) {
                setUserCount(count);
                results.forEach((result) => {
                    const tempObj = {
                        date_of_purchase: result.date_of_purchase ? format(parseISO(result.date_of_purchase), 'ccc, dd MMM yyyy, HH:mm') : '-',
                        is_active: result.is_active ? 'Active' : 'Inactive',
                        current_quantity: result.current_quantity,
                        remaining_quantity: result.remaining_quantity,
                    };
                    filterData.push(tempObj);
                });
            } else {
                if (currentPage === 1) setErrMsg(t('allModule.No data added yet'));
                else setErrMsg(t('allModule.No more data to view'));
            }
            setAadharQuotaList([...filterData]);
            setNextBtnLimit(Math.ceil(count / pageLimit))
        } else {
            setNextBtnLimit(0)
        }
    };


    const handleNotifyMe = async () => {
        const res = await resolve(api.get(`/user/site/setting/1`).then((res) => res.data));
        if (res.data) {
            setNotifyMeData(res.data)
            setOpenNotifymeModal(true)
        }
    }

    useEffect(() => {
        getAadharQuotaList();
    }, [sortColumn, sortingOrder, currentPage, pageLimit])

    return (
        <>
            <div className='text-primary'>
                <div className="main_head">
                    <h1 className='text-4xl font-bold'>{t('allModule.Master Aadhaar Quota')}</h1>
                </div>

                <div className="tab_main">
                    <div className={`tab_us show`}>
                        <div className="row justify-end">                          
                            <div className="col-md-5">
                                <div className='flex justify-end mt-3'>
                                    <AddButton text="Add" currentForm="AadharQuotaForm" getAadharQuotaList={getAadharQuotaList} />

                                    <button
                                        onClick={() => {
                                            handleNotifyMe()

                                        }}
                                        className='btn btn_theme2 btn-sm flex items-center justify-center ml-2'                                    >
                                        <span>{t('allModule.Notify Me')}</span>
                                    </button>
                                </div>

                            </div>
                        </div>
                        <div className='mt-3 rounded-lg'>
                            {aadharQuotaList.length < 1 && (
                                <p className='text-red-500 text-sm font-medium'>{errMsg}</p>
                            )}
                        </div>
                        <AadharQuotaTable
                            tableColumns={aadharQuotaTableColumns}
                            getListData={getAadharQuotaList}
                            tableData={aadharQuotaList}
                            // showActionBtn
                            nextBtnLimit={nextBtnLimit}
                            currentPage={currentPage}
                            setCurrentPage={setCurrentPage}
                            itemCount={count}
                            setPageLimit={setPageLimit}
                            showPageLimit={true}
                            sortingOrder={sortingOrder}
                            setSortingOrder={setSortingOrder}
                            setSortColumn={setSortColumn}
                        />

                        <NotifyMeModel
                            isOpen={openNotifymeModal}
                            setIsOpen={setOpenNotifymeModal}
                            notifyMeData={notifyMeData}
                        />


                    </div>

                </div>
            </div>
        </>
    )
}

export default AadharQuotaList