import React from 'react';
import DatePicker from 'react-datepicker';
import './demographicslegacy.css';

const MonthPicker = ({ date, setDate }) => {
  return (
    <DatePicker
      className='w-fit border-2 border-primary rounded-lg cursor-pointer p-1 focus:outline-none text-center'
      selected={date}
      onChange={(e) => setDate(e)}
      showMonthYearPicker
      dateFormat='MMMM - yyyy'
      value={date}
      minDate={new Date('03/01/2022')}
    />
  );
};

export default MonthPicker;
