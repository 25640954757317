import React, { useState, useContext } from 'react';
import { Link } from 'react-router-dom';
import AuthContext from '../../context/AuthContext';
import appLogo from '../../assets/app-logo.svg';
import { validateEmail } from '../../utils/utils';
import { useTranslation } from 'react-i18next';

const ForgotPasswordForm = ({ formErrors, setFormErrors, onSubmit }) => {
  const { t } = useTranslation();
  const { loading,apiStatus } = useContext(AuthContext);
  const [email, setEmail] = useState('');

  const onFormSubmit = (e) => {
    e.preventDefault();
    onSubmit(email);
  };

  const checkValidateEmail = (email) => {
    if (!validateEmail(email)) {
      setFormErrors({ ...formErrors, email: t('loginPage.Please enter a valid email!') });     
    } else {
      setFormErrors({ ...formErrors, email: '' });
    }
  };

  

  return (
    <div className=' w-full flex flex-col items-center'>
      <Link to='/'>
        <img
          src={appLogo}
          alt='app-logo'
          style={{ maxWidth: '200px', maxHeight: '200px' }}
        />
      </Link>
      <div className='mt-6 pt-3 pl-5 pr-5 pt-3   form-container '>
        <p className='text-2xl font-bold text-center  text-primary m-3 mb-5'>         
          {t('loginPage.Enter your registered email address to reset your password')}
        </p>
        <form className='w-full' onSubmit={(e) => onFormSubmit(e)}>
          <div className='mb-3'>
            <label className='text-primary font-bold'>{t('common.Email')}</label>
            <input
              type='text'
              placeholder={t('loginPage.Enter email address')}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              onKeyUp={(e) => checkValidateEmail(e.target.value)}
              className='w-full p-1 pl-3 focus:outline-none text-primary'
            />
            <p className='text-sm text-red-500'>{formErrors.email?formErrors.email:''}</p>
          </div>
          <button
            type='submit'
            className={`bg-primary ${
              loading && 'bg-gray-500'
            } text-white p-1 font-medium w-full mb-3`}
            disabled={loading}
          >
            {t('loginPage.Reset your password')}
          </button>
          {apiStatus && <p className='text-sm' style={{'color': 'green'}}>{t('loginPage.Check your email above for a link to reset your password')}</p>}
              
          
        </form>
        <Link to='/'>
            <p className='text-center text-red-600 font-bold flex justify-center'>
              {t('loginPage.Back To Login')}
            </p>
          </Link>
        
      </div>
    </div>
  );
};

export default ForgotPasswordForm;
