import React, { useEffect, useContext, useState } from 'react';
import ReactTooltip from 'react-tooltip';
import FormContext from '../../context/FormContext';
import AppModal from '../modals/Modal';
import 'react-tagsinput/react-tagsinput.css';
import useAxios from '../../hooks/useAxios';
import HelpIcon from '../../assets/icons/Help-sm.svg';
import ToggleSwitch from '../../shared/ToggleSwitch';

const PlanForm = ({from}) => {
  const api = useAxios();
  const {
    modalData,
    setIsOpen,
    modalIsOpen,
    isEditing,
    editFormData,
    setEditFormData,
    setIsEditing,
    saveOrEditPlan,
    loading,
    progressValue,

  } = useContext(FormContext);

  const [planDetail, setPlanDetail] = useState({
    planName: '',
    available_for_user: false,
    available_for_company: false,
    countryId: '101',
    price: '',
    stripePlanId: '',
    autoMagic: false,
    manual: true,
    presets: false,
    aadhar: false,
    customeTemplate: false,
    legalTemplate: false,
    aiTemplate: false,
    legecy_integration: false,
    user_allotted_cnt: 0,
    document_allotted_cnt: 0,
    e_signature_allotted_cnt: 0,
    custom_template_allotted_cnt: 0,
    legal_template_allotted_cnt: 0,
    ai_template_allotted_cnt: 0,
    lawyer_partner_network_on: false,    
    duration: '',

    seal_and_stamp_on: false,
    e_stamp_attachment_on: false,
    workflow_on: false,
    email_notify_on: false,
    sms_email_reminder_on: false,
    contact_book_on: false,
    document_search_on: false,
    account_billing_management_on: false,
    multiple_user_on: false,
    admin_login_on: false,
    google_microsoft_login_on: false,
    Qcm_on: false,
    nda_review_on: false,
    deligence_on: false,
    Transaction_no: false,
    rbac_on: false,
  });

  const [planError, setPlanError] = useState({
    planName: '',
    countryId: '',
    price: '',
    stripePlanId: '',
    duration:''
  });
  const [planId, setPlanId] = useState(0);

  const submitPlan = async () => {
    for (const key in planError) {
      if (planError.hasOwnProperty(key)) {
        planError[key] = "";
      }
    }
    if (planDetail.planName.trim().length < 1) {
      setPlanError({ ...planError, planName: 'Plan name is required' })
    } else if (planDetail.countryId === "") {
      setPlanError({ ...planError, countryId: 'Country is required' })
    } else if (planDetail.price === "") {
      setPlanError({ ...planError, price: 'Price is required' })
    } else if (from =='AddNewPlan' && planDetail.stripePlanId === "") {
      setPlanError({ ...planError, stripePlanId: 'Stripe plan id is required' })
    }else if (from =='AddCorporatePlan' && planDetail.duration === "") {
      setPlanError({ ...planError, duration: 'Duration is required' })
    } else {
      setPlanError({ ...planError, planName: '', countryId: '', price: '', stripePlanId: '', duration: '' })

      const postData = {
        "plan_id": planId,
        "is_corporate_plan": from =='AddCorporatePlan' ? true : false,
        "name": planDetail.planName,
        "available_for_user": planDetail.available_for_user,
        "available_for_company": planDetail.available_for_company,
        "amount": planDetail.price,
        "country_id": planDetail.countryId,
        "stripe_plan_id": planDetail.stripePlanId,
        "user_allotted_cnt": planDetail.user_allotted_cnt,
        "document_allotted_cnt": planDetail.document_allotted_cnt,
        "e_signature_allotted_cnt": planDetail.e_signature_allotted_cnt,
        "manual_on": planDetail.manual,
        "automagic_on": planDetail.autoMagic,
        "preset_on": planDetail.presets,
        "aadhar_on": planDetail.aadhar,
        "template_allotted_cnt": planDetail.custom_template_allotted_cnt,
        "custom_template_on": planDetail.customeTemplate,
        "custom_template_allotted_cnt": planDetail.custom_template_allotted_cnt,
        "legal_template_on": planDetail.legalTemplate,
        "legal_template_allotted_cnt": planDetail.legal_template_allotted_cnt,
        "ai_template_on": planDetail.aiTemplate,
        "ai_template_allotted_cnt": planDetail.ai_template_allotted_cnt,
        "lawyer_partner_network_on": planDetail.lawyer_partner_network_on,
        "quoqo_legecy_integration_on": planDetail.legecy_integration,
        "plan_duration": planDetail.duration,

        "seal_and_stamp_on": planDetail.seal_and_stamp_on,
        "e_stamp_attachment_on": planDetail.e_stamp_attachment_on,
        "workflow_on": planDetail.workflow_on,
        "email_notify_on": planDetail.email_notify_on,
        "sms_email_reminder_on": planDetail.sms_email_reminder_on,
        "contact_book_on": planDetail.contact_book_on,
        "document_search_on": planDetail.document_search_on,
        "account_billing_management_on": planDetail.account_billing_management_on,
        "multiple_user_on": planDetail.multiple_user_on,
        "admin_login_on": planDetail.admin_login_on,
        "google_microsoft_login_on": planDetail.google_microsoft_login_on,
        "Qcm_on": planDetail.Qcm_on,
        "nda_review_on": planDetail.nda_review_on,
        "deligence_on": planDetail.deligence_on,
        "Transaction_no": planDetail.Transaction_no,
        "rbac_on": planDetail.rbac_on,
      }
      saveOrEditPlan(postData);
    }
  }

  useEffect(() => {

    if (isEditing) {
      setPlanDetail({
        planName: editFormData['plan_id'].name,
        available_for_user: editFormData['plan_id'].available_for_user,
        available_for_company: editFormData['plan_id'].available_for_company,
        countryId: editFormData['plan_id'].country_id,
        price: editFormData['plan_id'].amount,
        stripePlanId: editFormData['plan_id'].stripe_plan_id,
        autoMagic: editFormData.automagic_on,
        manual: editFormData.manual_on,
        presets: editFormData.preset_on,
        aadhar: editFormData.aadhar_on,
        customeTemplate: editFormData.custom_template_on,
        legalTemplate: editFormData.legal_template_on,
        aiTemplate: editFormData.ai_template_on,
        legecy_integration: editFormData.quoqo_legecy_integration_on,
        user_allotted_cnt: editFormData.user_allotted_cnt,
        document_allotted_cnt: editFormData.document_allotted_cnt,
        e_signature_allotted_cnt: editFormData.e_signature_allotted_cnt,
        custom_template_allotted_cnt: editFormData.custom_template_allotted_cnt,
        legal_template_allotted_cnt: editFormData.legal_template_allotted_cnt,
        ai_template_allotted_cnt: editFormData.ai_template_allotted_cnt,
        lawyer_partner_network_on: editFormData.lawyer_partner_network_on,
        duration: editFormData['plan_id'].plan_duration,

        seal_and_stamp_on: editFormData.seal_and_stamp_on,
        e_stamp_attachment_on: editFormData.e_stamp_attachment_on,
        workflow_on: editFormData.workflow_on,
        email_notify_on: editFormData.email_notify_on,
        sms_email_reminder_on: editFormData.sms_email_reminder_on,
        contact_book_on: editFormData.contact_book_on,
        document_search_on: editFormData.document_search_on,
        account_billing_management_on: editFormData.account_billing_management_on,
        multiple_user_on: editFormData.multiple_user_on,
        admin_login_on: editFormData.admin_login_on,
        google_microsoft_login_on: editFormData.google_microsoft_login_on,
        Qcm_on: editFormData.Qcm_on,
        nda_review_on: editFormData.nda_review_on,
        deligence_on: editFormData.deligence_on,
        Transaction_no: editFormData.Transaction_no,
        rbac_on: editFormData.rbac_on,
      });
      setPlanId(editFormData['plan_id'].id);
    }
  }, [isEditing, editFormData]);




  return (
    <div className='text-primary text-base font-normal font-sans'>
      <div className="main_head">
        <h1 className='text-4xl font-bold'>
          {!isEditing ? `Add ${from=='AddCorporatePlan'?'Corporate':''} Plan` : `Edit ${from=='AddCorporatePlan'?'Corporate':''} Plan`}
        </h1>
      </div>

      <div className=''>
        <form className='w-full'>
        <div className="row">
    <div className="col-md-5 pad_leftdd">
    <div className='flex flex-col mb-3'>
            <div className='flex'>
              <label className='mb-2'>Plan Name</label>
              <>
                <img
                  src={HelpIcon}
                  width={12}
                  className='ml-1 -mt-2 cursor-pointer'
                  data-tip='Insert plan name'
                />
                <ReactTooltip backgroundColor='#400835' textColor='#ffffff' />
              </>
            </div>
            <input
              placeholder='Insert plan name'
              className='border-2 border-primary rounded-lg outline-none p-1 pl-2'
              value={planDetail.planName}
              onChange={(e) => {
                setPlanDetail({ ...planDetail, planName: e.target.value });
              }}
            />
            <p className='text-sm text-red-500 font-normal'>{planError.planName}</p>
          </div>

          <div className='flex flex-col mb-3 '>
            <div className='flex'>
              <label className='mb-2'>Select Country</label>
              <>
                <img
                  src={HelpIcon}
                  width={12}
                  className='ml-1 -mt-2 cursor-pointer'
                  data-tip='Select country'
                />
                <ReactTooltip backgroundColor='#400835' textColor='#ffffff' />
              </>
            </div>
            <select
              className='border-2 border-primary rounded-lg outline-none p-1 pl-2'
              onChange={(e) => {
                setPlanDetail({ ...planDetail, countryId: e.target.value });
              }}
              value={planDetail.countryId}
            >
              <option value="">select country</option>
              <option value="101">India</option>

            </select>

            <p className='text-sm text-red-500 font-normal'>
              {planError.countryId}
            </p>
          </div>

          <div className='flex flex-col mb-3'>
            <div className='flex'>
              <label className='mb-2'>Plan Price (Per Licence )</label>
              <>
                <img
                  src={HelpIcon}
                  width={12}
                  className='ml-1 -mt-2 cursor-pointer'
                  data-tip='plan price'
                />
                <ReactTooltip backgroundColor='#400835' textColor='#ffffff' />
              </>
            </div>
            <input
              type='number'
              placeholder='plan price'
              className='border-2 border-primary rounded-lg outline-none p-1 pl-2'
              value={planDetail.price}
              onChange={(e) => {
                setPlanDetail({ ...planDetail, price: e.target.value });
              }}
            />
            <p className='text-sm text-red-500 font-normal'>{planError.price}</p>
          </div>
          {from =='AddNewPlan' && (
            <div className='flex flex-col mb-3'>
              <div className='flex'>
                <label className='mb-2'>Stripe Plan id</label>
                <>
                  <img
                    src={HelpIcon}
                    width={12}
                    className='ml-1 -mt-2 cursor-pointer'
                    data-tip='Stripe plan id'
                  />
                  <ReactTooltip backgroundColor='#400835' textColor='#ffffff' />
                </>
              </div>
              <input
                placeholder='Stripe plan id'
                className='border-2 border-primary rounded-lg outline-none p-1 pl-2'
                value={planDetail.stripePlanId}
                onChange={(e) => {
                  setPlanDetail({ ...planDetail, stripePlanId: e.target.value });
                }}
              />
              <p className='text-sm text-red-500 font-normal'>{planError.stripePlanId}</p>
            </div>
          )}

          {from =='AddCorporatePlan' && (
            <div className='flex flex-col mb-3 '>
              <div className='flex'>
                <label className='mb-2'>Duration</label>
                <>
                  <img
                    src={HelpIcon}
                    width={12}
                    className='ml-1 -mt-2 cursor-pointer'
                    data-tip='Duration'
                  />
                  <ReactTooltip backgroundColor='#400835' textColor='#ffffff' />
                </>
              </div>
              <select
                className='border-2 border-primary rounded-lg outline-none p-1 pl-2'
                onChange={(e) => {
                  setPlanDetail({ ...planDetail, duration: e.target.value });
                }}
                value={planDetail.duration}
              >
                <option value="">Select Duration</option>
                <option value="7">7 Days</option>
                <option value="15">15 Days</option>
                <option value="30">30 Days</option>
                <option value="90">90 Days</option>
                <option value="365">1 Year</option>
                <option value="730">2 Years</option>
                <option value="1095">3 Years</option>

              </select>

              <p className='text-sm text-red-500 font-normal'>
                {planError.duration}
              </p>
            </div>
          )}
        <div className=' mb-3'>
           
           {/* <div className='flex mb-3'>
             <React.Fragment>
               <ToggleSwitch label="Lawyer Partner Network"
                 setPlanDetail={setPlanDetail}
                 planDetail={planDetail}
               />
             </React.Fragment>
           </div> */}

           

           <div style={{ justifyContent: 'space-between' }} className='flex mb-3 mt-5'>
             <div className='flex flex-col'>
               <label className='mb-2'>User Allotted</label>
               <input
                 placeholder='User Allotted'
                 className='border-2 border-primary rounded-lg outline-none p-1 pl-2 w-4/5'
                 value={planDetail.user_allotted_cnt}
                 onChange={(e) => {
                   setPlanDetail({ ...planDetail, user_allotted_cnt: e.target.value });
                 }}
               />
             </div>
             <div className='flex flex-col'>
               <label className='mb-2'>Document Allotted</label>
               <input
                 placeholder='Document Allotted'
                 className='border-2 border-primary rounded-lg outline-none p-1 pl-2 w-4/5'
                 value={planDetail.document_allotted_cnt}
                 onChange={(e) => {
                   setPlanDetail({ ...planDetail, document_allotted_cnt: e.target.value });
                 }}
               />
             </div>
             <div className='flex flex-col'>
               <label className='mb-2'>E-Signature Allotted</label>
               <input
                 placeholder='E-Signature Allotted'
                 className='border-2 border-primary rounded-lg outline-none p-1 pl-2 w-4/5'
                 value={planDetail.e_signature_allotted_cnt}
                 onChange={(e) => {
                   setPlanDetail({ ...planDetail, e_signature_allotted_cnt: e.target.value });
                 }}
               />
             </div>

           </div>
           <div style={{ justifyContent: 'space-between' }} className='flex mt-5'>
             <div className='flex flex-col'>
               <label className='mb-2'>Custome Template Allotted</label>
               <input
                 placeholder='Custome Template Allotted'
                 className='border-2 border-primary rounded-lg outline-none p-1 pl-2 w-4/5'
                 value={planDetail.custom_template_allotted_cnt}
                 onChange={(e) => {
                   setPlanDetail({ ...planDetail, custom_template_allotted_cnt: e.target.value });
                 }}
               />
             </div>
             <div className='flex flex-col'>
               <label className='mb-2'>Legal Template Allotted</label>
               <input
                 placeholder='Legal Template Allotted'
                 className='border-2 border-primary rounded-lg outline-none p-1 pl-2 w-4/5'
                 value={planDetail.legal_template_allotted_cnt}
                 onChange={(e) => {
                   setPlanDetail({ ...planDetail, legal_template_allotted_cnt: e.target.value });
                 }}
               />
             </div>
             <div className='flex flex-col'>
               <label className='mb-2'>AI Template Allotted</label>
               <input
                 placeholder='AI Template Allotted'
                 className='border-2 border-primary rounded-lg outline-none p-1 pl-2 w-4/5'
                 value={planDetail.ai_template_allotted_cnt}
                 onChange={(e) => {
                   setPlanDetail({ ...planDetail, ai_template_allotted_cnt: e.target.value });
                 }}
               />
             </div>
           </div>
         </div>
         {!isEditing ? (
            <>
              <p><b>Note:</b> Please use -1 for unlimited</p>
              <button
                className='bg-primary font-bold text-white w-full p-1 rounded-lg mt-3'
                onClick={(e) => {
                  e.preventDefault();
                  submitPlan();
                }}
              >
                Add Plan
              </button>

            </>
          ) : (
            <>
              <p><b>Note:</b> Please use -1 for unlimited</p>
              <button
                className='bg-secondary font-bold text-white w-full p-1 rounded-lg'
                onClick={(e) => {
                  e.preventDefault();
                  submitPlan();
                }}
              >
                Update Plan
              </button>
            </>

          )}
    </div>
    <div className="col-md-7">
    <div className='list_checkuu'>
              <React.Fragment>
                <ToggleSwitch label="Available for Company"
                  setPlanDetail={setPlanDetail}
                  planDetail={planDetail}
                />
                <ToggleSwitch label="Available for User"
                  setPlanDetail={setPlanDetail}
                  planDetail={planDetail}
                />

              </React.Fragment>
              <React.Fragment>
                <ToggleSwitch
                  label="AutoMagic"
                  setPlanDetail={setPlanDetail}
                  planDetail={planDetail}
                />
                <ToggleSwitch
                  label="Manual"
                  setPlanDetail={setPlanDetail}
                  planDetail={planDetail}
                />
                <ToggleSwitch
                  label="Preset"
                  setPlanDetail={setPlanDetail}
                  planDetail={planDetail}
                />
                <ToggleSwitch label="Aadhaar Signee"
                  setPlanDetail={setPlanDetail}
                  planDetail={planDetail}
                />
              </React.Fragment>
              <React.Fragment>
                <ToggleSwitch label="Custom Template"
                  setPlanDetail={setPlanDetail}
                  planDetail={planDetail}
                />
                <ToggleSwitch label="Legal Template"
                  setPlanDetail={setPlanDetail}
                  planDetail={planDetail}
                />
                <ToggleSwitch label="AI Template"
                  setPlanDetail={setPlanDetail}
                  planDetail={planDetail}
                />

                <ToggleSwitch label="Legecy Integration"
                  setPlanDetail={setPlanDetail}
                  planDetail={planDetail}
                />
              </React.Fragment>
              <React.Fragment>
                <ToggleSwitch label="Seal And Stamp"
                  setPlanDetail={setPlanDetail}
                  planDetail={planDetail}
                />
                <ToggleSwitch label="E-Stamp Attachment"
                  setPlanDetail={setPlanDetail}
                  planDetail={planDetail}
                />
                <ToggleSwitch label="Workflow"
                  setPlanDetail={setPlanDetail}
                  planDetail={planDetail}
                />

                <ToggleSwitch label="Email Notify"
                  setPlanDetail={setPlanDetail}
                  planDetail={planDetail}
                />
              </React.Fragment>
              <React.Fragment>
                <ToggleSwitch label="SMS-Email Reminder"
                  setPlanDetail={setPlanDetail}
                  planDetail={planDetail}
                />
                <ToggleSwitch label="Contact Book"
                  setPlanDetail={setPlanDetail}
                  planDetail={planDetail}
                />
                <ToggleSwitch label="Document Search"
                  setPlanDetail={setPlanDetail}
                  planDetail={planDetail}
                />

                <ToggleSwitch label="Account Billing Access"
                  setPlanDetail={setPlanDetail}
                  planDetail={planDetail}
                />
              </React.Fragment>
              <React.Fragment>
                <ToggleSwitch label="Multiple User"
                  setPlanDetail={setPlanDetail}
                  planDetail={planDetail}
                />
                <ToggleSwitch label="NDA Review"
                  setPlanDetail={setPlanDetail}
                  planDetail={planDetail}
                />
                <ToggleSwitch label="Deligence"
                  setPlanDetail={setPlanDetail}
                  planDetail={planDetail}
                />
                <ToggleSwitch label="Transaction"
                  setPlanDetail={setPlanDetail}
                  planDetail={planDetail}
                />
              </React.Fragment>
              <React.Fragment>
                
                {/* <ToggleSwitch label="RBAC"
                  setPlanDetail={setPlanDetail}
                  planDetail={planDetail}
                /> */}
                {/* <ToggleSwitch label="Admin Login"
                  setPlanDetail={setPlanDetail}
                  planDetail={planDetail}
                /> */}
                {/* <ToggleSwitch label="Social Login"
                  setPlanDetail={setPlanDetail}
                  planDetail={planDetail}
                /> */}

                {/* <ToggleSwitch label="QCM"
                  setPlanDetail={setPlanDetail}
                  planDetail={planDetail}
                /> */}

              </React.Fragment>
            </div>
    </div>
</div>
          
         

          
         
        </form>

      </div>

      <AppModal
        title={modalData.title}
        bodyText={modalData.bodyText}
        setIsOpen={setIsOpen}
        modalIsOpen={modalIsOpen}
        modelAction="PlanForm"
        apiResStatus={modalData.apiResStatus}
      />

    </div>
  );
};

export default PlanForm;
