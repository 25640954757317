import React, { useEffect, useState } from "react";
import { useTable } from "react-table";
import "../contracts/contracts.css";
import DownArrow from "../../assets/icons/down-arrow.svg";
import share from "../../assets/icons/share.svg";
import Again from "../../assets/icons/Again.svg";
import cancelsharesvg from "../../assets/icons/cancelsharesvg.svg";
import DeleteIcon from "../../assets/icons/Delete.svg";
import EditIcon from "../../assets/icons/Edit.svg";
import ReactTooltip from "react-tooltip";

import { resolve } from "../../api/resolve";
import useAxios from "../../hooks/useAxios";
import { autoCloseTimer, delay } from "../../constants/constants";
import { toast } from "react-toastify";
// import RejectDocModal from "../modals/RejectDocModal";
import eye_on from "../../assets/icons/eye_on.png";
import RevertDocModal from "../modals/RevertDocModal";
import { useTranslation } from "react-i18next";
import EyeOnIconWhite from "../../assets/icons/eye_on_white.png";
import clearIcon from "../../assets/icons/clear.png";
import { sharedRepoTableColumns } from "../../constants/constants";
import Search from "../../assets/icons/Search.svg";
import CloseIcon from "../../assets/icons/Close.svg";
import HelpIcon from "../../assets/icons/Help-sm.svg";
import { parseISO, format } from "date-fns";
import ShareRepoLegacy from "../modallegacy/ShareRepoLegacy";
import DeleteModal from "../modallegacy/DeleteModallegacy";

const baseUrl = process.env.REACT_APP_BACKEND_BASE_URL;

export const NoDataComponent = ({ colLength }) => {
  const [rows] = useState(Array(4).fill(""));
  const [cols] = useState(Array(colLength).fill(""));
  return (
    <>
      {rows.map((data, index) => (
        <tr key={`group_ndf_row_${index}`}>
          {cols.map((data, index01) => (
            <td
              key={`group_ndf_td_${index01}`}
              className="px-5 py-2 font-medium text-smW"
            >
              --
            </td>
          ))}
        </tr>
      ))}
    </>
  );
};

const SharedRepolegacy = ({
  itemCount,
  getListData,
  setPreviewDocument,
  setPreviewDocumentData,
}) => {
  const [tableData, settableData] = useState([]);
  const [sharedDocSearchText, setsharedDocSearchText] = useState("");
  const [showActionBtn, setshowActionBtn] = useState(true);
  const [tableColumns, settableColumns] = useState([]);
  const [sharedDocCount, setsharedDocCount] = useState("");
  const [modalshareIsOpen, setmodalshareIsOpen] = useState(false);
  const [modalmultishareIsOpen, setmodalmultishareIsOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isDeletemultiModalOpen, setisDeletemultiModalOpen] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const [repoID, setrepoID] = useState("");
  const [repoName, setrepoName] = useState("");
  const [pageLoading, setPageLoading] = useState(true);
  const [alreadyselecteduser, setalreadyselecteduser] = useState("");
  const [selectedfolders, setselectedfolders] = useState([]);
  const [selectedfiles, setselectedfiles] = useState([]);
  const [nextBtnLimit, setnextBtnLimit] = useState(0);
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({ columns: sharedRepoTableColumns, data: tableData });

  const [docId, setDocId] = useState(0);

  const [revertDocModelOpen, setRevertDocModelOpen] = useState(false);
  const [errMsgDeletedDoc, setErrMsgDeletedDoc] = useState("");
  const [sharedDocList, setsharedDocList] = useState([]);
  const [sortColumn, setSortColumn] = useState("id");
  const [CurrentPage, setCurrentPage] = useState(1);
  const [sortingOrder, setSortingOrder] = useState("desc");
  const [PageLimit, setPageLimit] = useState(5);
  const [SearchText, setSearchText] = useState("");
  const [clearText, setclearText] = useState(false);
  // const [deleteShare, setdeleteShare] = useState(false);
  const [showPageLimit, setshowPageLimit] = useState(true);
  const api = useAxios();
  const { t } = useTranslation();

  const getSharedDocList = async () => {
    const res = await resolve(
      api
        .get(
          `legacy_contract/ShareRepoView/?ordering=${sortColumn}&sortingOrder=${sortingOrder}&page=${CurrentPage}&limit=${PageLimit}&search=${
            clearText ? "" : sharedDocSearchText
          }`
        )
        .then((res) => res.data)
      // api
      // .get(
      //   `legacy_contract/ShareRepoView/`
      // )
      // .then((res) => res.data)
    );

    if (res.data) {
      const { data } = res;
      const { count, results } = data;
      const filterData = [];
      if (results.length > 0) {
        setsharedDocCount(count);

        results.forEach((result) => {
          const docObj = {
            id: result?.shared_entity_id,
            act_id: result?.id,
            name: result?.shared_entity_name,

            // updated_at: format(
            //   parseISO(result?.updated_at),
            //   "ccc, dd MMM yyyy, HH:mm"
            // ),
            created_at: format(
              parseISO(result?.created_at),
              "ccc, dd MMM yyyy, HH:mm"
            ),
            SharedWith: result?.sharedwith_name,
            updated_at: result?.expiry_date,
            shared_by_id: result?.shared_by_id,
            shared_with_id: result?.shared_with_id,
            type_of_shared: result?.type_of_shared,
          };
          filterData.push(docObj);
        });
      } else {
        setErrMsgDeletedDoc(t("homePage.No more document to view"));
      }
      setsharedDocList([...filterData]);
      settableData([...filterData]);
    }
  };

  const deleteShare = async () => {
    setPageLoading(true);
    const formData = new FormData();
    formData.append("repo_ids", selectedfolders);
    formData.append("shared_with", alreadyselecteduser);
    const res = await resolve(
      api
        .post(`legacy_contract/ShareRepoDelete/`, formData)
        .then((res) => res.data)
    );
    if (res.data) {
      toast.success(t("fileBrowser.Shared Repository Revoked successfully"));
    } else {
      toast.error(t("fileBrowser.Shared Repository was not Revoked. Try again"));
    }
    getSharedDocList();
    setIsDeleteModalOpen(false);
    setPageLoading(false);
  };
  const shareagain = async (test) => {
    function formatDate(date) {
      var d = new Date(date),
        month = "" + (d.getMonth() + 1),
        day = "" + d.getDate(),
        year = d.getFullYear();

      if (month.length < 2) month = "0" + month;
      if (day.length < 2) day = "0" + day;

      return [year, month, day].join("-");
    }
    if (test.type == "External") {
      const externaldata = new FormData();
      externaldata.append("description", test.description);
      externaldata.append("docId", test.id);
      externaldata.append("email", test.emaillist);
      externaldata.append("name", test.created_by);
      externaldata.append("phone", test.contactnumberlist);
      externaldata.append("sharetype", "shareagain");
      // Append other form data to MarketPlaceForm as needed

      const res = await api.post(
        `${baseUrl}/contract/ShareDocumentExt/`,
        externaldata
      );
      if (res.data.message === "Document Shared Successfully.") {
        // If API call is successful, hide the popup and change button color
        toast.success(res.data.message);
      } else {
        toast.error(res.data.message);
      }
    } else {
      const externaldata = new FormData();
      externaldata.append("description", test.description);
      externaldata.append("docId", test.id);
      externaldata.append("email", test.emaillist);
      externaldata.append("name", test.created_by);
      externaldata.append("kindOfLegalAssistance", test.kindOfLegalAssistance);
      externaldata.append("expectedDate", formatDate(test.updated_at));
      externaldata.append("sharetype", "shareagain");
      const res = await api.post(
        `${baseUrl}/contract/ShareDocument/`,
        externaldata
      );

      if (res.data.message === "Document Shared Successfully.") {
        // If API call is successful, hide the popup and change button color
        toast.success(res.data.message);
      } else {
        toast.error(res.data.message);
      }
    }
  };
  useEffect(() => {
    getSharedDocList();
  }, []);

  return (
    <div className="text-primary text-base font-normal font-sans mt_set">
      <div className="main_head">
        <div className="flex">
          <h1 className="text-4xl font-bold text_c ">
            {t("allModule.Shared Documents")}{" "}
            <>
              <img
                src={HelpIcon}
                width={12}
                className=" cursor-pointer di_mes"
                data-tip={t("allModule.Shared Document's Repository")}
              />
              <ReactTooltip backgroundColor="#400835" textColor="#ffffff" />
            </>
          </h1>
        </div>
        <div className="tab_main">
          <div className="my_document_listing mb-7">
            <div className="pt-5">
              <div className="row justify-between pt-5">
                <div className="col-md-5 pt-5"></div>
                <div className="col-md-5">
                  <div className="flex justify-center">
                    <div className="heg_in flex justify-between items-center border-2 border-primary rounded-md rounded-tl-lg rounded-bl-lg bg-inputBackground px-2 w-full mr-2">
                      <input
                        placeholder={t("fileBrowser.Search for Repository")}
                        className="px-1 py-1 focus:outline-none bg-transparent w-11/12"
                        value={sharedDocSearchText}
                        onChange={(e) => {
                          setsharedDocSearchText(e.target.value);
                        }}
                      />
                      <img
                        src={CloseIcon}
                        width={14}
                        className="cursor-pointer"
                        onClick={() => {
                          setsharedDocSearchText("");
                          getSharedDocList();
                          // setShowDeletedDocClearIcon(false);
                        }}
                      />
                    </div>
                    <button
                      type="submit"
                      className="flex btn btn_theme2 items-center justify-between w_125"
                      onClick={(e) => {
                        e.preventDefault();
                        // setDeletedDocCurrentPage(1);
                        getSharedDocList();
                      }}
                    >
                      <img src={Search} style={{ width: "1rem" }} />
                      <span className="font-semibold ml-1">
                        {t("allModule.Search")}
                      </span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="mt-2 flex flex-col table-container mb-5">
        <div>
          <div className="table_desin">
            <div
              style={{ maxHeight: "60vh" }}
              className="shadow overflow-hidden border-2 border-primary  sm:rounded-lg overflow-y-auto"
            >
              <table {...getTableProps()} className="w-full ">
                <thead>
                  {headerGroups.map((headerGroup) => (
                    <tr
                      className={`cursor-pointer bg-[#F6C107]`}
                      {...headerGroup.getHeaderGroupProps()}
                    >
                      {headerGroup.headers.map((column) => (
                        // Add the sorting props to control sorting. For this example
                        // we can add them into the header props

                        <th
                          scope="col"
                          className="text-left px-2 py-2"
                          {...column
                            .getHeaderProps
                            // column.getSortByToggleProps()
                            ()}
                        >
                          <div className="flex items-center">
                            <span>
                              {t("fileBrowser." + column.render("Header"))}
                            </span>

                            {column.isSorted !== undefined &&
                              (column.isSorted ? (
                                <span
                                  onClick={(e) => {
                                    setSortingOrder("asc");
                                    setSortColumn(column.id);

                                    column.isSorted = false;
                                  }}
                                  style={{ cursor: "pointer", marginTop: 2 }}
                                  className="ml-1"
                                >
                                  <img src={DownArrow} />
                                </span>
                              ) : (
                                <span
                                  onClick={(e) => {
                                    setSortingOrder("desc");
                                    setSortColumn(`${column.id}`);

                                    column.isSorted = true;
                                  }}
                                  style={{
                                    cursor: "pointer",
                                    transform: "rotate(180deg)",
                                  }}
                                  className="ml-1"
                                >
                                  <img src={DownArrow} />
                                </span>
                              ))}
                          </div>
                        </th>
                      ))}
                      {showActionBtn && (
                        <th scope="col" className="text-left px-4 pt-2">
                          {t("constant.Action")}
                        </th>
                      )}
                    </tr>
                  ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                  {tableData.length < 1 && (
                    <NoDataComponent colLength={tableColumns.length} />
                  )}
                  {tableData.length > 0 &&
                    rows.map((row, i) => {
                      // new

                      prepareRow(row);
                      return (
                        <tr {...row.getRowProps()}>
                          {row.cells.map((cell, row_cell_index) => {
                            return (
                              <td
                                {...cell.getCellProps()}
                                className={`px-2 py-2 font-medium text-sm contracts-col-data ${
                                  cell.value === "In-Active" && "text-red-500"
                                }`}
                                style={
                                  cell.column.Header === "Contract Name"
                                    ? {
                                        width: 220,
                                        overflow: "hidden",
                                        display: "block",
                                        textOverflow: "ellipsis",
                                      }
                                    : null
                                }
                              >
                                <span
                                  title={
                                    cell.column.Header === "Document Name"
                                      ? cell.value
                                      : ""
                                  }
                                  className={`${
                                    cell.column.Header === "Document Name"
                                      ? "text_fixed cursor-pointer"
                                      : ""
                                  }`}
                                >
                                  {cell.render("Cell")}
                                </span>
                              </td>
                            );
                          })}
                          {showActionBtn && (
                            <td className="py-2 flex">
                              <button
                                onClick={(e) => {
                                  e.stopPropagation();
                                  setmodalshareIsOpen(true);
                                  setIsUpdating(true);
                                  setalreadyselecteduser(
                                    row.original.shared_with_id
                                  );
                                  setrepoID(row.original.id);
                                  setselectedfolders([row.original.id]);
                                  setrepoName(row.original.name);
                                  setmodalmultishareIsOpen(true);
                                  setPreviewDocumentData(row.original);
                                }}
                                className="btn btn_theme btn-sm mr-1 wh_spab w-10 pl-2"
                                data-tip={t("modal.Update")}
                              >
                                {/* {t('constant.View')} */}
                                <img
                                  src={EditIcon}
                                  className="ml-0.5"
                                  width={20}
                                />
                              </button>
                              <button
                                onClick={(e) => {
                                  e.stopPropagation();
                                  setalreadyselecteduser(
                                    row.original.shared_with_id
                                  );
                                  setrepoID(row.original.id);
                                  setselectedfolders([row.original.id]);
                                  setrepoName(row.original.name);
                                  setIsDeleteModalOpen(true);
                                }}
                                className="btn btn_theme btn-sm mr-1 wh_spab w-10 pl-2"
                                data-tip={t("fileBrowser.Revoke Access")}
                              >
                                {/* {t('constant.View')} */}
                                <img
                                  src={DeleteIcon}
                                  className="ml-0.5"
                                  width={20}
                                />
                              </button>
                            </td>
                          )}
                          <ReactTooltip
                            backgroundColor="#400835"
                            textColor="#ffffff"
                          />
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

      <ShareRepoLegacy
        setmodalshareIsOpen={setmodalshareIsOpen}
        modalshareIsOpen={modalshareIsOpen} // Assuming isModalOpen is defined somewhere
        isUpdating={isUpdating}
        setIsUpdating={setIsUpdating}
        parentid={repoID}
        repoName={repoName}
        modalmultishareIsOpen={modalmultishareIsOpen}
        selectedfolders={selectedfolders}
        selectedfiles={selectedfiles}
        alreadyselecteduser={alreadyselecteduser}
        getSharedDocList={getSharedDocList}
      />
      <DeleteModal
        title={t("allModule.Delete Repository")}
        bodyText={t(
          "common.This action cannot be undone. Are you sure you want to continue?"
        )}
        isDeleteModalOpen={isDeleteModalOpen}
        setIsDeleteModalOpen={setIsDeleteModalOpen}
        deleteShare={deleteShare}
        isDeletemultiModalOpen={isDeletemultiModalOpen}
        selectedfolders={selectedfolders}
        selectedfiles={selectedfiles}
      />
    </div>
  );
};

export default SharedRepolegacy;
