import React, { useState, useEffect, useContext } from "react";
import dayjs from "dayjs";
import NotificationsTable from "./NotificationsTable";
import {
  notificationTableColumns,
  notificationTableColumnslegacy,
} from "../../constants/constants";
import useAxios from "../../hooks/useAxios";
import { resolve } from "../../api/resolve";
import NotificationModal from "../modals/NotificationModal";
import AppContext from "../../context/AppContext";
import LoadingScreen from "../loader/LoadingScreen";
import { useTranslation } from "react-i18next";
import NotificationsTablelegacy from "../notificationslegacy/NotificationsTablelegacy";
import DocumentPreviewModal from "../modals/DocumentPreviewModal";

const Notifications = () => {
  const api = useAxios();
  const { t } = useTranslation();
  const { getNotificationsCount } = useContext(AppContext);
  const { getNotificationsCountlegacy } = useContext(AppContext);
  const [errMsg, setErrMsg] = useState("");
  const [nextPage, setIsNextPage] = useState(null);
  const [prevPage, setIsPrevPage] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);

  const [nextPagelegacy, setIsNextPagelegacy] = useState(null);
  const [prevPagelegacy, setIsPrevPagelegacy] = useState(null);
  const [currentPagelegacy, setCurrentPagelegacy] = useState(1);
  const [sortingOrder, setSortingOrder] = useState("desc");
  const [sortColumn, setSortColumn] = useState("id");

  const [sortingOrderlegacy, setSortingOrderlegacy] = useState("desc");
  const [sortColumnlegacy, setSortColumnlegacy] = useState("id");
  const [notifications, setNotifications] = useState([]);
  const [notificationslegacy, setNotificationslegacy] = useState([]);
  const [pageLoading, setPageLoading] = useState(true);
  const [modalIsOpen, setIsOpen] = useState(false);
  const [notifIsOpen,setnotifIsOpen] = useState(false);
  const [notiftype, setnotiftype] = useState("normal");
  const [selectedNotification, setSelectedNotification] = useState([]);
  const [selectedNotificationlegacy, setSelectedNotificationlegacy] = useState(
    []
  );
  const [docurl, setdocurl] = useState("");
  const [previewDocument, setPreviewDocument] = useState(false);
  const [previewDocumentData, setPreviewDocumentData] = useState({});
  const baseUrl = process.env.REACT_APP_BACKEND_BASE_URL;
  const fetchData = async (id) => {
    try {
      // Fetch data from API
      let sharedata = {"docId":id}
      const res = await api.post(`${baseUrl}/contract/document/info/`, sharedata);
      
      // Extract data from response
      const responseData = res.data;
      // Update state with fetched data
      console.log("responseData",responseData.sas_token,responseData.DocDetail.file_url);
      setPreviewDocumentData(responseData.DocDetail);
      setdocurl(responseData.DocDetail.file_url+"?"+responseData.sas_token)

      
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };




  const getNotificationslegacy = async () => {
    const res = await resolve(
      api
        .get(
          `legacy_notification/upcoming_notifications/?ordering=${sortColumnlegacy}&page=${currentPagelegacy}&sortingOrder=${sortingOrderlegacy}`
        )
        .then((res) => res.data)
    );
    if (res.data) {
      const { results, next, previous } = res.data;
      let filterData = [];
      if (results.length > 0) {
        results.forEach((result) => {
          const contractObj = {
            id: result.id,
            title: result.msg,
            date: dayjs(result.for_date).format("DD MMMM YYYY"),
            read: result.is_seen ? "Seen" : "Not Seen",
            contract_details: result.contract_details,
            type: result.type,
          };
          filterData.push(contractObj);
        });
      } else {
        setErrMsg(t("modal.No notifications found"));
      }
      filterData.forEach((item) => {
        if (item.contract_details) {
          item.contract_details.replaceAll("\\", "");
          const parsedObj = JSON.parse(item.contract_details);
          item.contract_details = parsedObj;
        }
      });
      setNotificationslegacy([...filterData]);
      setIsNextPagelegacy(next);
      setIsPrevPagelegacy(previous);
    }
    setPageLoading(false);
  };

  const updateNotificationSeenlegacy = async (id) => {
    const res = await resolve(
      api
        .get(`legacy_notification/update_is_seen/${id}`)
        .then((res) => res.data)
    );
    if (res.data) {
      getNotificationsCountlegacy();
      getNotificationslegacy();
    }
  };
  const updateNotificationClearedlegacy = async (id) => {
    const res = await resolve(
      api
        .get(`legacy_notification/update_is_cleared/${id}`)
        .then((res) => res.data)
    );
    if (res.data) {
      getNotificationsCountlegacy();
      getNotificationslegacy();
    }
  };

  const clearAllNotificationslegacy = async () => {
    setPageLoading(true);
    const res = await resolve(
      api.get(`legacy_notification/clear_all/`).then((res) => res.data)
    );
    if (res.data) {
      getNotificationsCountlegacy();
      getNotificationslegacy();
    }
    setPageLoading(false);
  };

  const getNotifications = async () => {
    const res = await resolve(
      api
        .get(
          `notification/upcoming_notifications/?ordering=${sortColumn}&sortingOrder=${sortingOrder}&page=${currentPage}`
        )
        .then((res) => res.data)
    );

    if (res.data) {
      const { results, next, previous } = res.data;
      let filterData = [];
      if (results.length > 0) {
        results.forEach((result) => {
          const contractObj = {
            id: result.id,
            title: result.msg,
            date: dayjs(result.for_date).format("DD MMMM YYYY"),
            read: result.is_seen ? "Seen" : "Not Seen",
            contract_details: result.contract_details,
            type: result.type,
          };
          filterData.push(contractObj);
        });
      } else {
        setErrMsg(t("allModule.No notifications found"));
      }
      filterData.forEach((item) => {
        if (item.contract_details) {
          item.contract_details.replaceAll("\\", "");
          const parsedObj = JSON.parse(item.contract_details);
          item.contract_details = parsedObj;
        }
      });
      setNotifications([...filterData]);
      setIsNextPage(next);
      setIsPrevPage(previous);
    }
    setPageLoading(false);
  };

  const updateNotificationSeen = async (id) => {
    const res = await resolve(
      api.get(`notification/update_is_seen/${id}`).then((res) => res.data)
    );
    if (res.data) {
      getNotificationsCount();
      getNotifications();
    }
  };
  const updateNotificationCleared = async (id) => {
    const res = await resolve(
      api.get(`notification/update_is_cleared/${id}`).then((res) => res.data)
    );
    if (res.data) {
      getNotificationsCount();
      getNotifications();
    }
  };

  const clearAllNotifications = async () => {
    setPageLoading(true);
    const res = await resolve(
      api.get(`notification/clear_all/`).then((res) => res.data)
    );
    if (res.data) {
      getNotificationsCount();
      getNotifications();
    }
    setPageLoading(false);
  };
  useEffect(() => {
    getNotificationsCount();
    getNotifications();
    getNotificationsCountlegacy();
    getNotificationslegacy();
  }, [
    sortColumn,
    sortingOrder,
    currentPage,
    sortColumnlegacy,
    sortingOrderlegacy,
    currentPagelegacy,
  ]);
  return (
    <>
      {pageLoading ? (
        <LoadingScreen />
      ) : (
        <div className="text-primary">
          <div className="main_head">
            <h1 className="text-4xl font-bold">{t("menu.Notifications")}</h1>
          </div>
          {notifications.length < 1 && (
            <p className="text-red-500 text-sm font-medium my-5">{errMsg}</p>
          )}
          <h1 className="text-2xl font-bold ">
            {t("menu.Fusion Notifications")}:
          </h1>
          <NotificationsTable
            contractsData={notifications}
            contractTableColumns={notificationTableColumns}
            prevPage={prevPage}
            nextPage={nextPage}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            sortingOrder={sortingOrder}
            setSortingOrder={setSortingOrder}
            setSortColumn={setSortColumn}
            fetchData={fetchData}
            setIsOpen={setPreviewDocument}
            updateNotificationSeen={updateNotificationSeen}
            updateNotificationCleared={updateNotificationCleared}
            setSelectedNotification={setSelectedNotification}
            clearAllNotifications={clearAllNotifications}
            setnotiftype={setnotiftype}
          />
          <br></br>
          <h1 className="text-2xl font-bold ">
            {t("menu.Legacy Notifications")}:
          </h1>
          <NotificationsTablelegacy
            contractsData={notificationslegacy}
            contractTableColumns={notificationTableColumnslegacy}
            prevPagelegacy={prevPagelegacy}
            nextPagelegacy={nextPagelegacy}
            currentPagelegacy={currentPagelegacy}
            setCurrentPagelegacy={setCurrentPagelegacy}
            sortingOrder={sortingOrder}
            setSortingOrderlegacy={setSortingOrderlegacy}
            setSortColumnlegacy={setSortColumnlegacy}
            setIsOpen={setIsOpen}
            updateNotificationSeenlegacy={updateNotificationSeenlegacy}
            updateNotificationClearedlegacy={updateNotificationClearedlegacy}
            setSelectedNotification={setSelectedNotification}
            clearAllNotificationslegacy={clearAllNotificationslegacy}
            setnotiftype={setnotiftype}
          />

          <NotificationModal
            modalIsOpen={modalIsOpen}
            setIsOpen={setIsOpen}
            notifications={notifications}
            notiftype={notiftype}
            selectedNotification={selectedNotification}
          />
          <DocumentPreviewModal
            isOpen={previewDocument}
            setIsOpen={setPreviewDocument}
            previewDocumentData={previewDocumentData}
          />
        </div>
      )}
    </>
  );
};

export default Notifications;
