import React from "react";
import Modal from "react-modal";
import "./modal.css";
import { useTranslation } from "react-i18next";

const customStyles = {
  content: {
    top: "45%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    widtg: "auto",
    maxHeight: "auto",
    borderRadius: "11px",
  },
  overlay: {
    backgroundColor: "rgba(64, 8, 53, 0.63)",
  },
};

Modal.setAppElement("#root");
const PermissionInfoModal = ({
  permissionInfoModelOpen,
  setPermissionInfoModelOpen,
  permissionList,
  user,
}) => {
  const { t } = useTranslation();
  return (
    <div className="">
      <Modal
        isOpen={permissionInfoModelOpen}
        onRequestClose={() => setPermissionInfoModelOpen(false)}
        style={customStyles}
        contentLabel="Example Modal"
        onAfterClose={() => {}}
      >
        <div className="text-center modal-container">
          <h1 className="text-primary font-bold text-lg mb-2">
            {t("form.Subscription Benefits and Balance")}
          </h1>
          <hr />
          <div className="mt-2 flex flex-col table-container mb-5 permission-quota-box">
            <table className="table-auto">
              <thead>
                <tr>
                  <th className="px-4 py-2">{t("form.Items​")}</th>
                  <th className="px-4 py-2">{t("form.Allotted Quota")}</th>
                  <th className="px-4 py-2">{t("form.Balance")}</th>
                </tr>
              </thead>
              <tbody>
                {user.user_type == "admin" && (
                  <>
                    <tr>
                      <td className="border px-4 py-2">
                        Total Original User Quantity
                      </td>
                      <td className="border px-4 py-2">
                        {permissionList.total_original_user_qty}
                      </td>
                      <td className="border px-4 py-2">
                        {permissionList.total_remaining_user_quantity}
                      </td>
                    </tr>
                    <tr>
                      <td className="border px-4 py-2">
                        {t("form.Number of Users")}
                      </td>
                      <td className="border px-4 py-2">
                        {permissionList.user_allotted_cnt == -1
                          ? t("constant.Unlimited")
                          : permissionList.user_allotted_cnt}
                      </td>
                      <td className="border px-4 py-2">
                        {permissionList.user_allotted_cnt == -1
                          ? t("constant.Unlimited")
                          : permissionList.user_remaing_cnt}
                      </td>
                    </tr>
                  </>
                )}

                <tr>
                  <td className="border px-4 py-2">
                    {t("form.Document Uploads")}
                  </td>
                  <td className="border px-4 py-2">
                    {permissionList.document_allotted_cnt == -1
                      ? t("constant.Unlimited")
                      : permissionList.document_allotted_cnt}
                  </td>
                  <td className="border px-4 py-2">
                    {permissionList.document_allotted_cnt == -1
                      ? t("constant.Unlimited")
                      : permissionList.document_remaing_cnt}
                  </td>
                </tr>

                <tr>
                  <td className="border px-4 py-2">
                    {t("form.Custom Templates")}
                  </td>
                  <td className="border px-4 py-2">
                    {permissionList.custom_template_allotted_cnt == -1
                      ? t("constant.Unlimited")
                      : permissionList.custom_template_allotted_cnt}
                  </td>
                  <td className="border px-4 py-2">
                    {permissionList.custom_template_allotted_cnt == -1
                      ? t("constant.Unlimited")
                      : permissionList.custom_template_remaing_cnt}
                  </td>
                </tr>

                <tr>
                  <td className="border px-4 py-2">{t("form.Custom Forms")}</td>
                  <td className="border px-4 py-2">
                    {permissionList.legal_template_allotted_cnt == -1
                      ? t("constant.Unlimited")
                      : permissionList.legal_template_allotted_cnt}
                  </td>
                  <td className="border px-4 py-2">
                    {permissionList.legal_template_allotted_cnt == -1
                      ? t("constant.Unlimited")
                      : permissionList.legal_template_remaing_cnt}
                  </td>
                </tr>

                <tr>
                  <td className="border px-4 py-2">
                    {t("form.Generate documents using AI Editor")}​
                  </td>
                  <td className="border px-4 py-2">
                    {permissionList.ai_template_allotted_cnt == -1
                      ? t("constant.Unlimited")
                      : permissionList.ai_template_allotted_cnt}
                  </td>
                  <td className="border px-4 py-2">
                    {permissionList.ai_template_allotted_cnt == -1
                      ? t("constant.Unlimited")
                      : permissionList.ai_template_remaing_cnt}
                  </td>
                </tr>

                <tr>
                  <td className="border px-4 py-2">{t("form.E-Signatures")}</td>
                  <td className="border px-4 py-2">
                    {permissionList.e_signature_allotted_cnt == -1
                      ? t("constant.Unlimited")
                      : permissionList.e_signature_allotted_cnt}
                  </td>
                  <td className="border px-4 py-2">
                    {permissionList.e_signature_allotted_cnt == -1
                      ? t("constant.Unlimited")
                      : permissionList.e_signature_remaing_cnt}
                  </td>
                </tr>

                <tr>
                  <td className="border px-4 py-2">
                    {t("form.Aadhaar Signatures")}
                  </td>
                  <td className="border px-4 py-2">
                    {t("form.Pay as you use")}​
                  </td>
                  <td className="border px-4 py-2">
                    {permissionList.aadhar_quota}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <hr />
          <div className="flex justify-end mt-5">
            <button
              className="bg-[#606060] font-semibold text-white p-1 pl-8 pr-8 rounded-lg w-1/5"
              onClick={() => setPermissionInfoModelOpen(false)}
            >
              {t("common.Close")}
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
};
export default PermissionInfoModal;
