import React, { useState } from 'react';
import { useTable } from 'react-table';
import axios from "axios";
import useAxios from "../../hooks/useAxios";
import DocumentPreviewModal from "../modals/DocumentPreviewModal";
import { useTranslation } from 'react-i18next';

export const NoDataComponent = ({ colLength }) => {
  const [rows] = useState(Array(4).fill(''));
  const [cols] = useState(Array(colLength).fill(''));
  return (
    <>
      {rows.map(() => (
        <tr>
          {cols.map(() => (
            <td className='px-5 py-2 font-medium text-sm text-center'>--</td>
          ))}
        </tr>
      ))}
    </>
  );
};

const ContractsListTable = ({ contractsData, contractTableColumns, notiftype }) => {
  const { t } = useTranslation();
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({ columns: contractTableColumns, data: contractsData });
  const [docurl, setdocurl] = useState("");
  const [previewDocument, setPreviewDocument] = useState(false);
  const [previewDocumentData, setPreviewDocumentData] = useState({});
  const api = useAxios();
  const baseUrl = process.env.REACT_APP_BACKEND_BASE_URL;
  const fetchData = async (id) => {
    try {
      // Fetch data from API
      let sharedata = {"docId":id}
      const res = await api.post(`${baseUrl}/contract/document/info/`, sharedata);
      
      // Extract data from response
      const responseData = res.data;
      // Update state with fetched data
      console.log("responseData",responseData.sas_token,responseData.DocDetail.file_url);
      setPreviewDocumentData(responseData.DocDetail);
      setdocurl(responseData.DocDetail.file_url+"?"+responseData.sas_token)

      
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };
  const handleClick = () => {
    setPreviewDocument(true);
  };
  const handleViewClick = (id) => {
    if (notiftype !== 'legacy') {
      fetchData(id);
      handleClick();
    } else {
      window.open(`/Searchlegacy/${id}`, '_blank');
    }
  };


  return (
    <>
      <div className='mt-5'>
        <div>
          <div>
            <div className='shadow overflow-hidden border-2 border-primary  sm:rounded-lg max-h-96 overflow-y-auto'>
              <table {...getTableProps()} className='block'>
                <thead className='block'>
                  {headerGroups.map((headerGroup) => (
                    <tr
                      {...headerGroup.getHeaderGroupProps()}
                      className=' w-full flex justify-between  px-12'
                    >
                      {headerGroup.headers.map((column) => (
                        // Add the sorting props to control sorting. For this example
                        // we can add them into the header props

                        <th
                          scope='col'
                          className='pt-2  '
                          {...column
                            .getHeaderProps
                            // column.getSortByToggleProps()
                            ()}
                        >
                          <span>{t("constant." + column.render("Header"))}</span>
                        </th>
                      ))}
                      <th className='pt-2 pr-10'>{t('common.Action')}</th>
                      {/* <th scope='col' className='text-left px-6 pt-2'>
                        Notifications
                      </th> */}
                    </tr>
                  ))}
                </thead>
                <tbody {...getTableBodyProps()} className='block'>
                  {contractsData.length < 1 && (
                    <NoDataComponent colLength={contractTableColumns.length} />
                  )}
                  {contractsData.length > 0 &&
                    rows.map((row, i) => {
                      // new
                      prepareRow(row);
                      return (
                        <tr
                          {...row.getRowProps()}
                          className=' w-full flex justify-between px-12 overflow-x-auto'
                        >
                          {row.cells.map((cell) => {
                            return (
                              <>
                                <td
                                  {...cell.getCellProps()}
                                  className={`py-2 font-medium text-sm`}
                                >
                                  {cell.row.original.name ||
                                    cell.row.original.doc_name}
                                </td>
                              </>
                            );
                          })}
                          <td className='-mr-3 py-1'>
                            
                            <button
                              className="bg-secondary text-white font-semibold p-1 px-2 rounded-lg flex items-center"
                              onClick={() => handleViewClick(row.original.id)}
                            >
                              <span>{t("constant.View")}</span>
                              <svg
                                width='16'
                                height='16'
                                viewBox='0 0 42 42'
                                fill='none'
                                xmlns='http://www.w3.org/2000/svg'
                                className='ml-2'
                              >
                                <path
                                  fillRule='evenodd'
                                  clipRule='evenodd'
                                  d='M25.2348 5.21036C23.9817 5.21036 22.9658 4.19449 22.9658 2.94136C22.9658 1.68823 23.9817 0.672363 25.2348 0.672363H38.8488C39.4506 0.672363 40.0277 0.911418 40.4532 1.33694C40.8788 1.76246 41.1178 2.33959 41.1178 2.94136L41.1178 16.5553C41.1178 17.8085 40.1019 18.8243 38.8488 18.8243C37.5957 18.8243 36.5798 17.8085 36.5798 16.5553L36.5798 8.41921L15.4943 29.5047C14.6082 30.3908 13.1715 30.3908 12.2854 29.5047C11.3993 28.6187 11.3993 27.182 12.2854 26.2959L33.371 5.21036H25.2348ZM0.275879 9.74835C0.275879 7.24209 2.30761 5.21036 4.81387 5.21036H16.1589C17.412 5.21036 18.4279 6.22622 18.4279 7.47935C18.4279 8.73249 17.412 9.74835 16.1589 9.74835H4.81387V36.9763H32.0418V25.6313C32.0418 24.3782 33.0577 23.3623 34.3108 23.3623C35.564 23.3623 36.5798 24.3782 36.5798 25.6313V36.9763C36.5798 39.4826 34.5481 41.5143 32.0418 41.5143H4.81387C2.30761 41.5143 0.275879 39.4826 0.275879 36.9763V9.74835Z'
                                  fill='#fff'
                                />
                              </svg>
                              </button>
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <DocumentPreviewModal
        isOpen={previewDocument}
        setIsOpen={setPreviewDocument}
        previewDocumentData={previewDocumentData}
      />
    </>
  );
};

export default ContractsListTable;
