import React, { useState, useEffect, useContext } from 'react';
import 'chart.js/auto';
import { Bar } from 'react-chartjs-2';
import 'react-datepicker/dist/react-datepicker.css';
import MonthPicker from './monthpickerlegacy';
import { resolve } from '../../api/resolve';
import useAxios from '../../hooks/useAxios';
import AuthContext from '../../context/AuthContext';
import { useTranslation } from 'react-i18next';


const options = {
  responsive: true,
  plugins: {
    legend: {
      position: 'bottom', // place legend on the bottom side of chart
      labels: {
        boxWidth: 8,
        pointStyle: 'circle',
        usePointStyle: true,
        maxWidth: 1,
      },
    },
  },
  scales: {
    x: {
      stacked: true,
      grid: {
        display: false,
        drawBorder: false,
      },
    },

    y: {
      stacked: true,
      grid: {
        // display: false,
        drawBorder: false,
      },
      ticks: {
        display: false,
        borderWidth: 0,
        strokeWidth: 0,
      },
    },
  },
};


const StackedBarChart = () => {
  const api = useAxios();
  const { t } = useTranslation();
  const { user } = useContext(AuthContext);
  const [date, setDate] = useState(new Date());
  const [dataSet, setDataSet] = useState({
    labels: [],
    datasets: [],
  });

  const months = [
    t('allModule.Jan'),
    t('allModule.Feb'),
    t('allModule.Mar'),
    t('allModule.Apr'),
    t('allModule.May'),
    t('allModule.Jun'),
    t('allModule.Jul'),
    t('allModule.Aug'),
    t('allModule.Sep'),
    t('allModule.Oct'),
    t('allModule.Nov'),
    t('allModule.Dec'),
  ];

  var chartpluginsset = [
    {
      afterDraw: (chart) => {
        let isDataAVaialable = false;
        if (chart['_metasets'] && chart['_metasets'].length > 0) {
          chart['_metasets'].forEach((arr) =>
            arr['_dataset'].data.forEach((item) => {
              if (item > 0) isDataAVaialable = true;
            })
          );
        }
        if (!isDataAVaialable) {
          var ctx = chart.ctx;
          var width = chart.width;
          var height = chart.height;
          chart.clear();
  
          ctx.save();
          ctx.textAlign = 'center';
          ctx.textBaseline = 'middle';
          ctx.font = '24px "Helvetica Neue", Helvetica, Arial, sans-serif';
          ctx.fillStyle = '#400835';
          ctx.fillText(t('homePage.No data to display'), width / 2, height / 2);
          ctx.restore();
        }
      },
    },
  ];
  
  const getBarChartData = async (date) => {
    const res =
      user.user_type === 'legacy_reviewer'
        ? await resolve(
            api
              .get(`/legacy_reviewer/daily_review_bar_chart/${date}`)
              .then((res) => res.data)
          )
        : await resolve(
            api
              .get(`/legacy_dashboard/contracts_in_repo/${date}`)
              .then((res) => res.data)
          );
    if (res.data) {
      // console.log("legacy_getbarchartdata",res.data)
      let formattedLabels = [];
      res.data.labels.forEach((date) => {
        formattedLabels.push(date.split('/').reverse().join(' / '));
      });
      let monthNames = [];
      formattedLabels.forEach((day) =>
        monthNames.push(
          `${months[Number(day.split('/')[0]) - 1]}- ${day.split('/')[1]}`
        )
      );
      user?.user_type === 'super_admin'
        ? setDataSet({
            labels: monthNames,
            datasets: [
              {
                label: t('homePage.Number of companies'),
                backgroundColor: '#F6C107',
                data: res.data.companies_data,
              },
              {
                label: t('homePage.Number of Repositories'),
                data: res.data.repo_data,
                backgroundColor: '#DE3127',
              },
              {
                label: t('homePage.Admins per Selected Company'),
                data: res.data.admin_data,
                backgroundColor: '#400835',
              },
              {
                label: t('homePage.Users per Selected Company'),
                data: res.data.user_data,
                backgroundColor: '#E0720C',
              },
            ],
          })
        : user?.user_type === 'legacy_reviewer'
        ? setDataSet({
            labels: monthNames,
            datasets: [
              {
                label: t('homePage.Number of Contracts'),
                backgroundColor: '#F6C107',
                data: res.data.reviewed_data,
              },
            ],
          })
        : setDataSet({
            labels: monthNames,
            datasets: [
              {
                label: t('homePage.Number of Contracts'),
                backgroundColor: '#F6C107',
                data: res.data.contract_data,
              },
            ],
          });
    }
    
  };
  useEffect(() => {
    const dateString = `${date.getUTCMonth() + 1}/${date.getUTCFullYear()}`;
    getBarChartData(dateString);
  }, [date, user]);

  return (
    <div className='border-2 border-primary stacked-container p-5 mt-5 stacked-chart-container'>
      <div className='flex justify-between items-center w-full mb-2'>
        <h1 className='font-bold text-xl text-primary'>
          {user.user_type === 'legacy_reviewer'
            ? t('homePage.Review History')
            : t('homePage.legacy_contracts in repository')}
        </h1>
        <div>
          <MonthPicker date={date} setDate={setDate} />
        </div>
      </div>
      <div>
        <Bar data={dataSet} options={options} plugins={chartpluginsset}></Bar>
      </div>
    </div>
  );
};

export default StackedBarChart;
