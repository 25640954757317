import React, { useContext, useEffect, useState } from "react";
import Modal from "react-modal";
import "./modal.css";
import LoadingScreen from "../loader/LoadingScreen";
import { Worker, Viewer } from "@react-pdf-viewer/core";
import AppContext from "../../context/AppContext";
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";
import DownArrow from "../../assets/icons/down-arrow.svg";
import ReactTooltip from "react-tooltip";
import { useTranslation } from "react-i18next";

const customStyles = {
  content: {
    top: "45%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    maxWidth: "80%",
    maxHeight: "auto",
    borderRadius: "11px",
    minWidth: "300px",
  },
  overlay: {
    backgroundColor: "rgba(64, 8, 53, 0.63)",
  },
};

Modal.setAppElement("#root");
const DocumentPreviewModal = ({ isOpen, setIsOpen, previewDocumentData }) => {
  const [docUrl, setDocUrl] = useState("");
  const [loading, setLoading] = useState(false);
  const { highlightToken, getSasToken, getHighlightSasToken } =
    useContext(AppContext);
  const { t } = useTranslation();

  const renderToolbar = (Toolbar) => (
    <Toolbar>
      {(slots) => {
        const {
          CurrentPageInput,
          GoToNextPage,
          GoToPreviousPage,
          NumberOfPages,

          CurrentScale,
          ZoomIn,
          ZoomOut,
        } = slots;

        return (
          <div className={`w-full`}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                width: "100%",
                justifyContent: "space-between",
              }}
            >
              <div className={`flex ml-8 items-center justify-center`}>
                <div className="w-10">
                  <CurrentPageInput />
                </div>
                <div className="mx-2">
                  /{" "}
                  <span>
                    <NumberOfPages />
                  </span>
                </div>
                <div className="flex flex-col ml-1">
                  <GoToPreviousPage>
                    {(props) => (
                      <>
                        <span
                          disabled={props.isDisabled}
                          onClick={props.onClick}
                          style={{
                            cursor: "pointer",
                            transform: "rotate(180deg)",
                            width: 15,
                            height: 15,
                          }}
                          className="ml-1"
                          data-tip="Previous Page"
                        >
                          <img src={DownArrow} alt="img" />
                        </span>
                        <ReactTooltip
                          backgroundColor="#400835"
                          textColor="#ffffff"
                        />
                      </>
                    )}
                  </GoToPreviousPage>
                  <GoToNextPage>
                    {(props) => (
                      <>
                        <span
                          disabled={props.isDisabled}
                          onClick={props.onClick}
                          style={{
                            cursor: "pointer",
                            marginTop: 2,
                            width: 15,
                            height: 15,
                          }}
                          className="ml-1"
                          data-tip="Next Page"
                        >
                          <img src={DownArrow} alt="img" />
                        </span>
                        <ReactTooltip
                          backgroundColor="#400835"
                          textColor="#ffffff"
                        />
                      </>
                    )}
                  </GoToNextPage>
                </div>
              </div>
              <div className="max-w-fit flex items-center">
                <ZoomIn />
                <span className="mx-1">{CurrentScale()}</span>
                <ZoomOut />
              </div>
            </div>
          </div>
        );
      }}
    </Toolbar>
  );

  const defaultLayoutPluginInstance = defaultLayoutPlugin({
    renderToolbar,
    sidebarTabs: () => [],
  });

  useEffect(() => {
    if (isOpen) {
      getHighlightSasToken();
      setDocUrl(previewDocumentData.converted_file_url);
    }
  }, [isOpen]);

  return (
    <div className="">
      <Modal
        isOpen={isOpen}
        onRequestClose={() => setIsOpen(false)}
        style={customStyles}
        onAfterClose={() => {}}
      >
        <div className=" modal-container">
          <div className="flex justify-between">
            <span style={{ color: "white" }}>.</span>
            <h3 className="text-center text-primary font-bold text-lg">
              {previewDocumentData.name}
            </h3>
            <button
              onClick={(e) => {
                e.preventDefault();
                setIsOpen(false);
              }}
              className="bg-[#606060] font-semibold text-white p-1 pl-2 pr-2 rounded-lg"
            >
              X
            </button>
          </div>

          <div className="box_u1 p-4">
            <div className="box_u2 account_pdf_view">
              <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.13.216/build/pdf.worker.min.js">
                {loading ? (
                  <LoadingScreen />
                ) : (
                  <>
                    {previewDocumentData.converted_file_url && (
                      <Viewer
                        fileUrl={`${previewDocumentData.converted_file_url}?${highlightToken.sas_token}`}
                        plugins={[defaultLayoutPluginInstance]}
                        onDocumentAskPassword={(e) => {}}
                        renderError={() => (
                          <div className="grid place-items-center h-full w-full">
                            <h1 className="font-semibold">
                              {t(
                                "common.Contract indexing in progress. Check back later"
                              )}
                            </h1>
                          </div>
                        )}
                        renderLoader={() => <LoadingScreen />}
                      />
                    )}
                  </>
                )}
              </Worker>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default DocumentPreviewModal;
