import React, { useEffect, useContext, useState } from 'react';
import TagsInput from 'react-tagsinput';
import Creatable from 'react-select/creatable';
import ReactTooltip from 'react-tooltip';
import LoadingScreen from '../loader/LoadingScreen';
import {
  CircularProgressbarWithChildren,
  buildStyles,
} from 'react-circular-progressbar';
import dayjs from 'dayjs';
import '../forms/companyForm.css';
import { resolve } from '../../api/resolve';
import { customStyles } from '../../constants/styles';
import FormContext from '../../context/FormContext';
import AppContext from '../../context/AppContext';
import AppModal from '../modals/Modal';
import DatePickerComponent from '../../shared/DatePickerComponent';
import AddContractModal from '../modals/AddContractModal';
import 'react-tagsinput/react-tagsinput.css';
import useAxios from '../../hooks/useAxios';
import HelpIcon from '../../assets/icons/Help-sm.svg';
import CloseIcon from '../../assets/icons/Close.svg';
import { toast } from 'react-toastify';
import PermissionContext from '../../context/PermissionContext';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const ContractForm = () => {
  const {
    setCurrentForm,
    contractFormErrors,
    modalData,
    setIsOpen,
    modalIsOpen,
    isEditing,
    editFormData,
    setEditFormData,
    setIsEditing,
    setContractFormErrors,
    saveOrEditContract,
    loading,
    progressValue,
  } = useContext(FormContext);
  const api = useAxios();
  const { t } = useTranslation();
  const {
    activeLink,
    setActiveLink,
    repoName,
    setRepoName,
    isRepoList,
    setRepoID,
    repoID,
    setShowDropdown,
  } = useContext(AppContext);
  const { permissionList, getComapnyInfoApi, isValidCompany, updatePermissionList } = useContext(PermissionContext);
  const navigate = useNavigate();
  const [startDate, setStartDate] = useState(
    isEditing &&
    editFormData?.renewal_date &&
    editFormData.renewal_date !== 'Not Added' &&
    new Date(editFormData?.renewal_date)
  );
  const [contractObj, setContractObj] = useState({
    name: '',
    type: isEditing && editFormData ? editFormData?.type : '',
    tags:
      isEditing && editFormData.tags && editFormData.tags.length > 0
        ? [...editFormData.tags.trim().split(',')]
        : [],
    doc_name: '',
    repo_name: repoName,
    repoID,
    renewal_date: startDate,
    document: '',
    checkSumValue: '',
  });
  const [contractModalIsOpen, setIsContractModalOpen] = useState(false);
  const [isMultipleContracts, setIsMultipleContracts] = useState(false);
  const [multipleContractsObj, setMultipleContractsObj] = useState([]);
  const [contractTypesList, setContractsTypesList] = useState([]);
  const [defaultValues, setDefaultValues] = useState([]);
  const getTypesData = async () => {
    const types = await resolve(
      api.get('/contract/get_all_contract_types/').then((res) => res.data)
    );
    if (types.data) {
      const filteredArr = [];
      const { contract_type_list } = types.data;
      contract_type_list.forEach((type) => {
        filteredArr.push({ value: type.name, label: type.name, id: type.id });
      });
      setContractsTypesList(filteredArr);
    }
  };

  const handleSaveEditContract = async () => {

    saveOrEditContract(contractObj, updatePermissionList);

  };

  useEffect(() => {
    getTypesData();
    setContractFormErrors({
      ...contractFormErrors,
      name: '',
      type: '',
      document: '',
      renewal_date: '',
      multi_document: '',
    });
    setRepoName(repoName);
    setRepoID(repoID);
    setCurrentForm('ContractForm');
    getComapnyInfoApi();
    if (editFormData && editFormData.type) {
      let filteredArr = editFormData.type.split(',');
      if (filteredArr.length > 0) {
        filteredArr.forEach((val) => {
          if (val.trim().length > 0) {
            defaultValues.push({ value: val, label: val });
          }
        });
      }
    }
    return () => setCurrentForm('');
  }, []);
  useEffect(() => {
    setIsEditing(false);
    return () => {
      if (isEditing) {
        setEditFormData('');
        setIsEditing(false);
      }
    };
    
  }, [isEditing]);
  useEffect(() => {
    if (isEditing) {
      setEditFormData({
        ...editFormData,
        renewal_date: dayjs(startDate).format('YYYY-MM-DD'),
      });
    } else {
      setContractObj({
        ...contractObj,
        renewal_date: startDate ? dayjs(startDate).format('YYYY-MM-DD') : '',
      });
    }
  }, [startDate]);


  if (loading && !isEditing) {
    return (
      <div className='grid place-items-center h-full'>
        <div
          style={{
            width: 150,
            height: 150,
          }}
        >
          <CircularProgressbarWithChildren
            value={progressValue - 1}
            styles={buildStyles({
              strokeLinecap: 'butt',
              pathColor: `#400835`,
              textColor: '#f88',
              trailColor: '#d6d6d6',
              backgroundColor: '#3e98c7',
            })}
          >
            <div className='text-center text-primary'>
              <span className='font-bold text-4xl ml-2'>
                {progressValue - 1}%
              </span>
              <p className='text-xs font-bold mt-1 space-x-10'>{t('allModule.Uploading')}</p>
            </div>
          </CircularProgressbarWithChildren>
        </div>
      </div>
    );
  } else if (loading && isEditing) {
    return <LoadingScreen />;
  }
  return (
    <div className='text-primary text-base font-normal font-sans upload_doc_page'>
      <div className="main_head">
        <h1 className='text-4xl font-bold '>
          {!isEditing ? activeLink =='ContractForm'? t('allModule.Upload Documents for Signature'): t('constant.Upload Document for Collaboration'): t('allModule.Edit Document')}
        </h1>
      </div>

     
      {!isValidCompany ? (
        <div className="flex justify-center items-center h-96 flex-column ">
          <h3 className="text-2xl font-bold text_c">{t('allModule.Please update your company info.')}</h3>
        </div>
      ) :
        permissionList.document_allotted_cnt > 0 && permissionList.document_remaing_cnt === 0  ? (
          <div className="flex justify-center items-center h-96 flex-column ">
            <h3 className="text-2xl font-bold text_c">{t('allModule.You have no more limit to upload document.')}</h3>
          </div>
        ) : (
          <div className=' flex w-full items-center h-full'>
            <form className='w-2/5'>
              <div className='flex flex-col mb-3 contract-type'>
                <div className='flex'>
                  <label className='mb-2'>{t('allModule.Name of Document')}</label>
                  <>
                    <img
                      src={HelpIcon}
                      width={12}
                      className='ml-1 -mt-2 cursor-pointer'
                      data-tip={t('allModule.Enter name of the document')}
                    />
                    <ReactTooltip backgroundColor='#400835' textColor='#ffffff' />
                  </>
                </div>
                <input
                  placeholder={t('allModule.Enter name of the document')}
                  className='border-2 border-primary rounded-lg outline-none p-1 pl-2'
                  value={!isEditing ? contractObj.name : editFormData.name}
                  onChange={(e) => {
                    !isEditing
                      ? setContractObj({ ...contractObj, name: e.target.value })
                      : setEditFormData({
                        ...editFormData,
                        name: e.target.value,
                      });
                  }}
                />
                <p className='text-sm text-red-500 font-normal'>
                  {contractFormErrors.name}
                </p>
              </div>

              
              <div className='flex flex-col mb-3'>
                <div className='flex'>
                  <label className='mb-2'>{t('allModule.Tags (Optional)')}</label>
                  <>
                    <img
                      src={HelpIcon}
                      width={12}
                      className='ml-1 -mt-2 cursor-pointer'
                      data-tip={t('allModule.Add tags to document')}
                    />
                    <ReactTooltip backgroundColor='#400835' textColor='#ffffff' />
                  </>
                </div>
                <TagsInput
                  value={
                    !isEditing
                      ? contractObj.tags
                      : editFormData.tags && editFormData.tags.length > 0
                        ? editFormData.tags
                          .split(',')
                          .filter((tag) => tag.trim().length > 0)
                        : []
                  }
                  onChange={(newTags) => {
                    !isEditing
                      ? setContractObj({ ...contractObj, tags: newTags })
                      : setEditFormData({
                        ...editFormData,
                        tags: newTags.join(','),
                      });
                  }}
                  inputProps={{
                    placeholder: t('allModule.Type and press enter'),
                  }}
                />
              </div>
              
              {!isEditing ? (
                <>
                  <p className='text-sm text-red-500 font-normal'>
                    {contractFormErrors.checkSumExists && 'Document already exists'}
                  </p>
                  {contractObj.document && (
                    <div className='flex justify-between items-center my-3'>
                      <p
                        className={`text-sm font-semibold ${contractFormErrors.checkSumExists && 'text-red-500'
                          }`}
                      >
                        {t('allModule.Selected File')} : {contractObj.doc_name}
                      </p>
                      <img
                        src={CloseIcon}
                        width={16}
                        className='cursor-pointer'
                        onClick={() => {
                          setContractObj({ ...contractObj, document: '' });
                          setContractFormErrors({
                            ...contractFormErrors,
                            checkSumExists: false,
                          });
                        }}
                      />
                    </div>
                  )}
                  <button
                    className='bg-secondary font-bold text-white w-full p-1 rounded-lg'
                    type='reset'
                    onClick={(e) => {
                      e.preventDefault();
                      setIsContractModalOpen(true);
                    }}
                  >
                    {t('allModule.Upload Single Document')}
                  </button>
                  <button
                    className='bg-primary font-bold text-white w-full p-1 rounded-lg mt-3'
                    onClick={(e) => {
                      e.preventDefault();
                      handleSaveEditContract(e);

                    }}
                  >
                    {t('allModule.Save Single Document')}
                  </button>
                  <p className='text-sm text-red-500 font-normal'>
                    {contractFormErrors.document}
                  </p>
                </>
              ) : (
                <button
                  className='bg-secondary font-bold text-white w-full p-1 rounded-lg'
                  onClick={(e) => {
                    e.preventDefault();
                    saveOrEditContract(editFormData, repoName);
                  }}
                >
                  {t('allModule.Update Document')}
                </button>
              )}
            </form>
            {!isEditing && activeLink =='ContractForm' && (
              <div className='h-64 ml-12 flex items-center w-2/5' style={{ position: 'relative' }}>
                <div
                  style={{
                    height: '100%',
                    width: 5,
                    background: '#400835',
                    borderRadius: 10,
                  }}
                ></div>
                <div className='w-full' >
                  <button
                    className='bg-secondary font-bold text-white w-full ml-4 p-1 px-2 rounded-lg'
                    type='reset'
                    onClick={(e) => {
                      e.preventDefault();
                      setIsMultipleContracts(true);
                      setIsContractModalOpen(true);
                    }}
                  >
                    {t('allModule.Upload Multiple Document')}
                  </button>
                  <p className='text-sm text-red-500 font-normal ml-5 mt-3'>
                    {contractFormErrors.multi_document}
                  </p>

                  <button
                // className='bg-secondary font-bold text-white w-full ml-4 p-1 px-2 rounded-lg'
                className="btn pb-1 pt-0.5 pl-6 pr-6 text_c font-bold rounded-lg btn_theme3 upload_doc_next_btn"
              
                onClick={(e) => {
                  e.preventDefault();
                  navigate('/my-documents/set-up-signatures')
                  setActiveLink('MyDocuments')
                  setShowDropdown('')
                }}
              >
                {t('allModule.My Documents')}
              </button>
                </div>
              </div>
            )}
          </div>
        )}

      <AddContractModal
        setIsOpen={setIsContractModalOpen}
        modalIsOpen={contractModalIsOpen}
        setContractObj={setContractObj}
        contractObj={contractObj}
        isMultipleContracts={isMultipleContracts}
        setIsMultipleContracts={setIsMultipleContracts}
        multipleContractsObj={multipleContractsObj}
        setMultipleContractsObj={setMultipleContractsObj}
        repoName={repoName}
        repoID={repoID}
        activeLink={activeLink}
      />
      <AppModal
        title={modalData.title}
        bodyText={modalData.bodyText}
        setIsOpen={setIsOpen}
        modalIsOpen={modalIsOpen}
        // activeLink='Search'
        // shouldShowRepoList={isRepoList}
        setContractObj={setContractObj}
        contractObj={contractObj}
        setMultipleContractsObj={setMultipleContractsObj}
        setIsContractModalOpen={setIsContractModalOpen}
        modelAction="ContractForm"
      />
    </div>
  );
};

export default ContractForm;
