import React, { useContext, useState, useEffect } from "react";
import Modal from "react-modal";
import "./modal.css";
import { toast } from "react-toastify";
import useAxios from "../../hooks/useAxios";
import { resolve } from "../../api/resolve";
import { useTranslation } from "react-i18next";
import LoadingScreen from "../loader/LoadingScreen";

const customStyles = {
  content: {
    top: "30%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    maxWidth: "600px",
    maxHeight: "auto",
    borderRadius: "11px",
    minWidth: "300px",
  },
  overlay: {
    backgroundColor: "rgba(64, 8, 53, 0.63)",
  },
};

Modal.setAppElement("#root");
const Office365EditModel = ({ isOpen, setIsOpen, docInfo, getListData }) => {
  const api = useAxios();
  const { t } = useTranslation();
  const [activeTable, setActiveTable] = useState("tab1");
  const [loading, setLoading] = useState(false);
  const [localDocInfo, setLocalDocInfo] = useState(docInfo);

  useEffect(() => {
    // Update localDocInfo when docInfo prop changes
    setLocalDocInfo(docInfo);
  }, [docInfo]);

  const handleReopen = () => {
    // Update the local state directly
    setLocalDocInfo((prevDocInfo) => ({
      ...prevDocInfo,
      office365_status: 1,
    }));
  };

  const handleDocumentIn365 = async () => {
    setLoading(true);
    try {
      const documentInfo = await api.post(`/user/SendResponseO365/`, {
        DocId: docInfo.id,
      });
      setLoading(false);
      if (documentInfo.data) {
        window.open(documentInfo.data.link);
      }
    } catch (err) {
      setLoading(false);
      if (err?.response?.data?.error?.message) {
        toast.error(err.response.data.error.message);
      } else {
        toast.error("Please setup your office 365 credentials and try again");
      }
    }
  };

  const closeCollaboration = async () => {
    setLoading(true);
    try {
      const responce = await api.post(`/user/closeCollaboration/`, {
        DocId: docInfo.id,
      });
      setLoading(false);
      if (responce.data) {
        toast.success("Collaboration closed successfully");
        getListData();
        setIsOpen(false);
      }
    } catch (err) {
      setLoading(false);
      if (err?.response?.data?.error?.message) {
        toast.error(err.response.data.error.message);
      } else {
        toast.error("Something went wrong");
      }
    }
  };
  return (
    <div className="">
      <Modal
        isOpen={isOpen}
        onRequestClose={() => setIsOpen(true)}
        style={customStyles}
        onAfterClose={() => {}}
      >
        <div className=" modal-container">
          <div className="flex justify-between ">
            <p style={{ color: "white" }}>.</p>
            <h1 className="text-center text-primary font-bold text-lg">
              Office 365
            </h1>
            <button
              onClick={(e) => {
                e.preventDefault();
                getListData();
                setIsOpen(false);
              }}
              className="bg-[#606060] font-semibold text-white p-1 pl-2 pr-2 rounded-lg"
            >
              X
            </button>
          </div>
          {loading ? (
            <LoadingScreen />
          ) : (
            <div className="tab_main">
              <div className={`tab_us show`}>
                <div
                  style={{
                    width: "100%",
                    padding: "5px 10px",
                    height: "100px",
                  }}
                  //
                >
                  <b>Latest Version</b>
                  <div className="flex justify-between">
                    <p>File: {docInfo?.name}</p>
                    {localDocInfo?.office365_status === 2 ? (
                      <button
                        onClick={(e) => {
                          e.stopPropagation();
                          handleReopen();
                        }}
                        className="btn btn_theme btn-sm mr-1 wh_spab"
                      >
                        {t("constant.Reopen in O-365")}
                      </button>
                    ) : (
                      <>
                        {" "}
                        <button
                          onClick={(e) => {
                            e.stopPropagation();
                            handleDocumentIn365();
                          }}
                          className="btn btn_theme btn-sm mr-1 wh_spab"
                        >
                          {t("constant.Edit in O-365")}
                        </button>
                        <button
                          onClick={(e) => {
                            e.stopPropagation();
                            closeCollaboration();
                          }}
                          className="btn btn_theme btn-sm mr-1 wh_spab"
                        >
                          Close Collaboration
                        </button>
                      </>
                    )}
                  </div>
                </div>

                <ul className="nav nav-tabs">
                  <li>
                    <b>Note:</b>
                  </li>
                  <li>
                    <b>*</b> You can come back and edit the document anytime by
                    clicking on the "Edit in O-365" button, until the
                    collaboration is closed.
                  </li>
                  <li>
                    <b>*</b> Please ensure that all the redlines are acted upon
                    and all comments are removed before closing the
                    collaboration.
                  </li>
                </ul>
              </div>
            </div>
          )}
        </div>
      </Modal>
    </div>
  );
};

export default Office365EditModel;
