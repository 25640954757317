
const Search = () => {
    return (<>
    <div className='text-primary text-base font-normal font-sans mt_set'>
    <div className="main_head">
    <h1 className='text-4xl font-bold text_c'>
        Create Search
            </h1>
    </div>
       
            </div>
        
    </>)
}

export default Search;