import React, { useEffect, useContext, useState, useRef } from 'react';
import DocumentContext from '../../context/DocumentContext';
import useAxios from '../../hooks/useAxios';
import { resolve } from '../../api/resolve';
import AppContext from '../../context/AppContext';
import { Worker, Viewer } from '@react-pdf-viewer/core';
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import LoadingScreen from '../loader/LoadingScreen';
import ReactTooltip from 'react-tooltip';
import DownArrow from '../../assets/icons/down-arrow.svg';
import HelpIcon from '../../assets/icons/Help-sm.svg';
import SignatureCanvas from 'react-signature-canvas';
import { v4 as uuidv4 } from 'uuid';
import { typeOfSign } from '../../constants/constants';
import axios from 'axios';
import { QRCodeSVG } from 'qrcode.react';
import { useTranslation } from 'react-i18next';

const AffixMySignature = () => {
    const {
        activeDocInfoMyDoc,
    } = useContext(DocumentContext);

    const {
        setActiveLink,
        getSasToken,
        highlightToken,
        getHighlightSasToken,
        setShowDropdown

    } = useContext(AppContext);

    const [docUrl, setDocUrl] = useState('');
    const [loading, setLoading] = useState(false);
    const [padState, setPadState] = useState('draw');
    const [image, setImage] = useState();
    const [penColor, setPenColor] = useState('black');
    const signRef = useRef();
    const [formData, setFormData] = useState(null);
    const inputRef = useRef();
    const [recptId, setRecptId] = useState('148');

    const api = useAxios();
    const Id = uuidv4();
    const { t } = useTranslation();

    useEffect(() => {
        if (activeDocInfoMyDoc?.converted_file_url) {
            
            setDocUrl(activeDocInfoMyDoc?.converted_file_url)
            setRecptId(activeDocInfoMyDoc?.id)
        }
    }, [activeDocInfoMyDoc])


    useEffect(() => {
        getSasToken();
        getHighlightSasToken();

    }, []);

    const renderToolbar = (Toolbar) => (
        <Toolbar>
            {(slots) => {
                const {
                    CurrentPageInput,
                    GoToNextPage,
                    GoToPreviousPage,
                    NumberOfPages,
                    ShowSearchPopover,
                    Print,
                    CurrentScale,
                    ZoomIn,
                    ZoomOut,
                } = slots;

                return (
                    <div className={`w-full`}>
                        <div
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                width: '100%',
                                justifyContent: 'space-between',

                            }}
                        >
                            <div className={`flex ml-8 items-center justify-center`}>
                                <div className='w-10'>
                                    <CurrentPageInput />
                                </div>
                                <div className='mx-2'>
                                    /{' '}
                                    <span>
                                        <NumberOfPages />
                                    </span>
                                </div>
                                <div className='flex flex-col ml-1'>
                                    <GoToPreviousPage>
                                        {(props) => (
                                            <>
                                                <span
                                                    disabled={props.isDisabled}
                                                    onClick={props.onClick}
                                                    style={{
                                                        cursor: 'pointer',
                                                        transform: 'rotate(180deg)',
                                                        width: 15,
                                                        height: 15,
                                                    }}
                                                    className='ml-1'
                                                    data-tip='Previous Page'
                                                >
                                                    <img src={DownArrow} />
                                                </span>
                                                <ReactTooltip
                                                    backgroundColor='#400835'
                                                    textColor='#ffffff'
                                                />
                                            </>
                                        )}
                                    </GoToPreviousPage>
                                    <GoToNextPage>
                                        {(props) => (
                                            <>
                                                <span
                                                    disabled={props.isDisabled}
                                                    onClick={props.onClick}
                                                    style={{
                                                        cursor: 'pointer',
                                                        marginTop: 2,
                                                        width: 15,
                                                        height: 15,
                                                    }}
                                                    className='ml-1'
                                                    data-tip='Next Page'
                                                >
                                                    <img src={DownArrow} />
                                                </span>
                                                <ReactTooltip
                                                    backgroundColor='#400835'
                                                    textColor='#ffffff'
                                                />
                                            </>
                                        )}
                                    </GoToNextPage>
                                </div>
                            </div>
                            <div className='max-w-fit flex items-center'>
                                <ZoomIn />
                                <span className='mx-1'>{CurrentScale()}</span>
                                <ZoomOut />
                            </div>

                        </div>
                    </div>
                );
            }}
        </Toolbar>
    );
    /////////// pdf search fuctionality ////////////////////
    const defaultLayoutPluginInstance = defaultLayoutPlugin({
        renderToolbar,
        sidebarTabs: () => [],
    });

    return (
        <div className='text-primary text-base font-normal font-sans mt_set'>
<div className="main_head">
<div className='flex'>
                <h1 className='text-4xl font-bold text_c'>{t('menu.Sign documents')}</h1>
            </div>
    </div>
           

            {docUrl ? (
                <>
                    <div className="row ">
                        <div className="" style={{ width: '100%' }}>
                            <h4 className='text-xl font-bold  mb-2 text_c text-center'>  {activeDocInfoMyDoc?.id ? activeDocInfoMyDoc.name : ''}</h4>

                            <div className="box_u1 p-4 mb-5">
                                <div className="box_u2 affix_signature_pdf_box pdf_box">
                                    <Worker workerUrl='https://unpkg.com/pdfjs-dist@2.13.216/build/pdf.worker.min.js'>
                                        {loading ? (
                                            <LoadingScreen />
                                        ) : (
                                            <>
                                                {docUrl && (
                                                    <Viewer
                                                        fileUrl={`${docUrl}?${highlightToken.sas_token}`}
                                                        plugins={[
                                                            defaultLayoutPluginInstance,
                                                            // searchPluginInstance,
                                                        ]}

                                                        renderError={() => (
                                                            <div className='grid place-items-center h-full w-full'>
                                                                <h1 className='font-semibold'>
                                                                    {t('common.Contract indexing in progress. Check back later')}
                                                                </h1>
                                                            </div>
                                                        )}
                                                        renderLoader={() => <LoadingScreen />}
                                                    />
                                                )}
                                            </>
                                        )}
                                    </Worker>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row ">
                        <div
                            style={{
                                width: '700px',
                                // height: '300px',
                                marginTop: '10px',
                                margin: 'auto',
                                padding: '10px',
                                boxShadow: '0 5px 30px rgb(0 0 0 / 0.2)',
                                borderRadius: '5px',
                            }}
                        >
                            <h2 className='pb-2'>{t('allModule.Virtual Signature Setup')}</h2>

                            <hr />

                            <div style={{ display: 'flex', marginBottom: '10px' }}>
                                <button
                                    style={{
                                        marginRight: 10,
                                        border: 'none',
                                        color: 'gray',
                                        background: 'transparent',
                                        fontWeight: 500,
                                        fontSize: 16,
                                        cursor: 'pointer',
                                        padding: '5px',
                                        borderBottom: padState == 'draw' ? '2px solid black' : 'none',
                                    }}
                                    onClick={() => {
                                        setImage('');
                                        setPadState('draw');
                                    }}
                                >
                                    {t('allModule.Draw')}
                                </button>
                                <button
                                    style={{
                                        border: 'none',
                                        color: 'gray',
                                        background: 'transparent',
                                        fontWeight: 500,
                                        fontSize: 16,
                                        cursor: 'pointer',
                                        padding: '5px',
                                        marginRight: 10,
                                        borderBottom:
                                            padState == 'upload' ? '2px solid black' : 'none',
                                    }}
                                    onClick={() => {
                                        setImage('');
                                        setPadState('upload');
                                    }}
                                >
                                    {t('allModule.Upload')}
                                </button>
                                <button
                                    style={{
                                        border: 'none',
                                        color: 'gray',
                                        background: 'transparent',
                                        fontWeight: 500,
                                        fontSize: 16,
                                        cursor: 'pointer',
                                        padding: '5px',
                                        borderBottom: padState == 'qrcode' ? '2px solid black' : 'none',
                                    }}
                                    onClick={() => {
                                        setImage('');
                                        setPadState('qrcode');
                                    }}
                                >
                                    {t('allModule.Scan QRcode')}
                                </button>
                            </div>

                            <>
                                {padState === 'draw' ? (
                                    <>
                                        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                            <button
                                                style={{
                                                    marginRight: '5px',
                                                    background: 'black',
                                                    border: 'none',
                                                    width: 20,
                                                    height: 20,
                                                    borderRadius: '50%',
                                                    border: '1px solid gray',
                                                    cursor: 'pointer',
                                                    color: 'white',
                                                    fontWeight: 'bolder',
                                                }}
                                                onClick={() => {
                                                    if (penColor !== 'black') {
                                                        signRef.current.clear();
                                                    }
                                                    setPenColor('black');
                                                }}
                                            >
                                                {penColor === 'black' && <span>&#10003;</span>}
                                            </button>
                                            <button
                                                style={{
                                                    background: 'blue',
                                                    border: 'none',
                                                    width: 20,
                                                    height: 20,
                                                    borderRadius: '50%',
                                                    border: '1px solid gray',
                                                    cursor: 'pointer',
                                                    color: 'white',
                                                    fontWeight: 'bolder',
                                                }}
                                                onClick={() => {
                                                    if (penColor !== 'blue') {
                                                        signRef.current.clear();
                                                    }
                                                    setPenColor('blue');
                                                }}
                                            >
                                                {penColor === 'blue' && <span>&#10003;</span>}
                                            </button>
                                        </div>
                                        <div style={{ border: '1px dashed #400835', marginTop: 5 }}>
                                            <SignatureCanvas
                                                ref={signRef}
                                                penColor={penColor}
                                                canvasProps={{
                                                    width: 500,
                                                    height: 200,
                                                }}
                                                onEnd={() => {
                                                    const url = signRef.current
                                                        .getTrimmedCanvas()
                                                        .toDataURL('image/png');
                                                    fetch(url)
                                                        .then((res) => res.blob())
                                                        .then((blob) => {
                                                            var fd = new FormData();
                                                            fd.append('image', blob, 'filename');
                                                            setFormData(blob);
                                                            let f = new FileReader();
                                                            f.readAsDataURL(blob);
                                                            f.onload = () => setImage(f.result);
                                                        });
                                                }}
                                            />
                                        </div>
                                        <button
                                            style={{
                                                height: 30,
                                                alignSelf: 'flex-end',
                                                border: 'none',
                                                background: '#E0720C',
                                                padding: '5px 10px',
                                                borderRadius: '2px',
                                                color: '#ffffff',
                                                fontSize: '14px',
                                                cursor: 'pointer',
                                                fontWeight: 500,
                                                marginTop: 5,
                                            }}
                                            onClick={() => {
                                                signRef.current.clear();
                                            }}
                                        >
                                            Clear
                                        </button>
                                    </>
                                ) : padState === 'upload' ? (
                                    <div
                                        style={{
                                            height: 200,
                                            display: 'grid',
                                            placeItems: 'center',
                                            border: '1px dashed #400835',
                                        }}
                                    >
                                        {image && <img src={image} width={200} height={150} />}
                                        <button
                                            style={{
                                                background: '#400835',
                                                padding: '8px 10px',
                                                borderRadius: '5px',
                                                color: '#ffffff',
                                                fontSize: '14px',
                                                cursor: 'pointer',
                                                fontWeight: 500,
                                                border: 'none',
                                            }}
                                            onClick={() => inputRef.current.click()}
                                        >
                                            {t('allModule.SELECT IMAGE')}
                                        </button>
                                        <input
                                            ref={inputRef}
                                            hidden
                                            type='file'
                                            accept='.png, .jpeg'
                                            onChange={(e) => {
                                                setFormData(e.target.files[0]);
                                                let f = new FileReader();
                                                f.readAsDataURL(e.target.files[0]);
                                                f.onload = () => setImage(f.result);
                                            }}
                                        />
                                    </div>
                                ) : (
                                    <div
                                        style={{
                                            height: 200,
                                            display: 'grid',
                                            placeItems: 'center',
                                            border: '1px dashed #400835',
                                        }}
                                    >
                                        <QRCodeSVG value={`${process.env.REACT_APP_BACKEND_BASE_URL}/qr-signature/${recptId}`} />
                                    </div>

                                )}

                                {
                                    padState !== 'qrcode' &&

                                    <div
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'space-between',

                                            marginTop: '10px',
                                        }}
                                    >

                                        <button
                                            style={{
                                                height: 30,
                                                alignSelf: 'flex-end',
                                                border: 'none',
                                                background: '#400835',
                                                padding: '5px 15px',
                                                borderRadius: '5px',
                                                color: '#ffffff',
                                                fontSize: '14px',
                                                cursor: 'pointer',
                                                fontWeight: 500,
                                            }}
                                            onClick={() => {
                                                const data = new FormData();
                                                data.append('image', formData);
                                                data.append('document_recipients_id', recptId);
                                                data.append('qrcode_id', Id); // add the id from url params
                                                data.append(
                                                    'webhook_url',
                                                    `${process.env.REACT_APP_BACKEND_BASE_URL}/contract/webhook_signature_pad/`
                                                );
                                                data.append('is_mobile', "false");
                                                data.append('type_of_sign', typeOfSign.SIGNATURE);


                                                axios
                                                    .post(`${process.env.REACT_APP_BACKEND_BASE_URL}/contract/save-image/`, data, {
                                                        headers: {
                                                            'Content-Type': 'multipart/form-data',
                                                        },
                                                    })
                                                    .then((res) => {
                                                        
                                                    });
                                            }}
                                        >
                                            {t('allModule.Submit')}
                                        </button>
                                    </div>
                                }
                            </>



                        </div>
                    </div>
                </>
            ) : (<>

                <div className='no_document_available_box'>
                    <p>{t('allModule.Document Url Not Available...')}</p>
                </div>

            </>)}

            <div className="set_btn2 mt-4">
                <button
                    onClick={(e) => {
                        e.preventDefault();
                        setActiveLink('MyDocuments')
                        setShowDropdown("")
                    }}
                    className="btn pb-1 pt-0.5 pl-6 pr-6 text_c font-bold rounded-lg btn_theme3">
                    {t('allModule.Previous')}
                </button>
                {docUrl && <button
                    onClick={(e) => {
                        // handleRightMenu('AutoMagic');
                    }}
                    className="pb-1 pt-2 pl-6 pr-6 text_c font-bold rounded-lg btn_theme3" style={{ paddingBottom: '0.50rem' }} >
                    {t('allModule.Submit e-signature')}
                </button>}

            </div>



        </div>
    );
};

export default AffixMySignature;
