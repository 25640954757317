import React, { useEffect, useRef, useState } from 'react';
import { QRCodeSVG } from 'qrcode.react';
import SignatureCanvas from 'react-signature-canvas';
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
import { useParams } from 'react-router-dom';
import { typeOfSign } from '../../constants/constants';
import LoadingScreen from "../loader/LoadingScreen";
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

const QRSignature = ({
  setIsSignModalOpen,
  setSrc,
  canvasType,
  setCompanyName,
  companyName,
}) => {

  // const Id = uuidv4();
  const { t } = useTranslation();
  const { recptId, qrcode_id } = useParams();

  const [formData, setFormData] = useState(null);
  const [padState, setPadState] = useState('draw');
  const signRef = useRef();
  const inputRef = useRef();
  const [showQr, setShowQr] = useState(false);
  const [penColor, setPenColor] = useState('black');
  const [image, setImage] = useState();
  const [complete, setComplete] = useState(false);
  const [loading, setLoading] = useState(false);

  if(loading){
    return <LoadingScreen/>
  }

  return (
    <div>
      <div>
        {
          complete ? (
            <div className="flex justify-center items-center h-96 flex-column ">
              <h3 className="text-2xl font-bold text_c">{t('form.Thank You, you can close this and continue on browser')}</h3>
            </div>
          )
            :
            <div
              style={{
                width: '500px',
                // height: '300px',
                margin: 'auto',
                padding: '10px',
                boxShadow: '0 5px 30px rgb(0 0 0 / 0.2)',
                borderRadius: '5px',
              }}
            >
              {/* {src && <img src={src} width={200} height={150} />} */}
              {canvasType !== 'seal' && (
                <div style={{ display: 'flex', marginBottom: '10px' }}>
                  <button
                    style={{
                      marginRight: 10,
                      border: 'none',
                      color: 'gray',
                      background: 'transparent',
                      fontWeight: 500,
                      fontSize: 16,
                      cursor: 'pointer',
                      padding: '5px',
                      borderBottom: padState == 'draw' ? '2px solid black' : 'none',
                    }}
                    onClick={() => {
                      setImage('');
                      setPadState('draw');
                    }}
                  >
                    {t('form.Draw')}
                  </button>
                  <button
                    style={{
                      border: 'none',
                      color: 'gray',
                      background: 'transparent',
                      fontWeight: 500,
                      fontSize: 16,
                      cursor: 'pointer',
                      padding: '5px',
                      borderBottom:
                        padState == 'upload' ? '2px solid black' : 'none',
                    }}
                    onClick={() => {
                      setImage('');
                      setPadState('upload');
                    }}
                  >
                    {t('form.Upload')}
                  </button>
                </div>
              )}
              {canvasType == 'stamp' ? (
                <div style={{ display: 'flex' }}>
                  <lable
                    variant='body1'
                    color='primary'
                    className='my-2 font-bold mr-2'
                  >
                    {t('form.Company Name')} :
                  </lable>
                </div>
              ) : canvasType == 'seal' ? (
                <div style={{ display: 'flex' }}>
                  <lable
                    variant='body1'
                    color='primary'
                    className='my-2 font-bold mr-2'
                  >
                    {t('form.Company Name')} :
                  </lable>
                </div>
              ) : null}
              {canvasType !== 'seal' && (
                <>
                  {padState === 'draw' ? (
                    <>
                      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <button
                          style={{
                            marginRight: '5px',
                            background: 'black',
                            border: 'none',
                            width: 20,
                            height: 20,
                            borderRadius: '50%',
                            border: '1px solid gray',
                            cursor: 'pointer',
                            color: 'white',
                            fontWeight: 'bolder',
                          }}
                          onClick={() => {
                            if (penColor !== 'black') {
                              signRef.current.clear();
                            }
                            setPenColor('black');
                          }}
                        >
                          {penColor === 'black' && <span>&#10003;</span>}
                        </button>
                        <button
                          style={{
                            background: 'blue',
                            border: 'none',
                            width: 20,
                            height: 20,
                            borderRadius: '50%',
                            border: '1px solid gray',
                            cursor: 'pointer',
                            color: 'white',
                            fontWeight: 'bolder',
                          }}
                          onClick={() => {
                            if (penColor !== 'blue') {
                              signRef.current.clear();
                            }
                            setPenColor('blue');
                          }}
                        >
                          {penColor === 'blue' && <span>&#10003;</span>}
                        </button>
                      </div>
                      <div style={{ border: '1px dashed #400835', marginTop: 5 }}>
                        <SignatureCanvas
                          ref={signRef}
                          penColor={penColor}
                          canvasProps={{
                            width: 500,
                            height: 200,
                          }}
                          onEnd={() => {
                            const url = signRef.current
                              .getTrimmedCanvas()
                              .toDataURL('image/png');
                            fetch(url)
                              .then((res) => res.blob())
                              .then((blob) => {
                                var fd = new FormData();
                                fd.append('image', blob, 'filename');
                                setFormData(blob);
                                let f = new FileReader();
                                f.readAsDataURL(blob);
                                f.onload = () => setImage(f.result);
                              });
                          }}
                        />
                      </div>
                      <button
                        style={{
                          height: 30,
                          alignSelf: 'flex-end',
                          border: 'none',
                          background: '#E0720C',
                          padding: '5px 10px',
                          borderRadius: '2px',
                          color: '#ffffff',
                          fontSize: '14px',
                          cursor: 'pointer',
                          fontWeight: 500,
                          marginTop: 5,
                        }}
                        onClick={() => {
                          signRef.current.clear();
                        }}
                      >
                        {t('form.Clear')}
                      </button>
                    </>
                  ) : (
                    <div
                      style={{
                        height: 200,
                        display: 'grid',
                        placeItems: 'center',
                        border: '1px dashed #400835',
                      }}
                    >
                      {image && <img src={image} width={200} height={150} />}
                      <button
                        style={{
                          background: '#400835',
                          padding: '8px 10px',
                          borderRadius: '5px',
                          color: '#ffffff',
                          fontSize: '14px',
                          cursor: 'pointer',
                          fontWeight: 500,
                          border: 'none',
                        }}
                        onClick={() => inputRef.current.click()}
                      >
                        {t('form.SELECT IMAGE')}
                      </button>
                      <input
                        ref={inputRef}
                        hidden
                        type='file'
                        accept='.png, .jpeg'
                        onChange={(e) => {
                          setFormData(e.target.files[0]);
                          let f = new FileReader();
                          f.readAsDataURL(e.target.files[0]);
                          f.onload = () => setImage(f.result);
                        }}
                      />
                    </div>
                  )}
                </>
              )}
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',

                  marginTop: '10px',
                }}
              >

                <button
                  style={{
                    height: 30,
                    alignSelf: 'flex-end',
                    border: 'none',
                    background: '#400835',
                    padding: '5px 15px',
                    borderRadius: '5px',
                    color: '#ffffff',
                    fontSize: '14px',
                    cursor: 'pointer',
                    fontWeight: 500,
                  }}
                  onClick={() => {
                    const data = new FormData();
                    data.append('image', formData);
                    data.append('document_recipients_id', recptId);
                    data.append('qrcode_id', qrcode_id); // add the id from url params
                    data.append(
                      'webhook_url',
                      `${process.env.REACT_APP_BACKEND_BASE_URL}/contract/webhook_signature_pad/`
                    );
                    data.append('is_mobile', "false");
                    data.append('type_of_sign', typeOfSign.SIGNATURE);

                    setLoading(true);
                    axios
                      .post(`${process.env.REACT_APP_BACKEND_BASE_URL}/contract/save-image/`, data, {
                        headers: {
                          'Content-Type': 'multipart/form-data',
                        },
                      })
                      .then((res) => {                        
                        setLoading(false);
                        setComplete(true);
                      }).catch((err) => {
                        setLoading(false);
                        toast.error(err.response.data.message);
                      });
                  }}


                >
                  {t('form.Submit')}
                </button>
              </div>
            </div>
        }
      </div>
    </div>
  )
}

export default QRSignature;
