import React, { useEffect, useState, useContext } from "react";
import dayjs from "dayjs";
import Search from "../../assets/icons/Search.svg";
import CloseIcon from "../../assets/icons/Close.svg";
import {
  mainUserListTableColumns02,
  corporateTransactionListTableColumns,
  PurchaseListTableColumns,
} from "../../constants/constants";
import useAxios from "../../hooks/useAxios";
import { resolve } from "../../api/resolve";
import AdminUserListTable from "./AdminUserListTable";
import CorporateTransactionListTable from "./CorporateTransactionListTable";

import AppContext from "../../context/AppContext";
import { parseISO, format } from "date-fns";
import PurchaseTransactionListTable from "./PurchaseTransactionListTable";
import AddButton from "../../shared/AddButton";
import { useTranslation } from "react-i18next";

const AdminUserList = ({ from = "directListing" }) => {
  const api = useAxios();
  // pending doc related states
  const [userList, setUserList] = useState([]);
  const [corporateTransactionList, setCorporateTransactionList] = useState([]);
  const [purchaseSearchText, setPurchaseSearchText] = useState("");
  const [userSearchText, setUserSearchText] = useState("");
  const [coprateSearchText, setCoprateSearchText] = useState("");
  const [userCurrentPage, setUserCurrentPage] = useState(1);
  const [userPageLimit, setUserPageLimit] = useState(5);
  const [userNextBtnLimit, setUserNextBtnLimit] = useState(0);
  const [corporateNextBtnLimit, setCorporateNextBtnLimit] = useState(0);
  const [userCount, setUserCount] = useState(0);
  const [corporateCount, setCorporateCount] = useState(0);
  const [errMsgUser, setErrMsgUser] = useState("");
  const [showUserClearIcon, setShowUserClearIcon] = useState(false);
  const [showCorporateClearIcon, setShowCorporateClearIcon] = useState(false);
  const [showPurchaseClearIcon, setShowPurchaseClearIcon] = useState(false);
  const [sortingOrder, setSortingOrder] = useState("desc");
  const [sortColumn, setSortColumn] = useState("id");
  const [corporatePageLimit, setCorporatePageLimit] = useState(5);
  const [corporateCurrentPage, setCorporateCurrentPage] = useState(1);
  const [corporatesorting, setCorporatesorting] = useState("desc");
  const [corporatesortColumn, setCorporatesortColumn] = useState("id");
  const [purchasePageLimit, setPurchasePageLimit] = useState(5);
  const [purchaseCurrentPage, setPurchaseCurrentPage] = useState(1);
  const [purchasesorting, setPurchasesorting] = useState("desc");
  const [purchasesortColumn, setPurchasesortColumn] = useState("id");
  const [purchaseCount, setPurchaseCount] = useState(0);
  const [purchaseNextBtnLimit, setPurchaseNextBtnLimit] = useState(0);
  const [purchaseTransactionList, setPurchaseTransactionList] = useState([]);
  const { adminId } = useContext(AppContext);
  const { t } = useTranslation();

  const getAdminUserList = async (clearText) => {
    const res = await resolve(
      api
        .get(
          `user/UserList/?AdminId=${adminId}&ordering=${sortColumn}&sortingOrder=${sortingOrder}&search=${
            clearText ? "" : userSearchText
          }&page=${userCurrentPage}&limit=${userPageLimit}`
        )
        .then((res) => res.data)
    );

    if (res.data) {
      const { data } = res;
      const { results, count } = data;
      const filterData = [];
      if (results.length > 0) {
        setUserCount(count);
        results.forEach((result) => {
          const userObj = {
            id: result.id,
            first_name: result.first_name,
            last_name: result.last_name ? result.last_name : "",
            email: result.email,
            phone: result.phone,
            is_active_user: result.is_active_user,
            date: format(
              parseISO(result.created_at),
              "ccc, dd MMM yyyy, HH:mm"
            ),
          };
          filterData.push(userObj);
        });
      } else {
        if (userCurrentPage === 1) setErrMsgUser("No user added yet");
        else setErrMsgUser("No more user to view");
      }
      setUserList([...filterData]);
      setUserNextBtnLimit(Math.ceil(count / userPageLimit));
    } else {
      setUserNextBtnLimit(0);
    }
  };
  const getcorporateTransactionList = async (clearText) => {
    const res = await resolve(
      api
        .get(
          `user/list/corprate/transaction?user_id=${adminId}&ordering=${sortColumn}&sortingOrder=${sortingOrder}&search=${
            clearText ? "" : userSearchText
          }&page=${userCurrentPage}&limit=${userPageLimit}`
        )
        .then((res) => res.data)
    );

    if (res.data) {
      const { data } = res;
      const { results, count } = data;
      const filterData = [];
      if (results.length > 0) {
        setCorporateCount(count);
        results.forEach((result) => {
          const corporateTransactionObj = {
            id: result.id,
            bank_name: result.bank_name,
            paid_amount: result.paid_amount ? result.paid_amount : "",
            mode_of_payment: result.mode_of_payment,
            cheque_no: result.cheque_no,
            date: result.date_of_payment?format(
              parseISO(result.date_of_payment),
              "ccc, dd MMM yyyy, HH:mm"
            ):'-',
          };
          filterData.push(corporateTransactionObj);
        });
      } else {
        if (userCurrentPage === 1) setErrMsgUser("No user added yet");
        else setErrMsgUser("No more user to view");
      }
      setCorporateTransactionList([...filterData]);
      setCorporateNextBtnLimit(Math.ceil(count / corporatePageLimit));
    } else {
      setCorporateNextBtnLimit(0);
    }
  };
  const getpurchaseTransactionList = async (clearText) => {
    // localStorage.setItem("adminId", adminId);

    const res = await resolve(
      api
        .get(
          `user/subscription/purchase/list/?subscriber_id=${adminId}&ordering=${sortColumn}&sortingOrder=${sortingOrder}&search=${
            clearText ? "" : userSearchText
          }&page=${userCurrentPage}&limit=${userPageLimit}`
        )
        .then((res) => res.data)
    );

    if (res.data) {
      const { data } = res;
      const { results, count } = data;
      const filterData = [];
      if (results.length > 0) {
        setPurchaseCount(count);
        results.forEach((result) => {
          const corporateTransactionObj = {
            id: result.id,
            plan_name: result.plan_id.name,
            original_plan_qty: result?.transaction_id?.original_plan_qty,
            plan_quantity: result?.transaction_id?.plan_quantity,
            plan_unit_price: result?.transaction_id?.plan_unit_price,
            total_paid_amount: result?.transaction_id?.total_paid_amount,
            date: dayjs(result.updated_at).format("DD MMMM YYYY"),
          };
          filterData.push(corporateTransactionObj);
        });
      } else {
        if (userCurrentPage === 1) setErrMsgUser("No user added yet");
        else setErrMsgUser("No more user to view");
      }
      setPurchaseTransactionList([...filterData]);
      setPurchaseNextBtnLimit(Math.ceil(count / corporatePageLimit));
    } else {
      setPurchaseNextBtnLimit(0);
    }
  };
  
  useEffect(() => {
    getAdminUserList();
  }, [sortColumn, sortingOrder, userCurrentPage, userPageLimit]);
  useEffect(() => {
    getcorporateTransactionList();
  }, [
    corporatesortColumn,
    corporatesorting,
    corporateCurrentPage,
    corporatePageLimit,
  ]);
  useEffect(() => {
    getpurchaseTransactionList();
  }, [
    purchasesortColumn,
    purchasesorting,
    purchaseCurrentPage,
    purchasePageLimit,
  ]);
  return (
    <>
      <div className="text-primary">
        <div className="main_head">
          {from === "directListing" && (
            <h1 className="text-4xl font-bold">List of Admin Users</h1>
          )}
        </div>

        <div className="tab_main">
          <div className={`tab_us show`}>
            <div className="row justify-between">
              <div className="col-md-5 flex_contact">
                <form>
                  <div className="flex mt-3  w-full">
                    <div className="flex justify-between items-center border-2 border-primary rounded-md rounded-tl-lg rounded-bl-lg bg-inputBackground px-2 mr-2 w-full ">
                      <input
                        placeholder="Search for user"
                        className="px-1 py-1 focus:outline-none bg-transparent w-11/12"
                        value={userSearchText}
                        onChange={(e) => {
                          setUserSearchText(e.target.value);
                          setShowUserClearIcon(true);
                          if (!e.target.value.trim()) {
                            setShowUserClearIcon(false);
                          }
                        }}
                      />
                      {showUserClearIcon && (
                        <img
                          src={CloseIcon}
                          width={14}
                          className="cursor-pointer"
                          onClick={() => {
                            setUserSearchText("");
                            getAdminUserList(true);
                            setShowUserClearIcon(false);
                          }}
                        />
                      )}
                    </div>
                    <button
                      type="submit"
                      className="flex btn btn_theme2 w_125 items-center justify-between"
                      onClick={(e) => {
                        e.preventDefault();
                        setUserCurrentPage(1);
                        getAdminUserList();
                      }}
                    >
                      <img src={Search} style={{ width: "1rem" }} />
                      <span className="font-semibold ml-1">Search</span>
                    </button>
                  </div>
                </form>
              </div>
              <div>
                {from === "DemoUserForm" && (
                  <h1 className="text-4xl font-bold">User list</h1>
                )}
              </div>
              <div>
                <div className="flex justify-end mt-3">
                  <AddButton
                    text={t("allModule.Add New User")}
                    currentForm="AdminUserFormBySA"
                    adminId={adminId}
                  />
                </div>
              </div>
              {/* <div className="col-md-7 flex justify-end">
                {from === "DemoUserForm" && (
                  <h1 className="text-4xl font-bold">User list</h1>
                )}
              </div> */}
            </div>
            <div className="mt-3 rounded-lg">
              {userList.length < 1 && (
                <p className="text-red-500 text-sm font-medium">{errMsgUser}</p>
              )}
            </div>
            <AdminUserListTable
              tableColumns={mainUserListTableColumns02}
              getListData={getAdminUserList}
              tableData={userList}
              setUserList={setUserList}
              // showActionBtn
              from={from}
              nextBtnLimit={userNextBtnLimit}
              currentPage={userCurrentPage}
              setCurrentPage={setUserCurrentPage}
              itemCount={userCount}
              setPageLimit={setUserPageLimit}
              showPageLimit={true}
              sortingOrder={sortingOrder}
              setSortingOrder={setSortingOrder}
              setSortColumn={setSortColumn}
            />
          </div>
        </div>
        <div className="tab_main">
          <div className={`tab_us show`}>
            <div className="row justify-between">
              <div className="col-md-5 flex_contact">
                <form>
                  <div className="flex mt-3  w-full">
                    <div className="flex justify-between items-center border-2 border-primary rounded-md rounded-tl-lg rounded-bl-lg bg-inputBackground px-2 mr-2 w-full ">
                      <input
                        placeholder="Search for user"
                        className="px-1 py-1 focus:outline-none bg-transparent w-11/12"
                        value={coprateSearchText}
                        onChange={(e) => {
                          setCoprateSearchText(e.target.value);
                          setShowCorporateClearIcon(true);
                          if (!e.target.value.trim()) {
                            setShowUserClearIcon(false);
                          }
                        }}
                      />
                      {showUserClearIcon && (
                        <img
                          src={CloseIcon}
                          width={14}
                          className="cursor-pointer"
                          onClick={() => {
                            setCoprateSearchText("");
                            getcorporateTransactionList(true);
                            setShowCorporateClearIcon(false);
                          }}
                        />
                      )}
                    </div>
                    <button
                      type="submit"
                      className="flex btn btn_theme2 w_125 items-center justify-between"
                      onClick={(e) => {
                        e.preventDefault();
                        setCorporateCurrentPage(1);
                        getcorporateTransactionList();
                      }}
                    >
                      <img src={Search} style={{ width: "1rem" }} />
                      <span className="font-semibold ml-1">Search</span>
                    </button>
                  </div>
                </form>
              </div>
              <div className="col-md-7 flex justify-end">
                {from === "DemoUserForm" && (
                  <h1 className="text-4xl font-bold">Bill List</h1>
                )}
              </div>
            </div>
            <div className="mt-3 rounded-lg">
              {userList.length < 1 && (
                <p className="text-red-500 text-sm font-medium">{errMsgUser}</p>
              )}
            </div>
            <CorporateTransactionListTable
              tableColumns={corporateTransactionListTableColumns}
              getListData={getcorporateTransactionList}
              tableData={corporateTransactionList}
              // showActionBtn
              from={from}
              nextBtnLimit={userNextBtnLimit}
              currentPage={corporateCurrentPage}
              setCurrentPage={setCorporateCurrentPage}
              itemCount={userCount}
              setPageLimit={setCorporatePageLimit}
              showPageLimit={true}
              sortingOrder={corporatesorting}
              setSortingOrder={setCorporatesorting}
              setSortColumn={setCorporatesortColumn}
            />
          </div>
        </div>
        <div className="main_head">
          {/* {from === "directListing" && ( */}
          <h1 className="text-4xl font-bold">Licence History </h1>
          {/* )} */}
        </div>
        <div className="tab_main">
          <div className={`tab_us show`}>
            <div className="row justify-between">
              <div className="col-md-5 flex_contact">
                <form>
                  <div className="flex mt-3  w-full">
                    <div className="flex justify-between items-center border-2 border-primary rounded-md rounded-tl-lg rounded-bl-lg bg-inputBackground px-2 mr-2 w-full ">
                      <input
                        placeholder="Search for user"
                        className="px-1 py-1 focus:outline-none bg-transparent w-11/12"
                        value={purchaseSearchText}
                        onChange={(e) => {
                          setPurchaseSearchText(e.target.value);
                          setShowPurchaseClearIcon(true);
                          if (!e.target.value.trim()) {
                            setShowUserClearIcon(false);
                          }
                        }}
                      />
                      {showUserClearIcon && (
                        <img
                          src={CloseIcon}
                          width={14}
                          className="cursor-pointer"
                          onClick={() => {
                            setPurchaseSearchText("");
                            getpurchaseTransactionList(true);
                            setShowPurchaseClearIcon(false);
                          }}
                        />
                      )}
                    </div>
                    <button
                      type="submit"
                      className="flex btn btn_theme2 w_125 items-center justify-between"
                      onClick={(e) => {
                        e.preventDefault();
                        setPurchaseCurrentPage(1);
                        getpurchaseTransactionList();
                      }}
                    >
                      <img src={Search} style={{ width: "1rem" }} />
                      <span className="font-semibold ml-1">Search</span>
                    </button>
                  </div>
                </form>
              </div>
              <div className="col-md-7 flex justify-end">
                {from === "DemoUserForm" && (
                  <h1 className="text-4xl font-bold">Bill List</h1>
                )}
              </div>
            </div>
            <div className="mt-3 rounded-lg">
              {userList.length < 1 && (
                <p className="text-red-500 text-sm font-medium">{errMsgUser}</p>
              )}
            </div>
            <PurchaseTransactionListTable
              tableColumns={PurchaseListTableColumns}
              getListData={getpurchaseTransactionList}
              tableData={purchaseTransactionList}
              // showActionBtn
              from={from}
              nextBtnLimit={userNextBtnLimit}
              currentPage={purchaseCurrentPage}
              setCurrentPage={setPurchaseCurrentPage}
              itemCount={userCount}
              setPageLimit={setPurchasePageLimit}
              showPageLimit={true}
              sortingOrder={purchasesorting}
              setSortingOrder={setPurchasesorting}
              setSortColumn={setPurchasesortColumn}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default AdminUserList;
