import React, { useState, useEffect, useRef, useContext } from "react";
import axios from "axios";
import { Worker, Viewer } from "@react-pdf-viewer/core";
import LoadingScreen from "../loader/LoadingScreen";
import { toast } from "react-toastify";
import { resolve } from "../../api/resolve";
import { useParams } from "react-router-dom";
import appLogo from "../../assets/fusion-logo.png";
import { useNavigate } from "react-router-dom";
import AppContext from "../../context/AppContext";
import jwt_decode from "jwt-decode";
import useAxios from "../../hooks/useAxios";
import { autoCloseTimer, delay } from "../../constants/constants";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

const FinalDocPreview = () => {
  const api = useAxios();
  const { t, i18n } = useTranslation();
  const { docId } = useParams();
  const { setActiveLink, language, setLanguage, langObj } =
    useContext(AppContext);
  const navigate = useNavigate();
  const baseURL = process.env.REACT_APP_BACKEND_BASE_URL;
  const [docUrl, setDocUrl] = useState("");
  const [loadingPreview, setLoadingPreview] = useState(false);
  const [sasToken, setSasToken] = useState("");
  const [isAadharSignee, setIsAadharSignee] = useState(false);
  const [selectedRecipient, setSelectedRecipient] = useState(0);
  const [recepientInfo, setRecipientInfo] = useState([]);
  const [docInfo, setDocInfo] = useState({});
  const [isValidateToken, setIsValidateToken] = useState(true);
  const decodedToken = jwt_decode(docId);
  const document_id = decodedToken.document_id;
  const axios = require("axios");
  const [downloadRes, setDownloadRes] = useState("");
  const location = useLocation();

  const getDocumentInfoApi = async () => {
    setLoadingPreview(true);
    const responceData = await resolve(
      axios
        .get(`${baseURL}/contract/final_doc_info/${docId}`)
        .then((res) => res.data)
    );

    if (responceData["data"]) {
      let doc_info = responceData["data"].doc_info;
      setDocInfo(doc_info);

      let recepient_info = responceData["data"].recepient_info;
      setSasToken(responceData["data"].sas_token);
      setRecipientInfo(recepient_info);
      setIsAadharSignee(doc_info.is_aadhar_signee);
      setSelectedRecipient(-1);
      setDocUrl(doc_info.converted_image_url);
    }

    if (
      responceData["error"]?.response?.data?.message &&
      responceData["error"]?.response?.data?.message === "Signature has expired"
    ) {
      setIsValidateToken(false);
    } else if (responceData["error"]) {
      toast.error(t("common.Something went wrong"));
    }

    setLoadingPreview(false);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await axios.get(
          `${baseURL}/contract/download/document/${document_id}?token=${
            location.pathname.split("/")[2]
          }`
        );
        setDownloadRes(res);
      } catch (error) {
        toast.error(error.message, {
          autoClose: autoCloseTimer,
          hideProgressBar: true,
        });
      }
    };
    fetchData();
    return () => {};
  }, [document_id]);

  const downloadDocument = async () => {
    if (downloadRes.data) {
      const apiUrl = `${baseURL}/contract/download/document/${document_id}?token=${
        location.pathname.split("/")[2]
      }`;
      window.open(apiUrl);
    }
    if (downloadRes.error) {
      if (downloadRes["error"]?.response?.data?.message) {
        toast.error(downloadRes["error"].response["data"].message, {
          autoClose: autoCloseTimer,
          hideProgressBar: true,
        });
      } else {
        toast.error(t("common.Something went wrong"), {
          autoClose: autoCloseTimer,
          hideProgressBar: true,
        });
      }
    }
  };

  useEffect(() => {
    getDocumentInfoApi();
  }, []);

  return (
    <div className="span_man">
      <div className="logo_on_final_page">
        <img src={appLogo} alt="app-logo" />
      </div>
      <div
        className="mb-3"
        style={{ position: "absolute", top: "10px", right: "15%" }}
      >
        <span className="text-base font-bold mr-2">
          {t("common.Language")} :
        </span>
        <select
          className="border-2 border-primary rounded-lg outline-none p-0.5 pl-2"
          onChange={(e) => {
            i18n.changeLanguage(e.target.value);
            setLanguage(e.target.value);
          }}
          value={language}
        >
          {Object.keys(langObj).map((lng) => (
            <option key={lng} value={lng}>
              {langObj[lng].nativeName}
            </option>
          ))}
        </select>
      </div>
      <div className="row_sig">
        <div
          className={`col-me-8 ${
            isAadharSignee ? `pdf_left_section` : `final_pdf_preview`
          }`}
        >
          <div className="border_us">
            {isValidateToken && (
              <div className="flex justify-end">
                <button
                  onClick={(e) => {
                    e.stopPropagation();
                    downloadDocument();
                  }}
                  className="btn btn_theme btn-sm mr-1 wh_spab"
                >
                  {t("form.Download document")}
                </button>
              </div>
            )}

            {loadingPreview ? (
              <LoadingScreen />
            ) : !isValidateToken ? (
              <>
                <div className="thank-you-for-signing">
                  <p>{t("form.Document preview link has been expired.")}</p>
                  <button
                    onClick={(e) => {
                      e.stopPropagation();
                      setActiveLink("Home");
                      navigate("/dashboard");
                    }}
                    className="btn btn_theme btn-sm mt-5"
                  >
                    {t("form.Back to Quoqo Fusion")}
                  </button>
                </div>
              </>
            ) : (
              docUrl && (
                <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.13.216/build/pdf.worker.min.js">
                  <Viewer
                    fileUrl={`${docUrl}?${sasToken}`}
                    renderError={() => (
                      <div className="grid place-items-center h-full w-full">
                        <h1 className="font-semibold">
                          {t(
                            "common.Contract indexing in progress. Check back later"
                          )}
                        </h1>
                      </div>
                    )}
                    renderLoader={() => <LoadingScreen />}
                  />
                </Worker>
              )
            )}
          </div>
        </div>
        {isAadharSignee && (
          <div className="col-me-4 mt-5 pdf_right_section">
            <div className="row">
              <h1 className="mb-5">
                {t("form.Select Recipient to view document Preview")}
              </h1>
              <div
                onClick={(e) => {
                  e.stopPropagation();
                  setSelectedRecipient(-1);
                  setDocUrl(docInfo.converted_image_url);
                }}
                className={`col-me-12 ${
                  selectedRecipient == -1
                    ? "final_recp_box_active"
                    : "final_recp_box"
                }`}
              >
                <div className="flex">
                  <h3> {t("form.Default")}</h3>
                </div>
              </div>

              {recepientInfo.map((recepient, index) => {
                return (
                  <div
                    key={index}
                    onClick={(e) => {
                      e.stopPropagation();
                      setSelectedRecipient(recepient.id);
                      if (
                        recepient.typeOfSignature ===
                        "Aadhaar_Digital_Signature"
                      ) {
                        setDocUrl(recepient.image_url);
                      } else {
                        setDocUrl(recepient.converted_image_url);
                      }
                    }}
                    className={`col-me-12 ${
                      selectedRecipient === recepient.id
                        ? "final_recp_box_active"
                        : "final_recp_box"
                    }`}
                  >
                    <div className="flex">
                      <h3> {recepient.user_name}</h3>
                    </div>
                    <div className="flex">
                      <h3> {recepient.title_name}</h3>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default FinalDocPreview;
