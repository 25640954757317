import React, { useEffect, useContext, useState } from "react";

import { useTranslation } from "react-i18next";
import DocumentContext from "../../context/DocumentContext";

const EditDocument365 = () => {
  
  const { t } = useTranslation();
  const { editDocumentIn365 } = useContext(DocumentContext);
  const [iframSrc, setIframeUrl] = useState(editDocumentIn365); 
  return (
    <div className="text-primary text-base font-normal font-sans mt_set">
      <div className="main_head">
        <h1 className="text-4xl font-bold text_c">
          {t("constant.Edit Document")}
        </h1>
      </div>

      <div className="w-full items-center generate_docuement">
        <div className="row">
          <div className="col-md-9">
            <div className="bottom_area">
              <iframe id="template-frame" src={iframSrc}></iframe>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditDocument365;
