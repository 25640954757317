import React, { useContext, useEffect, useState } from "react";
import Modal from "react-modal";
import "./modal.css";

import { toast } from "react-toastify";
import useAxios from "../../hooks/useAxios";
import { resolve } from "../../api/resolve";
import { validateEmail } from "../../utils/utils";
import { useTranslation } from 'react-i18next';

const customStyles = {
  content: {
    top: "30%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    maxWidth: "400px",
    maxHeight: "auto",
    borderRadius: "11px",
    minWidth: "300px",
  },
  overlay: {
    backgroundColor: "rgba(64, 8, 53, 0.63)",
  },
};

Modal.setAppElement("#root");
const AssignRoleModel = ({
  isOpen,
  setIsOpen,
  assignedToRoleList,
  corporateInfo,
  getDemoUserListReg,
}) => {
  const api = useAxios();
  const { t } = useTranslation();
  const [addFormData, setAddFormData] = useState({
    email: "",
    name: "",
    corporateUserId: "",
  });
  const [addFormError, setAddFormError] = useState({
    email: "",
    name: "",
    corporateUserId: "",
  });

  const handleChangeRole = async () => {
    const { email, name, corporateUserId } = addFormData;    
    for (const key in addFormError) {
      if (addFormError.hasOwnProperty(key)) {
        addFormError[key] = "";
      }
    }
    if (corporateUserId.trim().length < 1) {
      setAddFormError({
        ...addFormError,
        corporateUserId: t('form.User is required.'),
      });
    } else if (corporateUserId === "other" && name.trim().length < 1) {
      setAddFormError({
        ...addFormError,
        name: t('form.User name is required.'),
      });
    } else if (corporateUserId === "other" && email.trim().length < 1) {
      setAddFormError({
        ...addFormError,
        email: t('form.User email is required.'),
      });
    } else if (corporateUserId === "other" && !validateEmail(email)) {
      setAddFormError({
        ...addFormError,
        email: t('loginPage.Please enter a valid email!'),
      });
    } else {
      const postData = {
        UserId: corporateUserId,
        NewAdminEmail: email,
        NewAdminName: name,
        AdminId: corporateInfo.id,
      };
      const res = await resolve(
        api.post(`user/aadhar/addition/`, postData).then((res) => res.data)
      );

      if (res.data) {
        toast.success(t('form.Assigned Role successfully'));        
        getDemoUserListReg();
        
      }

      if (res.error) {
        toast.error(t('common.Something went wrong'));
      }

      setIsOpen(false);
      setAddFormData({
        ...addFormData,
        corporateUserId: "",
        email: "",
        name: "",
      });

      setAddFormError({
        ...addFormError,
        corporateUserId: "",
        email: "",
        name: "",
      });
    }
  };


  return (
    <div className="">
      <Modal
        isOpen={isOpen}
        onRequestClose={() => setIsOpen(true)}
        style={customStyles}
        onAfterClose={() => {}}
      >
        <div className=" modal-container">
          <h1 className="text-center text-primary font-bold text-lg">
            {t('form.Assigned Role')}
          </h1>

          <div>
            <label className="flex flex-start">{t('form.Assigned to User')}</label>
            <select
              value={addFormData.corporateUserId}
              onChange={(e) => {
                setAddFormData({
                  ...addFormData,
                  corporateUserId: e.target.value,
                });
              }}
              className="border-2 border-primary rounded-lg outline-none p-1 pl-2 w-full"
            >
              <option value="">{t('form.Select')}</option>
              {assignedToRoleList.map((item, index) => {
                return (
                  <option key={index} value={item.id}>
                    {item.first_name}
                  </option>
                );
              })}
              <option value="other">{t('form.Other')}</option>
            </select>
            <p className="flex flex-start error_msg">
              {addFormError.corporateUserId}
            </p>
          </div>
          {addFormData.corporateUserId === "other" && (
            <>
              <div className="mt-5">
                <div className="flex">
                  <label className="flex flex-start mb-1">{t('form.Name')}:</label>
                </div>
                <input
                  type="text"
                  value={addFormData.name}
                  className="border-2 border-primary rounded-lg outline-none p-1 pl-2 w-full "
                  onChange={(e) => {
                    setAddFormData({
                      ...addFormData,
                      name: e.target.value,
                    });
                  }}
                />
                <p className="flex flex-start error_msg">
                  {addFormError.name}
                </p>
              </div>

              <div className="mt-5">
                <div className="flex">
                  <label className="flex flex-start mb-1">{t('form.Email')}:</label>
                </div>
                <input
                  type="text"
                  value={addFormData.email}
                  className="border-2 border-primary rounded-lg outline-none p-1 pl-2 w-full "
                  onChange={(e) => {
                    setAddFormData({
                      ...addFormData,
                      email: e.target.value,
                    });
                  }}
                />
                <p className="flex flex-start error_msg">
                  {addFormError.email}
                </p>
              </div>
            </>
          )}

          <div className="flex justify-center mt-5">
            <button
              onClick={(e) => {
                e.preventDefault();
                handleChangeRole();
              }}
              className="bg-primary hover:bg-secondary font-semibold text-white p-1 pl-2 pr-2 rounded-lg mr-5"
            >
              {t('common.Save')}
            </button>

            <button
              onClick={(e) => {
                e.preventDefault();
                setIsOpen(false);
              }}
              className="bg-[#606060] font-semibold text-white p-1 pl-2 pr-2 rounded-lg"
            >
              {t('common.Cancel')}
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default AssignRoleModel;
