import React, { useEffect, useContext, useState, useRef } from "react";
import { Editor } from "@tinymce/tinymce-react";
import { resolve } from "../../api/resolve";
import useAxios from "../../hooks/useAxios";
import LoadingScreen from "../loader/LoadingScreen";
import AppContext from "../../context/AppContext";
import { toast } from "react-toastify";
import PermissionContext from "../../context/PermissionContext";
import ClauseAI from "./clauseai";
import DocChatAI from "./docChatAI";
import ClauseLibraryChat from "./ClauseLibraryChat";
import { useTranslation } from "react-i18next";
import LegalConfirmModal from "../modals/LegalConfirmModal";
import ReactTooltip from "react-tooltip";
import dayjs from "dayjs";
import DeleteIcon from "../../assets/icons/Delete.svg";
import DeleteModal from "../modals/DeleteModal";
import aiOrange from "../../assets/icons/ai_orange.svg";
import aiPurple from "../../assets/icons/ai_purple.svg";

import QChat from "./QChat";

const API_BASE_URL = process.env.REACT_APP_BACKEND_BASE_URL;

// Utility function to get CSRF token
// function getCsrfToken() {
//   const name = "csrftoken";
//   const cookieValue = document.cookie
//     .split("; ")
//     .find((row) => row.startsWith(name))
//     .split("=")[1];
//   return cookieValue;
// }

function getCsrfToken() {
  const name = "csrftoken";
  const cookieString = document.cookie || ""; // Ensure document.cookie is not undefined
  const cookies = cookieString.split("; ");
  const csrfToken = cookies.find((row) => row.startsWith(name));

  if (!csrfToken) {
    console.error(
      "CSRF token not found. Ensure it is set correctly in your cookies."
    );
    return "";
  }

  return csrfToken.split("=")[1];
}

// Function to Launch Chat with Selected Text
function launchChatWithText(selectedText) {
  fetch(`${API_BASE_URL}/contract/start-chat/`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "X-CSRFToken": getCsrfToken(),
    },
    body: JSON.stringify({
      searchText: selectedText,
      aiContent: "",
      conversationHistory: [],
    }),
  })
    .then((response) => {
      if (!response.ok) {
        return response.text().then((text) => {
          throw new Error(text);
        });
      }
      return response.json();
    })
    .then((data) => {
      alert(JSON.stringify(data, null, 2));
    })
    .catch((error) => {
      console.error("Error:", error);
      alert(`There was an error: ${error.message}`);
    });
}


const AZURE_OPENAI_API_KEY = "4581c30403f847b7add57bbd9d324aba";
const AZURE_OPENAI_ENDPOINT =
  "https://q-chatgpt.openai.azure.com/openai/deployments/gpt-4o/chat/completions?api-version=2024-05-01-preview";


const ai_request = (request, respondWith) => {
  const azureOpenAiOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "api-key": AZURE_OPENAI_API_KEY,
    },
    body: JSON.stringify({
      temperature: 0.7,
      max_tokens: 800,
      messages: [{ role: "user", content: request.prompt }],
    }),
  };
  respondWith.string((signal) =>
    window
      .fetch(AZURE_OPENAI_ENDPOINT, { signal, ...azureOpenAiOptions })
      .then(async (response) => {
        if (response) {
          const data = await response.json();
          if (data.error) {
            throw new Error(`${data.error.type}: ${data.error.message}`);
          } else if (response.ok) {
            return data?.choices[0]?.message?.content?.trim();
          }
        } else {
          throw new Error("Failed to communicate with the Azure OpenAI API");
        }
      })
  );
};




const GenerateDocument = () => {
  const [loading, setLoading] = useState(false);
  const [templateName, setTemplateName] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const editorRef = useRef(null); // <-- Define the editorRef here
  const [documentData, setDocumentData] = useState(() => {
    return localStorage.getItem("generateDocumentContent") || "";
  });
  const [search, setSearch] = useState("");
  const [searchDraft, setSearchDraft] = useState("");
  const [aiContent, setAiContent] = useState("");
  const [apicalled, setApicalled] = useState(false);
  const [apiResponse, setApiResponse] = useState("");
  const { t } = useTranslation();
  const [docchat, setDocchat] = useState(true);
  const [templateList, setTemplateList] = useState([]);
  const [deleteTemplateId, setDeleteTemplateId] = useState(null);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const textareaRef = useRef(null);

  const {
    activeLink,
    setActiveLink,
    setPrevActiveLink,
    setShowDropdown,
    setTempSaveConfirmation,
    templateConfirmationOpen,
    setTemplateConfirmationOpen,
  } = useContext(AppContext);

  const {
    permissionList,
    getComapnyInfoApi,
    isValidCompany,
    updatePermissionList,
  } = useContext(PermissionContext);

  const baseURL = process.env.REACT_APP_BACKEND_BASE_URL;
  const api = useAxios();

  const handleSearch = async () => {
    setLoading(true);

    // Check if the prompt contains any contract-related keywords
    if (!checkIfContract(search)) {
      setLoading(false);
      return;
    }

    const responceData = await resolve(
      api
        .post(`/contract/generate/`, { prompt: search })
        .then((res) => res.data)
    );

    if (responceData.data) {
      const textData = responceData.data;
      const paragraphs = textData.split("\n\n");
      const sanitizedText = paragraphs
        .map((paragraph) => `<p>${paragraph.replace(/\n/g, "<br>")}</p>`)
        .join("");

      setDocumentData(sanitizedText); // Update the TinyMCE editor content here
      setApiResponse(sanitizedText);
      setApicalled(false);
      setApicalled(true);
    }

    setLoading(false);
  };

  const checkIfContract = (prompt) => {
    const keywords = [
      "Accord",
      "Accordance",
      "Agreement",
      "Arrangement",
      "Articles of Agreement",
      "Bargain",
      "Bond",
      "Charter",
      "Compact",
      "Commitment",
      "Concord",
      "Consensus",
      "Contract",
      "Convention",
      "Covenant",
      "Deal",
      "Deed",
      "Engagement",
      "Instrument",
      "Lease",
      "License",
      "Memorandum",
      "Memorandum of Understanding (MOU)",
      "MOU",
      "NDA (Non-Disclosure Agreement)",
      "NDA",
      "Obligation",
      "Pact",
      "Protocol",
      "Resolution",
      "Settlement",
      "Stipulation",
      "Terms",
      "Terms and conditions",
      "Terms of Agreement",
      "Terms of Service",
      "Treaty",
      "Understanding",
    ];
    const hasContractKeyword = keywords.some((keyword) =>
      prompt.toLowerCase().includes(keyword.toLowerCase())
    );

    if (hasContractKeyword) {
      return true;
    } else {
      toast.error(
        t(
          "allModule.The Document Assistant can only be used to draft contracts or agreements."
        )
      );
      return false;
    }
  };

  const handleTemplateClick = async (item) => {
    setDocumentData("");
    setLoading(true);
    const res = await resolve(
      api
        .get(`${baseURL}/contract/template/list/view/${item.id}`)
        .then((res) => {
          setApiResponse(res["data"] + "");
          setApicalled(false);
          setApicalled(true);

          if (res["data"]) {
            // setDocumentDataDefault(responceData["data"] + "");
            setDocumentData(res["data"] + "");
            setLoading(false);
          }

          if (res["error"]) {
            toast.error(t("common.Something went wrong"));
            // setLoading(false);
          }
          return res.data;
        })
    );
  };

  const getTemplateListApi = async () => {
    const type = "draft";
    const responceData = await resolve(
      api
        .get(`${baseURL}/contract/template/list/?type=${type}`)
        .then((res) => res.data)
    );
    if (responceData["data"]) {
      setTemplateList(responceData["data"].data);
    }
  };

  const getTemplateSearchListApi = async (search) => {
    const type = "draft";
    const payload = {
      search: search,
      type: type,
    };

    const responceData = await resolve(
      api
        .get(`${baseURL}/contract/template/list/search/`, { params: payload })
        .then((res) => res.data)
    );
    if (responceData["data"] && responceData["data"].results) {
      setTemplateList(responceData["data"].results);
    }
  };

  const deleteTemplate = async (item) => {
    const responceData = await resolve(
      api
        .get(`${baseURL}/contract/template/list/delete/${deleteTemplateId}`)
        .then((res) => res.data)
    );
    if (responceData["data"]) {
      toast.success(t("allModule.Template deleted successfully"));
      getTemplateListApi();
      setIsDeleteModalOpen(false);
      // getDefaultTemplateListApi();
    }

    if (responceData["error"]) {
      toast.error(t("allModule.Delete template failed"));
    }
    setIsDeleteModalOpen(false);
  };

  const handleClearBtn = () => {
    setSearch("");
    setDocumentData("");
    setApiResponse("");
    setTemplateName("");
  };

  const handleSaveTemplate = async (type) => {
    const content = documentData;

    setErrorMsg("");
    if (templateName === "") {
      setErrorMsg(t("Please provide template name"));
    } else if (content === "") {
      setErrorMsg(t("Please provide template content"));
    } else {
      setErrorMsg("");
      setLoading(true);
      const postData = {
        templateName: templateName,
        content: content,
        createTemplate: true,
        createDocuement: false,
        type: type,
        fileType: "docx",
        remarks: "Created from Generate Document",
      };
      const res = await resolve(
        api
          .post(`${baseURL}/contract/template/converter/`, postData)
          .then((res) => {
            setLoading(false);
            if (res["data"]) {
              setApiResponse("");
              const msg =
                type === "template"
                  ? "Template"
                  : "Draft" + " saved successfully";
              toast.success(t(msg));
              setApicalled(false);
              setApicalled(true);
              setTemplateName("");
              setDocumentData("");
              localStorage.removeItem("createTemplateContent");
              setTempSaveConfirmation(false);
              getTemplateListApi();
              updatePermissionList(res["data"].User_permission[0]);
            }
            return res.data;
          })
          .catch((error) => {
            setLoading(false);
            setApicalled(false);
            setApicalled(true);
            if (
              error.response &&
              error.response.data &&
              error.response.data.message
            ) {
              setApiResponse(content);
              toast.error(error.response.data.message); // Displaying the custom error message
            }
          })
      );
    }
  };

  const handleSaveDocument = async (fileType) => {
    const content = documentData;
    setErrorMsg("");
    if (templateName === "") {
      setErrorMsg(t("allModule.Please provide document name"));
    } else if (content === "<p><br></p>") {
      setErrorMsg(t("allModule.Please provide document content"));
    } else {
      setLoading(true);

      const postData = {
        templateName: templateName,
        content: content,
        createTemplate: false,
        createDocuement: true,
        type: "ai",
        fileType: fileType,
      };
      const responceData = await resolve(
        api
          .post(`${baseURL}/contract/template/converter/`, postData)
          .then((res) => res.data)
      );

      setLoading(false);
      if (responceData["data"]) {
        setDocumentData("");
        localStorage.removeItem("generateDocumentContent");
        toast.success(t("allModule.Document created successfully"));
        setApiResponse("");
        setApicalled(false);
        setApicalled(true);
        setErrorMsg("");
        setTemplateName("");
        setTempSaveConfirmation(false);
        setActiveLink("MyDocuments");
        updatePermissionList(responceData["data"].User_permission[0]);
      }

      if (responceData["error"]) {
        setApiResponse(content);
        setLoading(false);
        setApicalled(false);
        setApicalled(true);
        toast.error(t("allModule.Create document failed"));
      }
    }
  };

  useEffect(() => {
    getComapnyInfoApi();
    getTemplateListApi();
    setTempSaveConfirmation(true);
  }, []);

  return (
    <>
      <div className="text-primary text-base font-normal font-sans mt_set">
        <div className="main_head">
          <h1 className="text-4xl font-bold text_c ">
            {t("menu.Generate Document")}
          </h1>
        </div>
        {!isValidCompany ? (
          <div className="flex justify-center items-center h-96 flex-column ">
            <h3 className="text-2xl font-bold text_c">
              {t("allModule.Please update your company info.")}
            </h3>
          </div>
        ) : permissionList["ai_template_on"] == 1 ? (
          <>
            {permissionList.ai_template_allotted_cnt > 0 &&
            permissionList.ai_template_remaing_cnt === 0 ? (
              <div className="flex justify-center items-center h-96 flex-column ">
                <h3 className="text-2xl font-bold text_c">
                  {t("allModule.You have no more limit to generate document.")}
                </h3>
              </div>
            ) : (
              <div className="w-full items-center generate_docuement">
                <div className=" pl-0 row ">
                  <div className="pl-0 col-md-9 seachbox">
                    <div className="col-md-7">
                      <textarea
                        value={search}
                        onKeyUp={(e) => {
                          if (e.key === "Enter") {
                            handleSearch();
                          }
                        }}
                        onChange={(e) => setSearch(e.target.value)}
                        className="outline-none p-1  border-2 border-primary rounded-lg-1"
                        placeholder={t(
                          "allModule.Type the name of contract to create with description"
                        )}
                        style={{
                          minHeight: "3.3em",
                          resize: "none",
                          width: "100%",
                          height: "100%",
                        }}
                      />
                    </div>
                    <div className="col-md-5 seachbox2">
                      <button
                        onClick={handleSearch}
                        className="btn pb-1 pt-0.5 pl-6 pr-6 text_c font-bold rounded-lg btn_theme3"
                        style={{ height: "fit-content", alignSelf: "center" }}
                      >
                        {t("menu.Generate Document")}
                      </button>
                      <button
                        onClick={(e) => {
                          e.preventDefault();
                          setDocchat(!docchat);
                        }}
                        className="btn pb-1 pt-0.5 pl-6 pr-6 text_c font-bold rounded-lg btn_theme3"
                        style={{ height: "fit-content", alignSelf: "center" }}
                        data-tip="AI Assistant and Document Chat"
                      >
                        <img
                          src={docchat ? aiPurple : aiOrange}
                          alt="AI"
                          className="tab-icon"
                          style={{ width: "40px", height: "24px" }}
                        />
                      </button>
                      {/* 
                      <div style={{ alignSelf: "center" }}>
                        <ClauseAI
                          aiContent={aiContent}
                          updateContent={() => setAiContent(documentData)}
                          className="btn pb-1 pt-0.5 pl-6 pr-6 text_c font-bold rounded-lg btn_theme3"
                        />
                      </div>
                      <div style={{ alignSelf: "center" }}>
                        <DocChatAI
                          aiContent={aiContent}
                          updateContent={() => setAiContent(documentData)}
                          className="btn pb-1 pt-0.5 pl-6 pr-6 text_c font-bold rounded-lg btn_theme3"
                        />
                      </div> */}
                      {/* <div style={{ alignSelf: "center" }}>
                        <ClauseLibraryChat
                          aiContent={aiContent}
                          updateContent={() => setAiContent(documentData)}
                          className="btn pb-1 pt-0.5 pl-6 pr-6 text_c font-bold rounded-lg btn_theme3"
                        />
                      </div> */}
                    </div>
                  </div>
                  <p className="pl-2">
                    (
                    {t(
                      "allModule.Disclaimer Please have the content of the document verified by a legal professional at all times"
                    )}
                    )
                  </p>
                </div>

                <div className="row bottom_area">
                  <div className="col-md-9">
                    {loading ? (
                      <LoadingScreen />
                    ) : (
                      <>
                        <div
                          className="bottom_area"
                          style={{
                            flex: "3",
                            flexDirection: "row",
                            width: "100%",
                            // height: "70vh",
                          }}
                        >
                          <Editor
                            tinymceScriptSrc={`${process.env.PUBLIC_URL}/tinymce/tinymce.min.js`}
                            //tinymceScriptSrc="https://cdn.tiny.cloud/1/zi2ytxohu4e3dhkgcqh3z4hlmorokuwbj5a31lz5s5xsj9ge/tinymce/6/tinymce.min.js"
                            value={documentData}
                            onInit={(evt, editor) =>
                              (editorRef.current = editor)
                            }
                            init={{
                              selector: "textarea#editor", // your selector for the TinyMCE editor
                              license_key: "gpl", // Add this line to agree to the GPL license terms
                              height: 500,
                              menubar: false,
                              branding: false,
                              statusbar: false,
                              browser_spellcheck: true,
                              promotion: false,
                              plugins: [
                                // Core editing and premium features
                                "anchor",
                                "autolink",
                                "charmap",
                                "codesample",
                                "emoticons",
                                "image",
                                "link",
                                "lists",
                                "media",
                                "searchreplace",
                                "table",
                                "visualblocks",
                                "wordcount",
                                "checklist",
                                "mediaembed",
                                "casechange",
                                "export",
                                "formatpainter",
                                "pageembed",
                                "a11ychecker",
                                "tinymcespellchecker",
                                "permanentpen",
                                "powerpaste",
                                "advtable",
                                "advcode",
                                "editimage",
                                "advtemplate",
                                "ai",
                                "mentions",
                                "tinycomments",
                                "tableofcontents",
                                "footnotes",
                                "mergetags",
                                "autocorrect",
                                "typography",
                                "inlinecss",
                                "markdown",
                                // Additional plugins
                                "advlist",
                                "insertdatetime",
                                "autosave",
                                "code",
                                "directionality",
                                "fullscreen",
                                "paste",
                                "preview",
                                "tinydrive",
                                "quickbars",
                                "spellchecker",
                                "save",
                              ],
                              toolbar:
                                "undo redo | aidialog aishortcuts | blocks fontsizeinput | bold italic underline strikethrough superscript subscript | forecolor backcolor formatpainter removeformat | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link image media table pageembed | lineheight outdent indent | charmap emoticons checklist | code fullscreen preview | save print | pagebreak anchor codesample footnotes mergetags | addtemplate inserttemplate | addcomment showcomments | ltr rtl casechange | spellcheckdialog a11ycheck | chatButton",
                              content_style:
                                "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
                              font_formats:
                                "Arial=arial,helvetica,sans-serif; Courier New=courier new,courier,monospace; Georgia=georgia,palatino,serif; Tahoma=tahoma,arial,helvetica,sans-serif; Times New Roman=times new roman,times,serif; Verdana=verdana,geneva,sans-serif",
                              fontsize_formats:
                                "8pt 10pt 12pt 14pt 18pt 24pt 36pt",
                              tinycomments_mode: "embedded",
                              tinycomments_author: "Author name",
                              mergetags_list: [
                                { value: "First.Name", title: "First Name" },
                                { value: "Email", title: "Email" },
                              ],
                              ai_request: (request, respondWith) =>
                                respondWith.string(() =>
                                  Promise.reject(
                                    "See docs to implement AI Assistant"
                                  )
                                ),
                              image_advtab: true,
                              typography_rules: [
                                "common/punctuation/quote",
                                "en-US/dash/main",
                                "common/nbsp/afterParagraphMark",
                                "common/nbsp/afterSectionMark",
                                "common/nbsp/afterShortWord",
                                "common/nbsp/beforeShortLastNumber",
                                "common/nbsp/beforeShortLastWord",
                                "common/nbsp/dpi",
                                "common/punctuation/apostrophe",
                                "common/space/delBeforePunctuation",
                                "common/space/afterComma",
                                "common/space/afterColon",
                                "common/space/afterExclamationMark",
                                "common/space/afterQuestionMark",
                                "common/space/afterSemicolon",
                                "common/space/beforeBracket",
                                "common/space/bracket",
                                "common/space/delBeforeDot",
                                "common/space/squareBracket",
                                "common/number/mathSigns",
                                "common/number/times",
                                "common/number/fraction",
                                "common/symbols/arrow",
                                "common/symbols/cf",
                                "common/symbols/copy",
                                "common/punctuation/delDoublePunctuation",
                                "common/punctuation/hellip",
                              ],
                              typography_ignore: ["code"],
                              toolbar_mode: "wrap",
                              contextmenu: "link image imagetools table", // Add table to the context menu
                              table_advtab: true, // Enable the "Advanced" tab in the table properties dialog
                              table_appearance_options: true, // Enable appearance options like width, height, etc.
                              table_default_attributes: {
                                border: "1", // Set default border for tables
                              },
                              table_default_styles: {
                                width: "100%", // Set default width for tables
                              },
                              typography_ignore: ["code"],

                              // setup: function (editor) {
                              //   // Add the chat button to the toolbar
                              //   editor.ui.registry.addButton("chatButton", {
                              //     text: "Chat",
                              //     onAction: function () {
                              //       const selectedText =
                              //         editor.selection.getContent({
                              //           format: "text",
                              //         });
                              //       if (selectedText) {
                              //         launchChatWithText(selectedText); // Call the function with the selected text
                              //       } else {
                              //         alert(
                              //           "Please select text to start a chat."
                              //         );
                              //       }
                              //     },
                              //   });

                              //   // Add Ctrl+/ keyboard shortcut for the chat functionality
                              //   editor.addShortcut(
                              //     "ctrl+/",
                              //     "Chat with selected text",
                              //     function () {
                              //       const selectedText =
                              //         editor.selection.getContent({
                              //           format: "text",
                              //         });
                              //       if (selectedText) {
                              //         launchChatWithText(selectedText); // Call the function with the selected text
                              //       } else {
                              //         alert(
                              //           "Please select text to start a chat."
                              //         );
                              //       }
                              //     }
                              //   );

                              //   // Manually handling Ctrl + /
                              //   editor.on("keydown", function (e) {
                              //     if (e.ctrlKey && e.key === "/") {
                              //       e.preventDefault(); // Prevent the default action
                              //       const selectedText =
                              //         editor.selection.getContent({
                              //           format: "text",
                              //         });
                              //       if (selectedText) {
                              //         launchChatWithText(selectedText); // Call the function with the selected text
                              //       } else {
                              //         alert(
                              //           "Please select text to start a chat."
                              //         );
                              //       }
                              //     }
                              //   });
                              // },

                              toolbar_mode: "wrap",
                              contextmenu: "link image imagetools table", // Add table to the context menu
                              table_advtab: true, // Enable the "Advanced" tab in the table properties dialog
                              table_appearance_options: true, // Enable appearance options like width, height, etc.
                              table_default_attributes: {
                                border: "1", // Set default border for tables
                              },
                              table_default_styles: {
                                width: "100%", // Set default width for tables
                              },
                            }}
                            onEditorChange={(content) => {
                              setDocumentData(content);
                            }}
                          />
                        </div>

                        <div className="flex justify-end">
                          <p className="error_msg">{errorMsg}</p>
                        </div>

                        <div className="mt-3 mb-3 flex justify-end set_mb1">
                          <input
                            type="text"
                            value={templateName}
                            onChange={(e) => setTemplateName(e.target.value)}
                            className="border-2 border-primary rounded-lg outline-none  pl-2 mt-3"
                            placeholder={t("allModule.Enter Document Name")}
                          />
                          <button
                            onClick={(e) => {
                              handleSaveDocument("docx");
                            }}
                            data-tip={t("constant.Save as docx for Editing")}
                            className="btn pb-1 pt-0.5 pl-6 pr-6 text_c font-bold rounded-lg btn_theme3 ml-5 mt-3"
                          >
                            {t("constant.Save as docx")}
                          </button>
                          <button
                            onClick={(e) => {
                              handleSaveDocument("pdf");
                            }}
                            data-tip={t("constant.Save as pdf for Signature")}
                            className="btn pb-1 pt-0.5 pl-6 pr-6 text_c font-bold rounded-lg btn_theme3 ml-5 mt-3"
                          >
                            {t("constant.Save as pdf")}
                          </button>
                          <button
                            onClick={(e) => {
                              handleSaveTemplate("template");
                            }}
                            data-tip={t("allModule.Save as Template")}
                            className="btn pb-1 pt-0.5 pl-6 pr-6 text_c font-bold rounded-lg btn_theme3 ml-5 mt-3"
                          >
                            {t("allModule.Save as Template")}
                          </button>
                          <button
                            onClick={(e) => {
                              handleSaveTemplate("draft");
                            }}
                            data-tip={t("constant.Save as Draft")}
                            className="btn pb-1 pt-0.5 pl-6 pr-6 text_c font-bold rounded-lg btn_theme3 ml-5 mt-3"
                          >
                            {t("constant.Save as Draft")}
                          </button>
                          <ReactTooltip
                            backgroundColor="#400835"
                            textColor="#ffffff"
                          />
                        </div>
                      </>
                    )}
                  </div>
                  <div className="col-md-3">
                    <div
                      style={{
                        flex: "3",
                        display: "fixed",
                        height: "100%",
                        // padding: ,
                      }}
                    >
                      {!loading &&
                        (docchat ? (
                          <div className="default-content">
                            <div>
                              <h4 className="text-xl font-bold mb-2 text_c ">
                                {t("Select Draft")}
                              </h4>
                              <div className="seachbox mb-2">
                                <input
                                  type="text"
                                  className="border-2 border-primary rounded-lg outline-none p-1 pl-2 w-2/3"
                                  placeholder={t("Search a Draft")}
                                  onChange={(e) => {
                                    setSearchDraft(e.target.value);
                                  }}
                                  onKeyUp={(e) => {
                                    if (
                                      e.key === "Enter" ||
                                      e.target.value === ""
                                    ) {
                                      getTemplateSearchListApi(searchDraft);
                                    }
                                  }}
                                  value={searchDraft}
                                />
                                <button
                                  className="btn pb-1 pt-0.5 pl-6 pr-6 text_c font-bold rounded-lg btn_theme3"
                                  onClick={(e) =>
                                    getTemplateSearchListApi(searchDraft)
                                  }
                                >
                                  {t("allModule.Search")}
                                </button>
                              </div>
                              <div className="template_list_container pad_0">
                                {templateList.length > 0 &&
                                  templateList.map((item, index) => {
                                    const titleWithoutExtension =
                                      item.title.replace(
                                        /\.(docx|pdf|doc)$/,
                                        ""
                                      );
                                    return (
                                      <>
                                        <ReactTooltip
                                          backgroundColor="#400835"
                                          textColor="#ffffff"
                                        />
                                        <div
                                          key={index}
                                          onClick={(e) =>
                                            handleTemplateClick(item)
                                          }
                                          className="mr-1 mb-1 template_box"
                                        >
                                          <div className="template_box_head">
                                            <h1 className="text-2xl font-bold text_c">
                                              {item.title}
                                            </h1>
                                          </div>
                                          <div className="template_box_body">
                                            <strong>
                                              {t("allModule.Created on")}
                                              {": "}
                                            </strong>
                                            <span className="text-base font-normal text_c">
                                              {dayjs(item.created_at).format(
                                                "dddd, MMM D, YYYY HH:mm"
                                              )}
                                            </span>
                                          </div>
                                          <button
                                            className="btn btn_theme btn-sm mr-1 mt-8 wh_spab"
                                            onClick={(e) => {
                                              e.stopPropagation();
                                              setDeleteTemplateId(item.id);
                                              setIsDeleteModalOpen(true);
                                            }}
                                            data-tip={t(
                                              "constant.Delete document"
                                            )}
                                          >
                                            <img
                                              src={DeleteIcon}
                                              className="ml-0.5"
                                              width={20}
                                              alt="Delete Document"
                                            />
                                          </button>
                                        </div>
                                      </>
                                    );
                                  })}

                                {templateList.length === 0 && (
                                  <div className="flex justify-center items-center h-96 flex-column w-full">
                                    <h3 className="text-2xl font-bold text_c">
                                      {t("allModule.No Template Found")}
                                    </h3>
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        ) : (
                          <QChat
                            documentData={documentData}
                            className="chat-window"
                          />
                        ))}
                    </div>
                  </div>
                </div>
              </div>
            )}
          </>
        ) : (
          <div className="flex justify-center items-center h-96 flex-column ">
            <h3 className="text-2xl font-bold text_c">
              You don't have permission to access this page
            </h3>
          </div>
        )}
      </div>
      <DeleteModal
        title="Delete Template"
        bodyText="Are you sure you want to delete template?"
        isDeleteModalOpen={isDeleteModalOpen}
        setIsDeleteModalOpen={setIsDeleteModalOpen}
        deleteTemplate={deleteTemplate}
      />
      <LegalConfirmModal
        title={t("allModule.Confirmation")}
        bodyText={t(
          "allModule.Please save your changes before moving out of this page!"
        )}
        isConfirmModalOpen={templateConfirmationOpen}
        setIsConfirmModalOpen={setTemplateConfirmationOpen}
        setTempSaveConfirmation={setTempSaveConfirmation}
        activeLink={activeLink}
        setPrevActiveLink={setPrevActiveLink}
      />
    </>
  );
};

export default GenerateDocument;
