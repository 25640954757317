import React, { useState } from "react";
import { useTable } from "react-table";
import "../contracts/contracts.css";
import DownArrow from "../../assets/icons/down-arrow.svg";
import ReactTooltip from "react-tooltip";

import { resolve } from "../../api/resolve";
import useAxios from "../../hooks/useAxios";
import { autoCloseTimer, delay } from "../../constants/constants";
import { toast } from "react-toastify";
import RejectDocModal from "../modals/RejectDocModal";
import eye_on from "../../assets/icons/eye_on.png";
import RevertDocModal from "../modals/RevertDocModal";
import { useTranslation } from 'react-i18next';
import EyeOnIconWhite from "../../assets/icons/eye_on_white.png";
import clearIcon from "../../assets/icons/clear.png";

export const NoDataComponent = ({ colLength }) => {
  const [rows] = useState(Array(4).fill(""));
  const [cols] = useState(Array(colLength).fill(""));
  return (
    <>
      {rows.map((data, index) => (
        <tr key={`group_ndf_row_${index}`}>
          {cols.map((data, index01) => (
            <td
              key={`group_ndf_td_${index01}`}
              className="px-5 py-2 font-medium text-smW"
            >
              --
            </td>
          ))}
        </tr>
      ))}
    </>
  );
};

const DeletedDocTable = ({
  tableData,
  tableColumns,
  currentPage,
  setCurrentPage,
  nextBtnLimit,
  itemCount,
  pageLimit,
  setPageLimit,
  getListData,

  sortingOrder,
  setSortingOrder,
  setSortColumn,
  showActionBtn,
  showPageLimit,
  setPreviewDocument,
  setPreviewDocumentData,
}) => {
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({ columns: tableColumns, data: tableData });

  const [docId, setDocId] = useState(0);
  const [revertDocModelOpen, setRevertDocModelOpen] = useState(false);

  const api = useAxios();
  const { t } = useTranslation();

  const revertDocument = async () => {
    const res = await resolve(api.get(`/contract/doc/cancle/${docId}/0/`));
    if (res.data) {
      setRevertDocModelOpen(false);
      toast.success(t('constant.Document Restore Successfully'), {
        autoClose: autoCloseTimer,
        hideProgressBar: true,
      });
      getListData();
    }

    if (res.error) {
      toast.error(t('common.Something went wrong'), {
        autoClose: autoCloseTimer,
        hideProgressBar: true,
      });
    }
    setRevertDocModelOpen(false);
  };



  return (
    <>
      <div className="mt-2 flex flex-col table-container mb-5">
        <div>
          <div className="table_desin">
            <div
              style={{ maxHeight: "60vh" }}
              className="shadow overflow-hidden border-2 border-primary  sm:rounded-lg overflow-y-auto"
            >
              <table {...getTableProps()} className="w-full ">
                <thead>
                  {headerGroups.map((headerGroup) => (
                    <tr
                      className={`cursor-pointer bg-[#F6C107]`}
                      {...headerGroup.getHeaderGroupProps()}
                    >
                      {headerGroup.headers.map((column) => (
                        // Add the sorting props to control sorting. For this example
                        // we can add them into the header props

                        <th
                          scope="col"
                          className="text-left px-2 py-2"
                          {...column
                            .getHeaderProps
                            // column.getSortByToggleProps()
                            ()}
                        >
                          <div className="flex items-center">
                            <span>{t('constant.' + column.render('Header'))}</span>

                            {column.isSorted !== undefined &&
                              (column.isSorted ? (
                                <span
                                  onClick={(e) => {
                                    setSortingOrder("asc");
                                    setSortColumn(column.id);
                                    column.isSorted = false;
                                  }}
                                  style={{ cursor: "pointer", marginTop: 2 }}
                                  className="ml-1"
                                >
                                  <img src={DownArrow} />
                                </span>
                              ) : (
                                <span
                                  onClick={(e) => {
                                    setSortingOrder("desc");
                                    setSortColumn(`${column.id}`);
                                    column.isSorted = true;
                                  }}
                                  style={{
                                    cursor: "pointer",
                                    transform: "rotate(180deg)",
                                  }}
                                  className="ml-1"
                                >
                                  <img src={DownArrow} />
                                </span>
                              ))}
                          </div>
                        </th>
                      ))}
                      {showActionBtn && (
                        <th scope="col" className="text-left px-4 pt-2">
                          {t('constant.Action')}
                        </th>
                      )}
                    </tr>
                  ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                  {tableData.length < 1 && (
                    <NoDataComponent colLength={tableColumns.length} />
                  )}
                  {tableData.length > 0 &&
                    rows.map((row, i) => {
                      // new
                      prepareRow(row);
                      return (
                        <tr {...row.getRowProps()}>
                          {row.cells.map((cell, row_cell_index) => {
                            return (
                              <td
                                {...cell.getCellProps()}
                                className={`px-2 py-2 font-medium text-sm contracts-col-data ${cell.value === "In-Active" && "text-red-500"
                                  }`}
                                style={
                                  cell.column.Header === "Contract Name"
                                    ? {
                                      width: 220,
                                      overflow: "hidden",
                                      display: "block",
                                      textOverflow: "ellipsis",
                                    }
                                    : null
                                }
                              >
                                <span
                                  title={cell.column.Header === "Document Name" ? cell.value : ""}
                                  className={`${cell.column.Header === "Document Name"
                                      ? "text_fixed cursor-pointer"
                                      : ""
                                    }`}
                                >
                                  {cell.render("Cell")}
                                </span>
                              </td>
                            );
                          })}
                          {showActionBtn && (
                            <td className="py-2 flex">
                              <button
                                onClick={(e) => {
                                  e.stopPropagation();
                                  setPreviewDocument(true);
                                  setPreviewDocumentData(row.original);
                                }}
                                className="btn btn_theme btn-sm mr-1 wh_spab w-10"
                                data-tip={t("constant.View")}
                              >
                                {/* {t('constant.View')} */}
                                <img
                                  src={EyeOnIconWhite}
                                  className="ml-0.5"
                                  width={20}
                                />
                              </button>
                              <button
                                onClick={(e) => {
                                  e.stopPropagation();
                                  setRevertDocModelOpen(true);
                                  setDocId(row.original.id);
                                }}
                                data-tip={t('constant.Restore Document')}
                                className="btn btn_theme btn-sm mr-1 wh_spab w-10"
                              >
                                {/* {t('constant.Restore Document')} */}
                                <img
                                  src={clearIcon}
                                  className="ml-0.5"
                                  width={20}
                                  alt="restore"
                                />
                              </button>
                            </td>
                          )}
                          <ReactTooltip backgroundColor='#400835' textColor='#ffffff' />
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        {tableData.length > 0 && (
          <div
            className={`mt-3 flex ${showPageLimit ? "justify-between" : "justify-end"
              }`}
          >
            {showPageLimit && (
              <div>
                {itemCount > 5 && (
                  <>
                    <span>{t('constant.Show')}</span>
                    <select
                      className="border-2 border-primary outline-none mx-1 rounded-md cursor-pointer btn-sm"
                      onChange={(e) => {
                        setCurrentPage(1);
                        setPageLimit(e.target.value);
                      }}
                      value={pageLimit}
                    >
                      <option>5</option>
                      <option>10</option>
                      <option>15</option>
                      <option>25</option>``
                    </select>
                    <span>{t('common.Results')}</span>
                  </>
                )}
              </div>
            )}
            <div className="flex text-primary  border-t-2 border-b-2 border-primary rounded-lg">
              <button
                className={`btn-sm border-r-2 border-l-2 border-primary font-semibold rounded-tl-lg rounded-bl-lg ${currentPage <= 1 && "bg-inputBackground"
                  }`}
                disabled={currentPage <= 1}
                onClick={(e) => {
                  if (currentPage > 1) {
                    setCurrentPage(currentPage - 1);
                  }
                }}
              >
                {t('common.Prev')}
              </button>
              <span className="btn-sm font-semibold">{currentPage}</span>
              <button
                className={`btn-sm border-l-2 border-r-2 border-primary font-semibold rounded-tr-lg rounded-br-lg ${currentPage >= nextBtnLimit && "bg-inputBackground"
                  }`}
                disabled={currentPage >= nextBtnLimit}
                onClick={(e) => {
                  if (currentPage < nextBtnLimit) {
                    setCurrentPage(currentPage + 1);
                  }
                }}
              >
                {t('common.Next')}
              </button>
            </div>
          </div>
        )}
      </div>
      <RevertDocModal
        revertDocModelOpen={revertDocModelOpen}
        setRevertDocModelOpen={setRevertDocModelOpen}
        revertDocument={revertDocument}
      />
    </>
  );
};

export default DeletedDocTable;
