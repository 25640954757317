import React from "react";
import DatePicker from "react-datepicker";
import { useTranslation } from "react-i18next";

const DatePickerComponent = ({
  startDate,
  setStartDate,
  placeholderText,
  inline,
  endDate,
  setEndDate,
  selectsRange,
  maxDate,
  showMonthDropdown,
  showYearDropdown,
  dropdownMode,
}) => {
  const { t } = useTranslation();
  const months = [
    t("form.January"),
    t("form.February"),
    t("form.March"),
    t("form.April"),
    t("form.May"),
    t("form.June"),
    t("form.July"),
    t("form.August"),
    t("form.September"),
    t("form.October"),
    t("form.November"),
    t("form.December"),
  ];
  return (
    <div className='startend_date_pickersrd rounded-lg'>
    <DatePicker
      selected={startDate}
      startDate={startDate && startDate}
      endDate={endDate && endDate}
      onChange={(date) => {
        if (selectsRange) {
          const [start, end] = date;
          setStartDate(start);
          setEndDate(end);
        } else {
          setStartDate(date);
        }
      }}
      className="border-2 border-primary rounded-lg p-1 w-full outline-none"

      showMonthDropdown={showMonthDropdown}
      showYearDropdown={showYearDropdown}
      dropdownMode={dropdownMode}

      showPopperArrow={false}
      inline={inline}
      selectsRange={selectsRange}
      placeholderText={placeholderText}
      formatWeekDay={(nameOfDay) => nameOfDay.substr(0, 1)}
      // minDate={!selectsRange && new Date()}
      maxDate={maxDate && new Date()}
      // renderCustomHeader={({
      //   date,
      //   decreaseMonth,
      //   increaseMonth,
      //   increaseYear,
      //   decreaseYear,
      // }) => (
      //   <div className='custom-header'>
      //     <div className='flex items-center w-2/5 justify-between font-normal cursor-pointer text-primary text-base'>
      //       <span
      //         onClick={(e) => {
      //           e.preventDefault();
      //           decreaseMonth();
      //         }}
      //         className='text-xl'
      //       >
      //         {'<'}
      //       </span>
      //       <span>{months[date.getMonth()]}</span>
      //       <span
      //         onClick={(e) => {
      //           e.preventDefault();
      //           increaseMonth();
      //         }}
      //         className='text-xl'
      //       >
      //         {'>'}
      //       </span>
      //     </div>
      //     <div className='flex items-center w-2/5 justify-between font-normal cursor-pointer text-primary text-base'>
      //       <span
      //         onClick={(e) => {
      //           e.preventDefault();
      //           decreaseYear();
      //         }}
      //         className='text-xl'
      //       >
      //         {'<'}
      //       </span>
      //       <span>{date.getFullYear()}</span>
      //       <span
      //         onClick={(e) => {
      //           e.preventDefault();
      //           increaseYear();
      //         }}
      //         className='text-xl'
      //       >
      //         {'>'}
      //       </span>
      //     </div>
      //   </div>
      // )}
    />
    </div>
  );
};

export default DatePickerComponent;
