import React, { useState, useRef, useEffect } from "react";
import axios from "axios";
import "./clauseai.css";
import copy from "../../assets/icons/copy-clause.png";
import useAxios from "../../hooks/useAxios";
import libaryicon from "../../assets/icons/Libaryai.svg";
import LoadingScreen from "../loader/LoadingScreen"; // Import the LoadingScreen component
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import ReactTooltip from "react-tooltip";
import { resolve } from "../../api/resolve";
import Select from "react-select";

const ClauseLibaryChat = React.forwardRef((props, ref) => {
  const { aiContent, updateContent, className } = props;
  const [searchText, setSearchText] = useState("");
  const [isPopupOpen, setPopupOpen] = useState(false);
  const [isResizing, setResizing] = useState(false);
  const [recommendations, setRecommendations] = useState([]);
  const { t } = useTranslation();
  const [selectedRecommendation, setSelectedRecommendation] = useState("");
  const [hasContent, setHasContent] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [copiedText, setCopiedText] = useState(null);
  const [conversationHistory, setConversationHistory] = useState([]);
  const baseURL = process.env.REACT_APP_BACKEND_BASE_URL;
  const api = useAxios();
  const popupRef = useRef(null);
  const prevMousePos = useRef({ x: 0, y: 0 });
  const [clauseLibaryList, setClauseLibaryList] = useState([]);
  const [selectedClauseName, setSelectedClauseName] = useState("");
  const [clauseTextOptions, setClauseTextOptions] = useState([]);
  const [selectedClauseText, setSelectedClauseText] = useState("");

  useEffect(() => {
    let clauseTextArray = clauseLibaryList;
  }, [clauseLibaryList]);

  const handleClauseNameChange = (selectedName) => {
    setRecommendations([]);
    setSelectedClauseName(selectedName);

    const selectedClause = clauseLibaryList.find(
      (item) => item.clause_name === selectedName
    );
    if (selectedClause) {
      setClauseTextOptions(selectedClause.clause_text);
    } else {
      setClauseTextOptions([]);
    }
  };

  const options = clauseLibaryList.map((item) => ({
    value: item.clause_name,
    label: item.clause_name,
  }));

  const fetchClauseLibaryList = async () => {
    try {
      const responseData = await resolve(
        api.get(`${baseURL}/contract/list/clause/`).then((res) => res.data)
      );

      if (responseData["data"] && responseData["data"].results) {
        const updatedList = responseData["data"].results.map((item) => {
          let clauseTextArray;
          try {
            // Replace single quotes with double quotes
            clauseTextArray = JSON.parse(item.clause_text.replace(/'/g, '"'));
          } catch (error) {
            console.error("Error parsing clause_text:", error);
            clauseTextArray = []; // Default to empty array on parse error
          }

          return {
            ...item,
            clause_text: clauseTextArray, // Convert clause_text string to array
          };
        });
        setClauseLibaryList(updatedList);
      }
    } catch (error) {
      console.error("Error fetching clause library list:", error);
    }
  };

  useEffect(() => {
    const handleGlobalKeyDown = (event) => {
      if (event.ctrlKey && event.key === "/") {
        setPopupOpen(true);
        setSearchText("");
        setHasContent(false);
        setLoading(false);
      }
    };
    window.addEventListener("keydown", handleGlobalKeyDown);
    fetchClauseLibaryList();

    return () => {
      window.removeEventListener("keydown", handleGlobalKeyDown);
    };
  }, []);

  useEffect(() => {
    if (recommendations.length > 0) {
      setLoading(false);
    }
  }, [recommendations]);

  const handleSearch = async () => {
    try {
      updateContent();
      setLoading(true);
      const payload = {
        aiContent,
        selectedClauseName,
        selectedClauseText,
      };

      const response = await axios.post(
        `${baseURL}/contract/generate/clause/`,
        payload
      );

      if (response.status === 200) {
        const recommendations = response.data;
        setRecommendations(Object.values(recommendations));
        setConversationHistory([
          ...conversationHistory,
          { user: searchText, assistant: recommendations },
        ]);
      } else {
        throw new Error("Failed to fetch recommendations");
      }
    } catch (error) {
      console.error("Error fetching recommendations:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleOpenPopup = () => {
    updateContent();
    setSelectedClauseName("");
    setClauseTextOptions([]);
    setPopupOpen(true);
    setSearchText("");
    setHasContent(false);
    setLoading(false);
  };

  const handleClosePopup = () => {
    setPopupOpen(false);
    setRecommendations([]);
    setSearchText("");
    setHasContent(false);
  };

  const handleMouseDown = (e) => {
    if (e.target.classList.contains("resize-handle")) {
      setResizing(true);
    } else {
      setResizing(false);
      prevMousePos.current = { x: e.clientX, y: e.clientY };
      document.addEventListener("mousemove", handleMouseMove);
      document.addEventListener("mouseup", handleMouseUp);
    }
  };

  const handleMouseMove = (e) => {
    if (!isResizing) {
      const dx = e.clientX - prevMousePos.current.x;
      const dy = e.clientY - prevMousePos.current.y;
      prevMousePos.current = { x: e.clientX, y: e.clientY };

      const popup = popupRef.current;
      popup.style.left = popup.offsetLeft + dx + "px";
      popup.style.top = popup.offsetTop + dy + "px";
    }
  };

  const handleMouseUp = () => {
    document.removeEventListener("mousemove", handleMouseMove);
    document.removeEventListener("mouseup", handleMouseUp);
  };

  const handleEnterPress = (e) => {
    if (e.key === "Enter") {
      handleSearch();
    }
  };

  const handleRecommendationSelect = (selectedRecommendation) => {
    setSelectedRecommendation(selectedRecommendation);
  };

  const handleCancelClick = () => {
    setPopupOpen(false);
    setRecommendations([]);
    setHasContent(false);
    setSearchText("");
    setConversationHistory([]);
  };

  const handleCopyClick = (textToCopy) => {
    try {
      const textarea = document.createElement("textarea");
      textarea.value = textToCopy;
      document.body.appendChild(textarea);
      textarea.select();
      document.execCommand("copy");
      document.body.removeChild(textarea);
      setCopiedText(textToCopy);
      toast.success("Copied to clipboard");
    } catch (error) {
      toast.error("Failed to copy to clipboard");
    }
  };

  return (
    <>
      <div>
        <button
          onClick={() => {
            handleOpenPopup();
          }}
          id="myButton"
          className="q-ai-button btn pb-1 pt-0.5 pl-6 pr-6 text_c font-bold rounded-lg btn_theme3"
          data-tip={t("constant.Clause Library")}
        >
          <img src={libaryicon} alt="AI Image" className="ai-image" />
        </button>

        {isPopupOpen && (
          <div
            className={`popup-containerLibary${isResizing ? " resizing" : ""}`}
            ref={popupRef}
            onMouseDown={handleMouseDown}
          >
            <div className="clause-container">
              <button className="cancel-button" onClick={handleCancelClick}>
                x
              </button>
              <div className="clause-title">
                <h2 style={{ fontWeight: "bold", fontSize: "1.2em" }}>
                  {t("constant.Clause Library")}
                </h2>
                {isLoading && (
                  <LoadingScreen /> // Use the LoadingScreen component when loading
                )}
              </div>
              <div className="mt-3 Libary-container">
                {/* <select
                  onChange={handleClauseNameChange}
                  value={selectedClauseName}
                  className="w-full text-primary rounded-lg font-semibold"
                >
                  <option value="" disabled>
                    {t("constant.Select a clause")}
                  </option>
                  {clauseLibaryList.map((item, index) => (
                    <option key={index} value={item.clause_name}>
                      {item.clause_name}
                    </option>
                  ))}
                </select> */}
                <Select
                  value={options.find(
                    (option) => option.value === selectedClauseName
                  )}
                  onChange={(selectedOption) =>
                    handleClauseNameChange(selectedOption.value)
                  }
                  options={options}
                  classNamePrefix="react-select"
                  placeholder={t("constant.Select a clause")}
                />

                {clauseTextOptions.length > 0 && (
                  <div
                    className="m-2 font-medium overflow-y-auto"
                    style={{ maxHeight: "200px" }}
                  >
                    {clauseTextOptions.map((text, index) => (
                      <div
                        key={index}
                        className="mb-2"
                        style={{
                          color: copiedText === text ? "#310128" : "black",
                        }}
                      >
                        <input
                          type="radio"
                          id={`clause_text_${index}`}
                          name="clause_text"
                          value={text}
                          className="mr-2 align-middle"
                          onChange={(e) =>
                            setSelectedClauseText(e.target.value)
                          }
                          checked={selectedClauseText === text}
                        />
                        <label
                          htmlFor={`clause_text_${index}`}
                          className="text-gray-700 inline"
                        >
                          {text}
                        </label>
                        <button
                          onClick={() => handleCopyClick(text)}
                          className="ml-2 inline-block align-baseline"
                          style={{ verticalAlign: "baseline" }}
                        >
                          <img
                            src={copy}
                            alt="Copy Icon"
                            className="copy-icon"
                            style={{ width: "20px", height: "20px" }}
                          />
                        </button>
                      </div>
                    ))}
                  </div>
                )}
              </div>
              <div className="flex justify-end mt-2 mb-2 ">
                <button
                  onClick={handleSearch}
                  className="px-4 py-2 bg-primary text-white rounded-md"
                  data-tip={t("allModule.Modify Clause as per Document")}
                >
                  {t("allModule.Modify")}
                </button>
              </div>
              <div className="recommendations-container">
                {recommendations.map((recommendation, index) => (
                  <>
                    <h3 style={{ fontWeight: "bold", fontSize: "1em" }}>
                      {t("constant.Modifed Clause")}:
                    </h3>
                    <div key={index} className="recommendation-item">
                      <span
                        onClick={() =>
                          handleRecommendationSelect(recommendation)
                        }
                        style={{
                          color:
                            copiedText === recommendation ? "#310128" : "black",
                          fontWeight:
                            copiedText === recommendation ? 500 : "normal",
                        }}
                      >
                        {recommendation}
                      </span>
                      <button
                        data-tip={t("Copy")}
                        onClick={() => handleCopyClick(recommendation)}
                      >
                        <img
                          src={copy}
                          alt="Copy Icon"
                          className="copy-icon"
                          style={{ width: "20px", height: "20px" }}
                        />
                      </button>
                    </div>
                  </>
                ))}
              </div>
              <div className="resize-handle"></div>
            </div>
          </div>
        )}
      </div>
      <ReactTooltip backgroundColor="#400835" textColor="#ffffff" />
    </>
  );
});

export default ClauseLibaryChat;
