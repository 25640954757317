import React from 'react';
import './footer.css';
import { useTranslation } from 'react-i18next';
const Footer = () => {
  const { t } = useTranslation();
  return (
    <>
      <div className="footer">
        <ul className="ul_set">
          <li><a href='https://www.quoqo.com/terms-and-conditions' target="_blank">{t('loginPage.Privacy')}</a></li>
          <li><a href='https://www.quoqo.com/company' target="_blank">{t('loginPage.About Us')}</a></li>
          <li><a href='https://www.quoqo.com/terms-and-conditions'>{t('loginPage.Terms of Use')}</a></li>         
        </ul>
        <p>
          {t('loginPage.All Rights Reserved, (C) Quoqo Technologies (P) Ltd')}. 2023-24
        </p>
      </div>
    </>
  );
};

export default Footer;
