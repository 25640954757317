import React, { useEffect, useContext, useState } from "react";
import Switch from "react-switch";
import Select from "react-select";
import ReactTooltip from "react-tooltip";
import Creatable from "react-select/creatable";
import FormContext from "../../context/FormContext";
import AppContext from "../../context/AppContext";
import AuthContext from "../../context/AuthContext";
import AppModal from "../modallegacy/Modallegacy";
import { resolve } from "../../api/resolve";
import useAxios from "../../hooks/useAxios";
import HelpIcon from "../../assets/icons/Help-sm.svg";
import CustomPhoneInput from "./PhoneInputlegacy";
import { useTranslation } from "react-i18next";
const customStyles = {
  indicatorSeparator: () => false,
  control: (provided, state) => {
    return {
      ...provided,
      outline: "none",
      boxShadow: "none",
      border: "1px solid #400835",
      borderRadius: "10px",
      cursor: "pointer",
      "&:hover": {
        border: "1px solid #400835",
        boxShadow: "none",
      },
    };
  },
  dropdownIndicator: (provided) => {
    return {
      ...provided,
      color: "transparent",
      "&:hover": { color: "transparent" },
    };
  },
  placeholder: (provided) => {
    return {
      ...provided,
      // color: '#400835',
    };
  },
  menuList: (provided) => ({ ...provided, height: 200 }),
  input: (provided) => {
    return {
      ...provided,
      color: "#400835",
    };
  },
  option: (provided, state) => ({
    ...provided,
    color: state.isSelected ? "#fff" : "#400835",
    fontSize: 16,
    fontWeight: "400",
    background: state.isSelected ? "#400835" : undefined,
    cursor: "pointer",
    "&:hover": {
      background: "rgba(64, 8, 53, 0.63)",
      color: "#fff",
      fontWeight: "bold",
    },
  }),
  singleValue: (provided) => {
    return {
      ...provided,
      color: "#400835",
    };
  },
};

const UserFormlegacy = () => {
  const { t } = useTranslation();
  const {
    setCurrentForm,
    saveOrEditUserlegacy,
    modalData,
    setIsOpen,
    modalIsOpen,
    isEditing,
    editFormData,
    setEditFormData,
    setIsEditing,
    userFormErrors,
    setUserFormErrors,
    shouldRedirect,
  } = useContext(FormContext);
  const api = useAxios();
  console.log("editFormData", editFormData);
  const { setActiveLink, companiesListlegacy } = useContext(AppContext);
  const { user } = useContext(AuthContext);
  const [companyID, setCompanyID] = useState(null);
  const [repos, setRepos] = useState([]);
  const [userRepos, setUserRepos] = useState([]);
  const [loading, setLoading] = useState(false);
  const [userObj, setuserObj] = useState({
    first_name: "",
    company_id: companyID,
    email: "",
    confirm_email: "",
    phone: "",
    user_type: "user",
    company_name: "",
    position: "",
    repo_array: [],
    is_reviewer: isEditing ? editFormData.is_reviewer : false,
  });
  const [isActiveUser, setIsActiveUser] = useState(
    editFormData?.status == "Active"
  );
  console.log("isActiveUser 111", isActiveUser);
  const getRepos = async () => {
    const res = await resolve(
      api.get(`legacy_contract/get_all_repos/`).then((res) => res.data)
    );
    if (res.data) {
      const filterData = [];
      if (res.data && res.data.length > 0) {
        res.data.forEach((result) => {
          if (
            result.repo_name.toLowerCase() !== "default" &&
            result.repo_name.toLowerCase() !== "shared repo"
          ) {
            const contractObj = {
              value: result.repo_name,
              label: result.repo_name,
              id: result.id,
            };
            filterData.push(contractObj);
          }
        });
      }
      setRepos([...repos, ...filterData]);
    }
  };
  const getUserRepos = async (id) => {
    setLoading(true);
    const res = await resolve(
      api.get(`/legacy_contract/get_user_repos/${id}`).then((res) => res.data)
    );
    if (res.data) {
      const filterData = [];
      if (res.data && res.data.length > 0) {
        res.data.forEach((result) => {
          if (!result.is_public) {
            const contractObj = {
              value: result.repo_name,
              label: result.repo_name,
              id: result.id,
            };
            filterData.push(contractObj);
          }
        });
      }
      setUserRepos([...userRepos, ...filterData]);
      if (isEditing) {
        setEditFormData({
          ...editFormData,
          repo_array: filterData,
        });
      }
    }
    setLoading(false);
  };
  useEffect(() => {
    getRepos();
    if (isEditing) {
      getUserRepos(editFormData.id);
    }
    setUserFormErrors({
      ...userFormErrors,
      name: "",
      company_id: "",
      email: "",
      confirm_email: "",
      phone: "",
      position: "",
    });
    setCurrentForm("UserFormlegacy");
    return () => setCurrentForm("");
  }, []);
  useEffect(() => {
    return () => {
      if (isEditing) {
        setEditFormData("");
        setIsEditing(false);
      }
    };
  }, [isEditing]);
  useEffect(() => {
    if (isEditing) {
      setEditFormData({
        ...editFormData,
        is_active_user: isActiveUser,
        is_reviewer: userObj.is_reviewer,
      });
    }
  }, [isActiveUser, userObj.is_reviewer]);
  return (
    <div className="text-primary text-base font-normal font-sans">
      <h1 className="text-4xl font-bold">
        {!isEditing ? t("form.Add User") : t("form.Edit User")}
      </h1>
      <div className=" w-2/5 mt-10">
        <form>
          <div className="flex flex-col mb-3">
            <label>{t("form.Name")}</label>
            <input
              placeholder={t("form.Insert user full name")}
              className="border-2 border-primary rounded-lg outline-none p-1 pl-2"
              value={!isEditing ? userObj.first_name : editFormData.first_name}
              onChange={(e) => {
                !isEditing
                  ? setuserObj({ ...userObj, first_name: e.target.value })
                  : setEditFormData({
                      ...editFormData,
                      first_name: e.target.value,
                    });
              }}
            />
            <p className="text-sm text-red-500 font-normal">
              {userFormErrors.name}
            </p>
          </div>
          {user.user_type === "super_admin" && !isEditing && (
            <div className="mb-3">
              <label>{t("form.Company")}</label>
              <Select
                options={companiesListlegacy}
                placeholder={t("form.Select Company name")}
                styles={customStyles}
                isSearchable={true}
                onChange={(company) => {
                  setuserObj({
                    ...userObj,
                    company_id: company.id,
                    company_name: company.company_name,
                  });
                  isEditing &&
                    setEditFormData({
                      ...editFormData,
                      company_id: company.id,
                      company_name: company.company_name,
                    });
                }}
                className="w-full text-primary rounded-lg"
                defaultInputValue={isEditing && editFormData.company_name}
                escapeClearsValue
              />
              <p className="text-sm text-red-500 font-normal">
                {userFormErrors.company_id}
              </p>
            </div>
          )}
          <div className="flex flex-col mb-3">
            <label>{t("form.Designation")}</label>
            <input
              placeholder={t("form.Insert user position")}
              className="border-2 border-primary rounded-lg outline-none p-1 pl-2"
              value={
                !isEditing ? userObj.designation : editFormData.designation
              }
              onChange={(e) => {
                e.preventDefault();
                !isEditing
                  ? setuserObj({ ...userObj, designation: e.target.value })
                  : setEditFormData({
                      ...editFormData,
                      designation: e.target.value,
                    });
              }}
            />
            <p className="text-sm text-red-500 font-normal">
              {userFormErrors.position}
            </p>
          </div>
          <div className="flex flex-col mb-3">
            <label>{t("form.Email")}</label>
            <input
              placeholder={t("form.Insert an unique user email")}
              type="email"
              className="border-2 border-primary rounded-lg outline-none p-1 pl-2"
              value={!isEditing ? userObj.email : editFormData.email}
              onChange={(e) => {
                !isEditing
                  ? setuserObj({ ...userObj, email: e.target.value })
                  : setEditFormData({
                      ...editFormData,
                      email: e.target.value,
                    });
              }}
            />
            <p className="text-sm text-red-500 font-normal">
              {userFormErrors.email}
            </p>
          </div>
          <div className="flex flex-col mb-3">
            <label>{t("form.Confirm Email")}</label>
            <input
              placeholder={t("form.Confirm user email")}
              type="email"
              className="border-2 border-primary rounded-lg outline-none p-1 pl-2"
              value={
                !isEditing ? userObj.confirm_email : editFormData.confirm_email
              }
              onChange={(e) => {
                !isEditing
                  ? setuserObj({ ...userObj, confirm_email: e.target.value })
                  : setEditFormData({
                      ...editFormData,
                      confirm_email: e.target.value,
                    });
              }}
              onPasteCapture={(e) => e.preventDefault()}
            />
            <p className="text-sm text-red-500 font-normal">
              {userFormErrors.confirm_email}
            </p>
          </div>
          <div className="flex flex-col mb-3">
            <label>{t("form.Phone")}</label>
            {/* <input
              placeholder='Phone Number'
              maxLength={10}
              className='border-2 border-primary rounded-lg outline-none p-1 pl-2'
              value={!isEditing ? userObj.phone : editFormData.phone}
              onChange={(e) => {
                !isEditing
                  ? setuserObj({ ...userObj, phone: e.target.value })
                  : setEditFormData({
                      ...editFormData,
                      phone: e.target.value,
                    });
              }}
            /> */}
            <CustomPhoneInput
              value={!isEditing ? userObj.phone : editFormData.phone}
              onChange={(phone) => {
                !isEditing
                  ? setuserObj({ ...userObj, phone })
                  : setEditFormData({
                      ...editFormData,
                      phone,
                    });
              }}
            />
            <p className="text-sm text-red-500 font-normal">
              {userFormErrors.phone}
            </p>
          </div>
          {user.user_type === "admin" && (
            <div className="flex flex-col mb-3">
              <div className="flex">
                <label className="mb-1">{t("form.Select Repositories")}</label>
                <>
                  <img
                    src={HelpIcon}
                    width={12}
                    className="ml-1 -mt-2 cursor-pointer"
                    data-tip="Users can access only selected repositories"
                  />
                  <ReactTooltip backgroundColor="#400835" textColor="#ffffff" />
                </>
              </div>
              {!loading && (
                <Creatable
                  options={repos}
                  placeholder="Select or create repository"
                  isMulti
                  styles={customStyles}
                  isSearchable={true}
                  onChange={(arr) => {
                    !isEditing
                      ? setuserObj({
                          ...userObj,
                          repo_array: arr,
                        })
                      : setEditFormData({
                          ...editFormData,
                          repo_array: arr,
                        });
                  }}
                  defaultValue={[...userRepos]}
                />
              )}
            </div>
          )}
          {/* <div className='flex justify-between items-center mb-3'>
            <span className='flex'>
              <img
                src={HelpIcon}
                width={12}
                className='mr-1 cursor-pointer'
                data-tip='Give reviewer access to user'
              />
              <ReactTooltip backgroundColor='#400835' textColor='#ffffff' />
              {t('form.Reviewer Status')} :{' '}
              <span
                className={`${
                  userObj.is_reviewer ? 'text-primary' : 'text-red-500'
                } font-medium`}
              >
                {userObj.is_reviewer ? 'Active' : 'In-Active'}
              </span>
            </span>
            <Switch
              offColor='#E0720C'
              onColor='#400835'
              name='Active'
              checked={userObj.is_reviewer}
              height={20}
              boxShadow='none'
              onChange={() =>
                setuserObj({ ...userObj, is_reviewer: !userObj.is_reviewer })
              }
            />
          </div> */}
          {isEditing && (
            <div className="flex justify-between items-center mb-3">
              <span>
                {t("form.Status")} :{" "}
                <span
                  className={`${
                    isActiveUser ? "text-primary" : "text-red-500"
                  } font-medium`}
                >
                  {isActiveUser ? "Active" : "In-Active"}
                </span>
              </span>
              <Switch
                offColor="#E0720C"
                onColor="#400835"
                name="Active"
                checked={isActiveUser}
                height={20}
                boxShadow="none"
                onChange={() => setIsActiveUser(!isActiveUser)}
              />
            </div>
          )}

          {!isEditing ? (
            <button
              className="bg-primary font-bold text-white w-full p-1 rounded-lg"
              onClick={(e) => {
                e.preventDefault();
                saveOrEditUserlegacy(userObj);
              }}
            >
              {t("form.Save User")}
            </button>
          ) : (
            <button
              className="bg-secondary font-bold text-white w-full p-1 rounded-lg"
              onClick={(e) => {
                e.preventDefault();
                saveOrEditUserlegacy(editFormData);
              }}
            >
              {t("form.Update User")}
            </button>
          )}
        </form>
      </div>
      <AppModal
        title={modalData.title}
        bodyText={modalData.bodyText}
        setIsOpen={setIsOpen}
        modalIsOpen={modalIsOpen}
        setActiveLink={setActiveLink}
        activeLink={shouldRedirect && "AdminUser"}
      />
    </div>
  );
};

export default UserFormlegacy;
